import React, { useCallback, useEffect, useMemo } from 'react'
import { ContextualMenu, ContextualMenuItemType, DirectionalHint } from '@fluentui/react'
import { IconSvg } from '../icons'
import { CheckmarkIcon, LeftArrowIcon } from '../icons/names'

import './ContextMenu.css'

const renderContextMenuItem = ({ item }) => (
  <div className="capex-context-menu-item">
    {item.icon || item.isChecked
      ? (
          <IconSvg name={item.icon || CheckmarkIcon} />
        )
      : (
          <span className="capex-context-menu-item__spacer" />
        )
    }
    <span>{item.text}</span>
    {item.subMenuProps && (
      <IconSvg name={LeftArrowIcon} />
    )}
  </div>
)

const prepareItems = (items, onItemClick) => {
  items.forEach((item) => {
    if (item.items && !item.subMenuProps) {
      prepareItems(item.items, onItemClick)
      item.subMenuProps = {
        onItemClick: onItemClick,
        contextualMenuItemAs: renderContextMenuItem,
        items: item.items,
      }
      delete item.items
    } else if (!item.key) {
      item.key = `divider_${++items.dividerId}`
      item.itemType = ContextualMenuItemType.Divider
    }
  })
}

const ContextMenu = ({
  items = [], onItemClick, onOpen, onClose, onDismiss, visible, hidden, target,
  directionalHint = DirectionalHint.bottomLeftEdge,
}) => {
  const visibleItems = useMemo(() => {
    const copyVisible = (list) => list.filter(({ hidden }) => !hidden)
    const result = copyVisible(items)
    result.forEach((item) => {
      if (item.items) {
        item.items = copyVisible(item.items)
      }
    })
    return result
  }, [ items ])

  const handleMenuItemClick = useCallback((...args) => {
    onClose()
    onItemClick(...args)
  }, [ onClose, onItemClick ])

  useEffect(() => {
    visibleItems.dividerId = 0
    prepareItems(visibleItems, handleMenuItemClick)
  }, [ visibleItems, handleMenuItemClick ])

  const menuHidden = useMemo(() => {
    if (visible !== undefined) {
      return !visible
    }
    return Boolean(hidden)
  }, [ hidden, visible ])

  useEffect(() => {
    global.menuMode = !menuHidden
    return () => {
      global.menuMode = false
    }
  }, [ menuHidden ])

  return (
    <ContextualMenu
      onMenuOpened={onOpen}
      contextualMenuItemAs={renderContextMenuItem}
      items={visibleItems}
      hidden={menuHidden}
      target={target}
      onItemClick={handleMenuItemClick}
      onDismiss={onClose ?? onDismiss}
      directionalHint={directionalHint}
    />
  )
}

export default ContextMenu
