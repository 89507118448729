import React from 'react'
import PropTypes from 'prop-types'
import { FontIcon as Icon } from '@fluentui/react'
import { addClassWhenNeeded } from './utils'

export const STATUS = {
  PROGRESS: 'progress',
  ERROR: 'error',
  DONE: 'done',
}

export function IconRenderer (props) {
  addClassWhenNeeded(props)
  const renderIcon = (status) => {
    switch (status) {
      case STATUS.ERROR:
        return <Icon
          iconName="Error"
          style={{ color: 'red ' }}
          title="Error"
        />
      case STATUS.DONE:
        return <Icon
          iconName="Completed"
          style={{ color: 'green' }}
          title="Success"
        />
      default:
        return <Icon
          iconName="AlarmClock"
          title="Pending..."
        />
    }
  }

  const status = props.value
  return renderIcon(status)
}

IconRenderer.PropsTypes = {
  TD: PropTypes.object,
  value: PropTypes.any,
  cellProperties: PropTypes.object,
}
