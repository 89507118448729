import { getTheme } from '@fluentui/react/lib/Styling'
import { mergeStyleSets } from '@fluentui/react'

const theme = getTheme()

export const updateTableStyles = {
  root: {
    height: '100%',
    overflowX: 'auto',
    overflowY: 'auto',
    selectors: {
      '& [role=grid]': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        height: '100%',
      },
    },
  },
  headerWrapper: {
    flex: '0 0 auto',
  },
  contentWrapper: {
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'auto',
  },
}

export const stylesChoiceGroup = {
  flexContainer: { display: 'flex' },
}
export const stylesRadioButton = { root: { marginTop: 0, minHeight: 'none' } }

export const stylesRowFooter = {
  root: {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.semanticColors.variantBorder,
    borderBottom: '',
  },
  cell: { padding: 0 },
}

export const classFooter = mergeStyleSets({
  header: {
    margin: 0,
  },
  row: {
    flex: '0 0 auto',
  },
})
