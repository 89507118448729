import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { ContextualMenu, TextField, ComboBox, Dropdown, Dialog, DialogFooter, DialogType } from '@fluentui/react'
import { DefaultButton, PrimaryButton } from '../../common/Button'
import { KEYS, ROLE_OPTIONS, USER_STATUSES } from '../constants'
import { generalizedRoles, getGeneralizedRole } from '../../../constants/roles'
import { EVENT_TYPE, wrapByEventLog } from '../../../features/eventLog/eventLogSlice'

import '../style.css'

const MIN_WIDTH_DIALOG = 420
const WIDTH_COMBO_BOX = 370

const statusOption = [
  {
    key: 'unblock',
    text: 'Active',
    value: USER_STATUSES.ACTIVE,
  },
  {
    key: 'block',
    text: 'Blocked',
    value: USER_STATUSES.BLOCKED,
  },
]

const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
}

const modalPropsStyles = {
  main: {
    maxWidth: MIN_WIDTH_DIALOG,
  },
}

const comboBoxStyles = {
  root: { width: WIDTH_COMBO_BOX },
  optionsContainerWrapper: { width: WIDTH_COMBO_BOX },
}
const dropdownStyles = { dropdown: { width: WIDTH_COMBO_BOX } }

const dialogContentProps = {
  type: DialogType.normal,
  title: 'User properties',
}

const EditUserForm = ({ onSave, user, onClose, canChangeRole, canChangeStatus }) => {
  const dispatch = useDispatch()

  const [ roleUser, setRole ] = useState()
  const [ statusUser, setStatus ] = useState()
  const [ modified, setModified ] = useState(false)

  useEffect(() => {
    const userRoles = user?.[KEYS.ROLES] ?? []
    const userRole = getGeneralizedRole(userRoles)
    setRole(userRole)
    setStatus(user[KEYS.STATUS] === USER_STATUSES.ACTIVE ? statusOption[0] : statusOption[1])
  }, [ user, setRole, setStatus ])

  const onChangeRoleHandler = useCallback((event, option) => {
    const key = option?.key
    setRole(key)
    setModified(true)
  }, [ setRole, setModified ])

  const onChangeStatusHandler = useCallback((event, status) => {
    setStatus(status)
    setModified(true)
  }, [ setStatus, setModified ])

  const onSaveHandler = useCallback(async () => {
    if (statusUser && user) {
      const roles = generalizedRoles[roleUser] ?? user[KEYS.ROLES]
      await dispatch(wrapByEventLog({
        type: statusUser.key === 'block' ? EVENT_TYPE.blockUser : EVENT_TYPE.unblockUser,
        details: `Users = ${user[KEYS.ID]}`,
        action: () => onSave({ ...user, [KEYS.ROLES]: roles, [KEYS.STATUS]: statusUser?.value }),
      }))
      setModified(false)
    }
    onClose()
  }, [ onClose, onSave, roleUser, user, setModified, statusUser, dispatch ])

  return (
    <Dialog
      hidden={false}
      onDismiss={onClose}
      dialogContentProps={dialogContentProps}
      minWidth={MIN_WIDTH_DIALOG}
      modalProps={{
        isBlocking: true,
        dragOptions: dragOptions,
        styles: modalPropsStyles,
      }}
    >
      <TextField label="Identifier" readOnly defaultValue={user[KEYS.ID]} />
      <TextField label="Name" readOnly defaultValue={user[KEYS.NAME]} />
      <ComboBox
        allowFreeform={false}
        placeholder='Select role'
        label="Role"
        multiSelect={false}
        selectedKey={roleUser}
        options={ROLE_OPTIONS}
        styles={comboBoxStyles}
        onChange={onChangeRoleHandler}
        disabled={!canChangeRole}
      />
      <Dropdown
        label={'Status'}
        placeholder="Select an status"
        styles={dropdownStyles}
        selectedKey={statusUser?.key}
        options={statusOption}
        onChange={onChangeStatusHandler}
        disabled={!canChangeStatus}
      />
      <DialogFooter className="buttons-dialog-footer">
        <PrimaryButton
          text="Save and close"
          onClick={onSaveHandler}
          disabled={!modified}
        />
        <DefaultButton
          onClick={onClose}
          text="Cancel"
        />
      </DialogFooter>
    </Dialog>
  )
}

EditUserForm.PropsTypes = {
  onSave: PropTypes.func,
  user: PropTypes.object,
  onClose: PropTypes.func,
}

export default EditUserForm
