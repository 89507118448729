import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner, SpinnerSize } from '@fluentui/react'
import { useHandler } from '../../../hooks'
import { selectUser } from '../../../features/login/loginSlice'
import { loadUsers, updateUsers, selectUsers, selectBusy } from '../../../features/users/usersSlice'
import { cutItems } from '../../../utils/format'
import { useConfirm } from '../../common/Confirm'
import { confirm as tConfirm } from '../../common/Confirm/constants'
import { KEYS, cabinet as t, USER_STATUSES } from '../constants'
// import ChangeRolesForm from '../Form/ChangeRolesForm'
import EditUserForm from '../Form/EditUserForm'
import { DATA_TYPES } from '../../../constants/common'
import { selectSettingsVersion } from '../../../features/settings/settingsSlice'
import { EVENT_TYPE, wrapByEventLog } from '../../../features/eventLog/eventLogSlice'
import { COLUMNS, prefixColumnId } from './constants'
import UsersGrid from './Grid'
import Menu from './Menu'

const findSelectedItem = (table, items) => {
  const select = table?.getSelectedLast && table.getSelectedLast()
  if (!select || !Array.isArray(items)) {
    return
  }
  const row = table.toPhysicalRow(select[0])
  const userId = table.getSourceDataAtRow(row)?.[KEYS.ID]
  return items.find((user) => (user[KEYS.ID] === userId))
}

const sortByName = (user1, user2) => {
  if (user1[KEYS.NAME] > user2[KEYS.NAME]) {
    return 1
  }
  if (user1[KEYS.NAME] < user2[KEYS.NAME]) {
    return -1
  }
  return 0
}

const Container = () => {
  const hotTableRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadUsers())
  }, [ dispatch ])

  const { confirm, renderConfirm } = useConfirm({ textNoBtn: tConfirm.cancel })
  const [ userEdit, setUserEdit ] = useState(null)
  // const [ userChangeRole, setUserChangeRole ] = useState(null)

  const settingsVersion = useSelector(selectSettingsVersion)
  const busy = useSelector(selectBusy)
  const user = useSelector(selectUser)
  const usersState = useSelector(selectUsers)

  const users = useMemo(() => {
    const userId = user?.[KEYS.ID]
    return usersState?.map((item) => (
      {
        ...item,
        [KEYS.STATUS]: item[KEYS.STATUS] === 'ACTIVE' ? USER_STATUSES.ACTIVE : USER_STATUSES.BLOCKED,
        [KEYS.OWNER]: userId ? userId === item[KEYS.ID] : false,
      }
    )).sort(sortByName) ?? []
  }, [ usersState, user ])

  // Занесення змінених даних у таблицю без її перегенерації
  useEffect(() => {
    if (hotTableRef?.current?.hotInstance && Array.isArray(users)) {
      hotTableRef.current.hotInstance.loadData(users)
    }
  }, [ hotTableRef, users ])

  const [ amountSelected, setAmountSelected ] = useState({ selected: 0, block: 0, unblock: 0 })

  const onChangeCheck = useHandler(() => {
    const myUserSelected = users?.find((item) => item[KEYS.OWNER] && item[KEYS.CHECK])
    const usersSelected = users?.filter((item) => item[KEYS.CHECK]) ?? []
    const usersUnblock = usersSelected.filter((item) => (item[KEYS.STATUS] === USER_STATUSES.ACTIVE))
    const usersBlock = usersSelected.filter((item) => (item[KEYS.STATUS] !== USER_STATUSES.ACTIVE))
    setAmountSelected({
      selected: usersSelected.length ?? 0,
      block: usersBlock.length,
      unblock: usersUnblock.length,
      myUserSelected,
    })
  })

  useEffect(() => {
    onChangeCheck()
  }, [ users, onChangeCheck ])

  const onEditUser = useHandler(() => {
    const user = findSelectedItem(hotTableRef?.current?.hotInstance, users)
    setAmountSelected({
      selected: 1,
      block: 0,
      unblock: 0,
      myUserSelected: user[KEYS.OWNER],
    })
    setUserEdit(user)
  })

  const handlingContextMenu = useCallback((e) => {
    switch (e) {
      case 'open': {
        onEditUser()
        break
      }
      default:
    }
  }, [ onEditUser ])

  const onEdit = useHandler(() => {
    const selectedUser = users?.find(
      (item) => (item[KEYS.CHECK] === true),
    )
    if (selectedUser) {
      setUserEdit(selectedUser)
    }
  })

  const onBlock = useHandler(() => {
    const usersBlock = users
      ?.filter((item) => item[KEYS.STATUS] === USER_STATUSES.ACTIVE && item[KEYS.CHECK] === true) ?? []
    const amount = usersBlock.length
    if (!amount) {
      return
    }
    const usersCut = cutItems(usersBlock, 'name', 5)
    const userIds = usersBlock.map((user) => user[KEYS.ID])
    confirm(
      () => {
        const updateData = usersBlock
          .map((user) => {
            const { [KEYS.ID]: id } = user ?? {}
            return id ? { [KEYS.ID]: id, [KEYS.STATUS]: USER_STATUSES.BLOCKED } : null
          })
          .filter(Boolean)
        return dispatch(wrapByEventLog({
          type: EVENT_TYPE.blockUser,
          details: `Users = ${userIds.join(', ')}`,
          action: () => dispatch(updateUsers(updateData)),
        }))
      },
      {
        title: t.blockUser(amount),
        messages: [ t.messageBlockUser(amount), ...usersCut ],
        maxCountMessage: 6,
        textYesBtn: t.block,
      })
  })

  const onUnblock = useHandler(() => {
    const usersUnblock = users
      ?.filter((item) => item[KEYS.STATUS] !== USER_STATUSES.ACTIVE && item[KEYS.CHECK] === true) ?? []
    const amount = usersUnblock.length
    if (!amount) {
      return
    }
    const usersCut = cutItems(usersUnblock, 'name', 5)
    const userIds = usersUnblock.map((user) => user[KEYS.ID])
    confirm(
      () => {
        const updateData = usersUnblock
          .map((user) => {
            const { [KEYS.ID]: id } = user ?? {}
            return id ? { [KEYS.ID]: id, [KEYS.STATUS]: USER_STATUSES.ACTIVE } : null
          })
          .filter(Boolean)
        return dispatch(wrapByEventLog({
          type: EVENT_TYPE.unblockUser,
          details: `Users = ${userIds.join(', ')}`,
          action: () => dispatch(updateUsers(updateData)),
        }))
      },
      {
        title: t.unblockUsers(amount),
        messages: [ t.messageUnblockUser(amount), ...usersCut ],
        maxCountMessage: 6,
        textYesBtn: t.unblock,
      })
  })

  const saveChangesToUserData = useCallback((editedUser) => {
    if (!editedUser) {
      return
    }
    const { [KEYS.STATUS]: status, [KEYS.ID]: id } = editedUser ?? {}
    const data = {
      [KEYS.ID]: id,
      [KEYS.STATUS]: (status === true || status === USER_STATUSES.ACTIVE)
        ? USER_STATUSES.ACTIVE
        : USER_STATUSES.BLOCKED,
    }
    return dispatch(updateUsers([ data ]))
  }, [ dispatch ])

  return (
  <>
    <Menu
      existBlock={!!amountSelected.block && !amountSelected.myUserSelected}
      existUnblock={!!amountSelected.unblock && !amountSelected.myUserSelected}
      existChangeRole={false}
      onBlock={onBlock}
      onUnblock={onUnblock}
      onEdit={onEdit}
      // onChangeRole={onChangeRole}
      busy={busy}
      selectCell={amountSelected.selected === 1}
    />
    <UsersGrid
      dataType={DATA_TYPES.USERS}
      prefixColumnId={prefixColumnId}
      columns={COLUMNS}
      refHot={hotTableRef}
      onChangeCheck={onChangeCheck}
      handlingContextMenu={handlingContextMenu}
      settingsVersion={settingsVersion}
      isFullViewHeader
    />
    {renderConfirm()}
    {busy && (
      <Spinner className="centered" size={SpinnerSize.large} />
    )}
    {userEdit && (
      <EditUserForm
        user={userEdit}
        onClose={() => setUserEdit(null)}
        onSave={saveChangesToUserData}
        canChangeRole={false}
        canChangeStatus={!amountSelected.myUserSelected}
      />
    )}
  </>
  )
}

export default Container
