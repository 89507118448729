const samePrefix = (arrays) => {
  if (!arrays[0] || !arrays[0][0]) {
    return false
  }
  const prefix = arrays[0][0]
  return arrays.every(([ item ]) => item === prefix)
}

const splitChildren = (arrays) => {
  let i = 1
  while (i < arrays.length - 1) {
    let pIdx = 0
    let j = 0
    let s = 0
    while (j < arrays[i].length) {
      const span = arrays[i][j].colspan
      const edge = s + span
      const pSpan = arrays[i - 1][pIdx].colspan
      if (edge < pSpan) {
        s += span
      } else {
        if (edge > pSpan) {
          arrays[i][j].colspan = pSpan
          arrays[i].splice(j + 1, 0, {
            label: arrays[i][j].label,
            colspan: edge - pSpan,
          })
        }
        pIdx++
        s = 0
      }
      j++
    }
    i++
  }
}

export const upFirst = (string) => string && string[0] ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : string

export const prepareHeader = (string) => string && string.split('_').map((text) => upFirst(text)).join(' ')

export const makeNestedHeaders = (columns) => {
  if (!columns?.length) {
    return
  }
  const tree = columns.map(({ id }) => id.split('.').slice(0, -1))
  while (samePrefix(tree)) {
    tree.forEach((item) => item.splice(0, 1))
  }
  const result = [
    columns.map(({ label }) => label),
  ]
  while (tree.some((item) => item.length > 0)) {
    const level = []
    let i = 0
    let label = tree[i].pop()
    let next
    let colspan
    while (i < tree.length) {
      colspan = 0
      do {
        colspan++
        i++
        if (i >= tree.length) {
          break
        }
        next = tree[i].pop()
      } while (next === label)
      level.push({ label: prepareHeader(label), colspan })
      label = next
    }
    result.unshift(level)
  }
  splitChildren(result)
  return result
}

export const isNumberType = (type) => [ 'long', 'int', 'double', 'integer' ].indexOf(type) >= 0

export const isDateType = (type) => type === 'date' || type === 'datetime'

export const isBooleanType = (type) => type === 'boolean'

export const isStringType = (type) => type === 'string'

export const isEnumType = (type) => type === 'enum'
