import React from 'react'
import Columns from './Columns'

export const ColumnsContainer = () => (
  <div
    id='columns-content-container'
    style={{
      flex: 1,
      display: 'flex',
    }}
  />
)

export default Columns
