import api from '../../api'
import { BUTTON_KEY } from '../../components/UpdateDB/constants'
import { OPERATION_DIFF } from '../../api/updateDB'
import { LOCALE, errorFixing } from '../../utils/format'
import { getSelectData, initializeData } from '../../components/UpdateDB/common/utils'
import { COLUMNS_UPDATE } from '../../constants/updateDB'
import { EVENT_TYPE, wrapByEventLog } from '../eventLog/eventLogSlice'

export const JOB_FROM_ATOLL = 'job_from_atoll'
export const STATUS_JOB = {
  NEW: 'NEW',
  ACTIVE: 'ACTIVE',
  FINISHED_SUCCESS: 'FINISHED_SUCCESS',
  FINISHED_FAIL: 'FINISHED_FAIL',
}
export const JOB_FINISHED_STATUS = [ STATUS_JOB.FINISHED_FAIL, STATUS_JOB.FINISHED_SUCCESS ]
export const JOB_ACTIVE_STATUS = [ STATUS_JOB.NEW, STATUS_JOB.ACTIVE ]

export const JOB_TYPE = {
  SYNC_ATOLL_NETWORK: 'SYNC_ATOLL_NETWORK',
}

export const initUpdateDB = (projectId) => api.updateDB.getData(projectId)

const applyNetworkDiff = async (projectId, operation, data, description) => window.store.dispatch(wrapByEventLog({
  type: EVENT_TYPE.updateDB,
  details: `Operation = ${description}; Sites = ${data?.sites?.length || 0}; RBS = ${data?.rbses?.length || 0}; Sectors = ${data?.sectors?.length || 0}; BC = ${data?.bcs?.length || 0}`,
  action: () => api.updateDB.applyNetworkDiff(projectId, operation, data),
}))

const reloadFromDefault = async (projectId) => window.store.dispatch(wrapByEventLog({
  type: EVENT_TYPE.updateDB,
  details: 'Operation = Reload from Default project',
  action: () => api.updateDB.reloadFromDefault(projectId),
}))

export const reloadFromDefaultProject = (projectId) => reloadFromDefault(projectId)

export const reloadFromDefaultProjectUnmodified = async (projectId) => {
  const { simultaneousUpdate } = await initUpdateDB(projectId)
  const items = initializeData(simultaneousUpdate)
  const columnDB = COLUMNS_UPDATE.DB_VALUE
  const columnShadow = COLUMNS_UPDATE.ORIGINAL_VALUE
  const columnProject = COLUMNS_UPDATE.NEW_VALUE
  items.forEach((item) => {
    if (item[columnShadow] === item[columnProject] && item[columnShadow] !== item[columnDB]) {
      item.select = columnDB
    }
  })
  const data = getSelectData(items, columnDB)
  return applyNetworkDiff(projectId, OPERATION_DIFF.APPLY_DEFAULT, data, 'Reload unmodified data only')
    .catch(errorFixing)
}

export const updateFromAtoll = (projectId, doNotSave) => api.updateDB.updateFromAtoll(projectId)
  .then((rez) => {
    if (JOB_ACTIVE_STATUS.includes(rez?.status) && rez?.id) {
      const date = new Date()
      const jobsStorage = { id: rez.id, status: rez.status, date: date.toLocaleString(LOCALE) }
      if (doNotSave) {
        return jobsStorage
      }
      localStorage.setItem(`${JOB_FROM_ATOLL}-${projectId}`, JSON.stringify(jobsStorage))
    }
    return { id: rez.id, status: rez.status }
  })
  .catch(errorFixing)

export const apiUpdateDB = {
  clear: {
    [BUTTON_KEY.UPDATE_TO_DB]: (projectId, data) =>
      applyNetworkDiff(projectId, OPERATION_DIFF.APPLY_PROJECT, data, 'Clear: Update to DB'),
    [BUTTON_KEY.APPLY_IN_MY_PROJECT]: (projectId, data) =>
      applyNetworkDiff(projectId, OPERATION_DIFF.APPLY_SHADOW, data, 'Clear: Apply in my project'),
  },
  simultaneous: {
    [BUTTON_KEY.UPDATE_TO_DB]: (projectId, data) =>
      applyNetworkDiff(projectId, OPERATION_DIFF.APPLY_PROJECT, data, 'Simultaneous: Update to DB'),
    [BUTTON_KEY.APPLY_IN_MY_PROJECT]: (projectId, data) =>
      applyNetworkDiff(projectId, OPERATION_DIFF.APPLY_SHADOW, data, 'Simultaneous: Apply in my project'),
  },
  conflict: {
    [BUTTON_KEY.UPDATE_TO_DB]: (projectId, data) =>
      applyNetworkDiff(projectId, OPERATION_DIFF.APPLY_PROJECT, data, 'Conflict: Update to DB'),
    [BUTTON_KEY.APPLY_IN_MY_PROJECT]: (projectId, data) =>
      applyNetworkDiff(projectId, OPERATION_DIFF.APPLY_SHADOW, data, 'Conflict: Apply in my project'),
    [BUTTON_KEY.APPLY_IN_MY_PROJECT_AND_UPDATE_TO_DB]: (projectId, data) =>
      applyNetworkDiff(projectId, OPERATION_DIFF.APPLY_SHADOW_DEFAULT, data,
        'Conflict: Apply in my project and Update to DB'),
    [BUTTON_KEY.APPLY_IN_MY_PROJECT_DBValue]: (projectId, data) =>
      applyNetworkDiff(projectId, OPERATION_DIFF.APPLY_DEFAULT, data,
        'Conflict: Apply in my project DB value'),
  },
  updateBC: {
    [BUTTON_KEY.UPDATE_BC]: (projectId, data) =>
      applyNetworkDiff(projectId, OPERATION_DIFF.APPLY_DEFAULT, data, 'Update BC: BC to Default project'),
  },
}
