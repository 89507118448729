import React from 'react'
import { TextField } from '@fluentui/react'
import { OpenDropdownIcon, HiddenDropdownIcon, SearchIcon } from '../icons/names'
import { IconSvg } from '../icons'

import './TextFieldSearch.css'
import { IconButton } from '../Button'

const onRenderPrefix = (props) => <IconSvg name={props.prefix} />
const onRenderSuffix = ({ suffix }) => {
  const { searchPrev, searchNext } = suffix
  return (
    <>
      {searchPrev && (
        <IconButton
          title={'Search previous'}
          icon={OpenDropdownIcon}
          small
          onClick={searchPrev}
        />
      )}
      {searchNext && (
        <IconButton
          title={'Search next'}
          icon={HiddenDropdownIcon}
          small
          onClick={searchNext}
        />
      )}
    </>
  )
}

const TextFieldSearch = ({ className = '', value, searchPrev, searchNext, ...props }) => {
  const showButtons = !!(searchPrev || searchNext)
  return (
    <TextField
      label={'Search'}
      prefix={SearchIcon}
      suffix={showButtons ? { searchPrev, searchNext } : undefined}
      className={`search${value ? '' : ' is-empty'} ${className}`}
      placeholder={'Search'}
      onRenderPrefix={onRenderPrefix}
      onRenderSuffix={showButtons ? onRenderSuffix : undefined}
      value={value}
      {...props}
    />
  )
}

export default TextFieldSearch
