import './style.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { SelectableOptionMenuItemType } from '@fluentui/react'
import {
  getColumnByIndex,
  getIndexById,
  getTypeById,
  normalizeDataFilters,
  normalizeHeader,
  setSizeTable,
} from '../../features/network/filtration'
import { useHandler } from '../../hooks'
import {
  initDataFilter,
  DEFAULT_COLS,
  MAX_COLS,
  MAX_ROWS,
  typeNumber,
  typeText,
  operationForText,
  typeBoolean,
} from './constant'
import {
  TableBody,
  TableHeader,
} from './components'

// изменение ширины таблици по данным
const buildHeader = (colSpan, header, setHeader) => {
  if (colSpan > header.length) {
    const newHeader = [ ...header ]
    for (let i = header.length; i < colSpan; i++) {
      newHeader.push({}) // наращиваем количество столбцов
    }
    setHeader && setHeader(newHeader)
  } else if (colSpan < header.length) {
    const newHeader = [ ...header ]
    newHeader.length = colSpan // обрезаем количество столбцов
    setHeader && setHeader(newHeader)
  }
}

const separator = /\r*\n/

const pastClipboard = (event, dataFilter, indexCol, indexRow) => {
  const types = event.clipboardData.types
  if (types.includes('text/plain')) {
    const clipboard = event.clipboardData.getData('text/plain')
    const rows = clipboard.split(separator)
    // фильтрация вставляемого блока на одинаковые строки
    const cells = rows
      .filter((row, index, all) => (index ? all.lastIndexOf(row, index - 1) === -1 : true))
      .map((row) => row.split('\t'))
    const pastCol = cells.reduce((maxCol, row) => (maxCol <= row.length ? row.length : maxCol), 0)
    const amountCols = Math.min(MAX_COLS, pastCol + indexCol)
    const amountRows = Math.min(MAX_ROWS, rows.length + indexRow)
    if (amountRows === 1 && amountCols === 1) { // вставка в саму ячейку
      return
    }
    const newDataFilter = dataFilter.slice()
    newDataFilter.forEach((row) => {
      for (let i = row.length; i < amountCols; i++) { row.push({}) }
    })
    for (let i = newDataFilter.length; i < amountRows; i++) {
      newDataFilter.push((new Array(Math.max(amountCols, newDataFilter?.[0]?.length ?? 0))).fill({}))
    }

    cells.length = amountRows - indexRow
    cells.forEach((row, rowAdd) => {
      row.length = amountCols - indexCol
      row.forEach((cell, colAdd) => {
        newDataFilter[indexRow + rowAdd][indexCol + colAdd] = {
          comparisonOperator: '=',
          ...(newDataFilter[indexRow + rowAdd][indexCol + colAdd] ?? {}),
          value: cell,
        }
      })
      newDataFilter[indexRow + rowAdd] = newDataFilter[indexRow + rowAdd].slice()
    })
    return newDataFilter
  }
}

const TableSpecialFiltering = ({ columnsSettings, specialFilters, oldFilters, notDisplayColumns }) => {
  const [ dataFilter, setDataFilter ] = useState(JSON.parse(initDataFilter))
  const [ header, setHeader ] = useState([ {}, {}, {}, {}, {} ])

  // список опций для выбора записей таблиц
  const optionsHeader = useMemo(() => {
    const notDisplayFields = Array.isArray(notDisplayColumns) ? notDisplayColumns : []
    const columns = Array.isArray(columnsSettings)
      ? columnsSettings
        .filter((column) => {
          const { id, hidden } = column
          return !(hidden || notDisplayFields.includes(id))
        })
        .map((item) => {
          const { id: key, label: text } = item
          return { key, text }
        })
      : []
    return [
      { key: 'Column', text: 'Name column', itemType: SelectableOptionMenuItemType.Header },
      ...columns,
    ]
  }, [ columnsSettings, notDisplayColumns ])

  // разборка раннее применённого простого фильтра
  useEffect(() => {
    if (!oldFilters || !columnsSettings) { return }
    const headerColumns = []
    const newDataFilters = []
    const { comparisonRules } = oldFilters

    if (Array.isArray(comparisonRules)) {
      // перебираем строки фильтра - OR
      comparisonRules.forEach((filters) => {
        const row = []
        // разбор фильтра по AND
        filters.forEach((condition) => {
          const {
            columnIndex,
            comparisonOperator,
            value,
            type,
          } = condition
          const { label: text, id } = getColumnByIndex(columnsSettings, columnIndex)
          // определяем количество повторов столбца
          let numberOfTwins = 0
          row.forEach((item) => {
            if (item.columnId === id) {
              numberOfTwins++
            }
          })
          // находим N столбец в заголовке
          let numberHeader = headerColumns.findIndex((headerItem) => {
            if (headerItem.key === id) {
              if (numberOfTwins === 0) {
                return true
              }
              numberOfTwins--
            }
            return false
          })
          if (numberHeader === -1) {
            // подходящей колонки нет, добавляем колонку в заголовок
            numberHeader = headerColumns.length
            headerColumns.push({
              key: id,
              text,
              columnIndex: getIndexById(columnsSettings, id),
              type: getTypeById(columnsSettings, id),
            })
          }
          row[numberHeader] = { columnId: id, comparisonOperator, value, type }
        })
        newDataFilters.push(row)
      })
    }
    // добавляем свободную строку и столбец
    const sizeHeader = headerColumns.length
    for (let i = sizeHeader; i < Math.max(sizeHeader + 1, DEFAULT_COLS); i++) {
      headerColumns.push({})
    }
    newDataFilters.push(new Array(headerColumns.length))
    normalizeDataFilters(newDataFilters)
    setHeader(headerColumns)
    setDataFilter(newDataFilters)
  }, [ oldFilters, columnsSettings ])

  // генерация спецфильтра по заголовку и содержимому таблицы
  useEffect(() => {
    if (specialFilters && dataFilter && header) {
      specialFilters.current = { columns: header, filter: dataFilter, inversion: false }
    }
  }, [ dataFilter, header, specialFilters ])

  // выбор фильтруемого поля основной таблицы данных
  const onChangeTHFilter = useHandler((option, indexColumn) => {
    if (!columnsSettings || !dataFilter || !header) { return }
    const newHeader = header.map((field, indexField) => {
      const oldType = getTypeById(columnsSettings, field.key)
      const newType = getTypeById(columnsSettings, option.key)
      if (indexColumn === indexField) {
        // смена фильтруемой записи
        if ((typeText.includes(newType) || typeBoolean.includes(newType)) && typeNumber.includes(oldType)) {
        // тип данных изменился с числа на текст или булевый
          const newDataFilter = dataFilter.map((row) => (
            row.map((cell, index) => {
              if (index !== indexColumn && operationForText.includes(cell?.comparisonOperator)) {
                return cell
              }
              // операция сравнения не подходит для текстового поля
              return { ...(cell ?? {}), comparisonOperator: '=' }
            })
          ))
          setDataFilter(newDataFilter)
        }
        return {
          ...option,
          columnIndex: getIndexById(columnsSettings, option.key),
          type: newType,
        }
      }
      return field
    })
    setHeader(newHeader)
  })

  // изменение фильтра в ячейке таблицы
  const changeFilter = useHandler((value, indexRow, indexCol) => {
    if (!Number.isInteger(indexRow + indexCol)) { return }
    let newDataFilter = dataFilter.map((row, indexR) => (
      indexRow !== indexR
        ? row
        : row.map((cell, indexC) => (indexC === indexCol ? { ...cell, ...value } : cell))
    ))
    // normalizeDataFilters(newDataFilter)
    newDataFilter = setSizeTable(newDataFilter, header, setHeader)
    setDataFilter(newDataFilter)
  })

  const onClearColumn = useHandler((indexHeader) => {
    let newDataFilter = dataFilter.map((row) => (
      row.map((cell, indexColumn) => (indexColumn === indexHeader ? { comparisonOperator: '=', value: null } : cell))
    ))
    newDataFilter = setSizeTable(newDataFilter, header, setHeader)
    setDataFilter(newDataFilter)
  })

  const onClearTable = useHandler(() => {
    const headerColumns = normalizeHeader([], DEFAULT_COLS)
    const newDataFilters = []
    newDataFilters.push(new Array(DEFAULT_COLS))
    normalizeDataFilters(newDataFilters)
    setHeader(headerColumns)
    setDataFilter(newDataFilters)
  })

  const tableSizeCheck = useHandler(() => {
    // const newDataFilter = setSizeTable(dataFilter, header, setHeader)
    // if (newDataFilter !== dataFilter) { setDataFilter(newDataFilter) }
  })

  const onPaste = useHandler((event, indexRow, indexCol) => {
    const newDataFilter = pastClipboard(event, dataFilter, indexCol, indexRow)
    if (Array.isArray(newDataFilter)) {
      buildHeader(newDataFilter[0]?.length ?? DEFAULT_COLS, header, setHeader)
      setDataFilter(newDataFilter)
      event.preventDefault()
    }
  })

  const onCopy = useHandler((event) => {
    const columnC = header.map((column) => {
      return column.text ?? ''
    }).join('\t')
    const dataC = dataFilter.map((row) => {
      return row.map((item) => {
        return `${item?.value ?? ''}`
      }).join('\t')
    }).join('\n')
    event.view.navigator.clipboard.writeText(columnC + '\n' + dataC)
    event.preventDefault()
  })

  const onNextCellHandler = useHandler(() => {

  })
  const colSpan = dataFilter[0]?.length

  return (
    <div style={{ overflow: 'auto', maxHeight: 'calc(100vh - 280px)' }}>
      <table className={'filters locked-header'}>
        <TableHeader
          onCopy={onCopy}
          header={header}
          colSpan={colSpan}
          optionsHeader={optionsHeader}
          onChangeTHFilter={onChangeTHFilter}
          onClearTable={onClearTable}
          onClearColumn={onClearColumn}
        />
        <TableBody
          header={header}
          changeFilter={changeFilter}
          dataFilter={dataFilter}
          onPaste={onPaste}
          tableSizeCheck={tableSizeCheck}
          onNextCell={onNextCellHandler}
        />
      </table>
    </div>
  )
}

export default TableSpecialFiltering
