import React, { useCallback, useRef, memo } from 'react'
import PropTypes from 'prop-types'
import { HotTable } from '@handsontable/react'
import { useDispatch } from 'react-redux'
import {
  getProjectSettings,
  saveSettingToServer,
  settingPropertyOnPath,
  SETTINGS_KEYS,
} from '../../../features/settings/settingsSlice'

import {
  drawCheckboxInRowHeaders,
  addClassesToRows,
  changeCheckboxCell,
} from '../../utils/hooksCallbacksTable'

import {
  encodeSorting,
  getTextWidth,
  MIN_WIDTH_COLUMN,
  PADDING_DEFAULT,
  WIDTH_COLUMN_DEFAULT,
  WIDTH_SORTING,
} from '../../common/utils/hotTable'

function deepEqual (obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

const SETTINGS = {
  colHeaders: true,
  hiddenColumns: {
    columns: [ 1 ],
    indicators: false,
  },
  rowHeaders: true,
  filters: true,
  readOnly: false,
  fillHandle: false,
  dropdownMenu: false,
  rowHeights: 24,
  copyPaste: false,
  manualColumnResize: true,
  manualColumnMove: false,
  currentRowClassName: 'currentRow',
  height: '100%',
  autoColumnSize: false,
  outsideClickDeselects: false,
  autoWrapCol: false,
  columnSorting: true,
  licenseKey: 'non-commercial-and-evaluation',
}

// переключение сортировки при нажатии в любом месте зашголовка
// function beforeOnCellMouseDown (e, c) {
//   if (c.row === -1) {
//     e.stopImmediatePropagation()
//     if (e?.target?.nodeName === 'BUTTON') { return }
//     const column = this.toPhysicalColumn && this.toPhysicalColumn(c.col)
//     const srt = this.getPlugin('columnSorting')
//     if (this.toPhysicalColumn && srt?.getSortConfig && column >= 0) {
//       const column = this.toPhysicalColumn(c.col)
//       const prevOrder = srt.getSortConfig(column)?.sortOrder
//       const sortOrder = prevOrder === 'asc' ? 'desc' : prevOrder === 'desc' ? undefined : 'asc'
//       srt.sort(sortOrder && { column, sortOrder })
//     }
//   }
// }

const UsersGrid = memo(
  ({
    dataType,
    columns,
    onChangeCheck,
    refHot,
    handlingContextMenu,
    prefixColumnId,
    settingsVersion,
    isFullViewHeader,
  }) => {
    const dispatch = useDispatch()
    const clickMouse = useRef({})

    const afterColumnSort = useCallback((currentSortConfig, destinationSortConfigs) => {
      const table = refHot?.current?.hotInstance
      if (dataType == null || (currentSortConfig.length === 0 && destinationSortConfigs.length === 0)) {
        return
      }
      if (table && !table.isDestroyed) {
        const config = destinationSortConfigs[0]
        let fixedConfig
        if (config) {
          const column = table.toPhysicalColumn(config.column)
          fixedConfig = {
            ...config,
            column,
          }
        }
        const colSorting = dispatch(getProjectSettings(SETTINGS_KEYS.COL_SORTING, dataType))
        if (JSON.stringify(fixedConfig) !== JSON.stringify(colSorting)) {
          dispatch(settingPropertyOnPath(fixedConfig, [ SETTINGS_KEYS.COL_SORTING, dataType ]))
          dispatch(saveSettingToServer)
        }
      }
    }, [ refHot, dataType, dispatch ])

    const beforeColumnResize = useCallback((newSize, column) => {
      const table = refHot?.current?.hotInstance
      const plug = table.getPlugin('ManualColumnResize')
      if (table && isFullViewHeader && Array.isArray(columns)) {
        const physicalColumn = table.toPhysicalColumn(column)
        const { title } = columns[physicalColumn]
        const width = Math.round(Math.max(
          newSize,
          getTextWidth(title, dataType) + PADDING_DEFAULT + WIDTH_SORTING, MIN_WIDTH_COLUMN,
        ))
        plug.newSize = width
        plug.setManualSize(column, width)
      }
    }, [ isFullViewHeader, columns, dataType, refHot ])

    const afterColumnResize = useCallback((width, column) => {
      const table = refHot?.current?.hotInstance
      if (table) {
        const physicalColumn = table.toPhysicalColumn(column)
        const columnId = columns[physicalColumn].id
        if (columnId) {
          const path = prefixColumnId ? `${prefixColumnId}.${columnId}` : columnId
          dispatch(settingPropertyOnPath(width, [ SETTINGS_KEYS.COL_WIDTHS, path ]))
          dispatch(saveSettingToServer)
        }
      }
    }, [ dispatch, prefixColumnId, columns, refHot ])

    const genSettings = useCallback(() => {
      const colWidths = dispatch(getProjectSettings(SETTINGS_KEYS.COL_WIDTHS))
      const colSorting = dispatch(getProjectSettings(SETTINGS_KEYS.COL_SORTING, dataType))
      const columnsBuild = (columns ?? []).map(({ id, title, ...settings }) => {
        const widthsId = `${prefixColumnId ? `${prefixColumnId}.` : ''}${id}`
        const width = isFullViewHeader
          ? Math.max(
            colWidths[widthsId], getTextWidth(title, dataType) + PADDING_DEFAULT + WIDTH_SORTING, MIN_WIDTH_COLUMN)
          : colWidths[id] ?? Math.max(getTextWidth(title, dataType) + PADDING_DEFAULT, WIDTH_COLUMN_DEFAULT)
        return { id, data: id, title, ...settings, width }
      })
      return {
        ...SETTINGS,
        ...encodeSorting(colSorting),
        columns: columnsBuild,
        hiddenColumns: {
          columns: [ 0, 1 ],
          indicators: false,
        },
      }
    }, [ dataType, columns, prefixColumnId, dispatch, isFullViewHeader ])

    const settings = genSettings(settingsVersion)

    const mouseDblClick = useCallback(function (e, c) {
      if ((e.timeStamp - clickMouse.current?.t < 500)) {
        if (deepEqual(clickMouse.current?.c, c)) {
          handlingContextMenu('open')
        }
        clickMouse.current = null
      } else {
        clickMouse.current = { c, t: e.timeStamp }
      }
    }, [ clickMouse, handlingContextMenu ])

    const afterChange = useCallback((e, source) => {
      if (source === 'loadData') {
        return
      }
      onChangeCheck && onChangeCheck()
      return true
    }, [ onChangeCheck ])

    return (
      <div style={{ height: 'calc(100% - 45px)' }}>
        <HotTable
          id={dataType}
          className="users-table"
          settings={settings}
          ref={refHot}
          // contextMenu={contextMenuMemo}
          // beforeOnCellMouseDown={beforeOnCellMouseDown}
          afterColumnResize={afterColumnResize}
          afterColumnSort={afterColumnSort}
          afterGetRowHeader={drawCheckboxInRowHeaders}
          afterOnCellMouseDown={function (e, c, ht) {
            changeCheckboxCell.call(this, e, c)
            mouseDblClick.call(this, e, c, ht)
          }}
          beforeRenderer={addClassesToRows}
          afterChange={afterChange}
          readOnlyCellClassName="read-only-cell"
          beforeColumnResize={beforeColumnResize}
        />
      </div>
    )
  },
)

UsersGrid.displayName = 'UsersGrid'

UsersGrid.propTypes = {
  // data: PropTypes.array,
  onChangeCheck: PropTypes.func,
  refHot: PropTypes.object,
  prefixColumnId: PropTypes.string,
  dataType: PropTypes.string,
  columns: PropTypes.array,
  handlingContextMenu: PropTypes.func,
}

export default UsersGrid
