import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import Data, { buildIndex, findIndex } from '../network/indexing'
import { convertToTableDate, addTRFDataArray, PERIOD_MONTH, TO_FIXED } from './utils'
import * as Headers from './headers'

const initialState = {
  name: 'TRF',
  loading: false,
  saving: false,
  error: false,
  fields: null,
  data: new Data(),
  updatedValues: null,
  loadedView: null,
}

const loading = (state) => {
  state.loading = true
}

const saving = (state) => {
  state.saving = true
}

const doLoadTRFData = createAsyncThunk(
  'TRF/getTRFData',
  api.trf.getTRFData,
)

const doSaveTRFData = createAsyncThunk(
  'TRF/saveTRFData',
  api.trf.saveTRFData,
)

const roundValue = (value) => {
  const result = Number.parseFloat(value.toFixed(TO_FIXED))
  if (result > -0.0001 && result < 0.0001) {
    return 0
  }
  return result
}

export const editTRFField = createAsyncThunk(
  'TRF/editTRFField',
  (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const { row, fieldIndx } = payload
    let { prevValue, value } = payload
    value = value ? Number(value) : 0
    prevValue = prevValue ? Number(prevValue) : 0
    const full = selectTRFData(state)
    const current = full.getList()[row]
    const fields = selectTRFFields(state)
    const [ dateTotalFieldIndx, dataTotalFieldIndx, voiceTotalFieldIndx, a1mTotalFieldIndx ] = selectTRFFldIdx(state)
    const fieldId = fields[fieldIndx].id
    current[fieldIndx] = value || 0
    if (fieldIndx > dateTotalFieldIndx) {
      let diff = value || 0
      if (prevValue) {
        diff -= prevValue
      }
      if (fieldIndx < dataTotalFieldIndx) {
        current[dataTotalFieldIndx] = roundValue((current[dataTotalFieldIndx] || 0) + diff)
      } else if (fieldIndx < voiceTotalFieldIndx) {
        current[voiceTotalFieldIndx] = roundValue((current[voiceTotalFieldIndx] || 0) + diff)
      } else if (fieldIndx < a1mTotalFieldIndx) {
        current[a1mTotalFieldIndx] = roundValue((current[a1mTotalFieldIndx] || 0) + diff)
      }
    }
    return { row, date: current[dateTotalFieldIndx], fieldId, value, current }
  },
)

export const editTRFFields = createAsyncThunk(
  'TRF/editTRFFields',
  (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const changes = payload
    const full = selectTRFData(state)
    const fields = selectTRFFields(state)
    const [ dateTotalFieldIndx, dataTotalFieldIndx, voiceTotalFieldIndx, a1mTotalFieldIndx ] = selectTRFFldIdx(state)
    const totalColumns = [ dataTotalFieldIndx, voiceTotalFieldIndx, a1mTotalFieldIndx ]
    const updatedRows = []
    changes.forEach(({ id, updates }) => {
      const idx = full.findIndexById(id)
      updates.forEach(({ field, value, oldValue }) => {
        if (field > dateTotalFieldIndx && !totalColumns.includes(field)) {
          const current = full.getList()[idx]
          const prevValue = oldValue // current[field]
          let numberValue = Number(value)
          numberValue = isNaN(numberValue) ? 0 : numberValue
          current[field] = numberValue
          let diff = numberValue
          if (prevValue) {
            diff -= prevValue
          }
          if (field < dataTotalFieldIndx) {
            current[dataTotalFieldIndx] = roundValue((current[dataTotalFieldIndx] || 0) + diff)
          } else if (field < voiceTotalFieldIndx) {
            current[voiceTotalFieldIndx] = roundValue((current[voiceTotalFieldIndx] || 0) + diff)
          } else if (field < a1mTotalFieldIndx) {
            current[a1mTotalFieldIndx] = roundValue((current[a1mTotalFieldIndx] || 0) + diff)
          }
          updatedRows.push({
            row: idx,
            date: id,
            fieldId: fields[field].id,
            value,
            current,
          })
        }
      })
    })

    return updatedRows
  },
)

export const trfSlice = createSlice({
  name: 'TRF',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTRFColumns: (state, action) => {
      state.columns = action.payload
    },
    setTRFData: (state, action) => {
      state.data = action.payload
    },
    addUpdatedValues: (state, action) => {
      if (!action.payload) {
        state.updatedValues = null
      } else {
        state.updatedValues = {
          ...(state.updatedValues || {}),
          ...action.payload,
        }
      }
    },
    setUpdatedValues: (state, action) => {
      state.updatedValues = action.payload
    },
    removeLastRow: (state) => {
      const current = state.data.getList().pop()
      const dateFieldIndx = findIndex(state.fields, Headers.TRF_DATE_FIELD)
      const currentDate = current[dateFieldIndx]
      if (!state.updatedValues) {
        state.updatedValues = {}
      }
      state.updatedValues[currentDate] = null
      state.loading = false
      state.ready = true
    },
  },
  extraReducers: (builder) => builder
    .addCase(doLoadTRFData.pending, loading)
    .addCase(doLoadTRFData.fulfilled, (state, action) => {
      if (!state.fields) {
        state.fields = Headers.headers('')
      }
      const activeView = action?.meta?.arg
      const tableData = convertToTableDate(state.fields, action.payload, activeView)
      state.data = new Data(tableData, buildIndex({
        fields: state.fields,
        data: tableData,
      }, Headers.TRF_DATE_FIELD))
      state.loadedView = activeView
      state.loading = false
      state.ready = true
    })
    .addCase(doSaveTRFData.pending, saving)
    .addCase(doSaveTRFData.fulfilled, (state) => {
      state.saving = false
      state.error = false
      state.ready = true
    })
    .addCase(doSaveTRFData.rejected, (state) => {
      state.saving = false
      state.error = true
      state.ready = true
    })
    .addCase(editTRFField.pending, loading)
    .addCase(editTRFField.fulfilled, (state, action) => {
      const { row, date, fieldId, value, current } = action.payload
      state.data.getList()[row] = current
      if (!state.updatedValues) {
        state.updatedValues = {}
      }
      if (!state.updatedValues[date]) {
        state.updatedValues[date] = {}
      }
      state.updatedValues[date][fieldId] = Number.parseFloat(value)
      state.loading = false
      state.ready = true
    })
    .addCase(editTRFFields.pending, loading)
    .addCase(editTRFFields.fulfilled, (state, action) => {
      const updates = action.payload
      updates.forEach(({ row, date, fieldId, value, current }) => {
        state.data.getList()[row] = current
        if (!state.updatedValues) {
          state.updatedValues = {}
        }
        if (!state.updatedValues[date]) {
          state.updatedValues[date] = {}
        }
        state.updatedValues[date][fieldId] = Number.parseFloat(value)
      })
      state.loading = false
      state.ready = true
    }),
})

export const {
  setLoading,
  setTRFData,
  setLoadedView,
  addUpdatedValues,
  setUpdatedValues,
  removeLastRow,
} = trfSlice.actions

export const selectTRFData = (state) => state.trf.data

export const selectTRFFields = (state) => state.trf.fields

export const selectLoading = (state) => state.trf.loading

export const selectSaving = (state) => state.trf.saving

export const selectError = (state) => state.trf.error

export const selectUpdatedValues = (state) => state.trf.updatedValues

export const selectLoadedView = (state) => state.trf.loadedView

export const selectTRFFldIdx = (state) => {
  const fields = selectTRFFields(state)
  return [
    findIndex(fields, Headers.TRF_DATE_FIELD),
    findIndex(fields, Headers.TRF_DATA_TOTAL_FIELD),
    findIndex(fields, Headers.TRF_VOICE_TOTAL_FIELD),
    findIndex(fields, Headers.TRF_A1M_TOTAL_FIELD),
  ]
}

export const loadTRFData = (activeView) => async (dispatch, getState) => {
  const currentState = getState()
  if (!selectLoading(currentState)) {
    await dispatch(doLoadTRFData(activeView))
  }
  return currentState.data
}

export const saveTRFData = (activeView) => async (dispatch, getState) => {
  const currentState = getState()
  if (selectSaving(currentState)) {
    return false
  }
  const updatedValues = selectUpdatedValues(currentState)
  const data = Object.keys(updatedValues).reduce((agg, dateString) => {
    const modValues = updatedValues[dateString]
    if (modValues === null) {
      agg[dateString] = null
    } else {
      Object.keys(modValues).forEach((id) => {
        const value = modValues[id]
        const ids = id.split('.')
        if (ids.length === 4) {
          const [ section, paramName ] = ids.splice(2, 2)
          if (!agg[dateString]) {
            agg[dateString] = {}
          }
          if (!agg[dateString][section]) {
            agg[dateString][section] = {}
          }
          agg[dateString][section][paramName] = value
        }
      })
    }
    return agg
  }, {})
  await dispatch(doSaveTRFData({ period: activeView, data }))

  const error = selectError(getState())
  return !error
}

export const addNewRow = (activeView) => async (dispatch, getState) => {
  const currentState = getState()
  const full = selectTRFData(currentState)
  const list = full.getList()
  const fields = selectTRFFields(currentState)
  const dateFieldIndx = findIndex(fields, Headers.TRF_DATE_FIELD)
  const lastDate = list[list.length - 1][dateFieldIndx]
  const nextDate = lastDate ? new Date(lastDate) : new Date()
  const isMonth = activeView === PERIOD_MONTH
  if (lastDate) {
    if (isMonth) {
      nextDate.setMonth(nextDate.getMonth() + 1)
    } else {
      nextDate.setFullYear(nextDate.getFullYear() + 1)
    }
  }
  addTRFDataArray(isMonth, nextDate, 1, list)
  dispatch(setTRFData(new Data(list, buildIndex({
    fields,
    data: list,
  }, Headers.TRF_DATE_FIELD))))
}

const checkDateValue = (value, isMonth) => {
  const regex = isMonth ? /^[0-9]{4}-[0-9]{2}$/ : /^[0-9]{4}$/
  if (regex.test(value)) {
    const array = value.split('-')
    return array[0] > 1900 && (array.length > 1 ? array[1] <= 12 : !isMonth)
  }
  return false
}

export const importTRF = (activeView, payload) => async (dispatch, getState) => {
  const currentState = getState()
  dispatch(setLoading(true))
  const isMonth = activeView === PERIOD_MONTH
  const fields = selectTRFFields(currentState)
  const updatedValuesFromState = selectUpdatedValues(currentState)
  const full = selectTRFData(currentState)
  const list = full.getList()

  const { cols, rows } = payload

  const dateColumn = cols.findIndex((col) => col?.id === Headers.TRF_DATE_FIELD)
  const [ dateFieldIndx, dataTotalFiledIndx, voiceTotalFieldIndx, aimTotalFieldIndx ] = selectTRFFldIdx(currentState)

  let created = 0
  let updated = 0
  let ignored = 0
  const serverError = 0

  const updatedValues = { ...(updatedValuesFromState || {}) }

  rows && rows.forEach((row) => {
    const date = row[dateColumn]
    if (!date) {
      return
    }
    let rowIndex = list.findIndex((item) => {
      return item[dateFieldIndx] === date
    })
    let changed = false
    if (rowIndex >= 0) {
      cols.forEach((col, index) => {
        const ind = findIndex(fields, col.id)
        let newValue = (ind > dateFieldIndx) ? Number.parseFloat(row[index]) : row[index]
        newValue = ind > dateFieldIndx && isNaN(newValue) ? 0 : newValue
        if (list[rowIndex][ind] !== newValue) {
          changed = true
          list[rowIndex][ind] = newValue
          if (!col.id.includes('total')) {
            if (!updatedValues[date]) {
              updatedValues[date] = {}
            }
            updatedValues[date] = {
              ...updatedValues[date],
              [col.id]: newValue,
            }
          }
        }
      })
      if (changed) {
        updated++
      }
    } else if (!checkDateValue(date, isMonth)) {
      ignored++
    } else {
      const size = list.length
      addTRFDataArray(isMonth, date, 1, list)
      cols.forEach((col, index) => {
        const ind = findIndex(fields, col.id)
        let newValue = (ind > dateFieldIndx) ? Number.parseFloat(row[index]) : row[index]
        newValue = ind > dateFieldIndx && isNaN(newValue) ? 0 : newValue
        list[size][ind] = newValue
        if (!col.id.includes('total')) {
          if (!updatedValues[date]) {
            updatedValues[date] = {}
          }
          updatedValues[date][col.id] = newValue
        }
      })
      rowIndex = size
      changed = true
      created++
    }

    if (changed) {
      list[rowIndex][dataTotalFiledIndx] = list[rowIndex][dataTotalFiledIndx - 1] +
          list[rowIndex][dataTotalFiledIndx - 2] +
          list[rowIndex][dataTotalFiledIndx - 3] +
          list[rowIndex][dataTotalFiledIndx - 4]
      list[rowIndex][voiceTotalFieldIndx] = list[rowIndex][voiceTotalFieldIndx - 1] +
          list[rowIndex][voiceTotalFieldIndx - 2] +
          list[rowIndex][voiceTotalFieldIndx - 3] +
          list[rowIndex][voiceTotalFieldIndx - 4] +
          list[rowIndex][voiceTotalFieldIndx - 5]
      list[rowIndex][aimTotalFieldIndx] = list[rowIndex][aimTotalFieldIndx - 1] +
          list[rowIndex][aimTotalFieldIndx - 2] +
          list[rowIndex][aimTotalFieldIndx - 3] +
          list[rowIndex][aimTotalFieldIndx - 4] +
          list[rowIndex][aimTotalFieldIndx - 5] +
          list[rowIndex][aimTotalFieldIndx - 6]
    }
  })

  if (created > 0) {
    list.sort((a, b) => (a[dateColumn] > b[dateColumn]) ? 1 : ((b[dateColumn] > a[dateColumn]) ? -1 : 0))
    await dispatch(setTRFData(new Data(list, buildIndex({
      fields,
      data: list,
    }, Headers.TRF_DATE_FIELD))))
  }

  if (created > 0 || updated > 0) {
    dispatch(setUpdatedValues(updatedValues))
  }
  dispatch(setLoading(false))

  return {
    created,
    updated,
    ignored,
    serverError,
  }
}

export default trfSlice.reducer
