import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { addEventLog, EVENT_TYPE, STATUS_ERROR, STATUS_SUCCESS } from '../../features/eventLog/eventLogSlice'
import { AuthenticationError } from '../../api/rest'
import { useConfirm } from '../common/Confirm'

import './LocationLog.css'

const deleteDocumentCookieByName = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

export const LocationLog = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const unListen = history.listen((location, action) => {
      // location is an object like window.location
      dispatch(addEventLog(location.pathname, `${action} ${location.pathname}`, EVENT_TYPE.urlChanged, STATUS_SUCCESS))
    })
    return () => {
      unListen()
    }
  }, [ dispatch, history ])

  return null
}

export const ErrorHandling = () => {
  const dispatch = useDispatch()
  const { renderConfirm, msg } = useConfirm()

  useEffect(() => {
    const onRestError = (event) => {
      const pathName = event?.target?.location?.pathname ?? '?'
      if (event.error instanceof AuthenticationError) {
        const doRedirect = () => {
          deleteDocumentCookieByName('csrftoken')
          window.location.href = '/api/auth/login/form'
        }

        msg({ title: event.error.name, messages: [ event.error.message ], allowDismiss: false }, doRedirect)
        return
      }
      toast.error(event.message)
      event.error && console.error(event.error)
      dispatch(addEventLog(pathName, `${event.message ?? 'ERROR'}`, EVENT_TYPE.fetchError, STATUS_ERROR))
    }
    const onRestErrorMessage = (event) => {
      const pathName = event?.target?.location?.pathname ?? '?'
      toast.error(event.message, {
        duration: 8000,
        position: 'top-center',
        icon: '!',
        className: 'snc-error-message',
      })
      event.error && console.error(event.error)
      dispatch(addEventLog(pathName, `${event.message ?? 'ERROR MESSAGE'}`, EVENT_TYPE.fetchError, STATUS_ERROR))
    }
    window.addEventListener('resterror', onRestError)
    window.addEventListener('resterrormessage', onRestErrorMessage)
    return () => {
      window.removeEventListener('resterror', onRestError)
      window.removeEventListener('resterrormessage', onRestErrorMessage)
    }
  }, [ dispatch, msg ])

  useEffect(() => {
    const onInfoMessage = (event) => {
      toast.success(event.message)
    }
    window.addEventListener('infomessage', onInfoMessage)
    return () => {
      window.removeEventListener('infomessage', onInfoMessage)
    }
  }, [])

  return (
    <>
      {renderConfirm()}
    </>
  )
}
