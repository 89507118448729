import React, { useEffect, useCallback } from 'react'
import {
  ListPeoplePicker,
  ValidationState,
  PeoplePickerItemSuggestion,
  PeoplePickerItem,
} from '@fluentui/react/lib/Pickers'

import { KEYS } from './constants'

const suggestionProps = {
  suggestionsHeaderText: 'Suggested Users',
  mostRecentlyUsedHeaderText: 'Available Users',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading Users',
  // showRemoveButtons: true,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'suggested users',
}

const personaStyles = {
  root: {
    height: 'auto',
  },
  secondaryText: {
    height: 'auto',
    whiteSpace: 'normal',
  },
  primaryText: {
    height: 'auto',
    whiteSpace: 'normal',
  },
}

const ROLES = [ '', 'ROLE_GUEST', 'ROLE_USER', 'ROLE_USER+', 'ROLE_ADMIN' ]

const peopleProps = (user) => {
  return {
    key: user.id,
    // imageUrl: userProps.imageUrl,
    imageInitials: user.login?.[0].toUpperCase(), // Текст вместо иконки
    text: user.name,
    secondaryText: user.login,
    // isValid: userProps.isValid,
    presence: ROLES.findIndex((role) => role === user?.roles?.[0]), // влияет на цвет кружочка в углу иконки пользователя
  }
}

// Елемент списку вибраних користувачів
const onRenderItem = (ItemProps) => (
  <PeoplePickerItem
    {...ItemProps}
    item={peopleProps(ItemProps.item)}
  />
)

// Елемент списку вибору користувачів
const onRenderSuggestionItem = (userProps, suggestionsProps) => (
  <PeoplePickerItemSuggestion
    personaProps={{
      ...peopleProps(userProps),
      styles: personaStyles,
    }}
    suggestionsProps={suggestionsProps}
  />
)

const filteredChanged = (filterText, currentUsers, limitResults, users) => {
  if (filterText) {
    let filteredUsers = filterUsersByText(users, filterText)
    filteredUsers = removeDuplicates(filteredUsers, currentUsers)
    return limitResults ? filteredUsers.slice(0, limitResults) : filteredUsers
  }
  return limitResults ? users.slice(0, limitResults) : users
}

const UserPickerList = ({ userPickerRef, setSelectedUsers, users }) => {
  const [ mostRecentlyUsed, setMostRecentlyUsed ] = React.useState([])

  useEffect(() => { setMostRecentlyUsed(users) }, [ users ])

  const onItemsChange = useCallback((items) => {
    setSelectedUsers && setSelectedUsers(items)
  }, [ setSelectedUsers ])

  const onFilterChanged = useCallback((filterText, currentUsers, limitResults) => {
    return filteredChanged(filterText, currentUsers, limitResults, users)
  }, [ users ])

  const returnAvailableUsers = useCallback((currentUsers) => {
    return removeDuplicates(users, currentUsers)
  }, [ users ])

  // TODO Создать список ранее использовавшихся пользователей
  const onRemoveSuggestion = (item) => {
    const indexMostRecentlyUsed = mostRecentlyUsed.indexOf(item)

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedUsers = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1))
      setMostRecentlyUsed(newSuggestedUsers)
    }
  }

  return (
    <ListPeoplePicker
      onResolveSuggestions={onFilterChanged}
      onEmptyInputFocus={returnAvailableUsers}
      getTextFromItem={getTextFromItem}
      className={'ms-PeoplePicker'}
      pickerSuggestionsProps={suggestionProps}
      key={'list'}
      selectionAriaLabel={'Selected users'}
      removeButtonAriaLabel={'Remove'}
      onChange={onItemsChange}
      onRenderItem={onRenderItem}
      onRemoveSuggestion={onRemoveSuggestion}
      onRenderSuggestionsItem={onRenderSuggestionItem}
      onValidateInput={validateInput}
      componentRef={userPickerRef}
      resolveDelay={300}
      disabled={false}
    />
  )
}

// фильтрация списка пользователей по вводимому тексту
const filterUsersByText = (list, filterText) => {
  return list.filter((item) => doesTextWith(`${item.surname ?? ''} ${item.name ?? ''} ${item.login ?? ''}`, filterText))
}

function doesTextWith (text, filterText) {
  return text.toLowerCase().indexOf(filterText.toLowerCase()) >= 0
}

function removeDuplicates (users = [], selectedUsers) {
  return users.filter((user) => listNotContainsUser(user, selectedUsers))
}

function listNotContainsUser (user, selectedUsers) {
  if (!selectedUsers || !selectedUsers.length || selectedUsers.length === 0) {
    return true
  }
  return selectedUsers.findIndex((item) => item[KEYS.ID] === user[KEYS.ID]) < 0
}

function getTextFromItem (persona) {
  return `${persona.surname} ${persona.name} / ${persona.login}`
}

function validateInput () {
  return ValidationState.invalid // блокировка добавление несуществующих пользователей
}

export default UserPickerList
