import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@fluentui/react'
import Dropdown from '../../../common/Dropdown'
import { selectSettings, saveSettings } from '../../../../features/settings/settingsSlice'
import { selectNetworkTemplates } from '../../../../features/network/networkSlice'

const EMPTY_TEMPLATE = {
  key: '',
  text: '(None)',
}

const NetworkTemplates = () => {
  const dispatch = useDispatch()

  const networkTemplates = useSelector(selectNetworkTemplates)
  const currentTemplate = useSelector(selectSettings).networkTemplate || ''

  const templateOptions = useMemo(() => [
    EMPTY_TEMPLATE,
    ...networkTemplates.map(({ name }) => ({
      key: name,
      text: name,
    })),
  ], [ networkTemplates ])

  const onChangeTemplate = useCallback((event, value) => {
    dispatch(saveSettings({
      networkTemplate: value.key || null,
    }))
  }, [ dispatch ])

  return (
    <Stack style={{ marginTop: 8, paddingBottom: 20, gap: 8 }}>
      <Dropdown
        label="Network Template"
        options={templateOptions}
        defaultSelectedKey={currentTemplate}
        onChange={onChangeTemplate}
      />
    </Stack>
  )
}

export default NetworkTemplates
