/**
 *
 *  Base64 encode / decode
 *  http://www.webtoolkit.info
 *
 **/

const _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='

// private method for UTF-8 encoding
const _utf8Encode = (string) => {
  const utftext = []

  for (let n = 0; n < string.length; n++) {
    const c = string.charCodeAt(n)

    if (c === 0x0d && n + 1 < string.length && string.charCodeAt(n + 1) === 0x0a) {
      utftext.push('\n')
      n++
    } else if (c < 128) {
      utftext.push(String.fromCharCode(c))
    } else if ((c > 127) && (c < 2048)) {
      utftext.push(String.fromCharCode((c >> 6) | 192))
      utftext.push(String.fromCharCode((c & 63) | 128))
    } else {
      utftext.push(String.fromCharCode((c >> 12) | 224))
      utftext.push(String.fromCharCode(((c >> 6) & 63) | 128))
      utftext.push(String.fromCharCode((c & 63) | 128))
    }
  }

  return utftext.join('')
} // End Function _utf8_encode

// private method for UTF-8 decoding
const _utf8Decode = (utftext) => {
  const string = []
  let i = 0
  let c, c2, c3

  while (i < utftext.length) {
    c = utftext.charCodeAt(i)

    if (c < 128) {
      string.push(String.fromCharCode(c))
      i++
    } else if ((c > 191) && (c < 224)) {
      c2 = utftext.charCodeAt(i + 1)
      string.push(String.fromCharCode(((c & 31) << 6) | (c2 & 63)))
      i += 2
    } else {
      c2 = utftext.charCodeAt(i + 1)
      c3 = utftext.charCodeAt(i + 2)
      string.push(String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)))
      i += 3
    }
  }

  return string.join('')
} // End Function _utf8_decode

// public method for encoding
export const encode = (input) => {
  const output = []
  let chr1, chr2, chr3, enc1, enc2, enc3, enc4
  let i = 0

  input = _utf8Encode(input)

  while (i < input.length) {
    chr1 = input.charCodeAt(i++)
    chr2 = input.charCodeAt(i++)
    chr3 = input.charCodeAt(i++)

    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63

    if (isNaN(chr2)) {
      enc3 = enc4 = 64
    } else if (isNaN(chr3)) {
      enc4 = 64
    }

    output.push(`${_keyStr.charAt(enc1)}${_keyStr.charAt(enc2)}${_keyStr.charAt(enc3)}${_keyStr.charAt(enc4)}`)
  }

  return output.join('')
} // End Function encode

// public method for decoding
export const decode = (input) => {
  let output = ''
  let chr1, chr2, chr3
  let enc1, enc2, enc3, enc4
  let i = 0

  input = input.replace(/[^A-Za-z0-9+/=]/g, '')
  while (i < input.length) {
    enc1 = _keyStr.indexOf(input.charAt(i++))
    enc2 = _keyStr.indexOf(input.charAt(i++))
    enc3 = _keyStr.indexOf(input.charAt(i++))
    enc4 = _keyStr.indexOf(input.charAt(i++))

    chr1 = (enc1 << 2) | (enc2 >> 4)
    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2)
    chr3 = ((enc3 & 3) << 6) | enc4

    output = output + String.fromCharCode(chr1)

    if (enc3 !== 64) {
      output = output + String.fromCharCode(chr2)
    }

    if (enc4 !== 64) {
      output = output + String.fromCharCode(chr3)
    }
  }

  output = _utf8Decode(output)

  return output
} // End Function decode
