import { useCallback, useEffect, useMemo } from 'react'
import L from 'leaflet'
import { shallowEqual, useSelector } from 'react-redux'
import { useBoolean } from '@fluentui/react-hooks'
import hash from 'object-hash'
import { formLegendSection } from '../../render'
import { selectLegendGeo } from '../../../../features/geo/geoSlice'
import { selectLegendNetwork } from '../../../../features/network/networkSlice'
import { selectLegendVector } from '../../../../features/vector/vectorSlice'
import { iconSvgLine } from '../../../common/icons'
import { HiddenDropdownIcon, OpenDropdownIcon } from '../../../common/icons/names'

let lastLegendHash = null
let lastSelected = null
let lastExpanded = null

const Legend = ({ map }) => {
  const [ expanded, { toggle: toggleExpanded } ] = useBoolean(true)

  const legendGeo = useSelector(selectLegendGeo, shallowEqual)
  const legendNetwork = useSelector(selectLegendNetwork, shallowEqual)
  const legendVector = useSelector(selectLegendVector, shallowEqual)

  const content = useMemo(() => [ ...legendGeo.content, ...legendNetwork.content, ...legendVector.content ],
    [ legendGeo, legendNetwork, legendVector ])

  const contentHash = useMemo(() => hash(content), [ content ])

  const stopPropagation = useCallback((e) => {
    e.stopPropagation()
  }, [])

  useEffect(() => {
    if (lastLegendHash === contentHash && lastSelected === legendGeo?.state?.selected && lastExpanded === expanded) {
      return
    }
    if (map.legend) {
      map.legend.remove()
      delete map.legend
    }
    if (legendGeo?.state?.selected) {
      map.legend = L.control({ position: 'bottomleft' })
      map.legend.onAdd = function () {
        const div = L.DomUtil.create('div', 'info legend')
        if (expanded) {
          const contentDiv = L.DomUtil.create('div', '', div)
          for (const item of content) {
            contentDiv.innerHTML += formLegendSection(item)
          }
          contentDiv.style.overflowY = 'auto'
          contentDiv.style.maxHeight = 'calc(100vh - 160px)'
        }
        const caption = L.DomUtil.create('div', 'caption', div)
        caption.innerHTML = '<span>Legend</span>'
        caption.innerHTML += iconSvgLine({
          name: expanded ? HiddenDropdownIcon : OpenDropdownIcon,
          className: 'dropdown-icon',
        })
        caption.onclick = toggleExpanded
        div.id = 'legend'
        div.addEventListener('contextmenu', stopPropagation)
        return div
      }
      map.legend.addTo(map)
      L.DomEvent.disableClickPropagation(L.DomUtil.get('legend'))
      L.DomEvent.disableScrollPropagation(L.DomUtil.get('legend'))
    }
    lastLegendHash = contentHash
    lastSelected = legendGeo?.state?.selected
    lastExpanded = expanded
  }, [ map, legendGeo?.state?.selected, content, contentHash, expanded, toggleExpanded, stopPropagation ])

  return null
}

export default Legend
