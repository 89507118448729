import React, { useCallback, useMemo } from 'react'
import ChoiceGroup from '../../../common/ChoiceGroup'
import { buildClassList } from '../../../utils/classes'

import './MapLayerButton.css'

const SAMPLE_ZOOM = 9
const SAMPLE_CENTER = [ 30.5, 50.5 ]
const DEFAULT_SUBDOMAINS = [ 'a', 'b', 'c' ]
const FAKE_CHOICE = [ { key: 'on', text: '' } ]

/**
 * Convert WGS-84 longitude, latitude (EPSG:4326) and zoom level to a tile index (in EPSG:3857).
 * @param lng - longitude
 * @param lat - latitude
 * @param zoom - zoom level
 * @returns [ x, y ] - tile index
 */
const tileIndex = (lng, lat, zoom) => {
  const x = (lng + 180) / 360
  const y = (1 - Math.log(Math.tan(Math.PI / 4 + lat * Math.PI / 180 / 2)) / Math.PI) / 2
  return [
    Math.floor(x * Math.pow(2, zoom)),
    Math.floor(y * Math.pow(2, zoom)),
  ]
}

const sampleTileUrl = (patternUrl, subdomains = DEFAULT_SUBDOMAINS) => {
  const [ x, y ] = tileIndex(SAMPLE_CENTER[0], SAMPLE_CENTER[1], SAMPLE_ZOOM)
  return patternUrl
    .replace('{z}', SAMPLE_ZOOM)
    .replace('{x}', x)
    .replace('{y}', y)
    .replace('{s}', subdomains[0])
}

const MapLayerButton = ({ index, substance, onClick }) => {
  const tileUrl = useMemo(() => sampleTileUrl(substance.url, substance.subdomains), [ substance ])
  const pressed = useMemo(() => substance.state?.selected, [ substance ])

  const handleClick = useCallback(() => onClick(substance), [ substance, onClick ])

  return (
    <button
      className={buildClassList('map-layer-button', { pressed })}
      onClick={pressed ? undefined : handleClick}
    >
      <img src={tileUrl} alt={substance.name} className="map-layer-button-sample" />
      <div className="map-layer-button-caption">
        <span className="map-layer-button-index">Layer #{index + 1}</span>
        <span className="map-layer-button-name">{substance.name}</span>
      </div>
      {pressed && (
        <ChoiceGroup className="map-layer-button-choice-group" defaultSelectedKey="on" options={FAKE_CHOICE} />
      )}
    </button>
  )
}

export default MapLayerButton
