export const DEGREE_DECIMAL_SIGN = '-xx.xxxxxx'
export const DEGREE_DECIMAL_LETTER = 'xx.xxxxxxS'
export const DEGREE_MINUTE_SECOND_ICON = 'xx°xx\'xx.xxx"S'
export const DEGREE_MINUTE_SECOND_LETTER = 'xxdxxmxx.xxxsS'

export const COORDINATES_ENCODINGS = [
  DEGREE_DECIMAL_SIGN, DEGREE_DECIMAL_LETTER, DEGREE_MINUTE_SECOND_ICON, DEGREE_MINUTE_SECOND_LETTER,
].map((key) => ({ key, text: key }))

export const ID_GEO = 'geo'
export const ID_GEO_SUBSTANCES = `${ID_GEO}-substances`
export const ID_GEO_VECTOR = `${ID_GEO}-vector`
export const ID_GEO_LEGEND = `${ID_GEO}-legend`
export const ID_GEO_ZONES = `${ID_GEO}-zones`
export const ID_GEO_USER_MAPS = `${ID_GEO}-user-maps`
export const ID_GEO_ZONES_FILTERING = `${ID_GEO_ZONES}-filtering`
export const ID_GEO_ZONES_FOCUS = `${ID_GEO_ZONES}-focus`
export const ID_GEO_ZONES_COMPUTATION = `${ID_GEO_ZONES}-computation`

export const MID_MIF_EXT = [ 'mid', 'mif' ]

export const INDEX_ZONE_FILTERING = 0
export const INDEX_ZONE_COMPUTATION = 1
export const INDEX_ZONE_FOCUS = 2

export const MAX_LEGEND_ITEMS = 1000

export const ZONES = [
  {
    id: ID_GEO_ZONES_FILTERING,
    name: 'Filtering Zone',
  },
  {
    id: ID_GEO_ZONES_COMPUTATION,
    name: 'Computation Zone',
  },
  {
    id: ID_GEO_ZONES_FOCUS,
    name: 'Focus Zone',
    allowMany: true,
  },
]

export const CLUSTER_RADIUS = 200
