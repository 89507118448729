import React, { memo, useEffect, useState } from 'react'
import {
  Checkbox,
  ComboBox, DirectionalHint,
  Stack,
} from '@fluentui/react'
import { IconButton } from '@fluentui/react/lib/Button'
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu'
import { useHandler } from '../../hooks'
import {
  clearFilterIcon,
  clipboardIcon,
  iconButtonClass, isBoolean, isTrue, menuItems, menuItemsText, typeBoolean, typeDate, typeNumber, typeText,
} from './constant'

const SelectFields = ({ value, options, onChange }) => {
  return <ComboBox
    title={value.text ?? ''}
    selectedKey={value.key ?? null}
    options={options}
    autoComplete="on"
    allowFreeform
    onChange={(e, o) => {
      if (!o) { return }
      onChange && onChange(o)
    }}
  />
}

export const THFilter = ({ col, index, optionsHeader, onChange, onClearClick, className }) => {
  const onClearClickHandler = useHandler(() => onClearClick(index))
  const onChangeHandler = useHandler((option) => onChange(option, index))
  return <th key={index} className={className}>
    <Stack horizontal>
      <IconButton
        title={'Clear column'}
        onClick={onClearClickHandler}
        iconProps={clearFilterIcon}
        className={iconButtonClass}
      />
      <SelectFields
        value={col ?? {}}
        options={optionsHeader}
        onChange={onChangeHandler}
      />
    </Stack>
  </th>
}

export const THClear = ({ onClick, className, colSpan = 1 }) => {
  return <th className={className} colSpan={colSpan}>
    <IconButton
      title={'Clear filter table'}
      onClick={onClick}
      iconProps={clearFilterIcon}
      className={iconButtonClass}
    />
  </th>
}

export const THClipboard = ({ onClick, className, colSpan = 1 }) => {
  return <th className={className} colSpan={colSpan}>
    <IconButton
      title={'Copy filter to clipboard'}
      onClick={onClick}
      iconProps={clipboardIcon}
      className={iconButtonClass}
    />
  </th>
}

// eslint-disable-next-line no-unused-vars
export const Check = ({ className, indeterminate, checked, onChange, id }) => {
  useEffect(() => {
    const checkBox = document.querySelector(`input[id="${id}"]`)
    if (checkBox.indeterminate !== indeterminate) {
      checkBox.indeterminate = indeterminate
    }
  }, [ id, indeterminate ])

  return (
    <input
      id={id}
      style={{ height: 'auto' }}
      type="checkbox"
      className={className}
      checked={checked}
      onChange={onChange}
    />
  )
}

const OperationMenu = ({ onClose, onItemClick, optionsMenu }) => {
  const { target, type } = optionsMenu ?? {}
  return <ContextualMenu
    title={'Comparison rules'}
    directionalHint={DirectionalHint.bottomLeftEdge}
    alignTargetEdge={true}
    items={ typeNumber.includes(type) || typeDate.includes(type)
      ? menuItems
      : typeText.includes(type) || typeBoolean.includes(type)
        ? menuItemsText
        : []
    }
    target={target}
    onItemClick={onItemClick}
    onDismiss={onClose}
  />
}

export const TD = memo(({
  item,
  type,
  onChange,
  onPaste,
  indexRow,
  indexCol,
  onNextCell,
}) => {
  const comparisonOperator = item?.comparisonOperator ?? '='
  const [ value, setValue ] = useState(item?.value)
  const [ operationMenu, setOperationMenu ] = useState({})
  const onClickOperationMenu = useHandler((e, indexRow, indexCol) => {
    setOperationMenu({
      target: e.target.parentElement,
      cell: [ indexRow, indexCol ],
      type: type,
    })
  })

  useEffect((value) => { if (value !== item.value) { setValue(item?.value) } }, [ item?.value ])

  const onChangeCheckboxHandler = useHandler(() => {
    const newValue = getThirdState(value)
    setValue(newValue)
    onChange({ value: newValue }, indexRow, indexCol)
  }, [ item, onChange ])

  const onClickOperationMenuHandler = useHandler((e) => onClickOperationMenu(e, indexRow, indexCol))

  const onChangeHandler = useHandler((e) => {
    setValue(e.target.value)
  })

  const onBlurHandler = useHandler(() => {
    onChange({ value }, indexRow, indexCol)
    // return onBlur(e)
  })

  // Вибір правила порівняння
  const selectComparisonRuleHandler = useHandler((e, item) => {
    onChange({ comparisonOperator: item.value }, indexRow, indexCol)
  })

  const onKeyDownHandler = useHandler((e) => {
    if (e.code === 'Enter' || e.code === 'NumEnter') {
      e.preventDefault()
      onChange({ value }, indexRow, indexCol)
      onNextCell && onNextCell(indexRow, indexCol)
    }
    if (e.code === 'Esc') {
      e.preventDefault()
      setValue(item.value)
    }
  })

  const onPasteHandler = useHandler((e) => onPaste(e, indexRow, indexCol))

  const onCloseMenuHandler = useHandler(() => {
    setOperationMenu(null)
  })

  return (
    <td>
      <div className={'filter-input'}>
        <span
          onClick={onClickOperationMenuHandler}
          className="Input__left"
          style={{ backgroundColor: '#EEE' }}
        >
          <b>{comparisonOperator === '<>' ? '\u2260' : comparisonOperator }</b>
        </span>
        {type === 'boolean'
          ? (
              <Checkbox
                className={`width120 check-box ${!isBoolean(item?.value) ? 'checkbox-intermediate' : ''}`}
                label={`${!isBoolean(value) ? '' : isTrue(value) ? 'TRUE' : 'FALSE'}`}
                indeterminate={!isBoolean(value)}
                checked={isTrue(value)}
                onChange={onChangeCheckboxHandler}
              />
            )
          : (
              <input
                onKeyDown={onKeyDownHandler}
                className={'width120'}
                value={value ?? ''}
                onBlur={onBlurHandler}
                onChange={onChangeHandler}
                onPaste={onPasteHandler}
              />
            )
        }
      </div>
      {operationMenu
        ? (
            <OperationMenu
              onClose={onCloseMenuHandler}
              onItemClick={selectComparisonRuleHandler}
              optionsMenu={operationMenu}
            />
          )
        : (
            <></>
          )
      }
    </td>
  )
})

TD.displayName = 'TDF'

const getThirdState = (item) => {
  if (item == null) {
    return true
  } else if (item) {
    return false
  }
  return undefined
}
