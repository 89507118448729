import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Stack, StackItem, DetailsList, DetailsListLayoutMode, DetailsRow, CheckboxVisibility } from '@fluentui/react'
import { DialogFooter } from '@fluentui/react/lib/Dialog'
import { SelectionMode } from '@fluentui/utilities'
import { EVENT_TYPE, saveEventLog } from '../../../features/eventLog/eventLogSlice'
import { DefaultButton, PrimaryButton } from '../../common/Button'
import { getCssVar } from '../../../utils/css'
import { tableToExcel } from '../../../utils/export'
import { useConfirm } from '../../common/Confirm'
import { confirm as tConfirm } from '../../common/Confirm/constants'
import { COLUMNS_UPDATE_BC } from '../../../constants/updateDB'
import { alwaysTrue, getItemKey } from '../../../utils/format'
import Header from './HeaderSelect'
import Footer from './Footer'
import TD from './TDUniversal'
import { updateTableStyles } from './styles'

import './style.css'

const stylesOddRow = {
  root: {
    backgroundColor: getCssVar('--CTA-secondary-pressed'),
  },
}

const defRenderItemGroup = (item, index, column) => {
  return (<div>
    {column.fieldName === COLUMNS_UPDATE_BC.SITE
      ? item[COLUMNS_UPDATE_BC.SECTOR_NAME]
      : column.fieldName === COLUMNS_UPDATE_BC.STATUS ? item[COLUMNS_UPDATE_BC.TECHNOLOGY] : ''
    }
  </div>)
}

const defGroupFieldName = 'sectors'

const Component = ({
  id,
  onCancel,
  columns: columnsInit,
  changeCheckBoxHeader,
  items: itemsInit,
  footer,
  onUpdateItem,
  onApply,
  groupFieldName = defGroupFieldName, // Поле, що містить дані групування
  onRenderItemGroup = defRenderItemGroup, // Рендер рядка елемента групи
}) => {
  const dispatch = useDispatch()
  const { renderConfirm, confirm } = useConfirm()

  const [ columns, setColumns ] = useState()
  const [ items, setItems ] = useState([])

  useEffect(() => {
    setItems(itemsInit ?? [])
  }, [ itemsInit ])

  useEffect(() => {
    setColumns(columnsInit)
  }, [ columnsInit ])

  const changeSortingHandler = useCallback((column) => {
    const {
      isSorted,
      isSortedDescending,
    } = column ?? {}
    const newColumns = columns.slice()
    let currentColumn = null
    newColumns.forEach((currCol) => {
      if (currCol.key === column.key) {
        currentColumn = currCol
        if (!isSorted) {
          currCol.isSorted = true
          currCol.isSortedDescending = false
        } else if (!isSortedDescending) {
          currCol.isSorted = true
          currCol.isSortedDescending = true
        } else {
          currCol.isSorted = false
        }
      } else {
        currCol.isSorted = false
        currCol.isSortedDescending = false
      }
    })
    setColumns(newColumns)
    if (currentColumn && currentColumn.isSorted) {
      setItems(copyAndSort(items, column.key, currentColumn?.isSortedDescending))
    } else {
      setItems(copyItemsInit(itemsInit, items))
    }
  }, [ columns, items, itemsInit ])

  const renderDetailsHeader = useCallback((props) => (
    <Header
      props={props}
      changeCheckBox={changeCheckBoxHeader}
      changeSorting={changeSortingHandler}
      checkBoxDisabled={items.length === 0}
    />
  ), [ changeCheckBoxHeader, changeSortingHandler, items ])

  const renderDetailsFooter = useCallback((props) => (
    <Footer
      props={props}
      onApply={onApply}
      footer={footer}
    />
  ), [ footer, onApply ])

  // Рендер комірки таблиці
  const onRenderItemColumn = useCallback((item, index, column) => (
    <TD
      item={item}
      index={index}
      column={column}
      onUpdateItem={onUpdateItem}
    />
  ), [ onUpdateItem ])

  // Рендер рядка таблиці
  const onRenderRow = useCallback((props) => {
    if (props) {
      const { groupVisible, [groupFieldName]: groupData } = props.item
      const isOutGroup = groupVisible && Array.isArray(groupData) && groupData?.length
      return (
        <>
          <DetailsRow
            {...props}
            styles={props.itemIndex % 2 === 0 ? {} : stylesOddRow}
            onRenderItemColumn={onRenderItemColumn}
          />
           {isOutGroup // Розгортаємо список групування
             ? groupData.map((item, index) => (
               <DetailsRow
                 key={index}
                 {...props}
                 item={item}
                 styles={props.itemIndex % 2 === 0 ? {} : stylesOddRow}
                 onRenderItemColumn={onRenderItemGroup ?? defRenderItemGroup}
               />
             ))
             : null
           }
        </>
      )
    }
    return null
  }, [ groupFieldName, onRenderItemColumn, onRenderItemGroup ])

  const convertSectorValue = useCallback((columnName, sector, emptyValue) => {
    switch (columnName) {
      case 'siteName': return sector.name
      case 'status': return sector.technology
      default: return emptyValue
    }
  }, [])

  const tableToClipboard = useCallback((e, columns, items) => {
    const columnC = columns.map((column) => {
      return column.data?.isChecked != null ? `check ${column.name}\t${column.name}` : column.name
    }).join('\t')
    const dataC = items.reduce((agg, row) => {
      agg.push(columns.map((column) => {
        const checked = column.data?.isChecked != null ? (row.select === column.fieldName ? 'X\t' : '\t') : null
        return `${checked != null ? checked : ''}${row[column.fieldName]?.toString()}`
      }).join('\t'))
      if (row.groupVisible && row.sectors?.length > 0) {
        row.sectors.forEach((sector) => {
          agg.push(columns.map((column) => convertSectorValue(column.fieldName, sector, '\t')).join('\t'))
        })
      }
      return agg
    }, []).join('\n')
    e.view.navigator.clipboard.writeText(columnC + '\n' + dataC)
    e.preventDefault()
  }, [ convertSectorValue ])

  const onCopyToClipboard = useCallback((e) => {
    tableToClipboard(e, columns, items)
    dispatch(saveEventLog(EVENT_TYPE.updateDBExport, `: Clipboard, Page "${id}", ${items.length} lines`))
    confirm(
      null,
      {
        title: 'Export',
        messages: [ 'Table data copied to clipboard.' ],
        textNoBtn: tConfirm.ok,
      },
    )
  }, [ columns, confirm, items, dispatch, id, tableToClipboard ])

  const onExportToXLSX = useCallback(() => {
    const headers = columns.map((column) => {
      return column.data?.isChecked != null ? [ `Check ${column.name}`, column.name ] : column.name
    }).flat()
    const cells = items.reduce((agg, row) => {
      agg.push(columns.map((column) => {
        const checked = column.data?.isChecked != null ? (row.select === column.fieldName ? 'X' : '') : null
        const item = row[column.fieldName]?.toString()
        return checked != null ? [ checked, item ] : item
      }).flat())
      if (row.groupVisible && row.sectors?.length > 0) {
        row.sectors.forEach((sector) => {
          agg.push(columns.map((column) => convertSectorValue(column.fieldName, sector, '')))
        })
      }
      return agg
    }, [])
    tableToExcel(headers, cells, alwaysTrue, id, `${id}.xlsx`)
    dispatch(saveEventLog(EVENT_TYPE.updateDBExport, `: Excel file, Page "${id}", ${items.length} lines`))
  }, [ columns, id, items, dispatch, convertSectorValue ])

  return (
    <Stack style={{ height: '100%' }}>
      <StackItem className={'toContentViewport'}>
        <DetailsList
          className="grouped-table"
          items={items}
          compact={true}
          columns={columns}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selectionMode={SelectionMode.multiple}
          onRenderDetailsHeader={renderDetailsHeader}
          onRenderDetailsFooter={renderDetailsFooter}
          onRenderRow={onRenderRow}
          getKey={getItemKey}
          setKey={id}
          styles={updateTableStyles}
          checkboxVisibility={CheckboxVisibility.hidden}
        />
      </StackItem>
      <DialogFooter>
        <DefaultButton onClick={onCopyToClipboard} text="Export to Clipboard" />
        <DefaultButton onClick={onExportToXLSX} text="Export to MS Excel file" />
        <PrimaryButton onClick={onCancel} text="Close" />
      </DialogFooter>
      {renderConfirm()}
    </Stack>
  )
}

export default Component

function copyAndSort (items, columnKey, isSortedDescending) {
  return items.slice(0)
    .sort((a, b) => ((isSortedDescending ? a[columnKey] < b[columnKey] : a[columnKey] > b[columnKey]) ? 1 : -1))
}

function copyItemsInit (itemsInit, items) {
  return itemsInit.map((itemInit) => (items.find((item) => (item.key === itemInit.key)))).filter(Boolean)
}
