import { v4 as uuid } from 'uuid'
import union from '@turf/union'
import { polygon } from '@turf/helpers'
import { DATA_TYPES } from '../../constants/common'
import { checkPolygonIsCorrect, toPolygon, toTurf } from '../../components/Map/utils'

export const ID_FIELD = 'Id'
export const NAME_FIELD1 = 'Name_U'
export const NAME_FIELD2 = 'Name'
export const COLOR_FIELD1 = 'Color'
export const COLOR_FIELD2 = 'Line-Color'

export const prepareFields = (fields, idIdx) => fields.map((field, index) => ({
  ...field,
  hidden: index === idIdx,
  editable: index !== idIdx,
}))

export const getDataType = (id) => `${DATA_TYPES.MAP_ATTRIBUTES}-${id}`

export const isVectorMap = (dataType) => `${dataType ?? ''}`.split('-')[0] === DATA_TYPES.MAP_ATTRIBUTES

export const getIdByDataType = (dataType) => {
  const [ , ...id ] = `${dataType ?? ''}`.split('-')
  return id.join('-')
}

export const combineMultiPolygons = (list) => list
  .map(([ zone ]) => zone)
  .filter(checkPolygonIsCorrect)
  .reduce((acc, item) => union(acc, toPolygon(toTurf(item))), polygon([]))
  .geometry.coordinates

export const convertFeatureToMultiPolygon = (nameProp) => (feature = {}) => {
  const { geometry: { type, coordinates } = {}, properties = {} } = feature
  if (type !== 'Polygon' && type !== 'MultiPolygon') {
    return null
  }
  return [
    coordinates,
    {
      description: properties[nameProp],
    },
    uuid(),
  ]
}

export const generateColumnsVectorMap = (dataType, fields = []) => {
  const ids = new Set()
  return fields.map((field) => {
    let id = field.id
    let n = 0
    while (ids.has(id) && n < 100) {
      n++
      id = `${field.id}.${n}`
    }
    ids.add(id)
    return {
      ...field,
      id: `${dataType}.${id}`,
    }
  })
}
