import { v4 as uuid } from 'uuid'
import moment from 'moment'
import { DATE_FORMAT } from '../../components/Tables/utils'
import { findIndex } from '../network/indexing'
import {
  BC_NAME_FIELD,
  BC_SELECTED_FIELD,
  BC_EX_STATUS_FIELD,
  BC_ID_FIELD,
  BC_DATE_REVENUE_FIELD,
  BC_DATE_CAPEX_FIELD,
  BC_SITE_FIELD,
  BC_SITE_PROJECT_ID_FIELD,
  BC_SITE_ID_FIELD,
  BC_CALC_METHOD_FIELD,
  BC_CASE_DURATION_FIELD,
  BC_CASE_TYPE_FIELD,
  BC_SITE_TYPE_FIELD,
  BC_EDRPOU_FIELD,
  BC_ASSOCIATION_INCOME_FIELD,
  BC_REVENUE_TREND_FIELD,
  BC_TOWERCO_FIELD,
  BC_TOWER_CO_RENT_FIELD,
  CALC_STATUS_MANUAL_REVENUE,
  BC_NEIGHBORS_FIELD,
  EXISTING_STATUS_ID,
} from './constants'

export const dateToString = (date) => {
  const month = date.getMonth() + 1
  return `${month < 10 ? '0' : ''}${month}/${date.getFullYear()}`
}

export const getEnumDefValue = (fields, id, index = 0) => {
  const fieldIndex = findIndex(fields, id)
  const field = fields[fieldIndex]
  if (field?.type === 'enum' && field['enum-values']) {
    const options = field['enum-values'].split(',')
    if (options && options.length > index) {
      return options[index]
    }
  }
  return undefined
}

export const bcObjectToRow = (fields, isSelected = false) => {
  const current = moment().add(2, 'M')
  const currentDate = current.date()
  if (currentDate > 1) {
    current.add(1, 'M').startOf('month')
  }
  const startCAPEXDate = current.format('YYYY-MM-DD')
  current.add(2, 'M')
  const startRevenueDate = current.format('YYYY-MM-DD')
  const defaultValues = {
    [BC_SELECTED_FIELD]: isSelected,
    [BC_ID_FIELD]: uuid(),
    [BC_CALC_METHOD_FIELD]: getEnumDefValue(fields, BC_CALC_METHOD_FIELD),
    [BC_CASE_DURATION_FIELD]: 5,
    [BC_CASE_TYPE_FIELD]: getEnumDefValue(fields, BC_CASE_TYPE_FIELD),
    [BC_SITE_TYPE_FIELD]: getEnumDefValue(fields, BC_SITE_TYPE_FIELD, 5),
    [BC_DATE_CAPEX_FIELD]: startCAPEXDate,
    [BC_DATE_REVENUE_FIELD]: startRevenueDate,
    [BC_EDRPOU_FIELD]: '',
    [BC_ASSOCIATION_INCOME_FIELD]: false,
    [BC_REVENUE_TREND_FIELD]: getEnumDefValue(fields, BC_REVENUE_TREND_FIELD),
    [BC_TOWERCO_FIELD]: false,
    [BC_TOWER_CO_RENT_FIELD]: 5000,
  }
  return fields.map((field) => {
    return defaultValues[field.id] || null
  })
}

export const rowToBCObject = (row, fields, siteId, projectId) => {
  const nameIndx = findIndex(fields, BC_NAME_FIELD)
  return row.reduce((res, value, indx) => {
    if ((value !== undefined && value !== null) || indx === nameIndx) {
      const field = fields[indx]
      if (field) {
        const resValue = value
        if (field.id === BC_SITE_FIELD) {
          res[BC_SITE_ID_FIELD] = siteId || resValue
          res[BC_SITE_PROJECT_ID_FIELD] = projectId
        }

        if (field.id === BC_NEIGHBORS_FIELD) {
          res[BC_NEIGHBORS_FIELD] = resValue?.data || resValue
        } else {
          res[field.id] = resValue
        }
      }
    }
    return res
  }, {})
}

export const bcRowToCreateBCRow = (row) => {
  const [ selected, ...other ] = row
  return [ selected, EXISTING_STATUS_ID.SELECTED, ...other ]
}

export const getBCFields = (allFields) => {
  return [
    {
      id: BC_SELECTED_FIELD,
      label: 'Selected',
      description: 'Selected',
      type: 'boolean',
      size: null,
      page: 'Group Business Case',
      editable: true,
      hidden: false,
    },
    ...allFields.map((field) => {
      const type = field.type
      const result = {
        ...field,
        editable: false,
      }
      if (type === 'date') {
        result.dateFormat = DATE_FORMAT
        result.correctFormat = true
      }
      return result
    }),
  ]
}

export const getCreateBCFields = (allFields) => {
  return [
    {
      id: BC_SELECTED_FIELD,
      label: 'Select All',
      type: 'boolean',
      editable: true,
      page: 'Create Business Case',
    },
    {
      id: BC_EX_STATUS_FIELD,
      label: 'New/Existing',
      type: 'string',
      correctFormat: true,
      editable: false,
      page: 'Create Business Case',
    },
    // remove group and summary columns
    // show hidden columns for create BC
    // hide RBSS and Sectors
    ...allFields.map((item) => {
      const editable = item.editable
      const type = (item.id === BC_NAME_FIELD || item.id === BC_SITE_FIELD)
        ? 'scapexautocomplete'
        : (item.id === BC_REVENUE_TREND_FIELD ? 'enum' : item.type)
      const enumValues = (item.id === BC_CALC_METHOD_FIELD && item['enum-values'])
        ? item['enum-values'].split(',').filter((item) => item !== CALC_STATUS_MANUAL_REVENUE).join(',')
        : item['enum-values']
      const result = { ...item, type, editable, 'enum-values': enumValues }
      if (type === 'date') {
        result.dateFormat = DATE_FORMAT
        result.correctFormat = true
      }
      if (item.id === BC_SITE_FIELD) {
        result.strict = true
      }
      return result
    }),
  ]
}

export const parseToType = (type, value) => {
  let resValue = value
  if (type === 'boolean') {
    resValue = value === 'true'
  } else if (type === 'int') {
    resValue = Number.parseInt(value)
  } else if (type === 'double') {
    resValue = Number.parseFloat(value)
  }
  return resValue
}

const isNumber = (value) => {
  return typeof value === 'number' && !Number.isNaN(value)
}

export const checkLimits = (field, value) => {
  let result = true
  if (isNumber(field?.min) && field.min > value) {
    result = false
  }
  if (isNumber(field?.max) && field.max < value) {
    result = false
  }
  return result
}

export const getNeightborSectorsTitle = (value) => {
  const neighbors = new Set()
  let sectorsCount = 0
  const title = value && Object.keys(value)?.length > 0
    ? Object.keys(value).reduce((result, tech) => {
      const stringValue = Object.keys(value[tech])?.length > 0
        ? '\n' + Object.keys(value[tech]).reduce((res, sector) => {
          if (value[tech][sector]?.length > 0) {
            res += '\t' + sector + ': ' + value[tech][sector].join(', ') + '\n'
            if (value[tech][sector].length > 0) {
              value[tech][sector].forEach(neighbors.add, neighbors)
            }
          } else {
            res += '\t' + sector + ': No neighbors\n'
          }
          sectorsCount++
          return res
        }, '')
        : ': No sectors\n'
      if (stringValue) {
        result += `⦿ ${tech}${stringValue}`
      }
      return result
    }, '').trim()
    : 'No technologies'
  return {
    count: neighbors.size,
    title,
    sectorsCount,
  }
}
