import React, { useMemo, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import L from 'leaflet'
import { GeoJSON } from 'react-leaflet'
import {
  prepareCollection,
  selectVectorJSONs,
  selectVectorRedraw,
} from '../../../../features/vector/vectorSlice'
import { DEFAULT_SETTINGS_VECTOR_MAP, selectSettingsDisplay } from '../../../../features/settings/settingsSlice'
import { ELEMENT_TYPES } from '../../../Panels/Customization/constants'
import { determineColorByValue, determineLabelByValue } from '../../render'
import { drawVectorLabel as draw, midToColor } from '../../draw'
import { reverseLngLat } from '../../utils'
import IndicateVector from './IndicateVector'

const type = 'vectorMap'

const skip = (e) => {
  L.DomEvent.stopPropagation(e)
  L.DomEvent.preventDefault(e)
}

export const skipHoverEvents = {
  mouseover: skip,
  mouseout: skip,
}

const Vector = ({ map }) => {
  const vectorMapsData = useSelector(selectVectorJSONs, shallowEqual)
  const vectorMapsRedraw = useSelector(selectVectorRedraw)

  const vectorMaps = useMemo(() => (vectorMapsData.map((map) => (prepareCollection(map)))), [ vectorMapsData ])

  const { [ELEMENT_TYPES.VECTOR_MAPS]: vectorMapsSettings = {} } = useSelector(selectSettingsDisplay) || {}

  const styleGeoJSON = useMemo(() => (id) => (feature) => {
    if (feature) {
      const settings = vectorMapsSettings[id] ?? DEFAULT_SETTINGS_VECTOR_MAP
      const calculated = determineColorByValue(
        feature.id,
        feature.properties?.[settings.attribute?.toLowerCase()],
        settings,
      )
      let color = calculated || feature.properties?.color || feature.properties?.['line-color']
      if (color) {
        color = midToColor(color)
        return { color, fillColor: color }
      }
    }
  }, [ vectorMapsSettings ])

  useEffect(() => {
    map._vectorLabels = new L.MarkersCanvas({ type })
    map._vectorLabels.addTo(map)
    map._vectorLabels.setVisible(true)
  }, [ map ])

  // Ініціалізація рендеру
  useEffect(() => {
    if (map && map._vectorLabels && vectorMapsSettings) {
      map._vectorLabels.clear()
      const markers = []
      ;(vectorMaps ?? [])
        .filter(Boolean)
        .forEach((vectorMap) => {
          const settings = vectorMapsSettings[vectorMap.id] ?? DEFAULT_SETTINGS_VECTOR_MAP
          markers.push(vectorMap.features.map((feature) => {
            const { id } = feature
            if (!feature?.geometry?.coordinates?.length) {
              return null
            }
            const coords = reverseLngLat(feature.geometry.coordinates)
            const point = feature.geometry.type === 'Point'
              ? L.latLng(coords)
              : L.latLngBounds(coords).getCenter()
            const label = determineLabelByValue(
              feature.properties?.[settings.labeling?.attribute?.toLowerCase()],
              settings.labeling,
            )
            return L.marker(point, { id, type, label, draw })
          }))
        })
      const final = markers.flat().filter(Boolean)
      map._vectorLabels._map = map
      map._vectorLabels.addMarkers(final)
    }
  }, [ map, vectorMaps, vectorMapsSettings ])

  const filteredVectorMaps = useMemo(() => {
    return (vectorMaps ?? []).filter(Boolean).filter(({ id }) => id)
  }, [ vectorMaps ])

  return (
    <>
      {filteredVectorMaps.map((vectorMap) => (
        <GeoJSON
          key={`${vectorMapsRedraw}:${vectorMap.id}`}
          data={vectorMap}
          pane="overlayPane"
          style={styleGeoJSON(vectorMap.id)}
          eventHandlers={skipHoverEvents}
        />
      ))}
      <IndicateVector map={map}/>
    </>
  )
}

export default Vector
