import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Stack, StackItem } from '@fluentui/react'
import { selectTab, setTab } from '../../../features/panel/panelSlice'
import Pivot, { FW_TABS_2 } from '../../common/Pivot'
import { SecondaryButton, PrimaryButton } from '../../common/Button'
import VirtualizedTree from '../Network/VirtualizedTree'
import {
  checkTreeItem as checkTreeItemVector, expandTreeItem as expandTreeItemVector, menuItemClick as menuItemClickVector,
  selectVectorItems, selectVectorState,
  TOP_INDEX_VECTOR,
} from '../../../features/vector/vectorSlice'
import {
  checkTreeItem as checkTreeItemRaster, menuItemClick as menuItemClickRaster, selectRasterItems, selectRasterState,
  TOP_INDEX_RASTER,
} from '../../../features/raster/rasterSlice'
import { MenuButton } from '../../common/ContextMenu'
import {
  ComputationZoneIcon, ConvertIcon, DefaultMap, DeleteIcon, ExportIcon, FilteringZoneIcon, FocusZoneIcon, KMLIcon,
  KMZIcon, MIDMIFIcon, MoreIcon, PencilIcon, PlusIcon,
} from '../../common/icons/names'
import { selectUserAccess } from '../../../features/login/loginSlice'
import { DEFAULT_MAPS, EDIT_POLYGONS, IMPORT_MAPS } from '../../../constants/access'
import { EXPORT_FORMAT } from '../../../constants/menus'
import {
  selectIsDefaultProject, selectIsMyProject, selectIsSharedProject,
} from '../../../features/projects/projectsSlice'
import { selectOfflineMode } from '../../../features/loading/loadingSlice'
import { selectEditZone } from '../../../features/geo/geoSlice'
import { INDEX_ZONE_COMPUTATION, INDEX_ZONE_FILTERING } from '../../../constants/geo'
import { findNode } from '../../../utils/tree'

import './ImportedMaps.css'

const VECTOR_TAB = 'vector-maps'
const RASTER_TAB = 'raster-maps'

const ImportedMaps = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const tab = useSelector(selectTab) || VECTOR_TAB
  const vectorItems = useSelector(selectVectorItems)
  const rasterItems = useSelector(selectRasterItems)
  const vectorState = useSelector(selectVectorState)
  const rasterState = useSelector(selectRasterState)
  const isMyProject = useSelector(selectIsMyProject)
  const isDefaultProject = useSelector(selectIsDefaultProject)
  const isSharedProject = useSelector(selectIsSharedProject)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const editZone = useSelector(selectEditZone)
  const zones = useSelector((state) => state.geo.zones)
  const offlineMode = useSelector(selectOfflineMode)

  const filteringZonePresent = useMemo(() => zones.children[INDEX_ZONE_FILTERING].zone?.length > 0, [ zones ])
  const computationZonePresent = useMemo(() => zones.children[INDEX_ZONE_COMPUTATION].zone?.length > 0, [ zones ])

  const handleLinkClick = useCallback((item) => {
    dispatch(setTab(item.props.itemKey))
  }, [ dispatch ])

  const onSelectVectorNode = useCallback((path) => {
    dispatch(checkTreeItemVector(path))
  }, [ dispatch ])

  const onSelectRasterNode = useCallback((path) => {
    dispatch(checkTreeItemRaster(path))
  }, [ dispatch ])

  const onExpandVectorNode = useCallback((path) => {
    dispatch(expandTreeItemVector(path))
  }, [ dispatch ])

  const elementsShown = useMemo(() => {
    switch (tab) {
      case VECTOR_TAB: return vectorState.selected
      case RASTER_TAB: return rasterState.selected
      default: return false
    }
  }, [ tab, vectorState, rasterState ])

  const toggleElements = useCallback(() => {
    switch (tab) {
      case VECTOR_TAB: return dispatch(checkTreeItemVector([ TOP_INDEX_VECTOR ]))
      case RASTER_TAB: return dispatch(checkTreeItemRaster([ TOP_INDEX_RASTER ]))
      default:
    }
  }, [ dispatch, tab ])

  const importMap = useCallback(() => {
    let id
    switch (tab) {
      case VECTOR_TAB: {
        id = 'vector-file-input'
        break
      }
      case RASTER_TAB: {
        id = 'raster-file-input'
        break
      }
      default:
    }
    if (id) {
      document.getElementById(id).click()
    }
  }, [ tab ])

  const vectorMenuClick = useCallback((event, { path, key }) => {
    dispatch(menuItemClickVector(path, key, history))
  }, [ dispatch, history ])

  const rasterMenuClick = useCallback((event, { path, key }) => {
    dispatch(menuItemClickRaster(path, key))
  }, [ dispatch ])

  const vectorNodes = useMemo(() => vectorItems.map((item) => ({
    ...item,
    children: item.children?.map((child) => {
      const suffix = [ (
        <MenuButton
          key="suffix-vector-item"
          icon={MoreIcon}
          disabled={isSharedProject}
          items={[
            {
              key: 'convert-filtering-item',
              text: 'Convert to Filtering Zone',
              icon: FilteringZoneIcon,
              disabled: (!isMyProject && !isDefaultProject) || !item.state?.selected || editZone ||
                !userAccess[EDIT_POLYGONS],
              items: [
                {
                  key: 'convert-filtering-item-replace',
                  text: 'Replacing existing',
                  icon: ConvertIcon,
                  path: child.path,
                },
                {
                  key: 'convert-filtering-item-add',
                  text: 'Adding to existing',
                  icon: PlusIcon,
                  path: child.path,
                  disabled: !filteringZonePresent,
                },
              ],
            },
            {
              key: 'convert-computation-item',
              text: 'Convert to Computation Zone',
              icon: ComputationZoneIcon,
              disabled: (!isMyProject && !isDefaultProject) || !item.state?.selected || editZone ||
                !userAccess[EDIT_POLYGONS],
              items: [
                {
                  key: 'convert-computation-item-replace',
                  text: 'Replacing existing',
                  icon: ConvertIcon,
                  path: child.path,
                },
                {
                  key: 'convert-computation-item-add',
                  text: 'Adding to existing',
                  icon: PlusIcon,
                  path: child.path,
                  disabled: !computationZonePresent,
                },
              ],
            },
            {
              key: 'convert-focus-item',
              text: 'Convert to Focus Zone',
              icon: FocusZoneIcon,
              disabled: (!isMyProject && !isDefaultProject) || !item.state?.selected || editZone ||
                !userAccess[EDIT_POLYGONS],
              path: child.path,
            },
          ]}
          onItemClick={vectorMenuClick}
        />
      ) ]
      return child.type === 'Point' ? child : { ...child, suffix }
    }),
    suffix: [ (
      <MenuButton
        key="suffix-vector"
        icon={MoreIcon}
        items={[
          {
            key: 'delete',
            text: 'Delete',
            icon: DeleteIcon,
            path: item.path,
            disabled: !userAccess[IMPORT_MAPS] || (item.isDefault && !userAccess[DEFAULT_MAPS]) ||
              (!item.isDefault && isSharedProject),
          },
          {
            key: 'edit',
            text: 'Edit as Table',
            icon: PencilIcon,
            path: item.path,
            disabled: !userAccess[IMPORT_MAPS] || (item.isDefault && !userAccess[DEFAULT_MAPS]) ||
              (!item.isDefault && isSharedProject),
          },
          {
            key: 'make-default',
            text: 'Make it Default',
            icon: DefaultMap,
            path: item.path,
            disabled: !userAccess[IMPORT_MAPS] || item.isDefault || !userAccess[DEFAULT_MAPS],
          },
          {}, // Divider
          {
            key: 'export',
            text: 'Export',
            icon: ExportIcon,
            disabled: !item.state?.selected,
            items: [
              {
                key: EXPORT_FORMAT.MIF_MID,
                text: 'To MID/MIF',
                icon: MIDMIFIcon,
                path: item.path,
              },
              {
                key: EXPORT_FORMAT.KML,
                text: 'To KML',
                icon: KMLIcon,
                path: item.path,
              },
              {
                key: EXPORT_FORMAT.KMZ,
                text: 'To KMZ',
                icon: KMZIcon,
                path: item.path,
              },
            ],
          },
          {
            key: 'convert',
            text: 'Convert',
            icon: ConvertIcon,
            disabled: (!isMyProject && !isDefaultProject) || !item.state?.selected || editZone ||
              !userAccess[EDIT_POLYGONS] || item.containsOnlyPoints,
            items: [
              {
                key: 'convert-filtering',
                text: 'To Filtering Zone',
                icon: FilteringZoneIcon,
                path: item.path,
              },
              {
                key: 'convert-computation',
                text: 'To Computation Zone',
                icon: ComputationZoneIcon,
                path: item.path,
              },
              {
                key: 'convert-focus',
                text: 'To Focus Zones',
                icon: FocusZoneIcon,
                path: item.path,
              },
            ],
          },
        ]}
        onItemClick={vectorMenuClick}
      />
    ) ],
  })), [
    vectorItems, vectorMenuClick, userAccess, isMyProject, isDefaultProject, editZone, filteringZonePresent,
    computationZonePresent, isSharedProject,
  ])

  const onClickVectorNode = useCallback((path) => {
    const nodeId = findNode({ children: vectorNodes }, path.slice(1))?.id
    window.dispatchEvent(new CustomEvent('zoom-to-entity', { detail: { nodeId } }))
  }, [ vectorNodes ])

  const rasterNodes = useMemo(() => rasterItems.map((item) => ({
    ...item,
    suffix: [ (
      <MenuButton
        key="suffix-raster"
        icon={MoreIcon}
        items={[
          {
            key: 'delete',
            text: 'Delete',
            icon: DeleteIcon,
            path: item.path,
            disabled: item.isDefault ? !userAccess[DEFAULT_MAPS] : isSharedProject,
          },
          {
            key: 'make-default',
            text: 'Make it Default',
            icon: DefaultMap,
            path: item.path,
            disabled: item.isDefault || !userAccess[DEFAULT_MAPS],
          },
        ]}
        disabled={!userAccess[IMPORT_MAPS]}
        onItemClick={rasterMenuClick}
      />
    ) ],
  })), [ rasterItems, rasterMenuClick, userAccess, isSharedProject ])

  return (
    <Stack className="panel-stack">
      <StackItem grow className="panel-tree-container">
        <Pivot selectedKey={tab} onLinkClick={handleLinkClick} className={`${FW_TABS_2} imported-maps-pivot`}>
          <Pivot.Item headerText="Vector Maps" itemKey={VECTOR_TAB}>
            <VirtualizedTree
              searchPanel={false}
              nodes={vectorNodes}
              onClickNode={onClickVectorNode}
              onSelectNode={onSelectVectorNode}
              onExpandNode={onExpandVectorNode}
              selectable
            />
          </Pivot.Item>
          <Pivot.Item headerText="Raster Maps" itemKey={RASTER_TAB}>
            <VirtualizedTree
              searchPanel={false}
              nodes={rasterNodes}
              onClickNode={onSelectRasterNode}
              onSelectNode={onSelectRasterNode}
              selectable
            />
          </Pivot.Item>
        </Pivot>
      </StackItem>
      <StackItem className="panel-button-container">
        <SecondaryButton
          text={elementsShown ? 'Hide All Maps' : 'Show Maps'}
          onClick={toggleElements}
        />
        <PrimaryButton
          text="Import Map"
          onClick={importMap}
          disabled={!userAccess[IMPORT_MAPS] || isSharedProject || offlineMode}
        />
      </StackItem>
    </Stack>
  )
}

export default ImportedMaps
