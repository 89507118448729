import React, { useCallback, useMemo } from 'react'
import Handsontable from 'handsontable'
import { HotTable } from '@handsontable/react'
import { DEFAULT_COLORS, midToColor } from '../../../Map/draw'
import { DEFAULT_TECH, SECTOR_BASE } from '../../../../constants/network'
import { ELEMENT_TYPES } from '../constants'
import { SPREAD_DEFAULT, SPREAD_DISCRETE, SPREAD_RANGE, SPREAD_UNIQUE } from '../../../../constants/settings'
import COLORS from '../../../../constants/colors'
import { hash } from '../../../../utils/math'

import './Preview.css'

const SAMPLE_LIST_LIMIT = 1000
const ICON_CELL_WIDTH = 24
const ICON_CELL_HEIGHT = 48

const fillColorCell = (td, color) => {
  td.style.padding = 0
  td.innerHTML = `<div style="width:${ICON_CELL_WIDTH}px;height:${ICON_CELL_HEIGHT}px;background:${color}"></div>`
}

const siteRenderer = (hotInstance, td, row, column, prop, value) => {
  fillColorCell(td, value || DEFAULT_COLORS.SITE)
}

const sectorRenderer = (hotInstance, td, row, column, prop, value) => {
  const [ color ] = value?.split(',') || []
  fillColorCell(td, color || DEFAULT_COLORS.SECTOR)
}

const complaintRenderer = (hotInstance, td, row, column, prop, value) => {
  fillColorCell(td, value || DEFAULT_COLORS.COMPLAINT)
}

const vectorMapRenderer = (hotInstance, td, row, column, prop, value) => {
  fillColorCell(td, value)
}

Handsontable.renderers.registerRenderer('site', siteRenderer)
Handsontable.renderers.registerRenderer('sector', sectorRenderer)
Handsontable.renderers.registerRenderer('complaint', complaintRenderer)
Handsontable.renderers.registerRenderer('vectorMap', vectorMapRenderer)

const iconsColumn = (elementType) => ({
  key: 'icon',
  title: ' ',
  width: ICON_CELL_WIDTH,
  renderer: elementType === ELEMENT_TYPES.SITES
    ? 'site'
    : elementType === ELEMENT_TYPES.SECTORS
      ? 'sector'
      : elementType === ELEMENT_TYPES.COMPLAINTS
        ? 'complaint'
        : elementType === ELEMENT_TYPES.VECTOR_MAPS
          ? 'vectorMap'
          : undefined,
})

const defColumns = (elementType) => [
  iconsColumn(elementType),
  { key: 'value', title: 'Value', width: 325 },
]

const columns = (elementType, expanded) => ({
  [SPREAD_RANGE]: [
    iconsColumn(elementType),
    {
      key: 'min',
      title: 'Min',
      width: expanded ? 148 : 162,
      type: 'numeric',
      className: 'htRight',
      numericFormat: { pattern: { thousandSeparated: true } },
    },
    {
      key: 'max',
      title: 'Max',
      width: expanded ? 148 : 163,
      type: 'numeric',
      className: 'htRight',
      numericFormat: { pattern: { thousandSeparated: true } },
    },
    expanded ? { key: 'description', title: 'Description', width: 440 } : null,
  ].filter(Boolean),
  [SPREAD_DISCRETE]: defColumns(elementType),
  [SPREAD_UNIQUE]: defColumns(elementType),
  [SPREAD_DEFAULT]: defColumns(elementType),
})

const SETTINGS = {
  colHeaders: true,
  rowHeights: `${ICON_CELL_HEIGHT}px`,
  width: '100%',
  height: '100%',
  licenseKey: 'non-commercial-and-evaluation',
  numericFormat: {
    pattern: {
      thousandSeparated: true,
    },
  },
}

const prepareData = (element, mode, records, coloring, idIdx, nameIdx, colorIdx, techIdx, attribute) => {
  const { key: elementKey, type: elementType } = element
  const getColor = (color, row, value) => elementType === ELEMENT_TYPES.SECTORS
    ? `${color},${value || row?.[techIdx] || DEFAULT_TECH}`
    : color

  switch (mode) {
    case SPREAD_RANGE: {
      if (!attribute) {
        return [ [] ]
      }
      return (coloring?.table || [])
        .map(({ color, min, max, minValue, maxValue, description }) =>
          [
            getColor(color),
            Math.abs(minValue) >= 1000 ? min : minValue,
            Math.abs(maxValue) >= 1000 ? max : maxValue,
            description,
          ])
        .reverse()
    }
    case SPREAD_DISCRETE: {
      if (!attribute) {
        return [ [] ]
      }
      return (coloring?.table || [])
        .map(({ color, value }) => [ getColor(color), value ]).reverse()
    }
    case SPREAD_UNIQUE: {
      return records.slice(0, SAMPLE_LIST_LIMIT).map((row) =>
        [ getColor(COLORS[hash(row[idIdx].toString()) % 75], row), row[nameIdx] ]).reverse()
    }
    default: {
      return elementType === ELEMENT_TYPES.SITES
        ? records.slice(0, 1).map((row) => [ null, row[nameIdx] ])
        : elementType === ELEMENT_TYPES.SECTORS
          ? Object.keys(SECTOR_BASE).filter((key) => key.startsWith(elementKey))
            .map((key) => [ getColor('', null, key), key ])
          : elementType === ELEMENT_TYPES.COMPLAINTS
            ? records.slice(0, 1).map((row) => [ null, row[nameIdx] ])
            : elementType === ELEMENT_TYPES.VECTOR_MAPS
              ? records.slice(0, SAMPLE_LIST_LIMIT).map((row) => [ midToColor(row[colorIdx]), row[nameIdx] ])
              : [ [] ]
    }
  }
}

const Preview = ({ idIdx, nameIdx, colorIdx, techIdx, records, element, mode, coloring, attribute, expanded }) => {
  const data = useMemo(
    () => prepareData(element, mode, records, coloring, idIdx, nameIdx, colorIdx, techIdx, attribute),
    [ element, mode, records, coloring, idIdx, nameIdx, colorIdx, techIdx, attribute ],
  )

  const cols = useMemo(() => columns(element.type, expanded)[mode], [ element, expanded, mode ])

  const cellClasses = useCallback((row, col) => {
    const cp = {}
    if (col !== 0) {
      cp.className = 'truncate'
      if (mode === SPREAD_RANGE && (col === 1 || col === 2)) {
        cp.className += ' htRight'
      }
    }
    return cp
  }, [ mode ])

  return (
    <HotTable
      data={data}
      columns={cols}
      settings={SETTINGS}
      height={expanded ? '100%' : (data.length + 1) * (ICON_CELL_HEIGHT + 2) + 1}
      width={Math.max(Math.min(cols?.reduce((acc, { width }) => acc + width, 0), window.innerWidth * 0.8 - 448), 352)}
      readOnly
      cells={cellClasses}
    />
  )
}

export default Preview
