import React, { useMemo } from 'react'

import './Badge.css'
import Tooltip from 'react-tooltip-lite'

const Badge = ({ value, color, title, htmlTitle, className, children, shift, ...props }) => {
  const arrowContent = useMemo(() => (
    <div className='badge-tooltip-arrow'>
      <div className='badge-tooltip-arrow-inner' />
      <div className='badge-tooltip-arrow-icon' />
    </div>
  ), [])
  const renderBadge = useMemo(() => {
    const style = {}
    if (color) {
      style.background = color
    }
    if (shift) {
      style.left = `${shift}px`
    }
    return (value > 0 || typeof value === 'string') && (htmlTitle
      ? (
        <Tooltip className='capex-badge-position' arrowContent={arrowContent} content={htmlTitle}>
          <div className='capex-badge-item' style={style}>
            {value}
          </div>
        </Tooltip>
        )
      : (
          <div className='capex-badge-item capex-badge-position' style={style} title={title}>
            {value}
          </div>
        ))
  }, [ value, color, title, htmlTitle, shift, arrowContent ])

  return (
    <div
      className={className ? `capex-badge ${className}` : 'capex-badge'}
      {...props}
    >
      {children}
      {renderBadge}
    </div>
  )
}

export default Badge
