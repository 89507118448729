import React from 'react'
import { IconSvg } from '../icons'
import { buildClassList } from '../../utils/classes'

import './IconButton.css'

const IconButton = ({ icon, title, disabled, small, onClick, ...rest }) => (
  <button
    title={title}
    className={buildClassList('capex-icon-button', { disabled, small })}
    onClick={onClick}
    disabled={disabled}
    {...rest}
  >
    <IconSvg name={icon} />
  </button>
)

export default IconButton
