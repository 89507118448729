import { v4 as uuid } from 'uuid'
import {
  CAN_BE_REMOVED, NAME_PREFIX, RBS_SITE_FIELD, SECTOR_AZIMUTH_FIELD, SECTOR_BANDWIDTH_FIELD, SECTOR_CELL_CARRIERS_FIELD,
  SECTOR_ELECTRICAL_TILT_FIELD, SECTOR_HEIGHT_FIELD, SECTOR_MECHANICAL_TILT_FIELD, SECTOR_NAME_FIELD, SECTOR_RBS_FIELD,
  SITE_NAME_FIELD, STATUS_DRAFT, STATUS_REMOVED,
} from '../../constants/network'
import { activeProjectId, ensureUserProject, forceProjectReload } from '../projects/projectsSlice'
import { addChanges, selectOfflineMode, selectSavingChanges } from '../loading/loadingSlice'
import { addUndoRecord } from '../undo/undoSlice'
import { setPanel } from '../panel/panelSlice'
import { prepareSiteNames } from '../bc/bcSlice'
import {
  selectBaseStationFields, selectBaseStationFldIdx, selectBaseStationsFull,
  selectSectorFields, selectSectorFldIdx, selectSectorsFull,
  selectSiteFields, selectSiteFldIdx, selectSitesFull,
  generateNextSiteName, generateNextSectorName,
  updateBundle, formSiteBundle, setSectorEditor, requestSites, selectCurrentNetworkTemplate,
  setInUpdate, requestSitesBySiteNames,
} from './networkSlice'
import { checkConvertValue, revertBundle, nextSiteName, nextSectorName, newElementName, selectMaxName } from './utils'
import { findIndex } from './indexing'

export const ACTION_CREATE = 'CREATE'
export const ACTION_DELETE = 'DELETE'
export const ACTION_UPDATE = 'UPDATE'

export const saveBundles = (bundles, setUndo = true, reloadIfDefault = true) => async (dispatch, getState) => {
  const state = getState()
  const offlineMode = selectOfflineMode(state)
  const savingChanges = selectSavingChanges(state)
  const activeProjectId = await dispatch(ensureUserProject())
  if (activeProjectId === '_') {
    if (reloadIfDefault) {
      dispatch(forceProjectReload())
    }
    return
  }
  return dispatch(updateBundle({
    children: bundles,
    activeProjectId,
    saveToCache: offlineMode && !savingChanges ? (data) => dispatch(addChanges(data)) : undefined,
  })).then(async (result) => {
    if (offlineMode && setUndo) {
      await dispatch(addUndoRecord({
        undoBundles: bundles.map(revertBundle),
        redoBundles: bundles,
      }))
    }
    dispatch(prepareSiteNames)
    return result
  })
}

export const saveBundle = (bundle) => saveBundles([ bundle ])

const sectorTemplateProps = {
  azimuth: SECTOR_AZIMUTH_FIELD,
  height: SECTOR_HEIGHT_FIELD,
  bandwidth: SECTOR_BANDWIDTH_FIELD,
  cellCarriers: SECTOR_CELL_CARRIERS_FIELD,
  electricalTilt: SECTOR_ELECTRICAL_TILT_FIELD,
  mechanicalTilt: SECTOR_MECHANICAL_TILT_FIELD,
}

export const buildTemplateElements = (template, siteId, siteName, siteLng, siteLat, state) => {
  let sectorName = ''

  const fields = selectSectorFields(state)
  const [
    idIdx, nameIdx, lngIdx, latIdx, azmIdx, xIdx, yIdx, techIdx, statusIdx, rbsIdx, rbsNameIdx, siteFldIdx,
    siteNameIdx,
  ] = selectSectorFldIdx(state)
  const keys = Object.keys(sectorTemplateProps)
  const indexes = Object.fromEntries(keys.map((key) => [
    key,
    findIndex(fields, sectorTemplateProps[key]),
  ]))

  const fields2 = selectBaseStationFields(state)
  const [ idIdx2, nameIdx2, siteIdx2, statusIdx2, siteNameIdx2, techIdx2 ] = selectBaseStationFldIdx(state)

  const bands = [ ...new Set(template.sectors.map((sector) => sector.technology)) ]
  const children = bands.map((band) => {
    const element2 = new Array(fields2.length).fill(null)

    const modified2 = new Array(fields2.length).fill(false)
      .map((_, index) => [ nameIdx2, siteIdx2, statusIdx2, techIdx2 ].includes(index))
    element2[idIdx2] = uuid()
    element2[nameIdx2] = `${siteName}_${band}`
    element2[siteIdx2] = siteId
    element2[siteNameIdx2] = siteName
    element2[techIdx2] = band
    element2[statusIdx2] = STATUS_DRAFT
    const origin2 = [ ...element2 ]
    origin2[statusIdx2] = STATUS_REMOVED

    const sectors = template.sectors.filter((sector) => sector.technology === band)
    const children = sectors.map((sector) => {
      const params = keys.reduce((acc, key) => {
        if (sector[key] === undefined) {
          return acc
        }
        const index = indexes[key]
        if (index === -1) {
          return acc
        }
        return {
          items: [ ...acc.items, index ],
          values: [ ...acc.values, sector[key] ],
        }
      }, { items: [], values: [] })

      const element = new Array(fields.length).fill(null)
      const modified = new Array(fields.length).fill(false)
        .map((_, index) => [
          ...params.items, nameIdx, lngIdx, latIdx, azmIdx, xIdx, yIdx, techIdx, statusIdx, rbsIdx, siteFldIdx,
        ].includes(index))
      element[azmIdx] = 0
      element[xIdx] = 0
      element[yIdx] = 0
      params.items.forEach((item, index) => {
        element[item] = checkConvertValue(params.values[index], item, fields)
      })
      sectorName = nextSectorName(siteName, sectorName)
      element[nameIdx] = sectorName
      element[lngIdx] = siteLng
      element[latIdx] = siteLat
      element[techIdx] = band
      element[idIdx] = uuid()
      element[statusIdx] = STATUS_DRAFT
      element[rbsIdx] = element2[idIdx2]
      element[rbsNameIdx] = element2[nameIdx2]
      element[siteFldIdx] = siteId
      element[siteNameIdx] = siteName
      const origin = [ ...element ]
      origin[statusIdx] = STATUS_REMOVED

      return {
        id: element[idIdx],
        action: ACTION_CREATE,
        element,
        origin,
        fields,
        modified,
      }
    })

    return {
      id: element2[idIdx2],
      element: element2,
      origin: origin2,
      action: ACTION_CREATE,
      fields: fields2,
      modified: modified2,
      children,
    }
  })

  return { children }
}

export const createNewSite = ({ lat, lng }, useTemplate = true) => async (dispatch, getState) => { // #1 [+]
  const state = getState()
  const projectId = activeProjectId(state)
  const fields = selectSiteFields(state)
  const template = selectCurrentNetworkTemplate(state)
  const element = new Array(fields.length).fill(null)
  const [ idIdx, nameIdx, lngIdx, latIdx, statusIdx ] = selectSiteFldIdx(state)
  const modified = new Array(fields.length).fill(false)
    .map((_, index) => [ nameIdx, lngIdx, latIdx, statusIdx ].includes(index))
  element[idIdx] = uuid()
  let serverGenerated
  try {
    serverGenerated = await dispatch(generateNextSiteName())
  } catch (error) {
    return
  }
  const clientGenerated = newElementName(state.network.sites.full, nameIdx, NAME_PREFIX, SITE_NAME_FIELD)
  element[nameIdx] = serverGenerated ? selectMaxName(clientGenerated, serverGenerated) : clientGenerated
  element[lngIdx] = lng
  element[latIdx] = lat
  element[statusIdx] = STATUS_DRAFT
  const origin = [ ...element ]
  origin[statusIdx] = STATUS_REMOVED
  const templateChildren = useTemplate && template
    ? buildTemplateElements(template, element[idIdx], element[nameIdx], lng, lat, state)
    : {}
  if (await dispatch(saveBundles([ {
    id: element[idIdx],
    action: ACTION_CREATE,
    element,
    origin,
    fields,
    modified,
    ...templateChildren,
  } ], true, false))) {
    if (lat && lng) {
      await dispatch(requestSitesBySiteNames({ projectId, siteNames: [ element[nameIdx] ] }))
    }
    return element[idIdx]
  }
}

export const createNewSites = (items, sitesData) => async (dispatch, getState) => { // #2 [+]
  const state = getState()
  const fields = selectSiteFields(state)
  const [ idIdx, nameIdx, lngIdx, latIdx, statusIdx ] = selectSiteFldIdx(state)
  let serverGenerated
  try {
    serverGenerated = await dispatch(generateNextSiteName())
  } catch (error) {
    return
  }
  const clientGenerated = newElementName(state.network.sites.full, nameIdx, NAME_PREFIX, SITE_NAME_FIELD)
  let siteName = serverGenerated ? selectMaxName(clientGenerated, serverGenerated) : clientGenerated
  const bundles = sitesData.map((values) => {
    const element = new Array(fields.length).fill(null)
    const modified = new Array(fields.length).fill(false)
      .map((_, index) => [ ...items, nameIdx, statusIdx ].includes(index))
    element[lngIdx] = 0
    element[latIdx] = 0
    items.forEach((item, index) => {
      element[item] = checkConvertValue(values[index], item, fields)
    })
    // Насправді групове створення сайтів у нас передбачає, що поле Site name не може бути порожнім.
    // Але на перспективу, якщо раптом все ж знадобиться групова генерація нових сайтів без назви,
    // то цей код має розв'язати проблему, генеруючи послідовність назв для нових сайтів.
    if (!element[nameIdx] || element[nameIdx] === '?') {
      element[nameIdx] = siteName
      siteName = nextSiteName(siteName)
    }
    element[idIdx] = uuid()
    element[statusIdx] = STATUS_DRAFT
    const origin = [ ...element ]
    origin[statusIdx] = STATUS_REMOVED
    return {
      id: element[idIdx],
      action: ACTION_CREATE,
      element,
      origin,
      fields,
      modified,
    }
  })
  return dispatch(saveBundles(bundles))
}

export const createNewSector = ({ siteId, baseStationId, tech, setPath }) => async (dispatch, getState) => { // #3 [+]
  try {
    let updateSite
    const state = getState()
    const siteIdx = state.network.sites.full.findIndexById(siteId)
    const [ , nameIdx2, lngIdx2, latIdx2 ] = selectSiteFldIdx(state)
    const site = state.network.sites.full.getList()[siteIdx]
    const siteName = site[nameIdx2]
    const bsPart = {
      id: baseStationId,
    }
    let baseStationName
    const fields2 = selectBaseStationFields(state)
    if (baseStationId) {
      const baseStationIdx = state.network.baseStations.full.findIndexById(baseStationId)
      const [ , nameIdx2, siteIdx2, statusIdx2,, techIdx2 ] = selectBaseStationFldIdx(state)
      const element2 = state.network.baseStations.full.getList()[baseStationIdx]
      baseStationName = element2[nameIdx2]
      if (element2[statusIdx2] === STATUS_REMOVED) {
        const origin2 = [ ...element2 ]
        element2[statusIdx2] = STATUS_DRAFT
        element2[techIdx2] = tech
        element2[siteIdx2] = siteId
        const modified2 = new Array(fields2.length).fill(false)
          .map((_, index) => [ nameIdx2, siteIdx2, statusIdx2, techIdx2 ].includes(index))
        bsPart.element = element2
        bsPart.origin = origin2
        bsPart.action = ACTION_UPDATE
        bsPart.fields = fields2
        bsPart.modified = modified2
        updateSite = true
      }
    } else {
      const element2 = new Array(fields2.length).fill(null)
      const [ idIdx2, nameIdx2, siteIdx2, statusIdx2, siteNameIdx2, techIdx2 ] = selectBaseStationFldIdx(state)
      const modified2 = new Array(fields2.length).fill(false)
        .map((_, index) => [ nameIdx2, siteIdx2, statusIdx2, techIdx2 ].includes(index))
      element2[idIdx2] = uuid()
      element2[nameIdx2] = `${siteName}_${tech}`
      element2[siteIdx2] = siteId
      element2[siteNameIdx2] = siteName
      element2[techIdx2] = tech
      element2[statusIdx2] = STATUS_DRAFT
      const origin2 = [ ...element2 ]
      origin2[statusIdx2] = STATUS_REMOVED
      bsPart.id = element2[idIdx2]
      bsPart.element = element2
      bsPart.origin = origin2
      bsPart.action = ACTION_CREATE
      bsPart.fields = fields2
      bsPart.modified = modified2
      baseStationId = element2[idIdx2]
      baseStationName = element2[nameIdx2]
      updateSite = true
    }
    const fields = selectSectorFields(state)
    const element = new Array(fields.length).fill(null)
    const [
      idIdx, nameIdx, lngIdx, latIdx, azmIdx, xIdx, yIdx, techIdx, statusIdx,
      rbsIdx, rbsNameIdx, siteFldIdx, siteNameIdx,
    ] = selectSectorFldIdx(state)
    const heightIdx = findIndex(fields, SECTOR_HEIGHT_FIELD)
    const modified = new Array(fields.length).fill(false)
      .map((_, index) => [
        nameIdx, lngIdx, latIdx, heightIdx, azmIdx, xIdx, yIdx, techIdx, statusIdx, rbsIdx, siteFldIdx,
      ].includes(index))
    element[idIdx] = uuid()
    const serverGenerated = await dispatch(generateNextSectorName(siteName))
    const clientGenerated = newElementName(state.network.sectors.full, nameIdx, siteName, SECTOR_NAME_FIELD, true)
    element[nameIdx] = serverGenerated ? selectMaxName(clientGenerated, serverGenerated) : clientGenerated
    const siteForCoordinates = state.network.sites.full.getList()[siteIdx]
    const siteLng = siteForCoordinates[lngIdx2]
    const siteLat = siteForCoordinates[latIdx2]
    element[lngIdx] = siteLng
    element[latIdx] = siteLat
    element[heightIdx] = fields[heightIdx]?.min || 0
    element[azmIdx] = 0
    element[xIdx] = 0
    element[yIdx] = 0
    element[techIdx] = tech
    element[statusIdx] = STATUS_DRAFT
    element[rbsIdx] = baseStationId
    element[rbsNameIdx] = baseStationName
    element[siteFldIdx] = siteId
    element[siteNameIdx] = siteName
    const origin = [ ...element ]
    origin[statusIdx] = STATUS_REMOVED
    await dispatch(saveBundle({
      id: siteId,
      children: [ {
        ...bsPart,
        children: [ {
          id: element[idIdx],
          action: ACTION_CREATE,
          element,
          origin,
          fields,
          modified,
        } ],
      } ],
    }))
    if (updateSite) {
      const projectId = activeProjectId(state)
      dispatch(requestSites({ projectId, sites: [ { siteId } ] }))
    }
    if (setPath) {
      dispatch(setSectorEditor(element[idIdx]))
      dispatch(setPanel('edit-sector'))
    }

    return element[idIdx]
  } finally {
    dispatch(setInUpdate(false))
  }
}

export const createNewSectors = (items, sectorsData) => async (dispatch, getState) => { // #4 [+]
  const updateSites = []
  const state = getState()
  const [
    idIdx, nameIdx, lngIdx, latIdx, azmIdx, xIdx, yIdx, techIdx, statusIdx, rbsIdx, rbsNameIdx, siteFldIdx,
    siteNameIdx,
  ] = selectSectorFldIdx(state)

  const sectorNames = {}

  const getGeneratedSectorName = async (siteName) => {
    let previousName = sectorNames[siteName]
    if (!previousName) {
      const serverGenerated = await dispatch(generateNextSectorName(siteName))
      const clientGenerated = newElementName(state.network.sectors.full, nameIdx, siteName, SECTOR_NAME_FIELD, true)
      previousName = serverGenerated ? selectMaxName(clientGenerated, serverGenerated) : clientGenerated
    }
    sectorNames[siteName] = nextSectorName(siteName, previousName)
    return previousName
  }

  const bundles = await Promise.all(sectorsData.map(async ({ siteId, bands }) => {
    const siteIdx = state.network.sites.full.findIndexById(siteId)
    const [ , nameIdx2, lngIdx2, latIdx2 ] = selectSiteFldIdx(state)
    const site = state.network.sites.full.getList()[siteIdx]
    const siteName = site[nameIdx2]
    const siteLng = site[lngIdx2]
    const siteLat = site[latIdx2]
    const children = await Promise.all(bands.map(async ({ baseStationId, tech, sectorsData }) => {
      const bsPart = {
        id: baseStationId,
      }
      let baseStationName
      const fields2 = selectBaseStationFields(state)
      if (baseStationId) {
        const baseStationIdx = state.network.baseStations.full.findIndexById(baseStationId)
        const [ , nameIdx2, siteIdx2, statusIdx2,, techIdx2 ] = selectBaseStationFldIdx(state)
        const element2 = state.network.baseStations.full.getList()[baseStationIdx]
        baseStationName = element2[nameIdx2]
        if (element2[statusIdx2] === STATUS_REMOVED) {
          const origin2 = [ ...element2 ]
          element2[statusIdx2] = STATUS_DRAFT
          element2[techIdx2] = tech
          element2[siteIdx2] = siteId
          const modified2 = new Array(fields2.length).fill(false)
            .map((_, index) => [ nameIdx2, siteIdx2, statusIdx2, techIdx2 ].includes(index))
          bsPart.element = element2
          bsPart.origin = origin2
          bsPart.action = ACTION_UPDATE
          bsPart.fields = fields2
          bsPart.modified = modified2
          updateSites.push({ siteId })
        }
      } else {
        const element2 = new Array(fields2.length).fill(null)
        const [ idIdx2, nameIdx2, siteIdx2, statusIdx2, siteNameIdx2, techIdx2 ] = selectBaseStationFldIdx(state)
        const modified2 = new Array(fields2.length).fill(false)
          .map((_, index) => [ nameIdx2, siteIdx2, statusIdx2, techIdx2 ].includes(index))
        element2[idIdx2] = uuid()
        element2[nameIdx2] = `${siteName}_${tech}`
        element2[siteIdx2] = siteId
        element2[siteNameIdx2] = siteName
        element2[techIdx2] = tech
        element2[statusIdx2] = STATUS_DRAFT
        const origin2 = [ ...element2 ]
        origin2[statusIdx2] = STATUS_REMOVED
        bsPart.id = element2[idIdx2]
        bsPart.element = element2
        bsPart.origin = origin2
        bsPart.action = ACTION_CREATE
        bsPart.fields = fields2
        bsPart.modified = modified2
        baseStationId = element2[idIdx2]
        baseStationName = element2[nameIdx2]
        updateSites.push({ siteId })
      }
      const fields = selectSectorFields(state)
      const children = await Promise.all(sectorsData.map(async (values) => {
        const element = new Array(fields.length).fill(null)
        const modified = new Array(fields.length).fill(false)
          .map((_, index) => [
            ...items, nameIdx, lngIdx, latIdx, azmIdx, xIdx, yIdx, techIdx, statusIdx, rbsIdx, siteFldIdx,
          ].includes(index))
        element[azmIdx] = 0
        element[xIdx] = 0
        element[yIdx] = 0
        items.forEach((item, index) => {
          element[item] = checkConvertValue(values[index], item, fields)
        })
        // Насправді групове створення секторів у нас передбачає, що поле Sector name не може бути порожнім.
        // Але на перспективу, якщо раптом все ж знадобиться групова генерація нових секторів без назви,
        // то цей код має розв'язати проблему, генеруючи послідовність назв для нових секторів.
        if (!element[nameIdx] || element[nameIdx] === '?') {
          element[nameIdx] = await getGeneratedSectorName(siteName)
        }
        element[lngIdx] = siteLng
        element[latIdx] = siteLat
        element[techIdx] = tech
        element[idIdx] = uuid()
        element[statusIdx] = STATUS_DRAFT
        element[rbsIdx] = baseStationId
        element[rbsNameIdx] = baseStationName
        element[siteFldIdx] = siteId
        element[siteNameIdx] = siteName
        const origin = [ ...element ]
        origin[statusIdx] = STATUS_REMOVED

        return {
          id: element[idIdx],
          action: ACTION_CREATE,
          element,
          origin,
          fields,
          modified,
        }
      }))

      return {
        ...bsPart,
        children,
      }
    }))

    return {
      id: siteId,
      children,
    }
  }).filter(Boolean))

  const result = await dispatch(saveBundles(bundles))

  if (updateSites.length) {
    const projectId = activeProjectId(state)
    dispatch(requestSites({ projectId, sites: updateSites }))
  }

  return result
}

export const deleteSector = ({ siteId, baseStationId, sectorId }) => async (dispatch, getState) => { // #5 [+]
  try {
    let updateSite
    const state = getState()
    const sectorIdx = state.network.sectors.full.findIndexById(sectorId)
    const [ ,,,,,,,, statusIdx ] = selectSectorFldIdx(state)
    const element = state.network.sectors.full.getList()[sectorIdx]
    const origin = [ ...element ]
    const fields = selectSectorFields(state)
    const modified = new Array(fields.length).fill(false)
      .map((_, index) => [ statusIdx ].includes(index))
    element[statusIdx] = STATUS_REMOVED
    const bsPart = {
      id: baseStationId,
    }
    const baseStationIdx = state.network.baseStations.full.findIndexById(baseStationId)
    const [ ,,, statusIdx2 ] = selectBaseStationFldIdx(state)
    const element2 = state.network.baseStations.full.getList()[baseStationIdx]
    const fields2 = selectBaseStationFields(state)
    const modified2 = new Array(fields2.length).fill(false)
      .map((_, index) => [ statusIdx2 ].includes(index))
    if (CAN_BE_REMOVED.includes(element2[statusIdx2])) {
      const sectorsRange = state.network.sectors.full.findRangeByValue(baseStationId, SECTOR_RBS_FIELD)
      if (
        !sectorsRange ||
        sectorsRange.every((index) => state.network.sectors.full.getList()[index][statusIdx] === STATUS_REMOVED)
      ) {
        const origin2 = [ ...element2 ]
        element2[statusIdx2] = STATUS_REMOVED
        bsPart.action = ACTION_DELETE
        bsPart.element = element2
        bsPart.origin = origin2
        bsPart.fields = fields2
        bsPart.modified = modified2
        updateSite = true
      }
    }
    const result = await dispatch(saveBundle({
      id: siteId,
      children: [ {
        ...bsPart,
        children: [ {
          id: sectorId,
          action: ACTION_DELETE,
          element,
          origin,
          fields,
          modified,
        } ],
      } ],
    }))

    if (updateSite) {
      const projectId = activeProjectId(state)
      dispatch(requestSites({ projectId, sites: [ { siteId } ] }))
    }

    return result
  } finally {
    dispatch(setInUpdate(false))
  }
}

export const deleteSectors = (sitesData) => async (dispatch, getState) => { // #6 [+]
  const updateSites = []
  const state = getState()
  const bundles = sitesData.map(({ siteId, bands }) => {
    const children = bands.map(({ baseStationId, sectorIds }) => {
      const [ idIdx,,,,,,,, statusIdx ] = selectSectorFldIdx(state)
      const bsPart = {
        id: baseStationId,
      }
      const baseStationIdx = state.network.baseStations.full.findIndexById(baseStationId)
      const [ ,,, statusIdx2 ] = selectBaseStationFldIdx(state)
      const element2 = state.network.baseStations.full.getList()[baseStationIdx]
      const fields2 = selectBaseStationFields(state)
      const modified2 = new Array(fields2.length).fill(false)
        .map((_, index) => index === statusIdx2)
      if (CAN_BE_REMOVED.includes(element2[statusIdx2])) {
        const sectorsRange = state.network.sectors.full.findRangeByValue(baseStationId, SECTOR_RBS_FIELD)
        if (
          !sectorsRange ||
          sectorsRange.every((index) => {
            const sector = state.network.sectors.full.getList()[index]
            return sector[statusIdx] === STATUS_REMOVED || sectorIds.includes(sector[idIdx])
          })
        ) {
          const origin2 = [ ...element2 ]
          element2[statusIdx2] = STATUS_REMOVED
          bsPart.action = ACTION_DELETE
          bsPart.element = element2
          bsPart.origin = origin2
          bsPart.fields = fields2
          bsPart.modified = modified2
          updateSites.push({ siteId })
        }
      }

      const children = sectorIds.map((sectorId) => {
        const sectorIdx = state.network.sectors.full.findIndexById(sectorId)
        const element = state.network.sectors.full.getList()[sectorIdx]
        const origin = [ ...element ]
        const fields = selectSectorFields(state)
        const modified = new Array(fields.length).fill(false)
          .map((_, index) => index === statusIdx)
        element[statusIdx] = STATUS_REMOVED

        return {
          id: sectorId,
          action: ACTION_DELETE,
          element,
          origin,
          fields,
          modified,
        }
      })

      return {
        ...bsPart,
        children,
      }
    })

    return {
      id: siteId,
      children,
    }
  })

  const result = await dispatch(saveBundles(bundles))

  if (updateSites.length) {
    const projectId = activeProjectId(state)
    dispatch(requestSites({ projectId, sites: updateSites }))
  }

  return result
}

export const deleteSite = ({ siteId }) => async (dispatch, getState) => { // #7
  try {
    const state = getState()

    const fields = selectSiteFields(state)
    const fields2 = selectBaseStationFields(state)
    const fields3 = selectSectorFields(state)

    const [ ,,,, siteStatusIdx ] = selectSiteFldIdx(state)
    const [ baseStationIdIdx,,, baseStationStatusIdx ] = selectBaseStationFldIdx(state)
    const [ sectorIdIdx,,,,,,,, sectorStatusIdx ] = selectSectorFldIdx(state)

    const modified = new Array(fields.length).fill(false)
      .map((_, index) => [ siteStatusIdx ].includes(index))
    const modified2 = new Array(fields2.length).fill(false)
      .map((_, index) => [ baseStationStatusIdx ].includes(index))
    const modified3 = new Array(fields3.length).fill(false)
      .map((_, index) => [ sectorStatusIdx ].includes(index))

    const siteIdx = state.network.sites.full.findIndexById(siteId)
    const element = state.network.sites.full.getList()[siteIdx]
    const origin = [ ...element ]
    element[siteStatusIdx] = STATUS_REMOVED

    const siteBundle = {
      id: siteId,
      action: ACTION_DELETE,
      element,
      origin,
      fields,
      modified,
      children: [],
    }

    const baseStationsRange = state.network.baseStations.full.findRangeByValue(siteId, RBS_SITE_FIELD) || []
    for (const baseStationIdx of baseStationsRange) {
      const element2 = state.network.baseStations.full.getList()[baseStationIdx]
      if (element2[baseStationStatusIdx] === STATUS_REMOVED) {
        continue
      }
      const origin2 = [ ...element2 ]
      element2[baseStationStatusIdx] = STATUS_REMOVED
      const baseStationBundle = {
        id: element2[baseStationIdIdx],
        action: ACTION_DELETE,
        element: element2,
        origin: origin2,
        fields: fields2,
        modified: modified2,
        children: [],
      }

      const sectorsRange = state.network.sectors.full.findRangeByValue(
        element2[baseStationIdIdx], SECTOR_RBS_FIELD) || []
      for (const sectorIdx of sectorsRange) {
        const element3 = state.network.sectors.full.getList()[sectorIdx]
        if (element3[sectorStatusIdx] === STATUS_REMOVED) {
          continue
        }
        const origin3 = [ ...element3 ]
        element3[sectorStatusIdx] = STATUS_REMOVED
        baseStationBundle.children.push({
          id: element3[sectorIdIdx],
          action: ACTION_DELETE,
          element: element3,
          origin: origin3,
          fields: fields3,
          modified: modified3,
        })
      }

      siteBundle.children.push(baseStationBundle)
    }

    return await dispatch(saveBundle(siteBundle))
  } finally {
    dispatch(setInUpdate(false))
  }
}

export const deleteSites = (siteIds) => (dispatch, getState) => { // #8 [+]
  const state = getState()

  const fields = selectSiteFields(state)
  const fields2 = selectBaseStationFields(state)
  const fields3 = selectSectorFields(state)

  const [ ,,,, siteStatusIdx ] = selectSiteFldIdx(state)
  const [ baseStationIdIdx,,, baseStationStatusIdx ] = selectBaseStationFldIdx(state)
  const [ sectorIdIdx,,,,,,,, sectorStatusIdx ] = selectSectorFldIdx(state)

  const modified = new Array(fields.length).fill(false)
    .map((_, index) => index === siteStatusIdx)
  const modified2 = new Array(fields2.length).fill(false)
    .map((_, index) => index === baseStationStatusIdx)
  const modified3 = new Array(fields3.length).fill(false)
    .map((_, index) => index === sectorStatusIdx)

  const bundles = siteIds.map((siteId) => {
    const siteIdx = state.network.sites.full.findIndexById(siteId)
    const element = state.network.sites.full.getList()[siteIdx]
    const origin = [ ...element ]
    element[siteStatusIdx] = STATUS_REMOVED

    const siteBundle = {
      id: siteId,
      action: ACTION_DELETE,
      element,
      origin,
      fields,
      modified,
      children: [],
    }

    const baseStationsRange = state.network.baseStations.full.findRangeByValue(siteId, RBS_SITE_FIELD) || []
    for (const baseStationIdx of baseStationsRange) {
      const element2 = state.network.baseStations.full.getList()[baseStationIdx]
      const origin2 = [ ...element2 ]
      element2[baseStationStatusIdx] = STATUS_REMOVED
      const baseStationBundle = {
        id: element2[baseStationIdIdx],
        action: ACTION_DELETE,
        element: element2,
        origin: origin2,
        fields: fields2,
        modified: modified2,
        children: [],
      }

      const sectorsRange = state.network.sectors.full.findRangeByValue(element2[baseStationIdIdx], SECTOR_RBS_FIELD) ||
        []
      for (const sectorIdx of sectorsRange) {
        const element3 = state.network.sectors.full.getList()[sectorIdx]
        const origin3 = [ ...element3 ]
        element3[sectorStatusIdx] = STATUS_REMOVED
        baseStationBundle.children.push({
          id: element3[sectorIdIdx],
          action: ACTION_DELETE,
          element: element3,
          origin: origin3,
          fields: fields3,
          modified: modified3,
        })
      }

      siteBundle.children.push(baseStationBundle)
    }

    return siteBundle
  })

  return dispatch(saveBundles(bundles))
}

export const extendSiteBundleToMoveSectors = (state, site) => { // #9 [+]
  let lat, lng
  const [ siteIdIdx,, lngIdx1, latIdx1 ] = selectSiteFldIdx(state)
  if (site.modified[lngIdx1]) {
    lng = site.element[lngIdx1]
  }
  if (site.modified[latIdx1]) {
    lat = site.element[latIdx1]
  }

  if (lng === undefined && lat === undefined) {
    return
  }

  const sectorFields = selectSectorFields(state)
  const [ ,, lngIdx2, latIdx2 ] = selectSectorFldIdx(state)
  const fullSectors = selectSectorsFull(state)
  const [ sectorIdIdx ] = selectSectorFldIdx(state)
  const fullBaseStations = selectBaseStationsFull(state)
  const [ baseStationIdIdx ] = selectBaseStationFldIdx(state)

  const baseStationsRange = fullBaseStations.findRangeByValue(site.element[siteIdIdx], RBS_SITE_FIELD)
  const baseStations = baseStationsRange
    ? baseStationsRange.map((index) => ({
      id: fullBaseStations.getList()[index][baseStationIdIdx],
    }))
    : []
  if (!baseStations.length) {
    return
  }

  baseStations.forEach((baseStation) => {
    const sectorsRange = fullSectors.findRangeByValue(baseStation.id, SECTOR_RBS_FIELD)
    const sectors = sectorsRange
      ? sectorsRange
        .map((index) => {
          const element = fullSectors.getList()[index]
          const origin = [ ...element ]
          const sector = {
            element,
            origin,
            id: fullSectors.getList()[index][sectorIdIdx],
            fields: state.network.sectors.fields,
          }
          const lngFlag = lng !== undefined && sector.element[lngIdx2] !== lng
          const latFlag = lat !== undefined && sector.element[latIdx2] !== lat
          if (!lngFlag && !latFlag) {
            return null
          }
          if (lngFlag) {
            sector.element[lngIdx2] = lng
          }
          if (latFlag) {
            sector.element[latIdx2] = lat
          }
          sector.action = ACTION_UPDATE
          sector.modified = new Array(sectorFields.length).fill(false)
            .map((_, index) => (index === lngIdx2 && lngFlag) || (index === latIdx2 && latFlag))
          return sector
        })
        .filter(Boolean)
      : []
    if (sectors.length) {
      baseStation.children = sectors
    }
  })

  const children = baseStations.filter(({ children }) => children)
  if (children.length) {
    site.children = children
  }
}

export const editSiteField = ({ id, field, value, oldValue }) => (dispatch, getState) => { // #10 [+]
  const state = getState()
  const fields = selectSiteFields(state)
  const elementIdx = state.network.sites.full.findIndexById(id)

  // TODO: Перевірити, чи це не вплине на щось іще. Бо тут ми чомусь робили копію, я повернув посилання на елемент.
  // TODO: Річ у тому, що нам крім власне елементів масиву потрібна ще його властивість isInMyProject.
  const element = state.network.sites.full.getList()[elementIdx]

  const origin = [ ...element ]
  if (oldValue !== undefined) {
    origin[field] = oldValue
  }
  const modified = new Array(fields.length).fill(false)
    .map((_, index) => index === field)
  element[field] = checkConvertValue(value, field, fields, state.network.sites.full,
    SITE_NAME_FIELD, elementIdx, [ { row: elementIdx, field, value: oldValue } ])
  const site = {
    id,
    action: ACTION_UPDATE,
    element,
    origin,
    fields,
    modified,
  }
  extendSiteBundleToMoveSectors(state, site)
  return dispatch(saveBundle(site))
}

export const editSiteFields = ({ id, updates }) => (dispatch, getState) => { // #11 [+]
  const state = getState()
  const fields = selectSiteFields(state)
  const elementIdx = state.network.sites.full.findIndexById(id)
  const element = state.network.sites.full.getList()[elementIdx]
  const origin = [ ...element ]
  const modified = new Array(fields.length).fill(false)
  const updatedValues = new Set()
  const oldValues = []
  updates.forEach(({ field, physicalRow, oldValue }) => {
    if (SITE_NAME_FIELD === fields?.[field]?.id) {
      oldValues.push({ row: physicalRow, field, value: oldValue })
    }
  })
  updates.forEach(({ field, value, oldValue }) => {
    element[field] = checkConvertValue(
      value, field, fields, state.network.sites.full, SITE_NAME_FIELD, elementIdx, oldValues, updatedValues)
    if (oldValue !== undefined) {
      origin[field] = oldValue
    }
    if (fields[field]?.id === SITE_NAME_FIELD) {
      updatedValues.add(value)
      if (value !== element[field]) {
        updatedValues.add(element[field])
      }
    }
    modified[field] = true
  })
  const site = {
    id,
    action: ACTION_UPDATE,
    element,
    origin,
    fields,
    modified,
  }
  extendSiteBundleToMoveSectors(state, site)
  return dispatch(saveBundle(site))
}

export const editSitesFields = (sitesData) => (dispatch, getState) => { // #12 [+]
  const state = getState()
  const fields = selectSiteFields(state)
  const updatedValues = new Set()
  const oldValues = []
  sitesData.forEach(({ updates }) => {
    updates.forEach(({ field, physicalRow, oldValue }) => {
      if (SITE_NAME_FIELD === fields?.[field]?.id) {
        oldValues.push({ value: oldValue, row: physicalRow, field })
      }
    })
  })
  const bundles = sitesData.map(({ id, updates }) => {
    const elementIdx = state.network.sites.full.findIndexById(id)
    const element = state.network.sites.full.getList()[elementIdx]
    const origin = [ ...element ]
    const modified = new Array(fields.length).fill(false)
    updates.forEach(({ field, value, oldValue }) => {
      element[field] = checkConvertValue(
        value, field, fields, state.network.sites.full, SITE_NAME_FIELD, elementIdx, oldValues, updatedValues)
      if (oldValue !== undefined) {
        origin[field] = oldValue
      }
      if (fields[field]?.id === SITE_NAME_FIELD) {
        updatedValues.add(value)
        if (value !== element[field]) {
          updatedValues.add(element[field])
        }
      }
      modified[field] = true
    })
    const site = {
      id,
      action: ACTION_UPDATE,
      element,
      origin,
      fields,
      modified,
    }
    extendSiteBundleToMoveSectors(state, site)

    return site
  })

  return dispatch(saveBundles(bundles))
}

export const editBaseStationField = ({ id, field, value, oldValue }) => (dispatch, getState) => { // #13 [+]
  const state = getState()
  const fields = selectBaseStationFields(state)
  const [ ,, siteFldIdx ] = selectBaseStationFldIdx(state)
  const elementIdx = state.network.baseStations.full.findIndexById(id)
  const element = state.network.baseStations.full.getList()[elementIdx]
  const origin = [ ...element ]
  const modified = new Array(fields.length).fill(false)
    .map((_, index) => index === field)
  element[field] = checkConvertValue(value, field, fields)
  if (oldValue !== undefined) {
    origin[field] = oldValue
  }
  return dispatch(saveBundle({
    id: element[siteFldIdx],
    children: [ {
      id,
      action: ACTION_UPDATE,
      element,
      origin,
      fields,
      modified,
    } ],
  }))
}

export const editSectorField = ({ id, field, value, oldValue }) => (dispatch, getState) => { // #14 [+]
  const state = getState()
  const fields = selectSectorFields(state)
  const [ ,,,,,,,,, baseStationFldIdx,, siteFldIdx ] = selectSectorFldIdx(state)
  const elementIdx = state.network.sectors.full.findIndexById(id)
  const element = state.network.sectors.full.getList()[elementIdx]
  const origin = [ ...element ]
  if (oldValue !== undefined) {
    origin[field] = oldValue
  }
  const modified = new Array(fields.length).fill(false)
    .map((_, index) => index === field)
  element[field] = checkConvertValue(value, field, fields, state.network.sectors.full,
    SECTOR_NAME_FIELD, elementIdx, [ { row: elementIdx, field, value: oldValue } ])
  return dispatch(saveBundle({
    id: element[siteFldIdx],
    children: [ {
      id: element[baseStationFldIdx],
      children: [ {
        id,
        action: ACTION_UPDATE,
        element,
        origin,
        fields,
        modified,
      } ],
    } ],
  }))
}

export const editSectorFields = ({ id, updates }) => (dispatch, getState) => { // #15 [+]
  const state = getState()
  const fields = selectSectorFields(state)
  const [ ,,,,,,,,, baseStationFldIdx,, siteFldIdx ] = selectSectorFldIdx(state)
  const elementIdx = state.network.sectors.full.findIndexById(id)
  const element = state.network.sectors.full.getList()[elementIdx]
  const origin = [ ...element ]
  const modified = new Array(fields.length).fill(false)
  const updatedValues = new Set()
  const oldValues = []
  updates.forEach(({ field, physicalRow, oldValue }) => {
    if (SECTOR_NAME_FIELD === fields?.[field]?.id) {
      oldValues.push({ row: physicalRow, field, value: oldValue })
    }
  })
  updates.forEach(({ field, value, oldValue }) => {
    element[field] = checkConvertValue(
      value, field, fields, state.network.sectors.full, SECTOR_NAME_FIELD, elementIdx, oldValues, updatedValues)
    if (oldValue !== undefined) {
      origin[field] = oldValue
    }
    if (fields[field]?.id === SECTOR_NAME_FIELD) {
      updatedValues.add(value)
      if (value !== element[field]) {
        updatedValues.add(element[field])
      }
    }
    modified[field] = true
  })
  return dispatch(saveBundle({
    id: element[siteFldIdx],
    children: [ {
      id: element[baseStationFldIdx],
      children: [ {
        id,
        action: ACTION_UPDATE,
        element,
        fields,
        origin,
        modified,
      } ],
    } ],
  }))
}

export const editSectorsFields = (sitesData) => (dispatch, getState) => { // #16 [+]
  const state = getState()
  const fields = selectSectorFields(state)
  const updatedValues = new Set()
  const oldValues = []
  sitesData.forEach(({ bands }) => {
    bands.forEach(({ sectorsData }) => {
      sectorsData.forEach(({ updates }) => {
        updates.forEach(({ field, physicalRow, oldValue }) => {
          if (SECTOR_NAME_FIELD === fields?.[field]?.id) {
            oldValues.push({ value: oldValue, row: physicalRow, field })
          }
        })
      })
    })
  })

  const bundles = sitesData.map(({ siteId, bands }) => {
    const children = bands.map(({ baseStationId, sectorsData }) => {
      const children = sectorsData.map(({ id, updates }) => {
        const elementIdx = state.network.sectors.full.findIndexById(id)
        const element = state.network.sectors.full.getList()[elementIdx]
        const origin = [ ...element ]
        const modified = new Array(fields.length).fill(false)
        updates.forEach(({ field, value, oldValue }) => {
          element[field] = checkConvertValue(
            value, field, fields, state.network.sectors.full, SECTOR_NAME_FIELD, elementIdx, oldValues, updatedValues)
          if (oldValue !== undefined) {
            origin[field] = oldValue
          }
          if (fields[field]?.id === SECTOR_NAME_FIELD) {
            updatedValues.add(value)
            if (value !== element[field]) {
              updatedValues.add(element[field])
            }
          }
          modified[field] = true
        })

        return {
          id,
          action: ACTION_UPDATE,
          element,
          origin,
          fields,
          modified,
        }
      })

      return {
        id: baseStationId,
        children,
      }
    })

    return {
      id: siteId,
      children,
    }
  })

  return dispatch(saveBundles(bundles))
}

const isEqual = (array, array2) => array?.length === array2?.length &&
  array.every((value, index) => value === array2[index])

export const putToMyProject = (defaultSites, projectSites, defaultRBS, projectRBS, defaultSectors, projectSectors) =>
  (dispatch, getState) => {
    const currentState = getState()
    const projectId = activeProjectId(currentState)
    if (projectId && !defaultSectors.error && !projectSectors.error) {
      const siteFields = selectSiteFields(currentState)
      const rbsFields = selectBaseStationFields(currentState)
      const sectorFields = selectSectorFields(currentState)
      const [ idFldIdx, nameFldIdx,,,,,, techFldIdx,, rbdIdFldIdx,, siteIdFldIdx ] = selectSectorFldIdx(currentState)
      const [ idFldIdx2, nameFldIdx2 ] = selectBaseStationFldIdx(currentState)
      const [ idFldIdx3, nameFldIdx3 ] = selectSiteFldIdx(currentState)

      const bundles = []

      defaultSectors.payload.data.forEach((sector) => {
        const siteId = sector[siteIdFldIdx]
        const rbsId = sector[rbdIdFldIdx]
        const id = sector[idFldIdx]

        const prSector = projectSectors.payload.data.find((sector) => sector[idFldIdx] === id)
        const isEqualSector = isEqual(sector, prSector)
        let isSiteFromDefault = false
        let isRBSFromDefault = false

        const modified = new Array(sectorFields.length).fill(false)
        modified[nameFldIdx] = true
        modified[techFldIdx] = true

        let bundle = bundles.find((bundle) => bundle.id === siteId)
        if (!bundle) {
          const modified = new Array(siteFields.length).fill(false)
          modified[nameFldIdx3] = true
          let site = projectSites.payload.data.find((site) => site[idFldIdx3] === siteId)
          if (!site) {
            site = defaultSites.payload.data.find((site) => site[idFldIdx3] === siteId)
            isSiteFromDefault = true
          }
          bundle = {
            id: siteId,
            action: ACTION_UPDATE,
            element: site,
            origin: [ ...site ],
            fields: siteFields,
            modified,
            children: [],
          }
        }

        let baseStation = bundle.children.find((baseStation) => baseStation.id === rbsId)
        if (!baseStation) {
          const modified = new Array(rbsFields.length).fill(false)
          modified[nameFldIdx2] = true
          let rbs = projectRBS.payload.data.find((rbs) => rbs[idFldIdx2] === rbsId)
          if (!rbs) {
            rbs = defaultRBS.payload.data.find((rbs) => rbs[idFldIdx2] === rbsId)
            isRBSFromDefault = true
          }
          baseStation = {
            id: rbsId,
            action: ACTION_UPDATE,
            element: rbs,
            origin: [ ...rbs ],
            fields: rbsFields,
            modified,
            children: [],
          }
          bundle.children.push(baseStation)
        }

        baseStation.children.push({
          id,
          action: ACTION_UPDATE,
          element: sector,
          origin: [ ...sector ],
          fields: sectorFields,
          modified,
        })

        if (!isEqualSector || isSiteFromDefault || isRBSFromDefault) {
          if (!bundles.find((bundle) => bundle.id === siteId)) {
            bundles.push(bundle)
          }
        }
      })

      if (bundles.length > 0) {
        return dispatch(saveBundles(bundles))
      }
    }
  }

export const moveSite = (siteId, { lat, lng }) => async (dispatch, getState) => { // #20 [+]
  const state = getState()
  const projectId = activeProjectId(state)
  const siteFields = selectSiteFields(state)
  const sectorFields = selectSectorFields(state)
  const [ , nameIdx, lngIdx1, latIdx1 ] = selectSiteFldIdx(state)
  const [ ,, lngIdx2, latIdx2 ] = selectSectorFldIdx(state)
  const fullSites = selectSitesFull(state)
  const siteIdx = fullSites.findIndexById(siteId)
  const site = formSiteBundle(state, siteIdx, false, true)
  site.element[lngIdx1] = lng
  site.element[latIdx1] = lat
  site.action = ACTION_UPDATE
  site.modified = new Array(siteFields.length).fill(false)
    .map((_, index) => index === lngIdx1 || index === latIdx1)
  site.children.forEach((baseStation) => {
    baseStation.children.forEach((sector) => {
      sector.element[lngIdx2] = lng
      sector.element[latIdx2] = lat
      sector.action = ACTION_UPDATE
      sector.modified = new Array(sectorFields.length).fill(false)
        .map((_, index) => index === lngIdx2 || index === latIdx2)
    })
  })
  const result = await dispatch(saveBundle(site))

  const name = fullSites.getList()[siteIdx][nameIdx]
  dispatch(requestSitesBySiteNames({ projectId, siteNames: [ name ] }))

  return result
}

export const moveSector = (sectorId, { x, y }) => (dispatch, getState) => { // #21 [+]
  const state = getState()
  const fields = selectSectorFields(state)
  const [ idFldIdx,,,,, xFldIdx, yFldIdx,,, baseStationFldIdx,, siteFldIdx ] = selectSectorFldIdx(state)
  const fullSectors = selectSectorsFull(state)
  const sectorIdx = fullSectors.findIndexById(sectorId)
  const element = fullSectors.getList()[sectorIdx]
  const origin = [ ...element ]
  element[xFldIdx] = x
  element[yFldIdx] = y
  const modified = new Array(fields.length).fill(false)
    .map((_, index) => index === xFldIdx || index === yFldIdx)
  return dispatch(saveBundle({
    id: element[siteFldIdx],
    children: [ {
      id: element[baseStationFldIdx],
      children: [ {
        id: element[idFldIdx],
        action: ACTION_UPDATE,
        element,
        origin,
        fields,
        modified,
      } ],
    } ],
  }))
}
