import * as Headers from './headers'

export const PERIOD_YEAR = 'yearly'
export const PERIOD_MONTH = 'monthly'

export const TO_FIXED = 6

export const getDateFormatForBackend = (value, isMonth) => {
  const date = new Date(value)
  if (isMonth) {
    const monthId = date.getMonth() + 1
    return `${date.getFullYear()}-${monthId < 10 ? '0' : ''}${monthId}` // format for backend
  } else {
    return `${date.getFullYear()}`
  }
}

export const addTRFDataArray = (isMonth, dateValue, numOfRows, array) => {
  const date = new Date(dateValue)
  array.push(...Array.from({ length: numOfRows }, (_, i) => {
    if (i > 0) {
      if (isMonth) {
        date.setMonth(date.getMonth() + 1)
      } else {
        date.setFullYear(date.getFullYear() + 1)
      }
    }
    const dateString = getDateFormatForBackend(date, isMonth)
    const res = [
      Headers.TYPE_FC,
      dateString,
    ]
    return res
  }))
}

export const convertToTableDate = (columns, trfData, period) => {
  const isMonth = period === PERIOD_MONTH
  const todayDate = new Date()
  todayDate.setDate(1)
  todayDate.setHours(0, 0, 0, 0)
  if (!isMonth) {
    todayDate.setMonth(0)
  }
  const keys = Object.keys(trfData)
  keys.sort()
  const tableData = keys.map((key, index) => {
    const date = new Date(key)
    let total = 0
    const rowData = [
      todayDate < date ? Headers.TYPE_FC : Headers.TYPE_ACT,
      key,
      ...columns.reduce((res, col) => {
        if (![
          Headers.TRF_DATA_TOTAL_FIELD,
          Headers.TRF_VOICE_TOTAL_FIELD,
          Headers.TRF_A1M_TOTAL_FIELD,
        ].includes(col.id)) {
          const id = col.id?.split('.').splice(2, 2)
          if (id && id.length === 2) {
            const value = trfData[key][id[0]][id[1]] || 0
            res.push(value)
            total += value
          }
        } else {
          res.push(Number.parseFloat(total.toFixed(TO_FIXED)))
          total = 0
        }
        return res
      }, []),
    ]
    return rowData
  })
  if (tableData.length === 0) {
    addTRFDataArray(isMonth, new Date(), 1, tableData)
  }
  return tableData
}
