import React from 'react'
import { ThemeProvider } from '@fluentui/react'
import { BrowserRouter as Router } from 'react-router-dom'
import UserHolder from './features/login/UserHolder'
import Main from './layout/Main'
import { capexTheme } from './theme'
import './App.css'

function App () {
  return (
    <ThemeProvider applyTo="body" theme={capexTheme}>
      <UserHolder>
        <Router>
          <Main />
        </Router>
      </UserHolder>
    </ThemeProvider>
  )
}

export default App
