import React, { useMemo } from 'react'
import { CloseIcon, SitesIcon, StandardIcon, RBSIcon, SectorsIcon, ComplaintsIcon } from '../../common/icons/names'
import { IconSvg } from '../../common/icons'

import './BundleTreeItem.css'
import Badge from '../../common/Badge'

const COMPLAINTS_HINT = 'Complaints'

const BundleTreeItem = ({
  type, name, className, path, complaints, complaintsBackground, onDelete, ...props
}) => {
  const icon = useMemo(() => {
    switch (type) {
      case 'site':
        return SitesIcon
      case 'rbs':
        return RBSIcon
      case 'sector':
        return SectorsIcon
      case 'complaint':
        return ComplaintsIcon
      default:
        return StandardIcon
    }
  }, [ type ])

  const renderIcon = useMemo(() => (
    <div className={`bundle-tree-item-icon bundle-tree-item-icon-${type}`}>
      <IconSvg name={icon} />
    </div>
  ), [ icon, type ])

  const renderName = useMemo(() => {
    let title = type.charAt(0).toUpperCase() + type.slice(1)
    if (type === 'standard') {
      title = 'Technology'
    }
    if (type === 'rbs') {
      title = 'RBS'
    }
    return (
      <div className='bundle-tree-item-name'>
        <label title={title}>{title}</label>
        <label title={name} className={`bundle-tree-item-name-name bundle-tree-item-name-name-${type}`}>{name}</label>
      </div>
    )
  }, [ type, name ])

  const renderDelete = useMemo(() => onDelete && (
    <div
      className='delete-button'
      onClick={onDelete}
      tabIndex={0}
    >
      <IconSvg name={CloseIcon} />
    </div>
  ), [ onDelete ])

  return (
    <Badge
      className={`bundle-tree-item ${props.disabled ? 'is-disabled' : ''} ${className}`}
      value={complaints?.length}
      color={complaintsBackground}
      title={COMPLAINTS_HINT}
      data-path={path}
      {...props}
    >
      {renderIcon}
      {renderName}
      {renderDelete}
    </Badge>
  )
}

export default BundleTreeItem
