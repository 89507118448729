import { SPREAD_RANGE, SPREAD_DISCRETE } from '../../../constants/settings'

export const ID_ICON_SITES = 'icon-sites'
export const ID_ICON_SECTOR = 'icon-sector'
export const ID_ICON_COMPLAINT = 'icon-complaint'

export const ELEMENT_TYPES = {
  VECTOR_MAPS: 'vectorMaps',
  SITES: 'sites',
  SECTORS: 'sectors',
  COMPLAINTS: 'complaints',
}

/* export const staticElementTypes = [
  ELEMENT_TYPES.SITES,
  ELEMENT_TYPES.SECTORS,
] */

export const adjustableSpreads = [
  SPREAD_RANGE,
  SPREAD_DISCRETE,
]
