import React from 'react'
import TextField from '../../../common/TextField'
import ColorWithPicker from '../../../common/ColorWithPicker'

import './TextFieldColor.css'

const className = (props) => {
  let result = 'capex-text-field-color'
  if (props.className) {
    result += ` ${props.className}`
  }
  return result
}

const TextFieldColor = ({ color, defaultColor, onChange, onBlur, onChangeColor, ...rest }) => (
  <TextField
    {...rest}
    onChange={onChange}
    onBlur={onBlur}
    className={className(rest)}
    onRenderSuffix={() => (
      <ColorWithPicker
        alpha={'none'}
        className="input-with-color"
        color={defaultColor ?? color}
        onChange={onChangeColor}
      />
    )}
  />
)

export default TextFieldColor
