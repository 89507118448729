import React from 'react'
import { DefaultButton } from '@fluentui/react'

import './Button.css'

const Button = ({ className, ...props }) => (
  <DefaultButton className={`capex-button ${className ?? ''}`} {...props} />
)

export const PrimaryButton = (props) => (
  <Button primary {...props} />
)

export const SecondaryButton = ({ className, ...props }) => (
  <DefaultButton className={`capex-button ms-Button--secondary ${className ?? ''}`} {...props} />
)

export default Button
