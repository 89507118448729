import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@fluentui/react'
import { saveSettings, selectSettingsComplaintsGrouping } from '../../../../features/settings/settingsSlice'
import Subheader from '../../../common/Subheader'
import Toggle from '../../../common/Toggle'

const ComplaintsGrouping = () => {
  const dispatch = useDispatch()

  const complaintsGrouping = useSelector(selectSettingsComplaintsGrouping)

  const handleToggle = useCallback(() => {
    dispatch(saveSettings({ complaintsGrouping: !complaintsGrouping }))
  }, [ dispatch, complaintsGrouping ])

  return (
    <Stack style={{ paddingBottom: 16 }}>
      <Subheader text="Show complaints grouped into clusters" />
      <Toggle onText="On" offText="Off" checked={complaintsGrouping} onChange={handleToggle} />
    </Stack>
  )
}

export default ComplaintsGrouping
