import React, { useCallback, useState, useMemo } from 'react'
import { useBoolean, useId } from '@fluentui/react-hooks'
import {
  DefaultButton, ColorPicker, Callout, getColorFromString, SwatchColorPicker, StackItem, DirectionalHint,
} from '@fluentui/react'
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@fluentui/react-accordion'
import { useHandler } from '../../hooks'

import './ColorWithPicker.css'

const COLOR = '#ff000033' // Red with 20% opacity

const PRESET_COLORS = [
  { id: 'preset_color_1', label: 'Red', color: '#ff0000' },
  { id: 'preset_color_2', label: 'Chartreuse (Traditional)', color: '#ebff00' },
  { id: 'preset_color_3', label: 'Electric Green', color: '#00ff0a' },
  { id: 'preset_color_4', label: 'Aqua', color: '#00ffff' },
  { id: 'preset_color_5', label: 'Blue (RYB)', color: '#0038ff' },
  { id: 'preset_color_6', label: 'Fuchsia', color: '#ff00f5' },
]

const getColor = (color) => {
  const currenColor = getColorFromString(color) ?? getColorFromString(COLOR)
  currenColor.a = Math.round(currenColor?.a)
  return currenColor
}

const ColorWithPicker = ({ id, color, defaultColor, onChange, disabled, alpha = 'alpha', alphaRange, ...rest }) => {
  const [ currentColor, setCurrentColor ] = useState(() => getColor(defaultColor ?? color))
  const [ showPicker, { toggle: toggleShowPicker } ] = useBoolean(false)
  const buttonId = useId('callout-button')

  const onColorChange = useCallback((event, color) => {
    if (alphaRange) {
      if (color.a > alphaRange.max) {
        color.a = alphaRange.max
      }
      if (color.a < alphaRange.min) {
        color.a = alphaRange.min
      }
    }
    setCurrentColor({ ...color })
  }, [ setCurrentColor, alphaRange ])

  const onDismissHandler = useHandler(() => {
    toggleShowPicker()
    const value = `#${currentColor.hex}${Math.round(currentColor.a / 100 * 255).toString(16).padStart(2, '0')}`
    onChange && onChange(id, value)
  })

  const onSwatchColorChange = useCallback((event, id, color) => {
    setCurrentColor(getColorFromString(`${color}${alpha === 'none' ? '' : '33'}`))
    setTimeout(onDismissHandler, 0)
  }, [ setCurrentColor, onDismissHandler, alpha ])

  const onClickHandler = useHandler(() => {
    showPicker ? onDismissHandler() : toggleShowPicker()
  })

  const borderColor = useMemo(() => disabled ? 'gray' : currentColor?.hex ? `#${currentColor.hex}` : 'black',
    [ disabled, currentColor ])

  const currentPresetId = useMemo(() => PRESET_COLORS.find(({ color }) => color === `#${currentColor.hex}`)?.id,
    [ currentColor ])

  const onToggle = useCallback(() => {
    setCurrentColor({ ...currentColor })
  }, [ currentColor, setCurrentColor ])

  return (
    <>
      <DefaultButton
        style={{
          borderColor,
          borderWidth: 2,
          backgroundColor: disabled ? 'silver' : currentColor?.str,
          height: 30,
          width: 120,
        }}
        id={buttonId}
        onClick={onClickHandler}
        disabled={disabled}
        {...rest}
      />
      {showPicker && (
        <Callout
          calloutWidth={300}
          directionalHint={DirectionalHint.bottomLeftEdge}
          target={`#${buttonId}`}
          onDismiss={onDismissHandler}
          // setInitialFocus // Do not focus the first color when open
        >
          <StackItem className="capex-color-picker-swatch">
            <SwatchColorPicker
              columnCount={6}
              colorCells={PRESET_COLORS}
              onChange={onSwatchColorChange}
              selectedId={currentPresetId}
              cellHeight={28}
              cellWidth={28}
              cellMargin={14}
            />
          </StackItem>
          <Accordion collapsible multiple onToggle={onToggle}>
            <AccordionItem value={'single'}>
              <AccordionHeader expandIconPosition="end" className="capex-color-picker-header">
                Color Picker
              </AccordionHeader>
              <AccordionPanel className="capex-color-picker-panel">
                <ColorPicker
                  color={currentColor}
                  onChange={onColorChange}
                  alphaType={alpha}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Callout>
      )}
    </>
  )
}

export default ColorWithPicker
