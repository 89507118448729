import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  loadTRFData, selectUpdatedValues, setUpdatedValues,
  selectLoadedView,
} from '../../features/trf/trfSlice'
import { useConfirm } from '../common/Confirm'
import {
  PERIOD_MONTH,
  PERIOD_YEAR,
} from '../../features/trf/utils'
import HeaderContext from '../../layout/context/HeaderContext'
import { activeProjectId } from '../../features/projects/projectsSlice'
import { empty } from '../../utils/format'
import TRFTable from './TRFTable'

import './TRFTable.css'

const TRF = () => {
  const { renderConfirm, msg, ask } = useConfirm()

  const dispatch = useDispatch()
  const { vectorMapId: activeView } = useParams()
  const history = useHistory()
  const projectId = useSelector(activeProjectId)
  const updated = useSelector(selectUpdatedValues)
  const loadedView = useSelector(selectLoadedView)

  useEffect(() => {
    const unblock = history.block((targetLocation) => {
      if (updated) {
        const action = async () => {
          await dispatch(setUpdatedValues(null))
          await dispatch(loadTRFData(activeView))
          history.push(targetLocation.pathname)
        }
        ask(
          action,
          empty,
          null,
          {
            title: 'Confirmation',
            messages: [
              'There are unsaved changes!',
              'Do you want to continue?',
            ],
            textYesBtn: 'Yes',
            textNoBtn: 'No',
          },
        )
      }
      return !updated
    })

    return () => {
      unblock()
    }
  }, [ history, updated, dispatch, ask, activeView ])

  useEffect(() => {
    const beforeUnload = (e) => {
      if (updated) {
        e.preventDefault()
        e.returnValue = true
      }
    }

    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [ updated ])

  useEffect(() => {
    if (!activeView) {
      history.replace(`/${projectId || '_'}/trf/monthly`)
    }
  }, [ history, projectId, activeView ])

  useEffect(() => {
    const action = async () => {
      await dispatch(setUpdatedValues(null))
      if (!updated && loadedView !== activeView) {
        dispatch(loadTRFData(activeView))
      }
    }
    const actionNo = () => {
      history.push(`/${projectId}/trf/${activeView === PERIOD_MONTH ? PERIOD_YEAR : PERIOD_MONTH}`)
    }
    if (activeView) {
      if (loadedView !== activeView && updated) {
        ask(
          action,
          actionNo,
          null,
          {
            title: 'Confirmation',
            messages: [
              'There are unsaved changes!',
              'Do you want to continue?',
            ],
            textYesBtn: 'Yes',
            textNoBtn: 'No',
          },
        )
      } else if (!updated) {
        action()
      }
    }
  }, [ dispatch, activeView, updated, projectId, ask, history, loadedView ])

  const renderTable = useMemo(() => {
    return (
      <HeaderContext.Consumer>
        {({ trfActions, setTrfActions }) => (
          <TRFTable activeView={activeView} msg={msg} trfActions={trfActions} setTrfActions={setTrfActions} />
        )}
      </HeaderContext.Consumer>
    )
  }, [ msg, activeView ])

  return (
    <div className={'container-trf'}>
      {renderTable}
      {renderConfirm()}
    </div>
  )
}

export default TRF
