import { pad } from '../../../utils/date'
import { KEYS } from '../constants'
import { getColumnIndexById } from './settings'

export const searchesLastNumberProjects = (projects = [], searchesName) => {
  const column = Array.isArray(projects?.[0]) ? getColumnIndexById(KEYS.PROJECT_NAME) : KEYS.PROJECT_NAME
  return projects.reduce((maxNumber, project) => {
    const projectName = project[column] ?? ''
    if (projectName.startsWith(searchesName)) {
      const number = parseInt(projectName.slice(searchesName.length))
      if (Number.isNaN(number)) {
        return maxNumber
      }
      return maxNumber <= number ? number + 1 : maxNumber
    }
    return maxNumber
  }, 1)
}

export const generateNameProject = (user, projects) => {
  const today = new Date(Date.now())
  const date = `${today.getFullYear()}${pad(today.getMonth() + 1)}${pad(today.getDate())}_${pad(today.getHours())}${pad(today.getMinutes())}`
  const role = user?.[KEYS.ROLES]?.[0] ?? ''
  const projectName = `${role}_${user?.[KEYS.NAME] ?? ''}_${date}_`
  const lastNumber = searchesLastNumberProjects(projects, projectName)
  return `${projectName}${lastNumber}`
}

export const findNextProjectNameIndex = (prefix, projects, name) => {
  if (!projects || projects.length === 0) {
    return 0
  }

  const reg = new RegExp(prefix + '_?(\\d*) ' + name)
  const next = projects.reduce((agg, project) => {
    const match = project[getColumnIndexById(KEYS.DESCRIPTION)]?.match(reg)
    if (match && match.length > 1) {
      const index = parseInt(match[1])
      if (index > agg) {
        return index
      }
    }
    return agg
  }, 0)
  return next > 0 ? next + 1 : 0
}

//
const regNumEnd = /\((\d*)\)$/
const strRegEnd = '\\((\\d*)\\)$'
const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
//
const getNumMin = (projects, nameProject, numCopy = 1) => {
  const descriptionIdx = Array.isArray(projects[0]) ? getColumnIndexById(KEYS.DESCRIPTION) : KEYS.DESCRIPTION
  const nameProjectReg = escapeRegExp(nameProject)
  const regFindCopyProject = new RegExp('^' + nameProjectReg + strRegEnd)
  const numsCopy = projects
    .map((project) => {
      const match = project[descriptionIdx]?.match(regFindCopyProject)
      return match?.[1] ? parseInt(match?.[1]) : null
    })
    .filter(Boolean)
    .sort((a, b) => a - b)
  return numsCopy.reduce((agg, num) => num === agg ? num + 1 : agg, numCopy)
}
const getNumMax = (projects, nameProject, numCopy = 0) => {
  const descriptionIdx = Array.isArray(projects[0]) ? getColumnIndexById(KEYS.DESCRIPTION) : KEYS.DESCRIPTION
  const nameProjectReg = escapeRegExp(nameProject)
  const regFindCopyProject = new RegExp('^' + nameProjectReg + strRegEnd)
  const numMax = projects.reduce((agg, project) => {
    const match = project[descriptionIdx]?.match(regFindCopyProject)
    if (match?.[1]) {
      const num = parseInt(match?.[1])
      if (num > agg) {
        return num
      }
    }
    return agg
  }, numCopy)
  return numMax + 1
}
// определение последнего номера копии в имени(DESCRIPTION) проекта
export const findProjectName = (projects, name, isCopy) => {
  if (!Array.isArray(projects) || projects.length === 0) {
    return isCopy ? `${name}(1)` : name
  }
  const descriptionIdx = Array.isArray(projects[0]) ? getColumnIndexById(KEYS.DESCRIPTION) : KEYS.DESCRIPTION
  const numCopyS = name.match(regNumEnd)
  if (numCopyS?.[1]) { // в имени содержиться индекс копии
    const numCopyStr = numCopyS?.[1]
    const nameProject = name.slice(0, -numCopyStr.length - 2)
    const numCopy = parseInt(numCopyStr)
    const newNumCopy = isCopy ? getNumMax(projects, nameProject, numCopy) : getNumMin(projects, nameProject, numCopy)
    return `${nameProject}(${newNumCopy})`
  }
  // проверка на существование такого имени
  const projectEqual = projects.some((project) => {
    return project[descriptionIdx] === name
  })
  if (!projectEqual) { // имя уникальное
    return name
  }
  const newNumCopy = isCopy ? getNumMax(projects, name) : getNumMin(projects, name)
  return `${name}(${newNumCopy})`
}
