import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import area from '@turf/area'
import circle from '@turf/circle'
import { polygon, multiPolygon } from '@turf/helpers'
import { TextField } from '@fluentui/react/lib/TextField'
import { Label } from '@fluentui/react/lib/Label'
import { Dropdown, Stack } from '@fluentui/react'
import tokml from 'tokml'
import { DefaultButton } from '../../common/Button'
import { arrayDepth, serializeGeometry, parseGeometry, formatItem, parseItem } from '../../../utils/geo'
import { selectCoordinatesFormat } from '../../../features/settings/settingsSlice'
import { fileFormat } from '../../../constants/common'
import { dataToZip, kmlToFile } from '../../../utils/export'
import { polygonToGeoJSON, geoJsonToMidMif } from '../../../utils/convert'
import { selectUserAccess } from '../../../features/login/loginSlice'
import { selectIsMyProject } from '../../../features/projects/projectsSlice'
import { addTaskType, setTaskTypeCompleted, setTaskTypeFailed, TASK_TYPES } from '../../../features/taskLog'
import { EVENT_TYPE, saveEventLog, STATUS_ERROR, STATUS_PENDING } from '../../../features/eventLog/eventLogSlice'
import { EDIT_POLYGONS } from '../../../constants/access'
import {
  ID_GEO_ZONES_COMPUTATION, ID_GEO_ZONES_FILTERING, ID_GEO_ZONES_FOCUS, MID_MIF_EXT,
} from '../../../constants/geo'

import './PolygonProperties.css'
import { LOCALE } from '../../../utils/format'

const POLYGON = 'polygon'

const toPolygon = (rings) => arrayDepth(rings) > 3 ? multiPolygon(rings) : polygon(rings)

const formCircleGeometry = ({ lng, lat }, radius) =>
  circle([ lng, lat ], radius / 1000, { steps: 256 }).geometry.coordinates

const useTabKey = (e) => {
  if (e.key === 'Tab') {
    e.preventDefault()
    const textArea = e.currentTarget
    const start = textArea.selectionStart
    const end = textArea.selectionEnd

    // set textarea value to: text before caret + tab + text after caret
    textArea.value = `${textArea.value.substring(0, start)}\t${textArea.value.substring(end)}`

    // put caret at right position again
    textArea.selectionStart = textArea.selectionEnd = start + 1
  }
}

const dropdownStyles = {
  dropdown: {
    width: 160,
  },
  label: {
    paddingBottom: 2,
  },
}

const PolygonProperties = ({ data, modified, onChange, onValid }) => {
  const format = useSelector(selectCoordinatesFormat, shallowEqual)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const isMyProject = useSelector(selectIsMyProject)
  const dispatch = useDispatch()

  const [ errorMessage, setErrorMessage ] = useState(null)
  const [ radiusErrorMessage, setRadiusErrorMessage ] = useState(null)
  const [ latitudeErrorMessage, setLatitudeErrorMessage ] = useState(null)
  const [ longitudeErrorMessage, setLongitudeErrorMessage ] = useState(null)
  const [ localData, setLocalData ] = useState(data)
  const [ selectType, setSelectType ] = useState('kml')

  const onChangeType = useCallback((_, v) => {
    setSelectType(v.key)
  }, [])

  const calculatedArea = useMemo(() => area(toPolygon(data.geometry)), [ data.geometry ])

  const polygonIsValid = useMemo(() => !errorMessage && calculatedArea > 0, [ calculatedArea, errorMessage ])

  const [ radius, setRadius ] = useState(Math.round(data.properties?.radius))
  const [ center, setCenter ] = useState(data.properties?.center)
  const [ radiusText, setRadiusText ] = useState(radius?.toLocaleString(LOCALE))

  const [ longitudeText, setLongitudeText ] = useState(center?.lng && formatItem(center?.lng, 'lng', format))
  const [ latitudeText, setLatitudeText ] = useState(center?.lat && formatItem(center?.lat, 'lat', format))

  const circle = useMemo(() => radius !== undefined && center !== undefined, [ radius, center ])

  useEffect(() => {
    onValid && onValid(polygonIsValid && !radiusErrorMessage && !latitudeErrorMessage && !longitudeErrorMessage)
  }, [ onValid, polygonIsValid, radiusErrorMessage, latitudeErrorMessage, longitudeErrorMessage ])

  const surface = useMemo(
    () => polygonIsValid ? `${(Math.round(calculatedArea / 1e3) / 1e3).toLocaleString(LOCALE)}` : 'Invalid polygon',
    [ calculatedArea, polygonIsValid ],
  )

  const serialized = useMemo(() => serializeGeometry(data.geometry, format), [ data, format ])

  const textAreaValue = useMemo(() =>
    circle ? { value: serialized } : { defaultValue: serialized, onKeyDown: useTabKey }, [ serialized, circle ])

  const exportDisabled = useMemo(() =>
    !polygonIsValid || longitudeErrorMessage || latitudeErrorMessage || radiusErrorMessage || modified,
  [ polygonIsValid, longitudeErrorMessage, latitudeErrorMessage, radiusErrorMessage, modified ])

  const onGeometryChange = useCallback((event, text) => {
    const geometry = parseGeometry(text)
    if (geometry) {
      setErrorMessage(null)
      const newData = { ...localData, geometry }
      setLocalData(newData)
      onChange(newData)
    } else {
      setErrorMessage('Error Parsing Geometry')
    }
  }, [ setErrorMessage, onChange, localData ])

  const onDescriptionChange = useCallback((event, text) => {
    const newData = { ...localData, properties: { ...(localData.properties ?? {}), description: text } }
    setLocalData(newData)
    onChange(newData)
  }, [ localData, onChange ])

  const onRadiusChange = useCallback((event, value) => {
    const text = value?.replace(' ', '')
    setRadiusText(value?.toLocaleString(LOCALE))
    const radius = Number(text)
    if (!isNaN(radius) && radius > 0 && radius <= 10000000) {
      setRadiusErrorMessage(null)
      setRadius(radius)
      const geometry = formCircleGeometry(center, radius)
      const newData = { ...localData, geometry, properties: { ...localData.properties, radius } }
      setLocalData(newData)
      onChange(newData)
    } else {
      setRadiusErrorMessage('Error Parsing Radius')
    }
  }, [ setRadius, setRadiusErrorMessage, setRadiusText, center, localData, onChange ])

  const onLongitudeChange = useCallback((event, text) => {
    setLongitudeText(text)
    const longitude = parseItem(text)
    if (longitude !== null && !isNaN(longitude) && longitude >= -180 && longitude <= 180) {
      setLongitudeErrorMessage(null)
      const newCenter = { ...center, lng: longitude }
      setCenter(newCenter)
      const geometry = formCircleGeometry(newCenter, radius)
      const newData = { ...localData, geometry, properties: { ...localData.properties, center: newCenter } }
      setLocalData(newData)
      onChange(newData)
    } else {
      setLongitudeErrorMessage('Error Parsing Longitude')
    }
  }, [ center, setCenter, setLongitudeErrorMessage, setLongitudeText, radius, localData, onChange ])

  const onLatitudeChange = useCallback((event, text) => {
    setLatitudeText(text)
    const latitude = parseItem(text)
    if (latitude !== null && !isNaN(latitude) && latitude >= -90 && latitude <= 90) {
      setLatitudeErrorMessage(null)
      const newCenter = { ...center, lat: latitude }
      setCenter(newCenter)
      const geometry = formCircleGeometry(newCenter, radius)
      const newData = { ...localData, geometry, properties: { ...localData.properties, center: newCenter } }
      setLocalData(newData)
      onChange(newData)
    } else {
      setLatitudeErrorMessage('Error Parsing Latitude')
    }
  }, [ center, setCenter, setLatitudeErrorMessage, setLatitudeText, radius, localData, onChange ])

  const onExport = useCallback(() => {
    const description = localData.properties?.description ?? ''
    const { id, geometry } = localData
    const geoJson = polygonToGeoJSON([ {
      id: POLYGON,
      coordinates: geometry,
      properties: { description, area: `${surface} km²` },
    } ])
    const taskType = id.startsWith(ID_GEO_ZONES_FILTERING)
      ? TASK_TYPES.exportFilteringZone
      : id.startsWith(ID_GEO_ZONES_FOCUS)
        ? TASK_TYPES.exportFocusZone
        : id.startsWith(ID_GEO_ZONES_COMPUTATION)
          ? TASK_TYPES.exportComputationZone
          : TASK_TYPES.exportPolygon
    const callbackFailed = () => {
      dispatch(setTaskTypeFailed(taskType))
      dispatch(saveEventLog(EVENT_TYPE.polygonExport, null, STATUS_ERROR))
    }
    const callbackCompleted = () => {
      dispatch(setTaskTypeCompleted(taskType))
      dispatch(saveEventLog(EVENT_TYPE.polygonExport))
    }
    dispatch(addTaskType(taskType, description))
    dispatch(saveEventLog(EVENT_TYPE.polygonExport, `: ${selectType}`, STATUS_PENDING))
    switch (selectType) {
      case 'kmz': {
        const kmlData = tokml(geoJson, { documentName: id, documentDescription: description })
        dataToZip(kmlData, POLYGON, 'kml', 'kmz', callbackFailed, callbackCompleted)
        break
      }
      case 'kml': {
        const fileName = `${POLYGON}.kml`
        const kmlData = tokml(geoJson, { documentName: id, documentDescription: description })
        kmlToFile(kmlData, fileName)
        callbackCompleted()
        break
      }
      case 'mif': {
        const { mid, mif } = geoJsonToMidMif(geoJson, [ { id: 'description', label: 'description', type: 'string' } ])
        dataToZip([ mid, mif ], POLYGON, MID_MIF_EXT, 'zip', callbackFailed, callbackCompleted)
        break
      }
      default: callbackFailed()
    }
  }, [ dispatch, localData, selectType, surface ])

  const multilineProps = useMemo(() => data?.type === 'targetCoverage'
    ? { multiline: true, rows: 3, style: { maxHeight: '160px' } }
    : {},
  [ data ])

  const isTargetCoverage = data?.type === 'targetCoverage'
  const description = data.properties?.description ?? ''

  return (
    <>
      {isTargetCoverage && (
        <TextField hidden={!isTargetCoverage}
          label="Target coverage for Sector"
          readOnly
          value={data?.name}
        />
      )}
      <TextField
        label="Area, km²"
        readOnly
        value={surface}
        className={polygonIsValid ? '' : 'invalid'}
      />
      {circle && (
        <>
          <TextField
            label="Center, longitude"
            value={longitudeText}
            onChange={onLongitudeChange}
            errorMessage={longitudeErrorMessage}
          />
          <TextField
            label="Center, latitude"
            value={latitudeText}
            onChange={onLatitudeChange}
            errorMessage={latitudeErrorMessage}
          />
          <TextField
            label="Radius, m"
            value={radiusText}
            onChange={onRadiusChange}
            errorMessage={radiusErrorMessage}
          />
        </>
      )}
      <TextField
        label="Geometry"
        multiline
        autoAdjustHeight
        onChange={onGeometryChange}
        errorMessage={errorMessage}
        readOnly={isTargetCoverage && (!userAccess[EDIT_POLYGONS] || !isMyProject)}
        className={circle ? 'coordinates-editor-circle' : 'coordinates-editor'}
        disabled={Boolean(radius || center)}
        {...textAreaValue}
      />
      <TextField
        label={isTargetCoverage ? 'Coverage info' : 'Description'}
        defaultValue={description}
        onChange={onDescriptionChange}
        {...multilineProps}
        readOnly={isTargetCoverage && (!userAccess[EDIT_POLYGONS] || !isMyProject)}
      />
      <Label>{'Export polygon to file'}</Label>
      <Stack style={{ border: 'solid 1px', padding: '0 8px 8px 8px' }}>
        <Stack horizontal style={{
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}>
          <Dropdown
            placeholder="Select type file"
            label="File type"
            options={fileFormat}
            styles={dropdownStyles}
            selectedKey={selectType}
            onChange={onChangeType}
            disabled={exportDisabled}
          />
          <DefaultButton
            text="Export"
            onClick={onExport}
            allowDisabledFocus
            style={{ marginLeft: 8 }}
            disabled={exportDisabled}
          />
        </Stack>
      </Stack>
    </>
  )
}

export default PolygonProperties
