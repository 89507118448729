import { useRef, useLayoutEffect } from 'react'

const FUNC_KEY = Symbol('FUNC_KEY')
const ARGS_KEY = Symbol('ARGS_KEY')

/**
 * Хук використовується, якщо треба оголосити функцію, посилання на яку не змінюється.
 * На відміну від useConstant, змінює функцію після перемальовування DOM-елементів
 * Тому цей хук краще використовувати для обробників подій від користувачів
 */
export const useHandler = (f, ...handlerArgs) => {
  const { current } = useRef((...eventArgs) => {
    return (current[FUNC_KEY] ?? f)(...(current[ARGS_KEY] ?? handlerArgs), ...eventArgs)
  })

  useLayoutEffect(() => {
    current[FUNC_KEY] = f
    current[ARGS_KEY] = handlerArgs
  })

  return current
}
