import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MessageBar, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react'
import LoginForm from './LoginForm'
import { checkAuthenticated, selectLoading, selectUser, selectError, selectEmpty } from './loginSlice'

import './styles.css'

const Panel = ({ children }) => (
  <div className="centered center-panel-size">
    {children}
  </div>
)

const UserHolder = ({ children }) => {
  const dispatch = useDispatch()

  const empty = useSelector(selectEmpty)
  const loading = useSelector(selectLoading)
  const user = useSelector(selectUser)
  const error = useSelector(selectError)

  useEffect(() => {
    if (!user) {
      dispatch(checkAuthenticated())
    }
  }, [ dispatch, user ])

  return (
    loading
      ? <Spinner className="centered" size={SpinnerSize.large} />
      : user
        ? children
        : <Panel>
            {error && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={true}
              >
                {String(error)}
              </MessageBar>
            )}
            {empty
              ? null
              : (
                  <LoginForm />
                )
            }
          </Panel>
  )
}

export default UserHolder
