import { DetailsHeader, Stack } from '@fluentui/react'
import React, { useCallback } from 'react'
import { IconButton } from '@fluentui/react/lib/Button'
import { Label } from '@fluentui/react/lib/Label'

const titleSorting = {
  Descending: 'Z-A',
  Ascending: 'A-Z',
  Sort: 'No sorting',
}

const iconStyle = {
  margin: 0,
  fontSize: 20,
}
const iconPropsSelect = {
  iconName: 'CheckboxCompositeReversed',
  style: {
    ...iconStyle,
  },
}
const iconPropsIndeterminate = {
  iconName: 'CheckboxComposite',
  style: {
    ...iconStyle,
    color: 'darkgrey',
  },
}
const iconPropsUnSelect = {
  iconName: 'Checkbox',
  style: {
    ...iconStyle,
  },
}

const TH = ({ column, changeCheckBox, changeSorting, checkBoxDisabled }) => {
  const {
    isSorted,
    isSortedDescending,
    name,
    isSelectable,
    select,
    indeterminate,
  } = column ?? {}

  const iconName = isSorted === true
    ? (isSortedDescending ? 'Descending' : 'Ascending')
    : (isSorted === false ? 'Sort' : null)
  const title = titleSorting[iconName] ?? ''

  const onClickHandler = useCallback(() => {
    // Опрацювати зміну стану Checkbox
    changeCheckBox && changeCheckBox({ ...column, select: !column.select, indeterminate: false })
  }, [ changeCheckBox, column ])

  const onSortingHandler = useCallback(() => {
    changeSorting && changeSorting(column)
  }, [ changeSorting, column ])

  const triStated = !select && indeterminate

  return (
    <Stack
      horizontal
      style={{ alignItems: 'center', height: '100%' }}
    >
      {isSelectable
        ? (
            <IconButton
              className={'align-center'}
              style={{ padding: 0 }}
              title={name}
              iconProps={triStated ? iconPropsIndeterminate : select ? iconPropsSelect : iconPropsUnSelect}
              onClick={onClickHandler}
              disabled={checkBoxDisabled}
            />
          )
        : (
            <Label>{name}</Label>
          )
      }
      <IconButton
        styles={{ fontSize: '1em' }}
        title={title}
        iconProps={{ iconName }}
        onClick={onSortingHandler}
      />
    </Stack>
  )
}

const Header = ({ props, changeCheckBox, changeSorting, checkBoxDisabled }) => {
  const renderColumnHeaderTooltip = useCallback((props, ev) => {
    if (props?.column) {
      return (
        <TH
          column={props.column}
          event={ev}
          changeCheckBox={changeCheckBox}
          changeSorting={changeSorting}
          checkBoxDisabled={checkBoxDisabled}
        />
      )
    }
  }, [ changeCheckBox, changeSorting, checkBoxDisabled ])

  return props
    ? (
        <DetailsHeader
          {...props}
          onRenderColumnHeaderTooltip={renderColumnHeaderTooltip}
        />
      )
    : null
}

export default Header
