import React from 'react'
import { IconSvg } from '../common/icons'

import './AutosaveModeIconButton.css'

const AutosaveModeIconButton = ({ iconName, onClick, ...rest }) => {
  return (
    <button className="autosave-mode-icon-button" onClick={onClick} {...rest}>
      <IconSvg name={iconName} />
    </button>
  )
}

export default AutosaveModeIconButton
