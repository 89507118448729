import { REPORT_COVERAGE_BY_SECTOR, REPORT_COVERAGE_BY_SIGNAL, REPORT_NEIGHBORS } from '../../constants/reports'

export const NOTIFICATIONS_ID_FIELD = 'table.notifications.id'
export const NOTIFICATIONS_STATUS_FIELD = 'table.notifications.status'
export const NOTIFICATIONS_DATE_FIELD = 'table.notifications.date'
export const NOTIFICATIONS_TYPE_FIELD = 'table.notifications.type'
export const NOTIFICATIONS_USER_FIELD = 'table.notifications.user'
export const NOTIFICATIONS_PROJECT_ID_FIELD = 'table.notifications.project.id'
export const NOTIFICATIONS_PROJECT_NAME_FIELD = 'table.notifications.project.name'
export const NOTIFICATIONS_PROJECT_PATH_FIELD = 'table.notifications.project.path'
export const NOTIFICATIONS_GC_ID_FIELD = 'table.notifications.bgc.id'
export const NOTIFICATIONS_BC_ID_FIELD = 'table.notifications.bc.id'
export const NOTIFICATIONS_BC_NAME_FIELD = 'table.notifications.bc.name'
export const NOTIFICATIONS_BC_PATH_FIELD = 'table.notifications.bc.path'
export const NOTIFICATIONS_REPORT_ID_FIELD = 'table.notifications.report.id'
export const NOTIFICATIONS_REPORT_TYPE_FIELD = 'table.notifications.report.type'
export const NOTIFICATIONS_ERROR_FIELD = 'table.notifications.error'
export const NOTIFICATIONS_CONTENT_SIZE_FIELD = 'table.notifications.content.size'

export const STATUS_UNREAD = 'NEW'
export const STATUS_READ = 'READ'

export const TYPE_PROJECT_SHARED = 'PROJECT_SHARED'
export const TYPE_PROJECT_DELETED = 'PROJECT_DELETED'
export const TYPE_BC_CALCULATE_SNC_COMPLETED = 'BC_CALCULATE_SNC_COMPLETED'
export const TYPE_BC_SENT_FOR_APPROVE = 'BC_SENT_FOR_APPROVE'
export const TYPE_BC_APPROVED = 'BC_APPROVED'
export const TYPE_BC_REJECTED = 'BC_REJECTED'
export const TYPE_REPORT_COMPLETED = 'REPORT_COMPLETED'

export const USER_MESSAGES = {
  [TYPE_PROJECT_SHARED]: 'Project shared',
  [TYPE_BC_CALCULATE_SNC_COMPLETED]: 'Calculate SNC completed',
  [TYPE_BC_SENT_FOR_APPROVE]: 'BC sent for approve',
  [TYPE_BC_APPROVED]: 'BC approved',
  [TYPE_BC_REJECTED]: 'BC rejected',
  [TYPE_REPORT_COMPLETED]: 'Report completed',
}

export const TYPES_BC_TO_UPDATE = [
  TYPE_BC_CALCULATE_SNC_COMPLETED,
  TYPE_BC_APPROVED,
  TYPE_BC_REJECTED,
]

export const TYPES_REPORT_TO_UPDATE = [
  TYPE_REPORT_COMPLETED,
]

export const fields = [
  {
    id: NOTIFICATIONS_ID_FIELD,
    label: 'id',
    description: 'Notification id',
    type: 'uuid',
    size: 50,
    hidden: true,
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_STATUS_FIELD,
    label: 'status',
    description: 'Notification status',
    type: 'enum',
    'enum-values': 'Unread,Read',
    hidden: true,
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_DATE_FIELD,
    label: 'Date',
    description: 'Date',
    type: 'datetime',
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_TYPE_FIELD,
    label: 'Type',
    description: 'Type',
    type: 'enum',
    'enum-values': 'Approving,Approved,Rejected,BC_SENT_FOR_APPROVE',
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_USER_FIELD,
    label: 'User',
    description: 'User',
    type: 'string',
    size: 50,
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_PROJECT_ID_FIELD,
    label: 'Project Id',
    description: 'Project Id',
    type: 'uuid',
    size: 50,
    hidden: true,
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_PROJECT_NAME_FIELD,
    label: 'Project name',
    description: 'Project name',
    type: 'string',
    size: 50,
    hidden: true,
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_PROJECT_PATH_FIELD,
    label: 'Project',
    description: 'Project',
    type: 'anchor',
    size: 50,
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_GC_ID_FIELD,
    label: 'Group case id',
    description: 'Group case id',
    type: 'uuid',
    size: 50,
    hidden: true,
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_BC_ID_FIELD,
    label: 'Business case id',
    description: 'Business case id',
    type: 'uuid',
    size: 50,
    hidden: true,
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_BC_NAME_FIELD,
    label: 'Business case name',
    description: 'Business case name',
    type: 'string',
    size: 50,
    hidden: true,
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_BC_PATH_FIELD,
    label: 'Business case',
    description: 'Business case',
    type: 'anchor',
    size: 50,
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_REPORT_ID_FIELD,
    label: 'Download Report',
    description: 'Download Report',
    type: 'string',
    size: 50,
    page: 'Notifications',
  },
  {
    id: NOTIFICATIONS_REPORT_TYPE_FIELD,
    label: 'Report type',
    description: 'Report type',
    type: 'enum',
    'enum-values': `${REPORT_COVERAGE_BY_SIGNAL},${REPORT_COVERAGE_BY_SECTOR},${REPORT_NEIGHBORS}`,
    page: 'Notifications',
    hidden: true,
  },
  {
    id: NOTIFICATIONS_ERROR_FIELD,
    label: 'Error',
    description: 'Error',
    type: 'string',
    size: 50,
    page: 'Notifications',
    hidden: true,
  },
  {
    id: NOTIFICATIONS_CONTENT_SIZE_FIELD,
    label: 'Content size',
    description: 'Content size',
    type: 'integer',
    size: 50,
    page: 'Notifications',
    hidden: true,
  },
]

export const MAX_NOTIFICATIONS_DISPLAYED = 20
export const CARRY_OVER_REMAINING_NOTIFICATIONS = true
