import { fetch } from './rest'

export const listVectorMaps = (projectId = '_') => fetch(`/api/project/${projectId}/geomaps`)

export const uploadVectorMap = ({ projectId, formData }) => fetch(`/api/project/${projectId}/geomaps`, 'POST', formData)

export const loadVectorMapGeoJson = (id) => fetch(`/api/geomaps/${id}/geojson`)

export const loadVectorMapGeoAttributes = (id) => fetch(`/api/geomaps/${id}/properties`)

export const updateVectorMapGeoAttributes = ({ id, data }) => fetch(`/api/geomaps/${id}/properties`, 'PUT', data)

export const deleteVectorMap = (id) => fetch(`/api/geomaps/${id}`, 'DELETE')

export const makeVectorMapDefault = (id) => fetch(`/api/geomaps/${id}/default`, 'PATCH')
