import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Stack, StackItem, DetailsList, DetailsListLayoutMode, DetailsRow } from '@fluentui/react'
import { DialogFooter } from '@fluentui/react/lib/Dialog'
import { SelectionMode } from '@fluentui/utilities'
import { EVENT_TYPE, saveEventLog } from '../../../features/eventLog/eventLogSlice'
import { DefaultButton, PrimaryButton } from '../../common/Button'
import { tableToExcel } from '../../../utils/export'
import { getCssVar } from '../../../utils/css'
import { useConfirm } from '../../common/Confirm'
import { confirm as tConfirm } from '../../common/Confirm/constants'
import { alwaysTrue, getItemKey } from '../../../utils/format'
import Header from './Header'
import Footer from './Footer'
import TD from './TD'
import { updateTableStyles } from './styles'

const stylesOddRow = {
  root: {
    backgroundColor: getCssVar('--CTA-secondary-pressed'),
  },
}

const Component = ({
  id,
  onCancel,
  columns: columnsInit,
  changeCheckBoxHeader,
  items: itemsInit,
  footer,
  onUpdateItem,
  onApply,
}) => {
  const dispatch = useDispatch()
  const { renderConfirm, confirm } = useConfirm()

  const [ columns, setColumns ] = useState()
  const [ items, setItems ] = useState([])

  useEffect(() => {
    setItems(itemsInit ?? [])
  }, [ itemsInit ])

  useEffect(() => {
    setColumns(columnsInit)
  }, [ columnsInit ])

  const changeSortingHandler = useCallback((column) => {
    const {
      isSorted,
      isSortedDescending,
    } = column ?? {}
    const newColumns = columns.slice()
    let currentColumn = null
    newColumns.forEach((currCol) => {
      if (currCol.key === column.key) {
        currentColumn = currCol
        if (!isSorted) {
          currCol.isSorted = true
          currCol.isSortedDescending = false
        } else if (!isSortedDescending) {
          currCol.isSorted = true
          currCol.isSortedDescending = true
        } else {
          currCol.isSorted = false
        }
      } else {
        currCol.isSorted = false
        currCol.isSortedDescending = false
      }
    })
    setColumns(newColumns)
    if (currentColumn && currentColumn.isSorted) {
      setItems(copyAndSort(items, column.key, currentColumn?.isSortedDescending))
    } else {
      setItems(copyItemsInit(itemsInit, items))
    }
  }, [ columns, items, itemsInit ])

  const renderDetailsHeader = useCallback((props) => (
    <Header
      props={props}
      changeCheckBox={changeCheckBoxHeader}
      changeSorting={changeSortingHandler}
      checkBoxDisabled={items.length === 0}
      items={items}
    />
  ), [ changeCheckBoxHeader, changeSortingHandler, items ])

  const renderDetailsFooter = useCallback((props) => (
    <Footer
      props={props}
      onApply={onApply}
      footer={footer}
    />
  ), [ footer, onApply ])

  const renderItemColumn = useCallback((item, index, column) => (
    <TD
      item={item}
      index={index}
      column={column}
      onCheck={null}
      onUpdateItem={onUpdateItem}
    />
  ), [ onUpdateItem ])

  const renderRow = useCallback((props) => props
    ? (
        <DetailsRow
          {...props}
          styles={props.itemIndex % 2 === 0 ? {} : stylesOddRow}
        />
      )
    : null,
  [])

  const onCopyToClipboard = useCallback((e) => {
    tableToClipboard(e, columns, items)
    dispatch(saveEventLog(EVENT_TYPE.updateDBExport, `: Clipboard, Page "${id}", ${items.length} lines`))
    confirm(
      null,
      {
        title: 'Export',
        messages: [ 'Table data copied to clipboard.' ],
        textNoBtn: tConfirm.ok,
      },
    )
  }, [ columns, confirm, items, dispatch, id ])

  const onExportToXLSX = useCallback(() => {
    const headers = columns.map((column) => {
      return column.data?.isChecked != null ? [ `Check ${column.name}`, column.name ] : column.name
    }).flat()
    const cells = items.map((row) => {
      return columns.map((column) => {
        const checked = column.data?.isChecked != null ? (row.select === column.fieldName ? 'X' : '') : null
        const item = row[column.fieldName]?.toString()
        return checked != null ? [ checked, item ] : item
      }).flat()
    })
    tableToExcel(headers, cells, alwaysTrue, id, `${id}.xlsx`)
    dispatch(saveEventLog(EVENT_TYPE.updateDBExport, `: Excel file, Page "${id}", ${items.length} lines`))
  }, [ columns, id, items, dispatch ])

  return (
    <Stack style={{ height: '100%' }}>
      <StackItem className={'toContentViewport'}>
        <DetailsList
          items={items}
          compact={true}
          columns={columns}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selectionMode={SelectionMode.none}
          onRenderDetailsHeader={renderDetailsHeader}
          onRenderRow={renderRow}
          onRenderItemColumn={renderItemColumn}
          onRenderDetailsFooter={renderDetailsFooter}
          getKey={getItemKey}
          setKey={id}
          styles={updateTableStyles}
        />
      </StackItem>
      <DialogFooter>
        <DefaultButton onClick={onCopyToClipboard} text="Export to Clipboard" />
        <DefaultButton onClick={onExportToXLSX} text="Export to MS Excel file" />
        <PrimaryButton onClick={onCancel} text="Close" />
      </DialogFooter>
      {renderConfirm()}
    </Stack>
  )
}

export default Component

function tableToClipboard (e, columns, items) {
  const columnC = columns.map((column) => {
    return column.data?.isChecked != null ? `check ${column.name}\t${column.name}` : column.name
  }).join('\t')
  const dataC = items.map((row) => {
    return columns.map((column) => {
      const checked = column.data?.isChecked != null ? (row.select === column.fieldName ? 'X\t' : '\t') : null
      return `${checked != null ? checked : ''}${row[column.fieldName]?.toString()}`
    }).join('\t')
  }).join('\n')
  e.view.navigator.clipboard.writeText(columnC + '\n' + dataC)
  e.preventDefault()
}

function copyAndSort (items, columnKey, isSortedDescending) {
  return items.slice(0)
    .sort((a, b) => ((isSortedDescending ? a[columnKey] < b[columnKey] : a[columnKey] > b[columnKey]) ? 1 : -1))
}

function copyItemsInit (itemsInit, items) {
  return itemsInit.map((itemInit) => (items.find((item) => (item.key === itemInit.key)))).filter(Boolean)
}
