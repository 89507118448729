import Handsontable from 'handsontable'
import { ColumnSorting } from 'handsontable/plugins'
import moment from 'moment'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import { HotColumn, HotTable } from '@handsontable/react'
import { useBoolean } from '@fluentui/react-hooks'
import {
  saveSettingToServer, selectCoordinatesFormat,
  selectSettings,
  settingPropertyOnPath,
  SETTINGS_KEYS,
} from '../../features/settings/settingsSlice'
import { isNumberType, makeNestedHeaders } from '../../utils/grid'
import { selectOfflineMode } from '../../features/loading/loadingSlice'
import { selectCanRedo, selectCanUndo, doUndo, doRedo } from '../../features/undo/undoSlice'
import { selectGroupFilters } from '../../features/network/networkSlice'
import { findIndex } from '../../features/network/indexing'
import { selectPanel, setPanel } from '../../features/panel/panelSlice'
import { formatItem } from '../../utils/geo'
import {
  STATUS_DRAFT,
  UPPER_CASE_ATTR,
  COMPOSITE_INDICES,
} from '../../constants/network'
import { getCssVar } from '../../utils/css'
import Search from '../Panels/Search'
import Replace from '../Panels/Replace'
import Columns from '../Panels/Columns'
import { useConfirm } from './Confirm'
import {
  getTextWidth,
  isValueInRange,
  MIN_WIDTH_COLUMN,
  oldCellTypeNumeric,
  PADDING_DEFAULT,
  stringValueToBoolean,
  validatorText,
  WIDTH_COLUMN_DEFAULT,
  WIDTH_SORTING,
} from './utils/hotTable'

import './handsontableCapex.css'
import './Grid.css'

const SELECT = 'hot-table-search-highlight'

const SETTINGS = {
  copyPaste: {
    rowsLimit: Infinity,
  },
  colHeaders: true,
  rowHeaders: true,
  filters: true,
  fillHandle: false,
  columnSorting: true,
  autoColumnSize: false,
  outsideClickDeselects: false,
  dropdownMenu: true,
  // selectionMode: 'range',
  manualColumnResize: true,
  // manualColumnMove: true,
  // multiColumnSorting: true,
  currentRowClassName: 'currentRow',
  width: '100%',
  height: '100%',
  readOnlyCellClassName: 'read-only-cell',
  licenseKey: 'non-commercial-and-evaluation',
  autoWrapCol: false, // блокировка перехода с крайних ячеек на противоположные по столбцу
  autoWrapRow: false, // блокировка перехода с крайних ячеек на противоположные по строке
}

const encodeType = (type, subType, editable, settings) => {
  const result = {
    readOnly: !editable,
  }

  switch (type) {
    case 'checkbox':
    case 'boolean': {
      return {
        ...result,
        type: 'checkbox',
      }
    }
    case 'uuid':
    case 'text':
    case 'string': {
      return {
        ...result,
        type: 'text',
      }
    }
    case 'double': {
      return {
        ...result,
        type: (subType === 'lat' || subType === 'lng') ? 'coordinate' : 'numeric',
      }
    }
    case 'integer':
    case 'int':
    case 'long': { // TODO: precision
      return {
        ...result,
        type: 'numeric',
      }
    }
    case 'enum': {
      return {
        ...result,
        type: 'dropdown',
        source: settings['enum-values']?.split(','),
      }
    }
    default: {
      return {
        ...result,
        type,
      }
    }
  }
}

const encodeSorting = (config) => config
  ? {
      columnSorting: {
        sortEmptyCells: true,
        indicator: true,
        initialConfig: config,
      },
    }
  : {}

const posToRowThenCol = (pos, rowCount, colCount, startRow, startCol) => {
  const row = Math.trunc(pos / colCount)
  return [
    startRow + row,
    startCol + pos - (row * colCount),
  ]
}

const posToColThenRow = (pos, rowCount, colCount, startRow, startCol) => {
  const col = Math.trunc(pos / rowCount)
  return [
    startRow + pos - (col * rowCount),
    startCol + col,
  ]
}

const rowThenColToPos = (row, col, rowCount, colCount, startRow, startCol) => {
  return (row - startRow) * colCount + (col - startCol)
}

const colThenRowToPos = (row, col, rowCount, colCount, startRow, startCol) => {
  return (col - startCol) * rowCount + (row - startRow)
}

const foundValue = (value, search, matchCase, entireCell) => {
  let result = false
  search = search.trim()
  if (search === '') {
    result = value === '' || value === null
  } else if (value !== '' && value !== null) {
    value = value.toString()
    result = matchCase
      ? value.includes(search)
      : value.toLowerCase().includes(search.toLowerCase())
    if (entireCell && result) {
      result = value.length === search.length
    }
  }
  return result
}

const isValueFound = (data, changes, row, col, search, matchCase, entireCell) => {
  let value = data[row][col]
  if (changes) {
    for (const { updates } of changes) {
      for (const { row: changedRow, col: changedCol, value: changedValue } of updates) {
        if (row === changedRow && col === changedCol) {
          value = changedValue
        }
      }
    }
  }
  return foundValue(value, search, matchCase, entireCell)
}

const replaceValue = (value, search, replace, matchCase, entireCell, type) => {
  if (entireCell || search === '' || value === '' || value === null) {
    return replace
  }
  search = search.trim()
  value = value.toString()
  const re = new RegExp(search, `g${matchCase ? '' : 'i'}`)
  let result = value.replace(re, replace)
  if (result === '' && isNumberType(type)) {
    result = null
  }
  return result
}

const isColumnHidden = (hiddenColumns, col) => hiddenColumns?.columns.includes(col)

export const addRowChange = (changes, table, list, row, idFieldIdx) => {
  const id = list[table.toPhysicalRow(row)][idFieldIdx]
  let change = changes.find(({ id: recordId }) => recordId === id)
  if (!change) {
    change = {
      id,
      updates: [],
    }
    changes.push(change)
  }
  return change
}

// Функція, якою ми підміняємо "рідну" функцію обчислення розмірів контейнера контекстного меню таблиці, щоб
// вона коректно працювала з різними значеннями висоти пунктів меню.
function onAfterInit2 () {
  const height = getCssVar('--height-context-menu-tr', 26)
  const wtTable = this.hotMenu.view.wt.wtTable
  const data = this.hotMenu.getSettings().data
  const hiderStyle = wtTable.hider.style
  const holderStyle = wtTable.holder.style
  const currentHiderWidth = parseInt(hiderStyle.width, 10)
  const realHeight = data.reduce((accumulator, value) => accumulator + (value.name === '---------' ? 1 : height), 0)
  holderStyle.width = `${currentHiderWidth + 3}px`
  holderStyle.height = `${realHeight + 3}px`
  hiderStyle.height = holderStyle.height
}

// eslint-disable-next-line react/display-name
const Grid = ({
  data,
  columns,
  onDblClick,
  onInlineEdit,
  onAfterEdit,
  onSelectionEnd,
  onPaste,
  onCut,
  onInlineEditRange,
  idFieldIdx,
  refHot,
  statusFieldIdx,
  dataType,
  filteredColumns,
  manualColumnMove,
  disableSettings,
  cells,
  readOnly,
  isFullViewHeader,
  afterGetColHeader,
  onContextMenu,
  disableHotKeys,
  disableHotKeyEvents,
  initialCell,
  children,
  ...others
}) => {
  const dispatch = useDispatch()
  const { renderConfirm, msg } = useConfirm()

  const {
    colWidths,
    colNotDisplay,
    colTablesMove,
    colSorting,
    [SETTINGS_KEYS.TABLE_DENSITY]: tablesDensity,
  } = useSelector(selectSettings)

  const coordinatesFormat = useSelector(selectCoordinatesFormat, shallowEqual)
  const localFilter = useSelector(selectGroupFilters(dataType))
  const offlineMode = useSelector(selectOfflineMode)
  const canUndo = useSelector(selectCanUndo)
  const canRedo = useSelector(selectCanRedo)
  const panel = useSelector(selectPanel)

  const [ highlight, setHighlight ] = useState(null)

  const [ searchPanelContentDiv, setSearchPanelContentDiv ] = useState(null)
  const [ replacePanelContentDiv, setReplacePanelContentDiv ] = useState(null)
  const [ columnsPanelContentDiv, setColumnsPanelContentDiv ] = useState(null)

  useLayoutEffect(() => {
    const table = refHot?.current?.hotInstance
    if (!table?.getSelected()) {
      setTimeout(() => {
        const table = refHot?.current?.hotInstance
        if (!table?.getSelected()) {
          const row = initialCell?.row || 0
          const col = initialCell?.col || 0
          table?.selectCell(row, col)
        }
      }, 50)
    }
  }, [ refHot, initialCell ])

  useEffect(() => {
    if (disableHotKeys) {
      return
    }
    if (!panel) {
      const table = refHot?.current?.hotInstance
      if (!table?.getSelected()) {
        const row = initialCell?.row || 0
        const col = initialCell?.col || 0
        table?.selectCell(row, col)
      }
      setHighlight(null)
    } else {
      const searchPanelContentDiv = document.getElementById('search-content-container')
      const replacePanelContentDiv = document.getElementById('replace-content-container')
      const columnsPanelContentDiv = document.getElementById('columns-content-container')
      setSearchPanelContentDiv(searchPanelContentDiv)
      setReplacePanelContentDiv(replacePanelContentDiv)
      setColumnsPanelContentDiv(columnsPanelContentDiv)
    }
  }, [ panel, refHot, disableHotKeys, initialCell ])

  // Установка плотности строк в таблице через стили
  useEffect(() => {
    const tableDensity = tablesDensity?.[dataType] ?? '40px'
    const r = document.querySelector(':root')
    r.style.setProperty('--height-tr', tableDensity)
  }, [ dataType, tablesDensity ])

  useEffect(() => {
    Handsontable.cellTypes.registerCellType('coordinate', {
      ...oldCellTypeNumeric,
      renderer: (hotInstance, td, row, column, prop, value, cellProperties) => {
        oldCellTypeNumeric.renderer(hotInstance, td, row, column, prop, value, cellProperties)
        const {
          subType,
        } = cellProperties

        if (subType && coordinatesFormat) {
          td.innerHTML = `<span>${formatItem(value, subType, coordinatesFormat)}</span>`
        } else {
          td.innerHTML = `<span>${value ?? ''}</span>`
        }
      },
    })
  }, [ coordinatesFormat ])

  const [ isInitialise, { setTrue: setInitialise } ] = useBoolean(false)

  const colMove = colTablesMove?.[dataType]
  const notDisplay = colNotDisplay?.[dataType]

  if (data && data[0] && !Array.isArray(data[0])) {
    data = data.map(Object.values)
  }

  // сброс установок по перемещению колонок при некорректных данных
  useEffect(() => {
    if (dataType && Array.isArray(columns) && columns.length > 0 &&
        Array.isArray(colMove) && columns.length !== colMove.length) {
      dispatch(settingPropertyOnPath(undefined, [ SETTINGS_KEYS.COL_TABLES_MOVE, dataType ]))
      dispatch(saveSettingToServer)
    }
  }, [ columns, colMove, dataType, dispatch ])

  const hiddenColumns = useMemo(() => {
    if (!Array.isArray(columns)) {
      return false
    }
    const notDisplayFields = Array.isArray(notDisplay) ? notDisplay : []
    const columnMove = colMove?.length !== columns.length ? [] : colMove
    const hideColumns = columns.map((column, index) => {
      const { id, hidden } = column
      const moveIndex = columnMove.findIndex((colId) => (colId === id))
      const notDisplay = hidden || notDisplayFields.includes(id)
      return notDisplay ? (moveIndex !== -1 ? moveIndex : index) : null
    }).filter((x) => x !== null)
    return ({
      copyPasteEnabled: false,
      columns: hideColumns,
    })
  }, [ columns, notDisplay, colMove ])

  const isGridCellEditable = useCallback((table, list, columns, col, row) => {
    if (readOnly || row < 0 || col < 0) {
      return false
    }
    if (isColumnHidden(hiddenColumns, col)) {
      return false
    }
    const metaCell = table.getCellMeta(row, col)
    if (metaCell && metaCell.readOnly) {
      return false
    }
    const { editable, editableDraft } = columns[table.toPhysicalColumn(col)] ?? {}
    if (editable) {
      return true
    }
    if (editableDraft && statusFieldIdx !== undefined) {
      return list[table.toPhysicalRow(row)][statusFieldIdx] === STATUS_DRAFT
    }
    return false
  }, [ statusFieldIdx, hiddenColumns, readOnly ])

  // Ця функція така громіздка, тому що ітеративно дороблялася під різні кейси.
  // Після того, як перелік кейсів стане вичерпним, варто її відрефакторити.
  // Особливо незручним виглядає довжелезний список параметрів.
  const handleSearch = useCallback((
    search, searchBy, matchCase, entireCell, silent, suppressHighlight, data, changes, searchPrevious, fromCurrent,
    skipReadOnly, findAll,
  ) => {
    const table = refHot?.current?.hotInstance
    if (table) {
      data = data ?? table.getData()
      if (!data.length || !data[0].length) {
        return false
      }
      let [ [ row, col, endRow, endCol ] ] = table.getSelected() || [ [ 0, 0, 0, 0 ] ]
      let rowCount, colCount, startRow, startCol
      if (row === endRow && col === endCol) {
        startRow = 0
        startCol = 0
        rowCount = data.length
        colCount = data[0].length
      } else {
        startRow = row
        startCol = col
        rowCount = endRow - row + 1
        colCount = endCol - col + 1
      }
      if (highlight) {
        row = highlight?.row
        col = highlight?.col
      }
      const length = rowCount * colCount
      let pos, posToRowCol, rowColToPos
      switch (searchBy) {
        case 'rows': {
          posToRowCol = posToRowThenCol
          rowColToPos = rowThenColToPos
          break
        }
        case 'cols': {
          posToRowCol = posToColThenRow
          rowColToPos = colThenRowToPos
          break
        }
        default: {
          return false
        }
      }
      pos = rowColToPos(row, col, rowCount, colCount, startRow, startCol)
      if (highlight && !fromCurrent) {
        pos = searchPrevious ? (pos === 0 ? length - 1 : pos - 1) : ((pos + 1) % length)
      }
      const startPos = pos
      const listFound = []
      do {
        const [ row, col ] = posToRowCol(pos, rowCount, colCount, startRow, startCol)
        if (
          !isColumnHidden(hiddenColumns, col) &&
          isValueFound(data, changes, row, col, search, matchCase, entireCell) &&
          (!skipReadOnly || isGridCellEditable(table, data, columns, col, row))
        ) {
          if (findAll) {
            listFound.push([ row, col ])
          } else {
            if (!suppressHighlight) {
              // Магія, яка тут відбувається: ми скролимо потрібну нам комірку у видиме поле таблиці,
              // але при цьому не змінюємо виділення комірок у таблиці.
              // Це досягається завдяки тому, що selectCells ми викликаємо з параметром scrollToCell = false
              const selection = table.getSelected()
              table.selectCell(row, col)
              // Table doesn't want to scroll to desired cell after first try
              table.deselectCell()
              table.selectCell(row, col)
              table.selectCells(selection, false)
            }
            setHighlight({ row, col, suppress: suppressHighlight })
            return [ row, col ]
          }
        }
        pos = searchPrevious ? (pos === 0 ? length - 1 : pos - 1) : ((pos + 1) % length)
      } while (pos !== startPos)
      if (findAll && listFound.length) {
        return listFound
      }
      if (!silent) {
        msg({
          messages: [
            skipReadOnly ? `Available for replacement value "${search}" not found` : `Value "${search}" not found`,
          ],
        })
      }
      return false
    }
  }, [ refHot, hiddenColumns, msg, highlight, setHighlight, columns, isGridCellEditable ])

  const handleReplace = useCallback((search, replace, searchBy, matchCase, entireCell, replaceAll) => {
    const table = refHot?.current?.hotInstance
    if (table) {
      const tableData = table.getData()
      const list = data.getList ? data.getList() : data
      const changes = []

      const processFound = ([ row, col ]) => {
        if (isGridCellEditable(table, list, columns, col, row)) {
          const column = columns[table.toPhysicalColumn(col)]
          const oldValue = tableData[row][col]
          const newValue = replaceValue(oldValue, search, replace, matchCase, entireCell, column.type)
          if (!onInlineEditRange) {
            table.setDataAtCell(row, col, newValue)
          } else {
            const change = addRowChange(changes, table, list, row, idFieldIdx)
            change.updates.push({
              field: table.toPhysicalColumn(col),
              value: newValue,
              row,
              col,
            })
          }
          return true
        }
      }

      if (replaceAll) {
        table.suspendRender()
        try {
          const listFound = handleSearch(search, searchBy, matchCase, entireCell, false, true, tableData, changes,
            false, false, true, true)
          if (listFound) {
            listFound.forEach(processFound)
          }
        } finally {
          table.resumeRender()
        }
      } else {
        let found = handleSearch(search, searchBy, matchCase, entireCell, false, false, undefined, undefined, false,
          true, true, false)
        if (found) {
          const [ startRow, startCol ] = found
          while (found) {
            if (processFound(found)) {
              break
            } else {
              setHighlight(null)
            }
            found = handleSearch(search, searchBy, matchCase, entireCell, false, false, undefined, undefined, false,
              false, true, false)
            if (found) {
              const [ row, col ] = found
              if (row === startRow && col === startCol) {
                break
              }
            }
          }
        }
      }
      if (onInlineEditRange) {
        onInlineEditRange(changes)
      }
    }
  }, [ refHot, handleSearch, onInlineEditRange, columns, data, idFieldIdx, isGridCellEditable ])

  const handleShowColumn = useCallback((selectedItem) => {
    if (selectedItem && selectedItem.dataType === dataType) {
      const ind = columns.findIndex((col) => col.id === selectedItem.id)
      if (ind >= 0) {
        const table = refHot.current.hotInstance
        table.selectColumns(table.toVisualColumn(ind))
      }
    }
  }, [ refHot, dataType, columns ])

  const duplicateFirstRow = useCallback(async () => {
    const table = refHot?.current?.hotInstance
    if (table) {
      const [ [ rowStart, colStart, rowFinish, colFinish ] ] = table.getSelected() || [ [ 0, 0, 0, 0 ] ]
      if (rowFinish > rowStart) {
        const tableData = table.getData()
        const list = data.getList()
        const changes = []
        table.suspendRender()
        try {
          const sourceRowIndex = rowStart === -1 ? 0 : rowStart
          for (let row = sourceRowIndex + 1; row <= rowFinish; row++) {
            for (let col = colStart; col <= colFinish; col++) {
              if (isGridCellEditable(table, list, columns, col, row)) {
                const newValue = tableData[sourceRowIndex][col]
                if (!onInlineEditRange) {
                  table.setDataAtCell(row, col, newValue)
                } else {
                  const change = addRowChange(changes, table, list, row, idFieldIdx)
                  change.updates.push({
                    field: table.toPhysicalColumn(col),
                    value: newValue,
                    col,
                    row,
                  })
                }
              }
            }
          }
          if (onInlineEditRange) {
            await onInlineEditRange(changes)
          }
        } finally {
          table.resumeRender()
        }
      }
    }
  }, [ refHot, onInlineEditRange, columns, data, idFieldIdx, isGridCellEditable ])

  const beforeKeyDownCallback = useCallback((disableHotKeyEvents) => (event) => {
    if (event.ctrlKey && !event.shiftKey && !event.altKey) {
      switch (event.code) {
        case 'KeyF': {
          console.log('Ctrl + F')
          event.preventDefault()
          event.stopImmediatePropagation()
          // setSearchMode('search')
          dispatch(setPanel('search'))
          break
        }
        case 'KeyH': {
          if (disableHotKeyEvents && disableHotKeyEvents.includes('KeyH')) {
            break
          }
          console.log('Ctrl + H')
          event.preventDefault()
          event.stopImmediatePropagation()
          if (!readOnly) {
            // setSearchMode('replace')
            dispatch(setPanel('replace'))
          }
          break
        }
        case 'KeyD': {
          console.log('Ctrl + D')
          event.preventDefault()
          event.stopImmediatePropagation()
          if (!readOnly) {
            duplicateFirstRow()
          }
          break
        }
        case 'KeyZ': {
          console.log('Ctrl + Z')
          if (offlineMode && canUndo) {
            event.preventDefault()
            event.stopImmediatePropagation()
            dispatch(doUndo())
          }
          break
        }
        default:
      }
    } else if (event.ctrlKey && event.shiftKey && !event.altKey) {
      switch (event.code) {
        case 'KeyZ': {
          console.log('Ctrl + Shift + Z')
          if (offlineMode && canRedo) {
            event.preventDefault()
            event.stopImmediatePropagation()
            dispatch(doRedo())
          }
          break
        }
        default:
      }
    } else if (!event.ctrlKey && event.shiftKey && !event.altKey) {
      const table = refHot?.current?.hotInstance
      switch (event.key) {
        case 'PageUp': {
          const visibleRow = table.countVisibleRows()
          event.preventDefault()
          event.stopImmediatePropagation()
          const selected = table.getSelected()?.[0]
          if (Array.isArray(selected) && selected.length === 4) {
            const newSelected = [ selected[0], selected[1], Math.max(selected[2] - visibleRow, 0), selected[3] ]
            table.selectCell(...newSelected)
          }
          break
        }
        case 'PageDown': {
          const visibleRow = table.countVisibleRows()
          const maxIndexRow = table.countRows() - 1
          event.preventDefault()
          event.stopImmediatePropagation()
          const selected = table.getSelected()?.[0]
          if (Array.isArray(selected) && selected.length === 4) {
            const newSelected = [
              selected[0],
              selected[1],
              Math.min(selected[2] + visibleRow, maxIndexRow),
              selected[3],
            ]
            table.selectCell(...newSelected)
          }
          break
        }
        default:
      }
    }
  }, [ duplicateFirstRow, readOnly, refHot, offlineMode, canUndo, canRedo, dispatch ])

  const beforeKeyDown = useMemo(() => {
    return !disableHotKeys && beforeKeyDownCallback(disableHotKeyEvents)
  }, [ disableHotKeyEvents, disableHotKeys, beforeKeyDownCallback ])

  const afterColumnSort = useCallback((currentSortConfig, destinationSortConfigs) => {
    const table = refHot?.current?.hotInstance
    if (dataType == null || (currentSortConfig.length === 0 && destinationSortConfigs.length === 0)) {
      return
    }
    if (table && !table.isDestroyed) {
      const config = destinationSortConfigs[0]
      let fixedConfig
      if (config) {
        const column = table.toPhysicalColumn(config.column)
        fixedConfig = {
          ...config,
          column,
        }
      }
      if (JSON.stringify(config) !== JSON.stringify(colSorting[dataType])) {
        dispatch(settingPropertyOnPath(fixedConfig, [ SETTINGS_KEYS.COL_SORTING, dataType ]))
        dispatch(saveSettingToServer)
      }
    }
  }, [ refHot, colSorting, dataType, dispatch ])

  const beforeColumnSort = useCallback(function () {
    if (!isInitialise) {
      const columnSortPlugin = this.getPlugin('columnSorting')
      if (columnSortPlugin) {
        // Try to debounce the first sorting
        columnSortPlugin.debounceSorting = true
      }
    }
    return isInitialise
  }, [ isInitialise ])

  const settings = useMemo(() => {
    let result = {
      ...SETTINGS,
      columnHeaderHeight: 24,
      rowHeights: 24,
      ...encodeSorting(colSorting[dataType]),
      beforeColumnSort,
    }
    if (disableSettings) {
      result[colSorting] = false
      if (!columns?.[0]?.title) {
        result = {
          ...result,
          nestedHeaders: makeNestedHeaders(columns),
        }
      }
    }
    return result
  }, [ colSorting, dataType, disableSettings, columns, beforeColumnSort ])

  const afterColumnResize = useCallback((width, index) => {
    const table = refHot?.current?.hotInstance
    if (table) {
      const physicalIndex = table.toPhysicalColumn(index)
      const columnId = columns[physicalIndex]?.id
      if (columnId) {
        dispatch(settingPropertyOnPath(width, [ SETTINGS_KEYS.COL_WIDTHS, columnId ]))
        dispatch(saveSettingToServer)
      }
      if (physicalIndex === table.countCols() - 1) {
        // Show the last column
        table.selectColumns(physicalIndex)
        table.deselectCell()
      }
    }
  }, [ refHot, dispatch, columns ])

  const afterColumnMove = useCallback(function (moveColumns, dropIndex, dragIndex, movePossible, orderChanged) {
    const table = this
    if (dragIndex == null || dataType == null) {
      return
    }
    if (movePossible && orderChanged && dataType) {
      const headerMoveState = (table && Array.isArray(columns))
        ? columns.map((_, index) => (table.toPhysicalColumn(index)))
        : null
      if (headerMoveState) {
        const newColumnHeaders = headerMoveState.map((index) => columns[index]?.id)
        dispatch(settingPropertyOnPath(newColumnHeaders, [ SETTINGS_KEYS.COL_TABLES_MOVE, dataType ]))
        dispatch(saveSettingToServer)
        // const newColumnHeaders = headerMoveState.map((index) => columns[index])
        // const settings = {
        //   nestedHeaders: makeNestedHeaders(newColumnHeaders),
        // }
      }
    }
  }, [ columns, dataType, dispatch ])

  const afterOnCellMouseDown = useCallback(function (event, coords, td) {
    const table = this
    const list = data.getList ? data.getList() : data
    const { col, row } = coords
    if (isGridCellEditable(table, list, columns, col, row)) {
      return
    }
    const now = new Date().getTime()
    if (!table.lastClick || now - table.lastClick > 500) {
      table.lastClick = now
      return
    }
    table.lastClick = 0
    if (col !== -1 && row !== -1) {
      onDblClick && onDblClick(list[table.toPhysicalRow(row)])
    }
  }, [ columns, onDblClick, data, isGridCellEditable ])

  // Дані перед валідацією, можна скоригувати
  const beforeChange = useCallback((data, source) => {
    if (source === 'edit' || source === 'CopyPaste.cut' || source === 'CopyPaste.paste') {
      const table = refHot?.current?.hotInstance
      if (Array.isArray(data)) {
        data.forEach((data) => {
          const colV = table.toVisualColumn(data[1])
          const rowV = table.toVisualRow(data[0])
          const type = table.getDataType(rowV, colV, rowV, colV)
          const column = columns[data[1]]
          const columnType = column.type.toLowerCase()
          if (type === 'coordinate' || type === 'numeric') {
            data[3] = `${data[3]}`.replace(',', '.')
            if (column.min !== null && column.min >= 0) {
              data[3] = data[3].replace('-', '')
            }
            if (column.type === 'int') {
              data[3] = data[3].split('.', 1)[0]
            }
            if (columnType === 'double') {
              const dd = data[3].split('.')
              if (dd.length === 2 && dd[1] === '') { // 'X.' преобразуем в 'X', иначе будет ошибка валидации
                data[3] = dd[0]
              }
            }
            // Контроль числа на сброс в 0 или пропуск изменений для пустых полей
            if (data[3] === 'null' || data[3] === '' || data[3] === null) {
              // если в поле было значение, сбрасываем его в 0 или оставляем старое неопределенное значение
              data[3] = (data[2] === null || data[2] === '') ? data[2] : 0
            }
          } else if (columnType === 'string') {
            if (data[3]) {
              if (column.max) {
                data[3] = data[3].slice(0, column.max) // текствый тип, просто обрезаем строку
              }
              if (UPPER_CASE_ATTR.includes(column.id)) {
                // Конвертация наименований элементов сети в верхний регистр
                data[3] = data[3].toUpperCase()
              }
            } else { // вводим пустую строку
              data[3] = data[2] === null ? null : ''
            }
          } else if (columnType === 'boolean') {
            if (source === 'CopyPaste.paste') {
              data[3] = stringValueToBoolean(data[3])
            } else if (data[3] === null && data[2] !== null) {
              // корректировка значения при вырезании, при Delete уже возвращаеться false
              data[3] = false
            }
          } else if (columnType === 'enum' && source === 'edit') {
            if (data[3] === null) { // установка значения перечисляемого типа, при удалениив, в первый вариант
              const enumValues = column['enum-values'].split(',')
              data[3] = enumValues ? enumValues[0] : 'NONE'
            }
          } else if (columnType === 'date' && column.dateFormat) {
            if (data[2] === null && (data[3] === '' || data[3] === null)) { // отказ от изменения, ввода даты
              data[3] = data[2]
            } else {
              const date = moment(data[3], column.dateFormat) // разгребает любой неадекват. (надеюсь)
              data[3] = date.format(column.dateFormat) // выводим в нужном формате
            }
          } else if (columnType.includes('autocomplete')) {
            if (data[3]) {
              if (UPPER_CASE_ATTR.includes(column.id)) {
                data[3] = data[3].toUpperCase()
              }
            }
          }
          // table.getCellMeta(rowV, colV),
          // table.getDataAtCell(data[0], colV),
          // table.getSourceDataAtCell(rowP, colV),
        })
      }
    }
  }, [ refHot, columns ])

  // Збереження даних, що пройшли валідацію
  const afterChange = useCallback((data, source) => {
    if (source === 'edit' || source === 'CopyPaste.cut' || source === 'CopyPaste.paste') {
      onInlineEdit && onInlineEdit(data)
      onAfterEdit && onAfterEdit(data)
    }
  }, [ onAfterEdit, onInlineEdit ])

  // Додаткова валідація даних, що вводяться в таблицю
  const afterValidate = useCallback((_isValid, value, row, prop, source) => {
    let isValid = _isValid
    let message = ''
    if (source === 'edit' || source === 'CopyPaste.cut' || source === 'CopyPaste.paste') {
      const column = columns[prop]
      const columnType = column.type.toLowerCase()

      if (columnType === 'string' && !value && column.requiredNonEmpty) {
        // Hide error message for NonEmpty fields
        return false // Назви елементів мережі повинні бути заповнені
      }

      if (isValid) {
        if (columnType === 'double') {
          const valueN = +value
          const rez = isValueInRange(valueN, column.min, column.max)
          isValid = rez.isValid
          message = rez.message
        } else if (columnType === 'enum' && column['enum-values']) {
          const enumValues = column['enum-values'].split(',')
          isValid = enumValues ? enumValues.includes(value) : isValid
          message = 'The value must be from the list of allowed for this field'
        } else if (columnType === 'int') {
          const valueN = +value
          const rez = isValueInRange(valueN, column.min, column.max)
          isValid = rez.isValid
          message = rez.message
        }
      }
    }
    // формат даты для валидции прописан в конфигурации колонок таблицы, + корректировка в beforeChange

    if (!isValid) {
      toast.error(`Invalid value entered.\n ${message}`)
    }
    return isValid
  }, [ columns ])

  const renderTH = useCallback(function (index, TH) {
    const columnIndex = this.toPhysicalColumn(index)
    const title = `${columns[columnIndex]?.description ?? ''}`
    TH.setAttribute('title', title)
    if (Array.isArray(filteredColumns) && filteredColumns.includes(columnIndex)) {
      TH.classList.add('filter-active')
    } else {
      TH.classList.remove('filter-active')
    }
  }, [ columns, filteredColumns ])

  const view = data?.getList ? data.getList() : data

  const afterGetRowHeader = useCallback(function (rowIndex, TH) {
    const row = this.toPhysicalRow(rowIndex)
    const element = view[row]
    if (element?.isInMyProject) {
      TH.classList.add('is-in-my-project')
      TH.setAttribute('title', 'This network element is in my project')
    } else {
      TH.classList.remove('is-in-my-project')
      TH.removeAttribute('title')
    }
  }, [ view ])

  const checkFilter = useCallback(() => {
    const table = refHot?.current?.hotInstance
    if (!table || !localFilter || !localFilter.columnId) {
      return
    }
    const filtersPlugin = table.getPlugin('filters')
    const index = findIndex(columns, localFilter.columnId)
    const currentFilter = filtersPlugin?.conditionCollection?.filteringStates?.indexedValues?.[index]
    if (currentFilter != null) {
      return // текущий фильтр действует
    }
    // текущий фильтр сброшен
    filtersPlugin.clearConditions()
    if (localFilter.value || typeof localFilter.value === 'boolean') {
      filtersPlugin.addCondition(index, 'contains', [ localFilter.value ])
    } else {
      filtersPlugin.addCondition(index, 'empty', [])
    }
    filtersPlugin.filter()
  }, [ columns, localFilter, refHot ])

  useEffect(() => {
    const table = refHot?.current?.hotInstance
    if (!table) {
      return
    }
    const filtersPlugin = table.getPlugin('filters')
    filtersPlugin.clearConditions() // сброс фильтра
    if (!localFilter || !localFilter.columnId) {
      return
    }
    const index = findIndex(columns, localFilter.columnId)
    if (localFilter.value || typeof localFilter.value === 'boolean') {
      filtersPlugin.addCondition(index, 'contains', [ localFilter.value ])
    } else {
      filtersPlugin.addCondition(index, 'empty', [])
    }
    filtersPlugin.filter()
  }, [ columns, localFilter, refHot ])

  useEffect(() => {
    const table = refHot?.current?.hotInstance

    checkFilter()

    if (manualColumnMove === false ||
      !Array.isArray(colMove) ||
      !Array.isArray(columns) ||
      !table ||
      !isInitialise ||
      columns.length !== colMove.length
    ) {
      return
    }
    // текущий порядок колонок
    const headerMoveState = columns.map((_, index) => (table.toPhysicalColumn(index)))
    // const manualColumnMove = columns.map(({ id }) => (colMove.findIndex((colId) => (colId === id))))
    // требуемый порядок колонок
    const headerMoveTo = colMove.map((colId) => (columns.findIndex((col) => (colId === col.id))))
    if (headerMoveTo.includes(-1)) { // не все столбцы найдены, была реструктуризация таблицы
      const settings = {
        manualColumnMove: [],
      }
      table.updateSettings(settings)
      return
    }
    const isEqual = headerMoveState.every((pos, index) => (pos === headerMoveTo[index]))
    if (!isEqual) {
      const settings = {
        manualColumnMove: headerMoveTo,
      }
      table.updateSettings(settings)
    }
  })

  const beforeCopy = useCallback((data, coords) => {
    const table = refHot?.current?.hotInstance
    if (table && table.getSelected().length > 1) {
      msg({ messages: [ 'This action won\'t work on multiple selections' ] })
      return false
    }

    let iCounter = 0
    for (const range of coords) {
      for (let i = 0; i <= range.endCol - range.startCol; ++i) {
        for (let j = 0; j <= range.endRow - range.startRow; ++j) {
          const colV = table.toVisualColumn(i + range.startCol)
          const rowV = table.toVisualRow(j + range.startRow)
          const type = table.getDataType(rowV, colV, rowV, colV)
          const subType = columns[i + range.startCol]?.subType
          if (type === 'date' && subType === 'dateTime') {
            const dateFormat = columns[i + range.startCol]?.dateFormat
            const value = data[j][iCounter]
            const date = value ? new Date(value) : null
            if (dateFormat) {
              data[j][iCounter] = date ? moment(date).format(dateFormat) : ''
            } else {
              data[j][iCounter] = date ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : ''
            }
          }
        }
        iCounter++
      }
    }
  }, [ refHot, msg, columns ])

  // Це аналог useLayoutEffect, але спрацьовує завжди (а не лише при зміні вказаних у залежностях параметрів)
  setTimeout(() => {
    const table = refHot?.current?.hotInstance
    if (table) {
      document.querySelectorAll(`.${SELECT}`).forEach((node) => node.classList.remove(SELECT))
      if (highlight && !highlight.suppress) {
        const cell = table.getCell(highlight.row, highlight.col)
        if (cell) {
          cell.classList.add(SELECT)
        }
      }
    }
  }, 200)

  const beforeContextMenuShow = useCallback((context) => {
    if (context.menu.onAfterInit !== onAfterInit2) {
      context.menu.onAfterInit = onAfterInit2
    }
  }, [])

  const beforeContextMenuSetItems = useCallback((menuItems) => {
    onContextMenu && onContextMenu(menuItems)
  }, [ onContextMenu ])

  const afterScrollHorizontally = useCallback(() => {
    const table = refHot?.current?.hotInstance
    const plugin = table?.getPlugin('autoColumnSize')
    localStorage.setItem(`${dataType}_scrollX`, plugin?.getFirstVisibleColumn())
  }, [ refHot, dataType ])

  const afterScrollVertically = useCallback(() => {
    const table = refHot?.current?.hotInstance
    const plugin = table?.getPlugin('autoRowSize')
    localStorage.setItem(`${dataType}_scrollY`, plugin?.getFirstVisibleRow())
    const contextMenu = table?.getPlugin('contextMenu')
    if (contextMenu) {
      contextMenu.close()
    }
  }, [ refHot, dataType ])

  useEffect(() => {
    const table = refHot?.current?.hotInstance
    const scrollX = Number(localStorage.getItem(`${dataType}_scrollX`))
    const scrollY = Number(localStorage.getItem(`${dataType}_scrollY`))
    if (!isNaN(scrollY) && !isNaN(scrollX)) {
      table?.scrollViewportTo(scrollY, scrollX)
    }
  }, [ dataType, refHot ])

  return (
    <>
      {renderConfirm()}
      <HotTable
        id={dataType}
        ref={refHot}
        data={view}
        settings={settings}
        afterGetColHeader={afterGetColHeader || renderTH}
        afterGetRowHeader={afterGetRowHeader}
        afterColumnResize={afterColumnResize}
        afterOnCellMouseDown={afterOnCellMouseDown}
        afterColumnMove={afterColumnMove}
        afterInit={setInitialise}
        afterSelectionEnd={onSelectionEnd}
        afterPaste={onPaste}
        afterCut={onCut}
        afterValidate={afterValidate}
        afterScrollHorizontally={afterScrollHorizontally}
        afterScrollVertically={afterScrollVertically}
        beforeContextMenuShow={beforeContextMenuShow}
        beforeContextMenuSetItems={beforeContextMenuSetItems}
        beforeChange={beforeChange}
        afterChange={afterChange}
        dropdownMenu={false}
        hiddenColumns={hiddenColumns}
        afterColumnSort={afterColumnSort}
        beforeKeyDown={beforeKeyDown}
        beforeCopy={beforeCopy}
        wordWrap={false}
        maxRows={view.length}
        manualColumnMove={manualColumnMove}
        cells={cells}
        readOnly={readOnly}
        allowInvalid={false} // не вносить невалидные данные
        {...others}
        filters={true}
      >
        {columns?.map(({
          id, type, size, max, editable, label, hidden, className, subType, requiredNonEmpty, ...settings
        }) => {
          const width = isFullViewHeader
            ? Math.max(colWidths[id], getTextWidth(label, dataType) + PADDING_DEFAULT + WIDTH_SORTING, MIN_WIDTH_COLUMN)
            : colWidths[id] ?? Math.max(getTextWidth(label, dataType) + PADDING_DEFAULT, WIDTH_COLUMN_DEFAULT)
          const sizeF = COMPOSITE_INDICES.includes(id) ? 4 : size
          return (
            <HotColumn
              key={id}
              className={className}
              title={label}
              settings={{
                id,
                ...settings,
                ...encodeType(type?.toLowerCase(), subType, !readOnly && editable, settings),
                width: width,
                subType: subType,
                size: sizeF,
              }}
              validator={(type === 'text' || type === 'string') ? validatorText(id, max, requiredNonEmpty) : undefined}
            >
              {children && children(id)}
            </HotColumn>
          )
        })}
      </HotTable>
      {searchPanelContentDiv && ReactDOM.createPortal(
        <Search
          onSearch={handleSearch}
        />,
        searchPanelContentDiv,
      )}
      {replacePanelContentDiv && ReactDOM.createPortal(
        <Replace
          onSearch={handleSearch}
          onReplace={handleReplace}
        />,
        replacePanelContentDiv,
      )}
      {columnsPanelContentDiv && ReactDOM.createPortal(
        <Columns
          onShowColumn={handleShowColumn}
        />,
        columnsPanelContentDiv,
      )}
    </>
  )
}

// Ensure that originalSortBySettings will not be overwritten in the dev mode
if (!ColumnSorting.prototype.originalSortBySettings) {
  ColumnSorting.prototype.originalSortBySettings = ColumnSorting.prototype.sortBySettings
}
ColumnSorting.prototype.sortBySettings = function (...args) {
  const context = this
  const method = ColumnSorting.prototype.originalSortBySettings
  if (context?.debounceSorting) {
    clearTimeout(ColumnSorting.prototype.timeout)
    ColumnSorting.prototype.timeout = setTimeout(() => {
      // Turn off debouncing after the first sort
      context.debounceSorting = false
      method.apply(context, args)
    }, 100)
  } else {
    method.apply(context, args)
  }
}

export default Grid
