import { KEYS } from './index'

export const cabinet = {
  create: 'Create',
  delete: 'Delete',
  open: 'Open',
  makeACopy: 'Make a copy',
  share: 'Share',
  synchronize: 'Synchronize',
  createProject: 'Create Project',
  deleteProject: 'Delete Project',
  copyProject: 'Copy project',
  apply: 'Apply',
  blockUser: (count) => `Block user${count > 1 ? 's' : ''}`,
  unblockUsers: (count) => `Unblock user${count > 1 ? 's' : ''}`,
  block: 'Block',
  edit: 'Edit',
  unblock: 'Unblock',
  changeRole: 'Change role',
  messageBlockUser: (count) => `Confirm blocking ${count} user${count > 1 ? 's' : ''}:`,
  messageUnblockUser: (count) => `Confirm unblocking ${count} user${count > 1 ? 's' : ''}:`,
  messageDeleteProject: (count) => `Confirm deletion of ${count} project${count > 1 ? 's' : ''}:`,
  messageCopyProject: (name) => `New project name: "${name}" ?`,
  title: {
    [KEYS.PROJECT_NAME]: 'Name ID',
    [KEYS.DESCRIPTION]: 'Project name',
    [KEYS.SOURCE_NAME]: 'Source Name',
    [KEYS.LAST_SYNC_DATE]: 'Last Sync Date',
    [KEYS.SYNCHRONIZATION_PERIOD]: 'Synchronization Period',
    [KEYS.STATUS]: 'Status',
    [KEYS.EXECUTE_DETAILS]: 'Execute Details',
  },
}
