import { createTheme } from '@fluentui/react'
import './assets/font-files/inter.css'
import './theme.css'

export const capexTheme = createTheme({
  defaultFontStyle: {
    fontFamily: 'Inter, sans-serif',
  },
  palette: {
    themePrimary: '#006EC4',
    themeTertiary: '#42A4FF',
    themeSecondary: '#FFE600',
  },
})
