import React from 'react'
import { SpinButton } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import './style.scss'
import { decimalAdjust } from '../../../utils/math'
import { useHandler } from '../../../hooks'

const getNumericPart = (value) => {
  const valueRegex = /^(\d+(\.\d+)?).*/
  const val = `${value ?? ''}`
  if (valueRegex.test(val)) {
    const numericValue = Number(val.replace(valueRegex, '$1'))
    return isNaN(numericValue) ? undefined : numericValue
  }
  return undefined
}

const InputSpin = ({
  item,
  onChange,
  maxValue = Number.POSITIVE_INFINITY,
  minValue = Number.NEGATIVE_INFINITY,
  name,
  suffix = '',
  step = 1,
  className,
  disabled,
}) => {
  const [ edited, { setTrue: setEdited, setFalse: setNotEdited } ] = useBoolean(false)

  const onChangeHandler = useHandler((ev, value) => {
    const valueParse = Number.parseFloat(value.replace(',', '.'))
    if (isNaN(valueParse)) { return }
    const valueFloat = decimalAdjust(Math.min(Math.max(minValue, valueParse), maxValue))
    if (valueFloat !== item?.[name]) {
      onChange({
        ...item,
        [name]: valueFloat,
      })
    }
  })

  const onValidateHandler = useHandler((value) => {
    if (value === '') {
      return '0'
    }
    const valueParse = Number.parseFloat(value.replace(',', '.'))
    return isNaN(valueParse) ? undefined : `${decimalAdjust(Math.min(Math.max(minValue, valueParse), maxValue))}`
  })

  const onIncrement = useHandler((value) => {
    const numericValue = getNumericPart(value) || 0
    return String(Math.min(numericValue + step, maxValue)) + suffix
  })

  const onDecrement = useHandler((value) => {
    const numericValue = getNumericPart(value) || 0
    return String(Math.max(numericValue - step, minValue)) + suffix
  })

  return (
    <SpinButton
      onValidate={onValidateHandler}
      disabled={disabled}
      className={`${className} input-spin`}
      precision={2}
      value={edited ? (item?.[name] || 0) : item?.[name] ? `${item[name]}${suffix}` : ''}
      onFocus={setEdited}
      onBlur={setNotEdited}
      onChange={onChangeHandler}
      onDecrement={onDecrement}
      onIncrement={onIncrement}
      name={item.key}
      inputProps={{ name: item.key, style: edited ? {} : { background: 'transparent' } }}
    />
  )
}

export default InputSpin
