import { ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu'
import {
  COMPLAINT_ID_FIELD, COMPLAINT_LAT_FIELD, COMPLAINT_LNG_FIELD,
  DIVIDER, ID_NETWORK_COMPLAINTS, ID_NETWORK_SECTORS, ID_NETWORK_SITES, SECTOR_LAT_FIELD, SECTOR_LNG_FIELD,
  SECTOR_NAME_FIELD, SECTOR_STATUS_FIELD, SITE_LAT_FIELD,
  SITE_LNG_FIELD,
  SITE_NAME_FIELD, SITE_STATUS_FIELD, STATUS_REMOVED,
} from '../../constants/network'
import { capexTheme } from '../../theme'
import Data, { findIndex, sortFunc } from './indexing'

const isNumber = (value) => typeof value === 'number'

const basicContextMenu = [
  {
    key: 'show',
    text: 'Show',
  },
  {
    key: 'hide',
    text: 'Hide',
  },
]

export const makeGroupByMenu = (fields, groupBy, filtered = [], extra = []) => {
  const pages = [
    ...new Set(
      fields
        .filter(({ id, hidden }) => !hidden && filtered.indexOf(id) < 0)
        .map(({ page }) => page),
    ),
  ]

  return [
    {
      key: 'group',
      text: 'Group by',
      secondaryText: fields.find(({ id }) => id === groupBy)?.label,
      iconProps: { iconName: 'GroupList' },
      itemProps: {
        styles: {
          secondaryText: {
            color: capexTheme.palette.neutralTertiary,
          },
        },
      },
      subMenuProps: {
        items: [
          {
            key: `group${DIVIDER}none`,
            text: 'None',
            canCheck: true,
            isChecked: groupBy === 'none',
          },
          {
            key: 'divider',
            itemType: ContextualMenuItemType.Divider,
          },
          ...pages.map((key) => ({
            key: `group${DIVIDER}${key}`,
            text: key,
            isChecked: fields.find(({ id }) => id === groupBy)?.page === key,
            subMenuProps: {
              items: fields
                .filter(({ id, page, hidden }) => !hidden && key === page && filtered.indexOf(id) < 0)
                .map(({ id, label }) => ({
                  key: `group${DIVIDER}${id}`,
                  text: label,
                  canCheck: true,
                  isChecked: groupBy === id,
                })),
            },
          })),
        ],
      },
    },
    {
      key: '-',
    },
    ...basicContextMenu,
    ...extra,
  ]
}

export const buildElementsTree = (node, expand, label, idField, lngField, latField, statusField) => {
  const selected = node.state?.selected
  const fields = node.fields
  const field = node.groupBy
  const list = node.list.getList()
  const idIdx = findIndex(fields, idField)
  const lngIdx = findIndex(fields, lngField)
  const latIdx = findIndex(fields, latField)
  const statusIdx = findIndex(fields, statusField)
  if (!fields.length || field === 'none') {
    let selectOne = false
    let selectAll = true
    node.children = new Data(
      list
        .filter((item) =>
          item[idIdx] &&
          (lngIdx < 0 || isNumber(item[latIdx])) &&
          (lngIdx < 0 || isNumber(item[lngIdx])) &&
          (statusIdx < 0 || item[statusIdx] !== STATUS_REMOVED))
        .map((item, index) => {
          selectOne ||= !item.hidden
          selectAll &&= !item.hidden
          return {
            id: `${label}${DIVIDER}${item[idIdx]}`,
            name: item[idIdx],
            state: { selected: !item.hidden },
            lat: item[latIdx],
            lng: item[lngIdx],
            // menu: prefContentMenu,
            item,
            path: [ ...node.path, index ],
          }
        }),
    )
    if (node.state) {
      node.state.selected = selectOne
      node.state.partial = !selectAll
    }
  } else {
    const groupIdx = findIndex(fields, field)
    const hash = {}
    list.forEach((item) => {
      const group = item[groupIdx]
      const id = item[idIdx]
      const lng = item[lngIdx]
      const lat = item[latIdx]
      if (id && (lngIdx < 0 || lng) && (latIdx < 0 || lat)) {
        if (!hash[group]) {
          hash[group] = []
        }
        item.hidden = !selected
        hash[group].push({
          id: `${label}${DIVIDER}${group}${DIVIDER}${id}`,
          name: id,
          state: { selected },
          lat,
          lng,
          // menu: prefContentMenu,
          item,
        })
      }
    })
    node.children = new Data(
      Object.entries(hash)
        .sort(sortFunc(field, fields[groupIdx].type, true))
        .map(([ key, value ], index) => {
          value.forEach((item, index2) => (item.path = [ ...node.path, index, index2 ]))
          const name = value?.length > 0 ? value[0].item[groupIdx] : key
          return {
            id: `${label}${DIVIDER}${key}`,
            name,
            children: value,
            state: { selected },
            // menu: basicContextMenu,
            path: [ ...node.path, index ],
          }
        }),
    )
    node.state = {
      ...node.state,
      partial: false,
    }
    if (expand) {
      node.state = {
        ...node.state,
        expanded: true,
      }
    }
  }
}

export const SITES_TREE = [
  ID_NETWORK_SITES, SITE_NAME_FIELD, SITE_LNG_FIELD, SITE_LAT_FIELD, SITE_STATUS_FIELD,
]
export const SECTORS_TREE = [
  ID_NETWORK_SECTORS, SECTOR_NAME_FIELD, SECTOR_LNG_FIELD, SECTOR_LAT_FIELD, SECTOR_STATUS_FIELD,
]
export const COMPLAINTS_TREE = [
  ID_NETWORK_COMPLAINTS, COMPLAINT_ID_FIELD, COMPLAINT_LNG_FIELD, COMPLAINT_LAT_FIELD,
]
