import React, { useCallback, useEffect, useRef } from 'react'
import { Pivot as PivotComponent, PivotItem as PivotItemComponent } from '@fluentui/react'
import { addToClassList } from '../../utils/classes'

import './Pivot.css'

const CAPEX_PIVOT_CLASS = 'capex-pivot'

// Може це трішки тупо, але так було найшвидше.
// Якщо треба, щоб усі таби були однакової ширини, і займали всю ширину контейнера, то в залежності від кількості табів
// оберіть потрібний клас і присвойте його пайвоту.
// Інакше таби займатимуть ширину в залежності від тексту їхнього хедера, і будуть автоматично прокручуватися,
// якщо їх забагато і вони не поміщаються в контейнер.
export const FW_TABS_1 = 'full-width-header-1-tab'
export const FW_TABS_2 = 'full-width-header-2-tabs'
export const FW_TABS_3 = 'full-width-header-3-tabs'
export const FW_TABS_4 = 'full-width-header-4-tabs'
export const FW_TABS_5 = 'full-width-header-5-tabs'
export const FW_TABS_6 = 'full-width-header-6-tabs'
export const FW_TABS_7 = 'full-width-header-7-tabs'
export const FW_TABS_8 = 'full-width-header-8-tabs'
export const FW_TABS_9 = 'full-width-header-9-tabs'
export const FW_TABS_10 = 'full-width-header-10-tabs'
// ...додайте, якщо цих не вистачатиме

// Прокрутка набору табів так, щоб було трішки видно наступний чи попередній, якщо вони є
const scrollTabButtonIntoView = (b) => {
  if (!b) {
    return
  }
  const p = b.parentElement
  if (p) {
    const br = b.getBoundingClientRect()
    const pr = p.getBoundingClientRect()
    const x = br.x - pr.x
    let delta = 0
    if (x - 48 < 0) {
      delta = x - 48
    } else if (x + br.width > pr.width - 48) {
      delta = x + br.width + 48 - pr.width
    }
    p.scrollBy(delta, 0)
  }
}

const Pivot = ({ className, onLinkClick, selectedKey, children, ...props }) => {
  const ref = useRef()

  const handleLinkClick = useCallback((tab, event) => {
    let b = event.currentTarget
    while (b && b.tagName !== 'BUTTON') {
      b = b.parentElement
    }
    scrollTabButtonIntoView(b)
    onLinkClick && onLinkClick(tab, event)
  }, [ onLinkClick ])

  const handleScroll = useCallback(() => {
    // Тут реалізуємо затінення на правій і лівій окрайці набору табів, якщо у відповідний бік можна прокручувати
    const buttons = ref.current?.querySelectorAll('button.ms-Pivot-link')
    const first = buttons?.[0]
    const last = buttons?.[buttons.length - 1]
    if (first && last) {
      const p = first.parentElement
      let leftGapDiv = p.querySelector('div[role="left-gap"]')
      let rightGapDiv = p.querySelector('div[role="right-gap"]')
      const pr = p.getBoundingClientRect()
      const size = `${pr.height - 1}px`
      const fr = first.getBoundingClientRect()
      const lr = last.getBoundingClientRect()
      const leftGap = fr.x - pr.x < 0
      const rightGap = lr.x + lr.width - pr.x > pr.width
      if (leftGap && !leftGapDiv) {
        leftGapDiv = p.appendChild(document.createElement('div'))
        leftGapDiv.setAttribute('role', 'left-gap')
        leftGapDiv.style.position = 'absolute'
        leftGapDiv.style.top = '0'
        leftGapDiv.style.height = size
        leftGapDiv.style.width = size
        leftGapDiv.style.background = 'linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%)'
        leftGapDiv.style.pointerEvents = 'none'
      }
      if (rightGap && !rightGapDiv) {
        rightGapDiv = p.appendChild(document.createElement('div'))
        rightGapDiv.setAttribute('role', 'right-gap')
        rightGapDiv.style.position = 'absolute'
        rightGapDiv.style.top = '0'
        rightGapDiv.style.height = size
        rightGapDiv.style.width = size
        rightGapDiv.style.background = 'linear-gradient(270deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%)'
        rightGapDiv.style.pointerEvents = 'none'
      }
      if (!leftGap && leftGapDiv) {
        leftGapDiv.remove()
        leftGapDiv = null
      }
      if (!rightGap && rightGapDiv) {
        rightGapDiv.remove()
        rightGapDiv = null
      }
      if (leftGapDiv) {
        leftGapDiv.style.left = `${p.scrollLeft}px`
      }
      if (rightGapDiv) {
        rightGapDiv.style.left = `${p.scrollLeft + pr.width - pr.height + 1}px`
      }
    }
  }, [ ref ])

  useEffect(() => {
    const tabList = ref.current?.querySelector('div[role="tablist"]')
    tabList?.addEventListener('scroll', handleScroll)
  }, [ ref, handleScroll ])

  useEffect(() => {
    if (selectedKey && children) {
      const index = children.findIndex((child) => child.props.itemKey === selectedKey)
      scrollTabButtonIntoView(ref.current?.querySelectorAll('button')[index])
      handleScroll()
    }
  }, [ selectedKey, children, ref, handleScroll ])

  return (
    <PivotComponent
      ref={ref}
      linkFormat="tabs"
      onLinkClick={handleLinkClick}
      selectedKey={selectedKey}
      {...props}
      className={addToClassList(className, CAPEX_PIVOT_CLASS)}
    >
      {children}
    </PivotComponent>
  )
}

Pivot.Item = PivotItemComponent

export default Pivot
