import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectTab, setTab } from '../../../features/panel/panelSlice'
import { COMPLAINTS, COVERAGE } from '../../../constants/access'
import { selectUserAccess } from '../../../features/login/loginSlice'
import Pivot, { FW_TABS_2 } from '../../common/Pivot'
import Complaints from './Complaints'
import Coverage from './Coverage'
import Sectors from './Sectors'
import Sites from './Sites'

const Network = () => {
  const dispatch = useDispatch()

  const userAccess = useSelector(selectUserAccess, shallowEqual)

  const tab = useSelector(selectTab) || 'sites'

  const handleLinkClick = useCallback((item) => {
    dispatch(setTab(item.props.itemKey))
  }, [ dispatch ])

  const className = useMemo(() => {
    if (!userAccess[COVERAGE] && !userAccess[COMPLAINTS]) {
      return FW_TABS_2
    }
  }, [ userAccess ])

  return (
    <Pivot selectedKey={tab} onLinkClick={handleLinkClick} className={className}>
      <Pivot.Item headerText="Sites" itemKey="sites">
        <Sites />
      </Pivot.Item>
      <Pivot.Item headerText="Sectors" itemKey="sectors">
        <Sectors />
      </Pivot.Item>
      {userAccess[COVERAGE] && (
        <Pivot.Item headerText="Coverage" itemKey="coverage">
          <Coverage />
        </Pivot.Item>
      )}
      {userAccess[COMPLAINTS] && (
        <Pivot.Item headerText="Complaints" itemKey="complaints">
          <Complaints />
        </Pivot.Item>
      )}
    </Pivot>
  )
}

export default Network
