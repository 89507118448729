import { useCallback, useEffect, useMemo } from 'react'
import L from 'leaflet'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { meters, METERS_FACTOR } from '../constants'
import { moveSector } from '../../../../features/network/mutations'
import { findSectorMarker } from '../../list'
import { beforeRedraw, drawSector } from '../../draw'
import { determineColor, determineLabel, isItemVisible } from '../../render'
import { SECTOR_BASE, STATUS_ACTIVE, STATUS_DRAFT } from '../../../../constants/network'
import {
  selectNetworkLayersVisibility, selectSectorFields, selectSectorFldIdx, selectSectors, selectSectorsFull,
  selectSectorsRedraw, selectSectorTypes,
} from '../../../../features/network/networkSlice'
import {
  selectSettingsDisplay, selectSettingsSectorsIcon, selectSettingsSizes,
} from '../../../../features/settings/settingsSlice'
import { ID_ICON_SECTOR } from '../../../Panels/Customization/constants'
import { EDIT_ELEMENTS } from '../../../../constants/access'

const Sectors = ({ shown, map, activeSector, setTargetCoverageButton, colorAttributeIdx, userAccess }) => {
  const dispatch = useDispatch()

  const sectors = useSelector(selectSectors)
  const sectorsFull = useSelector(selectSectorsFull)
  const sectorFields = useSelector(selectSectorFields)
  const sectorsRedraw = useSelector(selectSectorsRedraw)
  const sectorTypes = useSelector(selectSectorTypes)
  const [ idIdx2, nameIdx2, lngIdx2, latIdx2, azIdx2, xIdx2, yIdx2, tIdx2, statusIdx2,,, siteIdx2 ] =
    useSelector(selectSectorFldIdx, shallowEqual)
  const { sites, ...sectorSettings } = useSelector(selectSettingsDisplay) || {}
  const sectorsIcon = useSelector(selectSettingsSectorsIcon)
  const { [ID_ICON_SECTOR]: ratio } = useSelector(selectSettingsSizes) || {}
  const networkLayersVisibility = useSelector(selectNetworkLayersVisibility, shallowEqual)
  const {
    sectorsVisibility,
    sectorTypesVisibility,
    sectorTypeIdx,
    sectorSortFn,
  } = (networkLayersVisibility || {})

  // Переміщення секторів
  const sectorMarkerDrag = useCallback((event) => {
    const sectorMarker = map._activeSectorMarker.options.targetMarker
    sectorMarker.setLatLng(event.latlng)
    const sectorIdx = sectorsFull.findIndexById(sectorMarker.options.id)
    const sector = sectorsFull.getList()[sectorIdx]
    const lat = sector[latIdx2]
    const lng = sector[lngIdx2]
    L.Util.setOptions(sectorMarker, { basement: { lat, lng } })
    map._sectors.redraw(true)
  }, [ map, sectorsFull, latIdx2, lngIdx2 ])

  const sectorMarkerDragEnd = useCallback(() => {
    const sectorMarker = map._activeSectorMarker.options.targetMarker
    const { basement } = sectorMarker.options
    const newPos = map._activeSectorMarker.getLatLng()
    const p1 = meters.project(basement)
    const p2 = meters.project(newPos)
    const x = Math.round((p2.x - p1.x) / METERS_FACTOR)
    const y = Math.round((p2.y - p1.y) / METERS_FACTOR)
    dispatch(moveSector(map._activeSector, { x, y }))
  }, [ map, dispatch ])

  // Перемикання активного сектору
  useEffect(() => {
    if (map._activeSector !== activeSector) {
      if (activeSector) {
        const marker = findSectorMarker(map, activeSector)
        if (map._activeSiteMarker) {
          map._activeSiteMarker.removeFrom(map)
          delete map._activeSiteMarker
        }
        if (marker && userAccess[EDIT_ELEMENTS]) {
          if (map._activeSectorMarker) {
            map._activeSectorMarker.options.targetMarker = marker
            map._activeSectorMarker.setLatLng(marker.getLatLng())
          } else if (!marker.options.active) {
            map._activeSectorMarker = L.marker(marker.getLatLng(), {
              pane: 'markerPane',
              draggable: true,
              targetMarker: marker,
              icon: L.divIcon({ className: 'marker-icon' }),
            })
            map._activeSectorMarker.addTo(map)
            map._activeSectorMarker.on('move', sectorMarkerDrag)
            map._activeSectorMarker.on('dragend', sectorMarkerDragEnd)
          }
        }
      } else {
        if (map._activeSectorMarker) {
          map._activeSectorMarker.removeFrom(map)
          delete map._activeSectorMarker
        }
      }
      map._activeSector = activeSector
      if (map._sectors?.activeSite) {
        map._sectors.activeSite.activeSector = activeSector
      }
      map._sectors?.redraw(true)
      setTargetCoverageButton(!!activeSector)
    }
  }, [ map, activeSector, sectorMarkerDrag, sectorMarkerDragEnd, setTargetCoverageButton, userAccess ])

  useEffect(() => {
    map._sectors = new L.MarkersCanvas({ beforeRedraw, type: 'sector' })
    map._sectors.addTo(map)
  }, [ map ])

  const labelAttributeIdx = useMemo(() => Object.entries(sectorSettings).reduce((result, [ key, value ]) => ({
    ...result,
    [key]: sectorFields.findIndex(({ id }) => id === value?.labeling?.attribute),
  }), {}), [ sectorSettings, sectorFields ])

  // Ініціалізація рендеру секторів
  useEffect(() => {
    if (
      map && shown && sectors?.getList().length && sectorSettings &&
      (!map._sectors || map._sectors._version !== sectorsRedraw)
    ) {
      map._sectors.clear()
      const types = sectorTypesVisibility.getList()
      const markers = sectors.getList()
        .filter((item) => isItemVisible(item, sectorTypeIdx, types, sectorSortFn, idIdx2, latIdx2, lngIdx2, statusIdx2))
        .map((item) => {
          let lng = item[lngIdx2]
          let lat = item[latIdx2]
          const offset = {
            x: item[xIdx2],
            y: item[yIdx2],
          }
          let basement
          if (offset.x || offset.y) {
            basement = { lat, lng }
            const p = meters.project(new L.LatLng(lat, lng))
            p.x += offset.x * METERS_FACTOR
            p.y += offset.y * METERS_FACTOR
            const result = meters.unproject(p)
            lat = result.lat
            lng = result.lng
          }
          const base = SECTOR_BASE[item[tIdx2]]
          const figure = sectorsIcon[item[tIdx2]] || sectorTypes[item[tIdx2]] || 'U'
          return L.marker([ lat, lng ], {
            id: item[idIdx2],
            site: item[siteIdx2],
            name: item[nameIdx2],
            type: 'sector',
            technology: item[tIdx2],
            draw: drawSector,
            figure,
            angle: item[azIdx2],
            active: item[statusIdx2] === STATUS_ACTIVE,
            offset,
            basement,
            ratio,
            color: item[statusIdx2] === STATUS_DRAFT
              ? '#00000000'
              : determineColor(item, sectorSettings[base], colorAttributeIdx[base], idIdx2),
            label: determineLabel(item, sectorSettings[base]?.labeling, labelAttributeIdx[base]),
          })
        })
      map._sectors._map = map
      map._sectors.addMarkers(markers)
      map._sectors._version = sectorsRedraw
    } else if (map && shown && sectors && !sectors.getList().length && map._sectors) {
      map._sectors.clear()
    }
  }, [
    map, sectors, idIdx2, nameIdx2, latIdx2, lngIdx2, azIdx2, xIdx2, yIdx2, tIdx2, siteIdx2, sectorTypes, sectorTypeIdx,
    sectorFields, sectorTypesVisibility, sectorSortFn, sectorsRedraw, sectorSettings, colorAttributeIdx, statusIdx2,
    labelAttributeIdx, ratio, shown,
    sectorsIcon,
  ])

  // Перемикання видимості секторів
  useEffect(() => {
    if (map) {
      map._sectors?.setVisible(sectorsVisibility)
    }
  }, [ map, sectorsVisibility, sectors ])

  return null
}

export default Sectors
