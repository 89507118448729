import React, { useEffect, useState } from 'react'
import { Spinner, SpinnerSize } from '@fluentui/react'
import { useDispatch } from 'react-redux'
import { initCalc, sortingRateIndex, sortingRateSite } from '../utils'
import { ELEMENTS, EXECUTION_STATE, LABELS_BY_STAGE, siteNameForElement } from '../constants'
import { getIndexById } from '../../../features/network/filtration'
import {
  getResourceForPeriod, setExecutionState, setRatesResult, setSortedRates,
} from '../../../features/compositeIndex/compositeIndexSlice'
import { RBS_STATUS_FIELD, SECTOR_STATUS_FIELD, SITE_STATUS_FIELD } from '../../../constants/network'
import { errorFixing } from '../../../utils/format'

const CalculateRates = ({ elementKey, kpi, months, initData, setInitData }) => {
  const dispatch = useDispatch()

  const [ stage, setStage ] = useState(0)
  const [ initCalcData, setInitCalcData ] = useState()
  const [ summa, setSumma ] = useState()
  const [ matrix, setMatrix ] = useState()
  const [ rates, setRates ] = useState()
  const [ sortingRates, setSortingRates ] = useState()
  const [ currentIndex, setCurrentIndex ] = useState(0)
  const [ sorted, setSorted ] = useState(0)

  // Підготовка вихідних даних для розрахунку
  useEffect(() => {
    const { resource, fields } = dispatch(getResourceForPeriod(months, elementKey))
    if (Array.isArray(resource) && resource.length > 0 && Array.isArray(fields) && Array.isArray(kpi)) {
      const idStatus = elementKey === ELEMENTS.SITES
        ? SITE_STATUS_FIELD
        : elementKey === ELEMENTS.RBS
          ? RBS_STATUS_FIELD
          : elementKey === ELEMENTS.SECTORS ? SECTOR_STATUS_FIELD : ''
      const statusIdx = fields.findIndex((field) => field.id === idStatus)
      const resourceActive = statusIdx === -1
        ? resource
        : resource.filter((row) => row[statusIdx].toUpperCase() === 'ACTIVE')
      const kpiFiltered = kpi.filter((item) => item.weight)
      dispatch(setRatesResult(null))
      dispatch(setSortedRates(null))
      setInitData({ resource: resourceActive, fields, kpi: kpiFiltered })
      setStage(1)
    } else {
      // Вихідні дані з помилками
      setInitData(null)
      setStage(0)
      errorFixing({ stack: 'No data to calculate', message: 'No data to calculate' })
      dispatch(setExecutionState(EXECUTION_STATE.EDIT))
    }
    return () => {
      setCurrentIndex(0)
      setStage(0)
    }
  }, [ dispatch, elementKey, months, kpi, setInitData ])

  // Старт розрахунку
  useEffect(() => {
    if (initData && stage === 1) {
      const { weights, better, indexField, kHigher, kLower } = initCalc(kpi, initData.resource, initData.fields)
      // Сума значень показника по усіх елементах мережі
      const sum = indexField.map((index) => initData.resource.reduce((accum, item) => (accum + item[index]), 0))
      setInitCalcData({ weights, better, indexField, kHigher, kLower })
      setSumma(sum)
      setStage(2)
    }
  }, [ stage, initData, kpi ])

  useEffect(() => {
    if (summa && stage === 2) {
      try {
        const { better, indexField, kHigher, kLower } = initCalcData
        const { resource } = initData
        // Нормування показників згідно з вибраним типом (пряме/зворотне)
        const matrix = resource.map((item) => (indexField.map((indexF, index) => {
          const res = better[index]
            ? item[indexF] * kHigher / summa[index]
            : (1 - item[indexF] / summa[index]) * kLower
          if (Number.isNaN(res)) {
            throw new Error('At least one of the KPI has no data')
          }
          return res
        })))
        setMatrix(matrix)
        setStage(3)
      } catch (e) {
        errorFixing(e)
        dispatch(setExecutionState(EXECUTION_STATE.EDIT))
      }
    }
  }, [ summa, initCalcData, initData, stage, dispatch ])

  // Фінальний етап розрахунку
  useEffect(() => {
    if (matrix && stage === 3) {
      const { fields, resource } = initData
      const { weights } = initCalcData
      const rate = matrix.map((line) => line.reduce((accum, item, index) => (accum + item * weights[index]), 0))
      const siteFieldName = siteNameForElement[elementKey]
      const indexSiteName = getIndexById(fields, siteFieldName)
      // Дані для збереження композитних індексів
      dispatch(setRatesResult(rate, elementKey, resource, fields))
      // Заповнення колонок назвою сайту
      const rates = rate.map((rate, index) => [ resource[index]?.[indexSiteName] ?? '', index, rate ])
      setRates(rates)
      setStage(4)
    }
  }, [ matrix, stage, initData, initCalcData, elementKey, dispatch ])

  // Перший етап сортування
  useEffect(() => {
    if (rates && stage === 4) {
      const indexRate = rates[0]?.length - 1 // columnsTableRate[elementKey]?.length
      try {
        setRates(sortingRateIndex(rates, indexRate))
        setStage(5)
      } catch (error) {
        errorFixing(error)
        dispatch(setExecutionState(EXECUTION_STATE.EDIT))
      }
    }
  }, [ stage, rates, dispatch, elementKey ])

  // Другий етап сортування (не має сенсу для сайтів)
  useEffect(() => {
    if (rates && stage === 5) {
      try {
        const { rateSorting, currentIndex: index, sorted } = sortingRateSite(rates, currentIndex)
        if (index) {
          setSorted(sorted)
          setCurrentIndex(index)
        } else {
          setSortingRates(rateSorting)
          setStage(10)
        }
      } catch (error) {
        errorFixing(error)
        dispatch(setExecutionState(EXECUTION_STATE.EDIT))
      }
    }
  }, [ currentIndex, dispatch, elementKey, stage, rates ])

  useEffect(() => {
    if (stage === 10) {
      dispatch(setSortedRates(sortingRates))
      dispatch(setExecutionState(EXECUTION_STATE.CALCULATED))
    }
  }, [ dispatch, stage, sortingRates ])

  return (
    <Spinner
      className="centered"
      styles={{
        root: {
          backgroundColor: 'rgba(80,80,80,0.21)',
          width: '100%',
          height: '100%',
          zIndex: 1,
        },
        label: {
          fontSize: '1.1em',
          borderRadius: '12px',
          border: '8px solid transparent',
          backgroundColor: 'rgba(220,220,220,0.61)',
        },
      }}
      label={` ${LABELS_BY_STAGE[stage]} ${sorted ? `${sorted} out of ${rates.length}` : ''} `}
      size={SpinnerSize.large}
    />
  )
}

export default CalculateRates
