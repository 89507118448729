import React, { useMemo } from 'react'
import { IconButton, Stack } from '@fluentui/react'
import { Callout } from '@fluentui/react/lib/Callout'
import { useBoolean, useId } from '@fluentui/react-hooks'

import './EditFieldLabel.css'

const stackTokens = {
  childrenGap: 4,
  maxWidth: 300,
}
const labelCalloutStackStyles = { root: { padding: 20 } }
const iconProps = { iconName: 'Info' }

const EditFieldLabel = ({ label, description, showLabel, className }) => {
  const [ isCalloutVisible, { toggle: toggleIsCalloutVisible } ] = useBoolean(false)

  const descriptionId = useId('description')
  const iconButtonId = useId('iconButton')

  const cn = useMemo(() => {
    const baseClass = 'scapex-editfield-label'
    return className ? `${baseClass} ${className}` : baseClass
  }, [ className ])

  return label && showLabel
    ? (
        <div className={cn}>
          <Stack
            className='scapex-editfield-label-stack'
            horizontal
            verticalAlign='center'
            tokens={stackTokens}
          >
            <span title={label}>{label}</span>
            {description && (
              <IconButton
                id={iconButtonId}
                iconProps={iconProps}
                title={description}
                ariaLabel='Info'
                onClick={toggleIsCalloutVisible}
              />
            )}
          </Stack>
          <div className='scapex-editfield-label-background'/>
          {isCalloutVisible && (
            <Callout
              target={'#' + iconButtonId}
              setInitialFocus
              onDismiss={toggleIsCalloutVisible}
              ariaDescribedBy={descriptionId}
              role='alertdialog'
            >
              <Stack tokens={stackTokens} horizontalAlign='start' styles={labelCalloutStackStyles}>
                <span id={descriptionId}>{description}</span>
              </Stack>
            </Callout>
          )}
        </div>
      )
    : null
}

export default EditFieldLabel
