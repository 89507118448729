import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'
import {
  arraysEqual, ChoiceGroup, ConstrainMode, DetailsList, DetailsListLayoutMode, Stack, StackItem,
} from '@fluentui/react'
import { TextField } from '@fluentui/react/lib/TextField'
import { useDispatch, useSelector } from 'react-redux'
import { Selection, SelectionMode } from '@fluentui/utilities'
import { useLocation } from 'react-router'
import cn from 'classnames'
import { useHandler } from '../../../hooks'
import { IconSvg } from '../../common/icons'
import { SearchIcon, DragIcon, ShowIcon, HideIcon } from '../../common/icons/names'
import { PrimaryButton, SecondaryButton } from '../../common/Button'
import { testUniqueArray } from '../../../utils/math'
import {
  selectBaseStationFields, selectComplaintFields, selectSectorFields, selectSiteFields,
} from '../../../features/network/networkSlice'
import { DATA_TYPES, PAGE_TO_DATA_TYPE } from '../../../constants/common'
import {
  saveSettingToServer, selectSettings, settingPropertyOnPath, SETTINGS_KEYS,
} from '../../../features/settings/settingsSlice'
import { selectBCFields, selectCreateBCFields } from '../../../features/bc/bcSlice'
import { selectMapAttributes } from '../../../features/vector/vectorSlice'
import { DEFAULT_DENSITY_OPTION, densityOptions } from '../../../constants/columns'
import { generateColumnsVectorMap } from '../../../features/vector/utils'
import { alwaysFalse, getItemKey } from '../../../utils/format'
import { getDragDropEvents, initDragged } from './drag'

import './Columns.css'

export const columnsTable = [
  { key: 'column1', name: 'Name column', fieldName: 'label', minWidth: 250, maxWidth: 360, isResizable: false },
]

const gridStyles = {
  root: {
    overflowX: 'auto',
    selectors: {
      '& [role=grid]': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        height: 'auto',
      },
    },
    borderBottom: '1px solid var(--grey-2)',
  },
  cell: {
    padding: 0,
  },
  cellUnpadded: {
    padding: 0,
  },
  headerWrapper: {
    flex: '0 0 auto',
  },
  contentWrapper: {
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
  },
}

const Columns = ({ onShowColumn }) => {
  const dispatch = useDispatch()
  const selection = useRef(new Selection())
  const [ dragged, setDragged ] = useState(initDragged)
  const [ tableDensity, setTableDensity ] = useState(DEFAULT_DENSITY_OPTION)
  const [ currentSettingsColumn, setCurrentSettingsColumn ] = useState([])
  const [ initSettings, setInitSettings ] = useState(null)
  const [ filter, setFilter ] = useState(null)
  const projectSettings = useSelector(selectSettings) // { colNotDisplay, colTablesMove }
  const siteFields = useSelector(selectSiteFields)
  const sectorFields = useSelector(selectSectorFields)
  const baseStationFields = useSelector(selectBaseStationFields)
  const complaintsFields = useSelector(selectComplaintFields)
  const bcFields = useSelector(selectBCFields)
  const createBcFields = useSelector(selectCreateBCFields)

  const noMoveColumns = !!filter // в отфильтрованном списоке колонки не перемещаем

  // определяем тип выбранной таблицы
  const location = useLocation()

  const dataType = useMemo(() => {
    const [ , , section, id ] = location.pathname.split('/')
    if (PAGE_TO_DATA_TYPE[section] === DATA_TYPES.MAP_ATTRIBUTES && id) {
      return `${DATA_TYPES.MAP_ATTRIBUTES}-${id}`
    } else if (PAGE_TO_DATA_TYPE[section] === DATA_TYPES.BUSINESS_CASES && id) {
      return DATA_TYPES.CREATE_BUSINESS_CASES
    }
    return PAGE_TO_DATA_TYPE[section]
  }, [ location.pathname ])

  const mapAttributes = useSelector(selectMapAttributes(dataType))

  // выбираем описание столбцов выбранной таблицы
  const columns = useMemo(() => {
    return dataType === DATA_TYPES.SITES
      ? siteFields
      : dataType === DATA_TYPES.BASE_STATIONS
        ? baseStationFields
        : dataType === DATA_TYPES.SECTORS
          ? sectorFields
          : dataType === DATA_TYPES.COMPLAINTS
            ? complaintsFields
            : dataType === DATA_TYPES.BUSINESS_CASES
              ? bcFields
              : dataType === DATA_TYPES.CREATE_BUSINESS_CASES
                ? createBcFields
                : dataType?.startsWith(DATA_TYPES.MAP_ATTRIBUTES)
                  ? generateColumnsVectorMap(dataType, mapAttributes?.fields)
                  : []
  }, [
    dataType, siteFields, baseStationFields, sectorFields, complaintsFields, bcFields, mapAttributes, createBcFields,
  ])

  const noData = !(columns?.length)

  // определение отличие настроек от настроек по умолчанию
  const isEnabledReset = useMemo(() => {
    const { colNotDisplay, colTablesMove } = projectSettings ?? {}
    const colTableMove = colTablesMove?.[dataType] ?? []
    return (colNotDisplay?.[dataType] && colNotDisplay[dataType].length > 0) ||
      columns.some((column, index) => (column.id !== colTableMove[index]))
  }, [ columns, dataType, projectSettings ])

  // определение эквивалентности настроек
  const isEqualSetting = useMemo(() => {
    const { colNotDisplay, colTablesMove, [SETTINGS_KEYS.TABLE_DENSITY]: tablesDensity } = projectSettings ?? {}
    const projectMove = (colTablesMove[dataType] ? [ ...colTablesMove[dataType] ] : [])
    const projectNotDisplay = colNotDisplay[dataType] ? [ ...colNotDisplay[dataType] ] : []
    const currentNotDisplay = []
    const currentMove = currentSettingsColumn.map((item) => {
      if (item.notDisplayed) { currentNotDisplay.push(item.key) }
      return item.key
    })
    // восстановление скрытых колонок в списке
    columns.forEach((column, index) => {
      if (column.hidden) {
        currentMove.splice(index, 0, column.id)
      }
    })
    const isEqualMove = currentMove.every((column, index) => (projectMove[index] === column))
    const projectTableDensity = tablesDensity[dataType]
    const isEqualDensity = projectTableDensity === tableDensity.value ||
      (projectTableDensity == null && tableDensity.value === DEFAULT_DENSITY_OPTION.value)
    currentNotDisplay.sort()
    projectNotDisplay.sort()
    return arraysEqual(currentNotDisplay, projectNotDisplay) && isEqualMove && isEqualDensity
  }, [ projectSettings, dataType, currentSettingsColumn, columns, tableDensity ])

  useEffect(() => setInitSettings(projectSettings), [ projectSettings ])

  // подготовка списка колонок, настроек
  useEffect(() => {
    const { colNotDisplay, colTablesMove, [SETTINGS_KEYS.TABLE_DENSITY]: tablesDensity } = initSettings ?? {}
    if (!Array.isArray(columns) || !initSettings || !colNotDisplay || !dataType) {
      setCurrentSettingsColumn([])
      return
    }

    const tableDensity = tablesDensity?.[dataType]
    setTableDensity(densityOptions.find((option) => (option.value === tableDensity)) ?? densityOptions[1])

    const colNotDisplayTable = colNotDisplay[dataType] ?? []
    // перестановка колонок согласно настройкам
    const columnsMove = colTablesMove[dataType] ?? []
    if (columnsMove?.length === columns.length && testUniqueArray(columnsMove)) {
      // вроде есть полная матрица перестановок

      const colNotDisplayTable = colNotDisplay[dataType] ?? []
      const items = columns
        .map((column) => ({
          key: column.id,
          label: column.label,
          hidden: column.hidden,
          notDisplayed: colNotDisplayTable.includes(column.id),
          group: column.page,
        }))

      const currentSettings = columnsMove.map((columnMove) => {
        return items.find((item) => (item.key === columnMove))
      }).filter(Boolean)

      if (currentSettings.length === items.length) {
        const activeItems = currentSettings.filter((item) => (!item.hidden)) // удаляем скрытые столбцы
        setCurrentSettingsColumn(activeItems)
        return
      }
    }
    // есть ошибки при перестановке столбцов, перемещения нужно сбросить, выводим исходный список
    if (colNotDisplayTable.length !== 0) { // сброс отключенных столбцов
      dispatch(settingPropertyOnPath([], [ SETTINGS_KEYS.COL_NOT_DISPLAY, dataType ]))
    }
    const resetColumnsMove = columns.map((column) => column.id)
    dispatch(settingPropertyOnPath(resetColumnsMove, [ SETTINGS_KEYS.COL_TABLES_MOVE, dataType ]))
    dispatch(saveSettingToServer)
  }, [ projectSettings, columns, dataType, initSettings, dispatch ])

  const dragDropEvents = useMemo(() => {
    const insertBeforeItem = (item) => {
      const draggedItems = selection.current.isIndexSelected(dragged.draggedIndex)
        ? (selection.current.getSelection())
        : [ dragged.draggedItem ]
      const insertIndex = currentSettingsColumn.indexOf(item)
      const items = currentSettingsColumn.filter((item) => draggedItems.indexOf(item) === -1)

      items.splice(insertIndex, 0, ...draggedItems)
      setCurrentSettingsColumn(items)
    }
    return getDragDropEvents(dragged, setDragged, insertBeforeItem)
  }, [ currentSettingsColumn, dragged ])

  const handleShowColumn = useCallback((id) => {
    if (onShowColumn) {
      onShowColumn({ dataType, id })
    }
  }, [ dataType, onShowColumn ])

  const onRenderItemColumn = useCallback((item, index, column) => (
    <TD
      item={item}
      column={column}
      currentSettings={currentSettingsColumn}
      setCurrentSettings={setCurrentSettingsColumn}
      noMoveColumns={noMoveColumns}
      onShowColumn={handleShowColumn}
    />
  ), [ currentSettingsColumn, noMoveColumns, handleShowColumn ])

  const onChangeSizeHandler = useHandler((ev, option) => {
    setTableDensity(option)
  })

  const onRenderPrefix = useHandler((props) => {
    return <IconSvg name={props.prefix} />
  })

  const onApplyHandler = useHandler(() => {
    if (!Array.isArray(currentSettingsColumn) || !dispatch || !dataType) { return }
    const colNotDisplay = []
    const columnsMove = currentSettingsColumn.map((columnTable) => {
      if (columnTable.notDisplayed === true) {
        colNotDisplay.push(columnTable.key)
      }
      return columnTable.key
    })
    // занесеник данных по скрытым столбцам
    columns.forEach((column, index) => {
      if (column.hidden) {
        columnsMove.splice(index, 0, column.id)
      }
    })
    dispatch(settingPropertyOnPath(colNotDisplay, [ SETTINGS_KEYS.COL_NOT_DISPLAY, dataType ]))
    dispatch(settingPropertyOnPath(columnsMove, [ SETTINGS_KEYS.COL_TABLES_MOVE, dataType ]))
    dispatch(settingPropertyOnPath(tableDensity.value, [ SETTINGS_KEYS.TABLE_DENSITY, dataType ]))
    dispatch(saveSettingToServer)
  })

  const onResetHandler = useHandler(() => {
    const columnsMove = columns.map((column) => (column.id))
    dispatch(settingPropertyOnPath([], [ SETTINGS_KEYS.COL_NOT_DISPLAY, dataType ]))
    dispatch(settingPropertyOnPath(columnsMove, [ SETTINGS_KEYS.COL_TABLES_MOVE, dataType ]))
    dispatch(saveSettingToServer)
  })

  const onChangeFilter = useHandler((ev, text) => {
    setFilter(text)
  })

  // отфильтровуем записи
  const items = useMemo(() => {
    const f = filter?.toLowerCase ? filter.toLowerCase() : ''
    return noMoveColumns
      ? currentSettingsColumn.filter((i) => i.label.toLowerCase().indexOf(f) > -1)
      : currentSettingsColumn
  }, [ currentSettingsColumn, noMoveColumns, filter ])

  return noData
    ? null
    : (
    <Stack veticalFill={true} grow={true} className={'columns-full'} >
      <StackItem verticalFill={true} grow={true} className={'columns-body'}>
      <ChoiceGroup
        selectedKey={tableDensity.key}
        label="Table density"
        options={densityOptions}
        className={'groupFlexRow settings-columns'}
        onChange={onChangeSizeHandler}
      />
      <div className={'padding-large section margin-topLarge'}>
        <TextField
          label={'Search Column'}
          prefix={SearchIcon}
          className={'search'}
          placeholder={'Search Column'}
          onChange={onChangeFilter}
          onRenderPrefix={onRenderPrefix}
        />
      </div>
        <DetailsList
          isHeaderVisible={false}
          items={items}
          columns={columnsTable}
          enterModalSelectionOnTouch={true}
          constrainMode={ConstrainMode.unconstrained}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selectionMode={SelectionMode.none}
          compact={false}
          dragDropEvents={noMoveColumns ? undefined : dragDropEvents}
          onRenderItemColumn={onRenderItemColumn}
          styles={gridStyles}
          getKey={getItemKey}
          setKey="settingsColumns"
          onShouldVirtualize={alwaysFalse}
        />
      </StackItem>
      <Stack className={'padding-large section'} tokens={{ childrenGap: 24 }} horizontal >
        <SecondaryButton
          text={'Reset'}
          className={'flex-uniform'}
          onClick={onResetHandler}
          disabled={!isEnabledReset}
        />
        <PrimaryButton text={'Apply'} className={'flex-uniform'} onClick={onApplyHandler} disabled={isEqualSetting} />
      </Stack>
    </Stack>
      )
}

export default Columns

const TD = ({ item, column, currentSettings, setCurrentSettings, noMoveColumns, onShowColumn }) => {
  const checked = item.hidden ? false : !item.notDisplayed

  const onChangeHandler = useHandler((e) => {
    if (!Array.isArray(currentSettings)) {
      return
    }
    const newSettings = currentSettings.map((setting) => item.key !== setting.key && !e.shiftKey
      ? setting
      : {
          ...setting,
          notDisplayed: checked,
        },
    )
    setCurrentSettings(newSettings)
  })

  const handleClick = (item) => () => {
    if (item && !item.notDisplayed) {
      onShowColumn(item.key)
    }
  }

  return (
    <Stack horizontal className={'columns-field'} >
      <Stack.Item
        onClick={item.hidden ? null : onChangeHandler}
        className={cn('displayed', item.hidden ? 'is-disabled' : '')}
      >
        <IconSvg name={checked ? ShowIcon : HideIcon} />
      </Stack.Item>
      <Stack.Item
        className={'column-name'}
        onClick={handleClick(item)}
      >
        <label className={cn('group margin-left-middle')}>
          {item?.group?.toUpperCase ? item.group.toUpperCase() : ''}
        </label>
        <label className={cn('name margin-left-middle')}>{item?.[column.fieldName]}</label>
      </Stack.Item>
      <Stack.Item
        className={cn('move', item.hidden || noMoveColumns ? 'is-disabled' : '')}
      >
        <IconSvg name={DragIcon} />
      </Stack.Item>
    </Stack>
  )
}
