import React from 'react'
import { v4 as uuid } from 'uuid'
import {
  ComboBox,
  Label,
  Stack,
  Spinner,
  SpinnerSize,
  SelectableOptionMenuItemType,
} from '@fluentui/react'
import { DefaultButton } from '@fluentui/react/lib/Button'
import { EXISTING_STATUS_ID } from '../../features/bc/constants'

export const NEW_HEADER = [
  { key: 'divider', text: '-', itemType: SelectableOptionMenuItemType.Divider },
  { key: 'NewHeader', text: 'New', itemType: SelectableOptionMenuItemType.Header },
]

const comboBoxStyles = { root: { maxWidth: 300 } }

export const CreateBCHeader = ({
  loading,
  groupStatus,
  selectedGCKey,
  options,
  onChangeGCName,
  isSelectEnabled,
  onSelectGCName,
}) => {
  const onChange = (event, option, index, value) => {
    if (onChangeGCName) {
      let key = option?.key
      let newOptions = null
      let isNew = false
      if (!option && value) {
        // If allowFreeform is true, the newly selected option might be something the user typed that
        // doesn't exist in the options list yet. So there's extra work to manually add it.

        // Update existing BCG
        if (selectedGCKey && groupStatus === EXISTING_STATUS_ID.SELECTED) {
          key = selectedGCKey
          newOptions = options.map((item) => {
            if (item.key === key) {
              item.text = value
            }
            return item
          })
        } else {
          key = uuid()
          isNew = true
          const newHeaderAdded = options.findIndex(({ key }) => (key === 'NewHeader')) !== -1
          newOptions = [
            ...options,
            ...(newHeaderAdded ? [] : NEW_HEADER),
            { key, text: value },
          ]
        }
      }
      if (key) {
        onChangeGCName(key, isNew, newOptions, value)
      }
    }
  }

  return (
    <>
      <Label disabled>{groupStatus}</Label>
      <Stack horizontal tokens={{ childrenGap: 10 }} style={{ alignItems: 'center' }}>
        <ComboBox
          selectedKey={selectedGCKey}
          options={options}
          onChange={onChange}
          autoComplete="on"
          allowFreeform={true}
          styles={comboBoxStyles}
        />
        <DefaultButton text={'Select'} disabled={!isSelectEnabled || loading} onClick={onSelectGCName} />
        <div style={{ width: 20 }}>
          {loading && (
            <Spinner size={SpinnerSize.small} />
          )}
        </div>
      </Stack>
    </>
  )
}

export default CreateBCHeader
