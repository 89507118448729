import React, { useRef, useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Handsontable from 'handsontable'
import Table from '../common/Table'
import {
  loadTRFData, saveTRFData, selectTRFData, selectTRFFields, selectSaving,
  setUpdatedValues, selectUpdatedValues, addNewRow,
  editTRFField, editTRFFields, importTRF, removeLastRow,
  setLoading,
} from '../../features/trf/trfSlice'
import {
  PERIOD_MONTH,
} from '../../features/trf/utils'
import * as Headers from '../../features/trf/headers'
import { findIndex } from '../../features/network/indexing'
import { EVENT_TYPE, saveEventLog, wrapByEventLog } from '../../features/eventLog/eventLogSlice'
import { oldCellTypeDate } from '../common/utils/hotTable'
import { empty } from '../../utils/format'
import { getActClassName } from './constants'

import './TRFTable.css'

const IMPORT_EXPORT_OPTIONS = [ 'clip', 'xlsx' ]
const ADD_ROWS_OPTIONS = [ 1 ]

const typeRenderer = function (instance, td, row) {
  Handsontable.renderers.DropdownRenderer.apply(this, arguments)
}

const numberRenderer = function (instance, td, row, meta) {
  let renderer
  try {
    renderer = Handsontable.renderers.getRenderer('numeric')
  } catch (e) {
    renderer = Handsontable.renderers.NumericRenderer
  }
  renderer.apply(this, arguments)
}

const boldNumberRenderer = function (instance, td, row) {
  let renderer
  try {
    renderer = Handsontable.renderers.getRenderer('numeric')
  } catch (e) {
    renderer = Handsontable.renderers.NumericRenderer
  }
  renderer.apply(this, arguments)
  td.style.fontWeight = 'bold'
}

const TRFTable = ({ activeView, msg, trfActions: prevTRFActions, setTrfActions }) => {
  const dispatch = useDispatch()

  const data = useSelector(selectTRFData)
  const columns = useSelector(selectTRFFields)
  const idFieldIdx = useMemo(() => {
    return columns && findIndex(columns, Headers.TRF_DATE_FIELD)
  }, [ columns ])
  const saving = useSelector(selectSaving)
  const updatedValues = useSelector(selectUpdatedValues)

  const gridRef = useRef()

  const dateRenderer = useCallback((instance, td, row, col, prop, value, cellProperties) => {
    const isMonth = activeView === PERIOD_MONTH
    oldCellTypeDate.renderer(instance, td, row, col, prop, value, cellProperties)
    // Handsontable.renderers.TextRenderer.apply(this, arguments)
    if (cellProperties?.readOnly) {
      td.className = (td.className ? td.className + ' ' : '') + 'read-only-cell'
    }

    const date = new Date(value)
    const stringValue = date.toLocaleString(
      'en-us',
      isMonth ? { month: 'short', year: 'numeric' } : { year: 'numeric' },
    )
    td.innerHTML = `<span>${stringValue}</span>`
  }, [ activeView ])

  const cells = useCallback((row, col) => {
    const cellProperties = {}
    const id = columns[col].id
    if (id === Headers.TRF_TYPE_FIELD) {
      cellProperties.renderer = typeRenderer
    } else if (id === Headers.TRF_DATE_FIELD) {
      cellProperties.renderer = dateRenderer
    } else if ([
      Headers.TRF_DATA_TOTAL_FIELD,
      Headers.TRF_VOICE_TOTAL_FIELD,
      Headers.TRF_A1M_TOTAL_FIELD,
    ].includes(id)) {
      cellProperties.renderer = boldNumberRenderer
    } else {
      cellProperties.renderer = numberRenderer
    }

    const table = gridRef.current?.hotInstance
    cellProperties.className = (cellProperties.className ? cellProperties.className + ' ' : '') +
      getActClassName(table?.getDataAtCell(row, 0) === 'ACT')

    return cellProperties
  }, [ columns, dateRenderer ])

  const onClickAddRow = useCallback(async () => {
    const table = gridRef.current?.hotInstance
    if (table) {
      await dispatch(saveEventLog(EVENT_TYPE.trfActions, ': Add new row.'))
      const numOfRows = table.countRows()
      await dispatch(addNewRow(activeView))
      // Add any changes so as not to lose the line when saving.
      await dispatch(editTRFField({ row: numOfRows, fieldIndx: 2, value: 0 }))
      table.selectCell(numOfRows, 0)
      table.deselectCell()
    }
  }, [ dispatch, activeView ])

  const onClickRemoveRow = useCallback(async () => {
    const table = gridRef.current?.hotInstance
    if (table && table.countRows() > 1) {
      await dispatch(saveEventLog(EVENT_TYPE.trfActions, ': Remove last row.'))
      // table.alter('remove_row', table.countRows() - 1, 1)
      await dispatch(removeLastRow())
    }
  }, [ dispatch ])

  const onChangeData = useCallback((row, col, before, after) => {
    if (after !== before) {
      dispatch(editTRFField({ row, fieldIndx: col, prevValue: before, value: after }))
    }
  }, [ dispatch ])

  const onChangeMultipleData = useCallback((updated) => {
    const table = gridRef.current?.hotInstance
    const list = data?.getList()
    if (!table || !list) {
      return
    }
    const changes = {}
    updated.forEach(({ row, field, oldValue, normalized }) => {
      if (normalized === null) {
        normalized = 0
        // table.setDataAtCell(row, field, 0)
      }
      const id = list[row][idFieldIdx]
      if (!changes[id]) {
        changes[id] = [ {
          field,
          value: normalized,
          oldValue,
          row,
          col: field,
        } ]
      } else {
        changes[id].push({
          field,
          value: normalized,
          oldValue,
          row,
          col: field,
        })
      }
    })
    dispatch(editTRFFields(Object.keys(changes).map((key) => ({
      id: key,
      updates: changes[key],
    }))))
  }, [ dispatch, data, idFieldIdx ])

  const onImportStarted = useCallback(async (cols, rows) => {
    dispatch(setLoading(true))
  }, [ dispatch ])

  const onImportFinished = useCallback(async (cols, rows) => {
    dispatch(setLoading(false))
  }, [ dispatch ])

  const onImport = useCallback(async (cols, rows) => {
    const imported = await dispatch(wrapByEventLog({
      type: EVENT_TYPE.trfImport,
      action: async () => {
        const dateColumn = cols.findIndex((col) => col?.id === Headers.TRF_DATE_FIELD)
        if (dateColumn < 0) {
          msg({ messages: [ 'Mandatory column "Date" not found in dataset being imported' ] })
          return false
        }

        const result = await dispatch(importTRF(activeView, { cols, rows }))

        const results = []
        let success = 0
        if (result.updated > 0) {
          results.push(`${result.updated} item${result.updated > 1 ? 's where' : ' was'} updated`)
          success += result.updated
        }
        if (result.created > 0) {
          results.push(`${result.created} item${result.created > 1 ? 's where' : ' was'} created`)
          success += result.created
        }
        if (result.ignored > 0) {
          results.push(`${result.ignored} item${result.ignored > 1 ? 's where' : ' was'} ignored`)
        }

        const messages = [
          `${results.length ? results.join(', ') : 'No changes where made to TRF'} by this import operation`,
        ]
        if (result.serverError > 0) {
          messages.push(
            `Error importing ${result.serverError} item${result.serverError > 1 ? 's' : ''}: server operation failed.`,
          )
        }
        msg({ messages })

        return success
      },
      extractor: (result) => result ? `Imported ${result} rows` : 'No rows imported',
    }))

    return imported > 0
  }, [ dispatch, msg, activeView ])

  const onSaveData = useCallback(() => dispatch(wrapByEventLog({
    type: EVENT_TYPE.trfSave,
    action: async () => {
      const res = await dispatch(saveTRFData(activeView))
      if (res) {
        dispatch(setUpdatedValues(null))
        dispatch(loadTRFData(activeView))
      }
    },
  })), [ dispatch, activeView ])

  const trfActions = useMemo(() => ({
    save: onSaveData,
    saveDisabled: !updatedValues || saving,
  }), [ onSaveData, updatedValues, saving ])

  useEffect(() => {
    if (setTrfActions) {
      if (!prevTRFActions || prevTRFActions.saveDisabled !== trfActions.saveDisabled) {
        setTrfActions(trfActions)
      }
    }
  }, [ setTrfActions, trfActions, prevTRFActions ])

  const dataLength = data?.getList()?.length

  const inlineEditRange = useCallback((changes) => {
    dispatch(editTRFFields(changes))
  }, [ dispatch ])

  const doNothing = useCallback(empty, [])

  const contextMenuDisabled = useMemo(() => ({
    deleteLastRow: dataLength <= 1,
  }), [ dataLength ])

  return data && columns
    ? (
        <Table
          refHot={gridRef}
          id={'trfTable'}
          dataType={'trf'}
          data={data}
          columns={columns}
          afterGetColHeader={doNothing}
          addRowsOptions={ADD_ROWS_OPTIONS}
          onAddRows={onClickAddRow}
          onInlineEditCell={onChangeData}
          onInlineEditCells={onChangeMultipleData}
          idFieldIdx={idFieldIdx}
          onInlineEditRange={inlineEditRange}
          currentRowClassName={''}
          disableSettings={true}
          columnSorting={false}
          onImport={onImport}
          onImportStarted={onImportStarted}
          onImportFinished={onImportFinished}
          importRawNumbers={true}
          onImportOptions={ IMPORT_EXPORT_OPTIONS }
          onExportOptions={ IMPORT_EXPORT_OPTIONS }
          cells={cells}
          allowMultipleEdit={true}
          onDeleteRows={onClickRemoveRow}
          contextMenuDisabled={contextMenuDisabled}
        />
      )
    : null
}

export default TRFTable
