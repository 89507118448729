import React, { useCallback, useState } from 'react'
import { TextField } from '@fluentui/react'
import EditFieldLabel from '../EditFieldLabel'
import { buildClassList } from '../../utils/classes'
import { LOCALE } from '../../../utils/format'

import './TextField.css'

const className = (props) => {
  const { readOnly, upperCase } = props
  let result = buildClassList('capex-editfield', { readOnly, upperCase })
  if (props.className) {
    result += ` ${props.className}`
  }
  return result
}

const valueString = (value) => value === undefined || value === null ? '' : value.toLocaleString(LOCALE)

const TextFieldComponent = ({ label, placeholder, description, onChange, ...props }) => {
  const [ currentValue, setCurrentValue ] =
    useState(valueString(props.value !== undefined ? props.value : props.defaultValue))

  const changeHandler = useCallback((event, value) => {
    setCurrentValue(value)
    onChange && onChange(event, value)
  }, [ onChange ])

  return (
    <TextField
      {...props}
      className={className(props)}
      onChange={changeHandler}
      onRenderLabel={() => (
        <EditFieldLabel
          label={label}
          description={description}
          showLabel={currentValue !== ''}
        />
      )}
      placeholder={placeholder || label}
    />
  )
}

export default TextFieldComponent
