import React from 'react'
import Grid from '../../components/common/Grid'

// eslint-disable-next-line react/display-name
const EditableGrid = ({ columns, data, onChange, refHot, disableSettings, ...others }) => {
  const afterChange = function (e, source) {
    if (source === 'populateFromArray' || source === 'loadData') {
      return
    }
    try {
      const diffIndex = e.findIndex((cell) => {
        const [ , , before, after ] = cell
        return before !== after
      })
      if (diffIndex !== -1) {
        onChange(e)
      }
    } catch (err) {}
    return true
  }

  return (
    <Grid
      refHot={refHot}
      columns={columns}
      data={data}
      manualColumnMove={false}
      multiColumnSorting={false}
      rowHeaders={false}
      dropdownMenu={false}
      afterChange={afterChange}
      disableSettings={disableSettings}
      {...others}
    />
  )
}

export default EditableGrid
