import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  selectEditPath, cleanUpBundle,
  selectSiteFldIdx, deleteSite, saveBundle, selectSectorFldIdx, selectBaseStationFldIdx,
  createNewSector, setBundlePath, deleteSector, setInUpdate,
} from '../../features/network/networkSlice'
import { ensureUserProject } from '../../features/projects/projectsSlice'
import { selectOfflineMode } from '../../features/loading/loadingSlice'
import { checkNetworkItemByBCStatus, getIncludesSitesInBC } from '../../features/bc/bcSlice'
import { setPanel } from '../../features/panel/panelSlice'
import { BC_STATUS_ID } from '../../features/bc/constants'
import { CAN_BE_REMOVED, SECTOR_BASE, STATUS_REMOVED } from '../../constants/network'
import { useConfirm } from '../common/Confirm'
import Button from '../common/Button/Button'
import BundleTreeItem from './BundleTreeItem'

import './NetworkItemBundleTree.css'

const SECTOR_COUNT_LIMIT = 32
const LINE_HEIGHT = 70 + 25

const less = (arr1, arr2) => arr1?.length < arr2?.length && arr1?.join('-') === arr2?.slice(0, arr1?.length)?.join('-')

const NetworkItemBundleTree = ({ bundle, inUpdate, readOnly }) => {
  const dispatch = useDispatch()

  const path = useSelector(selectEditPath)
  const [ ,,,, siteStatusFldIdx ] = useSelector(selectSiteFldIdx, shallowEqual)
  const [ ,,, statusFldIdx2 ] = useSelector(selectBaseStationFldIdx, shallowEqual)
  const [ ,,,,,,, techFldIdx, statusFldIdx ] = useSelector(selectSectorFldIdx, shallowEqual)
  const offlineMode = useSelector(selectOfflineMode)

  const { renderConfirm, confirm } = useConfirm()

  const tech = useMemo(() => Object.keys(SECTOR_BASE).slice(1), [])

  const onStartUpdate = useCallback(async () => {
    await dispatch(saveBundle(bundle))
    dispatch(setInUpdate(true))
  }, [ dispatch, bundle ])

  const onDeleteSite = useCallback(async () => {
    confirm(
      async () => {
        await onStartUpdate()
        await dispatch(deleteSite({ siteId: bundle.id }))
        dispatch(cleanUpBundle())
        // Close panel, site is removed
        dispatch(setPanel(null))
      },
      {
        messages: [ `Delete site candidate ${bundle.name}?`, 'All child elements also will be deleted' ],
      },
    )
  }, [ dispatch, bundle, confirm, onStartUpdate ])

  const canDeleteSite = useMemo(() => {
    return bundle && CAN_BE_REMOVED.includes(bundle.element[siteStatusFldIdx]) && !offlineMode &&
      // !dispatch(checkNetworkItemByBCStatus(bundle.id, 'site')) &&
      !dispatch(getIncludesSitesInBC([ bundle.id ]))
  }, [ bundle, siteStatusFldIdx, offlineMode, dispatch ])

  const current = useMemo(() => {
    return bundle
      ? path.reduce((acc, index) => acc.children[index], bundle)
      : null
  }, [ bundle, path ])

  const getRBSTech = (rbs, techFldIdx) => rbs.children[0].element[techFldIdx]

  const baseStations = useMemo(() => {
    const result = {}
    if (bundle && bundle.children) {
      for (const child of bundle.children) {
        if (child.children[0]) {
          result[getRBSTech(child, techFldIdx)] = child
        }
      }
    }
    return result
  }, [ bundle, techFldIdx ])

  const rows = useMemo(() => {
    const length = Object.keys(baseStations).length
      ? tech.reduce((agg, key) => {
        return Math.max(agg, baseStations[key] ? baseStations[key].children.length : 0)
      }, 0)
      : 0
    return bundle.type === 'complaint' ? null : Array.from({ length: length + 2 }, (v, i) => i - 1)
  }, [ tech, baseStations, bundle.type ])

  const getButtonDataset = (event) => {
    let b = event.target
    while (b && b.tagName !== 'BUTTON') {
      b = b.parentElement
    }
    return b.dataset
  }

  const addNewSector = useCallback(async (event) => {
    const projectId = await dispatch(ensureUserProject())
    if (projectId === '_') {
      return
    }
    const siteId = bundle.id
    const { rbs: baseStationId, tech } = getButtonDataset(event)
    await onStartUpdate()
    await dispatch(createNewSector({ siteId, baseStationId, tech, setPath: true }))
  }, [ dispatch, bundle, onStartUpdate ])

  const deleteOldSector = useCallback(({ baseStationId, sectorName, sectorId }) => (event) => {
    event.stopPropagation()
    const siteId = bundle.id
    const messages = []

    if (dispatch(checkNetworkItemByBCStatus(siteId, 'site', [ BC_STATUS_ID.NEW ]))) {
      messages.push(
        'The project has a business case in the "Сreated" status for the site to which this sector belongs.',
      )
    }
    messages.push(`Delete sector candidate ${sectorName}?`)

    confirm(
      async () => {
        await onStartUpdate()
        await dispatch(deleteSector({ siteId, baseStationId, sectorId }))
      },
      { messages },
    )
  }, [ dispatch, confirm, bundle, onStartUpdate ])

  const calcActiveSectorCount = useCallback((baseStation) => {
    let count = 0
    if (baseStation?.children) {
      for (const sector of baseStation.children) {
        if (sector.element[statusFldIdx] !== STATUS_REMOVED) {
          count++
        }
      }
    }
    return count
  }, [ statusFldIdx ])

  const selectPath = useCallback((path) => (event) => {
    path = (path || '').split('-')
    path = path[0] === '' ? [] : path.map(Number)
    dispatch(setBundlePath(path))
  }, [ dispatch ])

  const getClassName = useCallback((node, removed) => {
    const classes = [ 'in-tree-label' ]
    if (
      current?.path.length >= node?.path?.length &&
      node?.path?.join('-') === current.path.slice(0, node?.path?.length).join('-')
    ) {
      classes.push('in-tree-selected')
    }
    if (node?.path?.join('-') === current?.path.join('-')) {
      classes.push('in-tree-current')
    }
    if (node?.modified) {
      classes.push('in-tree-modified')
    }
    if (removed) {
      classes.push('in-tree-removed')
    }
    return classes.join(' ')
  }, [ current ])

  const activeTech = useMemo(() => {
    let result = 0
    let size = 0
    tech.forEach((key, index) => {
      if (baseStations[key]?.path.join('-') === current?.path.join('-')) {
        result = index + 1
        size = 0
      } else {
        const children = baseStations[key]?.children
        if (children) {
          children.forEach((element, childIndex) => {
            if (element?.path.join('-') === current?.path.join('-')) {
              result = index + 1
              size = childIndex + 1
            }
          })
        }
      }
    })
    return result > 0
      ? {
          idx: result,
          size,
        }
      : null
  }, [ tech, current, baseStations ])

  const lines = useMemo(() => {
    return tech.reduce((agg, key, index) => {
      let size = 0
      if (!baseStations[key]) {
        size = 1
      } else {
        const children = baseStations[key]?.children
        size = children ? children.length + 1 : 1
      }
      agg.push({
        idx: index + 1,
        size,
      })
      return agg
    }, [])
  }, [ tech, baseStations ])

  const renderLines = useMemo(() => {
    return (
      <>
        {lines.map(({ idx, size }) => (
          <div
            key={`line-${idx}`}
            className={`
              network-item-line
              network-item-line-${idx}`}
            style={{ height: (LINE_HEIGHT) * (size + (readOnly ? 0 : 1)) }}
          />
        ))}
        {activeTech && (
          <div
            key={'line-active'}
            className={`
              network-item-line
              network-item-line-${activeTech.idx}
              network-item-line-active
            `}
            style={{ height: (LINE_HEIGHT) * (activeTech.size + 1) }}
          />
        )}
      </>
    )
  }, [ activeTech, lines, readOnly ])

  return (
    <>
      <div className='network-item-bundle-tree'>
        <div className='network-item-bundle-tree-row'>
          {rows && renderLines}
          <BundleTreeItem
            className={getClassName(bundle, bundle?.element[siteStatusFldIdx] === STATUS_REMOVED)}
            type={bundle.type}
            name={bundle?.name}
            complaints={bundle?.complaints}
            data-path={bundle?.path.join('-')}
            onClick={selectPath(bundle?.path.join('-'))}
            onDelete={canDeleteSite && !readOnly && !inUpdate && onDeleteSite}
          />
        </div>
        {rows && rows.map((ind, idx) => (
          <div key={ind} className='network-item-bundle-tree-row'>
            {tech.map((key, techIdx) => {
              let type = 'sector'
              let removed = false
              let addButton = false
              let element = null
              let itemKey = `${key}-${idx}-${techIdx}`
              if (ind === -1) { // Technology OR RBS
                element = baseStations[key]
                if (element) {
                  removed = element?.element[statusFldIdx2] === STATUS_REMOVED
                  type = 'rbs'
                } else {
                  type = 'standard'
                  itemKey = key
                  element = {
                    name: key,
                    disabled: true,
                  }
                }
              } else { // Sectors OR Add Sector buttons
                element = baseStations[key]?.children?.[ind]
                removed = element?.element[statusFldIdx] === STATUS_REMOVED
                if (ind === baseStations[key]?.children.length || (!readOnly && ind === 0 && !baseStations[key])) {
                  addButton = true
                }
              }
              return addButton
                ? (!readOnly
                    ? (
                        <Button
                          key={itemKey}
                          text={'Add Sector'}
                          onClick={addNewSector}
                          tabIndex={0}
                          disabled={bundle?.element[siteStatusFldIdx] === STATUS_REMOVED ||
                            calcActiveSectorCount(baseStations[key]) >= SECTOR_COUNT_LIMIT ||
                            inUpdate}
                          data-tech={key}
                          data-rbs={baseStations[key]?.id}
                        />
                      )
                    : (
                        <div key={itemKey} className={'bundle-tree-item-empty'} />
                      )
                  )
                : (!element?.name
                    ? (
                        <div key={itemKey} className={'bundle-tree-item-empty'} />
                      )
                    : (
                        <BundleTreeItem
                          className={`${getClassName(element, removed)} ${key}`}
                          key={itemKey}
                          type={type}
                          name={element?.name}
                          complaints={element?.complaints}
                          complaintsBackground={
                            element?.complaints && type === 'sector' && less(element?.path, current?.path)
                              ? '#068849'
                              : null
                          }
                          data-path={element?.path?.join('-')}
                          onClick={!element?.disabled ? selectPath(element?.path?.join('-')) : undefined}
                          onDelete={CAN_BE_REMOVED.includes(element?.element?.[statusFldIdx]) && !readOnly &&
                            !offlineMode && !inUpdate &&
                            !dispatch(
                              checkNetworkItemByBCStatus(
                                element?.id, 'sector', [ BC_STATUS_ID.NEW ], true)) &&
                            deleteOldSector({
                              baseStationId: baseStations[key]?.id,
                              sectorName: element?.name,
                              sectorId: element?.id,
                            })
                          }
                          disabled={element?.disabled}
                        />
                      )
                  )
            })}
          </div>
        ))}
      </div>
      {renderConfirm()}
    </>
  )
}

export default NetworkItemBundleTree
