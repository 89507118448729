import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setBounce } from '../../../../features/geo/geoSlice'
import { SEARCH_BOUNCE } from '../constants'

const SearchMarker = ({ map, bounce }) => {
  const dispatch = useDispatch()
  const [ clearBounce, setClearBounce ] = useState(null)

  // Приховування маркера пошуку після SEARCH_BOUNCE мілісекунд
  useEffect(() => {
    if (bounce && !clearBounce) {
      setClearBounce(
        setTimeout(() => {
          dispatch(setBounce(null))
          setClearBounce(null)
        }, SEARCH_BOUNCE),
      )
    }
  }, [ dispatch, bounce, clearBounce, setClearBounce ])

  // Показ маркера пошуку, позиціонування карти для відображення маркера на екрані
  useEffect(() => {
    if (map && bounce) {
      const bounds = map.getBounds()
      if (!bounds.contains(bounce)) {
        map.panTo(bounce)
      }
    }
  }, [ map, bounce ])

  return null
}

export default SearchMarker
