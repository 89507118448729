import React, { useCallback, useEffect } from 'react'
import { mergeStyles, mergeStyleSets, IconButton } from '@fluentui/react'

const contentStyles = mergeStyleSets({
  bg: {
    inset: 0,
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1001,
    display: 'flex',
  },
  bm: {
    margin: '16px',
    height: 'calc(100% - 32px)',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: '5%',
    marginTop: '5vh',
    marginBottom: '5vh',
    background: 'rgb(255, 255, 255)',
    boxShadow: 'rgb(0 0 0 / 22%) 0 25.6px 57.6px 0, rgb(0 0 0 / 18%) 0 4.8px 14.4px 0',
    borderRadius: '2px',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 25,
    fontWeight: 'bold',
  },
  body: {
    flex: 1,
    height: 'inherit',
  },
})

const iconButtonClass = mergeStyles({
  color: 'rgba(0, 0, 0, 0.5)',
})

const closeIcon = {
  iconName: 'Cancel',
}

export const Modal = ({
  hidden,
  title,
  onClose,
  children,
  width,
  heightAuto,
  marginV,
  closeByEsc = true,
  classNameBM,
  classNameBody,
  ...other
}) => {
  const style = width
    ? {
        width,
        marginLeft: `calc(50vw - ${Math.round(width / 2)}px)`,
      }
    : {}

  if (heightAuto) {
    style.marginTop = marginV || '5vh'
    style.height = `calc(100vh - ${style.marginTop} - ${style.marginTop})`
  }

  const renderTitle = (
    <div style={{ width: '100%' }}>
      {typeof title === 'function' ? title() : title}
    </div>
  )

  const escFunction = useCallback((event) => {
    if (onClose && event.keyCode === 27) {
      onClose()
    }
  }, [ onClose ])

  useEffect(() => {
    if (closeByEsc) {
      document.addEventListener('keydown', escFunction)

      return () => {
        document.removeEventListener('keydown', escFunction)
      }
    }
  }, [ escFunction, closeByEsc ])

  return (
    <div
      className={contentStyles.bg}
      style={{ display: hidden ? 'none' : 'initial' }}
      {...other}
    >
      <div className={contentStyles.container} style={style}>
        <div className={classNameBM ?? contentStyles.bm}>
          {title && (
            <div className={contentStyles.header}>
              {renderTitle}
              {onClose
                ? (<IconButton
                iconProps={closeIcon}
                className={iconButtonClass}
                onClick={onClose}
              />)
                : <></>}
            </div>
          )}
          <div className={classNameBody ?? contentStyles.body}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
