import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Slider, Stack } from '@fluentui/react'
import {
  selectSettings, saveSettings, selectSettingsComplaintsGrouping,
} from '../../../../features/settings/settingsSlice'
import { elementsRedraw } from '../../../../features/network/networkSlice'
import { ID_ICON_SITES, ID_ICON_SECTOR, ID_ICON_COMPLAINT } from '../../../Panels/Customization/constants'

const IconSize = () => {
  const dispatch = useDispatch()

  const sizes = useSelector(selectSettings).sizes
  const complaintsGrouping = useSelector(selectSettingsComplaintsGrouping)

  const onChangeSite = useCallback((value) => {
    dispatch(saveSettings({
      sizes: {
        [ID_ICON_SITES]: value,
      },
    }))
    dispatch(elementsRedraw())
  }, [ dispatch ])

  const onChangeSector = useCallback((value) => {
    dispatch(saveSettings({
      sizes: {
        [ID_ICON_SECTOR]: value,
      },
    }))
    dispatch(elementsRedraw())
  }, [ dispatch ])

  const onChangeComplaint = useCallback((value) => {
    dispatch(saveSettings({
      sizes: {
        [ID_ICON_COMPLAINT]: value,
      },
    }))
    dispatch(elementsRedraw())
  }, [ dispatch ])

  return (
    <Stack className="full-width" style={{ gap: 16 }}>
      <Stack.Item style={{ marginTop: 16 }}>
        <Slider
          label="Site Icon"
          min={1}
          max={10}
          step={1}
          value={sizes[ID_ICON_SITES] ?? 1}
          showValue
          snapToStep
          onChange={onChangeSite}
        />
      </Stack.Item>
      <Stack.Item style={{ marginBottom: complaintsGrouping ? 16 : 0 }}>
        <Slider
          label="Sector Icon"
          min={1}
          max={10}
          step={1}
          value={sizes[ID_ICON_SECTOR] ?? 1}
          showValue
          snapToStep
          onChange={onChangeSector}
        />
      </Stack.Item>
      {!complaintsGrouping && (
        <Stack.Item style={{ marginBottom: 16 }}>
          <Slider
            label="Complaint Icon"
            min={1}
            max={10}
            step={1}
            value={sizes[ID_ICON_COMPLAINT] ?? 4}
            showValue
            snapToStep
            onChange={onChangeComplaint}
          />
        </Stack.Item>
      )}
    </Stack>
  )
}

export default IconSize
