import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { DirectionalHint, StackItem } from '@fluentui/react'
import {
  TOP_INDEX_COVERAGE, COVERAGE_REPORT_KEY,
  checkTreeItem as checkTreeItemNetwork, menuItemClick as menuItemClickNetwork,
  selectNetworkTree, selectSelectedCoverage,
} from '../../../../features/network/networkSlice'
import { selectFocusZones, selectGlobalDrawMode } from '../../../../features/geo/geoSlice'
import ElementsTree from '../ElementsTree'
import { DefaultButton, SecondaryButton } from '../../../common/Button'
import ContextMenu from '../../../common/ContextMenu'
import { RBSIcon, SectorsIcon } from '../../../common/icons/names'
import { useConfirm } from '../../../common/Confirm'

const Coverage = () => {
  const dispatch = useDispatch()
  const { msg, renderConfirm } = useConfirm()

  const networkTree = useSelector(selectNetworkTree, shallowEqual)
  const selected = useSelector(selectSelectedCoverage, shallowEqual)
  const zones = useSelector((state) => selectFocusZones(state.geo), shallowEqual)
  const globalDrawMode = useSelector(selectGlobalDrawMode)

  const elementsShown = useMemo(() => networkTree[TOP_INDEX_COVERAGE].state.selected, [ networkTree ])

  const toggleElements = useCallback(() => {
    dispatch(checkTreeItemNetwork([ TOP_INDEX_COVERAGE ]))
  }, [ dispatch ])

  const onCoverageReport = useCallback(() => {
    dispatch(menuItemClickNetwork([ TOP_INDEX_COVERAGE ], COVERAGE_REPORT_KEY))
    msg({
      messages: [
        'Coverage report is being prepared.',
        'You will receive a notification when it is ready.',
        'Please do not modify Focus Zones while the report is being prepared. They are used in calculation process.',
      ],
    })
  }, [ dispatch, msg ])

  const menuItems = useMemo(() => {
    const txPresent = selected.some(({ tx }) => tx)
    return [
      {
        key: COVERAGE_REPORT_KEY,
        text: selected?.length > 0 ? 'Coverage by Signal level' : 'Coverage by Sectors',
        icon: selected?.length > 0 ? RBSIcon : SectorsIcon,
        onClick: onCoverageReport,
        disabled: !zones?.length || txPresent,
      },
    ]
  }, [ onCoverageReport, selected, zones ])

  return (
    <>
      <ElementsTree topIndex={TOP_INDEX_COVERAGE} />
      <StackItem className="panel-button-container footer">
        <SecondaryButton
          text={elementsShown ? 'Hide All Coverage' : 'Show Coverage'}
          onClick={toggleElements}
          className="full-width"
        />
        <DefaultButton
          menuProps={{
            items: menuItems,
            directionalHint: DirectionalHint.topRightEdge,
          }}
          menuAs={ContextMenu}
          text="Report"
          secondaryText="by signal level"
          disabled={globalDrawMode}
        />
      </StackItem>
      {renderConfirm()}
    </>
  )
}

export default Coverage
