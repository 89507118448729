import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { selectUser } from '../login/loginSlice'
import api from '../../api'

/* export const COLUMN_TIME_FIELDNAME = 'timestamp'

export const logColumns = [
  { key: 'columnTime', name: 'Time', maxWidth: 50, fieldName: COLUMN_TIME_FIELDNAME, isResizable: true },
  { key: 'columnMessage', name: 'Message', fieldName: 'message', isResizable: true },
  { key: 'columnURL', name: 'URL', fieldName: 'url', isResizable: true },
] */

export const STATUS_SUCCESS = 'SUCCESS'
export const STATUS_ERROR = 'ERROR'
export const STATUS_PENDING = 'PENDING'

export const EVENT_TYPE = {
  reportCompleted: 'REPORT_COMPLETED',
  calculatePainZones: 'CALCULATE_PAIN_ZONES',
  importRasterMap: 'IMPORT_RASTER_MAP',
  loadRasterMaps: 'LOAD_RASTER_MAPS',
  deleteRasterMap: 'DELETE_RASTER_MAP',
  makeRasterMapDefault: 'MAKE_RASTER_MAP_DEFAULT',
  importVectorMap: 'IMPORT_VECTOR_MAP',
  loadVectorMaps: 'LOAD_VECTOR_MAPS',
  deleteVectorMap: 'DELETE_VECTOR_MAP',
  makeVectorMapDefault: 'MAKE_VECTOR_MAP_DEFAULT',
  fetchError: 'FETCH_ERROR',
  urlChanged: 'URL_CHANGED',
  accessToDeletedResource: 'ACCESS_TO_DELETED_RESOURCE',
  generateReport: 'GENERATE_REPORT',
  requestAsyncReport: 'REQUEST_ASYNC_REPORT',
  getAsyncReportResult: 'GET_ASYNC_REPORT_RESULT',
  saveBCCalculationDetails: 'SAVE_BC_CALCULATION_DETAILS',
  historicalDataRequest: 'HISTORICAL_DATA_REQUEST',
  historicalDataWarnings: 'HISTORICAL_DATA_WARNINGS',
  historicalDataExport: 'HISTORICAL_DATA_EXPORT',
  compositeIndexStage: 'COMPOSITE_INDEX_STAGE',
  saveCompositeIndex: 'SAVE_COMPOSITE_INDEX',
  compositeIndexActions: 'COMPOSITE_INDEX_ACTIONS',
  exportData: 'EXPORT_DATA',
  importData: 'IMPORT_DATA',
  tableFilter: 'TABLE_FILTER',
  bcActions: 'BC_ACTIONS',
  bcInfo: 'BC_INFO',
  bcImport: 'BC_IMPORT',
  trfActions: 'TRF_ACTIONS',
  trfImport: 'TRF_IMPORT',
  trfSave: 'TRF_SAVE',
  macroActions: 'MACRO_ACTIONS',
  macroSave: 'MACRO_SAVE',
  bcSendForApprove: 'BC_SEND_FOR_APPROVE',
  bcUpdate: 'BC_UPDATE',
  bcAddNewRows: 'BC_ADD_NEW_ROWS',
  bcCreate: 'BC_CREATE',
  bcStartCalculation: 'BC_START_CALCULATION',
  bcSetStatus: 'BC_SET_STATUS',
  bcSyncWithAtoll: 'BC_SYNC_WITH_ATOLL',
  bcSave: 'BC_SAVE',
  bcCreateGroupCase: 'BC_CREATE_GROUP_CASE',
  bcCalculateForApprove: 'BC_CALCULATE_FOR_APPROVE',
  filtration: 'FILTRATION',
  zoneActions: 'ZONE_ACTIONS',
  autoSaveMode: 'AUTO_SAVE_MODE',
  login: 'LOGIN',
  logout: 'LOGOUT',
  changePassword: 'CHANGE_PASSWORD',
  targetCoverage: 'TARGET_COVERAGE',
  projects: 'PROJECTS',
  synchronization: 'SYNCHRONIZATION',
  updateDB: 'UPDATE_DB',
  blockUser: 'BLOCK_USER',
  unblockUser: 'UNBLOCK_USER',
  eventLog: 'EVENT_LOG',
  eventLogExport: 'EVENT_LOG_EXPORT',
  taskLogExport: 'TASK_LOG_EXPORT',
  polygonExport: 'POLYGON_EXPORT',
  compositeIndexExport: 'COMPOSITE_INDEX_EXPORT',
  updateDBExport: 'UPDATE_DB_EXPORT',
  updateProjectFromAtoll: 'UPDATE_PROJECT_FROM_ATOLL',
  vectorMapExport: 'VECTOR_MAP_EXPORT',
  openToolWindow: 'OPEN_TOOL_WINDOW',
  mapRuler: 'MAP_RULER',
  exportSettlements: 'EXPORT_SETTLEMENTS',
  importSettlements: 'IMPORT_SETTLEMENTS',
}

export const MESSAGES = {
  [EVENT_TYPE.reportCompleted]: 'Report calculation completed',
  [EVENT_TYPE.calculatePainZones]: 'Calculate "Pain zones"',
  [EVENT_TYPE.importRasterMap]: 'Import Raster map',
  [EVENT_TYPE.loadRasterMaps]: 'Load Raster maps',
  [EVENT_TYPE.deleteRasterMap]: 'Delete Raster map',
  [EVENT_TYPE.makeRasterMapDefault]: 'Make Raster map default',
  [EVENT_TYPE.importVectorMap]: 'Import Vector map',
  [EVENT_TYPE.loadVectorMaps]: 'Load Vector maps',
  [EVENT_TYPE.deleteVectorMap]: 'Delete Vector map',
  [EVENT_TYPE.makeVectorMapDefault]: 'Make Vector map default',
  [EVENT_TYPE.fetchError]: 'Fetch error',
  [EVENT_TYPE.urlChanged]: 'URL changed',
  [EVENT_TYPE.accessToDeletedResource]: 'Access to deleted resource',
  [EVENT_TYPE.generateReport]: 'Generate report',
  [EVENT_TYPE.requestAsyncReport]: 'Request async report',
  [EVENT_TYPE.getAsyncReportResult]: 'Get async report result',
  [EVENT_TYPE.saveBCCalculationDetails]: 'Save BC calculation details',
  [EVENT_TYPE.historicalDataRequest]: 'Historical data request',
  [EVENT_TYPE.historicalDataWarnings]: 'Historical data warnings',
  [EVENT_TYPE.historicalDataExport]: 'Historical data export',
  [EVENT_TYPE.compositeIndexStage]: 'Composite index stage',
  [EVENT_TYPE.saveCompositeIndex]: 'Save composite index',
  [EVENT_TYPE.compositeIndexActions]: 'Composite index actions',
  [EVENT_TYPE.exportData]: 'Export data',
  [EVENT_TYPE.importData]: 'Import data',
  [EVENT_TYPE.tableFilter]: 'Table filter',
  [EVENT_TYPE.bcActions]: 'BC actions',
  [EVENT_TYPE.bcInfo]: 'BC info',
  [EVENT_TYPE.bcImport]: 'BC import',
  [EVENT_TYPE.trfActions]: 'TRF actions',
  [EVENT_TYPE.trfImport]: 'TRF import',
  [EVENT_TYPE.trfSave]: 'TRF save',
  [EVENT_TYPE.macroActions]: 'Macro Indicators actions',
  [EVENT_TYPE.macroSave]: 'Macro Indicators save',
  [EVENT_TYPE.bcSendForApprove]: 'BC send for approve',
  [EVENT_TYPE.bcUpdate]: 'BC update',
  [EVENT_TYPE.bcAddNewRows]: 'BC add new rows',
  [EVENT_TYPE.bcCreate]: 'BC create',
  [EVENT_TYPE.bcStartCalculation]: 'BC start calculation',
  [EVENT_TYPE.bcSetStatus]: 'BC set status',
  [EVENT_TYPE.bcSyncWithAtoll]: 'BC start synchronize with Atoll',
  [EVENT_TYPE.bcSave]: 'BC save',
  [EVENT_TYPE.bcCreateGroupCase]: 'BC create group case',
  [EVENT_TYPE.bcCalculateForApprove]: 'BC calculate for approve',
  [EVENT_TYPE.filtration]: 'Filtration',
  [EVENT_TYPE.zoneActions]: 'Zone actions',
  [EVENT_TYPE.autoSaveMode]: 'Auto save mode',
  [EVENT_TYPE.login]: 'Login',
  [EVENT_TYPE.logout]: 'Logout',
  [EVENT_TYPE.changePassword]: 'Change password',
  [EVENT_TYPE.targetCoverage]: 'Target coverage',
  [EVENT_TYPE.projects]: 'Projects',
  [EVENT_TYPE.synchronization]: 'Synchronization',
  [EVENT_TYPE.updateDB]: 'Update DB',
  [EVENT_TYPE.blockUser]: 'Block users',
  [EVENT_TYPE.unblockUser]: 'Unblock users',
  [EVENT_TYPE.eventLog]: 'Event log',
  [EVENT_TYPE.eventLogExport]: 'Event log export',
  [EVENT_TYPE.taskLogExport]: 'Task log export',
  [EVENT_TYPE.polygonExport]: 'Polygon export',
  [EVENT_TYPE.compositeIndexExport]: 'Composite index export',
  [EVENT_TYPE.updateDBExport]: 'Update DB export',
  [EVENT_TYPE.updateProjectFromAtoll]: 'Update project from Atoll',
  [EVENT_TYPE.vectorMapExport]: 'Vector map export',
  [EVENT_TYPE.openToolWindow]: 'Open tool window',
  [EVENT_TYPE.mapRuler]: 'Map ruler',
}

// console.log(Object.values(MESSAGES).sort())

const initialState = {
  name: 'eventLog',
  loading: false,
  eventLog: null,
}

const doGetEventLog = createAsyncThunk(
  'EventLog/getEventLog',
  api.eventLog.getEventLog,
)

const doAddEventLog = createAsyncThunk(
  'EventLog/addEventLog',
  api.eventLog.logEvent,
)

export const taskLogSlice = createSlice({
  name: 'eventLog',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    clearEventLog: (state) => {
      state.eventLog = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doGetEventLog.pending, (state) => {
        state.loading = true
      })
      .addCase(doGetEventLog.fulfilled, (state, action) => {
        state.eventLog = action.payload
        state.loading = false
      })
      .addCase(doGetEventLog.rejected, (state) => {
        state.loading = false
      })
  },
})

export const {
  clearEventLog,
} = taskLogSlice.actions

export const selectLoading = (state) => state.eventLog.loading
export const selectEventLog = (state) => state.eventLog.eventLog

export const getEventLog = (userId, from, to, address, type, status) => (dispatch) =>
  dispatch(wrapByEventLog({
    type: EVENT_TYPE.eventLog,
    details: `User = ${userId}; From = ${from}; To = ${to}; IP = ${address}; Type = ${type}; Status = ${status}`,
    action: () => dispatch(doGetEventLog({ userId, from, to, address, type, status })),
    extractor: (result) => {
      const lines = (result?.payload || '').split('\n').length
      return lines ? `${lines} lines` : 'Empty result'
    },
  }))

export const addEventLog = (url, message, type, status = STATUS_SUCCESS) => (dispatch, getState) => {
  const currentState = getState()
  const user = selectUser(currentState)
  return dispatch(doAddEventLog({ userId: user?.id, url, message, type, status }))
}

export const saveEventLog = (type, suffix, status) =>
  addEventLog(window.location.pathname, `${MESSAGES[type]}${suffix || ''}`, type, status)

export const errorEventLog = (type, error, suffix) =>
  saveEventLog(type, `${suffix || ''}\nERROR: ${error.message}`, STATUS_ERROR)

export const wrapByEventLog = ({ message, details, type, action, extractor, waitCursor }) => async (dispatch) => {
  if (!message) {
    message = MESSAGES[type]
  }
  const url = window.location.pathname
  await dispatch(addEventLog(url, `${message}${details ? ` [${details}]` : ''}`, type, STATUS_PENDING))
  if (waitCursor) {
    document.body.classList.add('waiting')
  }
  try {
    const result = await action()
    await dispatch(addEventLog(url, `${message}${extractor ? `\nRESULT: ${extractor(result)}` : ''}`, type, STATUS_SUCCESS))
    return result
  } catch (error) {
    console.log(error)
    await dispatch(addEventLog(url, `${message}\nERROR: ${error.message}`, type, STATUS_ERROR))
    throw error
  } finally {
    if (waitCursor) {
      document.body.classList.remove('waiting')
    }
  }
}

export const invokeAction = (dispatch, action, ...args) => async () => {
  const { payload, error } = await dispatch(action(...args))
  if (error) {
    throw new Error(error.message)
  }
  return payload
}

export default taskLogSlice.reducer
