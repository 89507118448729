import { useCallback, useEffect, useState } from 'react'
import L from 'leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { selectRulerVisible, selectEditZone } from '../../../../features/geo/geoSlice'
import { selectSettings } from '../../../../features/settings/settingsSlice'
import { EVENT_TYPE, saveEventLog } from '../../../../features/eventLog/eventLogSlice'

const POLYLINE_MEASURE_POINT_SIZE = 7
const POLYLINE_MEASURE_LINE_COLOR = '#333743'
const POLYLINE_MEASURE_LINE_WEIGHT = 2
const POLYLINE_MEASURE_CONFIG = {
  position: 'topleft',
  unit: 'kilometres',
  showBearings: false,
  clearMeasurementsOnStop: false,
  showClearControl: true,
  measureControlLabel: '&#x1f4cf;',
  measureControlTitleOn: 'Enable the ruler',
  measureControlTitleOff: 'Disable the ruler',
  clearControlTitle: 'Clear measurements',
  tooltipTextMove: '<b>The ruler must be enabled for editing</b><br>Click and drag to <b>move point</b><br>',
  tempLine: {
    color: '#42A4FF',
    weight: POLYLINE_MEASURE_LINE_WEIGHT,
  },
  fixedLine: {
    color: POLYLINE_MEASURE_LINE_COLOR,
    weight: POLYLINE_MEASURE_LINE_WEIGHT,
  },
  startCircle: {
    radius: POLYLINE_MEASURE_POINT_SIZE,
    color: POLYLINE_MEASURE_LINE_COLOR,
    weight: POLYLINE_MEASURE_LINE_WEIGHT,
    fillColor: '#7F3DE7',
    fillOpacity: 1,
  },
  currentCircle: {
    radius: POLYLINE_MEASURE_POINT_SIZE,
    color: '#42A4FF',
    weight: POLYLINE_MEASURE_LINE_WEIGHT,
    fillColor: '#42A4FF',
  },
  intermedCircle: {
    radius: POLYLINE_MEASURE_POINT_SIZE,
    color: POLYLINE_MEASURE_LINE_COLOR,
    weight: POLYLINE_MEASURE_LINE_WEIGHT,
    fillColor: '#CC25B1',
    fillOpacity: 1,
  },
  endCircle: {
    radius: POLYLINE_MEASURE_POINT_SIZE,
    color: POLYLINE_MEASURE_LINE_COLOR,
    weight: POLYLINE_MEASURE_LINE_WEIGHT,
    fillColor: '#D63B3B',
    fillOpacity: 1,
  },
}

const Ruler = ({ map }) => {
  const dispatch = useDispatch()

  const [ ruler, setRuler ] = useState(null)
  const [ scale, setScale ] = useState(null)

  const rulerVisible = useSelector(selectRulerVisible)
  const editZone = useSelector(selectEditZone)
  const rulerSettings = useSelector(selectSettings)?.ruler

  const showRuler = useCallback(() => {
    if (map) {
      if (ruler) {
        ruler.remove()
      }
      if (scale) {
        scale.remove()
      }
      const polylineMeasure = L.control.polylineMeasure(POLYLINE_MEASURE_CONFIG)
      polylineMeasure.addTo(map)
      polylineMeasure._toggleMeasure()
      const scaleComponent = L.control.scale()
      scaleComponent.addTo(map)
      setRuler(polylineMeasure)
      setScale(scaleComponent)
    }
  }, [ map, ruler, scale ])

  const hideRuler = useCallback(() => {
    if (ruler) {
      ruler._clearAllMeasurements()
      ruler.remove()
      setRuler(null)
    }
    if (scale) {
      scale.remove()
      setScale(null)
    }
  }, [ ruler, scale ])

  useEffect(() => {
    if (rulerVisible && !ruler) {
      showRuler()
      dispatch(saveEventLog(EVENT_TYPE.mapRuler, ': show ruler'))
    } else if (!rulerVisible && ruler) {
      hideRuler()
      dispatch(saveEventLog(EVENT_TYPE.mapRuler, ': hide ruler'))
    }
  }, [ dispatch, rulerVisible, showRuler, hideRuler, ruler ])

  useEffect(() => {
    if (!editZone && ruler?._layerPaint?._layers && Object.keys(ruler?._layerPaint?._layers).length > 0) {
      setTimeout(() => {
        if (ruler?._layerPaint?._layers && Object.keys(ruler?._layerPaint?._layers).length > 0) {
          Object.values(ruler?._layerPaint?._layers).forEach((point) => {
            if (point.bringToFront) {
              point.bringToFront()
            }
          })
        }
      }, 500)
    }
    if (rulerVisible && ruler?._measuring && editZone) {
      ruler._toggleMeasure()
    }
  }, [ ruler, rulerVisible, editZone ])

  useEffect(() => {
    const r = document.querySelector(':root')
    r.style.setProperty('--ruler-text-size', `${rulerSettings?.textSize}px`)
    r.style.setProperty('--ruler-label-opacity', rulerSettings?.labelOpacity)
    r.style.setProperty('--ruler-intermediate-tooltip-display',
      rulerSettings?.hideIntermediateMeasures ? 'none' : 'inherit')
  }, [ rulerSettings ])

  useEffect(() => {
    let timestamp = null
    const onRemove = (e) => {
      timestamp = new Date().getTime()
      e.originalEvent.preventDefault()
    }

    const onStart = () => {
      if (ruler) {
        const currentTime = new Date().getTime()
        if (timestamp && currentTime - timestamp < 1500) {
          setTimeout(() => {
            if (ruler && ruler._currentLine) {
              // Restart the ruler to cancel the new measurement immediately after deleting the point with Shift
              ruler._toggleMeasure()
              ruler._toggleMeasure()
            }
          }, 100)
        }
        timestamp = null
      }
    }

    if (map) {
      map.on('polylinemeasure:remove', onRemove)
      map.on('polylinemeasure:start', onStart)
    }

    return () => {
      if (map) {
        map.off('polylinemeasure:remove', onRemove)
        map.off('polylinemeasure:start', onStart)
      }
    }
  }, [ map, ruler ])

  return null
}

export default Ruler
