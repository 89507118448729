import React from 'react'
import { Pivot, PivotItem, Spinner, SpinnerSize } from '@fluentui/react'
import Modal from '../common/Modal'
import Clear from './Clear/Container'
import Simultaneous from './Simultaneous/Container'
import Conflict from './Conflict/Container'
import UpdateBC from './UpdateBC/Container'

const Component = ({
  onClose,
  title,
  busy,
  dialogConfirm,
  projectId,
  itemsClear,
  itemsSimultaneous,
  itemsConflict,
  itemsUpdateBC,
  columnsClear,
  columnsConflict,
  columnsSimultaneous,
  columnsUpdateBC,
  onUpdateItem,
  onUpdateHeader,
  onUpdateHeaderBC,
  onClickApplyUpdate,
}) => {
  return (
    <Modal
      title={title}
      onClose={onClose}
      heightAuto
    >
      <Pivot className="full-height">
        <PivotItem alwaysRender headerText="Clear Update" className="full-height">
          <Clear
            onCancel={onClose}
            items={itemsClear}
            projectId={projectId}
            columns={columnsClear}
            onUpdateItem={onUpdateItem}
            changeCheckBoxHeader={onUpdateHeader}
            clickApplyUpdate={onClickApplyUpdate}
            changeSorting={null}
          />
        </PivotItem>
        <PivotItem alwaysRender headerText="Simultaneous Update" className="full-height">
          <Simultaneous
            onCancel={onClose}
            items={itemsSimultaneous}
            projectId={projectId}
            columns={columnsSimultaneous}
            onUpdateItem={onUpdateItem}
            changeCheckBoxHeader={onUpdateHeader}
            clickApplyUpdate={onClickApplyUpdate}
            changeSorting={null}
          />
        </PivotItem>
        <PivotItem alwaysRender headerText="Conflict Update" className="full-height">
          <Conflict
            onCancel={onClose}
            items={itemsConflict}
            projectId={projectId}
            columns={columnsConflict}
            onUpdateItem={onUpdateItem}
            changeCheckBoxHeader={onUpdateHeader}
            clickApplyUpdate={onClickApplyUpdate}
            changeSorting={null}
          />
        </PivotItem>
        <PivotItem alwaysRender headerText="Update BC" className="full-height">
          <UpdateBC
            onCancel={onClose}
            items={itemsUpdateBC}
            projectId={projectId}
            columns={columnsUpdateBC}
            onUpdateItem={onUpdateItem}
            changeCheckBoxHeader={onUpdateHeaderBC}
            clickApplyUpdate={onClickApplyUpdate}
            changeSorting={null}
          />
        </PivotItem>
      </Pivot>
      {busy && (
        <Spinner
          className="centered"
          style={{ backgroundColor: '#50505050', width: '100%', height: '100%' }}
          label={'Loading data'}
          size={SpinnerSize.large}
        />
      )}
      {dialogConfirm()}
    </Modal>
  )
}

export default Component
