import React, { useCallback } from 'react'
import { Stack } from '@fluentui/react'
import TextField from '../../../common/TextField'
import { DefaultButton } from '../../../common/Button'
import { SPREAD_RANGE } from '../../../../constants/settings'
import { toNumber } from '../utils'
import TextFieldColor from './TextFieldColor'

import './ColorValues.css'

const ColorValues = ({ mode, coloring, onChange, onAutoClick }) => {
  const onChangeStartValue = useCallback((event) => {
    const value = toNumber(event.target.value)
    if (value !== coloring?.start?.value) {
      onChange({ start: { value } })
    }
  }, [ onChange, coloring?.start?.value ])

  const onChangeStartColor = useCallback((id, color) => {
    if (color !== coloring?.start?.color) {
      onChange({ start: { color } })
    }
  }, [ onChange, coloring?.start?.color ])

  const onChangeEndValue = useCallback((event) => {
    const value = toNumber(event.target.value)
    if (value !== coloring?.end?.value) {
      onChange({ end: { value } })
    }
  }, [ onChange, coloring?.end?.value ])

  const onChangeEndColor = useCallback((id, color) => {
    if (color !== coloring?.end?.color) {
      onChange({ end: { color } })
    }
  }, [ onChange, coloring?.end?.color ])

  const onChangeStep = useCallback((event) => {
    const step = toNumber(event.target.value)
    if (step !== coloring?.step) {
      onChange({ step })
    }
  }, [ onChange, coloring?.step ])

  return (
    <>
      <TextFieldColor
        label="Top Value"
        defaultValue={coloring?.end?.value}
        defaultColor={coloring?.end?.color}
        onBlur={onChangeEndValue}
        onChangeColor={onChangeEndColor}
        disabled={mode !== SPREAD_RANGE}
      />
      <div className="capex-small-top-margin" >&nbsp;</div>
      <TextFieldColor
        label="Bottom Value"
        defaultValue={coloring?.start?.value}
        defaultColor={coloring?.start?.color}
        onBlur={onChangeStartValue}
        onChangeColor={onChangeStartColor}
        disabled={mode !== SPREAD_RANGE}
      />
      <Stack horizontal style={{ gap: 24 }}>
        <TextField
          label="Step"
          className="half-width right-aligned-text"
          defaultValue={coloring?.step}
          onBlur={onChangeStep}
          disabled={mode !== SPREAD_RANGE}
        />
        <DefaultButton
          text="Set Auto Value"
          className="half-width capex-top-margin-button"
          onClick={onAutoClick}
          disabled={mode !== SPREAD_RANGE}
        />
      </Stack>
    </>
  )
}

export default ColorValues
