import React, { useCallback, useMemo, useRef } from 'react'
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@fluentui/react-accordion'

import './PanelSections.css'

const SectionStack = ({ children }) => (
  <div className="capex-section-stack">
    {children}
  </div>
)

const Section = ({ children }) => (
  <div className="capex-section">
    {children}
  </div>
)

const SectionHeader = ({ children, suffix }) => (
  <div className="capex-section-header">
    <div className="capex-section-header-title">
      {children}
    </div>
    <div className="capex-section-header-suffix">
      {suffix}
    </div>
  </div>
)

const SectionPanel = ({ children }) => (
  <div className="capex-section-panel">
    {children}
  </div>
)

const PanelSections = ({ id, items, collapsible = true }) => {
  const ref = useRef()

  const visibleItems = useMemo(() => items.filter(({ hidden }) => !hidden), [ items ])

  const onToggle = useCallback(() => {
    if (id) {
      setTimeout(() => {
        const children = ref.current.children
        if (children.length === visibleItems.length) {
          const openItems = []
          for (let i = 0; i < children.length; i++) {
            const child = children[i]
            const item = visibleItems[i]
            if (child.children.length === 2) {
              openItems.push(item.id)
            }
          }
          localStorage.setItem(`accordion-${id}`, JSON.stringify(openItems))
        }
      })
    }
  }, [ ref, visibleItems, id ])

  const defaultOpenItems = useMemo(() => {
    let result = visibleItems.map(({ id }) => id)
    if (id) {
      const saved = localStorage.getItem(`accordion-${id}`)
      if (saved) {
        try {
          const parsed = JSON.parse(saved)
          if (Array.isArray(parsed)) {
            result = parsed
          }
        } catch (e) {
          console.warn(e)
        }
      }
    }
    return result
  }, [ id, visibleItems ])

  return collapsible
    ? (
        <Accordion ref={ref} collapsible multiple defaultOpenItems={defaultOpenItems} onToggle={onToggle}>
          {visibleItems.map(({ id, title, Component, suffix }) => (
            <AccordionItem value={id} key={id}>
              <AccordionHeader expandIconPosition="end">
                <div className="flex-uniform">
                  {title}
                </div>
                {suffix && (
                  <div className="capex-section-header-suffix">
                    {suffix}
                  </div>
                )}
              </AccordionHeader>
              <AccordionPanel className="accordion-panel">
                <Component />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )
    : (
        <SectionStack>
          {visibleItems.map(({ id, title, Component, suffix }) => (
            <Section key={id}>
              <SectionHeader suffix={suffix}>
                {title}
              </SectionHeader>
              <SectionPanel>
                <Component />
              </SectionPanel>
            </Section>
          ))}
        </SectionStack>
      )
}

export default PanelSections
