import { ID_GEO_SUBSTANCES } from '../constants/geo'
import { polygonToGeoJSON, geoJSONToPolygon } from '../utils/convert'
import { fetch } from './rest'

export const getSubstances = () =>
  fetch('/conf/substances.json', 'GET', undefined, 'json', true, window.location.origin)
    .then((substances) => substances.map((item, index) => ({
      ...item,
      id: `${ID_GEO_SUBSTANCES}-${index}`,
    })))

export const geocode = (query, key) =>
  global.fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(query)}&key=${key}`)
    .then((res) => {
      const { ok, status } = res
      if (!ok || status !== 200) {
        return null
      }
      return res.json()
    })
    .catch((error) => {
      console.error(error.stack)
      throw error
    })

export const loadZone = ({ projectId, zoneType }) =>
  fetch(`/api/project/${projectId}/${zoneType}`)
    .then(geoJSONToPolygon)

export const saveZone = ({ projectId, zoneType, zoneList }) =>
  fetch(`/api/project/${projectId}/${zoneType}`, 'PUT', polygonToGeoJSON(zoneList))

export const deleteZone = ({ projectId, zoneType }) =>
  fetch(`/api/project/${projectId}/${zoneType}`, 'DELETE')

export const deleteZoneItem = ({ projectId, zoneType, zoneId }) =>
  fetch(`/api/project/${projectId}/${zoneType}/item/${zoneId}`, 'DELETE')
