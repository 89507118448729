import { useEffect } from 'react'
import L from 'leaflet'
import { shallowEqual, useSelector } from 'react-redux'
import { determineColor, determineLabel } from '../../render'
import { drawComplaint } from '../../draw'
import {
  selectComplaintFields, selectComplaintFldIdx, selectComplaintsRedraw, selectFilteredComplaints,
  selectNetworkLayersVisibility,
} from '../../../../features/network/networkSlice'
import { selectSettingsDisplay, selectSettingsSizes } from '../../../../features/settings/settingsSlice'
import { ID_ICON_COMPLAINT } from '../../../Panels/Customization/constants'

const ComplaintsCanvas = ({ shown, map }) => {
  const complaints = useSelector(selectFilteredComplaints)
  const complaintFields = useSelector(selectComplaintFields)
  const [ idIdx,, lngIdx, latIdx, techIdx, typePIdx ] = useSelector(selectComplaintFldIdx, shallowEqual)
  const complaintsRedraw = useSelector(selectComplaintsRedraw)
  const networkLayersVisibility = useSelector(selectNetworkLayersVisibility, shallowEqual)
  const { complaintsVisibility } = (networkLayersVisibility || {})
  const { complaints: complaintsSettings } = useSelector(selectSettingsDisplay) || {}
  const { [ID_ICON_COMPLAINT]: ratio } = useSelector(selectSettingsSizes) || {}

  useEffect(() => {
    map._complaints = new L.MarkersCanvas({ type: 'complaint' })
    map._complaints.addTo(map)
    return () => {
      map.removeLayer(map._complaints)
      delete map._complaints
    }
  }, [ map ])

  // Ініціалізація рендеру скарг
  useEffect(() => {
    if (
      map && shown && map._complaints && map._complaints._version !== complaintsRedraw
    ) {
      map._complaints.clear()
      let complaintColorAttributeIdx, complaintLabelAttributeIdx
      if (complaintsSettings) {
        complaintColorAttributeIdx = complaintFields
          .findIndex(({ id }) => id === complaintsSettings?.attribute)
        complaintLabelAttributeIdx = complaintFields
          .findIndex(({ id }) => id === complaintsSettings?.labeling?.attribute)
      }
      const markers = complaints
        .map((item) => L.marker([ item[latIdx], item[lngIdx] ], {
          id: item[idIdx],
          type: 'complaint',
          typeP: item[typePIdx],
          tech: item[techIdx],
          draw: drawComplaint,
          ratio,
          color: complaintsSettings
            ? determineColor(item, complaintsSettings, complaintColorAttributeIdx, idIdx)
            : null,
          label: complaintsSettings
            ? determineLabel(item, complaintsSettings.labeling, complaintLabelAttributeIdx)
            : null,
          icon: {
            options: {
              iconSize: [ 16, 16 ],
              iconAnchor: [ 8, 8 ],
            },
          },
        }))
      map._complaints._map = map
      map._complaints.addMarkers(markers)
      map._complaints._version = complaintsRedraw
      map.fire('resize')
    }
  }, [
    map, complaints, idIdx, latIdx, lngIdx, techIdx, typePIdx, complaintsRedraw, complaintsSettings, complaintFields,
    ratio, shown,
  ])

  // Перемикання видимості скарг
  useEffect(() => {
    if (map) {
      map._complaints?.setVisible(complaintsVisibility)
    }
  }, [ map, complaintsVisibility, complaints ])

  return null
}

export default ComplaintsCanvas
