import React, { useRef, useEffect, useCallback, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button'
import { useBoolean } from '@fluentui/react-hooks'
import { DialogFooter } from '@fluentui/react/lib/Dialog'
import { useConfirm } from '../common/Confirm'
import ShareProjectForm from '../Cabinet/Form/ShareProjectForm'
import { KEYS } from '../Cabinet/constants'
import { getColumnIndexById } from '../Cabinet/Project/settings'
import {
  loadBusinessCasesForGC,
  loadBusinessCases,
  clearCreateBCData,
  addBusinessCases,
  createNewGroupCase,
  createNewBCs,
  saveBusinessCases,
  deleteGC,
  deleteBusinessCases,
  approveDraft,
  selectLoading,
  selectCreateLoading,
  selectSynchronizing,
  selectGCNames,
  selectBCNames,
  prepareSiteNames,
  selectSiteNames,
  selectBCFull,
  selectNeedToExportFields,
  selectCreateBCModifiedValues,
  editCreateBCField,
  clearModified,
  clearExistingBCNames,
  calculateDraft,
  recalculateDraft,
  calculateForApprove,
  cancelCalculation,
  doCreateBusinessCases,
  selectCreateBCFields,
  selectCreateBCFull,
  selectCreateBCList,
  updateCalculateForApprove,
  selectBCFields,
  selectCreateBCRedraw,
  resetFilters,
  showEditNeighbors,
  selectEditNeighborsForBC,
  selectIsBCFieldEditable,
} from '../../features/bc/bcSlice'
import {
  BC_SELECTED_FIELD,
  BC_NAME_FIELD,
  BC_ID_FIELD,
  BC_EX_STATUS_FIELD,
  BC_GROUP_ID_FIELD,
  BC_SITE_FIELD,
  EXISTING_STATUS_ID,
  BC_SITE_ID_FIELD,
  BC_STATUS_ID_FIELD,
  BC_CALCULATED_ID_FIELD,
  BC_CALC_METHOD_FIELD,
  BC_STATUS_ID,
  BC_DATE_CAPEX_FIELD,
  BC_DATE_REVENUE_FIELD,
  BC_NEIGHBORS_FIELD,
  BC_COMMENTS_FIELD,
  BC_SECTOR_IDS_FIELD,
  BC_SECTORS_FIELD,
  BC_CASE_TYPE_FIELD,
  REQUIRED_STATUS_FOR_CALCULATE_DRAFT,
  REQUIRED_STATUS_FOR_RECALCULATE_DRAFT,
  REQUIRED_STATUS_FOR_CALCULATE,
  REQUIRED_STATUS_FOR_APPROVE_DRAFT,
  REQUIRED_STATUS_FOR_CANCEL_CALCULATION,
  REQUIRED_STATUS_FOR_DISABLE_DELETE,
  REQUIRED_STATUS_FOR_DISABLE_ANY_CHANGES,
  CHANGE_STATUS,
  CALC_METHOD_NEW_SECTORS,
  CASE_TYPE_B2B,
  CASE_TYPE_B2C,
} from '../../features/bc/constants'
import {
  selectSectorsFull, selectSectorFields, selectSitesFull, selectSiteFields,
} from '../../features/network/networkSlice'
import { selectUserAccess } from '../../features/login/loginSlice'
import { activeProjectId, getGrantsToProject, grantAccessToProjects } from '../../features/projects/projectsSlice'
import { EVENT_TYPE, wrapByEventLog, saveEventLog, STATUS_ERROR } from '../../features/eventLog/eventLogSlice'
import { findIndex } from '../../features/network/indexing'
import {
  updateFromAtoll, JOB_ACTIVE_STATUS, STATUS_JOB,
} from '../../features/updateProject/update'
import { empty } from '../../utils/format'
import { DATE_FORMAT, initColumnBC } from '../Tables/utils'
import { BUSINESS_CASES } from '../../constants/access'
import { DATA_TYPES } from '../../constants/common'
import { SITE_STATUS_FIELD, STATUS_ACTIVE } from '../../constants/network'
import { saveSettings } from '../../features/settings/settingsSlice'
import HeaderContext from '../../layout/context/HeaderContext'
import { addTask } from '../../features/taskLog/taskLogSlice'
import { TASK_TYPES } from '../../features/taskLog'

import { rendererItem } from '../common/utils/hotTable'
import BCTable, { getMarkedCellKey } from './CreateBCTable'
import {
  askDataWillBeLost,
  confirmNotAllSelected,
  askSaveBC,
  askSitesErrors,
  askNotFieldsReady,
  askShowStatistics,
  errorMessage,
  notUniqueMessage,
  isBusinessCaseEligibleForCalculation,
} from './utils'
import CalculateConfirmationGrid from './CalculateConfirmationGrid'
import CreateBCHeader from './CreateBCHeader'

import './CreateBusinessCase.css'
import EditSectors from './EditSectors'

export const convertValue = (value, type) => {
  if (type === 'double') {
    return parseFloat(value)
  } else if (type === 'int') {
    return parseInt(value)
  }
  return value
}

export const BCCalculation = ({
  hidden, hideDialog, project, onBusinessCasesUpdated, onImport, setCreateBusinessCaseActions,
}) => {
  const { renderConfirm, confirm, ask, msg } = useConfirm()

  const dispatch = useDispatch()
  const history = useHistory()

  const { vectorMapId: selectedGroupCase } = useParams()

  const projectId = useSelector(activeProjectId)

  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const bcLoading = useSelector(selectLoading)
  const createLoading = useSelector(selectCreateLoading)
  const loading = useMemo(() => bcLoading || createLoading, [ bcLoading, createLoading ])
  const gcNames = useSelector(selectGCNames)
  const bcNames = useSelector(selectBCNames)
  const siteNames = useSelector(selectSiteNames)
  const needToExport = useSelector(selectNeedToExportFields)
  const allFull = useSelector(selectBCFull)
  const allFields = useSelector(selectBCFields)
  const fields = useSelector(selectCreateBCFields)
  const full = useSelector(selectCreateBCFull)
  const data = useSelector(selectCreateBCList)
  const sectorsData = useSelector(selectSectorsFull)
  const sectorFields = useSelector(selectSectorFields)
  const sitesData = useSelector(selectSitesFull)
  const siteFields = useSelector(selectSiteFields)
  const modifiedValues = useSelector(selectCreateBCModifiedValues, shallowEqual)
  const redraw = useSelector(selectCreateBCRedraw)
  const editNeighborsForBC = useSelector(selectEditNeighborsForBC)

  const isBCFieldEditableFunction = useSelector(selectIsBCFieldEditable)
  const isBCFieldEditable = useMemo(() => {
    return isBCFieldEditableFunction(fields, data?.getList(), 'createBC')
  }, [ fields, data, isBCFieldEditableFunction ])

  const gridRef = useRef()

  const bcIdColIndex = useMemo(() => findIndex(fields, BC_ID_FIELD), [ fields ])
  const fullBcIdColIndex = useMemo(() => findIndex(allFields, BC_ID_FIELD), [ allFields ])
  const bcNameColIndex = useMemo(() => findIndex(fields, BC_NAME_FIELD), [ fields ])
  const siteIdColIndex = useMemo(() => findIndex(fields, BC_SITE_ID_FIELD), [ fields ])
  const siteNameColIndex = useMemo(() => findIndex(fields, BC_SITE_FIELD), [ fields ])
  const exStatusColIndex = useMemo(() => findIndex(fields, BC_EX_STATUS_FIELD), [ fields ])
  const selectedColIndex = useMemo(() => findIndex(fields, BC_SELECTED_FIELD), [ fields ])
  const statusColIndex = useMemo(() => findIndex(fields, BC_STATUS_ID_FIELD), [ fields ])
  const caseTypeColIndex = useMemo(() => findIndex(fields, BC_CASE_TYPE_FIELD), [ fields ])
  const calcMethodColIndex = useMemo(() => findIndex(fields, BC_CALC_METHOD_FIELD), [ fields ])
  const sectorIdsColIndex = useMemo(() => findIndex(fields, BC_SECTOR_IDS_FIELD), [ fields ])
  const sectorNamesColIndex = useMemo(() => findIndex(fields, BC_SECTORS_FIELD), [ fields ])
  const calculatedColIndex = useMemo(() => findIndex(fields, BC_CALCULATED_ID_FIELD), [ fields ])
  const startCapexDateColIndex = useMemo(() => findIndex(fields, BC_DATE_CAPEX_FIELD), [ fields ])
  const revenueDateColIndex = useMemo(() => findIndex(fields, BC_DATE_REVENUE_FIELD), [ fields ])
  const neighborsColIndex = useMemo(() => findIndex(fields, BC_NEIGHBORS_FIELD), [ fields ])

  const siteStatusColIndex = useMemo(() => findIndex(siteFields, SITE_STATUS_FIELD), [ siteFields ])

  const selectedProject = useMemo(() => {
    if (project) {
      return [
        [
          project[KEYS.ID],
          0,
          0,
          project[KEYS.DESCRIPTION],
        ],
      ]
    }
    return []
  }, [ project ])

  const [ groupStatus, setGroupStatus ] = useState(null)
  const [ options, setOptions ] = useState([])
  const [ selectedGCKey, setSelectedGCKey ] = useState(null)
  const [ loadedGCKey, setLoadedGCKey ] = useState(null)
  const [ bcOptions, setBCOptions ] = useState([])
  const [ siteOptions, setSiteOptions ] = useState([])
  const [ isShareOpen, { setTrue: showShare, setFalse: hideShare } ] = useBoolean(false)
  const [ gcNameUpdated, setGCNameUpdated ] = useState(false)
  const [ sectorsForEdit, setSectorsForEdit ] = useState(null)
  const [ isShowEditSectors, { setTrue: showEditSectors, setFalse: hideEditSectors } ] = useBoolean(false)
  const [ groupCaseToUpdate, setGroupCaseToUpdate ] = useState(null)

  const synchronizing = useSelector(selectSynchronizing)

  useEffect(() => {
    const doUpdate = async () => {
      if (groupCaseToUpdate !== null && selectedGroupCase === groupCaseToUpdate) {
        await dispatch(loadBusinessCasesForGC(selectedGroupCase))
      }
    }

    doUpdate()
  }, [ dispatch, groupCaseToUpdate, selectedGroupCase ])

  const doRefresh = useCallback((groupCase) => {
    setTimeout(async () => {
      await onBusinessCasesUpdated()
      await dispatch(loadBusinessCasesForGC(groupCase))
    }, 100)
  }, [ dispatch, onBusinessCasesUpdated ])

  const handleShareProject = useCallback(async (projects, users) => {
    hideShare()
    const usersId = users.map((user) => (user[KEYS.ID]))
    const columnId = getColumnIndexById(KEYS.ID)
    const projectsId = projects.map((project) => (project[columnId]))
    let usersToShareProject = usersId
    const grants = await dispatch(getGrantsToProject(projectsId))
    if (grants?.payload) {
      usersToShareProject = usersId.filter((userId) => {
        const access = grants.payload[userId]?.access
        return !access?.includes('read')
      })
    }
    if (usersToShareProject.length > 0) {
      await dispatch(grantAccessToProjects(projectsId, usersToShareProject))
    }
    await dispatch(wrapByEventLog({
      type: EVENT_TYPE.bcSendForApprove,
      details: `Users: ${usersId.join(', ')}`,
      action: () => dispatch(approveDraft(projectsId, usersId)),
      extractor: (result) => result.join(', '),
    }))
    doRefresh(selectedGCKey)
  }, [ dispatch, hideShare, doRefresh, selectedGCKey ])

  const handleOnEditNeighbors = useCallback((bc) => {
    if (bc) {
      dispatch(showEditNeighbors({
        id: bc[bcIdColIndex],
        name: bc[bcNameColIndex],
        siteId: bc[siteIdColIndex],
        neighbors: {
          ...bc[neighborsColIndex],
          data: { ...bc[neighborsColIndex].data },
        },
        bc,
      }))
    }
  }, [ dispatch, bcIdColIndex, bcNameColIndex, siteIdColIndex, neighborsColIndex ])

  const handleOnEditSectors = useCallback((bc) => {
    if (bc) {
      const bcId = bc[bcIdColIndex]
      const siteId = bc[siteIdColIndex]
      const sectorIds = bc[sectorIdsColIndex]?.split(',')
      setSectorsForEdit({ bcId, siteId, sectorIds })
      showEditSectors()
    }
  }, [ showEditSectors, bcIdColIndex, siteIdColIndex, sectorIdsColIndex ])

  const isModified = useMemo(() => {
    return !!modifiedValues || gcNameUpdated
  }, [ modifiedValues, gcNameUpdated ])
  const [ markedCells, setMarkedCells ] = useState(null)
  const [
    isSelectEnabled,
    { setTrue: setSelectEnabled, setFalse: setSelectDisabled },
  ] = useBoolean(false)

  const getDisabled = useCallback((selectedRows) => {
    if (!selectedRows || selectedRows.length === 0) {
      return [ true, true, true, true, true, true, true, true, true ]
    }
    let readyForCalculate = true
    let readyForCalculateDraft = true
    let readyForApproveDraft = true
    let readyForCancellation = true
    let readyForRecalculate = true
    let readyForDeleteBC = true
    let readyForCreateBC = true
    let readyForSelectBC = true

    const list = data.getList()
    const readyForDeleteGC = groupStatus === EXISTING_STATUS_ID.SELECTED &&
      (!synchronizing || synchronizing.gcId !== selectedGCKey) &&
      list?.length > 0 && userAccess[BUSINESS_CASES] &&
      list.findIndex((row) => {
        const status = row[statusColIndex]
        return REQUIRED_STATUS_FOR_DISABLE_DELETE.includes(status)
      }) === -1

    if (groupStatus === EXISTING_STATUS_ID.EXISTING) {
      readyForCreateBC = false
    }

    selectedRows.every((row) => {
      const id = row[bcIdColIndex]
      const exStatus = row[exStatusColIndex]
      const status = row[statusColIndex]
      const siteName = row[siteNameColIndex]
      const calculated = row[calculatedColIndex]
      const notModified = !modifiedValues || !modifiedValues[row[bcIdColIndex]]
      if (exStatus !== EXISTING_STATUS_ID.SELECTED) {
        readyForCalculate = false
        readyForCalculateDraft = false
        readyForApproveDraft = false
        readyForCancellation = false
        readyForRecalculate = false
        readyForDeleteBC = false
        if (!siteName || status || (exStatus && exStatus !== EXISTING_STATUS_ID.NEW)) {
          readyForCreateBC = false
        }
        if (exStatus !== EXISTING_STATUS_ID.EXISTING) {
          readyForSelectBC = false
        }
      } else {
        readyForCreateBC = false
        readyForSelectBC = false
      }
      if (!REQUIRED_STATUS_FOR_CALCULATE.includes(status) || !notModified || synchronizing) {
        readyForCalculate = false
      }
      if (!REQUIRED_STATUS_FOR_CALCULATE_DRAFT.includes(status) || !notModified) {
        readyForCalculateDraft = false
      }
      if (!REQUIRED_STATUS_FOR_APPROVE_DRAFT.includes(status) || !notModified) {
        readyForApproveDraft = false
      }
      if (!REQUIRED_STATUS_FOR_CANCEL_CALCULATION.includes(status)) {
        readyForCancellation = false
      }
      if (!calculated || !REQUIRED_STATUS_FOR_RECALCULATE_DRAFT.includes(status) || !notModified) {
        readyForRecalculate = false
      }
      if (REQUIRED_STATUS_FOR_DISABLE_DELETE.includes(status) || synchronizing?.bcIds?.[id]) {
        readyForDeleteBC = false
      }

      return readyForCalculate || readyForCalculateDraft ||
        readyForApproveDraft || readyForCancellation || readyForRecalculate ||
        readyForCreateBC || readyForDeleteBC || readyForSelectBC
    })

    return [
      !readyForCalculate,
      !readyForCalculateDraft,
      !readyForApproveDraft,
      !readyForCancellation,
      !readyForRecalculate,
      !readyForCreateBC,
      !readyForDeleteBC,
      !readyForSelectBC,
      !readyForDeleteGC,
    ]
  }, [
    exStatusColIndex, statusColIndex, calculatedColIndex, siteNameColIndex, bcIdColIndex, modifiedValues,
    data, groupStatus, userAccess, synchronizing, selectedGCKey,
  ])

  useEffect(() => {
    gcNames && setOptions([
      ...gcNames.map(({ id, name }) => ({ key: id, text: name })),
    ])
  }, [ gcNames ])

  useEffect(() => {
    bcNames && setBCOptions(bcNames.map((item) => item.name))
  }, [ bcNames ])

  useEffect(() => {
    if (!hidden) {
      dispatch(prepareSiteNames)
    }
  }, [ dispatch, hidden ])

  useEffect(() => {
    if (siteNames) {
      setSiteOptions(Object.keys(siteNames))
    }
  }, [ siteNames ])

  useEffect(() => {
    if (!hidden) {
      if (selectedGroupCase) {
        if (options &&
            options.findIndex((opt) => opt.key === selectedGroupCase) !== -1 &&
            (selectedGCKey !== selectedGroupCase || groupStatus !== EXISTING_STATUS_ID.SELECTED) &&
            loadedGCKey !== selectedGroupCase) {
          setLoadedGCKey(selectedGroupCase)
          setSelectedGCKey(selectedGroupCase)
          if (!editNeighborsForBC) {
            dispatch(loadBusinessCasesForGC(selectedGroupCase))
          }
          setGroupStatus(EXISTING_STATUS_ID.SELECTED)
          setSelectDisabled()
        }
      }
    }
  }, [
    dispatch, hidden, selectedGroupCase, options, setSelectDisabled, selectedGCKey, loadedGCKey, setLoadedGCKey,
    groupStatus, editNeighborsForBC,
  ])

  const checkUniqueValue = useCallback((values, action) => {
    const table = gridRef.current?.hotInstance
    if (!table) {
      return
    }

    const notUniqueItems = []
    const checkedItems = {}
    const uniqueValues = values.filter((item) => {
      const { id, row, field, value } = item
      let itemIsUnique = true
      if (value) {
        if (field === bcNameColIndex) {
          if (row > 0) {
            const foundAll = allFull.findRangeByValue(value, BC_NAME_FIELD)
            itemIsUnique = !foundAll ||
              (foundAll?.length === 1 && id === allFull.getList()[foundAll[0]][fullBcIdColIndex])
          }
          if (itemIsUnique) {
            let counter = 0
            full.getList().forEach((row) => {
              if (row[bcNameColIndex] === value) {
                ++counter
              }
            })
            itemIsUnique = counter === 1
          }
        } else if (field === siteNameColIndex) {
          const values = table.getDataAtCol(table.toVisualColumn(field))
          const foundIndex = values.findIndex((v, idx) => {
            return v === value && idx !== row
          })
          itemIsUnique = (foundIndex === -1 || row === foundIndex) &&
            (!checkedItems[field] || !checkedItems[field].includes(value))
        }
      }
      if (!itemIsUnique) {
        notUniqueItems.push({
          value, row, field,
        })
        return false
      } else {
        if (!checkedItems[field]) {
          checkedItems[field] = []
        }
        checkedItems[field].push(value)
      }
      return true
    })

    if (uniqueValues?.length > 0) {
      action(uniqueValues)
    }

    if (notUniqueItems?.length > 0) {
      notUniqueMessage(ask, notUniqueItems.map((item) => item.value), () => {
        table.setDataAtCell(notUniqueItems.map(({ row, field }) => [ row, field, null ]))
      })
    }
  }, [ full, allFull, ask, bcNameColIndex, siteNameColIndex, fullBcIdColIndex ])

  const addIncorrectCells = useCallback((cells) => {
    const newCells = [
      ...(markedCells || []),
      ...cells,
    ]
    setMarkedCells(new Set(newCells))
  }, [ markedCells, setMarkedCells ])

  const validateValues = useCallback(async (items, action) => {
    const incorrectCells = []
    const values = items.map(({ id, field, row, prevValue, value }) => {
      if ((prevValue || '') !== value) {
        const type = fields[field]?.type
        if (type === 'int' || type === 'double') {
          const numberValue = parseFloat(value)
          const fieldId = fields[field]?.id
          if (isNaN(numberValue)) {
            incorrectCells.push(getMarkedCellKey(id, fieldId))
          } else if (type === 'int') {
            if (!Number.isInteger(numberValue)) {
              incorrectCells.push(getMarkedCellKey(id, fieldId))
            } else {
              value = numberValue
            }
          }
        }
        return { id, row, field, value }
      }
      return null
    }).filter(Boolean)

    if (incorrectCells.length > 0) {
      await addIncorrectCells(incorrectCells)
      const table = gridRef.current?.hotInstance
      table.render()
    }

    if (values.length > 0) {
      checkUniqueValue(values, action)
    }
  }, [ addIncorrectCells, checkUniqueValue, fields ])

  const updateCreateBC = useCallback((updates) => dispatch(wrapByEventLog({
    type: EVENT_TYPE.bcUpdate,
    details: updates.length === 1
      ? `Id = ${updates[0].id}; Field = ${updates[0].field}; Value = ${updates[0].value}`
      : `${updates.length} fields`,
    action: async () => {
      const table = gridRef.current?.hotInstance
      let renderIsRequired = false
      let successful = 0
      let unsuccessful = 0
      await Promise.all(updates.map(async ({ id, field, value }) => {
        const fieldId = fields[field]?.id
        const modified = {
          [fieldId]: convertValue(value, fields[field]?.type),
        }

        if (fieldId === BC_SITE_FIELD) {
          modified[BC_SITE_ID_FIELD] = siteNames[value]
        }

        const idx = full.findIndexById(id)
        const bcRow = full.getList()?.[idx]
        const status = bcRow?.[statusColIndex]
        let newStatus = null

        if (!fields[field]?.allowEditAndRebuild) {
          if (fieldId !== BC_COMMENTS_FIELD) {
            modified[BC_CALCULATED_ID_FIELD] = false
          }
          if (CHANGE_STATUS.includes(status) && fieldId !== BC_COMMENTS_FIELD) {
            newStatus = BC_STATUS_ID.NEW
          }
        } else {
          // Remove these lines because Recalculation is not accessible
          // if (status === BC_STATUS_ID.CALCULATE_SNC_DRAFT_SUCCESS) {
          //   // Do nothing
          // } else
          if (status === BC_STATUS_ID.CALCULATE_SNC_DRAFT_FAILED) {
            newStatus = BC_STATUS_ID.NEW // was BC_STATUS_ID.CALCULATE_SNC_DRAFT_SUCCESS
          } else if (CHANGE_STATUS.includes(status) && fieldId !== BC_COMMENTS_FIELD) {
            newStatus = BC_STATUS_ID.NEW
          }
        }

        if (newStatus) {
          modified[BC_STATUS_ID_FIELD] = newStatus
        }

        const res = await dispatch(editCreateBCField({ id, field, value, modified }))

        if (!res?.error) {
          if (markedCells && markedCells.size > 0) {
            const key = getMarkedCellKey(id, fieldId)
            if (markedCells.has(key)) {
              markedCells.delete(key)
              if (markedCells.size === 0) {
                setMarkedCells(null)
              }
              renderIsRequired = true
            }
          }
          successful++
        } else {
          await dispatch(saveEventLog(
            EVENT_TYPE.bcUpdate,
            `: Error modifying BC field [Id = ${id}; Field = ${field}; Value = ${value}]\nERROR: ${res?.error}`,
            STATUS_ERROR,
          ))
          unsuccessful++
        }
      }))

      if (renderIsRequired) {
        table.render()
      }

      return [ successful, unsuccessful ]
    },
    extractor: ([ successful, unsuccessful ]) => `${successful} successful, ${unsuccessful} unsuccessful}`,
  })), [
    dispatch, full, fields, markedCells, statusColIndex, siteNames,
  ])

  const handleOnChangeSectors = useCallback((bcId, mofified) => {
    const row = data.findIndexById(bcId)
    const ids = Object.keys(mofified).join(',')
    const names = Object.values(mofified).join(',')
    const updates = [
      {
        field: sectorIdsColIndex,
        id: bcId,
        row,
        value: ids,
      },
      {
        field: sectorNamesColIndex,
        id: bcId,
        row,
        value: names,
      },
    ]
    updateCreateBC(updates)
  }, [ updateCreateBC, data, sectorIdsColIndex, sectorNamesColIndex ])

  const onInlineEdit = useCallback(async (row, field, prevValue, value) => {
    const table = gridRef.current?.hotInstance
    if (table) {
      const physRow = table.toPhysicalRow(row)
      const list = data.getList()
      const id = list[physRow][bcIdColIndex]

      const items = [ { id, field, prevValue, value, row } ]
      if (fields[field]?.id === BC_CALC_METHOD_FIELD) {
        fields.forEach((field, ind) => {
          const purgeInBcMethods = field.purgeInBcMethods
          if (purgeInBcMethods && purgeInBcMethods.includes(value)) {
            list[physRow][ind] = null
          }
        })
      } else if (fields[field]?.id === BC_SITE_FIELD) {
        const siteId = siteNames[value]
        if (siteId) {
          const siteIdx = sitesData.findIndexById(siteId)
          const siteStatus = sitesData.getList()[siteIdx]?.[siteStatusColIndex]
          if (siteStatus === STATUS_ACTIVE) {
            list[physRow][caseTypeColIndex] = CASE_TYPE_B2B
            list[physRow][calcMethodColIndex] = CALC_METHOD_NEW_SECTORS
          } else {
            list[physRow][caseTypeColIndex] = CASE_TYPE_B2C
          }
        }
      } else if (fields[field]?.id === BC_DATE_CAPEX_FIELD) {
        const revenueDate = list[physRow][revenueDateColIndex]
        const newRevenueMoment = moment(value).startOf('day').add(1, 'M')
        const compare = moment(revenueDate).isSameOrAfter(newRevenueMoment)
        if (!compare) {
          const newRevenueString = newRevenueMoment.format(DATE_FORMAT)
          list[row][revenueDateColIndex] = newRevenueString
          items.push({ id, field: revenueDateColIndex, prevValue: revenueDate, value: newRevenueString, row })
        }
      }

      if (fields[field]?.id !== BC_SELECTED_FIELD) {
        validateValues(items, updateCreateBC)
      }
    }
  }, [
    fields, data, bcIdColIndex, validateValues, updateCreateBC, revenueDateColIndex,
    caseTypeColIndex, calcMethodColIndex, siteStatusColIndex, sitesData, siteNames,
  ])

  const onInlineEditRange = useCallback((changes) => {
    const table = gridRef.current?.hotInstance
    if (table) {
      const list = data.getList()
      let onlySelection = true
      let valueIgnored = false
      let notAllowed = false
      const values = changes.reduce((agg, { id, updates }) => {
        const filtered = updates.reduce((res, { field, value, col, row }) => {
          if (row >= 0) {
            const physRow = table.toPhysicalRow(row)
            const status = list[physRow][statusColIndex]
            const prevValue = field === 0 ? !value : list[physRow][field]
            if (field !== selectedColIndex) {
              onlySelection = false
            }

            if (fields[field]?.id !== BC_SELECTED_FIELD && REQUIRED_STATUS_FOR_DISABLE_ANY_CHANGES.includes(status)) {
              // Do not allow changes to BC when calculating
              notAllowed = true
              return res
            } else if (fields[field]?.id === BC_DATE_CAPEX_FIELD && moment(value).isBefore(moment().startOf('day'))) {
              // Ignore incorrect values when replacing
              valueIgnored = true
              return res
            } else if (fields[field]?.id === BC_DATE_REVENUE_FIELD) {
              // Ignore incorrect values when replacing
              const capexDate = list[physRow][startCapexDateColIndex]
              if (moment(value).isBefore(moment(capexDate).add(1, 'M'))) {
                valueIgnored = true
                return res
              }
            }

            res.push({
              id, field, prevValue, value, row, col: col || table.toVisualColumn(field),
            })
            if (fields[field]?.id === BC_DATE_CAPEX_FIELD) {
              const revenueDate = list[physRow][revenueDateColIndex]
              const newRevenueMoment = moment(value).startOf('day').add(1, 'M')
              const compare = moment(revenueDate).isSameOrAfter(newRevenueMoment)
              if (!compare) {
                const newRevenueString = newRevenueMoment.format(DATE_FORMAT)
                list[row][revenueDateColIndex] = newRevenueString
                res.push({
                  id,
                  field: revenueDateColIndex,
                  prevValue: revenueDate,
                  value: newRevenueString,
                  row,
                  col: table.toVisualColumn(revenueDateColIndex),
                })
              }
            }
          }
          return res
        }, [])

        return agg.concat(filtered)
      }, [])

      if (!onlySelection) {
        validateValues(values, updateCreateBC)
      }
      const messages = []
      if (valueIgnored || notAllowed) {
        messages.push('Not all values have been changed')
        if (valueIgnored) {
          messages.push('The value is incorrect or the format is not applicable.')
        }
        if (notAllowed) {
          messages.push('It is not allowed to modify the BC.')
        }
      }
      if (messages.length > 0) {
        msg({ messages })
      }
    }
  }, [
    data, fields, validateValues, updateCreateBC, selectedColIndex, revenueDateColIndex, startCapexDateColIndex, msg,
    statusColIndex,
  ])

  const beforePaste = useCallback((updates, coords) => {
    return false
  }, [])

  const onAddRows = useCallback(async (amount) => {
    const table = gridRef.current?.hotInstance
    if (table) {
      const numOfRows = table.countRows()
      await dispatch(wrapByEventLog({
        type: EVENT_TYPE.bcAddNewRows,
        details: `Amount = ${amount}`,
        action: () => dispatch(addBusinessCases(amount)),
      }))
      // Scroll to new rows
      table.selectCell(numOfRows + amount - 1, 0)
      table.deselectCell()
      table.selectRows(numOfRows)
    }
  }, [ dispatch ])

  const createBusinessCaseActions = useMemo(() => ({
    addRow: () => onAddRows(1),
    addRowDisabled: false,
    addRowHidden: false,
  }), [ onAddRows ])

  useEffect(() => {
    setCreateBusinessCaseActions && setCreateBusinessCaseActions(createBusinessCaseActions)
  }, [ createBusinessCaseActions, setCreateBusinessCaseActions ])

  const onChangeGCName = useCallback((key, isNew, newOptions, newName) => {
    if (key !== selectedGCKey) {
      const doSelection = () => {
        setSelectedGCKey(key)
        if (key === selectedGroupCase) {
          setGroupStatus(EXISTING_STATUS_ID.SELECTED)
          setSelectDisabled()
        } else {
          setGroupStatus(isNew ? EXISTING_STATUS_ID.NEW : EXISTING_STATUS_ID.EXISTING)
          if (!isNew) {
            setSelectEnabled()
          }
        }
      }
      if (newOptions) {
        setOptions(newOptions)
        setTimeout(() => {
          doSelection()
        }, 100)
      } else {
        doSelection()
      }
    } else {
      const selected = gcNames?.find((gc) => (gc?.id === selectedGCKey))
      if (selected && selected?.name !== newName) {
        setGCNameUpdated(true)
      }
    }
  }, [ selectedGCKey, setSelectEnabled, setSelectedGCKey, selectedGroupCase, setSelectDisabled, gcNames ])

  const clearForm = useCallback(() => {
    dispatch(saveEventLog(EVENT_TYPE.bcActions, ': Clear Create BC form'))
    dispatch(clearModified())
    dispatch(resetFilters(DATA_TYPES.CREATE_BUSINESS_CASES))
    setMarkedCells(null)
    setSelectedGCKey(null)
    setGroupStatus(null)
    setGCNameUpdated(false)
  }, [ dispatch, setGroupStatus, setSelectedGCKey ])

  useEffect(() => {
    if (!editNeighborsForBC) {
      clearForm()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ clearForm ])

  const onSelectGCName = useCallback(() => {
    const selectGC = async () => {
      // Remove modifications and open the selectyed GC
      dispatch(saveEventLog(EVENT_TYPE.bcActions, ': Clear modifications on Create BC form'))
      await dispatch(clearModified())
      await setMarkedCells(null)
      dispatch(saveEventLog(EVENT_TYPE.bcActions, `: Selecting an existing GC: ${selectedGCKey}`))
      history.push(`/${projectId}/business-cases/${selectedGCKey}`)
    }
    const cancelSelection = () => {
      if (selectedGroupCase !== selectedGCKey && selectedGroupCase !== '_') {
        setSelectedGCKey(selectedGroupCase)
        setGroupStatus(EXISTING_STATUS_ID.SELECTED)
        setSelectDisabled()
      }
    }

    if (isModified) {
      askDataWillBeLost(ask, selectGC, cancelSelection)
    } else {
      selectGC()
    }
  }, [ dispatch, selectedGCKey, selectedGroupCase, isModified, setSelectDisabled, ask, history, projectId ])

  const onDeleteGC = useCallback(() => {
    const doDeleteGC = async () => {
      const gcId = selectedGCKey
      // await dispatch(loadBusinessCasesForGC(null))
      await dispatch(wrapByEventLog({
        type: EVENT_TYPE.bcActions,
        details: `Delete GC with id '${gcId}'`,
        action: () => dispatch(deleteGC(gcId)),
      }))
      onBusinessCasesUpdated()
      hideDialog()
    }
    confirm(
      doDeleteGC,
      {
        title: 'Confirmation',
        messages: [ 'All BCs and Group Case will be deleted in your project!' ],
        textYesBtn: 'Continue',
        textNoBtn: 'Cancel',
      },
    )
  }, [ dispatch, confirm, selectedGCKey, onBusinessCasesUpdated, hideDialog ])

  const onDeleteSelectedBC = useCallback(() => {
    const doDeleteSelectedBC = async () => {
      await dispatch(wrapByEventLog({
        type: EVENT_TYPE.bcActions,
        details: 'Delete selected BCs',
        action: () => dispatch(deleteBusinessCases()),
      }))
      await doRefresh(selectedGCKey)
    }
    confirm(
      doDeleteSelectedBC,
      {
        title: 'Confirmation',
        messages: [ 'Selected BCs will be deleted from your project!' ],
        textYesBtn: 'Continue',
        textNoBtn: 'Cancel',
      },
    )
  }, [ dispatch, confirm, doRefresh, selectedGCKey ])

  const onCancelCalculation = useCallback(() => {
    const doCancelCalculationBC = async () => {
      await dispatch(wrapByEventLog({
        type: EVENT_TYPE.bcActions,
        details: 'Cancel calculation',
        action: () => dispatch(cancelCalculation()),
      }))
      doRefresh(selectedGCKey)
    }
    confirm(
      doCancelCalculationBC,
      {
        title: 'Confirmation',
        messages: [ 'The calculation for the selected BCs will be cancelled.' ],
        textYesBtn: 'Continue',
        textNoBtn: 'Cancel',
      },
    )
  }, [ dispatch, confirm, doRefresh, selectedGCKey ])

  const onSelectExistingBCs = useCallback(() => {
    const selectExistingBC = async () => {
      const table = gridRef.current?.hotInstance
      if (table) {
        const name = table.getDataAtCell(0, table.toVisualColumn(bcNameColIndex))
        const gcId = await dispatch(loadBusinessCases(name))
        if (gcId) {
          // Set Group Case
          dispatch(saveEventLog(EVENT_TYPE.bcActions, `: Selecting an existing GC: ${gcId}`))
          dispatch(clearModified())
          history.push(`/${projectId}/business-cases/${gcId}`)
        }
      }
    }

    const clearExistingBC = () => {
      dispatch(clearExistingBCNames())
    }

    if (isModified) {
      askDataWillBeLost(ask, selectExistingBC, clearExistingBC)
    } else {
      selectExistingBC()
    }
  }, [ dispatch, bcNameColIndex, ask, isModified, projectId, history ])

  const ensureGroupCaseSelected = useCallback(async () => {
    await dispatch(saveEventLog(EVENT_TYPE.bcActions, `: Ensure GC is selected: ${selectedGCKey}`))
    let gcId = selectedGCKey
    const name = options ? options.find((option) => option.key === gcId)?.text : undefined
    const isNewOrUpdated = gcId && gcNames.findIndex((gc) => (gc.id === gcId && gc.name === name)) === -1
    if (!gcId || isNewOrUpdated) {
      const res = await dispatch(createNewGroupCase({ id: gcId, name }))
      gcId = res ? res.id : null
      if (gcId) {
        setSelectedGCKey(gcId)
        setGroupStatus(EXISTING_STATUS_ID.SELECTED)
        setSelectDisabled()
        setGCNameUpdated(false)
        history.push(`/${projectId}/business-cases/${gcId}`)
      }
    }
    return gcId
  }, [ dispatch, selectedGCKey, gcNames, options, setSelectDisabled, history, projectId ])

  const onCreateNewBC = useCallback(() => {
    const doCreateNewBC = () => dispatch(wrapByEventLog({
      type: EVENT_TYPE.bcCreate,
      action: async () => {
        const gcId = await ensureGroupCaseSelected()
        if (gcId) {
          const result = await dispatch(createNewBCs({ gcId }))
          if (!result.error) {
            await dispatch(clearModified())
            await setMarkedCells(null)
            await doRefresh(gcId)
          } else {
            errorMessage(ask, result.error)
            throw new Error(result.error)
          }
        }
      },
    }))
    if (gcNameUpdated) {
      askDataWillBeLost(ask, doCreateNewBC, empty)
      return
    }
    if (modifiedValues) {
      // Check that only new BCs are changed
      const modified = Object.keys(modifiedValues).find((id) => {
        const index = full.findIndexById(id)
        if (index === undefined) {
          return false
        }
        const bc = full.getList()[index]
        const exStatus = bc[exStatusColIndex]
        return exStatus === EXISTING_STATUS_ID.SELECTED
      })

      if (modified) {
        askDataWillBeLost(ask, doCreateNewBC, empty)
        return
      }
    }
    doCreateNewBC()
  }, [ dispatch, ensureGroupCaseSelected, ask, doRefresh, gcNameUpdated, modifiedValues, full, exStatusColIndex ])

  const checkUnsavedAndClose = useCallback((backAction) => () => {
    const closeDialog = () => {
      hideDialog()
    }
    if (isModified) {
      askDataWillBeLost(ask, closeDialog, backAction)
    } else {
      closeDialog()
    }
  }, [ ask, hideDialog, isModified ])

  const showIncorrectCells = (cells) => () => {
    if (cells.length > 0) {
      setMarkedCells(new Set(cells))
      const table = gridRef.current?.hotInstance
      table.render()
    }
  }

  const checkAllBCIsSelected = useCallback((action, onlyOne) => () => {
    const table = gridRef.current?.hotInstance
    if (!table) {
      return
    }

    const gcIdColIndex = findIndex(fields, BC_GROUP_ID_FIELD)

    const selectedRows = data.getList().filter((bc) => bc[gcIdColIndex] && bc[selectedColIndex])
    const errors = selectedRows.reduce((agg, item) => {
      const siteId = item[siteIdColIndex]
      const siteName = item[siteNameColIndex]
      const siteIdx = sitesData.findIndexById(siteId)
      const siteStatus = sitesData.getList()[siteIdx]?.[siteStatusColIndex]
      const caseType = item[caseTypeColIndex]
      const calcMethod = item[calcMethodColIndex]
      if (!isBusinessCaseEligibleForCalculation(siteStatus, caseType, calcMethod)) {
        agg.push(siteName)
      }
      return agg
    }, [])
    if (errors.length > 0) {
      confirm(
        null,
        {
          title: 'Calculation not allowed',
          messages:
            [
              `The site status does not allow calculation of business ${errors.length > 1 ? 'cases' : 'case'} ${errors.join(', ')} with the selected type or calculation method`,
            ],
          textNoBtn: 'Close',
        },
      )
    } else if (!onlyOne && table.countRows() > Object.keys(selectedRows).length) {
      confirmNotAllSelected(confirm, action(selectedRows))
    } else {
      action(selectedRows)()
    }
  }, [
    data, fields, confirm, selectedColIndex, siteIdColIndex, calcMethodColIndex, caseTypeColIndex,
    siteNameColIndex, siteStatusColIndex, sitesData,
  ])

  const checkAllSitesHaveNetworkItems = useCallback((action) => (businessCases) => () => {
    const sites = Object.values(businessCases).reduce((res, row, index) => {
      if (row[selectedColIndex] && !!row[siteNameColIndex]) {
        res.push({
          index, name: row[siteNameColIndex],
        })
      }
      return res
    }, [])

    const sectorFieldSiteNameInd = sectorFields?.findIndex((field) => field.id.endsWith('site.name'))
    const sitesWithSectors = new Set(sectorsData?.getList()?.map((sector) => sector[sectorFieldSiteNameInd]))
    const siteFieldSiteNameInd = siteFields?.findIndex((field) => field.id.endsWith('site.name'))
    const allSiteNames = new Set(sitesData?.getList()?.map((site) => site[siteFieldSiteNameInd]))
    const marked = []
    const errorSites = sitesWithSectors
      ? sites.reduce((res, site) => {
        if (!sitesWithSectors.has(site.name)) {
          marked.push(`${site.index}|${siteNameColIndex}`)
          res.push(site.name)
        }
        return res
      }, [])
      : sites.map((site) => site.name)

    if (errorSites?.length > 0) {
      dispatch(saveEventLog(
        EVENT_TYPE.bcActions,
        `: Check before calculation: Sites with errors (without sectors): ${errorSites.length}`,
      ))
    }

    const siteNamesForMessage = errorSites.reduce((res, name) => {
      if (allSiteNames.has(name)) {
        res[1].push(name)
      } else {
        res[0].push(name)
      }
      return res
    }, [ [], [] ])

    if (errorSites.length > 0) {
      askSitesErrors(
        ask,
        siteNamesForMessage[0],
        siteNamesForMessage[1],
        showIncorrectCells(marked),
        checkUnsavedAndClose(showIncorrectCells(marked)),
      )
    } else {
      action(businessCases)
    }
  }, [
    sectorsData, sectorFields, siteFields, sitesData, ask, checkUnsavedAndClose, selectedColIndex, siteNameColIndex,
    dispatch,
  ])

  const checkAllRequiredFields = useCallback((action) => (businessCases) => {
    const marked = []
    const columnNames = []
    Object.keys(businessCases).forEach((row) => {
      const rowData = businessCases[row]
      const calcMethod = rowData[calcMethodColIndex]
      rowData.forEach((value, col) => {
        const field = fields[col]
        if (
          (!value && !(field.type === 'double' && value === 0)) &&
          (!field.hidden && field.type !== 'boolean' &&
            (field.required || field.requiredInBcMethods?.includes(calcMethod)))
        ) {
          if (!columnNames.includes(field.label)) {
            columnNames.push(field.label)
          }
          marked.push(getMarkedCellKey(rowData[bcIdColIndex], fields[col].id))
        }
      })
    })

    if (marked.length > 0) {
      dispatch(saveEventLog(EVENT_TYPE.bcActions, ': Check before calculation: Not all required fields are set'))
      askNotFieldsReady(
        ask,
        columnNames,
        showIncorrectCells(marked),
        checkUnsavedAndClose(showIncorrectCells(marked)),
      )
    } else {
      action(businessCases)
    }
  }, [ checkUnsavedAndClose, fields, ask, dispatch, bcIdColIndex, calcMethodColIndex ])

  const checkAndExecute = useCallback((action, onlyOne) => () => {
    checkAllBCIsSelected(
      checkAllSitesHaveNetworkItems(
        checkAllRequiredFields(
          action,
        ),
      ),
      onlyOne,
    )()
  }, [ checkAllBCIsSelected, checkAllRequiredFields, checkAllSitesHaveNetworkItems ])

  const onStartCalculating = useCallback((action = calculateDraft) => async (businessCases) => {
    const totalSectorIds = new Set()
    const selectedBusinessCases = Object.values(businessCases).reduce((res, row) => {
      const sectorIds = row[sectorIdsColIndex] && row[sectorIdsColIndex].split(',')
      sectorIds.forEach(totalSectorIds.add, totalSectorIds)
      res[row[bcIdColIndex]] = {
        bcId: row[bcIdColIndex],
        siteId: row[siteIdColIndex],
        isCalculated: row[calculatedColIndex],
        sectorIds,
      }
      return res
    }, {})

    const renderStatistics = () => (
      <CalculateConfirmationGrid
        bcInfo={Object.values(selectedBusinessCases)}
        selectedBusinessCases={selectedBusinessCases}
        projectId={projectId}
        totalSectorIds={totalSectorIds}
      />
    )

    const onStartCalculation = () => dispatch(wrapByEventLog({
      type: EVENT_TYPE.bcStartCalculation,
      details: `Calculate Draft; GC = '${selectedGCKey}'; BCs = ${Object.keys(selectedBusinessCases).join(', ')}`,
      action: async () => {
        const res = await dispatch(action({
          gcId: selectedGCKey,
          businessCaseIds: Object.keys(selectedBusinessCases),
        }))
        if (!res.error) {
          await doRefresh(selectedGCKey)
        } else {
          errorMessage(ask, res.error)
          throw new Error(res.error)
        }
      },
    }))

    askShowStatistics(ask, renderStatistics, onStartCalculation, empty)
  }, [
    dispatch, ask, selectedGCKey, doRefresh, projectId,
    siteIdColIndex, bcIdColIndex, calculatedColIndex, sectorIdsColIndex,
  ])

  const syncWithAtoll = useCallback((action) => (businessCases) => {
    const onOk = async () => {
      // Update BC status before synchronization
      const saveStatusResult = await dispatch(doCreateBusinessCases({
        groupCaseId: selectedGroupCase,
        businessCases: Object.values(businessCases).map((bc) => ({
          [BC_ID_FIELD]: bc[bcIdColIndex],
          [BC_STATUS_ID_FIELD]: BC_STATUS_ID.CALCULATE_DMP_FOR_APPROVE_ATOLL_SYNC,
        })),
      }))
      if (saveStatusResult.error) {
        await dispatch(saveEventLog(
          EVENT_TYPE.bcSetStatus,
          `: Changing BC status [Status = ${BC_STATUS_ID.CALCULATE_DMP_FOR_APPROVE_ATOLL_SYNC}]\nERROR: ${saveStatusResult.error}`,
          STATUS_ERROR,
        ))
        errorMessage(ask, saveStatusResult.error.message)
        return
      } else {
        // Refresh data
        await doRefresh(selectedGCKey)
      }

      let result
      try {
        result = await dispatch(wrapByEventLog({
          type: EVENT_TYPE.bcSyncWithAtoll,
          details: `Default Project; User Project = ${project[KEYS.ID]}`,
          action: async () => {
            const result = await updateFromAtoll(project[KEYS.ID], true)
            if (result.error) {
              throw new Error(result.error.message)
            }
            return result
          },
        }))
      } catch (e) {
        errorMessage(ask, e.message)
        return
      }
      const { id, status } = result

      const bcIds = Object.values(businessCases).reduce((agg, row) => {
        agg[row[bcIdColIndex]] = row[siteIdColIndex]
        return agg
      }, {})

      let groupCaseToUpdate = null
      if (JOB_ACTIVE_STATUS.includes(status)) {
        dispatch(addTask({ type: TASK_TYPES.updateFromAtoll, id }))
        await dispatch(saveSettings({
          bcCalcApprove: {
            jobId: id,
            selectedGroupCase,
            bcIds,
          },
        }))
        groupCaseToUpdate = await dispatch(updateCalculateForApprove)
      } else if (status === STATUS_JOB.FINISHED_SUCCESS) {
        await dispatch(saveSettings({
          bcCalcApprove: {
            jobId: null,
            selectedGroupCase,
            bcIds,
          },
        }))
        groupCaseToUpdate = await dispatch(updateCalculateForApprove)
      }

      if (groupCaseToUpdate) {
        setGroupCaseToUpdate(groupCaseToUpdate)
      }
    }
    confirm(
      onOk,
      {
        title: 'Confirmation',
        messages: [ 'All data for the selected BCs will be updated from Atoll' ],
        textYesBtn: 'Continue',
        textNoBtn: 'Cancel',
      },
    )
  }, [ ask, confirm, dispatch, bcIdColIndex, siteIdColIndex, selectedGCKey, project, selectedGroupCase, doRefresh ])

  const closeAndClear = useCallback(async () => {
    hideDialog()
    setGCNameUpdated(false)
    await dispatch(clearCreateBCData())
    clearForm()
  }, [ dispatch, hideDialog, clearForm ])

  const onSave = useCallback(async (action) => {
    const gcId = await ensureGroupCaseSelected()
    if (gcId) {
      if (modifiedValues) {
        await dispatch(wrapByEventLog({
          type: EVENT_TYPE.bcSave,
          details: `GC = ${gcId}`,
          action: async () => {
            const res = await dispatch(saveBusinessCases({ gcId }))
            if (!res?.error) {
              await dispatch(clearModified())
              await setMarkedCells(null)
              await doRefresh(gcId)
              if (action && typeof action === 'function') {
                action()
              }
            } else if (res.error) {
              errorMessage(ask, res.error)
              throw new Error(res.error)
            }
          },
        }))
      } else {
        await dispatch(saveEventLog(EVENT_TYPE.bcActions, `: Updating BCs for GC'${gcId}'`))
        await doRefresh(gcId)
        if (action && typeof action === 'function') {
          action()
        }
      }
    }
  }, [ dispatch, ensureGroupCaseSelected, ask, doRefresh, modifiedValues ])

  const onCloseDialog = useCallback(() => {
    if (isModified) {
      const handleOnSave = () => {
        onSave(closeAndClear)
      }
      askSaveBC(ask, handleOnSave, checkUnsavedAndClose(empty))
    } else {
      return closeAndClear()
    }
  }, [ ask, checkUnsavedAndClose, closeAndClear, onSave, isModified ])

  const logActionAndExecute = useCallback((name, action) => (props) => {
    dispatch(saveEventLog(EVENT_TYPE.bcActions, `: ${name}`))
    action(props)
  }, [ dispatch ])

  const handleOnImport = useCallback(async (cols, rows) => {
    if (selectedGCKey && groupStatus === EXISTING_STATUS_ID.SELECTED) {
      if (isModified) {
        const messages = [
          'There are unsaved changes',
          'Please save changes before import',
        ]
        msg({ messages })
        return false
      } else {
        const result = await onImport(cols, rows)
        dispatch(loadBusinessCasesForGC(selectedGCKey))
        return result
      }
    } else {
      const messages = [
        'Business Group Case is not selected',
        'Please select a business group case before import',
      ]
      msg({ messages })
      return false
    }
  }, [ selectedGCKey, groupStatus, msg, isModified, onImport, dispatch ])

  const columns = useMemo(() => (initColumnBC(fields)), [ fields ])

  const contextMenuHidden = useCallback((name) => {
    const list = data.getList()
    let readyForRecalculate = true
    list?.forEach((row) => {
      if (row[selectedColIndex]) {
        if (!row[calculatedColIndex]) {
          readyForRecalculate = false
        }
      }
      return readyForRecalculate
    })
    switch (name) {
      case 'calculateDraft':
        return readyForRecalculate
      case 'recalculateDraft':
        return !readyForRecalculate
      default:
        return false
    }
  }, [ data, selectedColIndex, calculatedColIndex ])

  const onCallbackContextMenu = useCallback((key, selection, clickEvent, refHot) => {
    switch (key) {
      case 'calculateDraft':
        checkAndExecute(onStartCalculating(calculateDraft))()
        break
      case 'recalculateDraft':
        checkAndExecute(onStartCalculating(recalculateDraft))()
        break
      case 'calculateForApproval':
        checkAndExecute(syncWithAtoll(calculateForApprove), true)()
        break
      case 'sendForApproval':
        showShare()
        break
      case 'cancelCalculation':
        onCancelCalculation()
        break
      case 'createBC':
        onCreateNewBC()
        break
      case 'selectBC':
        logActionAndExecute('Select existing', onSelectExistingBCs)()
        break
      case 'deleteGroupBC':
        onDeleteGC()
        break
      case 'deleteBC':
        onDeleteSelectedBC()
        break
      default:
    }
  }, [
    checkAndExecute, logActionAndExecute, onCancelCalculation, onCreateNewBC, onDeleteGC,
    onDeleteSelectedBC, onSelectExistingBCs, onStartCalculating, showShare, syncWithAtoll,
  ])

  const getContextMenuDisabled = useCallback((selectedRows) => {
    const [
      isCalculateDisabled,
      isCalculateDraftDisabled,
      isSendingForApproveBCDisabled,
      isCancelCalculationBCDisabled,
      isRecalculateDraftDisabled,
      isCreateBCDisabled,
      isDeleteBCDisabled,
      isSelectBCDisabled,
      isDeleteGCDisabled,
    ] = getDisabled(selectedRows)

    return {
      calculateDraft: isCalculateDraftDisabled || loading,
      recalculateDraft: isRecalculateDraftDisabled || loading,
      calculateForApproval: isCalculateDisabled || loading,
      sendForApproval: isSendingForApproveBCDisabled || loading,
      cancelCalculation: isCancelCalculationBCDisabled || loading,
      createBC: isCreateBCDisabled || loading,
      selectBC: isSelectBCDisabled || loading,
      deleteGroupBC: isDeleteGCDisabled || loading,
      deleteBC: isDeleteBCDisabled || loading,
    }
  }, [ loading, getDisabled ])

  const getContextMenuBusy = useCallback((selectedRows) => {
    let allowToDelete = true
    if (synchronizing) {
      selectedRows.every((row) => {
        const id = row[bcIdColIndex]
        if (synchronizing?.bcIds?.[id]) {
          allowToDelete = false
        }
        return allowToDelete
      })
    }

    return {
      calculateForApproval: !!synchronizing,
      deleteGroupBC: synchronizing ? synchronizing.gcId === selectedGCKey : false,
      deleteBC: !allowToDelete,
    }
  }, [ synchronizing, selectedGCKey, bcIdColIndex ])

  const Table = useMemo(() => {
    const onContextMenu = (contextMenu) => {
      const table = gridRef.current?.hotInstance
      if (table) {
        const [ [ row ] ] = table.getSelected() || []

        if (row === -1) {
          return
        }

        const col = table?.toVisualColumn(selectedColIndex)
        const selected = table.getDataAtCell(row, col)
        if (!selected) {
          const physRow = table?.toPhysicalRow(row)
          const list = data.getList()
          // const id = list[physRow][bcIdColIndex]
          const newSelectedRows = [
            list[physRow],
          ]
          const dis = getContextMenuDisabled(newSelectedRows)
          const busy = getContextMenuBusy(newSelectedRows)
          contextMenu.forEach((item) => {
            if (dis[item.key] !== undefined) {
              item.disabled = dis[item.key]
            }
            item.renderer = rendererItem(item.label, busy[item.key] ? true : item.icon)
          })
          // Update table
          const changes = []
          const tableSize = table.getData()?.length
          if (tableSize > 1) {
            for (let i = 0; i < tableSize; ++i) {
              const selected = table.getDataAtCell(i, col)
              if (selected) {
                changes.push([ i, col, false ])
              }
            }
          }
          changes.push([ row, col, true ])
          table.setDataAtCell(changes)
        } else {
          const list = data.getList()
          const selectedRows = list.filter((row) => row[selectedColIndex])
          const dis = getContextMenuDisabled(selectedRows)
          const busy = getContextMenuBusy(selectedRows)
          contextMenu.forEach((item) => {
            if (dis[item.key] !== undefined) {
              item.disabled = dis[item.key]
            }
            item.renderer = rendererItem(item.label, busy[item.key] ? true : item.icon)
          })
        }
      }
    }

    return !hidden && columns
      ? (
          <div className='table'>
            <BCTable
              refHot={gridRef}
              bcNames={bcOptions}
              siteNames={siteOptions}
              data={data}
              columns={columns}
              markedCells={markedCells}
              onInlineEdit={onInlineEdit}
              onAddRows={onAddRows}
              needToExport={needToExport}
              onImport={handleOnImport}
              idFieldIdx={bcIdColIndex}
              statusFieldIdx={statusColIndex}
              calcMethodColIndex={calcMethodColIndex}
              onInlineEditRange={onInlineEditRange}
              beforePaste={beforePaste}
              onCallbackContextMenu={onCallbackContextMenu}
              onContextMenu={onContextMenu}
              contextMenuHidden={contextMenuHidden}
              onEditNeighbors={handleOnEditNeighbors}
              onEditSectors={handleOnEditSectors}
              isBCFieldEditable={isBCFieldEditable}
            />
          </div>
        )
      : null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bcIdColIndex, bcOptions, beforePaste, calcMethodColIndex, columns, data, handleOnImport, hidden,
    markedCells, needToExport, onAddRows, onInlineEdit, onInlineEditRange, siteOptions, statusColIndex,
    exStatusColIndex,
    onCallbackContextMenu, contextMenuHidden,
    getContextMenuDisabled, selectedColIndex,
    redraw,
  ])

  return (
    <>
      <div className='create-business-case'>
        <div className='body'>
          <CreateBCHeader
            loading={loading || (synchronizing && synchronizing === selectedGCKey)}
            gridRef={gridRef}
            groupStatus={groupStatus}
            selectedGCKey={selectedGCKey}
            options={options}
            onChangeGCName={onChangeGCName}
            isSelectEnabled={isSelectEnabled}
            onSelectGCName={onSelectGCName}
          />
          {Table}
        </div>
        <DialogFooter className='footer'>
          <PrimaryButton
            onClick={onSave}
            text="Save"
            disabled={loading || (!isModified) || groupStatus !== EXISTING_STATUS_ID.SELECTED}
          />
          <DefaultButton onClick={onCloseDialog} text="Close" />
        </DialogFooter>
      </div>
      {!!editNeighborsForBC && <div className='modal-overlay' />}
      <EditSectors
        show={isShowEditSectors}
        onClose={hideEditSectors}
        onChange={handleOnChangeSectors}
        bcId={sectorsForEdit?.bcId}
        siteId={sectorsForEdit?.siteId}
        sectedSectorIds = {sectorsForEdit?.sectorIds}
      />
      {renderConfirm()}
      <ShareProjectForm
        isShow={isShareOpen}
        onHide={hideShare}
        onOk={handleShareProject}
        projects={selectedProject}
        onlyFPA={true}
      />
    </>
  )
}

const CreateBusinessCase = (props) => (
  <HeaderContext.Consumer>
    {({ setCreateBusinessCaseActions }) => (
      <BCCalculation setCreateBusinessCaseActions={setCreateBusinessCaseActions} {...props} />
    )}
  </HeaderContext.Consumer>
)

export default CreateBusinessCase
