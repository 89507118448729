import React from 'react'
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator'

const ProgressItem = ({ percentComplete = null, barHeight = 7 }) => {
  const value = (percentComplete && percentComplete > 0) ? (percentComplete / 100) : null
  return <ProgressIndicator
    barHeight={barHeight}
    percentComplete={value}
    progressHidden={percentComplete === null}
  />
}

export default ProgressItem
