import React, { useCallback, useMemo, useState } from 'react'
import { Dialog, DialogFooter, DialogType, TextField } from '@fluentui/react'
import { DefaultButton, PrimaryButton } from '../common/Button'
import { confirmStyles } from '../common/Confirm/styles'

const MIN_WIDTH_DIALOG = 480
const MAX_TEMPLATE_LENGTH = 255

const CreateTemplateForm = ({ onHide, onCreate, title, template, subText, oldName, templateList }) => {
  const [ name, setName ] = useState(oldName)

  const onChangeName = useCallback(({ target }) => {
    setName(target?.value)
  }, [ setName ])

  const onOk = useCallback(() => {
    onCreate(name)
  }, [ name, onCreate ])

  const isNotCreated = useMemo(() => {
    const nameTemplate = (name ?? '').trim()
    if (Array.isArray(templateList)) {
      return templateList.some((template) => template.text === nameTemplate)
    }
    return false
  }, [ templateList, name ])

  const canSave = useMemo(() => onOk && !isNotCreated, [ onOk, isNotCreated ])

  return (
    <Dialog
      hidden={false}
      onDismiss={onHide}
      dialogContentProps={{
        type: DialogType.normal,
        title: title,
        subText,
      }}
      minWidth={MIN_WIDTH_DIALOG}
      modalProps={{
        isBlocking: true,
      }}
    >
      <div className={confirmStyles.body}>
        <TextField
          styles={{ root: { minHeight: 86 } }}
          errorMessage={isNotCreated ? 'This template name already exists' : undefined}
          label={'Name template composite index'}
          maxLength={MAX_TEMPLATE_LENGTH}
          defaultValue={name}
          onChange={onChangeName}
          placeholder={'Input name'}
        />
      </div>
      <DialogFooter>
        <PrimaryButton onClick={onOk} text={template?.key === 'default' ? 'Create' : 'Save as'} disabled={!canSave} />
        <DefaultButton onClick={onHide} text="Cancel" />
      </DialogFooter>
    </Dialog>
  )
}

export default CreateTemplateForm
