// Допоміжні функції обробки подій
import L from 'leaflet'
import { setComplaintEditor } from '../../features/network/networkSlice'
import { binaryFindIndex, hash } from '../../utils/math'
import { SPREAD_DISCRETE, SPREAD_RANGE, SPREAD_UNIQUE } from '../../constants/settings'
import COLORS from '../../constants/colors'
import { STATUS_REMOVED } from '../../constants/network'
import { setPanel } from '../../features/panel/panelSlice'
import { defaultSortFn } from '../../features/network/indexing'

export const COMPLAINT_SIZE = 16
const CLUSTER_SIZE = 40
const COMPLAINT_MENU_LIMIT = 1000

const complaintDblClick = async (event) => {
  const dispatch = event?.target?._map?.dispatch
  const id = event?.target?.feature?.properties?.id
  if (dispatch && id !== undefined) {
    await dispatch(setComplaintEditor(id))
    dispatch(setPanel('edit-complaint'))
  }
}

const complaintGroupDblClick = (event) => {
  const setElementsMenuTarget = event?.target?._map?.setElementsMenuTarget
  const setElementsMenu = event?.target?._map?.setElementsMenu
  const complaintsCluster = event?.target?._map?.complaintsCluster
  const clusterId = event?.target?.feature?.properties?.cluster_id
  if (clusterId && complaintsCluster && setElementsMenuTarget && setElementsMenu) {
    const list = complaintsCluster.getLeaves(clusterId, COMPLAINT_MENU_LIMIT)
      .map(({ properties }) => ({
        ...properties,
        type: 'complaint',
      }))
    setElementsMenuTarget(event.originalEvent)
    setElementsMenu(list)
  }
}

// Допоміжні функції рендеру
export const formLegendSection = (section) => {
  let result = `<h4>${section.title}</h4>
                       <div class="legend-section">`
  for (const item of section.list) {
    result += `<div class="legend-item">
                 <div class="legend-item-sample" style="background:${item.color}"></div>
                 <div class="legend-item-label legend-truncated">${item.text}</div>
               </div>`
  }
  result += '</div>'
  return result
}

const complaintIcon = L.divIcon({
  html: '<div></div>',
  className: 'leaflet-oldie marker-single marker-cluster-small',
  iconSize: L.point(COMPLAINT_SIZE, COMPLAINT_SIZE),
})

export const createClusterIcon = (feature, latlng) => {
  if (!feature.properties.cluster) {
    return L.marker(latlng, {
      icon: complaintIcon,
    })
      .on('dblclick', complaintDblClick)
  }

  const count = feature.properties.point_count
  const size = count < 100
    ? 'small'
    : count < 1000 ? 'medium' : 'large'

  const icon = L.divIcon({
    html: '<div><span>' + feature.properties.point_count_abbreviated + '</span></div>',
    className: 'marker-cluster marker-cluster-' + size,
    iconSize: L.point(CLUSTER_SIZE, CLUSTER_SIZE),
  })

  return L.marker(latlng, {
    icon: icon,
  })
    .on('dblclick', complaintGroupDblClick)
}

export const updateClusters = (map) => {
  if (!map.complaints || !map.complaintsCluster) {
    return
  }
  map.complaints.clearLayers()
  if (map.networkLayersVisibility?.complaintsVisibility) {
    const bounds = map.getBounds()
    const bbox = [ bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth() ]
    const zoom = map.getZoom()
    const clusters = map.complaintsCluster.getClusters(bbox, Math.round(zoom))
    map.complaints.addData(clusters)
  }
}

export const isItemVisible = (item, typeIdx, types, sortFn, idIdx, latIdx, lngIdx, statusIdx) => {
  let result = Boolean(
    item[idIdx] && item[latIdx] && item[lngIdx] && !item.hidden && item[statusIdx] !== STATUS_REMOVED,
  )
  if (result && typeIdx >= 0) {
    const idx = binaryFindIndex(types, item[typeIdx], sortFn)
    result = Boolean(types[idx]?.state?.selected)
  }
  return result
}

export const determineColorByValue = (id, value, settings) => {
  switch (settings?.spread) {
    case SPREAD_RANGE: {
      const table = settings?.coloring?.table
      if (table?.length) {
        const v = +value
        const tableIndex = table.findIndex(({ maxValue: max, minValue: min }) =>
          (max === null && v >= min) || (min === null && v <= max) || (v >= min && v < max) ||
          (min === max && v === min))
        if (tableIndex >= 0) {
          return table[tableIndex].color
        }
      }
      break
    }
    case SPREAD_DISCRETE: {
      const table = settings?.coloring?.table
      if (table?.length) {
        const tableIndex = binaryFindIndex(table, value, defaultSortFn, 'value')
        if (tableIndex >= 0) {
          return table[tableIndex].color
        }
      }
      break
    }
    case SPREAD_UNIQUE: {
      return COLORS[hash(id.toString()) % 75]
    }
    default:
  }
  return undefined
}

export const determineColor = (item, settings, idx, idIdx) => determineColorByValue(item[idIdx], item[idx], settings)

export const determineLabelByValue = (value, labeling) => value == null || !labeling
  ? null
  : {
      label: `${value}`,
      size: labeling.size,
      background: labeling.background,
      font: labeling.font,
    }

export const determineLabel = (item, labeling, idx) => !labeling || idx < 0
  ? null
  : determineLabelByValue(item[idx], labeling)
