/* eslint-disable react/display-name */
import React, { useCallback, memo, useMemo, useState } from 'react'
import VirtualizedTreeComponent from 'react-virtualized-tree'
import { getNodeRenderOptions } from 'react-virtualized-tree/lib/selectors/nodes'
import debounce from 'lodash.debounce'
import { IconSvg } from '../../common/icons'
import {
  HiddenDropdownIcon, OpenDropdownIcon, ShowIcon, HideIcon, SearchIcon, SortIcon,
} from '../../common/icons/names'
import { empty } from '../../../utils/format'
import { buildClassList } from '../../utils/classes'
import MenuButton from '../../common/ContextMenu/MenuButton'

import './VirtualizedTree.css'

const MAX_NODE_NAME = 30

const GreyNode = ({ children, onClick, isExpanded, hasChildren }) => (
  <div className="capex-tree-grey-node" onClick={onClick}>
    {children}
    {hasChildren && (
      <div className="capex-tree-grey-node-icon">
        <IconSvg name={isExpanded ? OpenDropdownIcon : HiddenDropdownIcon} />
      </div>
    )}
  </div>
)

const WhiteNode = ({ children }) => (
  <div className="capex-tree-white-node">
    {children}
  </div>
)

const Expandable = ({ onExpandNode, node, children }) => {
  const [ { hasChildren, isExpanded }, pathLength ] =
    useMemo(() => [ getNodeRenderOptions(node), node.path.length ], [ node ])

  const handleClick = useCallback(() => onExpandNode(node.path), [ node, onExpandNode ])

  const Component = useMemo(() => hasChildren || pathLength <= 2
    ? GreyNode
    : WhiteNode,
  [ hasChildren, pathLength ])

  return (
    <Component
      hasChildren={hasChildren}
      isExpanded={isExpanded}
      onClick={handleClick}
      node={node}
      onExpandNode={onExpandNode}
    >
      {children}
    </Component>
  )
}

let lastClick = 0

const Selectable = ({
  nodes, node, children, onSelectNode, onClickNode, onDblClickNode, selectable, selectableWhenEmptyChildren,
}) => {
  const [ selected, isSelectable, path ] = useMemo(() => {
    const {
      state: {
        selectable = true,
        selected,
      } = {},
      path,
    } = node

    let isSelectable = selectable

    if (path && path.length) {
      if (nodes[path[0]]?.state?.selectable === false) {
        isSelectable = false
      }
    }

    if (isSelectable && !selectableWhenEmptyChildren && !node.children?.length && node.isBranchNode) {
      // Ми тут прибираємо галочку "видимість" для гілок, які не мають дочірніх елементів -- але тільки для гілок,
      // "листки" мусять мати галочку, тому перевіряється атрибут isBranchNode (він у нас заповнюється для дерева зон).
      isSelectable = false
    }

    return [ selected, isSelectable, path ]
  }, [ node, nodes, selectableWhenEmptyChildren ])

  const onIconClick = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
    document.body.classList.add('waiting')
    setTimeout(() => {
      onSelectNode && onSelectNode(path)
      document.body.classList.remove('waiting')
    }, 50)
  }, [ onSelectNode, path ])

  const onLabelClick = useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()
    const time = Date.now() - lastClick
    if (time < 1000) {
      onDblClickNode && onDblClickNode(path)
      lastClick = 0
    } else {
      onClickNode && onClickNode(path)
      lastClick = Date.now()
    }
  }, [ onClickNode, onDblClickNode, path ])

  return (
    <div className={buildClassList('capex-tree-node', { selected })}>
      {isSelectable && selectable && (
        <div className="capex-tree-node-icon" onClick={onIconClick}>
          <IconSvg name={selected ? ShowIcon : HideIcon} />
        </div>
      )}
      <div className="capex-tree-node-label" onClick={onLabelClick}>
        {children}
      </div>
      <div className="capex-tree-node-suffix">
        {node.suffix}
      </div>
    </div>
  )
}

const Item = ({ node, style, children }) => (
  <div style={style} title={node?.children?.length}>
    {children}
  </div>
)

const INITIAL_FILTERED_VALUE = {
  nodes: [],
  nodeParentMappings: {},
}

const filterNodes = (filter, nodes, parents = []) =>
  nodes.reduce((filtered, n) => {
    const { nodes: filteredChildren, nodeParentMappings: childrenNodeMappings } = n.children
      ? filterNodes(filter, n.children, [ ...parents, n.id ])
      : INITIAL_FILTERED_VALUE

    return !(filter(n) || filteredChildren.length)
      ? filtered
      : {
          nodes: [
            ...filtered.nodes,
            {
              ...n,
              children: filteredChildren,
            },
          ],
          nodeParentMappings: {
            ...filtered.nodeParentMappings,
            ...childrenNodeMappings,
            [n.id]: parents,
          },
        }
  }, INITIAL_FILTERED_VALUE)

const indexByName = (searchTerm) => ({ name }) => name.toLowerCase().indexOf(searchTerm) > -1

const FilteringContainer = ({ nodes, menu, onMenuItemClick, treeRenderer }) => {
  const [ filterText, setFilterText ] = useState('')
  const [ filterTerm, setFilterTerm ] = useState('')

  const debouncedSetFilterTerm = useMemo(() => debounce(setFilterTerm, 300), [ setFilterTerm ])

  const handleFilterTextChange = useCallback((event) => {
    const { value } = event.target
    setFilterText(value)
    const term = (value ?? '').toLowerCase().trim()
    if (term.length > 2) {
      debouncedSetFilterTerm(term)
    } else {
      debouncedSetFilterTerm('')
    }
  }, [ setFilterText, debouncedSetFilterTerm ])

  const relevantNodes = useMemo(() => ({ nodes, nodeParentMappings: {} }), [ nodes ])

  const { nodes: filteredNodes, nodeParentMappings } = useMemo(() => filterTerm
    ? filterNodes(indexByName(filterTerm), relevantNodes.nodes)
    : relevantNodes,
  [ filterTerm, relevantNodes ])

  return (
    <div className="tree-filter-container">
      <div className="tree-lookup-input">
        <div className="tree-lookup-input-icon">
          <IconSvg name={SearchIcon} />
        </div>
        <input type="text" value={filterText} onChange={handleFilterTextChange} placeholder="Search" />
        {menu && (
          <MenuButton icon={SortIcon} items={menu} onItemClick={onMenuItemClick} title="Group By" />
        )}
      </div>
      {treeRenderer({ nodes: filteredNodes, nodeParentMappings })}
    </div>
  )
}

const NodeLabel = ({ node }) => {
  const [ name, title ] = useMemo(() => node.name && node.name.length > MAX_NODE_NAME + 3
    ? [ `${node.name.substring(0, MAX_NODE_NAME)}...`, node.name ]
    : [ node.name ],
  [ node.name ])

  return (
    <span title={title}>{name?.toString()}</span>
  )
}

const VirtualizedTree = ({
  nodes, onSelectNode, onExpandNode, onClickNode, onDblClickNode, selectable, searchPanel = true,
  selectableWhenEmptyChildren = true, ...rest
}) => {
  const treeRenderer = useMemo(() => ({ nodes }) => (
    <VirtualizedTreeComponent nodes={nodes} onChange={empty}>
      {memo(({ node, style, ...rest }) => (
        <Item node={node} style={style}>
          <Expandable node={node} onExpandNode={onExpandNode} {...rest}>
            <Selectable
              nodes={nodes}
              node={node}
              onSelectNode={selectable ? onSelectNode : undefined}
              onClickNode={onClickNode}
              onDblClickNode={onDblClickNode}
              selectable={selectable}
              selectableWhenEmptyChildren={selectableWhenEmptyChildren}
              {...rest}
            >
              {node.name === null || node.name === 'null' || node.name === ''
                ? '(empty)'
                : (
                    <NodeLabel node={node} />
                  )
              }
            </Selectable>
          </Expandable>
        </Item>
      ))}
    </VirtualizedTreeComponent>
  ), [ onSelectNode, onExpandNode, onClickNode, onDblClickNode, selectable, selectableWhenEmptyChildren ])

  return searchPanel
    ? (
        <FilteringContainer nodes={nodes} treeRenderer={treeRenderer} {...rest} />
      )
    : treeRenderer({ nodes })
}

export default VirtualizedTree
