import { DATA_TYPES } from './common'

export const LINK_TAB_BY_DATA_TYPE = {
  [DATA_TYPES.SITES]: 'sites',
  [DATA_TYPES.BASE_STATIONS]: 'rbs',
  [DATA_TYPES.SECTORS]: 'sectors',
  [DATA_TYPES.COMPLAINTS]: 'complaints',
  [DATA_TYPES.BUSINESS_CASES]: 'business-cases',
  [DATA_TYPES.MAP_ATTRIBUTES]: 'vector-map',
}

export const TAB_KEYS = {
  PROJECTS: 'tab-projects',
  USERS: 'tab-users',
  SYNC: 'tab-sync',
  EVENT_LOG: 'tab-event-log',
}

export const LINK_TAB_CABINET = {
  [TAB_KEYS.PROJECTS]: 'projects',
  [TAB_KEYS.USERS]: 'users',
  [TAB_KEYS.SYNC]: 'sync',
  [TAB_KEYS.EVENT_LOG]: 'event-log',
}

export const PIVOT_TABS_CABINET = Object.keys(LINK_TAB_CABINET)
export const LINKS_CABINET = Object.values(LINK_TAB_CABINET)
