import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ActionButton,
  CheckboxVisibility,
  DetailsList,
  DetailsListLayoutMode,
  Icon,
  Stack,
  StackItem,
} from '@fluentui/react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import InputNumberFormat from '../../common/InputNumberFormat'
import { useConfirm } from '../../common/Confirm'
import TextFieldSearch from '../../common/TextField/TextFieldSearch'
import { DropDown } from '../../common/Dropdown'
import { IconSvg } from '../../common/icons'
import { PointerIcon } from '../../common/icons/names'
import { COMPLAINTS } from '../../../constants/access'
import { ID_NAME_FIELD_TABLE, initObjectTypes, initSearchAttributes, TYPES_SEARCH } from '../../../constants/search'
import { checkIndicateItem, selectVectorItems } from '../../../features/vector/vectorSlice'
import { selectCoordinatesFormat } from '../../../features/settings/settingsSlice'
import { geocode, setBounce } from '../../../features/geo/geoSlice'
import { selectNetwork } from '../../../features/network/networkSlice'
import { selectUserAccess } from '../../../features/login/loginSlice'
import { useHandler } from '../../../hooks'
import { empty } from '../../../utils/format'
import { ERRORS_MESSAGES, STATUS_CODES } from './constants'

import './SearchMap.css'

const ENTER_KEY = 13
const MIN_LENGTH_SEARCH = 3
export const DECIMAL_SCALE_COORDINATE = 6

// для корректного расчета размеров строки DetailList
const CELL_STYLE_PROPS = { cellLeftPadding: 24, cellRightPadding: 24 }

const searchableFieldTypes = [
  'string',
  'long',
  'double',
  'int',
]

const columnDefault = {
  key: 'column',
  name: 'attribute',
  minWidth: 50,
  maxWidth: 150,
  isRowHeader: true,
  isResizable: true,
  // isSorted: true,
  isSortedDescending: false,
  data: 'string',
  // isFiltered: true,
}

const HighlightText = ({ value = '', searchText = '', name, onShowItem }) => {
  const begin = value.toLocaleUpperCase().indexOf(searchText.toLocaleUpperCase())
  const end = begin + searchText.length
  return (
    <div className={'item-search'}>
      <div className={'info-search'} title={value}>
        {name && (
          <span>{`${name}: `}</span>
        )}
        <span>{value.slice(0, begin)}</span>
        <span className={'highlight'}>{value.slice(begin, end)}</span>
        <span>{value.slice(end)}</span>
      </div>
      <ActionButton onClick={onShowItem}>
        <IconSvg name={PointerIcon} />
      </ActionButton>
    </div>
  )
}

const onRenderElement = (itemData, index, props) => {
  const { searchText, nameIdx, fieldIdx } = props
  if (Array.isArray(itemData) && searchText) {
    const text = `${itemData[fieldIdx] ?? ''}`
    const name = itemData[nameIdx]
    return (
      <HighlightText
        value={text}
        name={name}
        searchText={searchText}
      />
    )
  }
  return null
}

const onRenderAddress = (item, index, props) => {
  const { searchText } = props
  return (
    <HighlightText
      value={item?.formatted_address}
      searchText={searchText}
    />
  )
}

const columnAddress = {
  key: 'column',
  minWidth: 50,
  maxWidth: 250,
  isRowHeader: true,
  isResizable: true,
  isSortedDescending: false,
  data: 'string',
  name: 'Addresses',
  fieldName: 'formatted_address',
  onRender: onRenderAddress,
  // isSorted: true,
  // isFiltered: true,
}
const itemsNoAddress = [
  {
    warning: true,
    disabled: true,
    formatted_address: 'Address not found',
  },
]

const getIndexFieldFromId = (fields, name) => {
  if (!Array.isArray(fields)) {
    return -1
  }
  return fields.findIndex((field) => (field.id?.split('.')?.pop() === name))
}

const isLatLng = (field) => {
  const name = field.id?.split('.')?.pop()
  return name === 'lat' || name === 'lng' || name === 'lon'
}

const getAttributesTable = (fullAttributes) => {
  if (!Array.isArray(fullAttributes)) {
    return initSearchAttributes
  }
  const attributes = fullAttributes
    .filter((field) =>
      (searchableFieldTypes.includes(`${field.type ?? ''}`.toLowerCase()) && !isLatLng(field) && !field.hidden))
    .map((field) => ({
      key: field.id,
      text: field.label,
      value: field.id,
    }))
  const indexLat = getIndexFieldFromId(fullAttributes, 'lat')
  const indexLon = getIndexFieldFromId(fullAttributes, 'lon')
  const indexLng = indexLon === -1 ? getIndexFieldFromId(fullAttributes, 'lng') : indexLon
  const indexName = getIndexFieldFromId(fullAttributes, 'name')
  return {
    attributes,
    indexLat,
    indexLng,
    indexName,
    isLatLng: false,
  }
}

// подготовка списка атрибутов векторных карт
const getAttributesVectorMap = (vectorMap = {}) => {
  const { idFldIdx, nameFldIdx, attributes } = vectorMap
  const { fields } = attributes ?? {}
  if (!Array.isArray(fields)) {
    return initSearchAttributes
  } else {
    const attributes = fields
      .filter((field, index) =>
        (searchableFieldTypes.includes(`${field.type ?? ''}`.toLowerCase()) && index !== idFldIdx && !field.hidden && field.id !== 'id'))
      .map((field) => ({
        key: field.id,
        text: field.label,
        value: field.id,
      }))
    return {
      attributes,
      indexName: nameFldIdx,
      isLatLng: false,
    }
  }
}

const compareText = (upSearchText, item) => {
  const compare = `${item ?? ''}`
  return compare.toLocaleUpperCase().includes(upSearchText)
}

const iconStyles = { marginRight: '8px' }

const onRenderOption = (option) => {
  return (
    <div>
      {option.data && option.data.icon && (
        <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" />
      )}
      <span className={'item-text'}>{option.text}</span>
    </div>
  )
}
const INIT_DETAILS = { columns: [], items: [] }

const SearchMap = () => {
  const dispatch = useDispatch()

  const { renderConfirm, confirm } = useConfirm()
  const [ selectObjectType, setObjectType ] = useState()
  const [ attributesObject, setAttributesObject ] = useState(initSearchAttributes)
  const [ attribute, setAttribute ] = useState()
  const [ searchText, setSearchText ] = useState('')
  const [ searchLat, setSearchLat ] = useState('')
  const [ searchLng, setSearchLng ] = useState('')
  const [ details, setDetails ] = useState(INIT_DETAILS)
  const [ addresses, setAddresses ] = useState({ columns: [], items: [] })
  const [ objectTypesJoint, setObjectTypesJoint ] = useState([])

  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const vectorItems = useSelector((selectVectorItems), shallowEqual)
  const coordinatesFormat = useSelector(selectCoordinatesFormat, shallowEqual)
  const network = useSelector(selectNetwork)

  const objectTypes = useMemo(() => {
    return userAccess[COMPLAINTS]
      ? initObjectTypes
      : initObjectTypes.filter((item) => item.key !== 'complaints')
  }, [ userAccess ])

  const decimalScaleCoordinate = useMemo(() => coordinatesFormat.coordinatesPrecision ?? DECIMAL_SCALE_COORDINATE,
    [ coordinatesFormat ])

  const { attributes, isLatLng } = useMemo(() => attributesObject ?? {}, [ attributesObject ])

  const isSearchCoordinate = useMemo(() => selectObjectType?.type === TYPES_SEARCH.COORDINATES, [ selectObjectType ])
  const isSearchTable = useMemo(() => selectObjectType?.type === TYPES_SEARCH.TABLE, [ selectObjectType ])
  const isSearchAddresses = useMemo(() => selectObjectType?.type === TYPES_SEARCH.ADDRESSES, [ selectObjectType ])
  const isSearchVector = useMemo(() => selectObjectType?.type === TYPES_SEARCH.VECTOR_MAP, [ selectObjectType ])

  const showSearchObjectOnMap = useCallback(({ lat, lng }) => {
    if (lat && lng) {
      dispatch(setBounce({ lat, lng }))
    }
  }, [ dispatch ])

  const onChangeObjectType = useCallback((_, option) => {
    setObjectType(option)
    setSearchText('')
  }, [])

  const onChangeAttribute = useCallback((_, attribute) => {
    setAttribute(attribute)
  }, [])

  const onChangedText = useCallback((_, value) => {
    setSearchText(value)
  }, [])

  // сброс поиска при смене объекта поиска или атрибута аттрибута
  useEffect(() => {
    setDetails(INIT_DETAILS)
  }, [ attribute, selectObjectType ])

  // подготовка списка типа объектов поиска
  useEffect(() => {
    const objectsJoint = objectTypes ? [ ...objectTypes ] : []
    if (Array.isArray(vectorItems)) {
      vectorItems.forEach((map) => {
        objectsJoint.push({
          key: map.id,
          text: map.name,
          value: map.id,
          type: TYPES_SEARCH.VECTOR_MAP,
          disabled: !map.state?.selected,
          data: {
            icon: 'VisioLogo', // Shapes VisioLogoPuzzle
          },
        })
      })
    }
    setObjectTypesJoint(objectsJoint)
  }, [ objectTypes, vectorItems ])

  // установка объекта по умолчанию
  useEffect(() => {
    if (Array.isArray(objectTypesJoint)) {
      setObjectType(objectTypesJoint[0])
    }
  }, [ objectTypesJoint ])

  // подготовка списка аттрибутов по выбранному объекту
  useEffect(() => {
    if (selectObjectType?.type === TYPES_SEARCH.TABLE) { // таблицы
      const table = network?.[selectObjectType.key]
      const attributesObject = getAttributesTable(table?.fields)
      setAttributesObject(attributesObject)
      if (!attributesObject.attributes?.find((item) => item.key === attribute?.key)) {
        setAttribute(attributesObject.attributes?.[0])
      }
    } else if (selectObjectType?.type === TYPES_SEARCH.VECTOR_MAP) { // векторная карта
      const vectorMap = vectorItems.find((vector) => (vector.id === selectObjectType.value))
      if (vectorMap) {
        const attributesObject = getAttributesVectorMap(vectorMap)
        setAttributesObject(attributesObject)
        if (!attributesObject.attributes?.find((item) => item.key === attribute?.key)) {
          setAttribute(attributesObject.attributes?.[0])
        }
      } else {
        setAttributesObject(null)
        setAttribute(null)
      }
    } else {
      setAttributesObject(null)
      setAttribute(null)
    }
  }, [ selectObjectType, network, vectorItems, attribute ])

  // обработка выбора найденого элемента таблицы или карты
  const onActiveItemChanged = useCallback((item, index, p) => {
    if (!item || !p?.relatedTarget) {
      return
    }
    if (selectObjectType?.type === TYPES_SEARCH.TABLE) {
      const { indexLat, indexLng, isLatLng } = attributesObject
      if (isLatLng) {
        const { lat, lng, lon } = item
        showSearchObjectOnMap({ lat, lng: lng ?? lon })
      } else {
        const lat = item[indexLat]
        const lng = item[indexLng]
        showSearchObjectOnMap({ lat, lng })
      }
    } else if (selectObjectType?.type === TYPES_SEARCH.VECTOR_MAP) { // выделение объекта векторной карты
      const vectorMap = vectorItems.find((vector) => (vector.id === selectObjectType.value))
      const { idFldIdx, children } = vectorMap ?? {}
      const idVector = item[idFldIdx]
      if (!Array.isArray(children) || !idVector) {
        return
      }
      const vectorIdx = children.findIndex((vector) => (vector.id === idVector))
      if (vectorIdx + 1) {
        dispatch(checkIndicateItem({ vectorMapId: selectObjectType?.value, childrenId: idVector }))
      }
    }
  }, [ selectObjectType, attributesObject, showSearchObjectOnMap, dispatch, vectorItems ])

  const onAddressInvoked = useCallback((item) => {
    if (item?.geometry?.location) {
      showSearchObjectOnMap(item?.geometry?.location)
    }
  }, [ showSearchObjectOnMap ])

  const onAddressChanged = useCallback((item) => {
    if (item?.geometry?.location) {
      showSearchObjectOnMap(item?.geometry?.location)
    }
  }, [ showSearchObjectOnMap ])

  // поиск адреса на сервисе google
  const searchAddresses = useCallback(async () => {
    const query = (searchText || '').trim()
    if (query) {
      const result = await dispatch(geocode(query))
      const columnData = { ...columnAddress, searchText }
      switch (result?.status) {
        case STATUS_CODES.OK: {
          if (!Array.isArray(result?.results) || result.results.length === 0) {
            return setAddresses({ columns: [ columnAddress ], items: itemsNoAddress })
          }
          return setAddresses({ columns: [ columnData ], items: result.results })
        }
        case STATUS_CODES.ZERO_RESULTS:
          return setAddresses({ columns: [ columnAddress ], items: itemsNoAddress })
        default: {
          const messages = [
            result?.emptyApiKey
              ? 'No Google API Key has been provided, contact system administrator'
              : ERRORS_MESSAGES[result?.status] ?? `The unknown service Error: ${result?.status}`,
            result?.error_message ?? [],
          ].flat(Infinity)
          confirm(null,
            {
              title: 'Geocoding API. Search addresses',
              messages,
              textNoBtn: 'Continue',
            },
          )
        }
      }
      return
    }
    confirm(null,
      {
        title: 'Search addresses',
        messages: 'The service is temporarily unavailable',
        textNoBtn: 'Continue',
      },
    )
  }, [ searchText, confirm, dispatch ])

  const onItemInvoked = useHandler((item, index) => {
    onActiveItemChanged(item, index, { relatedTarget: true })
  })

  // фильтрация записей по тексту для таблиц и карт
  const filteredItems = useCallback((searchText) => {
    if (!searchText || !attribute) {
      setDetails(INIT_DETAILS)
      return
    }
    const upSearchText = searchText.toLocaleUpperCase()

    if (selectObjectType?.type === TYPES_SEARCH.TABLE) {
      const table = network?.[selectObjectType.key]
      if (!table?.fields || !table?.list?.getList) {
        setDetails(INIT_DETAILS)
        return
      }
      const fieldIdx = table.fields.findIndex((field) => (field.id === attribute.value))
      if (fieldIdx + 1) {
        // индекс поля содержащее имя записи
        const nameIdx = table.fields.findIndex((field) => (field.id === ID_NAME_FIELD_TABLE[selectObjectType.key]))

        const list = table.list.getList()
        const items = list?.filter
          ? list.filter((item) => (compareText(upSearchText, item[fieldIdx])))
          : []
        const columns = [ {
          ...columnDefault,
          name: attribute.value.split('.')?.pop(),
          fieldIdx,
          nameIdx,
          searchText,
          onRender: onRenderElement,
        } ]
        return setDetails({ columns, items })
      }
    } else if (selectObjectType?.type === TYPES_SEARCH.VECTOR_MAP) {
      const vectorMap = vectorItems.find((vector) => (vector.id === selectObjectType.value))
      if (vectorMap) {
        const {
          nameFldIdx,
          attributes: { fields, data, list },
        } = vectorMap
        const dataInfo = list?.getList ? list.getList() : data
        if (Array.isArray(dataInfo) && Array.isArray(fields)) {
          const fieldIdx = fields.findIndex((field) => (field.id === attribute.value))
          if (fieldIdx + 1) {
            const items = dataInfo.filter((item) => (compareText(upSearchText, item[fieldIdx])))

            const columns = [ {
              ...columnDefault,
              name: attribute.value.split('.')?.pop(),
              fieldIdx,
              nameIdx: nameFldIdx,
              searchText,
              onRender: onRenderElement,
            } ]
            return setDetails({ columns, items })
          }
        }
      }
    }
    return setDetails(INIT_DETAILS)
  }, [
    attribute,
    network,
    selectObjectType,
    vectorItems,
  ])

  const handleSearchKeyDown = useHandler((event) => {
    if (event.keyCode === ENTER_KEY) {
      if (selectObjectType?.type === TYPES_SEARCH.VECTOR_MAP || selectObjectType?.type === TYPES_SEARCH.TABLE) {
        filteredItems(searchText)
      } else if (selectObjectType?.type === TYPES_SEARCH.ADDRESSES) {
        searchAddresses()
      }
      event.target.focus()
    }
  })

  const { LONGITUDE_RANGE, LATITUDE_RANGE } = useMemo(() => {
    const fields = network?.sites?.fields
    const indexLat = getIndexFieldFromId(fields, 'lat')
    const indexLon = getIndexFieldFromId(fields, 'lon')

    const result = {
      LATITUDE_RANGE: {
        min: fields?.[indexLat]?.min ?? -90,
        max: fields?.[indexLat]?.max ?? 90,
      },
      LONGITUDE_RANGE: {
        min: fields?.[indexLon]?.min ?? -180,
        max: fields?.[indexLon]?.max ?? 180,
      },
    }

    return result
  }, [ network?.sites?.fields ])

  const toCoordinate = useHandler(() => {
    if (searchLat !== '' &&
      searchLng !== '' &&
      searchLat <= LATITUDE_RANGE.max &&
      searchLat >= LATITUDE_RANGE.min &&
      searchLng <= LONGITUDE_RANGE.max &&
      searchLng >= LONGITUDE_RANGE.min
    ) {
      showSearchObjectOnMap({ lat: searchLat, lng: searchLng })
    }
  })

  // автопоиск при вводе MIN_LENGTH_SEARCH символов в поле поиска
  useEffect(() => {
    if (!searchText || searchText?.length < MIN_LENGTH_SEARCH) {
      return
    }
    filteredItems(searchText)
  }, [ filteredItems, searchText ])

  return (
    <Stack
      tokens={{ childrenGap: 24, maxWidth: '100%' }}
      verticalFill={true}
      grow={1}
      shrink={1}
    >
      <Stack id='searchForm' tokens={{ childrenGap: isSearchCoordinate ? 12 : 24, padding: '24px 24px 0 24px' }}>
      {(isSearchTable || isSearchAddresses || isSearchVector) && (
        <TextFieldSearch
          maxLength={255}
          value={searchText}
          onChange={onChangedText}
          onKeyDown={handleSearchKeyDown}
          onPaste={empty}
          disabled={!attributes?.length && !isSearchAddresses}
        />
      )}
      <DropDown
        label="Object type"
        selectedKey={selectObjectType?.key}
        options={objectTypesJoint}
        onChange={onChangeObjectType}
        onRenderOption={onRenderOption}
      />
      {(isSearchTable || isSearchVector) && (
        <DropDown
          label={'Attribute'}
          selectedKey={attribute?.key}
          options={attributes}
          onChange={onChangeAttribute}
          disabled={isLatLng}
        />
      )}
      {isSearchCoordinate && (
        <>
          <InputNumberFormat
            label="Longitude"
            max={LONGITUDE_RANGE.max}
            min={LONGITUDE_RANGE.min}
            onChange={setSearchLng}
            onEnter={toCoordinate}
            value={searchLng}
            decimalScale={decimalScaleCoordinate}
            fullWidth
          />
          <InputNumberFormat
            label="Latitude"
            max={LATITUDE_RANGE.max}
            min={LATITUDE_RANGE.min}
            onChange={setSearchLat}
            onEnter={toCoordinate}
            value={searchLat}
            decimalScale={decimalScaleCoordinate}
            fullWidth
          />
        </>
      )}
      </Stack>
      <StackItem className={'section-title'}>
        Result
      </StackItem>
      <StackItem
        data-is-scrollable="true"
        className={'scrollable'}
        style={{ marginTop: 0 }}
      >
        {(isSearchTable || isSearchVector) && (
          <DetailsList
            className={'dl-search'}
            cellStyleProps={CELL_STYLE_PROPS}
            compact={true}
            getKey={(item) => (item.id)}
            items={details?.items ?? []}
            columns={details?.columns}
            setKey="set"
            selectionPreservedOnEmptyClick={true}
            isHeaderVisible={false}
            onItemInvoked={onItemInvoked}
            onActiveItemChanged={onActiveItemChanged}
            checkboxVisibility={CheckboxVisibility.hidden}
          />
        )}
        {isSearchAddresses && (
          <DetailsList
            className={'dl-search'}
            cellStyleProps={CELL_STYLE_PROPS}
            compact={true}
            getKey={(item) => (item.place_id)}
            items={addresses?.items ?? []}
            columns={addresses?.columns}
            layoutMode={DetailsListLayoutMode.justified}
            setKey="set"
            selectionPreservedOnEmptyClick={true}
            isHeaderVisible={false}
            onItemInvoked={onAddressInvoked}
            onActiveItemChanged={onAddressChanged}
            checkboxVisibility={CheckboxVisibility.hidden}
          />
        )}
        {renderConfirm()}
      </StackItem>
      <StackItem className={'medium-margin-top'}> </StackItem>
    </Stack>
  )
}

export default SearchMap
