import {
  SITE_NAME_FIELD,
  SITE_DISTRICT_FIELD,
  SITE_REGION_FIELD,
  SITE_POLYGON_FIELD,
  SITE_CITY_FIELD,
  SITE_ADDRESS_FIELD,
  SITE_KOATOO_ALL_CITIES_FIELD,
  SITE_KOATOO_MAIN_CITIES_FIELD,
  SITE_KOATOO_DISTRICTS_FIELD,
  SITE_NUMERAL1_FIELD,
  SITE_NUMERAL2_FIELD,
  SITE_NUMERAL3_FIELD,
  SITE_NUMERAL4_FIELD,
  SITE_NUMERAL5_FIELD,
  RBS_SITE_NAME_FIELD,
  RBS_NAME_FIELD,
  RBS_TECHNOLOGY_FIELD,
  RBS_NUMERAL1_FIELD,
  RBS_NUMERAL2_FIELD,
  RBS_NUMERAL3_FIELD,
  RBS_NUMERAL4_FIELD,
  RBS_NUMERAL5_FIELD,
  SECTOR_SITE_NAME_FIELD,
  SECTOR_RBS_NAME_FIELD,
  SECTOR_NAME_FIELD,
  SECTOR_BANDWIDTH_FIELD,
  SECTOR_NUMERAL1_FIELD,
  SECTOR_NUMERAL2_FIELD,
  SECTOR_NUMERAL3_FIELD,
  SECTOR_NUMERAL4_FIELD,
  SECTOR_NUMERAL5_FIELD,
  SECTOR_SUBSCRIBERS_WITH_2G,
  SECTOR_SUBSCRIBERS_WITH_3G,
  SECTOR_HSDPA_USERS,
  SECTOR_SUBSCRIBERS_WITH_4G_WITHOUT_USIM,
  SECTOR_SUBSCRIBERS_WITH_4G,
  SECTOR_SUBSCRIBERS_WITH_VOLTE,
  SECTOR_LTE_ACTIVE_DATA_USERS,
  SECTOR_USER_THROUGHPUT,
  SECTOR_PRB_UTILIZATION,
  SECTOR_TIMING_ADVANCE,
  COMPLAINT_NAME_FIELD,
  COMPLAINT_DATE_FIELD,
} from '../../constants/network'
import { HISTORICAL_DATA_TYPE } from '../../features/historicalData/historicalDataSlice'

export const SITE_FIELDS = [
  SITE_DISTRICT_FIELD,
  SITE_REGION_FIELD,
  SITE_POLYGON_FIELD,
  SITE_CITY_FIELD,
  SITE_ADDRESS_FIELD,
  SITE_KOATOO_ALL_CITIES_FIELD,
  SITE_KOATOO_MAIN_CITIES_FIELD,
  SITE_KOATOO_DISTRICTS_FIELD,
]

export const REQUIRED_SITE_FIELDS = [
  SITE_NAME_FIELD,
]

export const REQUIRED_SITE_FIELDS_FOR_REGION = [
  SITE_REGION_FIELD,
]

export const REQUIRED_SITE_FIELDS_FOR_POLYGON = [
  SITE_POLYGON_FIELD,
]

export const IGNORE_SITE_FIELDS = [
  SITE_NUMERAL1_FIELD,
  SITE_NUMERAL2_FIELD,
  SITE_NUMERAL3_FIELD,
  SITE_NUMERAL4_FIELD,
  SITE_NUMERAL5_FIELD,
]

export const RBS_FIELDS = [
  RBS_SITE_NAME_FIELD,
  RBS_TECHNOLOGY_FIELD,
]

export const REQUIRED_RBS_FIELDS = [
  RBS_NAME_FIELD,
]

export const IGNORE_RBS_FIELDS = [
  RBS_NUMERAL1_FIELD,
  RBS_NUMERAL2_FIELD,
  RBS_NUMERAL3_FIELD,
  RBS_NUMERAL4_FIELD,
  RBS_NUMERAL5_FIELD,
]

export const SECTOR_FIELDS = [
  SECTOR_SITE_NAME_FIELD,
  SECTOR_RBS_NAME_FIELD,
]

export const REQUIRED_SECTOR_FIELDS = [
  SECTOR_NAME_FIELD,
]

export const IGNORE_SECTOR_FIELDS = [
  SECTOR_BANDWIDTH_FIELD,
  SECTOR_NUMERAL1_FIELD,
  SECTOR_NUMERAL2_FIELD,
  SECTOR_NUMERAL3_FIELD,
  SECTOR_NUMERAL4_FIELD,
  SECTOR_NUMERAL5_FIELD,
]

export const REQUIRED_COMPLAINT_FIELDS = [
  COMPLAINT_NAME_FIELD,
  COMPLAINT_DATE_FIELD,
]

export const LIMITED_VOLUME_TYPES = [
  HISTORICAL_DATA_TYPE.site,
  HISTORICAL_DATA_TYPE.rbs,
  HISTORICAL_DATA_TYPE.sector,
  HISTORICAL_DATA_TYPE.complaints,
]

export const AGGREGATE_AVERAGE = [
  SECTOR_SUBSCRIBERS_WITH_2G,
  SECTOR_SUBSCRIBERS_WITH_3G,
  SECTOR_HSDPA_USERS,
  SECTOR_SUBSCRIBERS_WITH_4G_WITHOUT_USIM,
  SECTOR_SUBSCRIBERS_WITH_4G,
  SECTOR_SUBSCRIBERS_WITH_VOLTE,
  SECTOR_LTE_ACTIVE_DATA_USERS,
  SECTOR_USER_THROUGHPUT,
  SECTOR_PRB_UTILIZATION,
  SECTOR_TIMING_ADVANCE,
]
