import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DropdownMenuItemType } from '@fluentui/react'
import Dropdown from '../../common/Dropdown'
import { SECTOR_TYPES_UNIQUE } from '../../../constants/network'
import { selectVectorItems } from '../../../features/vector/vectorSlice'
import { selectUserAccess } from '../../../features/login/loginSlice'
import { COMPLAINTS } from '../../../constants/access'
import { selectSettingsComplaintsGrouping } from '../../../features/settings/settingsSlice'
import { ELEMENT_TYPES } from './constants'

const staticElements = [
  { key: 'sites-header', text: 'Sites', itemType: DropdownMenuItemType.Header },
  { key: 'sites', text: 'Sites', type: ELEMENT_TYPES.SITES },
  { key: 'sectors', text: 'Sectors', itemType: DropdownMenuItemType.Header },
  ...SECTOR_TYPES_UNIQUE.map((type) => (
    { key: type, text: type, type: ELEMENT_TYPES.SECTORS }
  )),
]

const complaintsOptions = [
  {
    key: 'complaints-header',
    text: 'Complaints',
    itemType: DropdownMenuItemType.Header,
  },
  {
    key: 'complaints',
    text: 'Complaints',
    type: ELEMENT_TYPES.COMPLAINTS,
  },
]

const vectorHeaderOption = {
  key: ELEMENT_TYPES.VECTOR_MAPS,
  text: 'Vector Maps',
  itemType: DropdownMenuItemType.Header,
}

const Elements = ({ selected, onChange }) => {
  const vectorMaps = useSelector(selectVectorItems)

  const userAccess = useSelector(selectUserAccess)
  const complaintsGrouping = useSelector(selectSettingsComplaintsGrouping)

  const elementOptions = useMemo(() => {
    const vectorElements = !Array.isArray(vectorMaps)
      ? []
      : vectorMaps.map((vectorMap) => ({ key: vectorMap.id, text: vectorMap.name, type: ELEMENT_TYPES.VECTOR_MAPS }))
    const result = [ ...staticElements ]
    if (userAccess[COMPLAINTS] && !complaintsGrouping) {
      result.push(...complaintsOptions)
    }
    if (vectorElements.length) {
      result.push(vectorHeaderOption)
      result.push(...vectorElements)
    }
    return result
  }, [ vectorMaps, userAccess, complaintsGrouping ])

  return (
    <Dropdown
      placeholder="Select an element"
      label="Element"
      options={elementOptions}
      selectedKey={selected?.key}
      onChange={onChange}
    />
  )
}

export default Elements
