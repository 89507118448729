export const KEYS = {
  MAP: 'MAP',
  SITES: 'SITES',
  RBS: 'RBS',
  SECTORS: 'SECTORS',
  COMPLAINTS: 'COMPLAINTS',
  BUSINESS_CASES: 'BUSINESS_CASES',
  CREATE_BUSINESS_CASE: 'CREATE_BUSINESS_CASE',
  MACRO_INDICATORS: 'MACRO_INDICATORS',
  TRF: 'TRF',
  CABINET: 'CABINET',
  _CABINET: '_CABINET',
  VECTOR_MAPS: 'VECTOR_MAPS',
  TASK_LOG: 'TASK_LOG',
  NOTIFICATIONS: 'NOTIFICATIONS',
}

export const ROUTES = {
  [KEYS.MAP]: {
    route: 'map',
    label: 'Map',
  },
  [KEYS.SITES]: {
    route: 'sites',
    label: 'Sites',
  },
  [KEYS.RBS]: {
    route: 'rbs',
    label: 'RBS',
  },
  [KEYS.SECTORS]: {
    route: 'sectors',
    label: 'Sectors',
  },
  [KEYS.COMPLAINTS]: {
    route: 'complaints',
    label: 'Complaints',
  },
  [KEYS.BUSINESS_CASES]: {
    route: 'business-cases',
    label: 'Business Cases',
  },
  [KEYS.MACRO_INDICATORS]: {
    route: 'macro-indicators',
    label: 'Macro Indicators',
  },
  [KEYS.TRF]: {
    route: 'trf',
    label: 'TRF',
  },
  [KEYS.VECTOR_MAPS]: {
    route: 'vector-map',
    label: 'Vector Maps',
  },
  [KEYS.CABINET]: {
    route: '/cabinet',
    label: 'Cabinet',
  },
  [KEYS._CABINET]: {
    route: 'cabinet',
    label: 'Cabinet',
  },
  [KEYS.TASK_LOG]: {
    route: 'task-log',
    label: 'Task Log',
  },
  [KEYS.NOTIFICATIONS]: {
    route: 'notifications',
    label: 'Notifications',
  },
}

export const sectionByPath = (path = '') => {
  const pathParts = path.split('/')
  const section = pathParts[2]
  // const lastPart = pathParts.pop()
  for (const key of Object.keys(ROUTES)) {
    const route = ROUTES[key].route
    if (route === section || path.startsWith(route)) {
      return key
    }
  }
  return [ ...pathParts.slice(2) ].join('/')
}

export const crumbsByPath = (path = '') => {
  const pathParts = path.split('/')
  const section0 = pathParts[1]
  const section1 = pathParts[2]

  for (const key of Object.keys(ROUTES)) {
    const route = ROUTES[key].route
    // для проекта
    if (route === section1) {
      return { section: key, objectId: pathParts[3] }
    }
    // для кабинета
    if (route === section0) {
      return { section: key, subSection: section1 }
    }
  }

  return { section: null }
}
