import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Stack, StackItem } from '@fluentui/react'
import {
  selectNetworkTree, TOP_INDEX_COMPLAINTS, checkTreeItem as checkTreeItemNetwork,
} from '../../../../features/network/networkSlice'
import { SecondaryButton } from '../../../common/Button'
import ElementsTree from '../ElementsTree'

const Complaints = () => {
  const dispatch = useDispatch()

  const networkTree = useSelector(selectNetworkTree, shallowEqual)

  const elementsShown = useMemo(() => networkTree[TOP_INDEX_COMPLAINTS].state.selected, [ networkTree ])

  const toggleElements = useCallback(() => {
    dispatch(checkTreeItemNetwork([ TOP_INDEX_COMPLAINTS ]))
  }, [ dispatch ])

  return (
    <>
      <ElementsTree topIndex={TOP_INDEX_COMPLAINTS} />
      <StackItem>
        <Stack horizontal className="panel-footer">
          <SecondaryButton
            text={elementsShown ? 'Hide All Complaints' : 'Show Complaints'}
            onClick={toggleElements}
            className="full-width"
          />
        </Stack>
      </StackItem>
    </>
  )
}

export default Complaints
