import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack, StackItem } from '@fluentui/react'
import ChoiceGroup from '../../../common/ChoiceGroup'
import SpinEditField from '../../../common/SpinEditField'
import { DEFAULT_LABELING, saveSettings, selectSettings } from '../../../../features/settings/settingsSlice'
import { elementsRedraw } from '../../../../features/network/networkSlice'
import { ELEMENT_TYPES } from '../constants'
import Elements from '../Elements'
import Attributes, { NO_LABELS } from '../Attributes'
import { extractDisplaySlice } from '../utils'

import './Labels.css'

const FONT_STYLES = [
  { key: 'regular', text: 'Regular' },
  { key: 'bold', text: 'Bold' },
  { key: 'italic', text: 'Italic' },
  { key: 'underline', text: 'Underline' },
]

const BACKGROUND_STYLES = [
  { key: 'none', text: 'None' },
  { key: 'halo', text: 'Halo' },
  { key: 'opaque', text: 'Opaque' },
]

const formBundle = (element, settings) => {
  const { key, type } = element
  const partial = {
    [key]: {
      labeling: settings,
    },
  }
  const display = type === ELEMENT_TYPES.VECTOR_MAPS ? { [type]: partial } : partial
  return { display }
}

const Labels = () => {
  const dispatch = useDispatch()

  const [ element, setElement ] = useState()
  const [ attribute, setAttribute ] = useState()
  const [ current, setCurrent ] = useState()

  const settings = useSelector(selectSettings).display

  const onChangeElement = useCallback((event, element) => {
    setElement(element)
    setAttribute(extractDisplaySlice(settings, element)?.labeling?.attribute || null)
  }, [ settings ])

  useEffect(() => {
    if (settings && !element) {
      onChangeElement(null, { key: 'sites', type: 'sites' })
    }
  }, [ element, settings, onChangeElement ])

  useEffect(() => {
    if (element) {
      const current = extractDisplaySlice(settings, element)
      setCurrent(current)
      if (element.type === ELEMENT_TYPES.VECTOR_MAPS && !settings[element.type][element.key] && current?.labeling) {
        dispatch(saveSettings(formBundle(element, current.labeling)))
      }
    }
  }, [ settings, element, dispatch ])

  const [ size, font, background ] = useMemo(() => {
    const { size, font, background } = current?.labeling || {}
    return [ size, font, background ]
  }, [ current ])

  const onChangeAttribute = useCallback((event, option) => {
    const attribute = option.key === NO_LABELS ? null : option.key
    setAttribute(attribute)
    dispatch(saveSettings(formBundle(element, { ...(current?.labeling || DEFAULT_LABELING), attribute })))
    dispatch(elementsRedraw())
  }, [ dispatch, element, current ])

  const changeSize = useCallback((event, value) => {
    dispatch(saveSettings(formBundle(element, { size: value })))
    dispatch(elementsRedraw())
  }, [ dispatch, element ])

  const changeFont = useCallback((event, option) => {
    dispatch(saveSettings(formBundle(element, { font: option.key })))
    dispatch(elementsRedraw())
  }, [ dispatch, element ])

  const changeBackground = useCallback((event, option) => {
    const background = option.key
    dispatch(saveSettings(formBundle(element, { background })))
    dispatch(elementsRedraw())
  }, [ dispatch, element ])

  return (
    <Stack className="capex-map-style-labels-panel">
      <Elements
        selected={element}
        onChange={onChangeElement}
      />
      <Attributes
        element={element}
        selectedKey={attribute || NO_LABELS}
        onChange={onChangeAttribute}
      />
      {attribute && (
        <>
          <SpinEditField
            label="Font Size"
            min={1}
            max={99}
            value={size}
            onChange={changeSize}
          />
          <Stack horizontal>
            <StackItem grow>
              <ChoiceGroup
                label="Font Type"
                selectedKey={font}
                options={FONT_STYLES}
                onChange={changeFont}
              />
            </StackItem>
            <StackItem grow>
              <ChoiceGroup
                label="Background"
                selectedKey={background}
                options={BACKGROUND_STYLES}
                onChange={changeBackground}
              />
            </StackItem>
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default Labels
