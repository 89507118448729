import { ID_ICON_SECTOR, ID_ICON_SITES, ID_ICON_COMPLAINT } from '../components/Panels/Customization/constants'
import { DEGREE_DECIMAL_SIGN, ID_GEO_ZONES_COMPUTATION, ID_GEO_ZONES_FILTERING, ID_GEO_ZONES_FOCUS } from './geo'
import { SECTORS } from './network'

export const DEFAULT_COLORS = {
  [ID_GEO_ZONES_FILTERING]: '#0000ff33',
  [ID_GEO_ZONES_COMPUTATION]: '#00ff0033',
  [ID_GEO_ZONES_FOCUS]: '#ff000033',
}

export const DEFAULT_FORMATS = {
  coordinatesEncoding: DEGREE_DECIMAL_SIGN,
  coordinatesPrecision: 6,
}

export const DEFAULT_SIZES = {
  [ID_ICON_SITES]: 4,
  [ID_ICON_SECTOR]: 4,
  [ID_ICON_COMPLAINT]: 4,
}

export const DEFAULT_SECTORS_ICON = SECTORS.reduce((icons, name, index) => ({ ...icons, [name]: index }), {})

// Параметри розрахунку "больових зон"
export const PAIN_ZONE_PARAMS = {
  // "РОЗДІЛЬНА ЗДАТНІСТЬ": Агрегація скарг у кластери за радіусом в метрах
  resolution: {
    min: 50, // Мінімум шкали
    max: 50000, // Максимум шкали
    step: 100, // Крок шкали
    default: 5000, // Значення за замовчуванням
  },
  // "РЕЖИМ": Режим розрахунку "больових зон" (за щільністю, або за вагою)
  mode: 'density', // "density" - за щільністю, "weight" - за вагою
  // "ЧУТЛИВІСТЬ": Значення граничної щільності скарг (на одиницю площі), при якій кластер включається у "больову зону"
  sensitivity: {
    min: 0, // Мінімум шкали
    max: 100, // Максимум шкали
    step: 1, // Крок шкали
    default: 10, // Значення за замовчуванням
  },
  // "ВАГА": Відсоток сумарної ваги скарг в одній зоні за відношенням до сумарної ваги усіх скарг в зоні обчислення
  weight: {
    min: 0, // Мінімум шкали
    max: 100, // Максимум шкали
    step: 0.1, // Крок шкали
    default: 5, // Значення за замовчуванням
  },
}
