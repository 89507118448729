import {
  CustomizationIcon, ImportIcon, NetworkIcon, SearchIcon, ZonesIcon, PlusIcon, ExportIcon, FilterIcon, SaveIcon,
  ColumnSettingsIcon, CorrespondenceIcon, DeleteIcon, ReplaceIcon,
} from '../components/common/icons/names'
import { KEYS } from './routes'

export const ITEM_TYPE_CONTEXT_MENU = 'ITEM_TYPE_CONTEXT_MENU'
export const ITEM_TYPE_CALL_ACTION = 'ITEM_TYPE_CALL_ACTION'

const MAP_MENU = [
  {
    key: 'network',
    text: 'Network',
    iconName: NetworkIcon,
    component: 'Network',
  },
  {
    key: 'zones',
    text: 'Zones',
    iconName: ZonesIcon,
    component: 'Zones',
  },
  {
    key: 'imported-maps',
    text: 'Imported Maps',
    iconName: ImportIcon,
    component: 'ImportedMaps',
  },
  {
    key: 'customization',
    text: 'Customization',
    iconName: CustomizationIcon,
    component: 'Customization',
  },
  {
    key: 'search-map',
    text: 'Search',
    iconName: SearchIcon,
    component: 'SearchMap',
  },
]

const TABLE_MENU = [
  {
    key: 'import',
    text: 'Import',
    iconName: ImportIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'export',
    text: 'Export',
    iconName: ExportIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'filter',
    text: 'Filter',
    iconName: FilterIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'columns-table',
    text: 'Columns',
    iconName: ColumnSettingsIcon,
    component: 'Columns',
  },
  {
    key: 'replace',
    text: 'Replace',
    iconName: ReplaceIcon,
    component: 'Replace',
  },
  {
    key: 'search',
    text: 'Search',
    iconName: SearchIcon,
    component: 'Search',
  },
]

const TABLE_MENU_RO = [
  {
    key: 'export',
    text: 'Export',
    iconName: ExportIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'filter',
    text: 'Filter',
    iconName: FilterIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'columns-table',
    text: 'Columns',
    iconName: ColumnSettingsIcon,
    component: 'Columns',
  },
  {
    key: 'search',
    text: 'Search',
    iconName: SearchIcon,
  },
]

const VECTOR_MAP_MENU = [
  {
    key: 'export',
    text: 'Export',
    iconName: ExportIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'columns-table',
    text: 'Columns',
    iconName: ColumnSettingsIcon,
    component: 'Columns',
  },
]

const CASES_MENU = [
  {
    key: 'businessCasesActions-create',
    text: 'Create Case',
    iconName: PlusIcon,
    type: ITEM_TYPE_CALL_ACTION,
  },
  {
    key: 'export',
    text: 'Export',
    iconName: ExportIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'filter',
    text: 'Filter',
    iconName: FilterIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'columns-table',
    text: 'Columns',
    iconName: ColumnSettingsIcon,
    component: 'Columns',
  },
  {
    key: 'businessCasesActions-correspondence',
    text: 'Correspondence',
    iconName: CorrespondenceIcon,
    type: ITEM_TYPE_CALL_ACTION,
  },
  {
    key: 'search',
    text: 'Search',
    iconName: SearchIcon,
    component: 'Search',
  },
]

const CREATE_CASE_MENU = [
  {
    key: 'import',
    text: 'Import',
    iconName: ImportIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'export',
    text: 'Export',
    iconName: ExportIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'filter',
    text: 'Filter',
    iconName: FilterIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'columns-table',
    text: 'Columns',
    iconName: ColumnSettingsIcon,
    component: 'Columns',
  },
  {
    key: 'createBusinessCaseActions-addRow',
    text: 'Add Row',
    iconName: PlusIcon,
    type: ITEM_TYPE_CALL_ACTION,
  },
  {
    key: 'search',
    text: 'Search',
    iconName: SearchIcon,
    component: 'Search',
  },
]

const CASES_PANELS = [
  {
    key: 'bc-summary',
    text: 'Case Summary',
    component: 'BCSummaryPanel',
  },
]

const INDICATORS_MENU = [
  {
    key: 'replace',
    text: 'Replace',
    iconName: ReplaceIcon,
    component: 'Replace',
  },
  {
    key: 'search',
    text: 'Search',
    iconName: SearchIcon,
    component: 'Search',
  },
  {
    key: 'macroIndicatorActions-save',
    text: 'Save',
    iconName: SaveIcon,
    type: ITEM_TYPE_CALL_ACTION,
  },
]

const TRF_MENU = [
  {
    key: 'import',
    text: 'Import',
    iconName: ImportIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'export',
    text: 'Export',
    iconName: ExportIcon,
    type: ITEM_TYPE_CONTEXT_MENU,
  },
  {
    key: 'replace',
    text: 'Replace',
    iconName: ReplaceIcon,
    component: 'Replace',
  },
  {
    key: 'search',
    text: 'Search',
    iconName: SearchIcon,
    component: 'Search',
  },
  {
    key: 'trfActions-save',
    text: 'Save',
    iconName: SaveIcon,
    type: ITEM_TYPE_CALL_ACTION,
  },
]

const BUNDLE_EDITOR_MENU = [
  {
    key: 'edit-site',
    text: 'Site Settings',
    component: 'NetworkItemEditor',
    details: true,
  },
  {
    key: 'edit-rbs',
    text: 'RBS Settings',
    component: 'NetworkItemEditor',
    details: true,
  },
  {
    key: 'edit-sector',
    text: 'Sector Settings',
    component: 'NetworkItemEditor',
    details: true,
  },
  {
    key: 'edit-complaint',
    text: 'Complaint Settings',
    component: 'NetworkItemEditor',
    details: true,
  },
]

const TASK_LOG_MENU = [
  {
    key: 'taskLogActions-clear',
    text: 'Clear',
    iconName: DeleteIcon,
    type: ITEM_TYPE_CALL_ACTION,
  },
  {
    key: 'taskLogActions-export',
    text: 'Export',
    iconName: ExportIcon,
    type: ITEM_TYPE_CALL_ACTION,
  },
]

const NOTIFICATIONS_MENU = [
  {
    key: 'notificationsActions-removeAll',
    text: 'Remove All',
    iconName: DeleteIcon,
    type: ITEM_TYPE_CALL_ACTION,
  },
]

export const TOP_NAV_MENU = {
  [KEYS.MAP]: MAP_MENU,
  [KEYS.SITES]: TABLE_MENU,
  [KEYS.RBS]: TABLE_MENU_RO,
  [KEYS.SECTORS]: TABLE_MENU,
  [KEYS.COMPLAINTS]: TABLE_MENU_RO,
  [KEYS.BUSINESS_CASES]: CASES_MENU,
  [KEYS.CREATE_BUSINESS_CASE]: CREATE_CASE_MENU,
  [KEYS.MACRO_INDICATORS]: INDICATORS_MENU,
  [KEYS.TRF]: TRF_MENU,
  [KEYS.VECTOR_MAPS]: VECTOR_MAP_MENU,
  [KEYS.TASK_LOG]: TASK_LOG_MENU,
  [KEYS.NOTIFICATIONS]: NOTIFICATIONS_MENU,
  [undefined]: [],
}

const ALL_MENUS = [
  ...MAP_MENU, ...TABLE_MENU, ...CASES_MENU, ...CREATE_CASE_MENU, ...CASES_PANELS,
  ...INDICATORS_MENU, ...TRF_MENU, ...BUNDLE_EDITOR_MENU, ...NOTIFICATIONS_MENU,
]

export const findMenuItem = (key) => ALL_MENUS.find(({ key: itemKey }) => itemKey === key)

export const EXPORT_FORMAT = {
  KMZ: 'kmz',
  KML: 'kml',
  MIF_MID: 'mifMid',
  XLSX: 'xlsx',
  CSV: 'csv',
  TXT: 'txt',
}
