export const TYPES_SEARCH = {
  TABLE: 'table',
  COORDINATES: 'coordinates',
  ADDRESSES: 'addresses',
  VECTOR_MAP: 'vector,',
}

export const initObjectTypes = [
  { key: 'sites', text: 'Sites', value: 'sites', type: TYPES_SEARCH.TABLE },
  { key: 'sectors', text: 'Sectors', value: 'sectors', type: TYPES_SEARCH.TABLE },
  { key: 'complaints', text: 'Complaints', value: 'complaints', type: TYPES_SEARCH.TABLE },
  // { key: 'businessCases', text: 'Business-cases', value: 'businessCases', type: TYPES_SEARCH.TABLE },
  { key: 'coordinates', text: 'Coordinates', type: TYPES_SEARCH.COORDINATES },
  { key: 'addresses', text: 'Addresses', type: TYPES_SEARCH.ADDRESSES },
]

export const initSearchAttributes = {
  attributes: [],
  indexLat: -1,
  indexLng: -1,
  indexName: -1,
  isLatLng: false,
}

export const ID_NAME_FIELD_TABLE = {
  sites: 'table.network.site.name',
  sectors: 'table.network.sector.name',
  complaints: 'table.complaint.id_external',
  businessCases: 'bc.businessCases',
}
