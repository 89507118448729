import React, { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Pivot, PivotItem, Stack } from '@fluentui/react'
import { TAB_KEYS, PIVOT_TABS_CABINET, LINKS_CABINET, LINK_TAB_CABINET } from '../../constants/link'
import { selectUserAccess } from '../../features/login/loginSlice'
import { EDIT_PROJECTS, EDIT_USERS, SYNCHRONIZATION } from '../../constants/access'
import { Projects } from './Project'
import { Users } from './Users'
import { ManualSynchronization } from './ManualSynchronization'
import EventLog from './EventLog'

import './Cabinet.css'

const Cabinet = () => {
  const [ pivotItem, setPivotItem ] = useState(TAB_KEYS.PROJECTS)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const history = useHistory()
  const { tab } = useParams()

  useEffect(() => {
    if (!LINKS_CABINET.includes(tab)) {
      history.push(`/cabinet/${LINKS_CABINET[0]}`)
    }
    setPivotItem(`tab-${tab}`)
  }, [ tab, history ])

  const handlePivotItemSelect = useCallback(({ props }) => {
    const pivotTab = props?.itemKey
    if (PIVOT_TABS_CABINET.includes(pivotTab)) {
      history.push(`/cabinet/${LINK_TAB_CABINET[pivotTab]}`)
    }
  }, [ history ])

  return (
    <>
      <Stack grow className={'Cabinet'}>
        <Pivot
          selectedKey={pivotItem}
          onLinkClick={handlePivotItemSelect}
          style={{ height: '100%' }}
          styles={{ itemContainer: { height: 'calc(100% - 60px)', margin: '8px 0 0 8px' } }}
        >
          {userAccess[EDIT_PROJECTS] && (
            <PivotItem
              itemKey={TAB_KEYS.PROJECTS}
              className="full-height"
              headerText="Projects "
              itemIcon="ProjectCollection" // "ProtectedDocument"
              style={{ width: 'auto', height: 'calc(100vh - 160px)', minWidth: '840px' }}
            >
              <Projects/>
            </PivotItem>
          )}
          {userAccess[EDIT_USERS] && (
            <PivotItem
              itemKey={TAB_KEYS.USERS}
              headerText="Users"
              itemIcon="ContactList"
              style={{ height: '100%' }}
            >
              <Users/>
            </PivotItem>
          )}
          {userAccess[SYNCHRONIZATION] && (
            <PivotItem
              itemKey={TAB_KEYS.SYNC}
              headerText="Manual Synchronization"
              itemIcon="DatabaseSync"
              style={{ height: 'calc(100vh - 160px)' }}
            >
              <ManualSynchronization/>
            </PivotItem>
          )}
          {userAccess[EDIT_USERS] && (
            <PivotItem
              itemKey={TAB_KEYS.EVENT_LOG}
              headerText="Event Log"
              itemIcon="ClipboardList"
              style={{ height: 'calc(100vh - 165px)' }}
            >
              <EventLog/>
            </PivotItem>
          )}
        </Pivot>
      </Stack>
  </>
  )
}

export default Cabinet
