import { Dropdown } from '@fluentui/react'
import React from 'react'
import { IconSvg } from '../icons'
import { DropdownIcon } from '../icons/names'

const dropdownStyles = {
  dropdown: { maxWidth: 380, minWidth: 150 },
  callout: {
    calloutMain: { maxHeight: 250 },
  },
  title: {
    paddingLeft: 12,
    paddingRight: 40,
    height: 48,
    lineHeight: 42,
    background: 'var(--white)',
    border: '1px solid var(--grey-3)',
    borderRadius: 4,
  },
  caretDownWrapper: {
    paddingTop: 12,
    right: 16,
  },
  dropdownItem: {
    padding: '16px 14px',
    minHeight: 48,
    fontFamily: 'Inter',
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 20,
    letterSpacing: '0em',
    textAlign: 'left',
    color: 'var(--grey-9)',
  },
  dropdownItemSelected: {
    padding: '16px 14px',
    minHeight: 48,
  },
}

const onRenderCaretDown = () => {
  return <IconSvg name={DropdownIcon} />
}

const DropDown = ({ className = '', fullWidth, labelOverlay, label, ...props }) => {
  return labelOverlay
    ? (
      <div className={`${fullWidth ? 'full-width ' : ''}${className} capex-dropdown-overlay`}>
        <Dropdown
          className={'capex'}
          styles={dropdownStyles}
          onRenderCaretDown={onRenderCaretDown}
          {...props}
        />
        <label className={'label-overlay'}>{label}</label>
      </div>
      )
    : (
    <Dropdown
      className={`capex ${className}`}
      styles={dropdownStyles}
      onRenderCaretDown={onRenderCaretDown}
      label={label}
      {...props}
    />
      )
}

export default DropDown
