import React, { useCallback, useState } from 'react'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import { DefaultButton, PrimaryButton } from '../Button'
import { empty } from '../../../utils/format'
import { confirmStyles } from './styles'
import { confirm as t } from './constants'

const MAX_COUNT_MESSAGES = 5

export const useConfirm = (_defaultModalProps) => {
  const [ modalProps, setModalProps ] = useState(null)
  const [ defaultModalProps ] = useState(_defaultModalProps)

  const renderConfirm = useCallback(() => {
    if (modalProps === null) { return }

    const {
      args,
      messages,
      funcYesBtn = defaultModalProps?.funcYesBtn,
      funcClose = defaultModalProps?.funcClose,
      funcCancelBtn = defaultModalProps?.funcCancel,
      funcNoBtn = defaultModalProps?.funcNoBtn,
      title = defaultModalProps?.title ?? t.DEF_CONFIRM_TITLE,
      textYesBtn = defaultModalProps?.textYesBtn ?? t.yes,
      textNoBtn = defaultModalProps?.textNoBtn ?? t.no,
      textCancelBtn = defaultModalProps?.textCancelBtn ?? t.cancel,
      isLastYesBtn = defaultModalProps?.isLastYesBtn ?? false,
      // hideClose = defaultModalProps?.hideClose,
      maxCountMessage = defaultModalProps?.maxCountMessage ?? MAX_COUNT_MESSAGES,
      allowContentComponent = defaultModalProps?.allowContentComponent ?? false,
      subTitle = defaultModalProps?.subTitle,
      allowDismiss = defaultModalProps?.allowDismiss ?? true,
    } = modalProps

    const closeModal = () => setModalProps(null)

    const onYesHandler = () => {
      closeModal()
      return (typeof funcYesBtn === 'function') ? (args ? funcYesBtn(...args) : funcYesBtn()) : undefined
    }

    const onNoHandler = () => {
      closeModal()
      if (typeof funcNoBtn === 'function') { return funcNoBtn() }
    }

    const onCancelHandler = () => {
      closeModal()
      if (typeof funcCancelBtn === 'function') { return funcCancelBtn() }
    }

    const onCloseHandler = () => {
      closeModal()
      if (typeof funcClose === 'function') { return funcClose() }
    }

    return (
      <Dialog
        hidden={false}
        onDismiss={allowDismiss && onCloseHandler}
        maxWidth={'800px'}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: title,
          subText: subTitle || (Array.isArray(messages) ? undefined : messages),
        }}
        modalProps={{
          isBlocking: true,
        }}
      >
        { Array.isArray(messages)
          ? <div className={confirmStyles.body}>
            {messages.slice(0, maxCountMessage).map((message, index) => {
              if (index === maxCountMessage - 1 && maxCountMessage !== messages.length) {
                return (<p key={index} className="lastItem">...</p>)
              }
              return (<p key={index} className={!index ? 'title' : 'item'}>{message}</p>)
            })}
          </div>
          : (allowContentComponent && messages ? messages() : null)
        }
        <DialogFooter>
          {!isLastYesBtn && funcYesBtn && (
            <PrimaryButton onClick={onYesHandler} text={textYesBtn} />
          )}
          {funcNoBtn && (
            <DefaultButton onClick={onNoHandler} text={textNoBtn} />
          )}
          {funcCancelBtn && (
            <DefaultButton onClick={onCancelHandler} text={textCancelBtn} />
          )}
          {isLastYesBtn && funcYesBtn && (
            <DefaultButton onClick={onYesHandler} text={textYesBtn} />
          )}
        </DialogFooter>
      </Dialog>
    )
  }, [ modalProps, defaultModalProps ])

  return {
    renderConfirm,
    withConfirm: useCallback((
      func,
      {
        title,
        messages,
        textYesBtn,
        textNoBtn,
        isLastYesBtn,
        hideClose,
      } = {}) => (...args) =>
      setModalProps({
        funcYesBtn: func,
        title,
        messages,
        textYesBtn,
        textNoBtn,
        isLastYesBtn,
        hideClose,
        args,
      }), [ setModalProps ]),
    confirm: useCallback((
      func,
      {
        title,
        messages,
        textYesBtn,
        textNoBtn,
        isLastYesBtn,
        hideClose,
        maxCountMessage,
        allowDismiss,
      } = {}) =>
      setModalProps({
        funcYesBtn: func,
        funcNoBtn: empty,
        title,
        messages,
        textYesBtn,
        textNoBtn,
        isLastYesBtn,
        hideClose,
        maxCountMessage,
        allowDismiss,
      }), [ setModalProps ]),
    ask: useCallback((
      func,
      funcNoBtn,
      funcCancelBtn,
      {
        title,
        messages,
        textYesBtn,
        textNoBtn,
        textCancelBtn,
        isLastYesBtn,
        hideClose,
        maxCountMessages,
        allowContentComponent,
        subTitle,
      } = {}) =>
      setModalProps({
        funcYesBtn: func,
        funcNoBtn,
        funcCancelBtn,
        title,
        subTitle,
        messages,
        textYesBtn,
        textNoBtn,
        textCancelBtn,
        isLastYesBtn,
        hideClose,
        maxCountMessages,
        allowContentComponent,
      }), [ setModalProps ]),
    msg: useCallback((
      {
        title = 'Information',
        subTitle,
        messages,
        textYesBtn = 'OK',
        allowDismiss,
      } = {},
      func = empty,
    ) =>
      setModalProps({
        funcYesBtn: func,
        title,
        subTitle,
        messages,
        textYesBtn,
        allowDismiss,
      }), [ setModalProps ]),
  }
}

// export const useConfirmDelete = (defaultModalProps) => useConfirm({ title: i18n.DELETING, ...defaultModalProps })

// export const useAsk = (defaultModalProps) => useConfirm({
//   textYesBtn: i18n.YES,
//   textNoBtn: i18n.NO,
//   hideClose: true,
//   ...defaultModalProps,
// })
