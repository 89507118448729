import L from 'leaflet'
  /**
   * Checks if a single point is contained in a polyline or polygon (L.Polygon extends L.Polyline).
   * @member external:L.Polyline.contains
   * @param {L.LatLng} p A geographical point with a latitude and longitude.
   * @returns {boolean} True if the point is contained in the polygon or polyline; otherwise,
   */

  L.Polyline.prototype.contains = function (p) {
    const rectangularBounds = this.getBounds()  // It appears that this is O(1): the LatLngBounds is updated as points are added to the polygon when it is created.
    if (rectangularBounds.contains(p)) {
      const wn = this.getWindingNumber(p)
      return (wn !== 0)
    } else {
      return false;
    }
  }

  /**
   * Tests if a point is left|on|right of an infinite line.
   * @member external:L.LatLng.isLeft
   * @param {LatLng} p1 Point The reference line is defined by `this` LatLng to p1.
   * @param {LatLng} p2 The point in question.
   * @returns {Number} >0 for p2 left of the line through this point and p1,
   *          =0 for p2 on the line,
   *          <0 for p2 right of the line through this point an p1.
   */
  L.LatLng.prototype.isLeft = function (p1, p2) {
    return ((p1.lng - this.lng) * (p2.lat - this.lat) -
      (p2.lng - this.lng) * (p1.lat - this.lat));
  }

  /**
   * Test for a point in a polygon or on the bounding lines of the polygon.  The
   * coordinates (L.LatLngs) for a GeodesicPolygon are set to follow the earth's
   * curvature when the GeodesicPolygon object is created.  Since L.Polygon
   * extends L.Polyline we can use the same method for both.  Although, for
   * L.Polyline, we only get points on the line even if a collection of lines
   * appear to make a polygon.
   *
   * @member external:L.Polyline.getWindingNumber
   * @param p {L.LatLng} A point.
   * @returns {Number} The winding number (=0 only when the point is outside)
   */
  L.Polyline.prototype.getWindingNumber = function (p) { // Note that L.Polygon extends L.Polyline
    let isLeftTest
    function flatten(a) {
      return ((Array.isArray ? Array.isArray(a) : L.Util.isArray(a)) ? a.reduce( (accumulator, v) => {
          return accumulator.concat(Array.isArray(v) ? flatten(v) : v);
        }, [])
        : a)
    }

    let vertices = this.getLatLngs();
    vertices = flatten(vertices); // Flatten array of LatLngs since multi-polylines return nested array.
    // Filter out duplicate vertices.
    vertices = vertices.filter(function (v, i, array) { // remove adjacent duplicates
      return !(i > 0 && v.lat === array[i - 1].lat && v.lng === array[i - 1].lng);
    })
    let n = vertices.length
    // Note that per the algorithm, the vertices (V) must be "a vertex points of a polygon V[n+1] with V[n]=V[0]"
    if (n > 0 && !(vertices[n-1].lat === vertices[0].lat && vertices[n-1].lng === vertices[0].lng)) {
      vertices.push(vertices[0])
    }
    n = vertices.length - 1
    let wn = 0 // the winding number counter
    for (let i=0; i < n; i++) {
      isLeftTest = vertices[i].isLeft(vertices[i+1], p)
      if (isLeftTest === 0) { // If the point is on a line, we are done.
        wn = 1
        break
      } else {
        if (isLeftTest !== 0) { // If not a vertex or on line (the C++ version does not check for this)
          if (vertices[i].lat <= p.lat) {
            if (vertices[i+1].lat > p.lat) { // An upward crossing
              if (isLeftTest > 0) { // P left of edge
                wn++ // have a valid up intersect
              }
            }
          } else {
            if (vertices[i+1].lat <= p.lat) {// A downward crossing
              if (isLeftTest < 0) { // P right of edge
                wn-- // have a valid down intersect
              }
            }
          }
        } else {
          wn++
        }
      }
    }
    return wn
  }
