import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toKml from 'tokml'
import { v4 as uuid } from 'uuid'
import api from '../../api'
import Data, { buildIndex, findIndexCI } from '../network/indexing'
import { combineFilters, cropByTableFilters } from '../network/filtration'
import { findNode, invertProp } from '../../utils/tree'
import {
  ID_GEO_VECTOR, ID_GEO_ZONES_COMPUTATION, ID_GEO_ZONES_FILTERING, ID_GEO_ZONES_FOCUS, INDEX_ZONE_COMPUTATION,
  INDEX_ZONE_FILTERING, INDEX_ZONE_FOCUS, MAX_LEGEND_ITEMS, MID_MIF_EXT,
} from '../../constants/geo'
import { setZone, setZoneList, saveGeoZone, doSaveZone, deleteZones } from '../geo/geoSlice'
import { selectUserAccess } from '../login/loginSlice'
import { addTaskType, setTaskTypeCompleted, setTaskTypeFailed, TASK_TYPES } from '../taskLog'
import { filterNetwork, legendDiscreteItemValue } from '../network/networkSlice'
import { activeProjectId, ensureUserProject, forceProjectReload } from '../projects/projectsSlice'
import { EVENT_TYPE, saveEventLog, STATUS_ERROR, STATUS_PENDING, wrapByEventLog } from '../eventLog/eventLogSlice'
import { saveFilterLog } from '../filters/filters'
import { setPanel } from '../panel/panelSlice'
import { DATA_TYPES } from '../../constants/common'
import { LINK_TAB_BY_DATA_TYPE } from '../../constants/link'
import { geoJsonToMidMif } from '../../utils/convert'
import { dataToZip, kmlToFile } from '../../utils/export'
import { ELEMENT_TYPES } from '../../components/Panels/Customization/constants'
import { SPREAD_DISCRETE, SPREAD_RANGE } from '../../constants/settings'
import { EXPORT_FORMAT } from '../../constants/menus'
import { DIVIDER } from '../../constants/network'
import { DEFAULT_MAPS } from '../../constants/access'
import {
  prepareFields, ID_FIELD, NAME_FIELD1, NAME_FIELD2, COLOR_FIELD1, COLOR_FIELD2, getDataType, combineMultiPolygons,
  convertFeatureToMultiPolygon,
} from './utils'

export const TOP_INDEX_VECTOR = 2

const READY_TO_USE = 'READY_TO_USE'

const initialState = {
  id: ID_GEO_VECTOR,
  path: [ 2 ],
  children: [],
  state: {
    selected: true,
  },
  redraw: 0,
  indicate: null,
  loading: false,
}

export const findMapById = (maps, id) => maps.find((map) => map.id === id)
const findMapIndexById = (maps, id) => maps.findIndex((map) => map.id === id)

const listVectorMaps = createAsyncThunk(
  'geo/listVectorMaps',
  api.vector.listVectorMaps,
)

export const uploadVectorMap = createAsyncThunk(
  'geo/uploadVectorMap',
  api.vector.uploadVectorMap,
)

export const loadVectorMapGeoJson = createAsyncThunk(
  'geo/loadVectorMapGeoJson',
  api.vector.loadVectorMapGeoJson,
)

export const loadVectorMapGeoAttributes = createAsyncThunk(
  'geo/loadVectorMapGeoAttributes',
  api.vector.loadVectorMapGeoAttributes,
)

const doUpdateVectorMapGeoAttributes = createAsyncThunk(
  'geo/updateVectorMapGeoAttributes',
  api.vector.updateVectorMapGeoAttributes,
)

const deleteVectorMap = createAsyncThunk(
  'geo/deleteVectorMap',
  api.vector.deleteVectorMap,
)

const makeVectorMapDefault = createAsyncThunk(
  'geo/makeVectorMapDefault',
  api.vector.makeVectorMapDefault,
)

const getRoot = (state) => {
  const vectorRoot = state.vector ?? state
  return {
    children: [ null, null, vectorRoot ],
  }
}

const toggleSelection = (state, path) => {
  invertProp(state, path, 'selected', true, getRoot)
}

const prepareMap = (map, index, justImported) => {
  map.path = [ TOP_INDEX_VECTOR, index ]
  map.state = {
    selected: justImported === true || map.state?.selected !== false,
  }
}

export const vectorSlice = createSlice({
  name: 'vector',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setMaps: (state, action) => {
      state.children = action.payload
      state.state.selected = state.children.reduce((agg, map, index) => {
        prepareMap(map, index, false)
        if (map.state.selected) {
          return true
        }
        return agg
      }, false)
    },
    addMap: (state, action) => {
      const len = state.children.push(action.payload)
      prepareMap(state.children[len - 1], len - 1, true)
      state.state.selected = state.children.length > 0
    },
    deleteMap: (state, action) => {
      const id = action.payload
      const index = findMapIndexById(state.children, id)
      if (index >= 0) {
        state.children.splice(index, 1)
      }
      state.children.forEach(prepareMap)
      state.state.selected = state.children.length > 0
    },
    setJson: (state, action) => {
      const { id, json } = action.payload
      const map = findMapById(state.children, id)
      if (!map) {
        console.warn(`Map with ID ${id} not found!`)
      } else {
        map.json = json
      }
    },
    setAttributes: (state, action) => {
      const { id, attributes } = action.payload
      const map = findMapById(state.children, id)
      if (!map) {
        console.warn(`Map with ID ${id} not found!`)
      } else {
        if (!attributes) {
          console.warn(`Attributes for Map ID ${id} not loaded!`)
        } else {
          const isEditing = !!map?.editing
          map.idFldIdx = findIndexCI(attributes.fields, ID_FIELD, 0)
          map.idFld = attributes.fields[map.idFldIdx].id
          map.nameFldIdx = findIndexCI(attributes.fields, NAME_FIELD1,
            findIndexCI(attributes.fields, NAME_FIELD2, 1))
          map.nameFld = attributes.fields[map.nameFldIdx].id
          map.colorFldIdx = findIndexCI(attributes.fields, COLOR_FIELD1,
            findIndexCI(attributes.fields, COLOR_FIELD2, -1))
          map.colorFld = attributes.fields[map.colorFldIdx]?.id
          const modifiedData = {
            fields: prepareFields(attributes.fields, map.idFldIdx),
            data: attributes.data.map((row) => ([ ...row ])),
          }
          const indexes = {
            [map.nameFld]: buildIndex(modifiedData, map.nameFld),
          }
          map.attributes = {
            ...attributes,
            fields: modifiedData.fields,
            data: undefined,
            full: new Data(modifiedData.data, buildIndex(modifiedData, map.idFld), indexes),
            list: new Data(modifiedData.data),
            filters: {},
            filtering: false,
          }
          const attributesById = {}
          const typesById = {}
          let containsOnlyPoints = true
          map.json?.features.forEach((feature) => {
            typesById[feature.id] = feature.geometry.type
            if (feature.geometry.type !== 'Point') {
              containsOnlyPoints = false
            }
          })
          map.children = modifiedData.data.map((row, index) => {
            attributesById[row[map.idFldIdx]] = row
            return {
              id: row[map.idFldIdx],
              name: row[map.nameFldIdx],
              type: typesById[row[map.idFldIdx]],
              path: [ ...map.path, index ],
              state: {
                selected: map.state?.selected,
              },
            }
          })
          map.json.features.forEach((feature) => {
            feature.properties = Object.fromEntries(modifiedData.fields.map((field, fieldIndex) => [
              field.id.toLowerCase(),
              attributesById[feature.id][fieldIndex],
            ]))
          })
          map.dataType = getDataType(id)
          map.editing = isEditing
          map.containsOnlyPoints = containsOnlyPoints
          state.redraw++
        }
      }
    },
    updateFeatures: (state, action) => {
      const { id, attributes } = action.payload
      const map = findMapById(state.children, id)
      if (map) {
        // Зміна назви елемента карти
        const idFieldName = map?.attributes?.fields?.[map.nameFldIdx]?.id
        map.children.forEach((children) => {
          const childrenId = children.id
          if (attributes[childrenId] && idFieldName) {
            const name = attributes[childrenId]?.[idFieldName]
            if (name) {
              children.name = name
            }
          }
        })
        // TODO: Виправити обробку однакових id полів
        map.json.features.forEach((feature) => {
          const featureId = feature.id
          if (attributes[featureId]) {
            feature.properties = {
              id: featureId,
              ...Object.fromEntries(Object.keys(attributes[featureId]).map((key) => [
                key.toLowerCase(),
                attributes[featureId][key],
              ])),
            }
          }
        })
        state.redraw++
      }
    },
    expandTreeItem: (state, action) => {
      invertProp(state, action.payload, 'expanded', false, getRoot)
    },
    checkTreeItem: (state, action) => {
      toggleSelection(state, action.payload)
      state.redraw++
    },
    modifyMap: (state, action) => {
      const { id } = action.payload
      const map = findMapById(state.children, id)
      map.editing = true
      // updateMapMenu(map, true)
    },
    closeMap: (state, action) => {
      const { id } = action.payload
      const map = findMapById(state.children, id)
      map.editing = false
      // updateMapMenu(map, false)
    },
    setList: (state, action) => {
      const { dataType, list } = action.payload
      const map = state.children.find((map) => map.dataType === dataType)
      const idFldIdx = map?.idFldIdx
      const mapAttributes = map?.attributes
      if (mapAttributes) {
        mapAttributes.list = new Data(list)
        const ids = list.map((row) => row[idFldIdx])
        // Update features
        map.children.forEach((child) => {
          const childId = child.id
          if (!ids.includes(childId)) {
            child.state = { selected: false }
          } else {
            child.state = { selected: true }
          }
        })
        state.redraw++
      }
    },
    setFiltering: (state, action) => {
      const { dataType, filtering } = action.payload
      const mapAttributes = state.children.find((map) => map.dataType === dataType)?.attributes
      if (mapAttributes) {
        mapAttributes.filtering = filtering
      }
    },
    addFilters: (state, action) => {
      const { dataType, filter, reset } = action.payload
      const mapAttributes = state.children.find((map) => map.dataType === dataType)?.attributes
      if (mapAttributes) {
        combineFilters(mapAttributes, filter, reset)
      }
    },
    resetFilters: (state, action) => {
      const dataType = action.payload
      const mapAttributes = state.children.find((map) => map.dataType === dataType)?.attributes
      if (mapAttributes && mapAttributes.filters) {
        mapAttributes.filters = {}
      }
    },
    checkIndicateItem: (state, action) => {
      state.indicate = action.payload
    },
    makeDefault: (state, action) => {
      const { id } = action.payload
      const index = findMapIndexById(state.children, id)
      const map = state.children[index] // .find((map) => map.id === id)
      map.isDefault = true
      state.children.forEach(prepareMap)
      state.state.selected = state.children.length > 0
    },
  },
  extraReducers: (builder) => builder
    .addCase(doUpdateVectorMapGeoAttributes.rejected, (state, action) => {
      console.error(action.payload)
    })
    .addCase(makeVectorMapDefault.rejected, (state, action) => {
      console.error(action.payload)
      return false
    }),
})

export const { expandTreeItem, checkTreeItem, checkIndicateItem, closeMap } = vectorSlice.actions

const {
  setMaps, addMap, deleteMap, setJson, setAttributes, setList, setFiltering, updateFeatures, makeDefault, modifyMap,
} = vectorSlice.actions

export const prepareCollection = ({ json, children, id }) => {
  const result = json ? { ...json } : json
  if (json && children) {
    result.id = id
    result.features = json.features
      .map((feature, index) => ({
        ...feature,
        name: children?.[index].name,
      }))
      .filter((_, index) => children?.[index].state?.selected !== false)
  }
  return result
}

const formDisplayLegend = ({ spread, coloring, attribute } = {}) => {
  if (!attribute) {
    return []
  }
  switch (spread) {
    case SPREAD_RANGE: {
      return (coloring?.table || [])
        .slice(0, MAX_LEGEND_ITEMS)
        .map(({ color, description }) => ({ color, text: description })).reverse()
    }
    case SPREAD_DISCRETE: {
      return (coloring?.table || [])
        .slice(0, MAX_LEGEND_ITEMS)
        .map(({ color, value }) => ({ color, text: legendDiscreteItemValue(value, coloring) })).reverse()
    }
    default: {
      return []
    }
  }
}

export const selectVectorItems = (state) => state.vector.children

export const selectVectorState = (state) => state.vector.state

export const selectVectorJSONs = (state) => state.vector.children
  .filter((map) => (map.state?.selected !== false))

export const selectLoading = (state) => state.vector.loading

export const selectMapAttributes = (dataType) => (state) => state.vector.children
  .find((map) => map.dataType === dataType)?.attributes

export const selectVectorMapContainer = (dataType) => (state) => state.vector.children
  .find((map) => map.dataType === dataType)

export const selectMapById = (id) => (state) => state.vector.children.find((map) => map.id === id)

export const selectVectorIndicate = (state) => state.vector.indicate

export const selectVectorRedraw = (state) => state.vector.redraw

export const selectLegendVector = (state) => {
  const result = {
    content: [],
  }
  const legend = state.settings.legend
  state.vector.children
    .filter(({ id }) => legend[id])
    .forEach((vectorMap) => {
      result.content.push({
        title: vectorMap.name,
        list: formDisplayLegend(state.settings.display[ELEMENT_TYPES.VECTOR_MAPS][vectorMap.id]),
      })
    })
  return result
}

export const { addFilters, resetFilters } = vectorSlice.actions

const loadMapGeoJSONAndAttributes = (dispatch) => ({ id }) => new Promise((resolve, reject) => {
  dispatch(loadVectorMapGeoJson(id))
    .then((json) => {
      dispatch(setJson({ id, json: json.payload }))
      return dispatch(loadVectorMapGeoAttributes(id))
    })
    .then((attributes) => {
      dispatch(setAttributes({ id, attributes: attributes.payload }))
      resolve()
    })
    .catch(reject)
})

const refreshMapAttributes = (id) => async (dispatch) => {
  const result = await dispatch(loadVectorMapGeoAttributes(id))
  if (result?.payload) {
    await dispatch(setAttributes({ id, attributes: result.payload }))
  }
}

export const importVectorMap = ({ formData }) => async (dispatch) => {
  const projectId = await dispatch(ensureUserProject())
  if (projectId === '_') {
    dispatch(forceProjectReload())
    return
  }
  const files = Array.from(formData.entries()).map(([ name, file ]) => `${name} - ${file.size} bytes`).join(', ')
  return dispatch(wrapByEventLog({
    type: EVENT_TYPE.importVectorMap,
    details: `Project ID = ${projectId}; File = ${files}`,
    action: async () => {
      const result = await dispatch(uploadVectorMap({ projectId, formData }))
      const { payload } = result
      if (payload) {
        await dispatch(addMap(payload))
        await loadMapGeoJSONAndAttributes(dispatch)(payload)
      }
      return result
    },
    extractor: (map) => map.payload ? `Map ID = ${map.payload.id}; Map Name = ${map.payload.name}` : map.error,
  }))
}

export const initVectorMaps = () => async (dispatch, getState) => {
  const type = EVENT_TYPE.loadVectorMaps
  const details = (projectId = 'DEFAULT') => `Project ID = ${projectId}`
  const loader = async (projectId) => {
    const list = await dispatch(listVectorMaps(projectId))
    return (list?.payload?.data || [])
      .filter(({ status }) => status === READY_TO_USE)
      .map((item) => ({ ...item, state: { selected: false }, isDefault: !projectId }))
  }
  const extractor = (maps) => `Loaded ${maps?.length} Vector Maps`

  const ready = await dispatch(wrapByEventLog({
    type,
    details: details(),
    action: () => loader(),
    extractor,
  }))
  const projectId = activeProjectId(getState())
  if (projectId) {
    const readyP = await dispatch(wrapByEventLog({
      type,
      details: details(projectId),
      action: () => loader(projectId),
      extractor,
    }))
    ready.push(...readyP)
  }
  await dispatch(setMaps(ready))
  await Promise.all(ready.map(loadMapGeoJSONAndAttributes(dispatch)))
}

export const vectorMapCount = () => (dispatch, getState) => {
  const state = getState()
  return state.vector.children.length
}

export const menuItemClick = (path, key, history) => async (dispatch, getState) => {
  const state = getState()
  const root = getRoot(state)
  const node = findNode(root, path)
  if (root.children[node.path?.[0]]?.id === ID_GEO_VECTOR) {
    let taskType = TASK_TYPES.exportVectorMap
    const eventType = EVENT_TYPE.vectorMapExport
    const description = 'vectorMap'
    const callbackFailed = () => {
      dispatch(setTaskTypeFailed(taskType))
      dispatch(saveEventLog(eventType, null, STATUS_ERROR))
    }
    const callbackCompleted = () => {
      dispatch(setTaskTypeCompleted(taskType))
      dispatch(saveEventLog(eventType))
    }
    switch (key) {
      case 'import': {
        document.getElementById('vector-file-input').click()
        break
      }
      case 'delete': {
        if (node.isDefault && !selectUserAccess(state)[DEFAULT_MAPS]) {
          return
        }
        await dispatch(wrapByEventLog({
          type: EVENT_TYPE.deleteVectorMap,
          details: `Map ID = ${node.id}; Map Name = ${node.name}`,
          action: () => dispatch(deleteVectorMap(node.id)),
        }))
        await dispatch(deleteMap(node.id))
        break
      }
      case 'edit': {
        const { id, name, attributes, path } = node
        await dispatch(modifyMap({ id, name, attributes, path }))
        dispatch(activateVectorMap(path, history))
        dispatch(setPanel(null))
        break
      }
      case EXPORT_FORMAT.MIF_MID: {
        dispatch(addTaskType(taskType))
        const idItems = node.children.filter(({ state }) => (state?.selected)).map((item) => item.id)
        const features = node.json.features.filter((feature) => idItems.includes(feature.id))
        dispatch(saveEventLog(eventType, `: Format = ${key}; Features: ${features.length}; Map ID = ${node.id}; Map Name = ${node.name}`, STATUS_PENDING))
        const { mid, mif } = geoJsonToMidMif({ features }, node.attributes.fields)
        dataToZip([ mid, mif ], node.name, MID_MIF_EXT, 'zip', callbackFailed, callbackCompleted)
        break
      }
      case EXPORT_FORMAT.KML:
      case EXPORT_FORMAT.KMZ: {
        dispatch(addTaskType(taskType))
        const documentName = node.name ?? 'vector_map'
        const idItems = node.children.filter(({ state }) => (state?.selected)).map((item) => item.id)
        const features = node.json.features.filter((feature) => idItems.includes(feature.id))
        dispatch(saveEventLog(eventType, `: Format = ${key}; Features: ${features.length}; Map ID = ${node.id}; Map Name = ${node.name}`, STATUS_PENDING))
        const kmlData = toKml(
          { features, type: 'FeatureCollection' },
          { documentName, documentDescription: description },
        )
        if (key === EXPORT_FORMAT.KMZ) {
          dataToZip(kmlData, documentName, 'kml', 'kmz', callbackFailed, callbackCompleted)
        } else {
          try {
            kmlToFile(kmlData, `${documentName}.kml`)
            callbackCompleted()
          } catch (err) {
            callbackFailed()
            throw err
          }
        }
        break
      }
      case 'convert-filtering': {
        taskType = TASK_TYPES.convertVectorMapToFilteringZone
        dispatch(addTaskType(taskType))
        try {
          const projectId = activeProjectId(state) ?? '_'
          const convert = convertFeatureToMultiPolygon(node.attributes.fields[node.nameFldIdx].id.toLowerCase())
          const list = node.json.features.filter((_, index) => node.children?.[index].state?.selected !== false)
          const zone = combineMultiPolygons(list.map(convert).filter(Boolean))
          const id = ID_GEO_ZONES_FILTERING
          const zoneId = uuid()
          await dispatch(setZone({
            id,
            uuid: zoneId,
            zone,
            modifying: true,
          }))
          await dispatch(saveGeoZone({
            projectId,
            zoneType: id.split('-').slice(-1)[0],
            zoneList: [ { id: zoneId, coordinates: zone, properties: {} } ],
          }))
          dispatch(filterNetwork())
          dispatch(setTaskTypeCompleted(taskType))
          dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Convert to filtering zone; Map ID = ${node.id}; Map Name = ${node.name}`))
        } catch (err) {
          dispatch(setTaskTypeFailed(taskType))
          throw err
        }
        break
      }
      case 'convert-computation': {
        taskType = TASK_TYPES.convertVectorMapToComputationZone
        dispatch(addTaskType(taskType))
        try {
          const projectId = activeProjectId(state) ?? '_'
          const convert = convertFeatureToMultiPolygon(node.attributes.fields[node.nameFldIdx].id.toLowerCase())
          const list = node.json.features.filter((_, index) => node.children?.[index].state?.selected !== false)
          const zone = combineMultiPolygons(list.map(convert).filter(Boolean))
          const id = ID_GEO_ZONES_COMPUTATION
          const zoneId = uuid()
          await dispatch(setZone({
            id,
            uuid: zoneId,
            zone,
            modifying: true,
          }))
          await dispatch(saveGeoZone({
            projectId,
            zoneType: id.split('-').slice(-1)[0],
            zoneList: [ { id: zoneId, coordinates: zone, properties: {} } ],
          }))
          dispatch(setTaskTypeCompleted(taskType))
          dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Convert to computation zone; Map ID = ${node.id}; Map Name = ${node.name}`))
        } catch (err) {
          dispatch(setTaskTypeFailed(taskType))
          throw err
        }
        break
      }
      case 'convert-focus': {
        taskType = TASK_TYPES.convertVectorMapToFocusZone
        dispatch(addTaskType(taskType))
        try {
          const projectId = activeProjectId(state) ?? '_'
          const convert = convertFeatureToMultiPolygon(node.attributes.fields[node.nameFldIdx].id.toLowerCase())
          const list = node.json.features.filter((_, index) => node.children?.[index].state?.selected !== false)
          const zoneList = list.map(convert).filter(Boolean)
          const id = ID_GEO_ZONES_FOCUS
          if (state.geo.zones.children.find(({ id }) => id === ID_GEO_ZONES_FOCUS)?.children?.length > 0) {
            await dispatch(deleteZones(id))
          }
          await dispatch(setZoneList({
            id,
            zoneList,
            modifying: true,
          }))
          await dispatch(saveGeoZone({
            projectId,
            zoneType: id.split('-').slice(-1)[0],
            zoneList: zoneList.map(([ coordinates, properties, id ]) => (
              { id, coordinates, properties }
            )),
          }))
          dispatch(setTaskTypeCompleted(taskType))
          dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Convert to focus zones; Map ID = ${node.id}; Map Name = ${node.name}`))
        } catch (err) {
          dispatch(setTaskTypeFailed(taskType))
          throw err
        }
        break
      }
      case 'make-default': {
        if (!selectUserAccess(state)[DEFAULT_MAPS]) {
          return
        }
        const { id, name } = node
        const res = await dispatch(makeVectorMapDefault(id))
        if (res?.meta?.requestStatus === 'fulfilled') {
          await dispatch(makeDefault({ id }))
          dispatch(saveEventLog(EVENT_TYPE.makeVectorMapDefault, `: Map ID = ${id}, Map Name = ${name}`))
        }
        break
      }
      case 'convert-filtering-item-replace': {
        const p = [ ...path ]
        const index = p.pop()
        const node = findNode(root, p)
        const convert = convertFeatureToMultiPolygon(node.attributes.fields[node.nameFldIdx].id.toLowerCase())
        const [ zone ] = convert(node.json.features[index])
        const id = ID_GEO_ZONES_FILTERING
        const zoneId = state.geo.zones.children[INDEX_ZONE_FILTERING].uuid || uuid()
        await dispatch(setZone({
          id,
          uuid: zoneId,
          zone,
          modifying: true,
        }))
        await dispatch(doSaveZone(id))
        dispatch(filterNetwork())
        dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Convert to filtering zone (replace); Map ID = ${node.id}; Map Name = ${node.name}; Item = ${node.children[index].name}`))
        break
      }
      case 'convert-filtering-item-add': {
        const p = [ ...path ]
        const index = p.pop()
        const node = findNode(root, p)
        const convert = convertFeatureToMultiPolygon(node.attributes.fields[node.nameFldIdx].id.toLowerCase())
        const zone = combineMultiPolygons([
          [ state.geo.zones.children[INDEX_ZONE_FILTERING].zone ],
          convert(node.json.features[index]),
        ])
        const id = ID_GEO_ZONES_FILTERING
        const zoneId = state.geo.zones.children[INDEX_ZONE_FILTERING].uuid || uuid()
        await dispatch(setZone({
          id,
          uuid: zoneId,
          zone,
          modifying: true,
        }))
        await dispatch(doSaveZone(id))
        dispatch(filterNetwork())
        dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Convert to filtering zone (add); Map ID = ${node.id}; Map Name = ${node.name}; Item = ${node.children[index].name}`))
        break
      }
      case 'convert-computation-item-replace': {
        const p = [ ...path ]
        const index = p.pop()
        const node = findNode(root, p)
        const convert = convertFeatureToMultiPolygon(node.attributes.fields[node.nameFldIdx].id.toLowerCase())
        const [ zone ] = convert(node.json.features[index])
        const id = ID_GEO_ZONES_COMPUTATION
        const zoneId = state.geo.zones.children[INDEX_ZONE_COMPUTATION].uuid || uuid()
        await dispatch(setZone({
          id,
          uuid: zoneId,
          zone,
          modifying: true,
        }))
        dispatch(doSaveZone(id))
        dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Convert to computation zone (replace); Map ID = ${node.id}; Map Name = ${node.name}; Item = ${node.children[index].name}`))
        break
      }
      case 'convert-computation-item-add': {
        const p = [ ...path ]
        const index = p.pop()
        const node = findNode(root, p)
        const convert = convertFeatureToMultiPolygon(node.attributes.fields[node.nameFldIdx].id.toLowerCase())
        const zone = combineMultiPolygons([
          [ state.geo.zones.children[INDEX_ZONE_COMPUTATION].zone ],
          convert(node.json.features[index]),
        ])
        const id = ID_GEO_ZONES_COMPUTATION
        const zoneId = state.geo.zones.children[INDEX_ZONE_COMPUTATION].uuid || uuid()
        await dispatch(setZone({
          id,
          uuid: zoneId,
          zone,
          modifying: true,
        }))
        dispatch(doSaveZone(id))
        dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Convert to computation zone (add); Map ID = ${node.id}; Map Name = ${node.name}; Item = ${node.children[index].name}`))
        break
      }
      case 'convert-focus-item': {
        const p = [ ...path ]
        const index = p.pop()
        const node = findNode(root, p)
        const zoneUuid = node.json.features[index].id
        const found = state.geo.zones.children[INDEX_ZONE_FOCUS].children?.find(({ uuid }) => uuid === zoneUuid)
        if (found) {
          window.dispatchEvent(new ErrorEvent('infomessage', { message: 'Already converted' }))
          return
        }
        const convert = convertFeatureToMultiPolygon(node.attributes.fields[node.nameFldIdx].id.toLowerCase())
        const [ zone, { description } ] = convert(node.json.features[index])
        const id = ID_GEO_ZONES_FOCUS
        const zoneId = `${id}${DIVIDER}${zoneUuid}`
        await dispatch(setZone({
          id,
          zone,
          uuid: zoneUuid,
          properties: {},
          name: description,
          modifying: true,
        }))
        dispatch(doSaveZone(zoneId))
        dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Convert to focus zone; Map ID = ${node.id}; Map Name = ${node.name}; Item = ${node.children[index].name}`))
        break
      }
      default:
    }
  }
}

export const filterAllData = (dataType) => (dispatch, getState) => {
  dispatch(setFiltering({ dataType, filtering: true }))
  const currentState = getState()
  const mapAttributes = selectMapAttributes(dataType)(currentState)
  const full = mapAttributes.full
  if (!full.getCache()) {
    full.setCache(full.getList())
  }
  const filters = mapAttributes.filters
  const list = cropByTableFilters({ filters }, full.getCache())
  dispatch(setList({ dataType, list }))
  dispatch(setFiltering({ dataType, filtering: false }))
  dispatch(saveFilterLog(dataType, selectVectorMapContainer(dataType)))
}

export const activateVectorMap = (path, history) => async (dispatch, getState) => {
  const state = getState()
  const maps = selectVectorItems(state)
  const map = maps.find((map) => {
    return path[0] === map.path[0] && path[1] === map.path[1]
  })
  if (map) {
    const projectId = activeProjectId(state)
    const linkTab = LINK_TAB_BY_DATA_TYPE[DATA_TYPES.MAP_ATTRIBUTES]
    linkTab && history.push(`/${projectId ?? '_'}/${linkTab}/${map.id}`)
  }
}

export const importMapAttributes = (payload) => async (dispatch, getState) => {
  const state = getState()
  const { id: mapId, dataType, cols, rows } = payload
  const updated = {}
  let ignored = 0
  const { idFldIdx, nameFldIdx, nameFld } = selectMapById(mapId)(state)
  const attributes = selectMapAttributes(dataType)(state)
  if (attributes) {
    const full = attributes.full
    rows.forEach((row) => {
      const name = row[nameFldIdx]
      const range = full.findRangeByValue(name, nameFld)
      const obj = cols.reduce((agg, col, index) => {
        agg[col.id] = row[index]
        return agg
      }, {})
      if (range?.length > 0) {
        const id = full.getList()[range[0]][idFldIdx]
        updated[id] = obj
      } else {
        ignored++
      }
    })
  }

  const numOfUpdated = Object.keys(updated).length
  if (numOfUpdated > 0) {
    await dispatch(doUpdateVectorMapGeoAttributes({
      id: mapId,
      data: updated,
    }))
    await dispatch(refreshMapAttributes(mapId))
  }

  return {
    ignored,
    updated: numOfUpdated,
  }
}

export const updateMapAttributes = (payload) => async (dispatch, getState) => {
  const { mapId, dataType, id, field, value } = payload
  const state = getState()
  const attributes = selectMapAttributes(dataType)(state)
  const { idFldIdx } = selectMapById(mapId)(state)
  if (attributes?.fields) {
    const full = attributes.full
    const fields = attributes.fields
    const idx = full.findIndexById(id)
    const attribute = idx >= 0 && full.getList()[idx]
    if (attribute) {
      const updatedAttributes = {
        [id]: attribute.reduce((agg, param, index) => {
          const paramName = fields[index].id
          if (index === field) {
            agg[paramName] = value
          } else if (index !== idFldIdx) {
            agg[paramName] = param
          }
          return agg
        }, {}),
      }
      await dispatch(doUpdateVectorMapGeoAttributes({
        id: mapId,
        data: updatedAttributes,
      }))
      dispatch(updateFeatures({ id: mapId, attributes: updatedAttributes }))
    }
  }
}

export default vectorSlice.reducer
