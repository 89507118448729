import Handsontable from 'handsontable'
import {
  SELECTED_CLASS,
  DEFAULT_ALIGNMENT_CLASS,
  ODD_ROW_CLASS,
} from './constants'

export function addClassesToRows (TD, row, column, prop, value, cellProperties) {
  // Adding classes to `TR` just while rendering first visible `TD` element
  if (column !== 0) {
    return
  }

  const parentElement = TD.parentElement

  if (parentElement === null) {
    return
  }

  // Добавление class к выбранной строке
  if (cellProperties.instance.getDataAtRowProp(row, 'check')) {
    Handsontable.dom.addClass(parentElement, SELECTED_CLASS)
  } else {
    Handsontable.dom.removeClass(parentElement, SELECTED_CLASS)
  }

  // Добавление class к нечетным строкам
  if (row % 2 === 0) {
    Handsontable.dom.addClass(parentElement, ODD_ROW_CLASS)
  } else {
    Handsontable.dom.removeClass(parentElement, ODD_ROW_CLASS)
  }
}

// рендер checkbox в заголовок строк
// вешать на afterGetRowHeader
export function drawCheckboxInRowHeaders (row, TH) {
  const checked = !!(row >= 0 && this.getDataAtRowProp(row, 'check'))
  TH.innerHTML = `<input type="checkbox" ${checked ? 'checked' : ''} style="cursor: pointer; transform: scale(1.2)">`
  // TH.className = ``
  TH.style.verticalAlign = 'middle'
}

// вешать на afterGetColHeader с предварительной передачей объекта Map
// с классами(thLeft, thCenter, thRight ) выравнивания [[ numberCol, classAlign ], ... ]
export const alignHeaders = (headerAlignments) => (column, TH) => {
  if (column < 0) {
    return
  }
  if (TH.firstChild) {
    if (headerAlignments.has(column.toString())) {
      Handsontable.dom.removeClass(TH.firstChild, DEFAULT_ALIGNMENT_CLASS)
      Handsontable.dom.addClass(TH.firstChild, headerAlignments.get(column.toString()))
    } else {
      Handsontable.dom.addClass(TH.firstChild, DEFAULT_ALIGNMENT_CLASS)
    }
  }
}

// обработка чекбокса в заголовке строк
// необходимо создать в описании столбцов таблицы скрытый столбец "check"
// вешать на afterOnCellMouseDown
export function changeCheckboxCell (event, coords) {
  const target = event.target
  if (coords.col === -1 && target && target.nodeName === 'INPUT' && this) {
    event.preventDefault() // Handsontable will render checked/unchecked checkbox by it own.
    this.setDataAtRowProp(coords.row, 'check', !target.checked)
  }
}
