import { fetch } from './rest'

export const listRasterMaps = (projectId = '_') => fetch(`/api/project/${projectId}/geomaps?types=RASTER_USER`)

export const uploadRasterMap = ({ projectId, formData }) =>
  fetch(`/api/project/${projectId}/geomaps?type=RASTER_USER`, 'POST', formData)

export const deleteRasterMap = (id) => fetch(`/api/geomaps/${id}`, 'DELETE')

export const makeRasterMapDefault = (id) => fetch(`/api/geomaps/${id}/default`, 'PATCH')
