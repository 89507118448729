import Handsontable from 'handsontable'
import moment from 'moment'
import { USER_STATUSES } from '../../Cabinet/constants'
import { getGeneralizedRole } from '../../../constants/roles'
import {
  RBS_COMPOSITE_INDEX_FIELD,
  SECTOR_COMPOSITE_INDEX_FIELD,
  SITE_COMPOSITE_INDEX_FIELD,
} from '../../../constants/network'
import { formatNumeric } from '../../../utils/format'
import { iconSvgLine } from '../icons'

import './hotTable.css'

export const oldCellTypeBoolean = { ...Handsontable.cellTypes.getCellType('checkbox') }
export const oldCellTypeText = { ...Handsontable.cellTypes.getCellType('text') }
export const oldCellTypeDate = { ...Handsontable.cellTypes.getCellType('date') }
export const oldCellTypeNumeric = { ...Handsontable.cellTypes.getCellType('numeric') }
export const oldCellTypeDropdown = { ...Handsontable.cellTypes.getCellType('dropdown') }

export const textFieldTypes = [ 'text', 'string' ]

export const MIN_WIDTH_COLUMN = 32
export const WIDTH_COLUMN_DEFAULT = 80
export const WIDTH_SORTING = 24
export const PADDING_DEFAULT = 40

const classesType = {
  checkbox: [ 'htCenter' ],
  status: [ 'htCenter' ],
  roles: [ 'htCenter' ],
  date_time: [ 'htCenter' ],
}

export const cellClass = (readOnly, type) => {
  const classes = [ ...(classesType[type] ?? []) ]
  if (readOnly) {
    classes.push('read-only-cell')
  }
  return classes.join(' ')
}

const STATUS_CLASS = {
  ACTIVE: 'status-active',
  ACTIVE_NOT_CORRESPONDENT: 'status-active',
  APPROVED: 'status-approved',
  APPROVED_NOT_CORRESPONDENT: 'status-approved',
  CANDIDATE: 'status-candidate',
  DRAFT: 'status-draft',
  NON_ACTIVE: 'status-non-active',
  POSTPONED: 'status-postponed',
  REMOVED: 'status-removed',
  __CREATE: 'status-create',
}

const CLEAR_CLASS = Object.values(STATUS_CLASS)

export const COMPOSITE_INDICES = [
  SITE_COMPOSITE_INDEX_FIELD,
  RBS_COMPOSITE_INDEX_FIELD,
  SECTOR_COMPOSITE_INDEX_FIELD,
]

export const isValueInRange = (value, min, max) => {
  let message = null
  let isValid = true
  if ((min != null && value < min) || (max != null && value > max)) {
    isValid = false
    message = `The input value must be ${
      min != null ? `greater than ${min}` : ''
    }${
      min != null && max != null ? ' and ' : ''
    }${
      max != null ? `less than ${max}` : ''
    }`
  }
  return { isValid, message }
}

export const initCellTypes = () => {
  Handsontable.cellTypes.registerCellType('coordinate', {
    renderer: (...props) => {
      oldCellTypeNumeric.renderer(...props)
    },
  })

  Handsontable.cellTypes.registerCellType('dropdown', {
    ...oldCellTypeDropdown,
    renderer: (hotInstance, td, row, column, prop, value, cellProperties) => {
      oldCellTypeDropdown.renderer(hotInstance, td, row, column, prop, value, cellProperties)

      // для read-only ячейки удаляем стрелочку выбора значения из списка
      if (td.classList.contains('read-only-cell')) {
        const arrow = td.querySelector('div.htAutocompleteArrow')
        arrow && td.removeChild(arrow)
      }
      // прописываем соответствующий класс для ячеек статуса
      const subType = cellProperties.subType
      if (subType === 'status') {
        td.classList.remove(CLEAR_CLASS)
        const statusClass = (value?.toUpperCase && STATUS_CLASS[value.toUpperCase()]) ?? null
        if (statusClass) {
          td.classList.add(statusClass)
        }
      }
    },
  })

  Handsontable.cellTypes.registerCellType('status', {
    ...oldCellTypeBoolean,
    renderer: (hotInstance, td, row, column, prop, value, cellProperties) => {
      const {
        readOnly,
        className = '',
      } = cellProperties.instance.getCellMeta(row, column) ?? {}

      td.innerHTML = `<span>${value === USER_STATUSES.ACTIVE ? 'Active' : 'Blocked'}</span>`
      td.className = `${className}${readOnly ? ' read-only-cell' : ''}`
    },
  })

  Handsontable.cellTypes.registerCellType('roles', {
    ...oldCellTypeText,
    renderer: (hotInstance, td, row, column, prop, value, cellProperties) => {
      const {
        readOnly,
        className = '',
      } = cellProperties.instance.getCellMeta(row, column) ?? {}

      const role = Array.isArray(value) ? getGeneralizedRole(value) ?? value.join(', ') : '?'

      td.innerHTML = `<span>${role}</span>`
      td.className = `${className}${readOnly ? ' read-only-cell' : ''}`
    },
  })

  Handsontable.cellTypes.registerCellType('error', {
    ...oldCellTypeText,
    renderer: (hotInstance, td, row, column, prop, value, cellProperties) => {
      const {
        readOnly,
      } = cellProperties.instance.getCellMeta(row, column) ?? {}
      const messages = value?.error?.message ?? ''
      const message = Array.isArray(messages) ? messages.join(', ') : messages

      td.innerHTML = `<span>${message}</span>`
      td.className = cellClass(readOnly, 'error')
    },
  })

  Handsontable.cellTypes.registerCellType('datetime', {
    renderer: (hotInstance, td, row, column, prop, value, cellProperties) => {
      if (cellProperties?.readOnly) {
        td.className = (td.className ? td.className + ' ' : '') + 'read-only-cell'
      }
      td.innerHTML = `<span>${value ? new Date(`${value}`).toLocaleString().replace(' ', ' ') : ''}</span>`
      !td.classList.contains('ht-td-date') && td.classList.add('ht-td-date')
    },
  })

  Handsontable.cellTypes.registerCellType('date', {
    ...oldCellTypeDate,
    renderer: (hotInstance, td, row, column, prop, value, cellProperties) => {
      const {
        className,
        subType,
        dateFormat,
      } = cellProperties ?? {}
      oldCellTypeDate.renderer(hotInstance, td, row, column, prop, value, cellProperties)
      if (subType === 'dateTime') {
        const date = value ? new Date(`${value}`) : null
        if (dateFormat) {
          td.innerHTML = date ? `<span>${moment(date).format(dateFormat)}</span>` : '<span></span>'
        } else {
          td.innerHTML = date ? `<span>${date.toLocaleDateString()} ${date.toLocaleTimeString()}</span>` : '<span></span>'
        }
        if (className) { td.className = `${td.className ? td.className : ''} ${className}` }
      }

      !td.classList.contains('ht-td-date') && td.classList.add('ht-td-date')
      !td.classList.contains('htNoWrap') && td.classList.add('htNoWrap')
    },
  })

  Handsontable.cellTypes.registerCellType('numeric', {
    ...oldCellTypeNumeric,
    renderer: (hotInstance, td, row, column, prop, value, cellProperties) => {
      oldCellTypeNumeric.renderer(hotInstance, td, row, column, prop, value, cellProperties)
      const { size } = cellProperties
      td.innerHTML = `<span>${formatNumeric(value, size)}</span>`
    },
  })

  Handsontable.cellTypes.registerCellType('anchor', {
    renderer: (hotInstance, td, row, column, prop, value, cellProperties) => {
      if (cellProperties?.readOnly) {
        td.className = 'read-only-cell'
      }
      if (value) {
        const { id, href, label, newTab } = value
        td.innerHTML = newTab
          ? `<a id="${id}" href='${href}' target='_blank' rel='noreferrer'>${label}</a>`
          : `<a id="${id}" href='${href}' rel='noreferrer'>${label}</a>`
      } else {
        td.innerHTML = ''
      }
    },
  })

  class ScapexAutocompleteEditor extends Handsontable.editors.AutocompleteEditor {
    requestUpdateList () {
      setTimeout(() => {
        this.updateChoicesList(this.strippedChoices)
      }, 10)
    }

    open () {
      super.open()
      this.requestUpdateList()
    }

    queryChoices (query) {
      super.queryChoices(query)
      if (!query) {
        this.requestUpdateList()
      }
    }
  }

  Handsontable.cellTypes.registerCellType('scapexautocomplete', {
    renderer: Handsontable.renderers.AutocompleteRenderer,
    editor: ScapexAutocompleteEditor,
    validator: Handsontable.validators.AutocompleteValidator,
  })
}

export const getTextWidth = (text, id) => {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  context.font = getComputedStyle(document.getElementById(id ?? 'root') ?? document.getElementById('root')).font

  const metrics = context.measureText(text)
  return metrics.width
}

export const encodeSorting = (config) => config
  ? {
      columnSorting: {
        initialConfig: config,
        sortEmptyCells: true,
        indicator: true,
      },
    }
  : {}

export const getTypeCellsSelected = (selectedTab) => {
  const selected = selectedTab.map((select) => {
    if ((select[0] === -1 && select[2] === -1) || (select[1] === -1 && select[3] === -1)) {
      return null
    }
    return select.map((index) => (index === -1 ? 0 : index))
  }).filter(Boolean)
  const selectRow = selected[0]?.[0]
  const isMultiRowSelect = selected.some((select) => {
    return select[0] !== select[2] || selectRow !== select[0]
  })
  const selectCol = selected[0]?.[1]
  const isMultiColSelect = selected.some((select) => {
    return select[1] !== select[3] || selectCol !== select[1]
  })
  return {
    isOneRowSelected: !isMultiRowSelect && !!selected.length,
    isSelectedCell: !!selected.length,
    isOneColSelected: !isMultiColSelect && !!selected.length,
  }
}

// самый простой способ валидации при заполнении текстовых атрибутов
export const validatorText = (id, max, requiredNonEmpty) => (value, _callback) => {
  if (!value && requiredNonEmpty) { // Имена элементов сети не богут быть пустыми
    _callback(false)
  } else if (max && value?.length > max) { // Проверяем размер строки
    _callback(false)
  } else {
    _callback(true)
  }
}

export const stringValueToBoolean = (value) => {
  if (value === '' || value === '0' || value === '0.0' || value === '0,0' || value === 'false') {
    return false
  }
  return !!value
}

export function hiddenHeader () {
  return this.getSelectedLast()[0] !== -1
}

export function hiddenCells () {
  return this.getSelectedLast()[0] === -1
}

// hot, wrapper, row, col, prop, itemValue
export const rendererItem = (label, iconName) => (hot, TD) => {
  TD.classList.add('flexRow')
  if (typeof iconName === 'string') {
    const icon = iconSvgLine({ name: iconName })
    TD.innerHTML = `<div class="context-menu-item-icon">${icon}</div><div class="marginLS menuItem">${label}</div>`
  } else {
    TD.innerHTML = `<div class="ms-Spinner root"><div class="ms-Spinner-circle ms-Spinner--small circle"></div></div><div class="marginLS menuItem">${label}</div>`
  }
  return TD
}
