import { Checkbox, DetailsHeader, Stack } from '@fluentui/react'
import React, { useCallback } from 'react'
import { IconButton } from '@fluentui/react/lib/Button'
import { Label } from '@fluentui/react/lib/Label'
import { COLUMNS_UPDATE } from '../../../constants/updateDB'

// const onSorted = (columns, column) => {
//   const newColumns = columns.slice()
//   const currColumn = newColumns.filter((currCol) => (column.key === currCol.key)[0])
//   newColumns.forEach((newCol) => {
//     if (newCol === currColumn) {
//       currColumn.isSortedDescending = column.isSortedDescending
//       currColumn.isSorted = column.isSorted
//     } else {
//       newCol.isSorted = newCol.isSorted === true ? false : newCol.isSorted
//     }
//   })
//   return newColumns
// }

const titleSorting = {
  Descending: 'Z-A',
  Ascending: 'A-Z',
  Sort: 'No sorting',
}

const TH = ({ column, changeCheckBox, changeSorting, checkBoxDisabled }) => {
  const {
    isSorted,
    isSortedDescending,
    name,
    data,
    title: titleColumn,
  } = column ?? {}

  const {
    isIndeterminate,
    isChecked,
  } = data ?? {}

  const iconName = isSorted === true
    ? (isSortedDescending ? 'Descending' : 'Ascending')
    : isSorted === false ? 'Sort' : null
  const title = titleSorting[iconName] ?? ''

  const onChangeHandler = useCallback(() => {
    // сменить состояние чекбокса на противопожожный
    changeCheckBox && changeCheckBox(column, !isChecked)
  }, [ changeCheckBox, column, isChecked ])

  const onSortingHandler = useCallback(() => {
    changeSorting && changeSorting(column)
  }, [ changeSorting, column ])

  return (
    <Stack
      horizontal
      style={{ alignItems: 'center', height: '100%' }}
      title={titleColumn}
    >
      {isChecked != null
        ? (
            <Checkbox
              className={'align-center'}
              indeterminate={isIndeterminate}
              checked={isChecked}
              label={name}
              onChange={onChangeHandler}
              disabled={checkBoxDisabled}
            />
          )
        : (
            <Label>{name}</Label>
          )
      }
      <IconButton
        title={title}
        iconProps={{ iconName }}
        onClick={onSortingHandler}
      />
    </Stack>
  )
}

const Header = ({ props, changeCheckBox, changeSorting, checkBoxDisabled, items }) => {
  const renderColumnHeaderTooltip = useCallback((props, ev) => {
    const filtered = items.filter((item) => item[props?.column.fieldName] === null || (
      props?.column.fieldName === COLUMNS_UPDATE.ORIGINAL_VALUE &&
      item[COLUMNS_UPDATE.ORIGINAL_VALUE] === item[COLUMNS_UPDATE.NEW_VALUE] &&
      item[COLUMNS_UPDATE.DB_VALUE] === null
    ))
    const allNull = filtered.length === items.length
    if (props?.column) {
      return (
        <TH
          column={props.column}
          event={ev}
          changeCheckBox={changeCheckBox}
          changeSorting={changeSorting}
          checkBoxDisabled={checkBoxDisabled || allNull}
        />
      )
    }
  }, [ changeCheckBox, changeSorting, checkBoxDisabled, items ])

  return props
    ? (
        <DetailsHeader
          {...props}
          onRenderColumnHeaderTooltip={renderColumnHeaderTooltip}
          item={{}}
          itemIndex={-1}
        />
      )
    : null
}

export default Header
