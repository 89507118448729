// Функція простого, але якісного і швидкого 53-бітного хешу
const cyrb53 = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed
  let h2 = 0x41c6ce57 ^ seed
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)
  return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}

export const hash = (string) => cyrb53(string, 649)

// Бінарний пошук у відсортованому масиві полів за назвою поля
export const binaryFindIndex = (array, item, sortFn, property = 'name') => {
  let start = 0
  let end = array.length - 1
  while (start <= end) {
    const mid = Math.floor((start + end) / 2)
    const cmp = sortFn(array[mid][property], item)
    if (cmp === 0) {
      return mid
    } else if (cmp < 0) {
      start = mid + 1
    } else {
      end = mid - 1
    }
  }
}

// Бінарна перевірка наявності елемента у відсортованому масиві, яка за потреби повертає індекс для його вставки
// у масив таким чином, щоб він залишався відсортованим
export const binaryIncludes = (array, item, returnInsertIndex = false) => {
  let start = 0
  let end = array.length - 1
  while (start <= end) {
    const mid = Math.floor((start + end) / 2)
    if (array[mid] === item) {
      return true
    } else if (array[mid] < item) {
      start = mid + 1
      if (returnInsertIndex && start > end) {
        return start
      }
    } else {
      end = mid - 1
      if (returnInsertIndex && start > end) {
        return start
      }
    }
  }
  return false
}

export const binaryIncludesCmp = (array, item, compare) => {
  let start = 0
  let end = array.length - 1
  while (start <= end) {
    const mid = Math.floor((start + end) / 2)
    const cmp = compare(array[mid], item)
    if (cmp === 0) {
      return true
    } else if (cmp < 0) {
      start = mid + 1
    } else {
      end = mid - 1
    }
  }
  return false
}

export const testUniqueArray = (a) => {
  const n = a.length
  for (let i = 0; i < n - 1; i++) {
    for (let j = i + 1; j < n; j++) {
      if (a[i] === a[j]) {
        return false
      }
    }
  }
  return true
}

export const decimalAdjust = (value, exp = 2) => {
  // Якщо степінь невизначена або рівна нулю
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math.round(value)
  }
  value = +value
  exp = -(+exp)
  // Якщо значення не є числом, або степінь не є цілим числом
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN
  }
  // Зсув розрядів
  value = value.toString().split('e')
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)))
  // Зворотний зсув
  value = value.toString().split('e')
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp))
}

// Визначення глибини рекурсивної вкладеності масивів
export const arrayLevel = (array) => {
  let result = 0
  let test = array
  while (Array.isArray(test)) {
    result++
    test = test[0]
  }
  return result
}

const isCoordinate = (entity) =>
  (Array.isArray(entity) && entity.length === 2 && Number.isFinite(entity[0]) && Number.isFinite(entity[1]))

export const determineNumberOfCoordinates = (array, _accum) => {
  if (Array.isArray(array)) {
    if (isCoordinate(array[0])) {
      return _accum + array.length // Припускаємо коректність решти елементів масиву
    }
    const accum = array.reduce((accum, item) => determineNumberOfCoordinates(item, accum), 0)
    return _accum + accum
  }
  return _accum
}
