export const DATA_TYPES = {
  SECTORS: 'sectors',
  SITES: 'sites',
  RBSES: 'rbses',
  BASE_STATIONS: 'baseStations',
  COMPLAINTS: 'complaints',
  BUSINESS_CASES: 'businessCases',
  CREATE_BUSINESS_CASES: 'createBC',
  MAP_ATTRIBUTES: 'MapAttributes',
  USERS: 'users',
  TRF: 'trf',
  NOTIFICATIONS: 'notifications',
}

export const PAGE_TO_DATA_TYPE = {
  sites: DATA_TYPES.SITES,
  rbs: DATA_TYPES.BASE_STATIONS,
  sectors: DATA_TYPES.SECTORS,
  'business-cases': DATA_TYPES.BUSINESS_CASES,
  complaints: DATA_TYPES.COMPLAINTS,
  'vector-map': DATA_TYPES.MAP_ATTRIBUTES,
}

export const fileFormat = [
  { key: 'mif', text: '.mif' },
  { key: 'kml', text: '.kml' },
  { key: 'kmz', text: '.kmz' },
]
