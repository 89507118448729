export const buildClassList = (baseClassName, features, ...rest) => {
  const classList = [ baseClassName ]
  for (const feature of Object.keys(features)) {
    if (features[feature]) {
      classList.push(`${baseClassName}--${feature}`)
    }
  }
  classList.push(...rest)
  return classList.join(' ')
}

export const addToClassList = (classList = '', newClass) => {
  const array = classList.split(' ')
  if (array.includes(newClass)) {
    return classList
  }
  return [ newClass, ...array ].join(' ')
}
