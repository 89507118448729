/* eslint-disable react/no-unknown-property */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Stack, Spinner, SpinnerSize, Label, IconButton } from '@fluentui/react'
import { DefaultButton, PrimaryButton } from '../common/Button'
import Modal from '../common/Modal'
import { selectBaseStationFields, selectSectorFields, selectSiteFields } from '../../features/network/networkSlice'
import { decimalAdjust } from '../../utils/math'
import {
  selectedTemplate, selectTemplateList, setTemplateById, setKPITemplate, setOptionsElements, setElementKPI,
  createTemplate, saveTemplate, selectKPIChanged, selectStatistic, initCompositeIndex, selectRateCalculationCompleted,
  setRateCalculationCompleted, setAllKpiElements, selectOptionsElement, setExecutionState, selectExecutionState,
  deleteTemplate, selectAllKpiElements, selectKPITemplate, selectSavingRates, setRates, setSortedRates,
  selectSortedRates, saveCompositeIndexes, loadResourceForPeriod, selectLoadingResourceStatus,
  setLoadingResourceStatus, startCalculation, setSavingRates, selectToTemplate, loadTemplates, getResourceForPeriod,
  resetStatistic,
} from '../../features/compositeIndex/compositeIndexSlice'
import { confirm as tConfirm } from '../common/Confirm/constants'
import { useConfirm } from '../common/Confirm'
import { EMPTY } from '../../constants'
import { selectUserAccess } from '../../features/login/loginSlice'
import { EDITING_TEMPLATE_CI } from '../../constants/access'
import { addTaskType, setTaskTypeCompleted, setTaskTypeFailed, TASK_TYPES } from '../../features/taskLog'
import { EVENT_TYPE, saveEventLog, STATUS_ERROR, STATUS_PENDING } from '../../features/eventLog/eventLogSlice'
import { plainTextToExcel } from '../../utils/export'
import { DropDown } from '../common/Dropdown'
import { WebkitScrollbar } from '../common/WebkitScrollbar'
import {
  columnsTableRate, EXECUTION_STATE, monthOptions, statisticHeader, statisticRow, TITLE, TITLE_STATE, titleMonth,
  titleTemplate,
} from './constants'
import TableRate from './Rate'
import { buildTemplateAttributes, getAttributesKPI, getOptionsElements, tableToClipboardText } from './utils'
import CalculateRates from './Calculate'
import TableWeights from './Weights'
import SavingRates from './SavingRates'
import LoadingResourceStatus from './LoadingResourceStatus'
import CreateTemplateForm from './CreateTemplateForm'
import GraphModal from './GraphModal'

import './CompositeIndex.css'

const styleTableWidth = {
  height: '100%',
  marginTop: 16,
  borderTop: '1px solid var(--grey-6)',
  borderBottom: '1px solid var(--grey-6)',
}

const stackTokens = {
  childrenGap: 16,
  padding: '16px 0 0 0',
}

const stackTokensRate = {
  childrenGap: 16,
  padding: 2,
}

const monthItemStyles = {
  root: {
    // alignItems: 'center',
    width: '40%',
    display: 'flex',
    height: 'auto',
  },
}

const stackItemStylesRoot = {
  root: {
    // alignItems: 'center',
    // background: DefaultPalette.themePrimary,
    // color: DefaultPalette.white,
    width: 100,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    // flexGrow: 1,
    // justifyContent: 'center',
  },
}

const stackItemStylesRate = {
  root: {
    width: 100,
    height: '100%',
    // display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    // overflowX: 'hidden',
    // flexGrow: 1,
    // height: 'auto',
  },
}

const TemplateOperations = (props) => {
  const {
    onSave,
    onSaveAs,
    onDelete,
    templateKey,
    kpiChanged,
    onEdit,
    allowedEditing,
    allowCalculations,
    isEdited,
  } = props
  const isDefaultTemplate = templateKey === 'default'

  return (
    <Stack
      horizontal
      verticalAlign="center"
      horizontalAlign="space-evenly"
    >
      <IconButton
        disabled={(!allowedEditing && !isDefaultTemplate) || isEdited || !onEdit}
        onClick={onEdit}
        iconProps={{ iconName: 'Edit' }}
        title="To edit template"
        styles={{ root: { marginBottom: -3 } }}
      />
      <IconButton
        disabled={isDefaultTemplate || !kpiChanged || !allowCalculations || !allowedEditing}
        onClick={onSave}
        iconProps={{ iconName: 'Save' }}
        title="Save template"
        styles={{ root: { marginBottom: -3 } }}
      />
      <IconButton
        disabled={!allowCalculations || !allowedEditing}
        iconProps={{ iconName: isDefaultTemplate ? 'SaveTemplate' : 'SaveAs' }}
        title={isDefaultTemplate ? 'Create template' : 'SaveAs template'}
        styles={{ root: { marginBottom: -3 } }}
        onClick={onSaveAs}
      />
      <IconButton
        disabled={templateKey === 'default' || !allowedEditing}
        iconProps={{ iconName: 'Delete' }}
        title="Delete template"
        styles={{ root: { marginBottom: -3 } }}
        onClick={onDelete}
      />
    </Stack>
  )
}

const CompositeIndex = ({ onClose }) => {
  const dispatch = useDispatch()

  const [ month, setMonth ] = useState(monthOptions[0])
  const [ nameTemplate, setNameTemplate ] = useState()
  const [ element, setElement ] = useState() // Вибраний елемент мережі
  const { renderConfirm, ask } = useConfirm()
  const [ initData, setInitData ] = useState(null)
  const [ graphVisible, setGraphVisible ] = useState(false)

  const sortedRates = useSelector(selectSortedRates)
  const sitesFields = useSelector(selectSiteFields)
  const rbsFields = useSelector(selectBaseStationFields)
  const sectorsFields = useSelector(selectSectorFields)
  const savingRates = useSelector(selectSavingRates)
  const loadingResourceStatus = useSelector(selectLoadingResourceStatus)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const allKpiElements = useSelector(selectAllKpiElements) // Повні списки атрибутів елементів мережі
  const executionState = useSelector(selectExecutionState) // Стан розрахунку композитних індексів
  const isRateCalculationCompleted = useSelector(selectRateCalculationCompleted)
  const toTemplate = useSelector(selectToTemplate)
  const templateList = useSelector(selectTemplateList) // Список шаблонів
  const template = useSelector(selectedTemplate) ?? EMPTY.OBJECT // Вибраний шаблон
  const kpiTemplate = useSelector(selectKPITemplate, shallowEqual) // Дані шаблону
  const kpiChanged = useSelector(selectKPIChanged)
  const statistic = useSelector(selectStatistic)
  const optionsElement = useSelector(selectOptionsElement) // Доступні для вибору елементи мережі у выбраному шаблоні

  const elementKey = useMemo(() => element?.key, [ element ])

  const templateKey = useMemo(() => template?.key, [ template ])

  const kpiOptions = useMemo(() => {
    const { [elementKey]: kpiOptions } = kpiTemplate ?? {}
    return kpiOptions
  }, [ kpiTemplate, elementKey ])

  const {
    statisticSector,
    // statisticRBS,
    statisticSite: countSites,
    statisticSiteBySectors: countSitesBySectors,
  } = useMemo(() => statistic, [ statistic ])

  // Сума усіх ваг за атрибутами
  const percentage = useMemo(() => {
    if (!Array.isArray(kpiOptions)) {
      return 0
    }
    const percentage = kpiOptions.reduce((percentage, item) => percentage + (item.weight ?? 0), 0)
    return decimalAdjust(percentage)
  }, [ kpiOptions ])

  // Дозволяємо проводити розрахунок
  const allowCalculations = useMemo(() => percentage === 100, [ percentage ])

  // При проведеному розрахунку залишаємо лише заповнені атрибути
  const kpiOptionsWeights = useMemo(() => {
    if (!Array.isArray(kpiOptions)) {
      return []
    }
    if (!isRateCalculationCompleted) {
      return kpiOptions
    }
    return kpiOptions.filter((item) => item.weight)
  }, [ kpiOptions, isRateCalculationCompleted ])

  const isTemplateEdit = useMemo(() => executionState === EXECUTION_STATE.EDIT, [ executionState ])

  const isStateInit = useMemo(() => executionState?.slice(0, 4) === EXECUTION_STATE.INIT, [ executionState ])

  // Визначення атрибутів, які можуть брати участь у розрахунках
  useEffect(() => {
    const historical = month?.key !== '0'
    const sites = getAttributesKPI(sitesFields, historical)
    const rbs = getAttributesKPI(rbsFields, historical)
    const sectors = getAttributesKPI(sectorsFields, historical)
    dispatch(setAllKpiElements({ sites, rbs, sectors }))
  }, [ month, rbsFields, sectorsFields, sitesFields, dispatch ])

  // Ініціалізація даних
  useEffect(() => {
    dispatch(setExecutionState(EXECUTION_STATE.INIT))
  }, [ dispatch ])

  useEffect(() => {
    let id
    if (executionState?.slice(0, 4) === EXECUTION_STATE.INIT) {
      id = setTimeout(() => dispatch(initCompositeIndex(executionState)), 10)
    }
    return () => clearTimeout(id)
  }, [ dispatch, executionState ])

  // Перезавантаження списку шаблонів та вибір необхідного шаблону після видалення чи створення шаблону
  useEffect(() => {
    dispatch(loadTemplates(toTemplate?.id))
  }, [ dispatch, toTemplate ])

  // Завантаження налаштувань розрахунку для вибраного шаблону
  useEffect(() => {
    const { key, template: kpi } = template
    if (!key) {
      return
    }
    // Для шаблону за замовчуванням -- усім елементам мережі повні, чисті списки
    const kpiTemplate = key === 'default' ? { sites: [], rbs: [], sectors: [] } : kpi
    // Заповнюємо повний список атрибутів даними з шаблону
    const kpiAttributes = buildTemplateAttributes(kpiTemplate, allKpiElements)
    dispatch(setKPITemplate(kpiAttributes))
    // Встановлення списку можливих опцій вибору елемента мережі
    const optionsElements = getOptionsElements(kpiAttributes)
    if (JSON.stringify(optionsElements) !== JSON.stringify(optionsElement)) {
      dispatch(setOptionsElements(optionsElements))
      setElement(optionsElements[0])
    }
  }, [ allKpiElements, dispatch, template, optionsElement ])

  // Скидаємо розрахунок і коефіцієнти при зміні елемента мережі
  useEffect(() => {
    dispatch(setRateCalculationCompleted(false))
    dispatch(setRates(null))
    dispatch(setSortedRates(null))
  }, [ dispatch, elementKey ])

  // Збереження змін у шаблоні
  const onChangeKpiElement = useCallback((kpiOptions, elementKey) => {
    if (elementKey) {
      dispatch(setElementKPI({ elementKey, kpiOptions }))
    }
  }, [ dispatch ])

  const onCreateTemplate = useCallback((nameTemplate) => {
    const name = `${nameTemplate ?? ''}`
    if (name.length) {
      dispatch(createTemplate(nameTemplate, elementKey))
      setNameTemplate(null)
    }
  }, [ dispatch, elementKey ])

  const saveTemplateHandler = useCallback(() => {
    dispatch(saveTemplate(template, elementKey))
  }, [ dispatch, template, elementKey ])

  const saveAsTemplateHandler = useCallback(() => {
    setNameTemplate(template.key === 'default' ? elementKey : template.text)
  }, [ template, elementKey ])

  const deleteTemplateHandler = useCallback(() => {
    dispatch(deleteTemplate(template?.key))
  }, [ dispatch, template ])

  const deleteTemplateConfirm = useCallback(() => {
    ask(
      deleteTemplateHandler,
      () => { },
      null,
      {
        title: 'Template',
        messages: [ `Confirm the removal of the "${template?.text ?? ''}" template` ],
        textNoBtn: tConfirm.no,
        textYesBtn: tConfirm.yes,
      },
    )
  }, [ ask, deleteTemplateHandler, template?.text ])

  // Перехід у режим редагування
  const editTemplate = useCallback(() => {
    dispatch(setExecutionState(EXECUTION_STATE.EDIT))
    setRateCalculationCompleted(false)
  }, [ dispatch ])

  const selectMonth = useCallback((_, item) => {
    setMonth(item)
    editTemplate()
  }, [ setMonth, editTemplate ])

  const selectElement = useCallback((_, element) => {
    setElement(element)
    editTemplate()
  }, [ editTemplate ])

  // Вибір шаблону
  const selectTemplate = useCallback((_, item) => {
    dispatch(setTemplateById(item.key))
    editTemplate()
  }, [ dispatch, editTemplate ])

  const onCalculateRate = useCallback(async () => {
    // Завантаження даних за період
    const months = month?.value
    if (months > 0 && months <= 24) {
      await dispatch(
        setLoadingResourceStatus({ title: 'Preparing data for calculation Composite index', total: 4, current: 0 }),
      )
      dispatch(loadResourceForPeriod(elementKey, months))
    } else {
      dispatch(startCalculation)
    }
  }, [ dispatch, elementKey, month ])

  const onSaveCompositeIndex = useCallback(() => {
    dispatch(addTaskType(TASK_TYPES.saveCompositeIndex, elementKey))
    dispatch(saveCompositeIndexes(elementKey))
      .finally(() => {
        setTimeout(() => dispatch(setSavingRates(null)), 500)
      })
      .then(() => {
        dispatch(setTaskTypeCompleted(TASK_TYPES.saveCompositeIndex))
      }, () => {
        dispatch(setTaskTypeFailed(TASK_TYPES.saveCompositeIndex))
      })
  }, [ dispatch, elementKey ])

  const onCloseForm = useCallback(() => {
    dispatch(setSavingRates(null))
    dispatch(resetStatistic())
    onClose()
  }, [ dispatch, onClose ])

  // Експорт композитних індексів
  const onExport = useCallback(() => {
    const taskType = TASK_TYPES.exportCompositeIndexes
    dispatch(addTaskType(taskType))
    dispatch(saveEventLog(EVENT_TYPE.compositeIndexExport, `: ${elementKey}, ${month.text}`, STATUS_PENDING))
    try {
      const columns = columnsTableRate[elementKey]
      const { resource, fields } = dispatch(getResourceForPeriod(month.value, elementKey))
      const text = tableToClipboardText(columns, sortedRates, kpiOptionsWeights, elementKey, resource, fields)
      plainTextToExcel(text, 'Composite Index', 'composite_index.xlsx')
      dispatch(setTaskTypeCompleted(taskType))
      dispatch(saveEventLog(EVENT_TYPE.compositeIndexExport, `: ${sortedRates.length} rows`))
    } catch (error) {
      dispatch(setTaskTypeFailed(taskType))
      dispatch(saveEventLog(EVENT_TYPE.compositeIndexExport, `\nERROR: ${error.message}`, STATUS_ERROR))
    }
  }, [ sortedRates, kpiOptionsWeights, elementKey, month, dispatch ])

  const onShowGraph = useCallback(() => {
    setGraphVisible(true)
  }, [ setGraphVisible ])

  const onCloseGraph = useCallback(() => {
    setGraphVisible(false)
  }, [ setGraphVisible ])

  // При зміні списку опцій встановлюємо за замовчуванням вибір на перший елемент
  useEffect(() => {
    setElement(optionsElement?.[0])
  }, [ optionsElement ])

  useEffect(() => {
    if (templateList && templateList.length && templateKey) {
      const idx = templateList.findIndex((template) => template.key === templateKey)
      if (idx === -1) {
        // Change to default
        const defaultTemplate = templateList.find((template) => template.key === 'default')
        if (defaultTemplate) {
          selectTemplate(null, defaultTemplate)
        }
      }
    }
  }, [ templateKey, templateList, selectTemplate ])

  return (
    <Modal
      title={TITLE}
      onClose={onCloseForm}
      width={1200}
      heightAuto={true}
      marginV={'10vh'}
      classNameBM={'ci-bm'}
      classNameBody={'ci-body'}
    >
      {isStateInit && (
        <Spinner
          className="centered"
          label={TITLE_STATE[executionState] ?? '-'}
          style={{ backgroundColor: '#50505050', width: '100%', height: '100%', zIndex: 1 }}
          size={SpinnerSize.large}
        />
      )}
      {executionState === EXECUTION_STATE.CALCULATION && (
        <CalculateRates
          elementKey={elementKey}
          kpi={kpiOptions}
          months={month.value}
          initData={initData}
          setInitData={setInitData}
        />
      )}
      <Stack horizontal tokens={stackTokens} style={{ flex: '1 1 auto' }}>
        <Stack styles={stackItemStylesRoot} grow={1}>
          <WebkitScrollbar>
            <Stack tokens={{ childrenGap: '16', padding: '16px 0 0 0' }} grow>
              <DropDown
                labelOverlay
                label={'Element and attributes'}
                selectedKey={elementKey}
                options={optionsElement}
                // styles={comboBoxElementStyles}
                calloutProps={{ doNotLayer: true }}
                onChange={selectElement}
              />
              <Stack style={{ flexGrow: 1 }}>
                <label className="h2">Statistic</label>
                <Stack horizontal>
                  <Stack.Item grow={1} align='start'>
                    <Label>Sites</Label>
                  </Stack.Item>
                  <Stack.Item grow={2} align="center">
                    <Label>
                      <span title="Total number of active sites">
                        {countSites}
                      </span>
                      <span style={{ fontWeight: 500 }} title="Number of sites with active sectors">
                        {` (${countSitesBySectors ?? '-'})`}
                      </span>
                    </Label>
                  </Stack.Item>
                </Stack>
                <Stack grow={1}>
                  <table className="ci-statistic" width="100%" border="1px solid">
                    <thead>
                      <tr>
                        {statisticHeader.map((header, index) => (
                          <th key={index} className={'center'}>{header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {statisticRow.map((header, index) => (
                        <tr key={index}>
                          <th className="left">{header}</th>
                          {/* <td className="center">{statisticRBS?.[header]}</td> */}
                          <td className="center">{statisticSector?.[header]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Stack>
              </Stack>
            </Stack>
          </WebkitScrollbar>
        </Stack>
        <Stack.Item styles={stackItemStylesRoot} grow={2}>
          <Stack horizontal tokens={stackTokensRate}>
            <Stack.Item styles={monthItemStyles} align="end">
              <DropDown
                options={monthOptions}
                labelOverlay
                fullWidth
                label="Count period (months)"
                placeholder="Select number of months"
                title={titleMonth}
                onChange={selectMonth}
                selectedKey={month?.key}
              />
            </Stack.Item>
            <Stack grow={1} tokens={stackTokens}>
              <TemplateOperations
                onSave={saveTemplateHandler}
                onSaveAs={saveAsTemplateHandler}
                onDelete={deleteTemplateConfirm}
                templateKey={templateKey}
                kpiChanged={kpiChanged}
                onEdit={editTemplate}
                allowedEditing={userAccess[EDITING_TEMPLATE_CI]}
                allowCalculations={allowCalculations}
                isEdited={isTemplateEdit}
              />
              <DropDown
                className={'margin-topM select-templates-dropdown'}
                options={templateList}
                labelOverlay
                fullWidth
                label={'Templates'}
                placeholder={'Select template'}
                title={titleTemplate}
                onChange={selectTemplate}
                selectedKey={templateKey}
              />
            </Stack>
          </Stack>
          <Stack style={styleTableWidth}>
            <TableWeights
              items={kpiOptions}
              onChange={onChangeKpiElement}
              percentage={percentage}
              disabled={isRateCalculationCompleted || executionState !== EXECUTION_STATE.EDIT}
              elementKey={elementKey}
              isEdit={executionState === EXECUTION_STATE.EDIT}
            />
          </Stack>
        </Stack.Item>
        <Stack
          styles={stackItemStylesRate}
          grow={2}
          className={'table-rate-container'}
        >
          <TableRate elementKey={elementKey} dataSources={initData} />
        </Stack>
      </Stack>
      <Stack horizontal tokens={stackTokens} horizontalAlign={'end'} >
        <PrimaryButton
          disabled={executionState === EXECUTION_STATE.CALCULATION || !allowCalculations}
          text="Calculate"
          onClick={onCalculateRate}
        />
        <DefaultButton
          text="Ok"
          onClick={onSaveCompositeIndex}
        />
        <PrimaryButton
          text="Cancel"
          onClick={onCloseForm}
        />
        <PrimaryButton
          text="Export"
          disabled={executionState !== EXECUTION_STATE.CALCULATED}
          onClick={onExport}
        />
        <PrimaryButton
          text="Graph"
          disabled={executionState !== EXECUTION_STATE.CALCULATED}
          onClick={onShowGraph}
        />
      </Stack>
      {renderConfirm()}
      {savingRates && (
        <SavingRates onClose={onCloseForm} />
      )}
      {loadingResourceStatus && (
        <LoadingResourceStatus />
      )}
      {nameTemplate && (
        <CreateTemplateForm
          onCreate={onCreateTemplate}
          template={template}
          oldName={nameTemplate}
          title={'SaveAs Template'}
          onHide={() => setNameTemplate(null)}
          templateList={templateList}
        />
      )}
      {graphVisible && (
        <GraphModal
          onClose={onCloseGraph}
          data={initData}
        />
      )}
    </Modal>
  )
}

export default CompositeIndex
