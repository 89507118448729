import React, { useCallback } from 'react'
import Table from '../common/GroupedTable'
import { BUTTON_KEY, footerUpdateBC } from '../constants'
import { COLUMNS_UPDATE_BC, TABLES_UPDATE } from '../../../constants/updateDB'
import { apiUpdateDB } from '../../../features/updateProject/update'

const renderItemGroup = (item, index, column) => {
  return (<div>
    {column.fieldName === COLUMNS_UPDATE_BC.SITE
      ? item[COLUMNS_UPDATE_BC.SECTOR_NAME]
      : column.fieldName === COLUMNS_UPDATE_BC.STATUS ? item[COLUMNS_UPDATE_BC.TECHNOLOGY] : ''
    }
  </div>)
}
const groupFieldName = 'sectors'

const UpdateBC = ({ onUpdateItem, clickApplyUpdate, items, projectId, columns, ...props }) => {
  const onUpdateItemHandler = useCallback(
    (item) => onUpdateItem && onUpdateItem(item, TABLES_UPDATE.UPDATE_BC),
    [ onUpdateItem ],
  )

  const onApply = useCallback((button) => {
    const select = items.map((item) => item[COLUMNS_UPDATE_BC.SELECT]
      ? item[COLUMNS_UPDATE_BC.ID]
      : null)
      .filter(Boolean)
    const data = { bcs: select }
    const updateDB = apiUpdateDB.updateBC[button?.key] && apiUpdateDB.updateBC[button.key](projectId, data)
    clickApplyUpdate(updateDB, BUTTON_KEY.UPDATE_BC)
  }, [ clickApplyUpdate, items, projectId ])

  return (
    <Table
      id={TABLES_UPDATE.UPDATE_BC}
      items={items}
      columns={columns}
      footer={footerUpdateBC}
      onUpdateItem={onUpdateItemHandler}
      onApply={onApply}
      {...props}
      groupFieldName={groupFieldName}
      onRenderItemGroup={renderItemGroup}
    />
  )
}

export default UpdateBC
