import { ROLES } from './roles'

/* Список функцій з розмежованим доступом */

// Блокування/розблокування користувачів, зміна ролей
export const EDIT_USERS = 'EDIT_USERS'
// Перегляд списку проєктів, створення/видалення проєкту
export const EDIT_PROJECTS = 'EDIT_PROJECTS'
// Публікація проєктів для інших користувачів
export const SHARE_PROJECTS = 'SHARE_PROJECTS'
// Візуальне, табличне створення/додавання нового елементу мережі, зміна його атрибутів, видалення елементу мережі
export const EDIT_ELEMENTS = 'EDIT_ELEMENTS'
// Створення/зміна/видалення полігонів (зон)
export const EDIT_POLYGONS = 'EDIT_POLYGONS'
// Завантаження і використання власної мапи
export const IMPORT_MAPS = 'IMPORT_MAPS'
// Призначення власним картам статусу "карта за замовчуванням"
export const DEFAULT_MAPS = 'DEFAULT_MAPS'
// Розрахунок композитних індексів, налаштування вагових коефіцієнтів композитних індексів для кожної технології
export const COMPOSITE_INDEXES_CALCULATE = 'COMPOSITE_INDEXES_CALCULATE'
// Збереження відредагованих композитних індексів на сервері
export const COMPOSITE_INDEXES_SAVE = 'COMPOSITE_INDEXES_SAVE'
// Перегляд історичних даних
export const HISTORICAL_DATA = 'HISTORICAL_DATA'
// Синхронізація даних свого проєкту з дефолтним проєктом
export const UPDATE_DB = 'UPDATE_DB'
// Перегляд списку бізнес-кейсів (БК), створення/видалення, експорт, попередній розрахунок, відправлення на узгодження
export const BUSINESS_CASES = 'BUSINESS_CASES'
// Узгодження БК, видалення узгодженого БК
export const APPROVE_BUSINESS_CASES = 'APPROVE_BUSINESS_CASES'
// Верифікація результатів прогнозованого впливу нового елементу мережі
export const VERIFY = 'VERIFY'
// Налаштування параметрів попереднього розрахунку БК
export const EDIT_COEFFICIENTS = 'EDIT_COEFFICIENTS'
// Імпорт/експорт шаблону прогнозу трафіку
export const TRF_TEMPLATE = 'TRF_TEMPLATE'
// Перегляд списку скарг, розрахунок Pain-зон
export const COMPLAINTS = 'COMPLAINTS'
// Перегляд карт покриття
export const COVERAGE = 'COVERAGE'
// Налаштування макропоказників
export const MACRO_INDICATORS = 'MACRO_INDICATORS'
// Налаштування TRF
export const TRF = 'TRF'
// Синхронізація даних (з дефолтним проєктом та Атолом)
export const SYNCHRONIZATION = 'SYNCHRONIZATION'
// Створення шаблону композитних індексів
export const EDITING_TEMPLATE_CI = 'EDITING_TEMPLATE_CI'
// Перегляд списку нотифікацій
export const NOTIFICATIONS = 'NOTIFICATIONS'
// Аналітичні звіти
export const ANALYTICAL_REPORTS = 'ANALYTICAL_REPORTS'
// Експорт табличних даних
export const EXPORT_TABLE_DATA = 'EXPORT_TABLE_DATA'

/* Доступ до функцій */

export const ACCESS_TO_FEATURE = {
  // Для всіх користувачів, незалежно від ролі
  // [EDIT_POLYGONS]: null,
  [COMPOSITE_INDEXES_CALCULATE]: null,
  [EDIT_POLYGONS]: null,

  // ROLE_USER
  [EXPORT_TABLE_DATA]: ROLES.ROLE_USER,
  [EDIT_PROJECTS]: ROLES.ROLE_USER,
  [SHARE_PROJECTS]: ROLES.ROLE_USER,
  [EDIT_ELEMENTS]: ROLES.ROLE_USER,
  [IMPORT_MAPS]: ROLES.ROLE_USER,
  [COMPOSITE_INDEXES_SAVE]: ROLES.ROLE_USER,
  [HISTORICAL_DATA]: ROLES.ROLE_USER,
  [UPDATE_DB]: ROLES.ROLE_USER,
  [BUSINESS_CASES]: ROLES.ROLE_USER,
  [VERIFY]: ROLES.ROLE_USER,
  [COVERAGE]: ROLES.ROLE_USER,
  [ANALYTICAL_REPORTS]: ROLES.ROLE_USER,

  // ROLE_FPA
  [APPROVE_BUSINESS_CASES]: ROLES.ROLE_FPA,
  [EDIT_COEFFICIENTS]: ROLES.ROLE_FPA,
  [TRF_TEMPLATE]: ROLES.ROLE_FPA,

  // ROLE_GMT
  [COMPLAINTS]: ROLES.ROLE_GMT,
  [NOTIFICATIONS]: ROLES.ROLE_GMT,

  // ROLE_RPL
  [MACRO_INDICATORS]: ROLES.ROLE_RPL,
  [TRF]: ROLES.ROLE_RPL,

  // ROLE_ADMIN
  [EDIT_USERS]: ROLES.ROLE_ADMIN,
  [EDITING_TEMPLATE_CI]: ROLES.ROLE_ADMIN,
  [DEFAULT_MAPS]: ROLES.ROLE_ADMIN,
  [SYNCHRONIZATION]: ROLES.ROLE_ADMIN,
}
