import { TextField } from '@fluentui/react'
import React, { useMemo } from 'react'

export const NotPresentInAtollItems = ({ data }) => {
  const message = useMemo(() => {
    if (data) {
      const { sites, sectors } = data
      let result = ''
      if (sites?.length > 0) {
        result = result.concat('Sites:\n')
        result = result.concat(sites.map(({ name }) => `\t${name}`).join('\n'))
      }
      if (sectors?.length > 0) {
        if (sites?.length > 0) {
          result = result.concat('\n\n')
        }
        result = result.concat('Sectors:\n')
        result = result.concat(sectors.map(({ name }) => `\t${name}`).join('\n'))
      }
      return result
    }
    return null
  }, [ data ])
  return (
    <div style={{ width: 400 }}>
      <TextField
        multiline
        rows={10}
        readOnly
        defaultValue={message} />
    </div>
  )
}

export default NotPresentInAtollItems
