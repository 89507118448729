import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useBoolean } from '@fluentui/react-hooks'
import { format, parseISO } from 'date-fns'
import { toast } from 'react-hot-toast'
import {
  loadJobs,
  onExportSettlementsToXLSX,
  selectBusy,
  selectJobs,
  synchronizeJob,
  selectIsImporting,
  selectIsExporting,
  onImportSettlementsToXLSX,
} from '../../../features/synchronization/synchronizationSlice'
import { EMPTY } from '../../../constants'
import { KEYS } from '../constants'
import SynchronizationForm from '../Form/SynchronizationForm'
import Component from './Component'
import { COLUMNS, getIdColumnFromIndex, sourcesName, SYNC_STATUS_ACTIVE } from './settings'

const REFRESH_DELAY = 10000

const SYNC_NETWORK_DEFAULT = 'SYNC_NETWORK_DEFAULT'
const SYNC_ATOLL_NETWORK = 'SYNC_ATOLL_NETWORK'
const SYNC_ATOLL_MAP = 'SYNC_ATOLL_MAP'
const SYNC_COMPLAINT = 'SYNC_COMPLAINT'
const SYNC_NETWORK_HISTORIES_DATES = 'SYNC_NETWORK_HISTORIES_DATES'
const SYNC_COMPLAINT_HISTORIES = 'SYNC_COMPLAINT_HISTORIES'
const SYNC_SNC_COMPLAINTS = 'SYNC_SNC_COMPLAINTS'
const SYNC_SNC_SETTLEMENTS = 'SYNC_SNC_SETTLEMENTS'

const DATE_FORMAT_MONTH = 'MM.yyyy'
const DATE_FORMAT_BACKEND = 'yyyy-MM-dd'
const DATE_FORMAT_DEFAULT = 'dd.MM.yyyy HH:mm:ss'

export const xlsxFileInputId = 'xlsx-file-input-settlements'

const clearValue = (event) => {
  event.target.value = null
}

const dateValidFormated = (date, formatDate = DATE_FORMAT_DEFAULT, noValid) => {
  const dateIso = parseISO(date)
  return Number.isNaN(dateIso.valueOf())
    ? noValid ?? null
    : format(dateIso, formatDate)
}

const Container = () => {
  const hotGridRef = useRef(null)
  const dispatch = useDispatch()
  const [ isConfirmOpen, { setTrue: showConfirm, setFalse: hideConfirm } ] = useBoolean(false)
  const [ confirmOptions, setConfirmOptions ] = useState({})
  const [ sources, setSources ] = useState([])
  const [ selectSource, setSelectSource ] = useState(null)
  const jobsState = useSelector(selectJobs)
  const isImporting = useSelector(selectIsImporting)
  const isExporting = useSelector(selectIsExporting)

  useEffect(() => {
    dispatch(loadJobs)
  }, [ dispatch ])

  // const newSources = sourcesName

  useEffect(() => {
    const jobs = jobsState ?? []
    let isActiveJobs = false
    const sources = sourcesName.map((item) => {
      const job = jobs.find((job) => job.type === item.key)
      const status = job?.[KEYS.STATUS]
      if (status === SYNC_STATUS_ACTIVE) {
        isActiveJobs = true
      }
      return {
        [KEYS.ID]: item.key,
        [KEYS.SOURCE_NAME]: item[KEYS.NAME],
        ...job,
      }
    })
    setSources(sources)
    if (isActiveJobs) {
      setTimeout(() => {
        dispatch(loadJobs)
      }, REFRESH_DELAY)
    }
  }, [ dispatch, jobsState ])

  const [ amountSelected, setAmountSelected ] = useState(0)

  const columns = useMemo(() => {
    return COLUMNS.map((column) => ({ ...column, id: `table.synchronization.${column.key}` }))
  }, [])

  const data = useMemo(() => {
    if (!Array.isArray(sources)) {
      return EMPTY.ARRAY
    }

    return sources.map((source) => {
      // Заповнення даних таблиці згідно з описом рядків
      return COLUMNS.map(
        (column) => {
          switch (column.key) {
            case KEYS.SYNCHRONIZATION_PERIOD: {
              const {
                [KEYS.SYNCHRONIZATION_FROM]: dateFrom,
                [KEYS.SYNCHRONIZATION_TO]: dateTo,
              } = source[KEYS.PROPERTIES] ?? {}
              return `${
                dateValidFormated(dateFrom, DATE_FORMAT_MONTH, '')
              } - ${
                dateValidFormated(dateTo, DATE_FORMAT_MONTH, '')
              }`
            }
            case KEYS.LAST_SYNC_DATE: {
              return dateValidFormated(source[KEYS.FINISHED_AT] || source[KEYS.LAST_SYNC_DATE], undefined, '-')
            }
            case KEYS.TYPE: {
              // Чомусь, якщо синхронізація ще не була запущена, то відсутній ключ source.type,
              // а натомість його значення передається в source.id
              return source?.[column.key] ?? source?.[KEYS.ID]
            }
            case KEYS.EXECUTE_DETAILS: {
              const res = source?.[column.key]?.warn || source?.[column.key]?.error || source?.[column.key]?.info
              return res?.message || res
            }
            default: {
              return source?.[column.key]
            }
          }
        })
    })
  }, [ sources ])

  const onShowSynchronize = (selectSource) => {
    if (selectSource) {
      const source = sourcesName.find((source) => (source.key === selectSource))
      if (source) {
        setConfirmOptions({
          syncSource: source,
        })
        showConfirm()
      }
    }
  }

  const onStartSynchronize = useCallback((syncSource, period) => {
    switch (syncSource?.key) {
      case SYNC_NETWORK_DEFAULT:
      case SYNC_ATOLL_NETWORK:
      case SYNC_ATOLL_MAP:
      case SYNC_SNC_SETTLEMENTS: return dispatch(synchronizeJob({ type: syncSource.key }))
      case SYNC_COMPLAINT: return dispatch(synchronizeJob({ type: SYNC_COMPLAINT_HISTORIES }))
      case SYNC_NETWORK_HISTORIES_DATES:
      case SYNC_COMPLAINT_HISTORIES:
      case SYNC_SNC_COMPLAINTS: {
        if (
          period?.dateFrom && period?.dateTo &&
          (period.dateFrom instanceof Date) &&
          (period.dateTo instanceof Date)
        ) {
          const fromDate = format(period?.dateFrom, DATE_FORMAT_BACKEND)
          const toDate = format(period?.dateTo, DATE_FORMAT_BACKEND)
          return dispatch(synchronizeJob({
            type: syncSource.key,
            properties: {
              fromDate,
              toDate,
            },
          }))
        }
        break
      }
      default:
    }
    toast.error('Synchronization.\n An error in the synchronization parameters')
  }, [ dispatch ])

  const onAfterEdit = useCallback(async (dataEdit) => {
    const [ , column, oldValue, newValue ] = dataEdit?.[0] ?? []
    if (oldValue === newValue) { return }
    const id = getIdColumnFromIndex(column)
    switch (id) {
      case KEYS.CHECK: {
        const sourcesSelected = data?.filter((item) => (item[column] === true)) ?? []
        if (amountSelected !== sourcesSelected.length) {
          setAmountSelected(sourcesSelected?.length ?? 0)
        }
        break
      }
      default:
    }
  }, [ data, amountSelected ])

  const onSelect = useCallback((rowIdx) => {
    const table = hotGridRef.current.hotInstance
    const columns = hotGridRef.current.columnSettings
    const typeIdx = columns.findIndex((col) => (col.key === KEYS.TYPE))
    const type = table.getDataAtCell(rowIdx, typeIdx)
    setSelectSource(type ?? null)
  }, [ hotGridRef ])

  const handleOnImportSettlements = useCallback(async () => {
    document.getElementById(xlsxFileInputId).click()
  }, [])

  const onSelectFileWithSettlements = useCallback(async () => {
    dispatch(onImportSettlementsToXLSX)
  }, [ dispatch ])

  const handleOnExportSettlements = useCallback(() => {
    dispatch(onExportSettlementsToXLSX)
  }, [ dispatch ])

  return (
    <>
      <Component
        selectSource={selectSource}
        data={data}
        onSynchronize={onShowSynchronize}
        hotGridRef={hotGridRef}
        onAfterEdit={onAfterEdit}
        onSelect={onSelect}
        loading={useSelector(selectBusy)}
        columns={columns}
      />
      <SynchronizationForm
        {...confirmOptions}
        isShow={isConfirmOpen}
        onHide={hideConfirm}
        onOk={onStartSynchronize}
        isImporting={isImporting}
        isExporting={isExporting}
        onImport={handleOnImportSettlements}
        onExport={handleOnExportSettlements}
      />
      <input
        type="file"
        id={xlsxFileInputId}
        accept=".xlsx"
        style={{ display: 'none' }}
        onChange={onSelectFileWithSettlements}
        onClick={clearValue}
      />
    </>
  )
}

export default Container
