import merge from 'deepmerge'
import { NAME_PREFIX } from '../../constants/network'
import { isNumberType, isDateType, isBooleanType, isStringType, isEnumType } from '../../utils/grid'

const ZCode = 'Z'.charCodeAt(0)

const isAZNumber = (str) => /^[A-Z]+$/.test(str)

const getAZDigit = (digit, inc = false) => !inc
  ? [ false, String.fromCharCode(digit) ]
  : digit === ZCode
    ? [ true, 'A' ]
    : [ false, String.fromCharCode(digit + 1) ]

const nextAZNumber = (str) => {
  let result = ''
  let letter
  let inc = true
  let position = str.length - 1
  while (position >= 0) {
    [ inc, letter ] = getAZDigit(str.charCodeAt(position), inc)
    result = `${letter}${result}`
    position--
  }
  return inc ? `A${result}` : result
}

const nextLetterIndex = (name, prefix) => {
  if (name.slice(0, prefix.length) !== prefix) {
    return 'A'
  }
  const suffix = name.slice(prefix.length)
  let position = 0
  while (position < suffix.length) {
    const part1 = suffix.slice(0, position)
    const part2 = suffix.slice(position)
    if (isAZNumber(part2)) {
      return `${part1}${nextAZNumber(part2)}`
    }
    position++
  }
  return `${suffix}A`
}

const makeId = (prefix, id) => `${prefix}${`0000${id}`.slice(-(Math.max(4, `${id}`.length)))}`

export const nextSiteName = (siteName) => makeId(NAME_PREFIX, Number(siteName.slice(NAME_PREFIX.length)) + 1)

export const nextSectorName = (siteName, sectorName) => `${siteName}${nextLetterIndex(sectorName, siteName)}`

const toNumber = (value) => {
  if (typeof value !== 'number') {
    value = Number((value || '').replace(',', '.'))
  }
  return Number.isNaN(value) ? null : value
}

const TRUE_VALUES = [ 'true', '1', 'on', 'yes', 'y' ]
const FALSE_VALUES = [ 'false', '0', 'off', 'no', 'n' ]

const toBoolean = (value) => {
  const strValue = String(value).toLowerCase()
  if (TRUE_VALUES.includes(strValue)) {
    return true
  } else if (FALSE_VALUES.includes(strValue)) {
    return false
  }
  return null
}

const d2 = (value) => `0${value}`.slice(-Math.max(2, `${value}`.length))

export const ISODate = (value) => `${value.getFullYear()}-${d2(value.getMonth() + 1)}-${d2(value.getDate())}`

const ISODateFromStr = (dateStr) => dateStr.includes('.')
  ? dateStr.slice(0, 10).split('.').reverse().map(d2).join('-')
  : dateStr.includes('/')
    ? dateStr.slice(0, 10).split('/').reverse().map(d2).join('-')
    : dateStr

export const checkConvertValue = (value, field, fields, full, nameField, elementIdx, oldValues, updatedValues) => {
  const { id, type, 'enum-values': enumValues } = fields[field]
  if (full && id === nameField) {
    const valuesBeforeCheck = []
    if (oldValues?.length > 0) {
      oldValues.forEach(({ row, field, value }) => {
        valuesBeforeCheck.push({ row, field, value: full.getList()[row][field] })
        // Restore prevValue before name checking
        // We need this because the index is prepared for data without modifications
        full.getList()[row][field] = value
      })
    }
    do {
      let found = updatedValues && updatedValues.has(value)
      if (!found) {
        const foundSet = new Set([ ...(full.findRangeByValue(value, id) || []), elementIdx ])
        found = foundSet.size > 1
      }
      if (found) {
        value += '*'
      } else {
        break
      }
    } while (true)
    if (valuesBeforeCheck.length > 0) {
      // Restore values just in case
      valuesBeforeCheck.forEach(({ value, row, field }) => {
        full.getList()[row][field] = value
      })
    }
  }
  if (isNumberType(type)) {
    return value === null || value === '' ? null : toNumber(value)
  }
  if (isDateType(type)) {
    return value === null || value === '' ? null : ISODateFromStr(value)
  }
  if (isBooleanType(type)) {
    return toBoolean(value)
  }
  if (isStringType(type)) {
    return value === null ? '' : String(value)
  }
  if (isEnumType(type) && enumValues) {
    const values = enumValues.split(',')
    const lowerCaseValues = values.map((value) => value.toLowerCase().trim())
    let index = -1
    if (typeof value === 'string') {
      index = lowerCaseValues.indexOf(value.toLowerCase().trim())
    }
    return index === -1 ? values[0] ?? null : values[index]
  }
  return value
}

const revert = (bundle) => {
  const keys = Object.keys(bundle)
  if (keys.includes('element') && keys.includes('origin')) {
    const { element, origin } = bundle
    bundle.element = origin
    bundle.origin = element
  }
  if (keys.includes('children')) {
    bundle.children.map(revert)
  }
  return bundle
}

export const revertBundle = (bundle) => revert(merge({}, bundle))

const lengthThenStringCompare = (a, b) => {
  if (a.length !== b.length) {
    return a.length - b.length
  }
  return a.localeCompare(b)
}

export const newElementName = (list, nameIdx, prefix, nameFld, letters) => {
  const [ minId, maxId ] = list.findRangeByPrefix(prefix, nameFld, true)
  if (maxId === null) {
    return letters ? `${prefix}A` : makeId(prefix, 1)
  } else {
    const names = []
    const arr = list.getList()
    const index = list.getIndex(nameFld)
    for (let i = minId; i <= maxId; i++) {
      const name = arr[index[i]][nameIdx]
      if (letters || !isNaN(Number(name.slice(prefix.length)))) {
        names.push(name)
      }
    }
    names.sort(lengthThenStringCompare)
    const lastName = names[names.length - 1]
    return letters
      ? `${prefix}${nextLetterIndex(lastName, prefix)}`
      : makeId(prefix, Number(lastName?.slice(prefix.length) ?? 0) + 1)
  }
}

export const selectMaxName = (a, b) => lengthThenStringCompare(a, b) > 0 ? a : b

export const coverageNameComparator = (reverse = false) => (a, b) => {
  const regex = /\d+/g
  const aNumbers = a?.name.match(regex)
  const bNumbers = b?.name.match(regex)
  const aValue = aNumbers && aNumbers.length > 0 ? Number(aNumbers[aNumbers.length - 1]) : 0
  const bValue = bNumbers && bNumbers.length > 0 ? Number(bNumbers[bNumbers.length - 1]) : 0
  if (reverse) {
    return (aValue > bValue) ? 1 : ((bValue > aValue) ? -1 : 0)
  } else {
    return (aValue < bValue) ? 1 : ((bValue < aValue) ? -1 : 0)
  }
}
