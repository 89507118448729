import React, { useCallback, useMemo, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  selectBaseStations,
  selectBaseStationFields,
  editBaseStationField,
  setBaseStationEditor,
  selectBaseStationFldIdx,
  reloadNetworkElements,
} from '../../features/network/networkSlice'
import { setPanel } from '../../features/panel/panelSlice'
import Table from '../../components/common/Table'
import { DATA_TYPES } from '../../constants/common'
import { RBS_STATUS_FIELD } from '../../constants/network'
import { initColumnsGrid } from './utils'

const SUB_TYPES = {
  [RBS_STATUS_FIELD]: 'status',
}

const BaseStations = () => {
  const dispatch = useDispatch()
  const refHot = useRef()

  const data = useSelector(selectBaseStations)
  const columnsInit = useSelector(selectBaseStationFields)
  const [ idFieldIdx ] = useSelector(selectBaseStationFldIdx, shallowEqual)

  const columns = useMemo(() => initColumnsGrid(columnsInit, SUB_TYPES), [ columnsInit ])

  const initialCell = useMemo(() => ({ row: 0, col: 1 }), [])

  const recordEdit = useCallback(async (row) => {
    await dispatch(setBaseStationEditor(row))
    dispatch(setPanel('edit-rbs'))
  }, [ dispatch ])

  const inlineEdit = useCallback((id, field, value) => {
    return dispatch(editBaseStationField({ id, field, value })).then((res) => {
      if (res.error) {
        dispatch(reloadNetworkElements(res))
      }
      return res
    })
  }, [ dispatch ])

  return (
    <Table
      data={data}
      columns={columns}
      idFieldIdx={idFieldIdx}
      onRecordEdit={recordEdit}
      onInlineEdit={inlineEdit}
      refHot={refHot}
      dataType={DATA_TYPES.BASE_STATIONS}
      readOnly
      manualColumnMove
      initialCell={initialCell}
    />
  )
}

export default BaseStations
