import { createSlice } from '@reduxjs/toolkit'
import { saveBundles } from '../network/mutations'

const initialState = {
  records: [],
  position: 0,
}

const undoSlice = createSlice({
  name: 'undo',
  initialState,
  reducers: {
    addUndoRecord: (state, action) => {
      state.records.splice(state.position, Infinity, action.payload)
      state.position = state.records.length
    },
    clearUndoRecords: (state, action) => {
      state.records = []
      state.position = 0
    },
    setPosition: (state, action) => {
      state.position = action.payload
    },
  },
})

const { setPosition } = undoSlice.actions

export const { addUndoRecord, clearUndoRecords } = undoSlice.actions

export const selectUndoPosition = (state) => state.undo.position
export const selectCanUndo = (state) => state.undo.records.length > 0 && state.undo.position > 0
export const selectCanRedo = (state) => state.undo.records.length > 0 && state.undo.position < state.undo.records.length

export const doUndo = () => async (dispatch, getState) => {
  const state = getState()
  if (selectCanUndo(state)) {
    const record = state.undo.records[state.undo.position - 1]
    await dispatch(saveBundles(record.undoBundles, false))
    dispatch(setPosition(state.undo.position - 1))
  }
}

export const doRedo = () => async (dispatch, getState) => {
  const state = getState()
  if (selectCanRedo(state)) {
    const record = state.undo.records[state.undo.position]
    await dispatch(saveBundles(record.redoBundles, false))
    dispatch(setPosition(state.undo.position + 1))
  }
}

export default undoSlice.reducer
