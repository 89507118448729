import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useBoolean } from '@fluentui/react-hooks'
import { initUpdateDB } from '../../features/updateProject/update'
import { activeProjectId, forceProjectReload } from '../../features/projects/projectsSlice'
import { reloadDefaultBC } from '../../features/bc/bcSlice'
import { COLUMNS_UPDATE, COLUMNS_UPDATE_BC, TABLES_UPDATE } from '../../constants/updateDB'
import { useConfirm } from '../common/Confirm'
// import { _generateUpdateBC } from '../../api/updateDB'
import {
  addTaskType,
  setTaskTypeCompleted,
  setTaskTypeFailed,
  TASK_TYPES,
} from '../../features/taskLog'
import { initializeData } from './common/utils'
import Component from './Component'
import {
  stateCheck, initColumnsClear, initColumnsConflict, initColumnsSimultaneous, initColumnsUpdateBC,
  NOT_SELECT, RELOAD_COMMAND, TITLE, BUTTON_KEY,
} from './constants'

const UpdateDB = ({ onClose, isChanged }) => {
  const dispatch = useDispatch()
  const { renderConfirm, confirm, msg } = useConfirm()

  const projectId = useSelector(activeProjectId)

  const [ itemsClear, setItemsClear ] = useState([])
  const [ itemsConflict, setItemsConflict ] = useState([])
  const [ itemsSimultaneous, setItemsSimultaneous ] = useState([])
  const [ itemsUpdateBC, setItemsUpdateBC ] = useState([] /* _generateUpdateBC() */)
  const [ columnsClear, setColumnsClear ] = useState(initColumnsClear)
  const [ columnsSimultaneous, setColumnsSimultaneous ] = useState(initColumnsSimultaneous)
  const [ columnsConflict, setColumnsConflict ] = useState(initColumnsConflict)
  const [ columnsUpdateBC, setColumnsUpdateBC ] = useState(initColumnsUpdateBC)
  const [ loading, { setTrue: setLoading, setFalse: setLoaded } ] = useBoolean(false)
  const [ isApplyProject, { setTrue: setApplyProject } ] = useBoolean(false)

  const onCloseHandler = useCallback(() => {
    if (isApplyProject) {
      const funcClose = () => {
        dispatch((forceProjectReload()))
        onClose()
      }
      msg({
        title: 'UpdateDB',
        messages: [ 'Project will be restarted to apply the changes' ],
      }, funcClose)
    } else {
      onClose()
    }
  }, [ onClose, msg, isApplyProject, dispatch ])

  const setAllItems = useCallback((dataAll) => {
    const { clearUpdate, simultaneousUpdate, conflictUpdate, bcUpdate } = dataAll
    setItemsClear(initializeData(clearUpdate))
    setColumnsClear(initColumnsClear)
    setItemsSimultaneous(initializeData(simultaneousUpdate))
    setColumnsSimultaneous(initColumnsSimultaneous)
    setItemsConflict(initializeData(conflictUpdate))
    setColumnsConflict(initColumnsConflict)
    if (Array.isArray(bcUpdate?.bcs)) {
      const bcs = bcUpdate.bcs
      bcs.forEach((item) => {
        item[COLUMNS_UPDATE_BC.SELECT] = false
        item[COLUMNS_UPDATE_BC.DATE_TIME] = new Date(item[COLUMNS_UPDATE_BC.DATE_TIME]).toLocaleString()
      })
      setItemsUpdateBC(bcs)
    }
    setColumnsUpdateBC(initColumnsUpdateBC)
  }, [ setItemsConflict, setItemsSimultaneous, setItemsClear, setItemsUpdateBC ])

  useEffect(() => {
    setLoading()
    initUpdateDB(projectId)
      .then(setAllItems)
      .finally(setLoaded)
  }, [ projectId, setLoaded, setLoading, setAllItems ])

  const onUpdateItem = useCallback((newItem, typeUpdate) => {
    switch (typeUpdate) {
      case TABLES_UPDATE.CLEAR: {
        const {
          columns,
          items,
        } = toggleCheck(itemsClear, columnsClear, newItem)
        setColumnsClear(columns)
        setItemsClear(items)
        break
      }
      case TABLES_UPDATE.CONFLICT: {
        const {
          columns,
          items,
        } = toggleCheck(itemsConflict, columnsConflict, newItem)
        setColumnsConflict(columns)
        setItemsConflict(items)
        break
      }
      case TABLES_UPDATE.SIMULTANEOUS: {
        const {
          columns,
          items,
        } = toggleCheck(itemsSimultaneous, columnsSimultaneous, newItem)
        setColumnsSimultaneous(columns)
        setItemsSimultaneous(items)
        break
      }
      case TABLES_UPDATE.UPDATE_BC: {
        const { items, columns } = changeItemUpdateBC(itemsUpdateBC, newItem, columnsUpdateBC)
        setItemsUpdateBC(items)
        setColumnsUpdateBC(columns)
        break
      }
      default:
    }
  }, [
    columnsClear,
    columnsConflict,
    columnsSimultaneous,
    itemsClear,
    itemsConflict,
    itemsSimultaneous,
    itemsUpdateBC,
    setColumnsUpdateBC,
    columnsUpdateBC,
  ])

  const onUpdateHeader = useCallback((column, isChecked = true, typeUpdate) => {
    const select = column?.key
    if (!select) {
      return
    }
    switch (typeUpdate) {
      case TABLES_UPDATE.CLEAR: {
        setColumnsClear(checkHeader(columnsClear, select, isChecked))
        setItemsClear(radioChecked(itemsClear, select, isChecked))
        break
      }
      case TABLES_UPDATE.CONFLICT: {
        setColumnsConflict(checkHeader(columnsConflict, select, isChecked))
        setItemsConflict(radioChecked(itemsConflict, select, isChecked))
        break
      }
      case TABLES_UPDATE.SIMULTANEOUS: {
        setColumnsSimultaneous(checkHeader(columnsSimultaneous, select, isChecked))
        setItemsSimultaneous(radioChecked(itemsSimultaneous, select, isChecked))
        break
      }
      default:
    }
  }, [ columnsClear, columnsConflict, columnsSimultaneous, itemsClear, itemsConflict, itemsSimultaneous ])

  // Обробка натиснення CheckBox у заголовку
  const onUpdateHeaderBC = useCallback((columnEdit) => {
    setColumnsUpdateBC(columnsUpdateBC.map((column) => (column.key === columnEdit.key ? columnEdit : column)))
    setItemsUpdateBC(allItemsCheck(itemsUpdateBC, columnEdit))
  }, [ columnsUpdateBC, itemsUpdateBC ])

  // Обробка застосування вибраних змін у таблицях
  const onClickApplyUpdate = useCallback((updateDB, key) => {
    if (!updateDB?.then) {
      return
    }
    dispatch(addTaskType(TASK_TYPES.synchronizationDB, key))
    setLoading()
    updateDB.then((res) => {
      setAllItems(res)
      dispatch(setTaskTypeCompleted(TASK_TYPES.synchronizationDB))
    }, (res) => {
      confirm(null, {
        title: 'Applying changes',
        messages: [ 'Error', res.message ?? 'The request ended with an error' ],
        textNoBtn: 'Cancel',
      })
      dispatch(setTaskTypeFailed(TASK_TYPES.synchronizationDB))
    }).finally(() => {
      setLoaded()
      if (RELOAD_COMMAND.includes(key)) {
        setApplyProject()
      } else {
        if (key === BUTTON_KEY.UPDATE_BC) {
          // Refresh the list of BC from defult project
          dispatch(reloadDefaultBC())
        }
      }
    })
  }, [ dispatch, setLoading, setAllItems, confirm, setLoaded, setApplyProject ])

  return (
    <Component
      title={TITLE}
      value={null}
      busy={loading}
      projectId={projectId}
      onClose={onCloseHandler}
      isChanged={isChanged}
      itemsClear={itemsClear}
      itemsConflict={itemsConflict}
      itemsSimultaneous={itemsSimultaneous}
      itemsUpdateBC={itemsUpdateBC}
      columnsClear={columnsClear}
      columnsSimultaneous={columnsSimultaneous}
      columnsConflict={columnsConflict}
      columnsUpdateBC={columnsUpdateBC}
      onUpdateItem={onUpdateItem}
      onUpdateHeader={onUpdateHeader}
      onUpdateHeaderBC={onUpdateHeaderBC}
      onClickApplyUpdate={onClickApplyUpdate}
      dialogConfirm={renderConfirm}
    />
  )
}

export default UpdateDB

const checkHeader = (columns, columnKey, isChecked = true) => {
  return columns.map((currCol) => {
    if (currCol?.data?.isChecked != null) {
      return {
        ...currCol,
        data: currCol.key === columnKey
          ? (isChecked ? stateCheck.checked : stateCheck.unchecked)
          : (isChecked ? stateCheck.unchecked : currCol.data),
      }
    }
    return currCol
  })
}

const radioChecked = (items, select, isChecked) => items.map((item) => {
  if (item[select] === null || (
    select === COLUMNS_UPDATE.ORIGINAL_VALUE &&
    item[COLUMNS_UPDATE.ORIGINAL_VALUE] === item[COLUMNS_UPDATE.NEW_VALUE] &&
    item[COLUMNS_UPDATE.DB_VALUE] === null
  )) {
    return item
  }
  return {
    ...item,
    [COLUMNS_UPDATE.SELECT]: isChecked
      ? select
      : item[COLUMNS_UPDATE.SELECT] === select
        ? NOT_SELECT
        : item[COLUMNS_UPDATE.SELECT],
  }
})

const allItemsCheck = (items, column) => {
  if (!column) {
    return items
  }
  const fieldName = column.key
  const value = column.select
  return items.map((item) => ({
    ...item,
    [fieldName]: value,
  }))
}

const toggleCheck = (items, columns, newItem) => {
  let checkAllOriginalValue = true
  let unCheckAllOriginalValue = true
  let checkAllNewValue = true
  let unCheckAllNewValue = true
  let checkAllDBValue = true
  let unCheckAllDBValue = true
  const newItems = items.map((item) => {
    const setItem = item.key === newItem.key ? newItem : item
    checkAllOriginalValue &= setItem.select === COLUMNS_UPDATE.ORIGINAL_VALUE
    unCheckAllOriginalValue &= setItem.select !== COLUMNS_UPDATE.ORIGINAL_VALUE
    checkAllNewValue &= setItem.select === COLUMNS_UPDATE.NEW_VALUE
    unCheckAllNewValue &= setItem.select !== COLUMNS_UPDATE.NEW_VALUE
    checkAllDBValue &= setItem.select === COLUMNS_UPDATE.DB_VALUE
    unCheckAllDBValue &= setItem.select !== COLUMNS_UPDATE.DB_VALUE
    return setItem
  })
  const stateOriginalValue = checkAllOriginalValue
    ? 'checked'
    : (unCheckAllOriginalValue ? 'unchecked' : 'indeterminate')
  const stateNewValue = checkAllNewValue
    ? 'checked'
    : (unCheckAllNewValue ? 'unchecked' : 'indeterminate')
  const stateDBValue = checkAllDBValue
    ? 'checked'
    : (unCheckAllDBValue ? 'unchecked' : 'indeterminate')
  const newColumns = columns.map((column) => {
    if (column.data) {
      // CheckBox-стовпчик
      if (column.key === COLUMNS_UPDATE.ORIGINAL_VALUE) {
        return {
          ...column,
          data: stateCheck[stateOriginalValue],
        }
      }
      if (column.key === COLUMNS_UPDATE.NEW_VALUE) {
        return {
          ...column,
          data: stateCheck[stateNewValue],
        }
      }
      if (column.key === COLUMNS_UPDATE.DB_VALUE) {
        return {
          ...column,
          data: stateCheck[stateDBValue],
        }
      }
    }
    return column
  })
  return { items: newItems, columns: newColumns }
}

const changeItemUpdateBC = (items, newItem, columns) => {
  // Скидання стану CheckBox у заголовку
  const newColumns = columns.map((column) => {
    if (column.isSelectable) {
      return {
        ...column,
        select: true,
        indeterminate: false,
      }
    }
    return column
  })

  // Внесення змін до атрибутів (select, groupVisible) елемента таблиці
  const newItems = items.map((item) => {
    const setItem = item[COLUMNS_UPDATE_BC.ID] === newItem[COLUMNS_UPDATE_BC.ID] ? newItem : item
    // Визначення стану CheckBox у заголовку. Перевірка на повний вибір стовпчика.
    newColumns.forEach((column) => {
      if (column.isSelectable) {
        column.select &&= !!setItem[COLUMNS_UPDATE_BC.SELECT]
        column.indeterminate ||= !!setItem[COLUMNS_UPDATE_BC.SELECT]
      }
    })
    return setItem
  })
  return { items: newItems, columns: newColumns }
}
