import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { MessageBar, MessageBarType } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useDispatch, useSelector } from 'react-redux'
import DialogFilter from '../../components/Filter/DialogFilter'
import {
  addFilterToTable, dataFilteringByType, resetFilterInTable, selectFilters,
} from '../../features/filters/filters'
import {
  NESTED_HEADER_DELIMITER, exportTableData, normalizeDouble, readFileContentCSV, readFileContentXLSX,
} from '../../utils/export'
import { MENU_FILTER_KEYS } from '../Filter/constant'
import { filterAssembly } from '../../features/network/filtration'
import { resetGroupFilters, selectGroupFilters } from '../../features/network/networkSlice'
import { SECTOR_NAME_FIELD, SITE_NAME_FIELD, STATUS_ACTIVE, STATUS_DRAFT } from '../../constants/network'
import { DATA_TYPES } from '../../constants/common'
import { isVectorMap } from '../../features/vector/utils'
import {
  TASK_TYPES, setTaskTypeCompleted, setTaskTypeFailed, addTaskType, setTaskCompleted, setTaskFailed,
} from '../../features/taskLog'
import { EVENT_TYPE, saveEventLog, wrapByEventLog } from '../../features/eventLog/eventLogSlice'
import { selectPanel } from '../../features/panel/panelSlice'
import { selectOfflineMode } from '../../features/loading/loadingSlice'
import { useHandler } from '../../hooks'
import { existsContextMenu, genContextMenu } from '../Tables/contextMenu'
import {
  saveSettingToServer, selectSettings, settingPropertyOnPath, SETTINGS_KEYS,
} from '../../features/settings/settingsSlice'
import { EXPORT_FORMAT } from '../../constants/menus'
import HeaderContext from '../../layout/context/HeaderContext'
import {
  AdvancedFilterIcon, ClipboardIcon, CSVIcon, EntireTableIcon, ExceptSelectedIcon, KMLIcon, KMZIcon, MIDMIFIcon,
  MSExcelIcon, OnlySelectedIcon, ResetFilterIcon, SelectedRangeIcon,
} from './icons/names'
import { useConfirm } from './Confirm'
import Grid, { addRowChange } from './Grid'
import { getTypeCellsSelected } from './utils/hotTable'
import GroupFilterControl from './GroupFilterControl'

const TOAST_TIME = 3000

// const normalizeIndex = (length, index) => ((index % length) + length) % length

const valueEqualityByType = (type, oldValue, newValue) => {
  switch (type) {
    case 'date':
    case 'int':
    case 'double': {
      if ((oldValue === null || oldValue === '') && (newValue === 'null' || newValue === null || newValue === '')) {
        return true
      }
      return oldValue === newValue
    }
    case 'string': {
      return oldValue === newValue
    }
    default:
  }
  return oldValue === newValue
}

const clearValue = (event) => {
  event.target.value = null
}

const Table = ({
  data,
  columns,
  onRecordEdit,
  openDialogFilter,
  onButtonFilter,
  refHot,
  dataType,
  label,
  idFieldIdx,
  statusFieldIdx,
  onInlineEdit,
  onInlineEditCell,
  onInlineEditCells,
  onInlineEditRange,
  onImport,
  onExport,
  onImportOptions,
  onExportOptions,
  onImportStarted,
  onImportFinished,
  onExportStarted,
  onExportFinished,
  addRowsOptions,
  onAddRows,
  onSelectionEnd,
  onCanDeleteRows,
  onDeleteRows,
  cells,
  disableSettings,
  readOnly,
  needToExport,
  ignoreWhenExport,
  importItemId,
  importRawNumbers,
  onCallbackContextMenu,
  contextMenuDisabled,
  contextMenuHidden,
  contextMenuBusy,
  setImportMenu,
  setExportMenu,
  setFilterMenu,
  ...rest
}) => {
  const dispatch = useDispatch()
  const { renderConfirm, msg } = useConfirm()

  const [ isSelectedCell, setIsSelectedCell ] = useState(false)
  const [ dialogSpecialFiltering, {
    setTrue: showDialogSpecialFiltering,
    setFalse: hideDialogSpecialFiltering,
  } ] = useBoolean(false)
  const [ toast, setToast ] = useState(null)
  const [ currentFilter, setCurrentFilter ] = useState({})
  const [ isOneRowSelected, setIsOneRowSelected ] = useState(false)
  const [ isOneColSelected, setIsOneColSelected ] = useState(false)
  const [ isAllowRemoveItems, setAllowRemoveItems ] = useState(undefined)

  const panel = useSelector(selectPanel)
  // Визначення колонок, що беруть участь у фільтрації
  const filters = useSelector(selectFilters(dataType))
  const groupFilter = useSelector(selectGroupFilters(dataType))
  const { colNotDisplay } = useSelector(selectSettings)
  const offlineMode = useSelector(selectOfflineMode)

  const isExportGeoJson = useMemo(() => isVectorMap(dataType) || dataType === DATA_TYPES.COMPLAINTS, [ dataType ])

  const resetSelection = useCallback(() => {
    const deselect = refHot.current?.hotInstance?.deselectCell
    deselect && deselect()
  }, [ refHot ])

  const getRecordId = useCallback((row) => {
    const table = refHot.current.hotInstance
    return data.getList()[table.toPhysicalRow(row)]?.[idFieldIdx]
  }, [ idFieldIdx, refHot, data ])

  const recordEdit = useCallback(() => {
    if (refHot && onRecordEdit) {
      const table = refHot.current.hotInstance
      const [ [ row ] ] = table.getSelected() || []
      const id = getRecordId(row)
      onRecordEdit(id)
    }
  }, [ onRecordEdit, getRecordId, refHot ])

  const inlineEdit = useCallback((blockData) => {
    if (blockData?.length > 1 && onInlineEditCells) {
      const updated = []
      blockData.forEach((item) => {
        const [ row, field, oldValue, value ] = item
        const type = columns[field]?.type
        const normalized = type === 'double'
          ? normalizeDouble(value)
          : (type === 'boolean' ? stringValueToBoolean(value) : value)
        updated.push({ row, field, oldValue, normalized })
      })
      if (updated.length > 0) {
        onInlineEditCells(updated)
        return
      }
    }

    if (blockData?.length > 1 && onInlineEditRange) { // Оновлення даних блоком
      const changes = []
      const list = data.getList()
      const table = refHot.current.hotInstance
      let addedChanges = 0
      // Збирання блоку змінених даних
      blockData.forEach((updatedCell) => {
        const [ row, field, oldValue, value ] = updatedCell
        if (oldValue === value) {
          return
        }
        const change = addRowChange(changes, table, list, row, idFieldIdx)
        change.updates.push({
          field,
          value,
          oldValue,
          row,
          physicalRow: table.toPhysicalRow(row),
        })
        ++addedChanges
      })

      if (addedChanges) {
        onInlineEditRange(changes)

        // Try to clear the selection if Name field has modified
        const nameFldId = dataType === DATA_TYPES.SITES
          ? SITE_NAME_FIELD
          : dataType === DATA_TYPES.SECTORS
            ? SECTOR_NAME_FIELD
            : null
        let selected = null
        if (changes && nameFldId && changes.findIndex(
          ({ updates }) => updates.findIndex(({ row, field }) => {
            if (columns[field]?.id === nameFldId) {
              selected = row
              return true
            }
            return false
          }) !== -1) !== -1
        ) {
          // Name column has been changed
          const table = refHot.current?.hotInstance
          const sorting = table.getPlugin('columnSorting')
          if (sorting.isSorted()) {
            const column = sorting.getSortConfig()?.[0].column
            const physicalColumn = table.toPhysicalColumn(column)
            if (columns[physicalColumn]?.id === nameFldId && selected !== null) {
              // Name column is sorted
              // Try to clear selection
              table.selectCell(selected, column)
            }
          }
        }
      }
      return
    }

    blockData.forEach((updatedCell) => {
      const [ row, field, oldValue, value ] = updatedCell
      const type = columns[field]?.type
      if (refHot && (onInlineEdit || onInlineEditCell) && !valueEqualityByType(type, oldValue, value)) {
        if (onInlineEdit && value?.toString() !== oldValue?.toString()) {
          const id = getRecordId(row)
          onInlineEdit(id, field, value, oldValue)
        }
        if (onInlineEditCell) {
          const normalized = type === 'double'
            ? normalizeDouble(value)
            : type === 'boolean'
              ? stringValueToBoolean(value)
              : value
          onInlineEditCell(row, field, oldValue, normalized)
        }
      }
    })
  }, [
    onInlineEditCells, onInlineEditRange, columns, data, refHot, idFieldIdx, onInlineEdit, onInlineEditCell,
    getRecordId, dataType,
  ])

  const dblClick = useCallback(() => setTimeout(() => {
    const table = refHot.current.hotInstance
    const [ [ row, col ] ] = table.getSelected() || []
    const physicalColumn = table.toPhysicalColumn(col)
    if (columns[physicalColumn].editableDraft) {
      const physicalRow = table.toPhysicalRow(row)
      const status = data.getList()[physicalRow]?.[statusFieldIdx]
      if (status === STATUS_DRAFT) {
        return
      }
    }
    recordEdit()
  }, 100), [ recordEdit, refHot, data, columns, statusFieldIdx ])

  const handleOnAddRows = useCallback((num) => () => {
    if (dataType) {
      // TODO Update state with dataType
    }
    if (onAddRows) {
      onAddRows(num)
    } else if (refHot?.current) {
      const table = refHot?.current?.hotInstance
      const numOfRows = table.countRows()
      table.alter('insert_row', numOfRows, num)
      table.selectCell(numOfRows + num - 1, 0)
      table.deselectCell()
    }
  }, [ onAddRows, dataType, refHot ])

  const filteredColumns = useMemo(() => {
    const rules = filters?.comparisonRules
    if (!Array.isArray(rules)) {
      return []
    }
    const filtered = []
    rules.forEach((rule) => {
      if (Array.isArray(rule)) {
        rule.forEach((item) => {
          if (item.columnIndex != null && !filtered.includes(item.columnIndex)) {
            filtered.push(item.columnIndex)
          }
        })
      }
    })
    return filtered
  }, [ filters ])

  // Обробка вибору пункту меню фільтрації
  const onClickMenuFilter = useCallback((_, item) => {
    if (!refHot || !columns || !dispatch || !dataType) {
      return
    }

    dispatch(saveEventLog(EVENT_TYPE.tableFilter, ` [DataType = ${dataType}; Operation = ${item?.key}]`))
    switch (item?.key) {
      case MENU_FILTER_KEYS.ONLY_SELECTED:
      case MENU_FILTER_KEYS.EXCEPT_SELECTED: {
        const comparisonRules = filterAssembly(
          refHot,
          item?.key === MENU_FILTER_KEYS.ONLY_SELECTED ? 'equal' : 'not_equal',
          columns,
        )
        if (comparisonRules?.error) {
          msg({ messages: [ comparisonRules?.error ] })
          break
        }
        const inversion = false
        // Додавання нового фільтра до старого
        dispatch(addFilterToTable({
          dataType,
          filter: {
            inversion,
            comparisonRules,
          },
        })).then(
          () => dispatch(dataFilteringByType(dataType)),
          (error) => msg({ messages: [ error?.message ] }),
        )
        break
      }
      case MENU_FILTER_KEYS.RESET_FILTER: {
        dispatch(resetFilterInTable(dataType))
        dispatch(dataFilteringByType(dataType))
        break
      }
      case MENU_FILTER_KEYS.SPECIAL_FILTERING: {
        setCurrentFilter(filters)
        showDialogSpecialFiltering()
        break
      }
      default:
    }
    resetSelection()
  }, [ columns, dataType, dispatch, filters, refHot, resetSelection, showDialogSpecialFiltering, msg ])

  // Скидання групового фільтра в таблиці
  const onClickResetFilterGroup = useCallback(() => dispatch(resetGroupFilters(dataType)), [ dispatch, dataType ])

  // Відстежування наявності вибраних комірок у таблиці
  const handleOnSelectionEnd = useCallback((...props) => {
    const selectedTab = refHot.current?.hotInstance?.getSelected()
    if (Array.isArray(selectedTab)) {
      const { isOneRowSelected, isSelectedCell, isOneColSelected } =
        getTypeCellsSelected(selectedTab, columns, statusFieldIdx, refHot)
      setIsOneRowSelected(isOneRowSelected)
      setIsSelectedCell(isSelectedCell)
      setIsOneColSelected(isOneColSelected)

      const table = refHot?.current?.hotInstance
      if (data && data.getList) {
        const allowRemoveValue = selectedTab.some((select) => {
          for (let row = select[0]; row <= select[2]; ++row) {
            const status = statusFieldIdx >= 0 ? data.getList()[table.toPhysicalRow(row)]?.[statusFieldIdx] : null
            for (let col = select[1]; col <= select[3]; ++col) {
              if (col >= 0) {
                const physicalColumn = table.toPhysicalColumn(col)
                let editable = false
                if (status === STATUS_ACTIVE) {
                  editable = columns[physicalColumn].editable
                } else if (status === STATUS_DRAFT) {
                  editable = columns[physicalColumn].editableDraft
                }
                const requiredNonEmpty = columns[physicalColumn].requiredNonEmpty
                if (editable && !requiredNonEmpty) {
                  return true
                }
              }
            }
          }
          return false
        })
        setAllowRemoveItems(allowRemoveValue)
      }
    }
    onSelectionEnd && onSelectionEnd(...props)
  }, [ onSelectionEnd, refHot, data, columns, statusFieldIdx ])

  const makeToast = useCallback((type, text) => {
    setToast({ type, text })
    setTimeout(() => setToast(null), TOAST_TIME)
  }, [ setToast ])

  const exportData = useCallback(async (format, range) => {
    const table = refHot.current.hotInstance
    const nestedHeaders = refHot?.current?.props?.settings?.nestedHeaders
    dispatch(addTaskType(TASK_TYPES.export, dataType))
    try {
      const resMessage = await dispatch(wrapByEventLog({
        type: EVENT_TYPE.exportData,
        details: `DataType = ${dataType}; Format = ${format}; Range = ${range}`,
        action: () => exportTableData(format, range, table, dataType,
          needToExport, ignoreWhenExport, nestedHeaders, columns, onExport, label),
        extractor: (resMessage) => resMessage,
      }))
      if (resMessage) {
        makeToast(MessageBarType.success, resMessage)
      }
      dispatch(setTaskTypeCompleted(TASK_TYPES.export))
    } catch (error) {
      msg({ title: 'Error', messages: [ error.message ] })
      dispatch(setTaskTypeFailed(TASK_TYPES.export))
    }
  }, [ dispatch, refHot, makeToast, dataType, needToExport, ignoreWhenExport, msg, columns, onExport, label ])

  const exportToClipboard = useCallback(() => exportData(EXPORT_FORMAT.TXT, false), [ exportData ])
  const exportToXLSX = useCallback(() => exportData(EXPORT_FORMAT.XLSX, false), [ exportData ])
  const exportToCSV = useCallback(() => exportData(EXPORT_FORMAT.CSV, false), [ exportData ])
  const exportToClipboardRange = useCallback(() => exportData(EXPORT_FORMAT.TXT, true), [ exportData ])
  const exportToXLSXRange = useCallback(() => exportData(EXPORT_FORMAT.XLSX, true), [ exportData ])
  const exportToCSVRange = useCallback(() => exportData(EXPORT_FORMAT.CSV, true), [ exportData ])
  const exportToMIFMID = useCallback(() => exportData(EXPORT_FORMAT.MIF_MID, false), [ exportData ])
  const exportToKML = useCallback(() => exportData(EXPORT_FORMAT.KML, false), [ exportData ])
  const exportToKMZ = useCallback(() => exportData(EXPORT_FORMAT.KMZ, false), [ exportData ])

  const importData = useCallback(async (headerNames, cells) => {
    const id = uuid()
    dispatch(addTaskType(TASK_TYPES.import, dataType, id))
    const headers = headerNames.map((name) => {
      const nameArray = name.split(NESTED_HEADER_DELIMITER)
      const parentHeader = nameArray.length > 1 ? nameArray[nameArray.length - 2] : null
      const header = nameArray[nameArray.length - 1]
      const column = columns
        .find(({ id, label }) => label === header && (!parentHeader || id.includes(parentHeader.toLowerCase()))) || null
      if (!column) {
        console.warn(`Column "${header}" not found`)
      }
      return column
    })
    try {
      const result = await dispatch(wrapByEventLog({
        type: EVENT_TYPE.importData,
        details: `DataType = ${dataType}`,
        action: () => onImport(headers, cells),
        extractor: (resMessage) => resMessage,
      }))
      dispatch((typeof result === 'boolean' && !result ? setTaskFailed : setTaskCompleted)(id))
    } catch (error) {
      dispatch(setTaskFailed(id))
      msg({ title: 'Error', messages: [ error.message ] })
    }
  }, [ dispatch, msg, columns, onImport, dataType ])

  const importFromClipboard = useCallback(async () => {
    onImportStarted && onImportStarted()
    const text = await navigator.clipboard.readText()
    if (text) {
      const [ headersText, ...cellsText ] = text.trim().split('\r\n')
      const headerNames = headersText.split('\t')
      const minLength = headerNames.length
      const cells = cellsText.map((row) => {
        const dataArray = row.split('\t')
        if (dataArray.length < minLength) {
          dataArray.push(...new Array(minLength - dataArray.length).fill(''))
        }
        return dataArray
      })
      await importData(headerNames, cells)
    }
    onImportFinished && onImportFinished()
  }, [ importData, onImportStarted, onImportFinished ])

  const csvFileInputId = useMemo(() => importItemId ? `csv-file-input-${importItemId}` : 'csv-file-input', [ importItemId ])
  const xlsxFileInputId = useMemo(() => importItemId ? `xlsx-file-input-${importItemId}` : 'xlsx-file-input', [ importItemId ])

  const importFromCSV = useCallback(() => {
    onImportStarted && onImportStarted()
    document.getElementById(csvFileInputId).click()
  }, [ csvFileInputId, onImportStarted ])

  const importFromXLSX = useCallback(() => {
    onImportStarted && onImportStarted()
    document.getElementById(xlsxFileInputId).click()
  }, [ xlsxFileInputId, onImportStarted ])

  const csvFileChange = useCallback(async (e) => {
    const input = document.getElementById(csvFileInputId)
    const [ headerNames, ...cells ] = await readFileContentCSV(input.files[0])
    await importData(headerNames, cells)
    onImportFinished && onImportFinished()
  }, [ importData, csvFileInputId, onImportFinished ])

  const xlsxFileChange = useCallback(async (e) => {
    const input = document.getElementById(xlsxFileInputId)
    const [ headerNames, ...cells ] = await readFileContentXLSX(input.files[0], importRawNumbers)
    await importData(headerNames, cells)
    onImportFinished && onImportFinished()
  }, [ importData, xlsxFileInputId, onImportFinished, importRawNumbers ])

  const stringValueToBoolean = (value) => {
    if (value === '' || value === '0' || value === '0.0' || value === '0,0') {
      return false
    }
    return !!value
  }

  const handleOnDeleteRows = useCallback(() => {
    const table = refHot.current.hotInstance
    const [ [ rowStart,, rowFinish ] ] = table.getSelected()
    if (onCanDeleteRows(rowStart, rowFinish)) {
      onDeleteRows(rowStart, rowFinish)
    } else {
      msg({ messages: [ 'Can not delete selected rows' ] })
    }
  }, [ refHot, onCanDeleteRows, onDeleteRows, msg ])

  const handleOnDeleteLastRows = useCallback(() => onDeleteRows && onDeleteRows(), [ onDeleteRows ])

  // Додавання контекстного меню кнопці Import на панелі інструментів
  useEffect(() => {
    setImportMenu && setImportMenu([
      !readOnly && !offlineMode && onImport && (!onImportOptions || onImportOptions.includes('clip'))
        ? {
            key: 'import-clip',
            text: 'From Clipboard',
            icon: ClipboardIcon,
            onClick: importFromClipboard,
          }
        : null,
      !readOnly && !offlineMode && onImport && (!onImportOptions || onImportOptions.includes('csv'))
        ? {
            key: 'import-csv',
            text: 'From CSV file',
            icon: CSVIcon,
            onClick: importFromCSV,
          }
        : null,
      !readOnly && !offlineMode && onImport && (!onImportOptions || onImportOptions.includes('xlsx'))
        ? {
            key: 'import-xlsx',
            text: 'From MS Excel file',
            icon: MSExcelIcon,
            onClick: importFromXLSX,
          }
        : null,
    ].filter(Boolean))
  }, [
    setImportMenu, importFromClipboard, importFromCSV, importFromXLSX, onImportOptions, onImport, readOnly, offlineMode,
  ])

  // Додавання контекстного меню кнопці Export на панелі інструментів
  useEffect(() => {
    setExportMenu && setExportMenu([
      !onExportOptions || onExportOptions.includes('clip')
        ? {
            key: 'export-clip',
            text: 'To Clipboard',
            icon: ClipboardIcon,
            items: [
              {
                key: 'export-clip-range',
                text: 'Selected range',
                icon: SelectedRangeIcon,
                onClick: exportToClipboardRange,
              },
              {
                key: 'export-clip-entire',
                text: 'Entire table',
                icon: EntireTableIcon,
                onClick: exportToClipboard,
              },
            ],
          }
        : null,
      !onExportOptions || onExportOptions.includes('csv')
        ? {
            key: 'export-csv',
            text: 'To CSV file',
            icon: CSVIcon,
            items: [
              {
                key: 'export-csv-range',
                text: 'Selected range',
                icon: SelectedRangeIcon,
                onClick: exportToCSVRange,
              },
              {
                key: 'export-csv-entire',
                text: 'Entire table',
                icon: EntireTableIcon,
                onClick: exportToCSV,
              },
            ],
          }
        : null,
      !onExportOptions || onExportOptions.includes('xlsx')
        ? {
            key: 'export-xlsx',
            text: 'To MS Excel file',
            icon: MSExcelIcon,
            items: [
              {
                key: 'export-xlsx-range',
                text: 'Selected range',
                icon: SelectedRangeIcon,
                onClick: exportToXLSXRange,
              },
              {
                key: 'export-xlsx-entire',
                text: 'Entire table',
                icon: EntireTableIcon,
                onClick: exportToXLSX,
              },
            ],
          }
        : null,
      ...(isExportGeoJson
        ? [
            {
              key: 'export-mifMid',
              text: 'To MIF/MID files',
              icon: MIDMIFIcon,
              onClick: exportToMIFMID,
            },
            {
              key: 'export-kml',
              text: 'To KML file',
              icon: KMLIcon,
              onClick: exportToKML,
            },
            {
              key: 'export-kmz',
              text: 'To KMZ file',
              icon: KMZIcon,
              onClick: exportToKMZ,
            },
          ]
        : []),
    ].filter(Boolean))
  }, [
    setExportMenu, exportToClipboard, exportToCSV, exportToXLSX, exportToClipboardRange, exportToCSVRange,
    exportToXLSXRange, onExportOptions, isExportGeoJson, exportToMIFMID, exportToKML, exportToKMZ,
  ])

  // Додавання контекстного меню кнопці Filter на панелі інструментів
  useEffect(() => {
    setFilterMenu && setFilterMenu([
      isSelectedCell
        ? {
            key: MENU_FILTER_KEYS.ONLY_SELECTED,
            text: 'Only selected',
            icon: OnlySelectedIcon,
            onClick: onClickMenuFilter,
          }
        : null,
      isSelectedCell
        ? {
            key: MENU_FILTER_KEYS.EXCEPT_SELECTED,
            text: 'Except selected',
            icon: ExceptSelectedIcon,
            onClick: onClickMenuFilter,
          }
        : null,
      isSelectedCell
        ? {} // Роздільник
        : null,
      {
        key: MENU_FILTER_KEYS.SPECIAL_FILTERING,
        text: 'Advanced Filter',
        icon: AdvancedFilterIcon,
        onClick: onClickMenuFilter,
      },
      {
        key: MENU_FILTER_KEYS.RESET_FILTER,
        text: 'Reset Filter',
        icon: ResetFilterIcon,
        onClick: onClickMenuFilter,
        disabled: !filteredColumns?.length,
      },
    ].filter(Boolean))
  }, [ setFilterMenu, isSelectedCell, onClickMenuFilter, filteredColumns ])

  // Обробник контекстного меню
  const callbackContextMenu = useHandler((key, selection, clickEvent) => {
    switch (key) {
      // Команди контекстного меню заголовку стовпця
      case 'moveLeft': {
        const table = refHot.current.hotInstance
        const manualColumnMove = table.getPlugin('manualColumnMove')
        const column = selection[0].start.col
        const notDisplayColumn = colNotDisplay?.[dataType] ?? []
        let dropIndex = column
        let colIdx
        do {
          dropIndex--
          colIdx = table.toPhysicalColumn(dropIndex)
        } while (columns[colIdx]?.hidden === true || notDisplayColumn.includes(columns?.[colIdx]?.id))
        column && manualColumnMove?.moveColumn && manualColumnMove?.dragColumn(column, dropIndex)
        table.selectColumns(dropIndex, dropIndex)
        break
      }
      case 'moveRight': {
        const table = refHot.current.hotInstance
        const manualColumnMove = table.getPlugin('manualColumnMove')
        const column = selection[0].start.col
        const notDisplayColumn = colNotDisplay?.[dataType] ?? []
        let dropIndex = column
        let colIdx
        do {
          dropIndex++
          colIdx = table.toPhysicalColumn(dropIndex)
        } while (columns[colIdx]?.hidden === true || notDisplayColumn.includes(columns?.[colIdx]?.id))
        column && manualColumnMove?.moveColumn && manualColumnMove?.dragColumn(column, dropIndex + 1)
        table.selectColumns(dropIndex, dropIndex)
        break
      }
      case 'hideColumn': {
        const table = refHot.current.hotInstance
        const colIdx = table.toPhysicalColumn(selection[0].start.col)
        const colId = columns?.[colIdx]?.id
        const colNotDisplayNew = Array.isArray(colNotDisplay?.[dataType]) ? [ ...(colNotDisplay[dataType]) ] : []
        if (colNotDisplayNew?.includes && !colNotDisplayNew.includes(colId)) {
          colNotDisplayNew.push(colId)
          dispatch(settingPropertyOnPath(colNotDisplayNew, [ SETTINGS_KEYS.COL_NOT_DISPLAY, dataType ]))
          dispatch(saveSettingToServer)
        }
        break
      }
      // Команди контекстного меню комірки таблиці
      case 'open': {
        recordEdit()
        break
      }
      case 'addRow': {
        handleOnAddRows(1)()
        break
      }
      case 'add5Rows': {
        handleOnAddRows(5)()
        break
      }
      case 'add10Rows': {
        handleOnAddRows(10)()
        break
      }
      case 'deleteRow': {
        handleOnDeleteRows()
        break
      }
      case 'deleteLastRow': {
        handleOnDeleteLastRows()
        break
      }
      case 'paste': {
        const table = refHot.current.hotInstance
        const copyPaste = table.getPlugin('copyPaste')
        navigator.clipboard.readText().then((text) => {
          text && copyPaste?.paste && copyPaste.paste(text)
        })
        break
      }
      case 'quickFilters:onlySelected': {
        onClickMenuFilter(null, { key: MENU_FILTER_KEYS.ONLY_SELECTED })
        break
      }
      case 'quickFilters:exceptSelected': {
        onClickMenuFilter(null, { key: MENU_FILTER_KEYS.EXCEPT_SELECTED })
        break
      }
      case 'sortAscending':
      case 'sort:ascending': {
        const table = refHot.current.hotInstance
        const sorting = table.getPlugin('columnSorting')
        const column = selection[0].start.col
        column != null && sorting?.sort && sorting.sort({ column, sortOrder: 'asc' })
        break
      }
      case 'sortDescending':
      case 'sort:descending': {
        const table = refHot.current.hotInstance
        const sorting = table.getPlugin('columnSorting')
        const column = selection[0].start.col
        column != null && sorting?.sort && sorting.sort({ column, sortOrder: 'desc' })
        break
      }
      case 'exportActions:exportClipRange': {
        exportToClipboardRange()
        break
      }
      case 'exportActions:exportClipEntire': {
        exportToClipboard()
        break
      }
      case 'exportActions:exportXlsxRange': {
        exportToXLSXRange()
        break
      }
      case 'exportActions:exportXlsxEntire': {
        exportToXLSX()
        break
      }
      default: {
        onCallbackContextMenu && onCallbackContextMenu(key, selection, clickEvent, refHot)
      }
    }
  })

  const contextMenu = useMemo(() => {
    if (existsContextMenu(dataType)) {
      const disabled = {
        open: !isOneRowSelected,
        hideColumn: !isOneColSelected,
        sort: !isOneColSelected,
        sortAscending: !isOneColSelected,
        sortDescending: !isOneColSelected,
        ...(contextMenuDisabled || {}),
      }

      if (isAllowRemoveItems !== undefined) {
        if (!disabled.cut && !isAllowRemoveItems) {
          disabled.cut = true // Disable cut for non editable and requiredNonEmpty fields
        }
      }

      return {
        callback: callbackContextMenu,
        ...genContextMenu(
          dataType,
          disabled,
          contextMenuHidden,
          contextMenuBusy,
        ),
      }
    }
  }, [
    callbackContextMenu, dataType, isOneColSelected, isOneRowSelected, contextMenuDisabled, contextMenuHidden,
    contextMenuBusy, isAllowRemoveItems,
  ])

  return (
    <div className="full-height-wrap">
      {renderConfirm()}
      {toast && (
        <MessageBar messageBarType={toast.type} isMultiline={true}>
          {toast.text}
        </MessageBar>
      )}
      <input
        type="file"
        id={csvFileInputId}
        accept=".csv"
        style={{ display: 'none' }}
        onChange={csvFileChange}
        onClick={clearValue}
      />
      <input
        type="file"
        id={xlsxFileInputId}
        accept=".xlsx"
        style={{ display: 'none' }}
        onChange={xlsxFileChange}
        onClick={clearValue}
      />
      <Grid
        data={data}
        columns={columns}
        onDblClick={dblClick}
        onInlineEdit={inlineEdit}
        refHot={refHot}
        dataType={dataType}
        filteredColumns={filteredColumns}
        onSelectionEnd={handleOnSelectionEnd}
        onInlineEditRange={onInlineEditRange}
        idFieldIdx={idFieldIdx}
        cells={cells}
        disableSettings={disableSettings}
        readOnly={readOnly}
        statusFieldIdx={statusFieldIdx}
        contextMenu={contextMenu}
        {...rest}
      />
      {dialogSpecialFiltering && (
        <DialogFilter
          hidden={!dialogSpecialFiltering}
          onClose={hideDialogSpecialFiltering}
          dataType={dataType}
          columns={columns}
          dataFiltering={dataFilteringByType}
          addFilters={addFilterToTable}
          resetFilters={resetFilterInTable}
          currentFilter={currentFilter}
          resetSelection={resetSelection}
        />
      )}
      {!panel && groupFilter && (
        <GroupFilterControl
          columns={columns}
          groupFilter={groupFilter}
          onClose={onClickResetFilterGroup}
        />
      )}
    </div>
  )
}

const TableWithContext = (props) => (
  <HeaderContext.Consumer>
    {({ setImportMenu, setExportMenu, setFilterMenu }) => (
      <Table
        {...props}
        setImportMenu={setImportMenu}
        setExportMenu={setExportMenu}
        setFilterMenu={setFilterMenu}
      />
    )}
  </HeaderContext.Consumer>
)

export default TableWithContext
