import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { findNode, invertProp } from '../../utils/tree'
import { ID_GEO_USER_MAPS } from '../../constants/geo'
import { selectUserAccess } from '../login/loginSlice'
import { activeProjectId, ensureUserProject, forceProjectReload } from '../projects/projectsSlice'
import { EVENT_TYPE, wrapByEventLog, invokeAction } from '../eventLog/eventLogSlice'
import { DEFAULT_MAPS } from '../../constants/access'

const READY_TO_USE = 'READY_TO_USE'

export const TOP_INDEX_RASTER = 3

const initialState = {
  id: ID_GEO_USER_MAPS,
  path: [ TOP_INDEX_RASTER ],
  children: [],
  state: {
    selected: true,
  },
  // redraw: 0,
  indicate: null,
  loading: false,
}

const findMapIndexById = (maps, id) => maps.findIndex((map) => map.id === id)

const listRasterMaps = createAsyncThunk(
  'raster/listRasterMaps',
  api.raster.listRasterMaps,
)

export const uploadRasterMap = createAsyncThunk(
  'raster/uploadRasterMap',
  api.raster.uploadRasterMap,
)

const deleteRasterMap = createAsyncThunk(
  'raster/deleteRasterMap',
  api.raster.deleteRasterMap,
)

const makeRasterMapDefault = createAsyncThunk(
  'raster/makeRasterMapDefault',
  api.raster.makeRasterMapDefault,
)

const getRoot = (state) => {
  const rasterRoot = state.raster ?? state
  return {
    children: [ null, null, null, rasterRoot ],
  }
}

const toggleSelection = (state, path) => {
  invertProp(state, path, 'selected', true, getRoot)
}

const prepareMap = (map, index, justImported) => {
  map.path = [ TOP_INDEX_RASTER, index ]
  map.state = {
    selected: justImported === true || map.state?.selected !== false,
  }
}

export const rasterSlice = createSlice({
  name: 'raster',
  initialState,
  reducers: {
    setMaps: (state, action) => {
      state.children = action.payload
      state.state.selected = state.children.reduce((agg, map, index) => {
        prepareMap(map, index, false)
        if (map.state.selected) {
          return true
        }
        return agg
      }, false)
    },
    addMap: (state, action) => {
      const len = state.children.push(action.payload)
      prepareMap(state.children[len - 1], len - 1, true)
      state.state.selected = state.children.length > 0
    },
    deleteMap: (state, action) => {
      const id = action.payload
      const index = findMapIndexById(state.children, id)
      if (index >= 0) {
        state.children.splice(index, 1)
      }
      state.children.forEach(prepareMap)
      state.state.selected = state.children.length > 0
    },
    checkTreeItem: (state, action) => {
      toggleSelection(state, action.payload)
      // state.redraw++
    },
    makeDefault: (state, action) => {
      const { id } = action.payload
      const index = findMapIndexById(state.children, id)
      const map = state.children[index]
      map.isDefault = true
      state.children.forEach(prepareMap)
      state.state.selected = state.children.length > 0
    },
  },
  extraReducers: (builder) => builder
    .addCase(makeRasterMapDefault.rejected, (state, action) => {
      console.error(action.payload)
      return false
    }),
})

export const { checkTreeItem } = rasterSlice.actions

const { setMaps, addMap, deleteMap, makeDefault } = rasterSlice.actions

// export const selectRasterMapById = (id) => (state) => state.raster.children.find((map) => map.id === id)
// export const selectRasterRedraw = (state) => state.raster.redraw
export const selectRasterItems = (state) => state.raster.children
export const selectRasterState = (state) => state.raster.state
export const selectLoading = (state) => state.raster.loading

export const selectSelectedRasterMaps = (state) => {
  return state.raster.children?.map((item) => {
    if (item.state?.selected) {
      return { id: item.id, uri: item.uri }
    }
    return null
  }).filter(Boolean)
}

export const importRasterMap = ({ formData }) => async (dispatch) => {
  const projectId = await dispatch(ensureUserProject())
  if (projectId === '_') {
    dispatch(forceProjectReload())
    return
  }
  const files = Array.from(formData.entries()).map(([ name, file ]) => `${name} - ${file.size} bytes`).join(', ')
  const result = await dispatch(wrapByEventLog({
    type: EVENT_TYPE.importRasterMap,
    details: `Project ID = ${projectId}; File = ${files}`,
    action: invokeAction(dispatch, uploadRasterMap, { projectId, formData }),
    extractor: (map) => `Map ID = ${map.id}; Map Name = ${map.name}`,
  }))
  return dispatch(addMap(result))
}

export const initRasterMaps = () => async (dispatch, getState) => {
  const type = EVENT_TYPE.loadRasterMaps
  const details = (projectId = 'DEFAULT') => `Project ID = ${projectId}`
  const loader = (projectId) => async () => {
    const { data } = await invokeAction(dispatch, listRasterMaps, projectId)()
    return (data || [])
      .filter(({ status }) => status === READY_TO_USE)
      .map((item) => ({ ...item, state: { selected: false }, isDefault: !projectId }))
  }
  const extractor = (maps) => `Loaded ${maps?.length} Raster Maps`

  const mapList = await dispatch(wrapByEventLog({
    type,
    details: details(),
    action: loader(),
    extractor,
  }))
  const projectId = activeProjectId(getState())
  if (projectId) {
    const projectMapList = await dispatch(wrapByEventLog({
      type,
      details: details(projectId),
      action: loader(projectId),
      extractor,
    }))
    mapList.push(...projectMapList)
  }
  await dispatch(setMaps(mapList))
}

export const rasterMapCount = () => (dispatch, getState) => {
  const state = getState()
  return state.raster.children.length
}

export const menuItemClick = (path, key) => async (dispatch, getState) => {
  const state = getState()
  const root = getRoot(state)
  const node = findNode(root, path)
  if (root.children[node.path?.[0]]?.id === ID_GEO_USER_MAPS) {
    switch (key) {
      case 'import': {
        document.getElementById('raster-file-input').click()
        break
      }
      case 'delete': {
        if (node.isDefault && !selectUserAccess(state)[DEFAULT_MAPS]) {
          return
        }
        await dispatch(wrapByEventLog({
          type: EVENT_TYPE.deleteRasterMap,
          details: `Map ID = ${node.id}; Map Name = ${node.name}`,
          action: invokeAction(dispatch, deleteRasterMap, node.id),
        }))
        dispatch(deleteMap(node.id))
        break
      }
      case 'make-default': {
        if (node.isDefault && !selectUserAccess(state)[DEFAULT_MAPS]) {
          return
        }
        const { id } = node
        await dispatch(wrapByEventLog({
          type: EVENT_TYPE.makeRasterMapDefault,
          details: `Map ID = ${id}; Map Name = ${node.name}`,
          action: invokeAction(dispatch, makeRasterMapDefault, id),
        }))
        dispatch(makeDefault({ id }))
        break
      }
      default:
    }
  }
}

export default rasterSlice.reducer
