import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FontWeights, getTheme, IconButton, mergeStyleSets, Modal, Spinner, Stack, StackItem,
  ComboBox,
  SelectableOptionMenuItemType,
} from '@fluentui/react'
import { DefaultButton, PrimaryButton } from '../common/Button'
import Checkbox from '../common/Checkbox'

import './SelectFinancials.css'
import { getBusinessCaseGroups, selectBusinessCaseGroups, selectIsLoading } from '../../features/reports/reportsSlice'
import { activeProjectId } from '../../features/projects/projectsSlice'

const theme = getTheme()

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}

const dropdownStyles = {
  root: {
    width: 400,
  },
  callout: {
    width: 400,
    maxHeight: 200,
  },
}

const cancelIcon = { iconName: 'Cancel' }

const contentStyles = mergeStyleSets({
  header: [
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
})

const SelectFinancials = ({ onCancel, onSubmit }) => {
  const dispatch = useDispatch()

  const [ isAdditionalParameters, setIsAdditionalParameters ] = useState(false)
  const [ isOnlyValid, setIsOnlyValid ] = useState(false)
  const [ fields, setFields ] = useState([])
  const [ field, setField ] = useState(false)

  const isLoading = useSelector(selectIsLoading)
  const businessCaseGroups = useSelector(selectBusinessCaseGroups)
  const activeProject = useSelector(activeProjectId)
  const defaultProject = '_'

  useEffect(() => {
    if (isAdditionalParameters) {
      dispatch(getBusinessCaseGroups(isOnlyValid))
    }
  }, [ dispatch, isAdditionalParameters, isOnlyValid ])

  useEffect(() => {
    if (businessCaseGroups) {
      const groups = [ ...businessCaseGroups ]
      groups.sort((a, b) => {
        // First, show current project
        // Then, default project
        // Then, compare by projectDescription
        // If descriptions are equal, then compare by name
        if (a.projectId === activeProject && b.projectId !== activeProject) {
          return -1
        } else if (b.projectId === activeProject && a.projectId !== activeProject) {
          return 1
        } else if (a.projectId === defaultProject && b.projectId !== defaultProject) {
          return -1
        } else if (b.projectId === defaultProject && a.projectId !== defaultProject) {
          return 1
        } else if (a.projectDescription < b.projectDescription) {
          return -1
        } else if (a.projectDescription > b.projectDescription) {
          return 1
        } else if (a.name < b.name) {
          return -1
        } else if (a.name > b.name) {
          return 1
        } else {
          return 0
        }
      })
      let lastProjectHeader
      setFields(groups.reduce((agg, group) => {
        if (!lastProjectHeader || lastProjectHeader !== group.projectDescription) {
          agg.push({
            key: group.projectId,
            text: `Project: ${group.projectDescription}`,
            title: group.projectDescription,
            itemType: SelectableOptionMenuItemType.Header,
          })
          lastProjectHeader = group.projectDescription
        }
        agg.push({
          key: group.id,
          text: group.name,
        })
        return agg
      }, []))
    }
  }, [ businessCaseGroups, activeProject ])

  const handleChangeAdditionalParameters = useCallback((_, value) => {
    setIsAdditionalParameters(value)
  }, [])

  const handleChangeOnlyValid = useCallback((_, value) => {
    setIsOnlyValid(value)
  }, [])

  const handleFieldChange = useCallback((_, option) => {
    setField(option)
  }, [ setField ])

  const handleOnOk = useCallback(() => {
    onSubmit({
      id: field?.key,
      title: field?.text,
      isOnlyValid,
    })
  }, [ onSubmit, field, isOnlyValid ])

  return (
    <Modal
      width={480}
      containerClassName="top-most"
      isOpen={true}
      isBlocking={true}
    >
      <div className={contentStyles.header}>
        <span>Financial indicators</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          onClick={onCancel}
        />
      </div>
      <Stack className="modal-content-financials">
        <StackItem>
          <Checkbox
            checked={isAdditionalParameters}
            label={'Additional parameters'}
            onChange={handleChangeAdditionalParameters}
          />
        </StackItem>
        <StackItem className="financials-select-parameters">
          <Checkbox
            label={'Only valid'}
            disabled={!isAdditionalParameters}
            checked={isOnlyValid}
            onChange={handleChangeOnlyValid}
          />
          <StackItem>
            {isLoading
              ? <Spinner className='parameters-spinner'/>
              : (
                <ComboBox
                  label="Select GBC"
                  selectedKey={field?.key}
                  options={fields}
                  onChange={handleFieldChange}
                  autoComplete="on"
                  allowFreeform
                  styles={dropdownStyles}
                  disabled={!isAdditionalParameters}
                />
                )}
          </StackItem>
        </StackItem>
        <StackItem className='financials-buttons'>
          <DefaultButton className="dialog-button-financials" text="Cancel" onClick={onCancel} />
          <PrimaryButton
            className="dialog-button-financials"
            text="OK"
            onClick={handleOnOk}
            disabled={isAdditionalParameters && !field}
          />
        </StackItem>
      </Stack>
    </Modal>
  )
}

export default SelectFinancials
