import hash from 'object-hash'
import tokml from 'tokml'
import area from '@turf/area'
import { toast } from 'react-hot-toast'
import { v4 as uuid } from 'uuid'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { multiPolygon, point, polygon } from '@turf/helpers'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import simplify from '@turf/simplify'
import api from '../../api'
import {
  ID_GEO_SUBSTANCES, ID_GEO_VECTOR, ID_GEO_ZONES, ID_GEO_LEGEND, ZONES, ID_GEO_ZONES_FILTERING, INDEX_ZONE_FOCUS,
  ID_GEO_ZONES_COMPUTATION, ID_GEO_ZONES_FOCUS, ID_GEO_USER_MAPS, INDEX_ZONE_FILTERING, INDEX_ZONE_COMPUTATION,
  MID_MIF_EXT,
} from '../../constants/geo'
import { DIVIDER } from '../../constants/network'
import { checkPartial, findNode, invertProp, setTreeItemSelected, getTreeState, applyTreeState } from '../../utils/tree'
import { filterNetwork, savePainZonesXLS, selectApiKey, selectComplaintsAsGeoJSON } from '../network/networkSlice'
import { arrayDepth } from '../../utils/geo'
import { selectUserAccess } from '../login/loginSlice'
import { TOP_INDEX_VECTOR, selectVectorItems, selectVectorState } from '../vector/vectorSlice'
import { TOP_INDEX_RASTER, selectRasterItems, selectRasterState } from '../raster/rasterSlice'
import { activeProjectId, doLoadProject } from '../projects/projectsSlice'
import { calculatePainZones } from '../../components/PainZones/utils'
import { geoJsonToMidMif, polygonToGeoJSON } from '../../utils/convert'
import { dataToZip, kmlToFile } from '../../utils/export'
import { EXPORT_FORMAT } from '../../constants/menus'
import { addTaskType, setTaskTypeCompleted, setTaskTypeFailed, TASK_STATES, TASK_TYPES } from '../taskLog'
import { addTask, updateTaskByType } from '../taskLog/taskLogSlice'
import { EDIT_POLYGONS } from '../../constants/access'
import {
  EVENT_TYPE, STATUS_PENDING, STATUS_SUCCESS, STATUS_ERROR, saveEventLog, errorEventLog,
} from '../eventLog/eventLogSlice'

export const TOP_INDEX_SUBSTANCES = 0
export const TOP_INDEX_ZONES = 1
export const TOP_INDEX_LEGEND = 4

const zoneRootContextMenu = [
  {
    key: 'pain-zones',
    text: 'Calculate Pain Zones',
    iconProps: { iconName: 'Medical' },
  },
]

const zoneFolderContextMenu = [
  {
    key: 'create',
    text: 'Create',
    iconProps: { iconName: 'Pentagon' },
  },
  {
    key: '-',
  },
  {
    key: 'show',
    text: 'Show',
  },
  {
    key: 'hide',
    text: 'Hide',
  },
  {
    key: '-',
  },
  {
    key: 'delete-all',
    text: 'Delete All',
    iconProps: { iconName: 'Delete' },
  },
  {
    key: 'export',
    text: 'Export',
    iconProps: { iconName: 'Export' },
    subMenuProps: {
      items: [
        {
          key: EXPORT_FORMAT.KML,
          text: 'Export to KML',
          iconProps: { iconName: 'DownloadDocument' },
        },
        {
          key: EXPORT_FORMAT.KMZ,
          text: 'Export to KMZ',
          iconProps: { iconName: 'ZipFolder' },
        },
        {
          key: EXPORT_FORMAT.MIF_MID,
          text: 'Export to MIF/MID',
          iconProps: { iconName: 'TableGroup' },
        },
      ],
    },
  },
]

const zoneItemContextMenu = [
  {
    key: 'create',
    text: 'Create',
    iconProps: { iconName: 'Pentagon' },
  },
  {
    key: '-',
  },
  {
    key: 'edit',
    text: 'Edit',
    iconProps: { iconName: 'ColumnVerticalSectionEdit' },
  },
  {
    key: 'show',
    text: 'Show',
  },
  {
    key: 'hide',
    text: 'Hide',
  },
  {
    key: '-',
  },
  {
    key: 'delete',
    text: 'Delete',
    iconProps: { iconName: 'Delete' },
  },
]

const vectorTopContextMenu = [
  {
    key: 'import',
    text: 'Import',
    iconProps: { iconName: 'Import' },
  },
]

const initialState = {
  loading: false,
  ready: false,
  substances: {
    id: ID_GEO_SUBSTANCES,
    name: 'Substance',
    state: {
      icon: 'MapLayers',
      expanded: true,
      selected: true,
    },
    children: [],
    path: [ TOP_INDEX_SUBSTANCES ],
  },
  zones: {
    id: ID_GEO_ZONES,
    name: 'Zones',
    state: {
      icon: 'Pentagon',
      expanded: true,
    },
    children: ZONES.map((zone, index) => ({
      ...zone,
      path: [ TOP_INDEX_ZONES, index ],
      menu: zone.allowMany ? zoneFolderContextMenu : zoneItemContextMenu,
      properties: {},
    })),
    path: [ TOP_INDEX_ZONES ],
    menu: zoneRootContextMenu,
    modifying: false,
  },
  vector: {
    id: ID_GEO_VECTOR,
    name: 'Vector Maps',
    state: {
      icon: 'Shapes',
    },
    children: [],
    path: [ TOP_INDEX_VECTOR ],
    menu: vectorTopContextMenu,
  },
  raster: {
    id: ID_GEO_USER_MAPS,
    name: 'Raster Maps',
    state: {
      icon: 'ArrangeBringForward',
    },
    path: [ TOP_INDEX_RASTER ],
    menu: vectorTopContextMenu,
  },
  legend: {
    id: ID_GEO_LEGEND,
    name: 'Legend',
    state: {
      icon: 'CustomList',
    },
    path: [ TOP_INDEX_LEGEND ],
  },
  bounce: null,
  currentProject: '_',
  zoneStatesLoaded: false,
  buildPainZones: false,
  globalDrawMode: false,
  rulerVisible: false,
}

const getRoot = (state) => {
  const { substances, zones, vector, raster, legend } = (state.geo ? state.geo : state)
  return {
    children: [ substances, zones, vector, raster, legend ],
  }
}

const toggleSelection = (state, path) => {
  const root = getRoot(state)
  const node = findNode(root, path)
  if (node.id === ID_GEO_SUBSTANCES) {
    node.state.selected = !node.state.selected
  } else if (node.id.startsWith(ID_GEO_SUBSTANCES)) {
    root.children[path[0]].children.forEach((item) => (item.state.selected = item.id === node.id))
  } else {
    invertProp(state, path, 'selected', true, getRoot)
  }
}

const getSubstances = createAsyncThunk(
  'geo/getSubstances',
  api.geo.getSubstances,
)

const loadGeoZone = createAsyncThunk(
  'geo/loadZone',
  api.geo.loadZone,
)

export const saveGeoZone = createAsyncThunk(
  'geo/saveZone',
  api.geo.saveZone,
)

const deleteGeoZone = createAsyncThunk(
  'geo/deleteZone',
  api.geo.deleteZone,
)

const deleteGeoZoneItem = createAsyncThunk(
  'geo/deleteZoneItem',
  api.geo.deleteZoneItem,
)

export const geocode = (query) => async (dispatch, getState) => {
  const key = selectApiKey(getState()) || ''
  const result = await api.geo.geocode(query, key)
  if (!key) {
    result.emptyApiKey = true
  }
  return result
}

const nodeByZoneId = (state, id, zoneId, name) => {
  for (const item of state.zones.children) {
    if (item.id === id) {
      if (item.allowMany && zoneId) {
        if (!item.children) {
          item.children = []
        }
        const newItem = {
          id: `${id}${DIVIDER}${zoneId}`,
          name,
          properties: {
            description: name,
          },
          uuid: zoneId,
          path: [ ...item.path, item.children.length ],
          menu: zoneItemContextMenu,
        }
        item.children.push(newItem)
        return newItem
      } else {
        return item
      }
    }
    if (item.allowMany && item.children && id.startsWith(item.id)) {
      return item.children.find((zone) => zone.id === id)
    }
  }
}

const baseId = (id) => {
  const components = id.split(DIVIDER)
  if (components.length > 1) {
    return components[0]
  }
  return id
}

// const sortByName = (item1, item2) => `${item1.name}`.localeCompare(item2.name)

const saveZoneStates = ({ currentProject, zones, zoneStatesLoaded }) => {
  if (zoneStatesLoaded) {
    localStorage.setItem(`zone-states-[${currentProject}]`, JSON.stringify(getTreeState(zones)))
  }
}

export const geoSlice = createSlice({
  name: 'geo',
  initialState,
  reducers: {
    treeItemSelect: (state, action) => {
      setTreeItemSelected(state, action.payload, true, toggleSelection, getRoot)
      saveZoneStates(state)
    },
    treeItemUnselect: (state, action) => {
      setTreeItemSelected(state, action.payload, false, toggleSelection, getRoot)
      saveZoneStates(state)
    },
    checkTreeItem: (state, action) => {
      const node = findNode(getRoot(state), action.payload)
      if (state.zones.edit && state.zones.edit.startsWith(node.id)) {
        return
      }
      toggleSelection(state, action.payload)
      saveZoneStates(state)
    },
    expandTreeItem: (state, action) => {
      invertProp(state, action.payload, 'expanded', false, getRoot)
      saveZoneStates(state)
    },
    setZone: (state, action) => {
      const { id, zone, properties, uuid, name, loading, modifying } = action.payload
      const node = nodeByZoneId(state, id, uuid, name)
      if (node.state?.selected && !zone) {
        node.state.selected = false
      }
      if (zone && (modifying || (!node.zone && !loading))) {
        node.state = {
          ...(node.state ?? {}),
          selected: true,
        }
        if (!modifying) {
          state.zones.edit = node.id
        }
      }
      node.zone = zone
      if (properties) {
        node.properties = {
          ...(node.properties || {}),
          ...properties,
        }
        if (baseId(node.id) !== node.id) {
          node.name = node.properties.description
        }
      }
      if (uuid) {
        node.uuid = uuid
      }
      checkPartial(getRoot(state), node.path.slice(0, -1), 'selected')
      saveZoneStates(state)
    },
    setZoneList: (state, action) => {
      const { id, zoneList, modifying } = action.payload
      const node = nodeByZoneId(state, id)
      node.children = zoneList
        .map(([ zone, properties, uuid ], index) => ({
          id: `${node.id}${DIVIDER}${uuid}`,
          uuid,
          zone,
          properties,
          name: properties?.description,
          path: [ ...node.path, index ],
          menu: zoneItemContextMenu,
          ...(modifying ? { state: { selected: true } } : {}),
        }))
      if (modifying) {
        node.state = {
          ...(node.state ?? {}),
          selected: true,
          expanded: true,
        }
        checkPartial(getRoot(state), node.path.slice(0, -1), 'selected')
      }
    },
    dropZone: (state, action) => {
      const id = action.payload
      const base = baseId(id)
      if (base !== id) {
        const baseNode = nodeByZoneId(state, base)
        const index = baseNode.children.findIndex((item) => item.id === id)
        baseNode.children.splice(index, 1)
        for (let i = index; i < baseNode.children.length; i++) {
          baseNode.children[i].path[baseNode.children[i].path.length - 1] -= 1
        }
        checkPartial(getRoot(state), baseNode.path, 'selected')
      } else {
        const node = nodeByZoneId(state, id)
        delete node.zone
        node.state = {
          ...(node.state ?? {}),
          selected: false,
        }
        node.properties = {}
      }
      saveZoneStates(state)
    },
    dropAllZones: (state, action) => {
      const node = state.zones.children.find((zone) => zone.id === action.payload)
      delete node.children
      node.state = {
        ...(node.state ?? {}),
        selected: false,
      }
      saveZoneStates(state)
    },
    setZoneStates: (state, action) => {
      applyTreeState(state.zones, action.payload)
      state.zoneStatesLoaded = true
    },
    setEditZone: (state, action) => {
      state.zones.edit = action.payload
    },
    setModifying: (state, action) => {
      state.zones.modifying = action.payload
    },
    setBounce: (state, action) => {
      state.bounce = action.payload
    },
    showBuildPainZones: (state) => {
      state.buildPainZones = true
    },
    hideBuildPainZones: (state) => {
      state.buildPainZones = false
    },
    setGlobalDrawMode: (state, action) => {
      state.globalDrawMode = action.payload
    },
    setRulerVisible: (state, action) => {
      state.rulerVisible = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubstances.pending, (state) => {
        state.loading = true
      })
      .addCase(getSubstances.fulfilled, (state, action) => {
        state.loading = false
        state.ready = true
        state.substances.children = action.payload.map((item, index) => ({
          ...item,
          path: [ ...state.substances.path, index ],
          state: {
            radio: true,
            selected: index === 0,
          },
        }))
      })
    builder
      .addCase(doLoadProject.fulfilled, (state, action) => {
        state.currentProject = action.payload?.id ?? '_'
      })
  },
})

const { treeItemSelect, treeItemUnselect, checkTreeItem, dropAllZones, setZoneStates } = geoSlice.actions

export const {
  expandTreeItem, setZone, dropZone, setZoneList, setEditZone, setBounce, showBuildPainZones, hideBuildPainZones,
  setGlobalDrawMode, setModifying, setRulerVisible,
} = geoSlice.actions

const prepareOneZone = ({ uuid, zone, properties }) => ({
  id: uuid,
  coordinates: zone,
  properties,
})

export const doSaveZone = (zoneId, projectId) => async (dispatch, getState) => {
  const state = getState()
  const id = baseId(zoneId)
  const zoneType = id.split('-').slice(-1)[0]
  const zoneBase = nodeByZoneId(state.geo, id)
  const zoneList = zoneBase.allowMany
    ? zoneBase.children.map(prepareOneZone)
    : [ prepareOneZone(zoneBase) ]
  if (!projectId) {
    projectId = activeProjectId(state) ?? '_'
  }
  await dispatch(saveGeoZone({
    projectId,
    zoneType,
    zoneList,
  }))
}

export const saveAllZones = (projectId) => (dispatch, getState) => {
  const state = getState()
  const tasks = ZONES
    .map(({ id }) => {
      const zoneBase = nodeByZoneId(state.geo, id)
      const zonePresent = zoneBase.allowMany ? zoneBase.children?.length : zoneBase.zone
      return zonePresent ? id : null
    })
    .filter(Boolean)
  return tasks.length ? Promise.all(tasks.map((id) => dispatch(doSaveZone(id, projectId)))) : Promise.resolve()
}

export const saveEditedZone = (zone, doFilter = true) => async (dispatch, getState) => {
  if (zone?.zone && zone.zone.length === 0) {
    zone.zone = null
  }
  if (zone && !zone.zone) { //  && zone.id?.startsWith(ID_GEO_ZONES_FOCUS)
    const [ zoneType, zoneId ] = [ ...zone.id.split(DIVIDER) ]
    const state = getState()
    const deleteZoneFromServer = selectModifyingZone(state)
    await dispatch(dropZone(zone.id))
    if (deleteZoneFromServer) {
      await dispatch(deleteZones(zoneType, zoneId))
    }
    dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Clear ${zone.id}`))
  } else {
    await dispatch(setZone(zone))
    if (zone) {
      await dispatch(doSaveZone(zone.id))
    }
    dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Update ${zone.id}`))
  }
  dispatch(setModifying(false))
  if (doFilter && zone?.id === ID_GEO_ZONES_FILTERING) {
    dispatch(filterNetwork())
  }
}

export const deleteZones = (zoneType, zoneId) => (dispatch, getState) => {
  zoneType = zoneType.split('-').slice(-1)[0]
  const projectId = activeProjectId(getState()) ?? '_'
  dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Clear ${zoneType}${zoneId ? `${DIVIDER}${zoneId}` : ''}`))
  return dispatch(
    zoneId
      ? deleteGeoZoneItem({ projectId, zoneType, zoneId })
      : deleteGeoZone({ projectId, zoneType }),
  )
}

export const loadZoneStates = () => (dispatch, getState) => {
  const state = getState()
  const projectId = activeProjectId(state) ?? '_'
  const data = localStorage.getItem(`zone-states-[${projectId}]`)
  if (data) {
    dispatch(setZoneStates(JSON.parse(data)))
  }
}

const byDescription = (a, b) => {
  const aDesc = a[1].description ?? ''
  const bDesc = b[1].description ?? ''
  const prefixA = aDesc.split(' ').slice(0, -1).join(' ')
  const prefixB = bDesc.split(' ').slice(0, -1).join(' ')
  if (prefixA === prefixB) {
    const numberA = Number(aDesc.split(' ').slice(-1)[0])
    const numberB = Number(bDesc.split(' ').slice(-1)[0])
    if (Number.isInteger(numberA) && Number.isInteger(numberB)) {
      return numberA - numberB
    }
  }
  return aDesc.localeCompare(bDesc)
}

export const loadGeoZones = () => (dispatch, getState) => {
  const state = getState()
  const projectId = activeProjectId(state) ?? '_'
  return Promise.all(ZONES.map(({ id, allowMany }) => {
    const zoneType = id.split('-').slice(-1)[0]
    const zoneTypeFull = `${ID_GEO_ZONES}-${zoneType}`
    return dispatch(loadGeoZone({ projectId, zoneType }))
      .then((action) => {
        const data = action.payload
        if (allowMany) {
          if (data) {
            dispatch(setZoneList({
              id: zoneTypeFull,
              zoneList: data.sort(byDescription),
            }))
          } else {
            dispatch(dropAllZones(zoneTypeFull))
          }
        } else {
          if (data) {
            const [ [ zone, properties, uuid ] ] = data
            dispatch(setZone({
              id: zoneTypeFull,
              uuid,
              zone,
              properties,
              loading: true,
            }))
          } else {
            dispatch(dropZone(zoneTypeFull))
          }
        }
      })
  }))
    .then(() => dispatch(loadZoneStates()))
}

export const selectZoneCount = () => (dispatch, getState) => getState()
  .geo.zones.children.filter((zone) => zone?.geometry?.length).length

export const selectSubstances = (state) =>
  state.geo.ready && !state.geo.loading ? state.geo.substances.children : []

const makeLayerColors = (color) => {
  if (!color || color.length !== 9) {
    color = '#3388ff33'
  }
  const opacity = parseInt(color.slice(-2), 16) / 255
  color = color.slice(0, -2)
  return {
    color,
    fillColor: color,
    fillOpacity: opacity,
  }
}

export const selectZones = (state) => {
  const colors = state.settings.colors
  const zones = state.geo.zones
  const result = []

  const oneZone = (item) => {
    if (!item.state?.selected || !item.zone) {
      return null
    }
    const data = {
      key: item.id,
      geometry: item.zone,
      options: makeLayerColors(colors[baseId(item.id)]),
      properties: item.properties,
      uuid: item.uuid,
    }
    return {
      ...data,
      hash: hash(data),
    }
  }

  for (const item of zones.children) {
    if (!item.allowMany) {
      result.push(oneZone(item))
    } else if (item.children) {
      result.push(...item.children.map(oneZone))
    }
  }
  return result.filter(Boolean)
}

export const selectBounce = (state) => state.geo.bounce
export const selectEditZone = (state) => state.geo.zones.edit
export const selectModifyingZone = (state) => state.geo.zones.modifying
export const selectBuildPainZones = (state) => state.geo.buildPainZones

export const selectGlobalDrawMode = (state) => state.geo.globalDrawMode

export const selectRulerVisible = (state) => state.geo.rulerVisible

export const selectEditZoneColors = (state) => {
  const editZone = selectEditZone(state)
  const colors = state.settings.colors
  return editZone ? makeLayerColors(colors[baseId(editZone)]) : {}
}

export const selectLegendGeo = (state) => {
  const { state: legendState } = state.geo.legend
  const result = {
    state: legendState,
    content: [],
  }
  if (result.state?.selected) {
    const legend = state.settings.legend
    if (legend?.zones && state.geo.zones.state?.selected) {
      result.content.push({
        title: state.geo.zones.name,
        list: Object.entries(state.settings.colors)
          .filter(([ key ]) => state.geo.zones.children.find(({ id }) => id === key)?.state?.selected)
          .map(([ key, value ]) => ({
            color: value,
            text: state.geo.zones.children.find(({ id }) => id === key)?.name,
          })),
      })
    }
  }
  return result
}

export const selectGeoTree = (state) => {
  const { geo: { substances, zones, vector, raster, legend } } = state
  const userAccess = selectUserAccess(state)
  return [
    substances,
    userAccess[EDIT_POLYGONS]
      ? zones
      : {
          ...zones,
          menu: undefined,
        },
    {
      ...vector,
      children: selectVectorItems(state),
      state: {
        ...vector.state,
        selected: selectVectorState(state).selected,
      },
    },
    {
      ...raster,
      children: selectRasterItems(state),
      state: {
        ...raster.state,
        selected: selectRasterState(state).selected,
      },
    },
    legend,
  ]
}

export const selectChosenSubstance = (state) => (
  state.geo.substances.state.selected &&
  selectSubstances(state).find(({ state: { selected } = {} }) => selected)
) || null

export const selectFocusZones = (state) => {
  const node = nodeByZoneId(state, ID_GEO_ZONES_FOCUS)
  const { children, state: allState } = node
  const isAllSelected = allState?.selected && !allState?.partial
  return children?.map((zone) => {
    if (isAllSelected || zone.state?.selected) {
      return zone
    }
    return null
  }).filter(Boolean)
}

export const selectVisibleFocusZoneCount = (state) => selectFocusZones(state.geo)?.length ?? 0

export const selectFilteringZone = (state) => {
  const filteringZone = state.geo.zones.children.find(({ id }) => id === ID_GEO_ZONES_FILTERING)
  if (!filteringZone?.zone || !filteringZone?.zone?.[0]) {
    return null
  }
  return filteringZone.zone
}

export const selectComputationZone = (state) => {
  const computationZone = state.geo.zones.children.find(({ id }) => id === ID_GEO_ZONES_COMPUTATION)
  if (!computationZone?.zone || !computationZone?.zone?.[0]) {
    return null
  }
  return computationZone.zone
}

export const initSubstances = () => (dispatch, getState) => {
  const state = getState()
  if (!state.geo.ready && !state.geo.loading) {
    return dispatch(getSubstances())
  }
}

export const checkTreeItemGeo = (path) => (dispatch, getState) => {
  const node = findNode(getRoot(getState()), path)
  dispatch(checkTreeItem(path))
  if ((node.id === ID_GEO_ZONES &&
      node.children?.find((ch) => ch.id === ID_GEO_ZONES_FILTERING)?.zone?.length > 0) ||
    node.id === ID_GEO_ZONES_FILTERING) {
    dispatch(filterNetwork())
  }
}

const formatDate = (date) => [ date.getFullYear(), `0${date.getMonth() + 1}`.slice(-2), `0${date.getDate()}`.slice(-2) ]
  .join('-')

export const convertFeatureToPainZone = (feature, index) => [
  feature.geometry.coordinates,
  {
    description: `Pain Zone ${index + 1}`,
  },
  uuid(),
]

export const buildPainZones = ({ zoom, dateFrom, dateTo, resolution, sensitivity, weight, mode }) =>
  async (dispatch, getState) => {
    const weightMode = mode === 'weight'
    document.body.classList.add('waiting')
    await dispatch(saveEventLog(
      EVENT_TYPE.calculatePainZones,
      ` [Period = ${dateFrom} - ${dateTo}; Aggregation range = ${resolution}; ${weightMode ? `Weight of Complaints, % = ${weight}` : `Complaints density = ${sensitivity}`}]`,
      STATUS_PENDING,
    ))
    await dispatch(addTask({ type: TASK_TYPES.calculatePainZones }))
    await new Promise((resolve) => setTimeout(async () => {
      try {
        const state = getState()
        // Отримуємо скарги, що вже відфільтровані по фільтрувальній зоні й згідно з користувацькими фільтрами у таблиці
        const complaints = selectComplaintsAsGeoJSON(state)
        let features = complaints.features
        // Фільтруємо за діапазоном дат
        if (dateFrom || dateTo) {
          if (dateFrom) {
            dateFrom = formatDate(dateFrom)
          }
          if (dateTo) {
            dateTo = formatDate(dateTo)
          }
          features = features.filter((feature) =>
            (!dateFrom || feature.properties.date >= dateFrom) &&
            (!dateTo || feature.properties.date <= dateTo))
        }
        // Якщо задано обчислювальну зону, фільтруємо також по ній
        const comp = state.geo.zones.children.find(({ id }) => id === ID_GEO_ZONES_COMPUTATION)
        if (comp?.zone?.[0]) {
          const poly = toPolygon(comp.zone)
          simplify(poly, { tolerance: 0.001, highQuality: true, mutate: true })
          features = features.filter((feature) => booleanPointInPolygon(point(feature.geometry.coordinates), poly))
        }
        // Готуємо параметри та викликаємо розрахунок зон
        const [ polygons, bounds, clusterData ] =
          calculatePainZones(features, zoom, resolution, sensitivity, weight, weightMode)
        // Зберігаємо отримані зони у списку Focus zones та показуємо їх на карті користувачу
        if (polygons.length > 0) {
          if (state.geo.zones.children.find(({ id }) => id === ID_GEO_ZONES_FOCUS)?.children?.length > 0) {
            await dispatch(deleteZones(ID_GEO_ZONES_FOCUS))
          }
          const zoneList = polygons.map(convertFeatureToPainZone)
          dispatch(setZoneList({
            id: ID_GEO_ZONES_FOCUS,
            zoneList,
            modifying: true,
          }))
          await dispatch(doSaveZone(ID_GEO_ZONES_FOCUS))
          const [ lon1, lat1, lon2, lat2 ] = bounds
          window.map.fitBounds([ [ lat1, lon1 ], [ lat2, lon2 ] ])
          await dispatch(saveEventLog(
            EVENT_TYPE.calculatePainZones,
            `\nRESULT: ${polygons.length} polygons saved as Focus zones`,
          ))
          await dispatch(updateTaskByType({ type: TASK_TYPES.calculatePainZones, state: TASK_STATES.completed }))
          // Експортуємо згрупований за зонами список скарг у форматі Excel
          dispatch(savePainZonesXLS(clusterData))
        } else {
          // Якщо список розрахованих зон порожній, видаємо повідомлення користувачу
          toast.error('No "pain zones" were calculated with given parameters and Complaints data',
            { duration: 5000 })
          await dispatch(saveEventLog(
            EVENT_TYPE.calculatePainZones,
            `\nRESULT: No "pain zones" were calculated with given parameters and Complaints data [Filter ${complaints.features.length} => ${features.length} complaints]`,
          ))
          await dispatch(updateTaskByType({ type: TASK_TYPES.calculatePainZones, state: TASK_STATES.failed }))
        }
      } catch (error) {
        await dispatch(errorEventLog(EVENT_TYPE.calculatePainZones, error))
        await dispatch(updateTaskByType({ type: TASK_TYPES.calculatePainZones, state: TASK_STATES.failed }))
        throw error
      } finally {
        resolve()
        document.body.classList.remove('waiting')
      }
    }, 10))
  }

const getNextName = (name, projectId) => {
  const key = `next-${projectId}-${name}`
  const lastIndex = (Number(localStorage.getItem(key)) || 0) + 1
  localStorage.setItem(key, lastIndex)
  return `${name} ${lastIndex}`
}

const resetNames = (name, projectId) => localStorage.setItem(`next-${projectId}-${name}`, '')

export const menuItemClick = (path, key) => async (dispatch, getState) => {
  const state = getState()
  const projectId = activeProjectId(state) ?? '_'
  const node = findNode(getRoot(state), path)
  if (node.id.startsWith(ID_GEO_ZONES)) {
    switch (key) {
      case 'create': {
        window.map?.doSetActiveSector?.(null)
        dispatch(setZone({
          id: node.id,
          zone: [],
          uuid: uuid(),
          properties: {},
          name: getNextName(node.name, projectId),
        }))
        dispatch(treeItemSelect(path))
        dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Create ${node.id}`))
        dispatch(setModifying(false))
        break
      }
      case 'edit': {
        window.map?.doSetActiveSector?.(null)
        dispatch(treeItemSelect(path))
        dispatch(setEditZone(node.id))
        dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Edit ${node.id}`))
        dispatch(setModifying(true))
        break
      }
      case 'show': {
        dispatch(treeItemSelect(path))
        if (node.id === ID_GEO_ZONES_FILTERING) {
          dispatch(filterNetwork())
        }
        break
      }
      case 'hide': {
        if (state.geo.zones.edit === node.id) {
          dispatch(setEditZone(null))
        }
        dispatch(treeItemUnselect(path))
        if (node.id === ID_GEO_ZONES_FILTERING) {
          dispatch(filterNetwork())
        }
        break
      }
      case 'delete': {
        if (state.geo.zones.edit === node.id) {
          dispatch(setEditZone(null))
        }
        await dispatch(deleteZones(...node.id.split(DIVIDER)))
        dispatch(treeItemUnselect(path))
        dispatch(dropZone(node.id))
        if (node.id === ID_GEO_ZONES_FILTERING) {
          dispatch(filterNetwork())
        }
        break
      }
      case 'delete-all': {
        if (node.id.startsWith(state.geo.zones.edit)) {
          dispatch(setEditZone(null))
        }
        await dispatch(deleteZones(baseId(node.id)))
        dispatch(treeItemUnselect(path))
        dispatch(dropAllZones(node.id))
        resetNames(node.name, projectId)
        break
      }
      case 'pain-zones': {
        dispatch(showBuildPainZones())
        break
      }
      case EXPORT_FORMAT.MIF_MID:
      case EXPORT_FORMAT.KMZ:
      case EXPORT_FORMAT.KML: {
        zonesExport(node, key, dispatch)
        break
      }
      default: {
        break
      }
    }
  }
}

export const editOrCreateFilteringZone = (dispatch, getState) => {
  const state = getState()
  const key = state.geo.zones.children[INDEX_ZONE_FILTERING].zone ? 'edit' : 'create'
  return dispatch(menuItemClick([ TOP_INDEX_ZONES, INDEX_ZONE_FILTERING ], key))
}

export const editOrCreateComputationZone = (dispatch, getState) => {
  const state = getState()
  const key = state.geo.zones.children[INDEX_ZONE_COMPUTATION].zone ? 'edit' : 'create'
  return dispatch(menuItemClick([ TOP_INDEX_ZONES, INDEX_ZONE_COMPUTATION ], key))
}

export const createFocusZone = (dispatch) =>
  dispatch(menuItemClick([ TOP_INDEX_ZONES, INDEX_ZONE_FOCUS ], 'create'))

export const toPolygon = (rings) => arrayDepth(rings) > 3 ? multiPolygon(rings) : polygon(rings)

export const cropByFilteringZone = (state, array, latIdx, lngIdx, idGeoZones = ID_GEO_ZONES_FILTERING) => {
  let filteringZone
  if (idGeoZones === ID_GEO_ZONES_FILTERING) {
    filteringZone = selectFilteringZone(state)
  } else {
    filteringZone = state.geo.zones.children.find(({ id }) => id === idGeoZones)?.zone
  }

  if (!filteringZone || !filteringZone?.[0]) {
    return array
  }

  const poly = toPolygon(filteringZone)
  simplify(poly, { tolerance: 0.001, highQuality: true, mutate: true })
  return array.filter((item) => {
    const lng = item[lngIdx]
    const lat = item[latIdx]
    return lng && lat && booleanPointInPolygon(point([ lng, lat ]), poly)
  })
}

const NAME = 'focus_zones'

const zonesExport = (node, selectType, dispatch) => {
  const { id, children: zones } = node
  if (!Array.isArray(zones)) {
    return
  }
  const description = zones.name
  const polygons = []
  zones.forEach((zone) => {
    const description = zone.properties?.description ?? ''
    const {
      id,
      uuid,
      zone: geometry,
    } = zone
    const surface = Math.round(area(toPolygon(geometry)) / 1e3) / 1e3
    polygons.push({
      id,
      coordinates: geometry,
      properties: {
        id: uuid,
        name: description,
        area: `${surface} km²`,
      },
    })
  })
  const geoJson = polygonToGeoJSON(polygons)
  const taskType = id.startsWith(ID_GEO_ZONES_FILTERING)
    ? TASK_TYPES.exportFilteringZone
    : id.startsWith(ID_GEO_ZONES_FOCUS)
      ? TASK_TYPES.exportFocusZone
      : id.startsWith(ID_GEO_ZONES_COMPUTATION)
        ? TASK_TYPES.exportComputationZone
        : TASK_TYPES.exportPolygon
  const callbackCompleted = () => {
    dispatch(setTaskTypeCompleted(taskType))
    dispatch(saveEventLog(EVENT_TYPE.zoneActions, ': Export', STATUS_SUCCESS))
  }
  const callbackFailed = () => {
    dispatch(setTaskTypeFailed(taskType))
    dispatch(saveEventLog(EVENT_TYPE.zoneActions, ': Export', STATUS_ERROR))
  }
  dispatch(addTaskType(taskType))
  dispatch(saveEventLog(EVENT_TYPE.zoneActions, `: Export [Zone = ${id}; Type = ${selectType}]`, STATUS_PENDING))
  switch (selectType) {
    case EXPORT_FORMAT.KMZ: {
      const kmlData = tokml(geoJson, { documentName: id, documentDescription: description })
      dataToZip(kmlData, NAME, 'kml', 'kmz', callbackFailed, callbackCompleted)
      break
    }
    case EXPORT_FORMAT.KML: {
      const fileName = `${NAME}.kml`
      const kmlData = tokml(geoJson, { documentName: id, documentDescription: description })
      try {
        kmlToFile(kmlData, fileName)
        callbackCompleted()
      } catch (err) {
        callbackFailed()
        throw err
      }
      break
    }
    case EXPORT_FORMAT.MIF_MID: {
      const { mid, mif } = geoJsonToMidMif(geoJson, [
        { id: 'id', label: 'ID', type: 'string' },
        { id: 'name', label: 'Name', type: 'string' },
        { id: 'area', label: 'Area', type: 'string' },
      ])
      dataToZip([ mid, mif ], NAME, MID_MIF_EXT, 'zip', callbackFailed, callbackCompleted)
      break
    }
    default: {
      callbackFailed()
    }
  }
}

export const exportFocusZones = (format) => (dispatch, getState) => {
  const state = getState()
  const node = state.geo.zones.children.find(({ id }) => id === ID_GEO_ZONES_FOCUS)
  zonesExport(node, format, dispatch)
}

export const copyCoordinatesToClipboard = (coordinates) => () => {
  navigator.clipboard.writeText(coordinates)
  toast.success('Copied to clipboard')
}

export default geoSlice.reducer
