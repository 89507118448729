import L from 'leaflet'
import { isMultiPolygon, reverseLngLat } from '../components/Map/utils'

const defConfig = [
  { name: 'Version', type: 'number', value: 300 },
  { name: 'Charset', type: 'string', value: 'WindowsCyrillic' },
  { name: 'Delimiter', type: 'string', value: ',' },
  { name: 'CoordSys', type: 'none', value: 'Earth Projection 1, 104' },
]

const PEN = '1,2,6052956'
const BRUSH = '2,6052956,0'
const SYMBOL = '37,255,18'

const defFields = [
  { id: 'description', label: 'description', type: 'string' },
]

const regSpace = / /gi
const regShielding = /"/gi

const genNameColumn = (name) => `${name ?? ''}`.replace(regSpace, '_')

const shielding = (value) => `${value ?? ''}`.replace(regShielding, '""')

const dMap = {
  160: 160,
  164: 164,
  166: 166,
  167: 167,
  169: 169,
  171: 171,
  172: 172,
  173: 173,
  174: 174,
  176: 176,
  177: 177,
  181: 181,
  182: 182,
  183: 183,
  187: 187,
  1025: 168,
  1026: 128,
  1027: 129,
  1028: 170,
  1029: 189,
  1030: 178,
  1031: 175,
  1032: 163,
  1033: 138,
  1034: 140,
  1035: 142,
  1036: 141,
  1038: 161,
  1039: 143,
  1105: 184,
  1106: 144,
  1107: 131,
  1108: 186,
  1109: 190,
  1110: 179,
  1111: 191,
  1112: 188,
  1113: 154,
  1114: 156,
  1115: 158,
  1116: 157,
  1118: 162,
  1119: 159,
  1168: 165,
  1169: 180,
  8211: 150,
  8212: 151,
  8216: 145,
  8217: 146,
  8218: 130,
  8220: 147,
  8221: 148,
  8222: 132,
  8224: 134,
  8225: 135,
  8226: 149,
  8230: 133,
  8240: 137,
  8249: 139,
  8250: 155,
  8364: 136,
  8470: 185,
  8482: 153,
}

function unicodeToWin1251 (s) {
  const L = []
  for (let i = 0; i < s.length; i++) {
    const ord = s.charCodeAt(i)
    if (ord < 128) {
      L.push(ord)
    } else if (ord > 1039 && ord < 1104) {
      L.push(ord - 848)
    } else if (!(ord in dMap)) {
      L.push('?'.charCodeAt(0)) // console.warn('Character ' + s.charAt(i) + " isn't supported by win1251!")
    } else {
      L.push(dMap[ord])
    }
  }
  return L
}

export const genBounds = (point, bounds = {}) => {
  const { latMin, latMax, lngMin, lngMax } = bounds
  bounds.latMin = Math.min(latMin ?? point[1], point[1])
  bounds.lngMin = Math.min(lngMin ?? point[0], point[0])
  bounds.latMax = Math.max(latMax ?? point[1], point[1])
  bounds.lngMax = Math.max(lngMax ?? point[0], point[0])
  return bounds
}

const genHeader = (config, bounds) => (
  config.map((item) => {
    switch (item.type) {
      case 'number' : return `${item.name} ${item.value}`
      case 'string': return `${item.name} "${item.value ?? ''}"`
      default: {
        if (item.name === 'CoordSys') {
          return `${item.name} ${item.value} Bounds (${bounds.lngMin - 1}, ${bounds.latMin - 1}) (${bounds.lngMax + 1}, ${bounds.latMax + 1})`
        }
        return `${item.name} ${item.value}`
      }
    }
  }).join('\n')
)

export const columnsToMif = (headers) => (
  `Columns ${headers.length}\n${headers.map((field) => {
    let type
    switch (`${field.type}`.toLowerCase()) {
      case 'long': {
        type = 'Integer'
        break
      }
      case 'integer':
      case 'int': {
        type = 'Integer'
        break
      }
      case 'datetime': {
        type = 'Date'
        break
      }
      case 'boolean': {
        type = 'Logical'
        break
      }
      case 'double': {
        type = 'Float'
        break
      }
      default: {
        type = 'Char(254)'
      }
    }
    return `  ${genNameColumn(field.label)} ${type}`
  }).join('\n')}`
)

export const rowToMid = (columns, row) => (
  row.map((value, index) => {
    switch (`${columns[index]?.type}`.toLowerCase()) {
      case 'boolean': {
        return `${value ? 'T' : 'F'}`
      }
      case 'datetime':
      case 'long':
      case 'int':
      case 'integer':
      case 'double': {
        return `${value ?? ''}`
      }
      default: {
        return `"${shielding(value)}"`
      }
    }
  }).join(',')
)

// Табличні дані -- в MIF/MID формат
export const dataToMidMif = (data, headers, idLat, idLng, config = defConfig) => {
  const boundsFull = {}
  const indexLat = headers.findIndex((header) => header.id === idLat)
  const indexLng = headers.findIndex((header) => header.id === idLng)
  if (indexLat === -1 || indexLng === -1) {
    return { error: 'Not lat long data' }
  }
  const columnMif = columnsToMif(headers)
  // Генерація даних
  const midArray = []
  const dataMif = data.map((row) => {
    const lat = row[indexLat]
    const lng = row[indexLng]
    if (!lat || !lng) {
      return null
    }
    midArray.push(rowToMid(headers, row))
    genBounds([ lng, lat ], boundsFull)
    return `Point ${lng} ${lat}\n  Symbol (${SYMBOL})`
  }).filter(Boolean).join('\n')

  const headerMif = genHeader(config, boundsFull)
  // Збирання даних для файлів
  const mif = `${headerMif}\n${columnMif}\nData\n\n${dataMif}`
  const mid = midArray.join('\n')
  const ansi = unicodeToWin1251(mid)
  return { mid: ansi, mif }
}

export const genRegion = (coordinates, boundsFull) => {
  if (!Array.isArray(coordinates)) {
    return 'Region 0\n 0\n'
  }
  return `Region ${
    coordinates.length
  }\n${
    coordinates.map((polygon) => {
      if (!Array.isArray(polygon) || polygon.length === 0) {
        return '0\n'
      }
      const dataRegion = polygon.map((point) => {
        boundsFull && genBounds(point, boundsFull)
        return `${point[0]} ${point[1]}`
      }).join('\n')
      return `  ${polygon.length}\n${dataRegion}`
    }).join('\n')
  }\n    Pen (${
    PEN
  })\n    Brush (${
    BRUSH
  })`
}

const geoJsonToMid = (fields, properties) =>
  fields.map((field) => `"${shielding(properties?.[field.id] ?? properties?.[field.id.toLowerCase()])}"`).join(',')

export const geoJsonToMidMif = (geoJson, fields = defFields, dataForMid, config = defConfig) => {
  const { features } = geoJson ?? {}
  if (!Array.isArray(fields) || !Array.isArray(features)) {
    return null
  }
  const midRows = []
  const isMidGeoJson = !dataForMid
  const boundsFeatures = new L.LatLngBounds()
  // Генерація даних
  const dataMif = features.map((feature, index) => {
    const { geometry, properties } = feature ?? {}
    const { type, coordinates } = geometry ?? {}
    const dataMidRow = isMidGeoJson ? geoJsonToMid(fields, properties) : rowToMid(fields, dataForMid[index])
    boundsFeatures.extend(reverseLngLat(coordinates))
    switch (type) {
      case 'Point': {
        if (!Array.isArray(coordinates) || coordinates.length !== 2) {
          return ''
        }
        midRows.push(dataMidRow)
        return `Point ${coordinates[0]} ${coordinates[1]}\n  Symbol (${SYMBOL})`
      }
      case 'Polygon': {
        midRows.push(dataMidRow)
        return genRegion(coordinates)
      }
      case 'MultiPolygon': {
        if (!Array.isArray(coordinates)) {
          return ''
        }
        midRows.push(dataMidRow)
        return genRegion(coordinates.flat(1))
      }
      default:
    }
    return ''
  }).join('\n')
  const min = boundsFeatures.getSouthWest()
  const max = boundsFeatures.getNorthEast()
  const boundsFull = { latMin: min.lat, lngMin: min.lng, latMax: max.lat, lngMax: max.lng }
  const headerMif = genHeader(config, boundsFull)
  const columnsMif = columnsToMif(fields)
  // Збирання даних для файлів
  const mif = `${headerMif}\n${columnsMif}\nData\n\n${dataMif}`
  const mid = midRows.join('\n')
  const ansi = unicodeToWin1251(mid)
  return { mid: ansi, mif }
}

export const polygonToGeoJSON = (polygons) => ({
  type: 'FeatureCollection',
  features: polygons.map(({ id, coordinates, properties = {} }) => ({
    id,
    type: 'Feature',
    geometry: {
      type: `${isMultiPolygon(coordinates) ? 'Multi' : ''}Polygon`,
      coordinates,
    },
    properties,
  })),
})

export const geoJSONToPolygon = (json) => {
  const feature = json?.features?.[0]
  if (!feature) {
    return null
  }
  return json.features.map((feature) => [
    feature.geometry.coordinates,
    feature.properties,
    feature.id,
  ])
}
