import React, { useMemo } from 'react'
import { DatePicker as EditField } from '@fluentui/react'
import EditFieldLabel from '../EditFieldLabel'

import './DatePicker.css'

const DatePicker = ({ id, label, description, className, ...props }) => {
  const cn = useMemo(() => {
    const baseClass = 'capex-datepicker-wrapper'
    return className ? `${baseClass} ${className}` : baseClass
  }, [ className ])

  return (
    <div id={id} className={cn}>
      <EditField
        {...props}
        className='capex-datepicker'
        placeholder={label}
      />
      <EditFieldLabel
        label={label}
        description={description}
        showLabel={true}
      />
    </div>
  )
}

export default DatePicker
