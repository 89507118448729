import { fetch } from './rest'

export const loadHistorySites = (data) => fetch('/api/history/sites', 'POST', data)
  .catch((error) => {
    console.info(`No historical data for selected Sites ${error}`)
  })

export const loadHistoryRBSes = (data) => fetch('/api/history/rbses', 'POST', data)
  .catch((error) => {
    console.info(`No historical data for selected RBS ${error}`)
  })

export const loadHistorySectors = (data) => fetch('/api/history/sectors', 'POST', data)
  .catch((error) => {
    console.info(`No historical data for selected Sectors ${error}`)
  })

export const loadHistoryComplaints = (data) => fetch('/api/history/complaints', 'POST', data)
  .catch((error) => {
    console.info(`No historical data for selected Complaints ${error}`)
  })

export const loadHistoryPolygons = ({ fromDate, toDate, names }) =>
  fetch('/api/history/sites', 'POST', { fromDate, toDate, groupBy: 'polygon', names })
    .catch((error) => {
      console.info(`No historical data for selected Polygons ${error}`)
    })

export const loadHistoryRegions = ({ fromDate, toDate, names }) =>
  fetch('/api/history/sites', 'POST', { fromDate, toDate, groupBy: 'region', names })
    .catch((error) => {
      console.info(`No historical data for selected Regions ${error}`)
    })
