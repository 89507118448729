import React, { useCallback, useRef } from 'react'
import { useBoolean } from '@fluentui/react-hooks'
import { IconSvg } from '../icons'
import { buildClassList } from '../../utils/classes'
import ContextMenu from './ContextMenu'

import './MenuButton.css'

const MenuButton = ({ icon, title, disabled, small, ...rest }) => {
  const buttonRef = useRef(null)

  const [ menuOpen, { setTrue: showMenu, setFalse: hideMenu } ] = useBoolean(false)

  const handleClick = useCallback(async (e) => {
    e.preventDefault()
    e.stopPropagation()
    showMenu()
  }, [ showMenu ])

  return (
    <>
      <button
        title={title}
        ref={buttonRef}
        className={buildClassList('capex-menu-button', { active: menuOpen, disabled, small })}
        onClick={handleClick}
        disabled={disabled}
      >
        <IconSvg name={icon} />
      </button>
      <ContextMenu {...rest} visible={menuOpen} onClose={hideMenu} target={buttonRef} />
    </>
  )
}

export default MenuButton
