import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { DirectionalHint, Stack, StackItem } from '@fluentui/react'
import { DefaultButton, PrimaryButton, SecondaryButton, IconButton } from '../../common/Button'
import { COMPLAINTS, EDIT_POLYGONS } from '../../../constants/access'
import { selectIsDefaultProject, selectIsMyProject } from '../../../features/projects/projectsSlice'
import { selectUserAccess } from '../../../features/login/loginSlice'
import {
  checkTreeItemGeo, createFocusZone, editOrCreateComputationZone, editOrCreateFilteringZone,
  expandTreeItem as expandTreeItemGeo, exportFocusZones,
  menuItemClick as menuItemClickGeo,
  selectEditZone,
  selectGeoTree, selectGlobalDrawMode, showBuildPainZones,
  TOP_INDEX_ZONES,
} from '../../../features/geo/geoSlice'
import VirtualizedTree from '../Network/VirtualizedTree'
import ContextMenu, { MenuButton } from '../../common/ContextMenu'
import {
  ComputationZoneIcon, FilteringZoneIcon, FocusZoneIcon, ExportIcon, MIDMIFIcon, KMLIcon, KMZIcon, MoreIcon, DeleteIcon,
  PencilIcon,
} from '../../common/icons/names'
import { EXPORT_FORMAT } from '../../../constants/menus'
import ColorWithPicker from '../../common/ColorWithPicker'
import { saveSettings, selectSettings } from '../../../features/settings/settingsSlice'
import {
  ID_GEO_ZONES_COMPUTATION, ID_GEO_ZONES_FILTERING, ID_GEO_ZONES_FOCUS, INDEX_ZONE_FOCUS,
} from '../../../constants/geo'

import './Zones.css'
import { determineNumberOfCoordinates } from '../../../utils/math'
import { findNode } from '../../../utils/tree'

const dropNull = (path) => {
  const newPath = [ ...path ]
  if (newPath.length > 0 && newPath[newPath.length - 1] === null) {
    newPath.splice(newPath.length - 1, 1)
  }
  return newPath
}

const Zones = () => {
  const dispatch = useDispatch()

  const isMyProject = useSelector(selectIsMyProject)
  const isDefaultProject = useSelector(selectIsDefaultProject)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const editZone = useSelector(selectEditZone)
  const geoTree = useSelector(selectGeoTree)
  const settings = useSelector(selectSettings)
  const globalDrawMode = useSelector(selectGlobalDrawMode)

  const changeColor = useCallback((id, value) => {
    dispatch(saveSettings({ colors: { [id]: value } }))
  }, [ dispatch ])

  const [ createZonesDisabled, calculatePainZonesDisabled ] = useMemo(() => {
    const disallowEditZones = (!isMyProject && !isDefaultProject) || editZone || !userAccess[EDIT_POLYGONS]
    const disallowComplaints = !userAccess[COMPLAINTS]
    return [ disallowEditZones, disallowEditZones || disallowComplaints ]
  }, [ isMyProject, isDefaultProject, editZone, userAccess ])

  const showZonesDisabled = useMemo(() => {
    const numOfZones = geoTree[TOP_INDEX_ZONES].children.reduce((acc, item) => {
      if (item.children && item.children.length > 0) {
        acc += item.children.length
      } else if (item.zone) {
        acc += 1
      }
      return acc
    }, 0)
    return numOfZones === 0
  }, [ geoTree ])

  const zonesShown = useMemo(() => geoTree[TOP_INDEX_ZONES].state.selected, [ geoTree ])

  const toggleZones = useCallback(() => {
    dispatch(checkTreeItemGeo([ TOP_INDEX_ZONES ]))
  }, [ dispatch ])

  const calculatePainZonesClick = useCallback(() => {
    dispatch(showBuildPainZones())
  }, [ dispatch ])

  const exportToMIFMID = useCallback(() => {
    dispatch(exportFocusZones(EXPORT_FORMAT.MIF_MID))
  }, [ dispatch ])

  const exportToKML = useCallback(() => {
    dispatch(exportFocusZones(EXPORT_FORMAT.KML))
  }, [ dispatch ])

  const exportToKMZ = useCallback(() => {
    dispatch(exportFocusZones(EXPORT_FORMAT.KMZ))
  }, [ dispatch ])

  const exportItems = useMemo(() => [
    {
      key: 'export-mifMid',
      text: 'Export to MIF/MID',
      icon: MIDMIFIcon,
      onClick: exportToMIFMID,
    },
    {
      key: 'export-kml',
      text: 'Export to KML',
      icon: KMLIcon,
      onClick: exportToKML,
    },
    {
      key: 'export-kmz',
      text: 'Export to KMZ',
      icon: KMZIcon,
      onClick: exportToKMZ,
    },
  ], [ exportToMIFMID, exportToKML, exportToKMZ ])

  const zoneMenuClick = useCallback((event, item) => {
    dispatch(menuItemClickGeo(item.path, item.key))
  }, [ dispatch ])

  const deleteAllFocusZones = useCallback(() => {
    dispatch(menuItemClickGeo(geoTree[TOP_INDEX_ZONES].children[INDEX_ZONE_FOCUS].path, 'delete-all'))
  }, [ dispatch, geoTree ])

  const makeItemMenu = useCallback((path, dataObject) => {
    const numberCoordinates = determineNumberOfCoordinates(dataObject?.zone, 0)
    const editDisabled = numberCoordinates > 2000
    return [
      {
        path,
        key: 'delete',
        text: 'Delete',
        icon: DeleteIcon,
        onClick: zoneMenuClick,
      },
      {
        path,
        key: 'edit',
        text: 'Edit Polygon',
        icon: PencilIcon,
        onClick: zoneMenuClick,
        title: editDisabled
          ? `The number of coordinates (${numberCoordinates}) exceeds the allowed value of 2000`
          : `The number of coordinates is ${numberCoordinates}`,
        disabled: editDisabled,
      },
    ]
  }, [ zoneMenuClick ])

  const nodes = useMemo(() => {
    const [ filtering, computation, focus ] = geoTree[TOP_INDEX_ZONES].children
    return [
      {
        ...filtering,
        name: 'Filtering',
        isBranchNode: true,
        ...(filtering.zone
          ? {
              children: [
                {
                  ...filtering,
                  name: filtering.properties?.description || 'Zone',
                  path: [ ...filtering.path, null ],
                  suffix: [
                    (
                      <MenuButton
                        key="suffix-menu"
                        icon={MoreIcon}
                        items={makeItemMenu(filtering.path, filtering)}
                        disabled={createZonesDisabled}
                      />
                    ),
                    (
                      <ColorWithPicker
                        id={ID_GEO_ZONES_FILTERING}
                        key="suffix-color"
                        className="zone-item-color"
                        color={settings.colors[ID_GEO_ZONES_FILTERING]}
                        onChange={changeColor}
                        disabled={createZonesDisabled}
                      />
                    ),
                  ],
                },
              ],
            }
          : {}
        ),
      },
      {
        ...computation,
        name: 'Computation',
        isBranchNode: true,
        ...(computation.zone
          ? {
              children: [
                {
                  ...computation,
                  name: computation.properties?.description || 'Zone',
                  path: [ ...computation.path, null ],
                  suffix: [
                    (
                      <MenuButton
                        key="suffix-menu"
                        icon={MoreIcon}
                        items={makeItemMenu(computation.path, computation)}
                        disabled={createZonesDisabled}
                      />
                    ),
                    (
                      <ColorWithPicker
                        id={ID_GEO_ZONES_COMPUTATION}
                        key="suffix-color"
                        className="zone-item-color"
                        color={settings.colors[ID_GEO_ZONES_COMPUTATION]}
                        onChange={changeColor}
                        disabled={createZonesDisabled}
                      />
                    ),
                  ],
                },
              ],
            }
          : {}
        ),
      },
      {
        ...focus,
        name: 'Focus',
        isBranchNode: true,
        suffix: [
          (
            <IconButton
              key="suffix-delete"
              title="Delete All Focus Zones"
              icon={DeleteIcon}
              onClick={deleteAllFocusZones}
              disabled={!focus.children?.length || createZonesDisabled}
            />
          ),
          (
            <MenuButton
              key="suffix-export"
              icon={ExportIcon}
              items={exportItems}
              title="Export"
              disabled={!focus.children?.length || createZonesDisabled}
            />
          ),
        ],
        children: focus.children?.map((child) => ({
          ...child,
          suffix: [
            (
              <MenuButton
                key="suffix-menu"
                icon={MoreIcon}
                items={makeItemMenu(child.path, child)}
                disabled={createZonesDisabled}
              />
            ),
            (
              <ColorWithPicker
                id={ID_GEO_ZONES_FOCUS}
                key="suffix-color"
                className="zone-item-color"
                color={settings.colors[ID_GEO_ZONES_FOCUS]}
                onChange={changeColor}
                disabled={createZonesDisabled}
              />
            ),
          ],
        })),
      },
    ]
  }, [ geoTree, exportItems, settings, changeColor, makeItemMenu, createZonesDisabled, deleteAllFocusZones ])

  const onExpandNode = useCallback((path) => {
    dispatch(expandTreeItemGeo(dropNull(path)))
  }, [ dispatch ])

  const onSelectNode = useCallback((path) => {
    dispatch(checkTreeItemGeo(dropNull(path)))
  }, [ dispatch ])

  const onClickNode = useCallback((path) => {
    const nodeId = findNode({ children: geoTree }, path.filter((item) => item !== null))?.id
    window.dispatchEvent(new CustomEvent('zoom-to-entity', { detail: { nodeId } }))
  }, [ geoTree ])

  const createFiltering = useCallback(() => {
    dispatch(editOrCreateFilteringZone)
  }, [ dispatch ])

  const createComputation = useCallback(() => {
    dispatch(editOrCreateComputationZone)
  }, [ dispatch ])

  const createFocus = useCallback(() => {
    dispatch(createFocusZone)
  }, [ dispatch ])

  const menuItems = useMemo(() => [
    {
      key: 'create-zone-filtering',
      text: 'Filtering Zone',
      icon: FilteringZoneIcon,
      onClick: createFiltering,
    },
    {
      key: 'create-zone-computation',
      text: 'Computation Zone',
      icon: ComputationZoneIcon,
      onClick: createComputation,
    },
    {
      key: 'create-zone-focus',
      text: 'Focus Zone',
      icon: FocusZoneIcon,
      onClick: createFocus,
    },
  ], [ createComputation, createFiltering, createFocus ])

  return (
    <Stack className="panel-stack">
      <StackItem className="panel-button-container">
        <DefaultButton
          text="Calculate Pain Zones"
          onClick={calculatePainZonesClick}
          disabled={calculatePainZonesDisabled}
        />
      </StackItem>
      <StackItem grow className="panel-tree-container">
        <VirtualizedTree
          searchPanel={false}
          nodes={nodes}
          onExpandNode={onExpandNode}
          onSelectNode={onSelectNode}
          onClickNode={onClickNode}
          selectable
          selectableWhenEmptyChildren={false}
        />
      </StackItem>
      <StackItem className="panel-button-container">
        <SecondaryButton
          text={zonesShown ? 'Hide All Zones' : 'Show Zones'}
          disabled={showZonesDisabled}
          onClick={toggleZones}
        />
        <PrimaryButton
          menuProps={{
            items: menuItems,
            directionalHint: DirectionalHint.topRightEdge,
          }}
          menuAs={ContextMenu}
          text="Create Zone"
          disabled={createZonesDisabled || globalDrawMode}
        />
      </StackItem>
    </Stack>
  )
}

export default Zones
