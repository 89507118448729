export const TABLES_UPDATE = {
  CLEAR: 'Clear',
  CONFLICT: 'Conflict',
  SIMULTANEOUS: 'Simultaneous',
  UPDATE_BC: 'UpdateBC',
}

export const FIELDS_REQUEST = {
  SITES_ID: 'siteId',
  RBS_ID: 'rbsId',
  SECTOR_ID: 'sectorId',
  ATTRIBUTE_ID: 'attributeId',
}

export const VALUES_FIELDS_REQUEST = Object.values(FIELDS_REQUEST)

export const COLUMNS_UPDATE = {
  ID: 'key',
  NETWORK_ELEMENT: 'name',
  FIELD: 'attributeName',
  ORIGINAL_VALUE: 'attributeValueShadow',
  NEW_VALUE: 'attributeValueProject',
  DB_VALUE: 'attributeValueDefault',
  USER_NAME: 'modifyUsername',
  DATE_TIME: 'modifyDate',
  VALUE: 'value',
  SELECT: 'select',
}

export const COLUMNS_UPDATE_BC = {
  ID: 'id',
  GROUP_BC: 'group',
  BC_NAME: 'name',
  SITE: 'siteName',
  STATUS: 'status',
  SECTORS: 'sectors',
  DATE_TIME: 'createdAt',
  SELECT: 'select',
  SECTOR_NAME: 'name',
  TECHNOLOGY: 'technology',
}
