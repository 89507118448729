import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
import { doLoadProject, doSaveProject } from '../projects/projectsSlice'
import {
  doCreateBusinessCases, doDeleteBusinessCases,
  doCalculateDraft, doCalculateForApprove,
  doApprove, doReject, doGetAllBCData,
} from '../bc/bcSlice'
import {
  getSites, getBaseStations, getSectors, getComplaints,
  getProjectSites, getProjectBaseStations, getProjectSectors,
  getCoverage,
} from '../network/networkSlice'
import {
  doExportToCSV, doExportToExcel,
} from '../historicalData/historicalDataSlice'
import {
  uploadVectorMap, loadVectorMapGeoJson, loadVectorMapGeoAttributes,
} from '../vector/vectorSlice'
import {
  uploadRasterMap,
} from '../raster/rasterSlice'
import { STATUS_JOB } from '../updateProject/update'
import api from '../../api'
import { TASK_STATES, TASK_TYPES, TASK_DESCRIPTIONS } from './constants'

const initialState = {
  taskLog: [],
}

export const doLoadProjectJobs = createAsyncThunk(
  'TaskLog/getProjectJobs',
  api.jobs.getProjectJobs,
)

export const doLoadJobStatusById = createAsyncThunk(
  'TaskLog/getJobStatus',
  api.jobs.getJobStatusIgnoreNotFound,
)

const doAddTaskByType = (type, name, id) => (state) => {
  state.taskLog = [
    {
      id: id || uuid(),
      type,
      time: new Date().toLocaleString(),
      state: TASK_STATES.active,
      name,
      description: (TASK_DESCRIPTIONS[type] ? TASK_DESCRIPTIONS[type] : type) + (name ? ` ${name}` : ''),
      progress: -1,
    },
    ...state.taskLog,
  ]
}

const doUpdateTaskByType = (type, taskState, progress) => (state, action) => {
  state.taskLog = [
    ...state.taskLog.map((task) => {
      if (task.type === type) {
        return {
          ...task,
          state: taskState,
          progress,
        }
      }
      return task
    }),
  ]
}

export const taskLogSlice = createSlice({
  name: 'taskLog',
  initialState,
  reducers: {
    addTask: (state, action) => {
      const { type, name, id } = action.payload
      doAddTaskByType(type, name, id)(state)
    },
    updateTaskByType: (state, action) => {
      const { type, state: taskState, progress } = action.payload
      doUpdateTaskByType(type, taskState, progress)(state)
    },
    updateTask: (state, action) => {
      const { id, state: taskState, progress } = action.payload
      state.taskLog = [
        ...state.taskLog.map((task) => {
          if (task.id === id) {
            return {
              ...task,
              state: taskState,
              progress,
            }
          }
          return task
        }),
      ]
    },
    setTaskLog: (state, action) => {
      state.taskLog = [
        ...action.payload,
      ]
    },
    clearTaskLog: (state, action) => {
      state.taskLog = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(doLoadProjectJobs.pending, doAddTaskByType(TASK_TYPES.loadProjectJobs))
      .addCase(
        doLoadProjectJobs.fulfilled,
        doUpdateTaskByType(TASK_TYPES.loadProjectJobs, TASK_STATES.completed),
      )
      .addCase(doLoadProjectJobs.rejected, doUpdateTaskByType(TASK_TYPES.loadProjectJobs, TASK_STATES.failed))
    builder
      .addCase(doLoadProject.pending, doAddTaskByType(TASK_TYPES.loadProject))
      .addCase(doLoadProject.fulfilled, doUpdateTaskByType(TASK_TYPES.loadProject, TASK_STATES.completed))
      .addCase(doLoadProject.rejected, doUpdateTaskByType(TASK_TYPES.loadProject, TASK_STATES.failed))
      .addCase(doSaveProject.pending, doAddTaskByType(TASK_TYPES.saveProject))
      .addCase(doSaveProject.fulfilled, doUpdateTaskByType(TASK_TYPES.saveProject, TASK_STATES.completed))
      .addCase(doSaveProject.rejected, doUpdateTaskByType(TASK_TYPES.saveProject, TASK_STATES.failed))
    builder
      .addCase(getSites.pending, doAddTaskByType(TASK_TYPES.loadSites))
      .addCase(getSites.fulfilled, doUpdateTaskByType(TASK_TYPES.loadSites, TASK_STATES.completed))
      .addCase(getSites.rejected, doUpdateTaskByType(TASK_TYPES.loadSites, TASK_STATES.failed))
      .addCase(getProjectSites.pending, doAddTaskByType(TASK_TYPES.loadProjectSites))
      .addCase(getProjectSites.fulfilled, doUpdateTaskByType(TASK_TYPES.loadProjectSites, TASK_STATES.completed))
      .addCase(getProjectSites.rejected, doUpdateTaskByType(TASK_TYPES.loadProjectSites, TASK_STATES.failed))
      .addCase(getBaseStations.pending, doAddTaskByType(TASK_TYPES.loadBaseStations))
      .addCase(getBaseStations.fulfilled, doUpdateTaskByType(TASK_TYPES.loadBaseStations, TASK_STATES.completed))
      .addCase(getBaseStations.rejected, doUpdateTaskByType(TASK_TYPES.loadBaseStations, TASK_STATES.failed))
      .addCase(getProjectBaseStations.pending, doAddTaskByType(TASK_TYPES.loadProjectBaseStations))
      .addCase(
        getProjectBaseStations.fulfilled,
        doUpdateTaskByType(TASK_TYPES.loadProjectBaseStations, TASK_STATES.completed),
      )
      .addCase(
        getProjectBaseStations.rejected,
        doUpdateTaskByType(TASK_TYPES.loadProjectBaseStations, TASK_STATES.failed),
      )
      .addCase(getSectors.pending, doAddTaskByType(TASK_TYPES.loadSectors))
      .addCase(getSectors.fulfilled, doUpdateTaskByType(TASK_TYPES.loadSectors, TASK_STATES.completed))
      .addCase(getSectors.rejected, doUpdateTaskByType(TASK_TYPES.loadSectors, TASK_STATES.failed))
      .addCase(getProjectSectors.pending, doAddTaskByType(TASK_TYPES.loadProjectSectors))
      .addCase(getProjectSectors.fulfilled, doUpdateTaskByType(TASK_TYPES.loadProjectSectors, TASK_STATES.completed))
      .addCase(getProjectSectors.rejected, doUpdateTaskByType(TASK_TYPES.loadProjectSectors, TASK_STATES.failed))
      .addCase(getComplaints.pending, doAddTaskByType(TASK_TYPES.loadComplaints))
      .addCase(getComplaints.fulfilled, doUpdateTaskByType(TASK_TYPES.loadComplaints, TASK_STATES.completed))
      .addCase(getComplaints.rejected, doUpdateTaskByType(TASK_TYPES.loadComplaints, TASK_STATES.failed))
      .addCase(getCoverage.pending, doAddTaskByType(TASK_TYPES.loadCoverage))
      .addCase(getCoverage.fulfilled, doUpdateTaskByType(TASK_TYPES.loadCoverage, TASK_STATES.completed))
      .addCase(getCoverage.rejected, doUpdateTaskByType(TASK_TYPES.loadCoverage, TASK_STATES.failed))
    builder
      .addCase(doGetAllBCData.pending, doAddTaskByType(TASK_TYPES.loadBusinessUnits))
      .addCase(doGetAllBCData.fulfilled, doUpdateTaskByType(TASK_TYPES.loadBusinessUnits, TASK_STATES.completed))
      .addCase(doGetAllBCData.rejected, doUpdateTaskByType(TASK_TYPES.loadBusinessUnits, TASK_STATES.failed))
      .addCase(doCreateBusinessCases.pending, doAddTaskByType(TASK_TYPES.createBC))
      .addCase(doCreateBusinessCases.fulfilled, doUpdateTaskByType(TASK_TYPES.createBC, TASK_STATES.completed))
      .addCase(doCreateBusinessCases.rejected, doUpdateTaskByType(TASK_TYPES.createBC, TASK_STATES.failed))
      .addCase(doDeleteBusinessCases.pending, doAddTaskByType(TASK_TYPES.removeBC))
      .addCase(doDeleteBusinessCases.fulfilled, doUpdateTaskByType(TASK_TYPES.removeBC, TASK_STATES.completed))
      .addCase(doDeleteBusinessCases.rejected, doUpdateTaskByType(TASK_TYPES.removeBC, TASK_STATES.failed))
      .addCase(doCalculateDraft.pending, doAddTaskByType(TASK_TYPES.calculateBC))
      .addCase(doCalculateDraft.fulfilled, doUpdateTaskByType(TASK_TYPES.calculateBC, TASK_STATES.completed))
      .addCase(doCalculateDraft.rejected, doUpdateTaskByType(TASK_TYPES.calculateBC, TASK_STATES.failed))
      .addCase(doCalculateForApprove.pending, doAddTaskByType(TASK_TYPES.calculateBC))
      .addCase(doCalculateForApprove.fulfilled, doUpdateTaskByType(TASK_TYPES.calculateBC, TASK_STATES.completed))
      .addCase(doCalculateForApprove.rejected, doUpdateTaskByType(TASK_TYPES.calculateBC, TASK_STATES.failed))
      .addCase(doApprove.pending, doAddTaskByType(TASK_TYPES.approveBC))
      .addCase(doApprove.fulfilled, doUpdateTaskByType(TASK_TYPES.approveBC, TASK_STATES.completed))
      .addCase(doApprove.rejected, doUpdateTaskByType(TASK_TYPES.approveBC, TASK_STATES.failed))
      .addCase(doReject.pending, doAddTaskByType(TASK_TYPES.rejectBC))
      .addCase(doReject.fulfilled, doUpdateTaskByType(TASK_TYPES.rejectBC, TASK_STATES.completed))
      .addCase(doReject.rejected, doUpdateTaskByType(TASK_TYPES.rejectBC, TASK_STATES.failed))
    builder
      .addCase(doExportToCSV.pending, doAddTaskByType(TASK_TYPES.exportHistoricalData))
      .addCase(doExportToCSV.fulfilled, doUpdateTaskByType(TASK_TYPES.exportHistoricalData, TASK_STATES.completed))
      .addCase(doExportToCSV.rejected, doUpdateTaskByType(TASK_TYPES.exportHistoricalData, TASK_STATES.failed))
      .addCase(doExportToExcel.pending, doAddTaskByType(TASK_TYPES.exportHistoricalData))
      .addCase(doExportToExcel.fulfilled, doUpdateTaskByType(TASK_TYPES.exportHistoricalData, TASK_STATES.completed))
      .addCase(doExportToExcel.rejected, doUpdateTaskByType(TASK_TYPES.exportHistoricalData, TASK_STATES.failed))
    builder
      .addCase(uploadVectorMap.pending, doAddTaskByType(TASK_TYPES.uploadVectorMap))
      .addCase(uploadVectorMap.fulfilled, doUpdateTaskByType(TASK_TYPES.uploadVectorMap, TASK_STATES.completed))
      .addCase(uploadVectorMap.rejected, doUpdateTaskByType(TASK_TYPES.uploadVectorMap, TASK_STATES.failed))
      .addCase(loadVectorMapGeoJson.pending, doAddTaskByType(TASK_TYPES.loadVectorMapJSON))
      .addCase(loadVectorMapGeoJson.fulfilled, doUpdateTaskByType(TASK_TYPES.loadVectorMapJSON, TASK_STATES.completed))
      .addCase(loadVectorMapGeoJson.rejected, doUpdateTaskByType(TASK_TYPES.loadVectorMapJSON, TASK_STATES.failed))
      .addCase(loadVectorMapGeoAttributes.pending, doAddTaskByType(TASK_TYPES.loadVectorMapAttributes))
      .addCase(
        loadVectorMapGeoAttributes.fulfilled,
        doUpdateTaskByType(TASK_TYPES.loadVectorMapAttributes, TASK_STATES.completed),
      )
      .addCase(
        loadVectorMapGeoAttributes.rejected,
        doUpdateTaskByType(TASK_TYPES.loadVectorMapAttributes, TASK_STATES.failed),
      )
    builder
      .addCase(uploadRasterMap.pending, doAddTaskByType(TASK_TYPES.uploadRasterMap))
      .addCase(uploadRasterMap.fulfilled, doUpdateTaskByType(TASK_TYPES.uploadRasterMap, TASK_STATES.completed))
      .addCase(uploadRasterMap.rejected, doUpdateTaskByType(TASK_TYPES.uploadRasterMap, TASK_STATES.failed))
  },
})

export const {
  addTask,
  updateTask,
  updateTaskByType,
  setTaskLog,
  clearTaskLog,
} = taskLogSlice.actions

export const selectTaskLog = (state) => state.taskLog.taskLog

export const selectPendingTasks = (state) => state.taskLog.taskLog.some((task) => task.state === TASK_STATES.active)

export const setTaskFailed = (id) => async (dispatch) => {
  dispatch(updateTask({ id, state: TASK_STATES.failed }))
}

export const setTaskCompleted = (id) => async (dispatch) => {
  dispatch(updateTask({ id, state: TASK_STATES.completed }))
}

export const loadProjectJobs = (projectId) => async (dispatch, getState) => {
  const currentState = getState()
  const taskLog = selectTaskLog(currentState)
  const activeJobsValue = localStorage.getItem(`active_project_jobs_${projectId}`)
  if (!activeJobsValue) {
    return
  }
  const activeJobs = JSON.parse(activeJobsValue)
  const results = await Promise.all(activeJobs.map(({ id }) => dispatch(doLoadJobStatusById(id))))
  const remainActive = results.map((result) => {
    if (!result.error) {
      const { id, status } = result.payload
      let type = taskLog?.find(({ id: jobId }) => jobId === id)?.type
      if (!type) {
        const activeJob = activeJobs.find(({ id: jobId }) => jobId === id)
        type = activeJob?.type
        const name = activeJob?.name
        if (name) {
          dispatch(addTask({ name, type, id }))
        } else {
          dispatch(addTask({ type, id }))
        }
      }
      if (status === STATUS_JOB.ACTIVE) {
        return { id, type }
      } else {
        if (status === STATUS_JOB.FINISHED_SUCCESS) {
          dispatch(setTaskCompleted(id))
        } else {
          dispatch(setTaskFailed(id))
        }
      }
    }
    return null
  }).filter(Boolean)
  if (remainActive.length > 0) {
    localStorage.setItem(`active_project_jobs_${projectId}`, JSON.stringify(remainActive))
    setTimeout(() => {
      dispatch(loadProjectJobs(projectId))
    }, 10000)
  }
}

export const saveActiveProjectJobs = (projectId) => async (dispatch, getState) => {
  const currentState = getState()
  const taskLog = selectTaskLog(currentState)
  if (taskLog) {
    const activeJobs = taskLog
      .filter(({ state }) => state === TASK_STATES.active)
      .map(({ id, type, name }) => ({ id, type, name }))
    if (activeJobs?.length > 0) {
      localStorage.setItem(`active_project_jobs_${projectId}`, JSON.stringify(activeJobs))
      return
    }
  }
  localStorage.removeItem(`active_project_jobs_${projectId}`)
}

export default taskLogSlice.reducer
