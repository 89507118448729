import React, { useRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Spinner, SpinnerSize } from '@fluentui/react'
import tokml from 'tokml'
import Table from '../common/Table'
import {
  filterAllData, addFilters, resetFilters, selectLoading, selectMapAttributes, importMapAttributes, updateMapAttributes,
  selectVectorItems, selectMapById,
} from '../../features/vector/vectorSlice'
import { generateColumnsVectorMap, getDataType, NAME_FIELD1, NAME_FIELD2 } from '../../features/vector/utils'
import { useConfirm } from '../common/Confirm'
import { dataToZip, kmlToFile } from '../../utils/export'
import { geoJsonToMidMif } from '../../utils/convert'
import { EXPORT_FORMAT } from '../../constants/menus'
import { MID_MIF_EXT } from '../../constants/geo'
import { addTaskType, setTaskTypeCompleted, setTaskTypeFailed, TASK_TYPES } from '../../features/taskLog'

const MapAttributes = () => {
  const gridRef = useRef()
  const dispatch = useDispatch()
  const { renderConfirm, msg } = useConfirm()
  const { vectorMapId: id } = useParams()
  const dataType = useMemo(() => getDataType(id), [ id ])
  const loading = useSelector(selectLoading)
  const attributes = useSelector(selectMapAttributes(dataType))
  const map = useSelector(selectMapById(id))
  const data = useMemo(() => attributes?.list, [ attributes ])
  const columns = useMemo(() => generateColumnsVectorMap(dataType, attributes?.fields), [ attributes, dataType ])
  const vectorMaps = useSelector(selectVectorItems)
  const name = useMemo(() => map?.name, [ map ])
  const idFieldIdx = useMemo(() => map?.idFldIdx, [ map ])
  const nameField = useMemo(() => map?.nameFld, [ map ])

  const onImport = useCallback(async (cols, rows) => {
    const nameColumn = cols
      .findIndex((col) => col?.id === nameField || col?.id === NAME_FIELD1 || col?.id === NAME_FIELD2)
    if (nameColumn < 0) {
      msg({ messages: [ 'Mandatory column "Name" not found in dataset being imported' ] })
      return false
    }

    document.body.classList.add('waiting')
    const result = await dispatch(importMapAttributes({ cols, rows, dataType, id }))

    const results = []

    if (result.updated > 0) {
      results.push(`${result.updated} attribute${result.updated > 1 ? 's where' : ' was'} updated`)
    }
    if (result.ignored > 0) {
      results.push(`${result.ignored} attribute${result.ignored > 1 ? 's where' : ' was'} ignored`)
    }

    const messages = [
      `${results.length ? results.join(' and ') : 'No changes where made '} by this import operation`,
    ]

    document.body.classList.remove('waiting')

    msg({ messages })

    return results.length > 0
  }, [ dispatch, msg, dataType, id, nameField ])

  const inlineEdit = useCallback((fieldId, field, value) => {
    dispatch(updateMapAttributes({ mapId: id, dataType, id: fieldId, field, value }))
  }, [ dispatch, dataType, id ])

  const onExport = useCallback((columnsAll, cells, filterFn, id, format) => {
    const { json: geoJson, name } = vectorMaps.find((vector) => (vector.id === id)) ?? {}
    if (geoJson?.features) {
      const description = 'vector map'
      const taskType = TASK_TYPES.exportVectorMap
      const callbackFailed = () => dispatch(setTaskTypeFailed(taskType))
      const callbackCompleted = () => dispatch(setTaskTypeCompleted(taskType))
      dispatch(addTaskType(taskType, description))
      switch (format) {
        case EXPORT_FORMAT.MIF_MID: {
          let idIdx = null
          const columns = columnsAll.filter((item, index) => {
            const id = item.id?.split('.')?.[1]
            if (id === 'id' && idIdx === null) {
              idIdx = index
            }
            return filterFn(item, index)
          })
          const features = cells.map((item) => geoJson.features.find((feature) => (feature.id === item[idIdx])))
            .filter(Boolean)
          const data = cells.map((row) => row.filter((item, index) => (
            filterFn(item, index)
          )))
          const { mid, mif } = geoJsonToMidMif({ features }, columns, data)
          dataToZip([ mid, mif ], name, MID_MIF_EXT, 'zip', callbackFailed, callbackCompleted)
          break
        }
        case EXPORT_FORMAT.KMZ: {
          const kmlData = tokml(geoJson, { documentName: name, documentDescription: description })
          dataToZip(kmlData, name, 'kml', 'kmz', callbackFailed, callbackCompleted)
          break
        }
        case EXPORT_FORMAT.KML: {
          const kmlData = tokml(geoJson, { documentName: name, documentDescription: description })
          kmlToFile(kmlData, `${name}.kml`)
          callbackCompleted()
          break
        }
        default: callbackFailed()
      }
    }
  }, [ dispatch, vectorMaps ])

  return loading
    ? (
        <Spinner className="centered" size={SpinnerSize.large} />
      )
    : data && columns
      ? (
          <>
            <Table
              refHot={gridRef}
              id={id}
              dataType={dataType}
              label={name}
              data={data}
              columns={columns}
              currentRowClassName={''}
              dataFiltering={filterAllData}
              addFilters={addFilters}
              resetFilters={resetFilters}
              onImport={onImport}
              onExport={onExport}
              idFieldIdx={idFieldIdx}
              onInlineEdit={inlineEdit}
              manualColumnMove={true}
            />
            {renderConfirm()}
          </>
        )
      : null
}

export default MapAttributes
