export const children = (root) => root?.children?.getList ? root.children.getList() : root?.children

export const findNode = (root, path) => path.reduce((acc, item) => children(acc)?.[item], root)

export const checkPartial = (root, path, prop) => {
  if (!path.length) {
    return
  }
  const node = findNode(root, path)
  const list = children(node)
  const count = list.reduce((acc, item) => acc + (item.state?.[prop] ? 1 : 0), 0)
  if (!node.state) {
    node.state = {}
  }
  node.state[prop] = count > 0
  node.state.partial = count < list.length
  checkPartial(root, path.slice(0, -1), prop)
}

export const setProp = (state, path, prop, value, partial, getRoot, skipCheckForParent, order) => {
  const root = getRoot(state)
  const node = findNode(root, path)
  if (node.state) {
    node.state[prop] = value
  } else {
    node.state = { [prop]: value }
  }
  if (order !== undefined) {
    node.state.order = order
  }
  if (node.item && prop === 'selected') {
    node.item.hidden = !value
  }
  if (partial) {
    node.state.partial = false
    children(node)?.forEach((_, index) => setProp(state, [ ...path, index ], prop, value, true, getRoot, true))
    if (!skipCheckForParent) {
      checkPartial(root, path.slice(0, -1), prop)
    }
  }
}

export const invertProp = (state, path, prop, partial, getRoot, order) => {
  const value = findNode(getRoot(state), path).state?.[prop]
  setProp(state, path, prop, !value, partial, getRoot, undefined, order)
}

export const setTreeItemSelected = (state, path, value, toggleSelection, getRoot) => {
  const node = findNode(getRoot(state), path)
  if (!!node.state?.selected !== value) {
    toggleSelection(state, path)
  }
}

export const applyTreeState = (node, sample) => {
  const { expanded, selected, partial } = sample.state ?? {}
  if (expanded || selected || partial) {
    if (!node.state) {
      node.state = {}
    }
    if (expanded) {
      node.state.expanded = true
    }
    if (selected) {
      node.state.selected = true
    }
    if (partial) {
      node.state.partial = true
    }
  }
  if (node.children && sample.children) {
    for (const childSample of sample.children) {
      const childNode = node.children.find(({ id }) => id === childSample.id)
      if (childNode) {
        applyTreeState(childNode, childSample)
      }
    }
  }
}

export const getTreeState = (node) => {
  const { id, state, children } = node
  const result = {
    id,
  }
  if (state) {
    const { selected, expanded, partial } = state
    if (selected || expanded || partial) {
      result.state = {}
      if (selected) {
        result.state.selected = true
      }
      if (expanded) {
        result.state.expanded = true
      }
      if (partial) {
        result.state.partial = true
      }
    }
  }
  if (children && children.length) {
    result.children = children.map(getTreeState)
  }
  return result
}
