import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontWeights, getTheme, IconButton, mergeStyleSets, Modal, Stack, StackItem } from '@fluentui/react'
import { doChangePassword, setChangePasswordOpen } from '../../features/login/loginSlice'
import { selectPasswordRules } from '../../features/network/networkSlice'
import { useConfirm } from '../common/Confirm'
import { DefaultButton, PrimaryButton } from '../common/Button'
import TextField from '../common/TextField'

import './ChangePassword.css'

const theme = getTheme()

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}

const cancelIcon = { iconName: 'Cancel' }

const contentStyles = mergeStyleSets({
  header: [
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
})

const ruleClassName = (rule) => rule === false ? 'rule_error' : rule === true ? 'rule_ok' : undefined

const ChangePassword = () => {
  const dispatch = useDispatch()
  const { msg, renderConfirm } = useConfirm()

  const [ oldPassword, setOldPassword ] = useState()
  const [ newPassword, setNewPassword ] = useState()
  const [ newPassword2, setNewPassword2 ] = useState()
  const [ ruleLength, setRuleLength ] = useState()
  const [ ruleUpper, setRuleUpper ] = useState()
  const [ ruleLower, setRuleLower ] = useState()
  const [ ruleDigit, setRuleDigit ] = useState()
  const [ ruleSpecial, setRuleSpecial ] = useState()
  const [ ruleMatch, setRuleMatch ] = useState()

  const passwordRules = useSelector(selectPasswordRules)

  const handleCancel = useCallback(() => {
    dispatch(setChangePasswordOpen(false))
  }, [ dispatch ])

  const handleOk = useCallback(async () => {
    const result = await dispatch(doChangePassword(oldPassword, newPassword))
    const finish = () => dispatch(setChangePasswordOpen(false))
    if (result.error) {
      finish()
    } else {
      msg({ messages: [ 'Your password has been changed.' ] }, finish)
    }
  }, [ dispatch, oldPassword, newPassword, msg ])

  const handleChangeOldPassword = useCallback((event, value) => {
    setOldPassword(value)
  }, [ setOldPassword ])

  const handleChangeNewPassword = useCallback((event, value) => {
    setNewPassword(value)
  }, [ setNewPassword ])

  const handleChangeConfirmPassword = useCallback((event, value) => {
    setNewPassword2(value)
  }, [ setNewPassword2 ])

  const disabled = useMemo(() => {
    let result = !oldPassword
    const empty = !newPassword
    const tooShort = newPassword && newPassword.length < passwordRules.lengthMin
    if (empty || tooShort) {
      setRuleLength(tooShort ? false : undefined)
      setRuleDigit()
      setRuleLower()
      setRuleUpper()
      setRuleSpecial()
      result = true
    } else {
      const properLength = newPassword.length <= passwordRules.lengthMax
      setRuleLength(properLength)
      result |= !properLength
      const properUpper = newPassword.match(/[A-Z]/g)?.length >= passwordRules.upperCase
      setRuleUpper(properUpper)
      result |= !properUpper
      const properLower = newPassword.match(/[a-z]/g)?.length >= passwordRules.lowerCase
      setRuleLower(properLower)
      result |= !properLower
      const properDigits = newPassword.match(/\d/g)?.length >= passwordRules.number
      setRuleDigit(properDigits)
      result |= !properDigits
      const properSpecial = newPassword.match(/[^A-Za-z0-9]/g)?.length >= passwordRules.special
      setRuleSpecial(properSpecial)
      result |= !properSpecial
    }
    if (!newPassword2) {
      setRuleMatch()
      result = true
    } else {
      setRuleMatch(newPassword === newPassword2)
      result |= newPassword !== newPassword2
    }
    return result
  }, [
    oldPassword, newPassword, newPassword2, setRuleLength, passwordRules, setRuleUpper, setRuleLower, setRuleDigit,
    setRuleSpecial, setRuleMatch,
  ])

  return (
    <Modal
      containerClassName="top-most"
      isOpen={true}
      isBlocking={true}
    >
      <div className={contentStyles.header}>
        <span>Change Password</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          onClick={handleCancel}
        />
      </div>
      <Stack className="modal-content-change-password">
        <StackItem>
          <TextField
            label="Old Password"
            type="password"
            onChange={handleChangeOldPassword}
          />
          <TextField
            label="New Password"
            type="password"
            onChange={handleChangeNewPassword}
          />
          <TextField
            label="Confirm New Password"
            type="password"
            onChange={handleChangeConfirmPassword}
          />
        </StackItem>
        <StackItem>
          <ul className={ruleClassName(ruleLength)}>
            <li>Password must be from {passwordRules.lengthMin} to {passwordRules.lengthMax} characters long</li>
          </ul>
          <ul className={ruleClassName(ruleUpper)}>
            <li>Password must contain at least {passwordRules.upperCase} uppercase letters</li>
          </ul>
          <ul className={ruleClassName(ruleLower)}>
            <li>Password must contain at least {passwordRules.lowerCase} lowercase letters</li>
          </ul>
          <ul className={ruleClassName(ruleDigit)}>
            <li>Password must contain at least {passwordRules.number} digits</li>
          </ul>
          <ul className={ruleClassName(ruleSpecial)}>
            <li>Password must contain at least {passwordRules.special} special characters</li>
          </ul>
          <ul className={ruleClassName(ruleMatch)}>
            <li>Password confirmation must match</li>
          </ul>
          <br />
        </StackItem>
        <StackItem>
          <DefaultButton
            className="dialog-button-change-password"
            text="Cancel"
            onClick={handleCancel}
          />
          <PrimaryButton
            className="dialog-button-change-password"
            text="OK"
            disabled={disabled}
            onClick={handleOk}
          />
        </StackItem>
      </Stack>
      {renderConfirm()}
    </Modal>
  )
}

export default ChangePassword
