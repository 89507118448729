import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useBoolean } from '@fluentui/react-hooks'
import { KEYS, cabinet as t } from '../constants'
import ShareProjectForm from '../Form/ShareProjectForm'
import CreateProjectForm from '../Form/CreateProjectForm'
import { selectUser } from '../../../features/login/loginSlice'
import {
  createProject, grantAccessToProjects, revokeAccessToProjects, loadProjects, removeProject, selectProjects,
  loadingProjects, copyProject, saveProject,
} from '../../../features/projects/projectsSlice'
import { useConfirm } from '../../common/Confirm'
import { confirm as tConfirm } from '../../common/Confirm/constants'
import { EMPTY } from '../../../constants'
import { addEventLog, EVENT_TYPE, STATUS_ERROR } from '../../../features/eventLog/eventLogSlice'
import Component from './Component'
import { COLUMNS, getColumnIdByIndex, getColumnIndexById, TABLE_ID } from './settings'
import { generateNameProject, findProjectName } from './utils'

const Container = () => {
  const hotGridRef = useRef(null)
  const dispatch = useDispatch()
  const { renderConfirm, confirm, msg } = useConfirm()

  useEffect(() => {
    dispatch(loadProjects())
  }, [ dispatch ])

  const [ isShareOpen, { setTrue: showShare, setFalse: hideShare } ] = useBoolean(false)
  const [ isCreateOpen, { setTrue: showCreate, setFalse: hideCreate } ] = useBoolean(false)
  const [ isCopyOpen, { setTrue: showCopy, setFalse: hideCopy } ] = useBoolean(false)
  const [ projectDefault, setProjectDefault ] = useState({})
  const [ shareProjects, setShareProject ] = useState([])
  const [ selectProjectById, setSelectProjectById ] = useState(null)
  const [ projects, setProjects ] = useState([])

  const user = useSelector(selectUser)
  const newProjects = useSelector(selectProjects)

  useEffect(() => {
    const projects = newProjects?.map((item) => ({ [KEYS.CHECK]: false, ...item })) ?? []
    setProjects(projects)
  }, [ newProjects ])

  const [ amountSelected, setAmountSelected ] = useState(0)
  const [ nonRemovable, setNonRemovable ] = useState(true)

  const data = useMemo(() => {
    if (!Array.isArray(newProjects)) {
      return EMPTY.ARRAY
    }

    return newProjects.map((project) => {
      return COLUMNS.map(
        (column) => {
          switch (column.id) {
            case KEYS.OWNER_NAME: return project?.[KEYS.OWNER]?.[KEYS.NAME]
            case KEYS.OWNER_ID: return project?.[KEYS.OWNER]?.[KEYS.ID]
            case KEYS.CHECK: return false
            default: return project?.[column.id]
          }
        })
    })
  }, [ newProjects ])

  // Вибір потрібного проєкту
  useEffect(() => {
    if (selectProjectById) {
      const columnSettings = hotGridRef.current?.columnSettings ?? []
      let idIdx = null
      let nameIdx = null
      const idId = `${TABLE_ID}.${KEYS.ID}`
      const idProjectName = `${TABLE_ID}.${KEYS.PROJECT_NAME}`
      columnSettings.forEach((col, index) => {
        if (col[KEYS.ID] === idId) {
          idIdx = index
        }
        if (col[KEYS.ID] === idProjectName) {
          nameIdx = index
        }
      })
      if (hotGridRef?.current?.hotInstance && idIdx !== null && nameIdx !== null) {
        const table = hotGridRef.current.hotInstance
        const colId = table.getSourceDataAtCol(idIdx) ?? []
        const row = colId.indexOf(selectProjectById)
        if (row + 1) {
          const visualRow = table.toVisualRow(row)
          table.selectCell(visualRow, nameIdx)
        }
      }
      setSelectProjectById(null)
    }
  }, [ selectProjectById, setSelectProjectById, hotGridRef ])

  const projectNameById = useCallback((projectId) => projects.find(({ id }) => id === projectId)?.description,
    [ projects ])

  const onDelete = useCallback(async (deletedProjects) => {
    if (Array.isArray(deletedProjects)) {
      const descriptionIdx = getColumnIndexById(KEYS.DESCRIPTION)
      const columnId = getColumnIndexById(KEYS.ID)
      const ownerId = getColumnIndexById(KEYS.OWNER_ID)
      const projectsNames = deletedProjects.map((project) => project[descriptionIdx])
      const onOk = async () => {
        const projectsToDelete = deletedProjects.filter((project) => project[ownerId] === user.id)
        let projectsToUnShare = deletedProjects.filter((project) => project[ownerId] !== user.id)
        if (projectsToDelete.length) {
          const res = await dispatch(removeProject(projectsToDelete.map((project) => project[columnId])))
          if (Array.isArray(res)) {
            const messages = res
              .map((item) => item?.error?.message ? `${projectNameById(item.meta?.arg)}: ${item?.error?.message}` : null)
              .filter(Boolean)
            if (messages.length) {
              messages.splice(0, 0, 'Unable to delete')
              msg({ messages })
            }
          }
        }
        if (projectsToUnShare.length) {
          const res = await dispatch(loadProjects())
          if (Array.isArray(res.payload)) {
            projectsToUnShare = projectsToUnShare
              .filter((project) => res.payload.find((item) => item.id === project[columnId]))
          }
          if (projectsToUnShare.length) {
            await dispatch(revokeAccessToProjects(projectsToUnShare.map((project) => project[columnId])))
          }
        }
        dispatch(loadProjects())
      }
      confirm(
        onOk,
        {
          title: t.deleteProject,
          messages: [ t.messageDeleteProject(projectsNames.length), ...projectsNames ],
          textYesBtn: tConfirm.ok,
          textNoBtn: tConfirm.cancel,
        },
      )
    }
  }, [ user, dispatch, confirm, msg, projectNameById ])

  const onCopy = useCallback(async (project) => {
    let projectId
    if (project && user) {
      const rez = await dispatch(loadProjects())
      projectId = project[getColumnIndexById(KEYS.ID)]
      if (Array.isArray(rez.payload) && projectId) {
        const projectExists = rez.payload.some((project) => (project.id === projectId))
        if (projectExists) {
          const projects = hotGridRef?.current?.props?.data ?? []
          const projectDescription = project[getColumnIndexById(KEYS.DESCRIPTION)]

          const newName = findProjectName(projects, projectDescription, true)
          const data = {
            [KEYS.ID]: project[getColumnIndexById(KEYS.ID)],
            [KEYS.PROJECT_NAME]: generateNameProject(user, projects),
            [KEYS.DESCRIPTION]: newName,
          }
          setProjectDefault(data)
          showCopy()
          return
        }
      }
    }
    dispatch(addEventLog(window.location.pathname, `The project ${projectId ?? 'you are trying to copy'} no longer exists`, EVENT_TYPE.accessToDeletedResource, STATUS_ERROR))
    confirm(
      null,
      { title: 'Open project', messages: 'The project you are trying to copy no longer exists', textNoBtn: 'OK' },
    )
  }, [ showCopy, user, hotGridRef, dispatch, confirm ])

  const onCreate = useCallback(() => {
    if (!user) {
      return
    }
    const projects = hotGridRef?.current?.props?.data ?? []
    const project = {
      [KEYS.PROJECT_NAME]: generateNameProject(user, projects),
      [KEYS.DESCRIPTION]: '',
    }
    setProjectDefault(project)
    showCreate()
  }, [ showCreate, user, hotGridRef ])

  const handleCreateProject = useCallback(async (project) => {
    hideCreate()
    const id = await dispatch(createProject({
      [KEYS.PROJECT_NAME]: project[KEYS.PROJECT_NAME],
      [KEYS.DESCRIPTION]: project[KEYS.DESCRIPTION],
    }))
    if (id) {
      setSelectProjectById(id)
    }
  }, [ dispatch, hideCreate, setSelectProjectById ])

  const handleCopyProject = useCallback(async (project) => {
    hideCopy()
    const id = await dispatch(copyProject(project[KEYS.ID], project[KEYS.PROJECT_NAME], project[KEYS.DESCRIPTION]))
    if (id) {
      setSelectProjectById(id)
    }
  }, [ dispatch, hideCopy, setSelectProjectById ])

  const handleShareProject = useCallback((projects, users) => {
    hideShare()
    const usersId = users.map((user) => (user[KEYS.ID]))
    const columnId = getColumnIndexById(KEYS.ID)
    const projectsId = projects.map((project) => (project[columnId]))
    dispatch(grantAccessToProjects(projectsId, usersId))
  }, [ dispatch, hideShare ])

  // Ініціалізація стану кількості вибраних проєктів
  useEffect(() => {
    const column = getColumnIndexById(KEYS.CHECK)
    const projectsSelected = data?.filter((item) => (item[column] === true)) ?? []
    setAmountSelected(projectsSelected?.length ?? 0)
  }, [ data ])

  const onAfterEdit = useCallback(async (dataEdit) => {
    const [ row, column, oldValue, newValue ] = dataEdit?.[0] ?? []
    if (oldValue === newValue) {
      return
    }
    const id = getColumnIdByIndex(column)
    switch (id) {
      case KEYS.CHECK: {
        let notMyProject = false
        const projectsSelected = data?.filter((item, index) => {
          if (item[column] === true) {
            if (projects[index][KEYS.OWNER][KEYS.ID] !== user.id) {
              notMyProject = true
            }
          }
          return (item[column] === true)
        }) ?? []
        if (amountSelected !== projectsSelected.length) {
          setAmountSelected(projectsSelected?.length ?? 0)
        }
        if (nonRemovable !== notMyProject) {
          setNonRemovable(notMyProject)
        }
        break
      }
      case KEYS.DESCRIPTION: {
        // сохранить измененённого имени проекта
        const editableProject = projects[hotGridRef.current.hotInstance.toPhysicalRow(row)]
        if (editableProject) {
          editableProject[KEYS.DESCRIPTION] = newValue
          /* const rez = */ await dispatch(saveProject(editableProject))
          /* if (rez) {
            dispatch(loadProjects())
          } */
        }
        break
      }
      default:
    }
  }, [ data, amountSelected, nonRemovable, projects, user.id, dispatch, hotGridRef ])

  const onOpen = useCallback(async (project) => {
    let projectId
    if (project) {
      const rez = await dispatch(loadProjects())
      projectId = project[getColumnIndexById(KEYS.ID)]
      if (Array.isArray(rez.payload) && projectId) {
        const isProjectExist = rez.payload.some((project) => (project.id === projectId))
        if (isProjectExist) {
          window.open(`/${projectId}`)
          return
        }
      }
    }
    dispatch(addEventLog(window.location.pathname, `The project ${projectId ?? 'you are trying to open'} no longer exists`, EVENT_TYPE.accessToDeletedResource, STATUS_ERROR))
    confirm(
      null,
      { title: 'Open project', messages: 'The project you are trying to open no longer exists', textNoBtn: 'OK' },
    )
  }, [ confirm, dispatch ])

  const onShare = useCallback((projects) => {
    if (projects) {
      setShareProject(projects)
      showShare()
    }
  }, [ showShare ])

  const setCells = useCallback(function (row, col) {
    if (col === 3 || col === 5) {
      // row = this.instance.toPhysicalRow(row)
      const userId = projects?.[row]?.owner?.id
      if (user.id !== userId) {
        return col === 3
          ? { readOnly: true }
          : { className: 'not-my-project' }
      }
    }
  }, [ projects, user ])

  return (
  <>
    {renderConfirm()}
    <Component
      amountSelected={amountSelected}
      data={data}
      setCells={setCells}
      onDelete={onDelete}
      onCopy={onCopy}
      onAfterEdit={onAfterEdit}
      onShare={onShare}
      onCreate={onCreate}
      onOpen={onOpen}
      hotGridRef={hotGridRef}
      nonRemovable={nonRemovable}
      user={user}
      loading={useSelector(loadingProjects)}
    />
    <ShareProjectForm
      isShow={isShareOpen}
      onHide={hideShare}
      onOk={handleShareProject}
      projects={shareProjects}
    />
    <CreateProjectForm
      isShow={isCreateOpen}
      onHide={hideCreate}
      onCreate={handleCreateProject}
      project={projectDefault}
      title={t.createProject}
    />
    <CreateProjectForm
      isShow={isCopyOpen}
      onHide={hideCopy}
      onCreate={handleCopyProject}
      project={projectDefault}
      title={t.copyProject}
      okText={t.apply}
    />
  </>
  )
}

export default Container
