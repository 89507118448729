import { cabinet, KEYS } from '../constants'

export const COLUMNS = [
  {
    key: KEYS.ID,
    title: 'ID',
    label: 'ID',
    editable: false,
    readOnly: true,
    hidden: true,
  },
  {
    key: KEYS.TYPE,
    title: 'Type',
    label: 'Type',
    editable: false,
    readOnly: true,
    hidden: true,
  },
  {
    key: KEYS.SOURCE_NAME,
    title: cabinet.title[KEYS.SOURCE_NAME],
    label: cabinet.title[KEYS.SOURCE_NAME],
    type: 'text',
    editable: false,
    readOnly: true,
    width: 160,
  },
  {
    key: KEYS.LAST_SYNC_DATE,
    title: cabinet.title[KEYS.LAST_SYNC_DATE],
    label: cabinet.title[KEYS.LAST_SYNC_DATE],
    type: 'date',
    subType: 'noAutocomplete',
    editable: false,
    readOnly: true,
    dateFormat: 'DD.MM.YYYY hh:mm:ss',
    size: 160,
    className: 'align-center hideAutocomplete',
  },
  {
    key: KEYS.SYNCHRONIZATION_PERIOD,
    title: cabinet.title[KEYS.SYNCHRONIZATION_PERIOD],
    label: cabinet.title[KEYS.SYNCHRONIZATION_PERIOD],
    type: 'string',
    editable: false,
    readOnly: true,
    width: 160,
    className: 'align-center',
  },
  {
    key: KEYS.STATUS,
    title: cabinet.title[KEYS.STATUS],
    label: cabinet.title[KEYS.STATUS],
    type: 'text',
    editable: false,
    readOnly: true,
    className: 'align-center',
  },
  {
    key: KEYS.EXECUTE_DETAILS,
    title: cabinet.title[KEYS.EXECUTE_DETAILS],
    label: cabinet.title[KEYS.EXECUTE_DETAILS],
    type: 'error',
    editable: false,
    readOnly: true,
    className: 'align-center',
  },
]

// export const getIndexColumn = (id) => COLUMNS.findIndex((column) => column.id === id)
export const getIdColumnFromIndex = (index) => COLUMNS[index].id

export const sourcesName = [
  { key: 'SYNC_ATOLL_NETWORK', name: 'Atoll Network' },
  { key: 'SYNC_ATOLL_MAP', name: 'Atoll Map' },
  { key: 'SYNC_NETWORK_DEFAULT', name: 'DWH 018734 actual' },
  { key: 'SYNC_NETWORK_HISTORIES_DATES', name: 'DWH 018734 historical', period: true },
  { key: 'SYNC_COMPLAINT', name: 'DWH Complaints actual' },
  { key: 'SYNC_COMPLAINT_HISTORIES', name: 'DWH Complaints historical', period: true },
  { key: 'SYNC_SNC_COMPLAINTS', name: 'SNC Complaints', period: true },
  { key: 'SYNC_SNC_SETTLEMENTS', name: 'SNC Settlements', import: true },
]

export const SYNC_STATUS_ACTIVE = 'ACTIVE'
// export const statusSynchronize = [ 'NEW', SYNC_STATUS_ACTIVE, 'FINISHED_SUCCESS', 'FINISHED_FAIL' ]
