import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkTreeItemGeo, selectSubstances } from '../../../../features/geo/geoSlice'
import MapLayerButton from './MapLayerButton'

const MapLayers = () => {
  const dispatch = useDispatch()
  const substances = useSelector(selectSubstances)

  const handleClick = useCallback((substance) => {
    dispatch(checkTreeItemGeo(substance?.path))
  }, [ dispatch ])

  return (
    <>
      {substances.map((substance, index) => (
        <MapLayerButton key={substance.id} substance={substance} index={index} onClick={handleClick} />
      ))}
    </>
  )
}

export default MapLayers
