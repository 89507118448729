import L from 'leaflet'
import './leaflet-control-button.css'

const SimpleButton = L.Control.extend({
  options: {
    position: 'topleft',
    // title
    // className
    // onClick
  },

  onAdd: function (map) {
    let classNames = 'leaflet-button'
    const { className } = this.options
    if (className) {
      classNames += ` ${className}`
    }
    const button = L.DomUtil.create('a', classNames)
    button.href = '#'
    button.title = this.options.title || 'Button'
    L.DomEvent.disableClickPropagation(button)
    L.DomEvent.on(button, 'click', L.DomEvent.stop)
    L.DomEvent.on(button, 'click', this._onClick, this)
    L.DomEvent.on(button, 'click', this._refocusOnMap, this)
    this.button = button
    return button
  },

  _onClick: function () {
    this.options.onClick && this.options.onClick(this)
  },
})

L.SimpleButton = SimpleButton

L.control.simpleButton = function (options) {
  return new L.SimpleButton(options)
}
