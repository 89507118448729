import { fetch } from './rest'

export const getTemplateList = () => fetch('/api/composite/templates', 'GET')
  .catch((error) => console.error(`Composite Index: Get Templates error: ${error}`))

export const saveTemplate = ({ id, data }) => fetch(`/api/composite/templates/${id}`, 'PUT', data)
  .catch((error) => console.error(`Composite Index: Save Template error: ${error}`))

export const createTemplate = (data) => fetch('/api/composite/templates', 'POST', data)
  .catch((error) => console.error(`Composite Index: Create Template error: ${error}`))

export const deleteTemplate = (id) => fetch(`/api/composite/templates/${id}`, 'DELETE')
  .catch((error) => console.error(`Composite Index: Delete Template error: ${error}`))

export const loadDataSite = ({ fromDate, toDate, names }) =>
  fetch('/api/history/composite/sites', 'POST', { fromDate, toDate, groupBy: 'name', names })
    .catch((error) => console.error(`Composite Index: Load Sites error: ${error}`))

export const loadDataRBS = ({ fromDate, toDate, names }) =>
  fetch('/api/history/composite/rbses', 'POST', { fromDate, toDate, names })
    .catch((error) => console.error(`Composite Index: Load RBS error ${error}`))

export const loadDataSector = ({ fromDate, toDate, names }) =>
  fetch('/api/history/composite/sectors', 'POST', { fromDate, toDate, names })
    .catch((error) => console.error(`Composite Index: Load Sectors error: ${error}`))
