import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { activeProjectId } from '../../features/projects/projectsSlice'
import { saveActiveProjectJobs } from '../../features/taskLog/taskLogSlice'
import { logout, selectUser, selectUserAccess, setChangePasswordOpen } from '../../features/login/loginSlice'
import { selectDocUrl } from '../../features/network/networkSlice'
import { isLocalAdmin } from '../../features/login/utils'
import SelectOperation from '../common/SelectOperation'
import info from '../../../package.json'
import { getGeneralizedRole, KEYS } from '../../constants/roles'
import { EDIT_PROJECTS } from '../../constants/access'

const operations = (hideCabinetItem, hideChangePasswordItem, hideDefaultProject) => [
  {
    title: 'Cabinet',
    description: 'Enter to the Cabinet page (opens in a new tab), where you can manage the list of projects. ' +
      'Administrators can also use the functions of managing user accounts, synchronizing data, ' +
      'and viewing the event log.',
    icon: 'OpenFile',
    hidden: hideCabinetItem,
  },
  {
    title: 'Default Project',
    description: 'Open the Default Project.',
    icon: 'OpenFile',
    hidden: hideDefaultProject,
  },
  {
    title: 'User Guide',
    description: 'Open the User Guide.',
    icon: 'OpenFile',
  },
  {
    title: 'Change Password',
    description: 'Change your password.',
    icon: 'OpenFile',
    hidden: hideChangePasswordItem,
  },
  {
    title: 'Logout',
    description: 'By selecting this routine you will be logged out of the system. Useful if you want to ' +
      'log in as another user.',
    icon: 'OpenFile',
  },
]

const SelectOperationCabinet = ({ onClose, isCabinet }) => {
  const dispatch = useDispatch()

  const projectId = useSelector(activeProjectId)
  const user = useSelector(selectUser)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const docUrl = useSelector(selectDocUrl)

  const hideCabinetItem = useMemo(() => isCabinet || !userAccess[EDIT_PROJECTS], [ isCabinet, userAccess ])

  const hideChangePasswordItem = useMemo(() => !isLocalAdmin(user), [ user ])

  const generalizedRole = useMemo(() => getGeneralizedRole(user?.roles), [ user ])

  // Hide Default project for Analyst
  const hideDefaultProject = useMemo(() => generalizedRole === KEYS.ANALYST, [ generalizedRole ])

  const goToCabinet = useCallback(() => {
    onClose()
    window.open('/cabinet', '_blank')
  }, [ onClose ])

  const doOpenDefaultProject = useCallback(() => {
    onClose()
    window.open('/_/map')
  }, [ onClose ])

  const doDownloadUserManual = useCallback(() => {
    onClose()
    window.open(docUrl, '_blank')
  }, [ onClose, docUrl ])

  const doChangePassword = useCallback(() => {
    onClose()
    dispatch(setChangePasswordOpen(true))
  }, [ dispatch, onClose ])

  const doLogout = useCallback(async () => {
    onClose()
    await dispatch(saveActiveProjectJobs(projectId))
    dispatch(logout())
  }, [ dispatch, projectId, onClose ])

  const actions = useMemo(() => {
    const result = []
    if (!hideCabinetItem) {
      result.push(goToCabinet)
    }
    if (!hideDefaultProject) {
      result.push(doOpenDefaultProject)
    }
    result.push(doDownloadUserManual)
    if (!hideChangePasswordItem) {
      result.push(doChangePassword)
    }
    result.push(doLogout)
    return result
  },
  [
    goToCabinet, doLogout, hideCabinetItem, doDownloadUserManual, doChangePassword, hideChangePasswordItem,
    doOpenDefaultProject, hideDefaultProject,
  ])

  return (
    <SelectOperation
      caption={hideCabinetItem && generalizedRole !== KEYS.ANALYST ? 'Logout' : 'Cabinet'}
      operations={operations(hideCabinetItem, hideChangePasswordItem, hideDefaultProject)}
      actions={actions}
      onClose={onClose}
    >
      <table>
        <tbody>
          <tr>
            <td>User name:&nbsp;&nbsp;&nbsp;</td>
            <th>{user?.name}</th>
          </tr>
          <tr>
            <td>User role:&nbsp;&nbsp;&nbsp;</td>
            <th>{generalizedRole}</th>
          </tr>
          <tr>
            <td>Version:&nbsp;&nbsp;&nbsp;</td>
            <th>
              <a href="/release-notes.html" target="_blank" rel="noreferrer">
                {info.version}.{info.buildNumber}
              </a>
            </th>
          </tr>
        </tbody>
      </table>
    </SelectOperation>
  )
}

export default SelectOperationCabinet
