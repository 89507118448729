import L from 'leaflet'
import {
  BusinessCasesIcon,
  CalculatePainZonesIcon, ColorsIcon, ComplaintsIcon, ComputationZoneIcon, CoordinatesIcon, CoverageIcon,
  CreateSiteIcon, CreateZoneIcon,
  CustomizationIcon, EditCoefficientsIcon, FilteringZoneIcon, FocusZoneIcon, ImportIcon, MapStyleIcon, NetworkIcon,
  PencilIcon, PinIcon, SearchIcon, SectorsIcon, SitesIcon, UserMapIcon, VectorMapIcon,
} from '../../common/icons/names'
import { COMPLAINTS, COVERAGE, EDIT_ELEMENTS, EDIT_POLYGONS, IMPORT_MAPS } from '../../../constants/access'

// Загальні константи (незмінні)
export const ESCAPE_KEY = 27
export const F4_KEY = 115
export const meters = L.CRS.EPSG3857

// Константи тонкого налаштування
export const METERS_FACTOR = 1.6
export const HINT_TIMEOUT = 500
export const MAX_HINTS = 16
export const SEARCH_BOUNCE = 5000

export const MENU_KEY_CREATE_ZONE = 'create-zone'
export const MENU_KEY_CALCULATE_PAIN_ZONES = 'calculate-pain-zones'
export const MENU_KEY_IMPORT_MAP = 'import-map'

export const mapContextMenu = (userAccess, offlineMode, isSharedProject, isRulerShown, coordinates, draftSite) => {
  const result = [
    {
      key: 'create-site',
      text: 'Create Site',
      icon: CreateSiteIcon,
      disabled: offlineMode || isSharedProject,
      hidden: userAccess && !userAccess[EDIT_ELEMENTS],
      items: [
        {
          key: 'create-site-map',
          text: 'Point on Map',
          icon: PinIcon,
        },
        {
          key: 'create-site-manual',
          text: 'Create here',
          icon: PencilIcon,
        },
      ],
    },
    {
      key: 'network',
      text: 'Network',
      icon: NetworkIcon,
      items: [
        {
          key: 'network-sites',
          text: 'Sites',
          icon: SitesIcon,
        },
        {
          key: 'network-sectors',
          text: 'Sectors',
          icon: SectorsIcon,
        },
        {
          key: 'network-coverage',
          text: 'Coverage',
          icon: CoverageIcon,
          hidden: userAccess && !userAccess[COVERAGE],
        },
        {
          key: 'network-complaints',
          text: 'Complaints',
          icon: ComplaintsIcon,
          hidden: userAccess && !userAccess[COMPLAINTS],
        },
      ],
    },
    {}, // Роздільник
    {
      key: MENU_KEY_CREATE_ZONE,
      text: 'Create Zone',
      icon: CreateZoneIcon,
      disabled: isSharedProject,
      hidden: userAccess && !userAccess[EDIT_POLYGONS],
      items: [
        {
          key: 'create-zone-filtering',
          text: 'Filtering Zone',
          icon: FilteringZoneIcon,
        },
        {
          key: 'create-zone-computation',
          text: 'Computation Zone',
          icon: ComputationZoneIcon,
        },
        {
          key: 'create-zone-focus',
          text: 'Focus Zone',
          icon: FocusZoneIcon,
        },
      ],
    },
    {
      key: MENU_KEY_CALCULATE_PAIN_ZONES,
      text: 'Calculate Pain Zones',
      icon: CalculatePainZonesIcon,
      disabled: isSharedProject,
      hidden: userAccess && !userAccess[COMPLAINTS],
    },
    {}, // Роздільник
    {
      key: MENU_KEY_IMPORT_MAP,
      text: 'Import Map',
      icon: ImportIcon,
      disabled: offlineMode || isSharedProject,
      hidden: userAccess && !userAccess[IMPORT_MAPS],
      items: [
        {
          key: 'import-map-vector',
          text: 'Vector Map',
          icon: VectorMapIcon,
        },
        {
          key: 'import-map-raster',
          text: 'Raster Map',
          icon: UserMapIcon,
        },
      ],
    },
    {
      key: 'customization',
      text: 'Customization',
      icon: CustomizationIcon,
      items: [
        {
          key: 'customization-map-style',
          text: 'Map Style',
          icon: MapStyleIcon,
        },
        {
          key: 'customization-colors',
          text: 'Colors',
          icon: ColorsIcon,
        },
      ],
    },
    {
      key: 'search',
      text: 'Search',
      icon: SearchIcon,
    },
    {
      key: isRulerShown ? 'hide-ruler' : 'show-ruler',
      text: isRulerShown ? 'Hide ruler' : 'Show ruler',
      icon: EditCoefficientsIcon,
    },
  ]
  let extraItems = []
  if (coordinates) {
    extraItems = [
      {
        key: 'copy_coordinates',
        text: coordinates,
        icon: CoordinatesIcon,
      },
    ]
  }
  if (draftSite) {
    extraItems = [
      ...extraItems,
      {
        key: 'create_bc',
        text: 'Create BC',
        icon: BusinessCasesIcon,
        draftSite: draftSite,
      },
    ]
  }
  if (extraItems.length > 0) {
    return [
      ...extraItems,
      ...result,
    ]
  }
  return result
}

export const menuItemByKey = (key) => {
  let result = null
  const find = (items) => {
    items.forEach((item) => {
      if (item.key === key) {
        result = item
      } else if (item.items) {
        find(item.items)
      }
    })
  }
  find(mapContextMenu())
  return result
}

export const COVERAGE_DEFAULT_COLOR = '#55555533'
