import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Spinner, SpinnerSize } from '@fluentui/react'

import {
  selectSectorsFull,
  selectSectorFldIdx,
  putToMyProject,
  getSiteNamesByIds,
  requestSitesBySiteNames,
  requestRBSesBySiteNames,
  requestSectorsBySiteNames,
} from '../../features/network/networkSlice'
import {
  SECTOR_BASE, STATUS_REMOVED,
} from '../../constants/network'
import Grid from '../common/EditableGrid'
import { getTechInfo, selectbcSectorsLoading } from '../../features/bc/bcSlice'
import { EVENT_TYPE, saveEventLog } from '../../features/eventLog/eventLogSlice'

export const CalculateConfirmationGrid = ({
  bcInfo, selectedBusinessCases, projectId, totalSectorIds,
}) => {
  const dispatch = useDispatch()

  const fullSectors = useSelector(selectSectorsFull)
  const [ sectorIdFldIdx,,,,,,, techFldIdx, statusFldIdx ] = useSelector(selectSectorFldIdx, shallowEqual)
  const isLoading = useSelector(selectbcSectorsLoading)

  const [ techData, setTechData ] = useState(undefined)

  const doSync = async () => {
    const siteNames = dispatch(getSiteNamesByIds(Object.values(selectedBusinessCases).map(({ siteId }) => siteId)))
    const defaultSites = await dispatch(requestSitesBySiteNames({ siteNames }))
    const defaultRBS = await dispatch(requestRBSesBySiteNames({ siteNames }))
    const defaultSectors = await dispatch(requestSectorsBySiteNames({ siteNames }))
    const projectSites = await dispatch(requestSitesBySiteNames({ projectId, siteNames }))
    const projectRBS = await dispatch(requestRBSesBySiteNames({ projectId, siteNames }))
    const projectSectors = await dispatch(requestSectorsBySiteNames({ projectId, siteNames }))

    if (!defaultSectors.error && defaultSectors?.payload?.data) {
      defaultSectors.payload.data = defaultSectors.payload.data.filter((sector) => {
        return totalSectorIds.has(sector[sectorIdFldIdx])
      })
    }

    await dispatch(
      putToMyProject(defaultSites, projectSites, defaultRBS, projectRBS, defaultSectors, projectSectors))

    dispatch(saveEventLog(
      EVENT_TYPE.bcInfo, `: Load Statistics form for BCs = ${Object.keys(selectedBusinessCases).join(', ')}`))
  }

  useEffect(() => {
    const asyncFunc = async () => {
      await doSync()

      let sectorFound = false
      const tech = Object.keys(SECTOR_BASE).slice(1).reduce((map, item) => {
        map[item] = { sectors: 0 }
        return map
      }, {})
      for (const bc of bcInfo) {
        const { bcId, isCalculated, sectorIds } = bc
        if (isCalculated) {
          const techInfo = await dispatch(getTechInfo(bcId))
          dispatch(saveEventLog(EVENT_TYPE.bcInfo, `: Request sectors info for BC = ${bcId}`))
          for (const t in techInfo) {
            if (tech[t]) {
              tech[t].sectors += techInfo[t]
            } else {
              tech[t] = { sectors: techInfo[t] }
            }
            if (techInfo[t] > 0 && !sectorFound) {
              sectorFound = true
            }
          }
        } else {
          for (const sectorId of sectorIds) {
            const sectorIdx = fullSectors.findIndexById(sectorId)
            const sector = fullSectors.getList()[sectorIdx]
            const sectorTech = sector[techFldIdx]
            const sectorStatus = sector[statusFldIdx]
            if (sectorTech !== 'NONE' && sectorStatus !== STATUS_REMOVED) {
              if (tech[sectorTech]) {
                tech[sectorTech].sectors += 1
              } else {
                tech[sectorTech] = { sectors: 1 }
              }
              if (!sectorFound) {
                sectorFound = true
              }
            }
          }
        }
      }

      const data = sectorFound && tech
        ? Object.keys(tech).reduce((agg, t) => {
          if (tech[t].sectors > 0) {
            agg.push([ t, tech[t].sectors ])
          }
          return agg
        }, [])
        : null

      setTechData(data)
    }
    asyncFunc()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      id: 'table.sites.technology',
      label: 'Technology',
      description: 'Technology name',
      type: 'string',
      width: 200,
    },
    { id: 'table.sectors.amount', label: 'Sectors', description: 'Sectors amount', type: 'int' },
  ]

  return techData === undefined || isLoading
    ? (
      <div className='statistics-grid'>
        <Spinner
          style={{ margin: '40px 0 40px 0' }}
          size={SpinnerSize.large}
        />
      </div>
      )
    : techData
      ? (
        <div className='statistics-grid'>
          <Grid
            data={techData}
            columns={columns}
            stretchH={'all'}
            width={'100%'}
            height={'100%'}
          />
        </div>
        )
      : (
        <div className='statistics-no-data'>
          {'No sectors found in the project'}
        </div>
        )
}

export default CalculateConfirmationGrid
