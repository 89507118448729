import { Label } from '@fluentui/react'
import React, { useMemo } from 'react'
import Checkbox from '../../common/Checkbox'
import ChoiceGroup from '../../common/ChoiceGroup'
import PanelSections from '../PanelSections'

export const searchByOptions = [
  { key: 'cols', text: 'Columns', value: 'cols' },
  { key: 'rows', text: 'Rows', value: 'rows' },
]

const AdditionalSettings = ({
  searchBy, matchCase, entireCell,
  onSearchByChange, onMatchCaseChange, onEntireCellChange,
}) => {
  const items = useMemo(() => [
    {
      id: ' settings',
      title: 'Additional Settings',
      Component: () => (
        <>
          <ChoiceGroup
            defaultSelectedKey={searchBy || 'cols'}
            options={searchByOptions}
            label={'Search by'}
            onChange={onSearchByChange}
          />
          <Label className='preferences-label' disabled>{'Preferences'}</Label>
          <Checkbox
            label="Match case"
            checked={matchCase}
            onChange={onMatchCaseChange}
          />
          <Checkbox
            label="Match entire cell contents"
            checked={entireCell}
            onChange={onEntireCellChange}
          />
        </>
      ),
    },
  ], [
    searchBy, matchCase, entireCell,
    onSearchByChange, onMatchCaseChange, onEntireCellChange,
  ])

  return (
    <PanelSections collapsible={false} items={items} />
  )
}

export default AdditionalSettings
