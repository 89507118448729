import React, { useEffect, useMemo, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Stack } from '@fluentui/react'
import Map from '../components/Map'
import Complaints from '../components/Tables/Complaints'
import MacroIndicators from '../components/Tables/MacroIndicators'
import Sites from '../components/Tables/Sites'
import BaseStations from '../components/Tables/BaseStations'
import Sectors from '../components/Tables/Sectors'
import TRF from '../components/TRF'
import BusinessCases from '../components/BusinessCases'
import MapAttributes from '../components/MapAttributes'
import { selectBuildPainZones } from '../features/geo/geoSlice'
import BuildPainZones from '../components/PainZones/BuildPainZones'
import TaskLogPage from '../components/TaskLog/TaskLogPage'
import Notifications from '../components/notifications'

import './style.css'

const MapPane = ({ shown }) => {
  useLayoutEffect(() => {
    document.getElementById('capex-map-pane').style.display = shown ? 'block' : 'none'
  }, [ shown ])

  const buildPainZones = useSelector(selectBuildPainZones)

  return (
    <Stack.Item grow className="full-height" id="capex-map-pane">
      <Map shown={shown} />
      {buildPainZones && (
        <BuildPainZones/>
      )}
    </Stack.Item>
  )
}

const TAB_COMPONENTS = {
  map: null,
  sites: Sites,
  rbs: BaseStations,
  sectors: Sectors,
  complaints: Complaints,
  'business-cases': BusinessCases,
  'macro-indicators': MacroIndicators,
  trf: TRF,
  'vector-map': MapAttributes,
  'task-log': TaskLogPage,
  notifications: Notifications,
}

const Workspace = () => {
  const { tab } = useParams()

  useEffect(() => {
    if (tab === 'map') {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 50)
    }
  }, [ tab ])

  const Component = useMemo(() => TAB_COMPONENTS[tab], [ tab ])

  return (
    <>
      <MapPane shown={tab === 'map'} />
      {Component && (
        <Component />
      )}
    </>
  )
}

export default Workspace
