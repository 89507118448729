import React, { useCallback } from 'react'
import { Stack, Spinner, SpinnerSize } from '@fluentui/react'
import { IconSvg } from '../common/icons'
import { buildClassList } from '../utils/classes'
import { CloseIcon } from '../common/icons/names'

import './SideNavButton.css'

const BUTTON_BASE_CLASS = 'side-nav-button'
const DIVIDER_BASE_CLASS = 'side-nav-divider'

export const SideNavButton = ({
  iconName, text, expanded, link, newTab, onClick, pressed, lighter, disabled, closeId, onCloseClick,
}) => {
  const handleClickClose = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    onCloseClick && onCloseClick(closeId)
  }, [ closeId, onCloseClick ])

  return (
    <Stack
      horizontal
      onClick={disabled ? null : onClick}
      data-link={link}
      data-newtab={newTab}
      className={buildClassList(BUTTON_BASE_CLASS, { pressed, lighter, disabled })}
      title={expanded ? '' : text}
    >
      <Stack.Item>
        {iconName === 'Spinner'
          ? (
              <Spinner size={SpinnerSize.small} />
            )
          : (
              <IconSvg name={iconName} />
            )
        }
      </Stack.Item>
      {expanded && (
        <>
          <Stack.Item className="side-nav-button-label">
            <label>{text}</label>
          </Stack.Item>
          {onCloseClick && (
            <Stack.Item className="side-nav-button-close" title="Close section" onClick={handleClickClose}>
              <IconSvg name={CloseIcon} />
            </Stack.Item>
          )}
        </>
      )}
    </Stack>
  )
}

export const SideNavDivider = ({ expanded }) => (
  <div className={buildClassList(DIVIDER_BASE_CLASS, { expanded })} />
)
