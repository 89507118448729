import React, { useMemo } from 'react'
import { ChoiceGroup as ChoiceGroupComponent } from '@fluentui/react'

import './ChoiceGroup.css'

const ChoiceGroup = ({ className, ...rest }) => {
  const result = useMemo(() => [ ...(className || '').split(' '), 'capex-choice-group' ].join(' '), [ className ])

  return (
    <ChoiceGroupComponent key={rest.selectedKey} {...rest} className={result} />
  )
}

export default ChoiceGroup
