import L from 'leaflet'
import flatten from '@turf/flatten'
import { getEntityLayers } from '../../list'
import { isMultiPolygon, reverseLngLat, toGeometry, toPolygon, toTurf } from '../../utils'

export const flattenPolygonOnMap = (map, entityId, geometry) => {
  const layers = getEntityLayers(map, entityId)
  if (isMultiPolygon(geometry) && layers.length === 1) {
    const { options } = layers[0]
    const polies = flatten(toPolygon(toTurf(toGeometry(layers[0].getLatLngs())))).features
      .map((feature) => reverseLngLat(feature.geometry.coordinates))
    layers.forEach((layer) => map.removeLayer(layer))
    polies.forEach((poly) => L.polygon(poly, options).addTo(map))
  }
}

export const zoomMapToEntity = (map, entityId) => {
  const layers = getEntityLayers(map, entityId)
  if (layers.length) {
    const bounds = new L.LatLngBounds()
    for (const layer of layers) {
      if (layer.getBounds) {
        bounds.extend(layer.getBounds())
      } else if (layer.getLatLng) {
        bounds.extend(layer.getLatLng())
      }
    }
    map.fitBounds(bounds)
  }
}

export function hexToRgba (hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (!result) {
    result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
    a: result.length > 4 ? parseInt(result[4], 16) : 1,
  }
}
