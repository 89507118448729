import { fetch } from './rest'

export const load = () =>
  fetch('/api/user/_')
    .then((user) => {
      if (!user || !user.settings) {
        return null
      }
      return user.settings
    })

export const save = (settings) =>
  fetch('/api/user/_/settings', 'POST', settings)
