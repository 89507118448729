import L from 'leaflet'
import { numberFormatting } from '../../../utils/format'

const original_syncCircleRadius = L.PM.Draw.Circle.prototype._syncCircleRadius

const original_resizeCircle = L.PM.Edit.Circle.prototype._resizeCircle

const updateCircleRadius = function (e) {
  const radius = this._layer.getRadius()
  if (this._centerMarker) {
    const hint = `Radius: ${numberFormatting(radius.toFixed(0))} m`
    let tooltip = this._centerMarker.getTooltip()
    if (!tooltip) {
      this._centerMarker
        .bindTooltip(hint, {
          permanent: true,
          offset: L.point(0, -10),
          direction: 'top',
          opacity: 0.8,
        })
        .openTooltip()
    } else {
      tooltip.setContent(hint)
    }
  }
}

L.PM.Draw.Circle.prototype._syncCircleRadius = function (e) {
  original_syncCircleRadius.call(this, e)
  updateCircleRadius.call(this, e)
}

L.PM.Edit.Circle.prototype._resizeCircle = function (e) {
  original_resizeCircle.call(this, e)
  updateCircleRadius.call(this, e)
}

