import merge from 'deepmerge'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import {
  DEFAULT_COLORS, DEFAULT_FORMATS, PAIN_ZONE_PARAMS, DEFAULT_SECTORS_ICON, DEFAULT_SIZES,
} from '../../constants/default'
import {
  COMPLAINT_TYPE_P_FIELD, SECTOR_NAME_FIELD, SECTOR_TYPES_UNIQUE, SITE_NAME_FIELD,
} from '../../constants/network'
import { BACKGROUND_NONE, FONT_REGULAR, SPREAD_DEFAULT } from '../../constants/settings'
import { KEYS } from '../../components/Cabinet/constants'
import { selectFilters } from '../filters/filters'
import {
  selectBaseStationFields,
  selectComplaintFields,
  selectSectorFields,
  selectSiteFields,
  setExplorerGroupBy,
} from '../network/networkSlice'
import { DATA_TYPES } from '../../constants/common'
import { selectBCFields } from '../bc/bcSlice'
import { selectMapAttributes } from '../vector/vectorSlice'
import { selectIsMyProject, selectIsDefaultProject, loadProjectSettings } from '../projects/projectsSlice'

export const SETTINGS_KEYS = {
  COL_WIDTHS: 'colWidths',
  COL_SORTING: 'colSorting',
  COL_NOT_DISPLAY: 'colNotDisplay',
  COL_TABLES_MOVE: 'colTablesMove',
  TABLE_FILTERS: 'tableFilters',
  TABLE_DENSITY: 'tableDensity',
}

export const DEFAULT_COLORING = {
  start: {
    color: '#0000ffff',
    value: null,
  },
  end: {
    color: '#ff0000ff',
    value: null,
  },
  step: null,
}

export const DEFAULT_LABELING = {
  size: 11,
  font: FONT_REGULAR,
  background: BACKGROUND_NONE,
}

export const DEFAULT_SETTINGS_VECTOR_MAP = {
  attribute: null,
  spread: SPREAD_DEFAULT,
  coloring: DEFAULT_COLORING,
  labeling: {
    attribute: null,
    ...DEFAULT_LABELING,
  },
}

const initialState = {
  colors: DEFAULT_COLORS,
  userColors: {},
  legend: {
    zones: true,
    sites: true,
    ...SECTOR_TYPES_UNIQUE.reduce((result, type) => ({
      ...result,
      [type]: true,
    }), {}),
    complaints: true,
  },
  formats: DEFAULT_FORMATS,
  sizes: DEFAULT_SIZES,
  sectorsIcon: DEFAULT_SECTORS_ICON,
  colWidths: {},
  colSorting: {},
  colNotDisplay: {},
  colTablesMove: {},
  tableFilters: {},
  [SETTINGS_KEYS.TABLE_DENSITY]: {},
  display: {
    sites: {
      attribute: SITE_NAME_FIELD,
      spread: SPREAD_DEFAULT,
      coloring: DEFAULT_COLORING,
      labeling: {
        attribute: SITE_NAME_FIELD,
        ...DEFAULT_LABELING,
      },
    },
    complaints: {
      attribute: COMPLAINT_TYPE_P_FIELD,
      spread: SPREAD_DEFAULT,
      coloring: DEFAULT_COLORING,
      labeling: {
        attribute: COMPLAINT_TYPE_P_FIELD,
        ...DEFAULT_LABELING,
      },
    },
    ...SECTOR_TYPES_UNIQUE.reduce((result, type) => ({
      ...result,
      [type]: {
        attribute: SECTOR_NAME_FIELD,
        spread: SPREAD_DEFAULT,
        coloring: DEFAULT_COLORING,
        labeling: {
          attribute: SECTOR_NAME_FIELD,
          ...DEFAULT_LABELING,
        },
      },
    }), {}),
    vectorMaps: {},
    // last: DEFAULT_LAST,
  },
  groupBy: {},
  deepFiltration: true,
  complaintsGrouping: true,
  painZones: {
    dateFrom: null,
    dateTo: null,
    resolution: PAIN_ZONE_PARAMS.resolution.default,
    mode: PAIN_ZONE_PARAMS.mode,
    sensitivity: PAIN_ZONE_PARAMS.sensitivity.default,
    weight: PAIN_ZONE_PARAMS.weight.default,
  },
  networkTemplate: null,
  bcCalcApprove: {
    jobId: null,
    selectedGroupCase: null,
    bcIds: null,
  },
  ruler: {
    hideIntermediateMeasures: false,
    textSize: 10,
    labelOpacity: 0.8,
  },
  version: 1,
}

const doLoadSettings = createAsyncThunk(
  'settings/load',
  api.settings.load,
)

const doSaveSettings = createAsyncThunk(
  'settings/save',
  api.settings.save,
)

const doSaveSettingsToProject = createAsyncThunk(
  'settings/saveToProject',
  api.projects.update,
)

export const loadUserSettings = async (dispatch, getState) => {
  const result = await dispatch(doLoadSettings())
  return result?.payload
}

const updateEntireState = (state, updated, exclusive, loaded, overrideArrays) => {
  for (const key of Object.keys(state)) {
    if (updated?.[key] !== undefined) {
      if (typeof state[key] === 'object' && typeof updated[key] === 'object') {
        state[key] = updated[key] === null || state[key] === null
          ? updated[key]
          : merge(
            exclusive ? {} : state[key],
            updated[key],
            overrideArrays ? { arrayMerge: (a, b) => b } : {},
          )
      } else {
        state[key] = updated[key]
      }
    }
  }
  if (loaded) {
    state.version = state.version + 1
  }
}

export const mutatePath = (obj, path, value, i = 0) => {
  if (i >= path.length) {
    return
  }
  const key = path[i]
  if (i + 1 === path.length) {
    obj[key] = value
  } else {
    if (typeof obj[key] !== 'object') {
      obj[key] = {}
    }
    mutatePath(obj[key], path, value, i + 1)
  }
}

const updateByPathState = (state, updated) => {
  const { value, path } = updated
  if (Array.isArray(path)) {
    if (path.length === 0) {
      updateEntireState(state, value, true)
    } else {
      mutatePath(state, path, value)
    }
  }
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateByPathSettings: (state, action) => updateByPathState(state, action.payload),
    updateSettings: (state, action) => {
      const { settings, overrideArrays } = action.payload
      updateEntireState(state, settings, false, false, overrideArrays)
    },
    setSettings: (state, action) => updateEntireState(state, action.payload, true),
    setSettingsWithOverride: (state, action) => updateEntireState(state, action.payload, true, true),
    hardSetSettings: (state, action) => action.payload,
  },
})

const {
  updateByPathSettings,
  updateSettings,
  setSettings,
  setSettingsWithOverride,
  hardSetSettings,
} = settingsSlice.actions

export const selectSettings = (state) => state.settings
export const selectSettingsDisplay = (state) => state.settings.display
export const selectSettingsSizes = (state) => state.settings.sizes
export const selectSettingsSectorsIcon = (state) => state.settings.sectorsIcon
export const selectSettingsDeepFiltration = (state) => state.settings.deepFiltration
export const selectSettingsComplaintsGrouping = (state) => state.settings.complaintsGrouping
export const selectPainZoneParams = (state) => state.settings.painZones
export const selectSettingsVersion = (state) => state.settings.version
export const selectSettingsUserColors = (state) => state.settings.userColors

export const selectCoordinatesFormat = (state) => {
  const { coordinatesEncoding, coordinatesPrecision } = state.settings.formats
  return { coordinatesEncoding, coordinatesPrecision }
}

export const getProjectSettings = (group, subType) => (dispatch, getState) => {
  const state = group ? getState()?.settings?.[group] : getState()?.settings
  return subType ? state?.[subType] : state
}

export const setProjectSettings = async (dispatch, getState) => {
  const state = getState()
  // настройки проекта в общие настройки
  const settings = state.projects.active?.[KEYS.PROPERTIES]
  if (settings) {
    dispatch(setSettings(settings))
    // Групування в Провіднику
    dispatch(setExplorerGroupBy(settings.groupBy))
  }
}

export const loadSettings = (project) => async (dispatch, getState) => {
  if (!project) {
    const settings = await dispatch(loadUserSettings)
    if (settings) {
      dispatch(setSettingsWithOverride(settings))
    }
  } else {
    const state = getState()
    const projectId = state.projects.active?.[KEYS.ID]
    if (projectId) {
      const settings = await dispatch(loadProjectSettings(projectId))
      if (settings) {
        await dispatch(setSettings(settings))
        dispatch(setExplorerGroupBy(settings.groupBy))
        const userSettings = await dispatch(loadUserSettings)
        if (userSettings) {
          dispatch(setSettings({ userColors: { ...userSettings.userColors } }))
        }
      }
    }
  }
}

export const saveSettingToServer = (dispatch, getState) => {
  const state = getState()
  const isMyProject = selectIsMyProject(state)
  const isDefaultProject = selectIsDefaultProject(state)
  if (!isMyProject && !isDefaultProject) {
    // Зміна налаштувань у чужому проєкті ніде не зберігається. Користувач може змінити налаштування, але вони діятимуть
    // лише до завершення поточної сесії. При повторному відкритті проєкту знову будуть застосовані збережені у ньому
    // автором налаштування.
    return
  }
  const projectId = state.projects.active?.[KEYS.ID]
  if (projectId) {
    const project = {
      [KEYS.NAME]: state.projects.active[KEYS.NAME],
      [KEYS.DESCRIPTION]: state.projects.active[KEYS.DESCRIPTION],
      [KEYS.PROPERTIES]: {
        ...state.settings,
        userColors: {},
      },
    }
    return dispatch(doSaveSettingsToProject({ projectId, data: project }))
  }
  return dispatch(doSaveSettings(state.settings))
}

export const saveSettings = (settings, overrideArrays = false, userSettings = false) => async (dispatch, getState) => {
  dispatch(updateSettings({ settings, overrideArrays }))
  const state = getState()
  const isMyProject = selectIsMyProject(state)
  if (isMyProject) {
    if (userSettings) {
      const userState = await dispatch(loadUserSettings)
      if (userState) {
        updateEntireState(userState, settings, false, false, overrideArrays)
        dispatch(doSaveSettings(userState))
      }
      return
    } else {
      const projectId = state.projects.active?.[KEYS.ID]
      const project = {
        [KEYS.NAME]: state.projects.active[KEYS.NAME],
        [KEYS.DESCRIPTION]: state.projects.active[KEYS.DESCRIPTION],
        [KEYS.PROPERTIES]: {
          ...state.settings,
          userColors: {},
        },
      }
      dispatch(doSaveSettingsToProject({ projectId, data: project }))
      return
    }
  }
  dispatch(doSaveSettings(state.settings))
}

export const saveUserSettings = (settings) => (dispatch, getState) => {
  dispatch(saveSettings(settings, false, true))
}

export const restoreDefaults = () => async (dispatch, getState) => {
  await dispatch(hardSetSettings(initialState))
  const state = getState()
  const projectId = state.projects.active?.[KEYS.ID]
  if (projectId) {
    const project = {
      [KEYS.NAME]: state.projects.active[KEYS.NAME],
      [KEYS.DESCRIPTION]: state.projects.active[KEYS.DESCRIPTION],
      [KEYS.PROPERTIES]: initialState,
    }
    return dispatch(doSaveSettingsToProject({ projectId, data: project }))
  } else {
    return dispatch(doSaveSettings(initialState))
  }
}

export const clearAllSettings = () => async (dispatch, getState) => {
  const state = getState()
  const projectId = state.projects.active?.[KEYS.ID]
  if (projectId) {
    const project = {
      [KEYS.NAME]: state.projects.active[KEYS.NAME],
      [KEYS.DESCRIPTION]: state.projects.active[KEYS.DESCRIPTION],
      [KEYS.PROPERTIES]: {},
    }
    await dispatch(doSaveSettingsToProject({ projectId, data: project }))
  }
  await dispatch(doSaveSettings({}))
  localStorage.clear()
  window.location.reload()
}

export const settingPropertyOnPath = (value, path) => (dispatch) => {
  dispatch(updateByPathSettings({ value, path }))
}

// функции обработки фильтра таблиц для сохранения/загрузки
export const getFiltersFromSettings = (table, dataType) => (dispatch, getState) => {
  const state = getState()
  const fields = table.fields
  const filters = selectSettings(state)?.tableFilters?.[dataType]
  if (!filters || !fields) {
    return {}
  }
  return {
    comparisonRules: setColumnIndexById(filters.comparisonRules, fields) ?? [],
    inversion: filters.inversion ?? false,
  }
}

const setColumnIndexById = (filters, fields) => {
  if (Array.isArray(filters)) {
    return filters.map((filter) => setColumnIndexById(filter, fields)).filter(Boolean)
  }
  if (typeof filters === 'object') {
    const ind = fields.findIndex((field) => (field.id === filters.id))
    return ind < 0
      ? undefined
      : {
          columnIndex: ind,
          comparisonOperator: filters.o,
          value: filters.v,
          type: fields[ind].type,
        }
  }
}

const setIdByColumnIndex = (filters, fields) => {
  if (Array.isArray(filters)) {
    return filters.map((filter) => setIdByColumnIndex(filter, fields)).filter(Boolean)
  }
  if (typeof filters === 'object' && fields?.[filters.columnIndex]?.id) {
    return {
      id: fields[filters.columnIndex].id,
      o: filters.comparisonOperator,
      v: filters.value,
    }
  }
}

// сохранение фильтра в settings
export const controlFilterSettings = (dataType) => (dispatch, getState) => {
  if (dataType) {
    const state = getState()
    const filter = selectFilters(dataType)(state)
    let fields = {}
    switch (dataType) {
      case DATA_TYPES.SITES: fields = selectSiteFields(state)
        break
      case DATA_TYPES.SECTORS: fields = selectSectorFields(state)
        break
      case DATA_TYPES.COMPLAINTS: fields = selectComplaintFields(state)
        break
      case DATA_TYPES.BASE_STATIONS: fields = selectBaseStationFields(state)
        break
      case DATA_TYPES.BUSINESS_CASES: fields = selectBCFields(state)
        break
      default:
        if (dataType.startsWith(DATA_TYPES.MAP_ATTRIBUTES)) {
          const attributes = selectMapAttributes(dataType)(state)
          if (attributes) {
            fields = attributes.fields
            break
          }
        }
        return
    }
    const filters = {
      comparisonRules: setIdByColumnIndex(filter.comparisonRules, fields),
      inversion: filter.inversion,
    }
    dispatch(updateByPathSettings({ value: filters, path: [ SETTINGS_KEYS.TABLE_FILTERS, dataType ] }))
    dispatch(saveSettingToServer)
  }
}

export default settingsSlice.reducer
