export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm:ss'

export const compareFunctionBoolean = (sortOrder) => {
  return (value, nextValue) => {
    if (sortOrder === 'asc') {
      return !value && nextValue === true ? -1 : value === true && !nextValue ? 1 : 0
    }
    if (sortOrder === 'desc') {
      return !value && nextValue === true ? 1 : value === true && !nextValue ? -1 : 0
    }
    return 0
  }
}

export const initColumnsGrid = (columns, subTypes = {}) => columns.map((column) => {
  const columnSorting = column.type === 'boolean' ? { compareFunctionFactory: compareFunctionBoolean } : undefined
  const datePickerConfig = column.type === 'date'
    ? {
        /* datePickerConfig: {
          licenseKey: 'non-commercial-and-evaluation',
          reposition: true,
          position: 'top left',
          format: 'YYYY-MM-DD',
          numberOfMonths: 1,
          theme: 'dark-time',
          showMonthAfterYear: true,
        }, */
        dateFormat: DATE_FORMAT,
        correctFormat: true,
      }
    : {}

  return {
    ...column,
    subType: subTypes[column.id],
    ...datePickerConfig,
    columnSorting,
  }
})

const dateTime = {
  type: 'date',
  subType: 'dateTime',
  dateFormat: DATE_TIME_FORMAT,
}

export const initColumnBC = (fields) => fields.map((field) => {
  let added = {}
  if (field.type === 'date') {
    added.dateFormat = DATE_FORMAT
  } else if (field.type === 'datetime') {
    added = dateTime
  }
  return { ...field, ...added }
})

export const notNull = (value) => value !== null && value !== undefined
