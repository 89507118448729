import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb } from '@fluentui/react'
import { selectEditPath, setBundlePath } from '../../features/network/networkSlice'
import { empty } from '../../utils/format'

const typeDescription = (type) => {
  switch (type) {
    case 'site': {
      return 'Site'
    }
    case 'baseStation': {
      return 'RBS'
    }
    case 'sector': {
      return 'Sector'
    }
    case 'complaint': {
      return 'Complaint'
    }
    default: {
      return ''
    }
  }
}

const NetworkItemBundleBreadcrumbs = ({ bundle, currentId, currentName }) => {
  const dispatch = useDispatch()

  const path = useSelector(selectEditPath)

  const breadcrumbClick = useCallback((_, { path }) => {
    dispatch(setBundlePath(path))
  }, [ dispatch ])

  const breadcrumbs = useMemo(() => {
    if (!bundle) {
      return []
    }
    let current = bundle
    let step = 0
    const item = () => {
      const name = current.id === currentId ? currentName : current.name
      const item = {
        key: current.type,
        text: `${typeDescription(current.type)} ${name}`,
        path: path.slice(0, step),
      }
      if (step < path.length) {
        item.onClick = breadcrumbClick
        step++
      }
      return item
    }
    if (bundle) {
      return path.reduce((acc, index) => {
        current = current.children[index]
        return [ ...acc, item() ]
      }, [ item() ])
    }
  }, [ bundle, currentId, currentName, path, breadcrumbClick ])

  return (
    <div className='network-item-bundle-breadcrumb'>
      <Breadcrumb
        items={breadcrumbs}
        onReduceData={empty}
      />
    </div>
  )
}

export default NetworkItemBundleBreadcrumbs
