import React from 'react'
import { useBoolean } from '@fluentui/react-hooks'
import { IconButton } from './Button'
import { GreaterIcon, LessIcon } from './icons/names'

import './GroupFilterControl.css'

const GroupFilterControl = ({ columns, groupFilter, onClose }) => {
  const [ expanded, { toggle: toggleExpanded } ] = useBoolean(true)

  return (
    <div className="group-filter-control">
      <IconButton
        title="Drop this Filter"
        onClick={onClose}
      />
      {expanded && (
        <div className="group-filter-control-label">
          {`Filter [${columns.find(({ id }) => id === groupFilter.columnId)?.label}=${groupFilter.value}]`}
        </div>
      )}
      <IconButton
        title={expanded ? 'Fold it' : 'Unfold'}
        icon={expanded ? GreaterIcon : LessIcon}
        onClick={toggleExpanded}
      />
    </div>
  )
}

export default GroupFilterControl
