import React from 'react'
import { Stack } from '@fluentui/react'
import Dropdown from '../../../common/Dropdown'
import Elements from '../Elements'
import Attributes from '../Attributes'
import { SPREAD_DEFAULT, SPREAD_DISCRETE, SPREAD_RANGE, SPREAD_UNIQUE } from '../../../../constants/settings'
import { adjustableSpreads } from '../constants'

const displayingOptions = [
  { key: SPREAD_DEFAULT, text: 'Default' },
  { key: SPREAD_UNIQUE, text: 'Unique' },
  { key: SPREAD_RANGE, text: 'Range' },
  { key: SPREAD_DISCRETE, text: 'Discrete' },
]

const ElementsMode = ({ element, mode, attribute, onChangeElement, onChangeMode, onChangeAttribute }) => (
  <Stack>
    <Elements
      selected={element}
      onChange={onChangeElement}
    />
    {element && (
      <>
        <Dropdown
          label="Displaying"
          options={displayingOptions}
          selectedKey={mode}
          onChange={onChangeMode}
        />
        {mode && adjustableSpreads.includes(mode) && (
          <Attributes
            element={element}
            mode={mode}
            selectedKey={attribute}
            onChange={onChangeAttribute}
          />
        )}
      </>
    )}
  </Stack>
)

export default ElementsMode
