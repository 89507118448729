import { ChoiceGroup, Icon, Stack } from '@fluentui/react'
import React, { useCallback } from 'react'
import { NOT_SELECT } from '../constants'
import { COLUMNS_UPDATE } from '../../../constants/updateDB'
import { stylesChoiceGroup, stylesRadioButton } from './styles'

const optionHide = {
  text: '',
  onRenderField: () => null,
}

const optionsAll = [
  { key: COLUMNS_UPDATE.ORIGINAL_VALUE, styles: stylesRadioButton },
  { key: COLUMNS_UPDATE.NEW_VALUE, styles: stylesRadioButton },
  { key: COLUMNS_UPDATE.DB_VALUE, styles: stylesRadioButton },
  { key: NOT_SELECT, styles: stylesRadioButton },
]

const options = optionsAll.reduce((prevObj, option) => {
  const columnName = option.key
  return {
    ...prevObj,
    [columnName]: optionsAll.map((item) => (
      item.key === columnName
        ? item
        : { ...item, ...optionHide }
    )),
  }
}, {})

const TD = ({ item, column, onUpdateItem }) => {
  const doNotDrawCheckbox = item[column?.fieldName] === null || (
    column?.fieldName === COLUMNS_UPDATE.ORIGINAL_VALUE &&
    item[COLUMNS_UPDATE.ORIGINAL_VALUE] === item[COLUMNS_UPDATE.NEW_VALUE] &&
    item[COLUMNS_UPDATE.DB_VALUE] === null
  )
  const isChecked = column?.data?.isChecked
  const icon = column?.isIcon
    ? item.type === 'sites'
      ? 'InternetSharing'
      : item.type === 'sectors'
        ? 'Glimmer'
        : item.type === 'rbses' ? 'WifiEthernet' : undefined // PieDouble
    : undefined

  const columnName = column?.fieldName
  let value = item?.[columnName]
  if (columnName === 'modifyDate' && value) {
    const date = new Date(value)
    value = date.toLocaleString()
  }
  const onClickHandler = useCallback((event) => {
    if (event?.target?.checked) {
      const newItem = { ...item, select: item.select === columnName ? NOT_SELECT : columnName }
      onUpdateItem && onUpdateItem(newItem)
    }
  }, [ item, onUpdateItem, columnName ])

  return (
    <Stack
      horizontal
      verticalAlign={'center'}
    >
      {isChecked != null && !doNotDrawCheckbox
        ? (
            <ChoiceGroup
              styles={stylesChoiceGroup}
              selectedKey={item?.select}
              options={options[columnName]}
              onClick={onClickHandler}
            />
          )
        : null
      }
      {icon && (
        <Icon iconName={icon} style={{ marginRight: '8px' }}/>
      )}
      {typeof value === 'string' && value.length > 20
        ? <span title={value}>{`${value.slice(0, 20)}...`}</span>
        : <span>{value}</span>
      }
    </Stack>
  )
}

export default TD
