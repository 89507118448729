import React from 'react'
import { Icon, Spinner, Label } from '@fluentui/react'
import { useSelector } from 'react-redux'
import { selectStages } from '../../features/loading/loadingSlice'

import './styles.css'
import { STAGE_KEYS } from '../../features/loading/keys'

const busyState = [
  STAGE_KEYS.LOADING,
  STAGE_KEYS.INDEXING,
  STAGE_KEYS.FILTERING,
]

const Stage = ({ stage }) => {
  const { current: type, name, payload } = stage ?? {}

  const messageStage = type === STAGE_KEYS.LOADING
    ? `Loading ${name}`
    : type === STAGE_KEYS.LOADED
      ? `Loaded ${name}`
      : type === STAGE_KEYS.FILTERING
        ? `${name} filtering`
        : type === STAGE_KEYS.FILTERED
          ? `${name} filtered`
          : type === STAGE_KEYS.INDEXING
            ? `${name} indexing`
            : type === STAGE_KEYS.INDEXED
              ? `${name} indexed`
              : type === STAGE_KEYS.COMPLETE
                ? `${name} processed`
                : type === STAGE_KEYS.ABORTED
                  ? `${name} process aborted`
                  : ''

  return (
    <div style={{ display: 'flex' }}>
      {busyState.includes(type)
        ? <Spinner />
        : type === STAGE_KEYS.ABORTED
          ? (
              <Icon iconName="Error" style={{ color: 'red ' }} />
            )
          : (
              <Icon iconName="CheckMark" style={{ color: 'green', alignSelf: 'center' }} />
            )
      }
      <Label style={{ marginLeft: 8 }}>{`${messageStage}${payload != null ? ` (${payload})` : ''}`}</Label>
    </div>
  )
}

const InitLog = ({ modal }) => {
  const stages = useSelector(selectStages)

  return stages
    ? (
        <div className={modal ? '' : 'init-log'}>
          {stages.map((stage) => <Stage key={stage.id} stage={stage} />)}
        </div>
      )
    : null
}

export default InitLog
