import React from 'react'
import Replace from './Replace'

export const ReplaceContainer = () => (
  <div
    id='replace-content-container'
    style={{
      flex: 1,
      display: 'flex',
    }}
  />
)

export default Replace
