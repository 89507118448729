import moment from 'moment'
import {
  DEFAULT_COLS, DEFAULT_ROWS, FILTER_MAX_SIZE, isTrue, typeBoolean, typeNumber, typeText, typeDate,
} from '../../components/Filter/constant'

const operation = (cell) => (operation) => {
  const { comparisonOperator, value } = operation
  switch (comparisonOperator) {
    case '=': {
      return value === cell
    }
    case '<>': {
      return value !== cell
    }
    case '>': {
      return cell > value
    }
    case '<': {
      return cell < value
    }
    case '>=': {
      return cell >= value
    }
    case '<=': {
      return cell <= value
    }
    default:
  }
  return true
}

const conjunctionFilter = (oldFilter, addFilterI) => {
  const newFilter = []
  const addFilter = addFilterI.slice()
  oldFilter.forEach((oldCondition) => {
    const {
      columnIndex,
      comparisonOperator,
      value,
    } = oldCondition
    const index = addFilter.findIndex((addCondition) => addCondition.columnIndex === columnIndex)
    if ((index + 1) && comparisonOperator === addFilter[index]?.comparisonOperator) {
      if (value === addFilter[index].value) {
        // В колонці збіглися значення, залишаємо старе та видаляємо нове правило
        newFilter.push(oldCondition)
        addFilter.splice(index, 1)
        return
      }
      // В колонці не збіглися значення
      // TODO: Потрібно перевірити знаки порівняння і скоротити
      // Поки що: не додаємо старе і видаляємо нове правило
      newFilter.push(oldCondition)
      // addFilter.splice(index, 1)
      return
    }
    newFilter.push(oldCondition)
  })
  return newFilter.concat(addFilter)
}

// объединение фильтров
export const combineFilters = (data, addFilters, reset) => {
  if (!Array.isArray(addFilters?.comparisonRules) || addFilters.comparisonRules.length === 0) {
    if (reset) {
      data.filters = {}
    }
    return
  }
  const oldFilters = data.filters
  if (
    reset ||
    !Array.isArray(oldFilters?.comparisonRules) ||
    oldFilters.comparisonRules.length === 0
  ) {
    data.filters = addFilters
    return
  }
  const newFilters = []
  oldFilters.comparisonRules.forEach((oldFilter) => addFilters.comparisonRules.forEach((addFilter) => {
    const newFilter = conjunctionFilter(oldFilter, addFilter)
    if (newFilter.length) {
      newFilters.push(newFilter)
      if (newFilters.length > FILTER_MAX_SIZE) {
        throw new Error('When adding the filter size exceeds the allowable')
      }
    }
  }))
  data.filters = { inversion: false, comparisonRules: newFilters }
}

const regDelStar = /^\*+|\*+$/gm
const regStarToOne = /\*+/gm
const regSpecialCharacter = /[.+?^${}()|[\]\\]/g

const compareString = (_str, filter) => {
  const str = `${_str}`
  const strFilter = `${filter ?? ''}`.replace(regStarToOne, '*')
  if (strFilter === '*') { // Залишаємо непорожні
    return _str !== '' && _str != null
  }
  const clearFilter = strFilter
    .replace(regDelStar, '')
    .replace(regSpecialCharacter, '\\$&') // Екранування спецсимволів
    .replace('*', '.*?')
  const regFilter = `${strFilter[0] !== '*' ? '^' : ''}${clearFilter}${strFilter[strFilter.length - 1] !== '*' ? '$' : ''}`
  const regSearch = new RegExp(regFilter, 'gmi')
  return str?.search && (str.search(regSearch) !== -1)
}

const compareNumber = (number, filter) => {
  if (`${filter}` === '*') { // Перевірка на порожні комірки
    return number != null && number !== ''
  }
  return Number(filter) === Number(number)
}

// Перевірка рядка таблиці
const compareRow = (row, filter) => {
  for (let index = 0; index < filter.length; index++) {
    const {
      columnIndex,
      comparisonOperator,
      value,
      type,
    } = filter[index]
    switch (comparisonOperator) {
      case '=': {
        if (typeText.includes(type) || typeDate.includes(type)) {
          if (!compareString(row[columnIndex], value)) { return false }
        } else if (typeNumber.includes(type)) {
          if (!compareNumber(row[columnIndex], value)) {
            return false
          }
        } else if (typeBoolean.includes(type)) {
          if (isTrue(value) !== isTrue(row[columnIndex])) {
            return false
          }
        } else if (value !== row[columnIndex]) {
          return false
        }
        break
      }
      case '<>': {
        if (typeText.includes(type) || typeDate.includes(type)) {
          if (compareString(row[columnIndex], value)) { return false }
        } else if (typeNumber.includes(type)) {
          if (compareNumber(row[columnIndex], value)) {
            return false
          }
        } else if (typeBoolean.includes(type)) {
          if (isTrue(value) === isTrue(row[columnIndex])) {
            return false
          }
        } else if (value === row[columnIndex]) {
          return false
        }
        break
      }
      case '<': {
        if (typeDate.includes(type)) {
          if (row[columnIndex] && moment(row[columnIndex]).isSameOrAfter(moment(value))) { return false }
        } else if (row[columnIndex] >= value) { return false }
        break
      }
      case '>': {
        if (type === 'date') {
          if (!row[columnIndex] || moment(row[columnIndex]).isSameOrBefore(moment(value))) { return false }
        } else if (type === 'datetime') {
          const momentValue = moment(value)
          const hasTime = (momentValue - moment(momentValue).startOf('day')) > 0
          if (hasTime) {
            if (!row[columnIndex] || moment(row[columnIndex]).isSameOrBefore(momentValue)) { return false }
          } else {
            if (!row[columnIndex] || moment(row[columnIndex]).isSameOrBefore(momentValue.endOf('day'))) {
              return false
            }
          }
        } else if (row[columnIndex] <= value) { return false }
        break
      }
      case '<=': {
        if (type === 'date') {
          if (row[columnIndex] && moment(row[columnIndex]).isAfter(moment(value))) { return false }
        } else if (type === 'datetime') {
          const momentValue = moment(value)
          const hasTime = (momentValue - moment(momentValue).startOf('day')) > 0
          if (hasTime) {
            if (row[columnIndex] && moment(row[columnIndex]).isAfter(momentValue)) { return false }
          } else {
            if (row[columnIndex] && moment(row[columnIndex]).isAfter(momentValue.endOf('day'))) { return false }
          }
        } else if (row[columnIndex] > value) { return false }
        break
      }
      case '>=': {
        if (typeDate.includes(type)) {
          if (!row[columnIndex] || moment(row[columnIndex]).isBefore(moment(value))) { return false }
        } else if (row[columnIndex] < value) { return false }
        break
      }
      default:
    }
  }
  return true
}

// Застосування фільтра до таблиці
export const cropByTableFilters = (stateTable, list) => {
  const { comparisonRules } = stateTable.filters
  if (!Array.isArray(comparisonRules) || comparisonRules.length === 0) {
    return list
  }
  // Застосування усіх установлених фільтрів
  return list.filter((row) => {
    // Застосування фільтрів до рядка таблиці
    // Застосування правил за OR
    return comparisonRules.some((filter) => compareRow(row, filter))
  })
}

export const cropByTableFilters2 = (stateTable, list) => {
  const filters = stateTable.filters

  if (!Array.isArray(filters) || filters.length === 0) {
    return list
  }
  // Застосування усіх установлених фільтрів
  return filters.reduce((list, filter) => {
    const { inversion, comparisonRules } = filter
    return list.filter((row) => {
      let isOk = true
      // Перебирання фільтрів за AND
      comparisonRules?.forEach && comparisonRules.forEach(({ condition, columnIndex }) => {
        // Масив значень фільтра накладаємо на комірку за OR
        const cell = row[columnIndex]
        const comparisonResult = condition?.some && condition.some(operation(cell))
        isOk = isOk && comparisonResult
      })
      return inversion ? !isOk : isOk
    })
  }, list)
}

export const filterAssembly = (hotTable, compareOperation, columnsSettings) => {
  const debrisFilter = new Map()
  let maxSizeRow = 0
  const table = hotTable?.current?.hotInstance
  if (!table) { return [] }
  const hiddenColumns = table.getPlugin('hiddenColumns').getHiddenColumns()
  const selectedCells = table.getSelected() ?? []
  selectedCells.forEach((select) => {
    if (Array.isArray(select) && select.length === 4) {
      const cols = select[1] <= select[3]
        ? {
            begin: select[1],
            end: select[3],
          }
        : {
            begin: select[3],
            end: select[1],
          }
      const rows = select[0] <= select[2]
        ? {
            begin: select[0],
            end: select[2],
          }
        : {
            begin: select[2],
            end: select[0],
          }
      for (let rowIndex = rows.begin; rowIndex <= rows.end; rowIndex++) {
        const rowPhysical = table.toPhysicalRow(rowIndex)
        const andOperation = debrisFilter.has(rowPhysical) ? debrisFilter.get(rowPhysical) : []
        for (let columnIndex = cols.begin; columnIndex <= cols.end; columnIndex++) {
          if (hiddenColumns.includes(columnIndex) || !(columnIndex >= 0)) {
            continue
          }
          const columnPhysical = table.toPhysicalColumn(columnIndex)
          const value = table.getDataAtCell(rowIndex, columnIndex)
          const type = getTypeByIndex(columnsSettings, columnPhysical)
          const valueFilter = `${value ?? ''}`
          let newValue
          if (valueFilter === '') {
            newValue = {
              columnIndex: columnPhysical,
              type,
              comparisonOperator: '<>',
              value: '*',
            }
          } else {
            newValue = {
              columnIndex: columnPhysical,
              type,
              comparisonOperator: '=',
              value: valueFilter,
            }
          }
          if (!andOperation.some((item) => {
            return item.columnIndex === newValue.columnIndex &&
              item.type === newValue.type &&
              item.comparisonOperator === newValue.comparisonOperator &&
              item.value === newValue.value
          })) {
            andOperation.push(newValue)
          }
        }
        debrisFilter.set(rowPhysical, andOperation)
        maxSizeRow = Math.max(maxSizeRow, andOperation?.length)
      }
    }
  })
  if (debrisFilter.size > FILTER_MAX_SIZE ||
    (compareOperation === 'not_equal' && Math.pow(maxSizeRow, debrisFilter.size) > FILTER_MAX_SIZE)
  ) {
    return { error: 'Filter volume exceeds allowable size' }
  }
  return compareOperation === 'not_equal' ? convertToEqual(debrisFilter) : Array.from(debrisFilter.values())
}

const convertToEqual = (debrisFilter) => {
  const filters = Array.from(debrisFilter.values())
  let rez = [ [] ]
  for (let i = 0; i < filters.length; i++) {
    rez = and(rez, filters[i])
    if (rez.length > FILTER_MAX_SIZE) {
      return { error: 'Filter volume exceeds allowable size' }
    }
  }
  inversionOperands(rez)
  return rez
}

const and = (result, operand) => {
  const newResult = []
  for (let i = 0; i < result.length; i++) {
    for (let k = 0; k < operand.length; k++) {
      newResult.push([ ...result[i], { ...operand[k] } ])
    }
  }
  return newResult
}

const inversionOperands = (rules) => {
  rules.forEach((rule) => {
    rule.forEach((operand, index, ref) => {
      ref[index] = { ...operand, comparisonOperator: inversionOperation(operand.comparisonOperator) }
    })
  })
}

const inversionOperation = (operator) => {
  switch (operator) {
    case '=': {
      return '<>'
    }
    case '<>': {
      return '='
    }
    case '<': {
      return '>='
    }
    case '>': {
      return '<='
    }
    case '<=': {
      return '>'
    }
    case '>=': {
      return '<'
    }
    default: {
      return operator
    }
  }
}

const isCleanRow = (row) => {
  return Array.isArray(row) && !(row.findIndex((cell) => {
    return !(cell?.value == null || cell?.value === '')
  }) + 1)
}

const isCleanColumn = (table, column) => !(table.findIndex((row) => {
  const cell = row[column]
  return !(cell?.value == null || cell.value === '')
}) + 1)

export const normalizeDataFilters = (dataFilter) => {
  const maxColumn = Math.max(
    dataFilter.reduce((maxColumn, row) => (row.length > maxColumn ? row.length : maxColumn), 0),
    DEFAULT_COLS,
  )
  for (let i = dataFilter.length; i < DEFAULT_ROWS; i++) {
    dataFilter.push([])
  }
  // Ініціалізація порожніх комірок
  dataFilter.forEach((row) => {
    // + Вирівнювання рядків
    for (let i = 0; i < maxColumn; i++) {
      if (row[i] == null) {
        row[i] = { comparisonOperator: '=' }
      } else {
        const { comparisonOperator = '=', value } = row[i]
        row[i] = { comparisonOperator, value }
      }
    }
  })
  return { dataFilter, numberOfColumns: maxColumn }
}

export const normalizeHeader = (header, numberOfColumns) => {
  const newHeader = []
  for (let i = 0; i < numberOfColumns; i++) {
    newHeader.push((header[i] == null) ? {} : header[i])
  }
  return newHeader
}

// Прибираємо останні порожні рядки та стовпчики, додаємо рядок або стовпчик, якщо немає порожніх
export const setSizeTable = (dataFilter, header, setHeader) => {
  if (!Array.isArray(dataFilter) || !Array.isArray(header)) {
    return dataFilter
  }
  let newDataFilter
  let endRow = dataFilter.length - 1
  // Перевірка останнього рядка на порожність
  while (endRow > DEFAULT_ROWS - 1) {
    const penultimateRow = dataFilter?.[endRow]
    if (isCleanRow(penultimateRow)) {
      // Останній порожній, видаляємо його
      endRow = endRow - 1
    } else { break }
  }
  if (endRow === dataFilter.length - 1) {
    // Останній не порожній, додаємо рядок
    const newRow = header.map(() => ({ comparisonOperator: '=', value: null }))
    newDataFilter = [ ...dataFilter, newRow ]
  } else if (endRow < dataFilter.length - 2) {
    // Більше одного порожнього рядка
    newDataFilter = dataFilter.slice(0, endRow + 2)
  } else { newDataFilter = dataFilter.slice(0) }

  let colEnd = header.length - 1
  // Перевіряємо стовпчики з кінця, якщо є запас за кількістю
  while (colEnd > DEFAULT_COLS - 1) {
    if (isCleanColumn(newDataFilter, colEnd)) {
      colEnd = colEnd - 1
    } else { break }
  }

  if (colEnd === header.length - 2 && dataFilter.length === newDataFilter.length) {
    // Кількість стовпчиків та рядків не змінилась
    return dataFilter
  }

  if (colEnd === header.length - 1) {
    // Останній не порожній, додаємо стовпчик
    newDataFilter.forEach((row) => {
      row[header.length] = { comparisonOperator: '=', value: null }
    })
    setHeader([ ...header, {} ])
  } else if (colEnd < header.length - 2) {
    // Видаляємо порожні стовпчики
    setHeader(header.slice(0, colEnd + 2))
    newDataFilter.forEach((row) => {
      row.length = colEnd + 2
    })
  }
  return newDataFilter
}

export const getColumnByIndex = (columnsSettings, columnIndex) => {
  return columnsSettings?.[columnIndex] ?? {}
}

export const getTypeById = (columnsSettings, columnId) => {
  return columnsSettings.find((column) => (column.id === columnId))?.type
}

export const getTypeByIndex = (columnsSettings, columnIndex) => {
  return columnsSettings?.[columnIndex]?.type
}

export const getIndexById = (columnsSettings, columnId) => {
  return columnsSettings.findIndex((column) => (column.id === columnId))
}
