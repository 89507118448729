import React, { memo } from 'react'
import { TD, THClear, THClipboard, THFilter } from './THFilter'
import { DEFAULT_COLS } from './constant'

export const TableHeader = memo(
  ({ onCopy, onClearTable, colSpan, header, onChangeTHFilter, optionsHeader, onClearColumn }) => {
    return (
      <thead>
      <tr>
        <THClipboard
          className={'locked0'}
          onClick={onCopy}
        />
        <THClear
          className={'locked0'}
          onClick={onClearTable}
        />
        <th
          className={'Operation_h locked0'}
          colSpan={colSpan ?? DEFAULT_COLS}
        >
          AND
        </th>
      </tr>
      <tr className={''}>
        <th className={'Fields locked1'} colSpan={2}>
          Fields
        </th>
        {header.map((col, index) => (
          <THFilter
            className={'locked1'}
            key={index}
            col={col}
            index={index}
            onChange={onChangeTHFilter}
            optionsHeader={optionsHeader}
            onClearClick={onClearColumn}
          />
        ))}
      </tr>
      </thead>
    )
  },
)

TableHeader.displayName = 'fTableHeader'

const TR = memo(({ indexRow, row, header, ...props }) => {
  return (
    <tr>
      <th className={'Operation_h'}>{indexRow ? <div className={'topUp'}>OR</div> : ''}</th>
      <th className={'number_row'}>{indexRow + 1}</th>
      {row.map((item, indexCol) => {
        return (
          <TD
            key={`${indexCol}`}
            indexRow={indexRow}
            indexCol={indexCol}
            type={header[indexCol]?.type}
            item={item}
            {...props}
          />
        )
      })}
    </tr>
  )
})

TR.displayName = 'fTR'

export const TableBody = memo(({ dataFilter, header, onPaste, changeFilter, tableSizeCheck, onNextCell }) => {
  return (
    <tbody>
    {dataFilter.map((row, indexRow) => {
      return (
        <TR
          key={`tr${indexRow}`}
          header={header}
          indexRow={indexRow}
          row={row}
          onPaste={onPaste}
          onChange={changeFilter}
          onBlur={tableSizeCheck}
          onNextCell={onNextCell}
        />
      )
    })}
    </tbody>
  )
})

TableBody.displayName = 'fTableBody'
