import React, { useCallback, forwardRef } from 'react'
import { Stack } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { IconSvg } from '../common/icons'
import { buildClassList } from '../utils/classes'

import './TopNavButton.css'

const BUTTON_BASE_CLASS = 'top-nav-button'

const TopNavButton = forwardRef(({
  id, iconName, text, expanded, onClick, disabled, pressed, className, rest,
}, ref) => {
  const [ pressing, { setTrue: setPressedTrue, setFalse: setPressedFalse } ] = useBoolean(false)

  const pointerDown = useCallback((e) => {
    e.target.setPointerCapture(e.pointerId)
    setPressedTrue()
  }, [ setPressedTrue ])

  const pointerUp = useCallback((e) => {
    e.target.releasePointerCapture(e.pointerId)
    setPressedFalse()
  }, [ setPressedFalse ])

  const clickButton = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    onClick(id)
  }, [ id, onClick ])

  return (
    <div
      ref={ref}
      onClick={disabled || pressed ? null : clickButton}
      onPointerDown={pointerDown}
      onPointerUp={pointerUp}
      className={buildClassList(BUTTON_BASE_CLASS, { pressed: pressed || pressing, disabled }, className)}
      title={expanded ? '' : text}
      {...rest}
    >
      <Stack.Item>
        <IconSvg name={iconName} />
      </Stack.Item>
      {expanded && (
        <Stack.Item>
          <label>{text}</label>
        </Stack.Item>
      )}
    </div>
  )
})

TopNavButton.displayName = 'TopNavButton'

export default TopNavButton
