import { fetch } from './rest'

export const allBusinessCases = () => fetch('/api/report/bc')

export const financialIndicators = (bcgId) => fetch(bcgId ? `/api/report/financial?bcgId=${bcgId}` : '/api/report/financial')

export const launchedSitesAndSectors = () => fetch('/api/report/launched')

export const asyncReportRequest = ({ type, properties }) => fetch('/api/job', 'POST', { type, properties })

export const asyncReportResult = (id) => fetch(`/api/jobs/${id}`)

export const bcCalculationDetails = (id) => fetch(`/api/events/${id}/content`, 'GET', undefined, 'blob')

// export const neighbors = (siteNames) => fetch('/api/report/neighbors', 'POST', { siteNames })

// export const neighborsReport = (reportId) => fetch(`/api/report/neighbors/${reportId}`)
