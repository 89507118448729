import NumberFormat from 'react-number-format'
import React from 'react'
import { useHandler } from '../../../hooks'

import TextField from '../TextField'

const InputNumberFormat = ({ value, onChange, max, min, label, decimalScale = 6, fullWidth, onEnter, ...rest }) => {
  const onChangeHandler = useHandler(({ value }) => {
    onChange && onChange(value)
  })

  const isAllowed = useHandler(({ floatValue }) => {
    return !((min && floatValue < min) || (max && floatValue > max))
  })

  const onKeyDown = useHandler((event) => {
    if (event.keyCode === 13) {
      onEnter && onEnter()
    }
  })

  return (
    <NumberFormat
      customInput={TextField}
      label={label}
      value={value}
      decimalSeparator={'.'}
      allowedDecimalSeparators={[ '.', ',' ]}
      isAllowed={isAllowed}
      decimalScale={decimalScale}
      onValueChange={onChangeHandler}
      onKeyDown={onKeyDown}
      {...rest}
    />
  )
}

export default InputNumberFormat
