export const STATUS_CODES = {
  OK: 'OK',
  ZERO_RESULTS: 'ZERO_RESULTS',
  OVER_DAILY_LIMIT: 'OVER_DAILY_LIMIT ',
  OVER_QUERY_LIMIT: 'OVER_QUERY_LIMIT',
  REQUEST_DENIED: 'REQUEST_DENIED',
  INVALID_REQUEST: 'INVALID_REQUEST',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
}

export const ERRORS_MESSAGES = {
  OVER_DAILY_LIMIT: [
    'The API key is missing or invalid.',
    'Billing has not been enabled on your account.',
    'A self-imposed usage cap has been exceeded.',
    'The provided method of payment is no longer valid (for example, a credit card has expired).',
  ],
  OVER_QUERY_LIMIT: 'You are over your quota',
  REQUEST_DENIED: 'Your request was denied',
  INVALID_REQUEST: 'The query (address, components or latlng) is missing',
  UNKNOWN_ERROR: 'The request could not be processed due to a server error. The request may succeed if you try again',
}
