import { useCallback, useEffect } from 'react'
import L from 'leaflet'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { determineColor, determineLabel, isItemVisible } from '../../render'
import { drawSite } from '../../draw'
import { SECTOR_SITE_FIELD, SECTOR_TYPES_LIST, STATUS_ACTIVE, STATUS_DRAFT } from '../../../../constants/network'
import { moveSite } from '../../../../features/network/mutations'
import { findSiteMarker, SECTOR_OFFSET_STEP } from '../../list'
import {
  selectNetworkLayersVisibility,
  selectSectorFldIdx,
  selectSectorsFull, selectSiteFields,
  selectSiteFldIdx,
  selectSites, selectSitesRedraw,
} from '../../../../features/network/networkSlice'
import { selectSettingsDisplay, selectSettingsSizes } from '../../../../features/settings/settingsSlice'
import { ID_ICON_SITES } from '../../../Panels/Customization/constants'
import { EDIT_ELEMENTS } from '../../../../constants/access'
import { selectEditNeighborsForBC } from '../../../../features/bc/bcSlice'

const Sites = ({ shown, map, activeSite, activeSector, userAccess }) => {
  const dispatch = useDispatch()

  const sites = useSelector(selectSites)
  const siteFields = useSelector(selectSiteFields)
  const [ idIdx, nameIdx, lngIdx, latIdx, statusIdx ] = useSelector(selectSiteFldIdx, shallowEqual)
  const sectorsFull = useSelector(selectSectorsFull)
  const [ , , , , , , , tIdx2 ] = useSelector(selectSectorFldIdx, shallowEqual)
  const sitesRedraw = useSelector(selectSitesRedraw)
  const networkLayersVisibility = useSelector(selectNetworkLayersVisibility, shallowEqual)
  const editNeighborsForBC = useSelector(selectEditNeighborsForBC)
  const {
    sitesVisibility,
    siteTypesVisibility,
    siteTypeIdx,
    siteSortFn,
  } = (networkLayersVisibility || {})
  const { sites: sitesSettings } = useSelector(selectSettingsDisplay) || {}
  const { [ID_ICON_SITES]: ratio } = useSelector(selectSettingsSizes) || {}

  useEffect(() => {
    map._sites = new L.MarkersCanvas({ type: 'site' })
    map._sites.addTo(map)
  }, [ map ])

  // Ініціалізація рендеру сайтів
  useEffect(() => {
    if (
      map && shown && sites?.getList().length && sitesSettings && map._sites && map._sites._version !== sitesRedraw
    ) {
      map._sites.clear()
      const types = siteTypesVisibility.getList()
      const siteColorAttributeIdx = siteFields.findIndex(({ id }) => id === sitesSettings?.attribute)
      const siteLabelAttributeIdx = siteFields.findIndex(({ id }) => id === sitesSettings?.labeling?.attribute)
      const markers = sites.getList()
        .filter((item) => isItemVisible(item, siteTypeIdx, types, siteSortFn, idIdx, latIdx, lngIdx, statusIdx))
        .map((item) => {
          const marker = L.marker([ item[latIdx], item[lngIdx] ], {
            id: item[idIdx],
            name: item[nameIdx],
            type: 'site',
            draw: drawSite,
            ratio,
            active: item[statusIdx] === STATUS_ACTIVE,
            color: item[statusIdx] === STATUS_DRAFT
              ? '#00000000'
              : determineColor(item, sitesSettings, siteColorAttributeIdx, idIdx),
            label: determineLabel(item, sitesSettings.labeling, siteLabelAttributeIdx),
            icon: {
              options: {
                iconSize: [ 24, 24 ],
                iconAnchor: [ 12, 12 ],
              },
            },
          })
          if (map._activeSite === item[idIdx] && map._activeSiteMarker) {
            map._activeSiteMarker.options.targetMarker = marker
          }
          return marker
        })
      map._sites._map = map
      map._sites.addMarkers(markers)
      map._sites._version = sitesRedraw
    } else if (map && shown && sites && !sites.getList().length && map._sites) {
      map._sites.clear()
    }
  }, [
    map, sites, idIdx, nameIdx, latIdx, lngIdx, sitesRedraw, siteTypeIdx, siteTypesVisibility, siteSortFn, statusIdx,
    sitesSettings, siteFields, ratio, shown,
  ])

  // Перемикання видимості сайтів
  useEffect(() => {
    if (map) {
      map._sites?.setVisible(sitesVisibility)
    }
  }, [ map, sitesVisibility, sites ])

  // Переміщення сайтів
  const siteMarkerDrag = useCallback((event) => {
    map._activeSiteMarker.options.targetMarker.setLatLng(event.latlng)
    map._sites.redraw(true)
  }, [ map ])

  const siteMarkerDragEnd = useCallback(() => {
    dispatch(moveSite(map._activeSite, map._activeSiteMarker.getLatLng()))
  }, [ map, dispatch ])

  // Перемикання активного сайту
  useEffect(() => {
    if (!map || !map._sites) {
      return
    }
    if (map._activeSite !== activeSite) {
      if (activeSite) {
        const marker = findSiteMarker(map, activeSite)
        const sectorsRange = sectorsFull.findRangeByValue(activeSite, SECTOR_SITE_FIELD)
        const techOffset = {}
        if (sectorsRange) {
          sectorsRange.forEach((index) => {
            const tech = sectorsFull.getList()[index][tIdx2]
            techOffset[tech] = 0
          })
        }
        let offset = SECTOR_OFFSET_STEP / 2
        SECTOR_TYPES_LIST.forEach((tech) => {
          if (techOffset[tech] === 0) {
            techOffset[tech] = offset
            offset += SECTOR_OFFSET_STEP
          }
        })
        map._sectors.activeSite = {
          id: activeSite,
          techOffset,
        }
        map._sites.activeSite = activeSite
        if (marker && userAccess[EDIT_ELEMENTS]) {
          if (map._activeSiteMarker && marker.options.active) {
            map._activeSiteMarker.removeFrom(map)
            delete map._activeSiteMarker
          }

          if (map._activeSiteMarker) {
            map._activeSiteMarker.options.targetMarker = marker
            map._activeSiteMarker.setLatLng(marker.getLatLng())
          } else if (!marker.options.active) {
            map._activeSiteMarker = L.marker(marker.getLatLng(), {
              pane: 'markerPane',
              draggable: true,
              targetMarker: marker,
              icon: L.divIcon({ className: 'marker-icon' }),
            })
            map._activeSiteMarker.addTo(map)
            map._activeSiteMarker.on('move', siteMarkerDrag)
            map._activeSiteMarker.on('dragend', siteMarkerDragEnd)
          }
        }
      } else {
        if (map._activeSiteMarker) {
          map._activeSiteMarker.removeFrom(map)
          delete map._activeSiteMarker
        }
        if (map._sectors?.activeSite) {
          delete map._sectors.activeSite
        }
        delete map._sites.activeSite
      }
      map._activeSite = activeSite
      map._sites.redraw(true)
      map._sectors?.redraw(true)
    }
  }, [ map, activeSite, activeSector, siteMarkerDrag, siteMarkerDragEnd, sectorsFull, tIdx2, userAccess ])

  useEffect(() => {
    if (editNeighborsForBC?.siteId) {
      map._sites.editNeighborsSite = editNeighborsForBC?.siteId
    } else {
      delete map._sites.editNeighborsSite
    }
    map._sites.redraw(true)
  }, [ editNeighborsForBC, map ])

  return null
}

export default Sites
