import { SECTOR_TECH_FIELD } from '../../constants/network'
import { isNumberType } from '../../utils/grid'

export const buildIndex = ({ fields, data }, idFieldName) => {
  const idFieldIdx = findIndex(fields, idFieldName)
  if (idFieldIdx < 0) {
    console.warn('buildIndex: field not found!', fields, idFieldName)
    return
  }
  const result = Uint32Array.from(data, (_, i) => i)
  result.sort((aIdx, bIdx) => {
    const a = data[aIdx][idFieldIdx]
    const b = data[bIdx][idFieldIdx]
    return a === null && b === null
      ? 0
      : a === null
        ? -1
        : b === null
          ? 1
          : a > b
            ? 1
            : a < b
              ? -1
              : 0
  })
  result.idFieldIdx = idFieldIdx
  return result
}

const indexSearch = (data, index, value, indexPosition = false) => {
  const extract = (i) => data[index[i]][index.idFieldIdx]
  let start = 0
  let end = index.length - 1
  while (start <= end) {
    const mid = Math.floor((start + end) / 2)
    const cmp = extract(mid)
    if (cmp === value) {
      return indexPosition ? mid : index[mid]
    } else if (cmp < value || (cmp === null && value !== null)) {
      start = mid + 1
    } else {
      end = mid - 1
    }
  }
}

const indexSearchRange = (data, index, value) => {
  const found = indexSearch(data, index, value, true)
  if (found >= 0) {
    let rangeFrom = found
    while (rangeFrom > 0 && data[index[rangeFrom - 1]][index.idFieldIdx] === value) {
      rangeFrom--
    }
    let rangeTo = found
    while (rangeTo < index.length - 1 && data[index[rangeTo + 1]][index.idFieldIdx] === value) {
      rangeTo++
    }
    const result = []
    for (let i = rangeFrom; i <= rangeTo; i++) {
      result.push(index[i])
    }
    return result
  }
}

const indexSearchRangeByPrefix = (data, index, prefix, pure) => {
  const extract = (i) => data[index[i]]?.[index.idFieldIdx] ?? ''
  let start = 0
  let end = index.length - 1
  while (start < end) {
    const mid = Math.floor((start + end) / 2)
    const cmp = extract(mid)
    if (cmp < prefix || cmp.startsWith(prefix)) {
      start = mid + 1
    } else {
      end = mid - 1
    }
  }
  let maxIdx = -1
  if (extract(start).startsWith(prefix)) {
    maxIdx = start
  } else if (start > 0 && extract(start - 1).startsWith(prefix)) {
    maxIdx = start - 1
  }
  start = 0
  end = index.length - 1
  while (start < end) {
    const mid = Math.floor((start + end) / 2)
    const cmp = extract(mid)
    if (cmp < prefix) {
      start = mid + 1
    } else {
      end = mid - 1
    }
  }
  let minIdx = -1
  if (extract(start).startsWith(prefix)) {
    minIdx = start
  } else if (start < index.length - 1 && extract(start + 1).startsWith(prefix)) {
    minIdx = start + 1
  }
  return [
    minIdx >= 0 ? (pure ? minIdx : index[minIdx]) : null,
    maxIdx >= 0 ? (pure ? maxIdx : index[maxIdx]) : null,
  ]
}

class Data {
  #list

  #cache

  #index

  #indexes

  constructor (list, index, indexes) {
    this.#list = list || []
    this.#index = index
    this.#indexes = indexes || {}
  }

  getList () {
    return this.#list
  }

  getCache () {
    return this.#cache
  }

  setCache (data) {
    this.#cache = data
  }

  getIndexes () {
    return this.#indexes
  }

  getIndex (indexName) {
    return indexName ? this.#indexes[indexName] : this.#index
  }

  findIndexById (value) {
    if (this.#index) {
      return indexSearch(this.#list, this.#index, value)
    }
  }

  findRangeByValue (value, indexName) {
    const index = this.getIndex(indexName)
    if (index) {
      return indexSearchRange(this.#list, index, value)
    } else {
      console.warn(`index ${indexName} not found!`)
    }
  }

  findRangeByPrefix (prefix, indexName, pure) {
    const index = this.getIndex(indexName)
    if (index) {
      return indexSearchRangeByPrefix(this.#list, index, prefix, pure)
    } else {
      console.warn(`index ${indexName} not found!`)
    }
  }
}

export const defaultSortFn = (a, b) => {
  if (a === b || (a === null && b === null)) { return 0 }
  if (a === null) { return -1 }
  if (b === null) { return 1 }
  return a > b ? 1 : a < b ? -1 : 0
}

export const findIndex = (fields, id) => fields ? fields.findIndex((field) => field.id === id) : -1

export const findIndexCI = (fields, id, defaultValue = -1) => {
  const result = fields ? fields.findIndex((field) => field.id?.toLowerCase() === id?.toLowerCase()) : -1
  return result >= 0 ? result : defaultValue
}

const sortEntries = (sort) => ([ item1 ], [ item2 ]) => sort(item1, item2)

const sortTech = (item1, item2) => {
  const [ , k1, d1 ] = /(\D*)(\d*)/gm.exec(item1)
  const [ , k2, d2 ] = /(\D*)(\d*)/gm.exec(item2)
  return k1 > k2 ? 1 : k1 < k2 ? -1 : +d1 > +d2 ? 1 : +d1 < +d2 ? -1 : 0
}

const sortNumber = (a, b) => +a > +b ? 1 : +a < +b ? -1 : 0

export const sortFunc = (field, type, entries) => {
  switch (field) {
    case SECTOR_TECH_FIELD: {
      return entries ? sortEntries(sortTech) : sortTech
    }
    default: {
      return isNumberType(type)
        ? (entries ? sortEntries(sortNumber) : sortNumber)
        : (entries ? sortEntries(defaultSortFn) : undefined)
    }
  }
}

export default Data
