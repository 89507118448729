import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@fluentui/react'
import {
  saveSettings, clearAllSettings, selectSettingsDeepFiltration,
} from '../../../../features/settings/settingsSlice'
import { elementsRedraw, filterBaseStations, filterSectors } from '../../../../features/network/networkSlice'
import Subheader from '../../../common/Subheader'
import Toggle from '../../../common/Toggle'

const DeepFiltration = () => {
  const dispatch = useDispatch()

  const deepFiltration = useSelector(selectSettingsDeepFiltration)

  const handleToggle = useCallback(() => {
    document.body.classList.add('waiting')
    setTimeout(async () => {
      try {
        await dispatch(saveSettings({ deepFiltration: !deepFiltration }))
        await dispatch(filterBaseStations())
        await dispatch(filterSectors())
        await dispatch(elementsRedraw())
      } finally {
        document.body.classList.remove('waiting')
      }
    }, 50)
  }, [ dispatch, deepFiltration ])

  const dblClick = useCallback((e) => {
    if (e.shiftKey) {
      console.log('Clear all preferences!')
      dispatch(clearAllSettings())
    }
  }, [ dispatch ])

  return (
    <Stack style={{ paddingBottom: 16 }} onDoubleClick={dblClick}>
      <Subheader text="Filter child tables according to parent table" />
      <Toggle onText="On" offText="Off" checked={deepFiltration} onChange={handleToggle} />
    </Stack>
  )
}

export default DeepFiltration
