import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toggle } from '@fluentui/react'
import {
  clearChangesAndUndo,
  processUnsavedChanges, rollbackUnsavedChanges, selectChangesCount, selectOfflineMode, setOfflineMode,
} from '../../features/loading/loadingSlice'
import { selectCanUndo, selectCanRedo, doUndo, doRedo } from '../../features/undo/undoSlice'
import { addTaskType, setTaskTypeCompleted, setTaskTypeFailed, TASK_TYPES } from '../../features/taskLog'
import { EVENT_TYPE, saveEventLog } from '../../features/eventLog/eventLogSlice'
import { createSiteMode, selectCreateSiteMode } from '../../features/network/networkSlice'
import { buildClassList } from '../utils/classes'
import { AutosaveModeIcon, UndoIcon, RedoIcon } from '../common/icons/names'
import { useConfirm } from '../common/Confirm'
import { empty } from '../../utils/format'
import { SideNavButton } from './SideNavButton'
import AutosaveModeButton from './AutosaveModeButton'
import AutosaveModeIconButton from './AutosaveModeIconButton'

import './AutosaveMode.css'

const BASE_CLASS = 'autosave-mode'

const LABEL = 'Auto-Save Mode'

const AutosaveModeToggle = ({ disabled, active, onToggle }) => (
  <Toggle inlineLabel label={LABEL} onText=" " offText=" " onChange={onToggle} checked={active} disabled={disabled} />
)

const AutosaveMode = ({ disabled, expanded }) => {
  const dispatch = useDispatch()
  const { ask, renderConfirm } = useConfirm()

  const active = !useSelector(selectOfflineMode)
  const changesCount = useSelector(selectChangesCount)
  const canUndo = useSelector(selectCanUndo)
  const canRedo = useSelector(selectCanRedo)

  const createSite = useSelector(selectCreateSiteMode)

  const saveChanges = useCallback(async () => {
    dispatch(addTaskType(TASK_TYPES.saveProject))
    const res = await dispatch(processUnsavedChanges())
    if (res) {
      dispatch(setTaskTypeCompleted(TASK_TYPES.saveProject))
    } else {
      dispatch(setTaskTypeFailed(TASK_TYPES.saveProject))
    }
  }, [ dispatch ])

  const rollbackChanges = useCallback(async () => {
    dispatch(addTaskType(TASK_TYPES.rollbackProject))
    const res = await dispatch(rollbackUnsavedChanges())
    if (res) {
      dispatch(setTaskTypeCompleted(TASK_TYPES.rollbackProject))
    } else {
      dispatch(setTaskTypeFailed(TASK_TYPES.rollbackProject))
    }
  }, [ dispatch ])

  const doSetOfflineMode = useCallback(async (value) => {
    if (value) {
      const setOffline = () => {
        dispatch(setOfflineMode(true))
        dispatch(saveEventLog(EVENT_TYPE.autoSaveMode, ' [Off]'))
      }
      // Cancel creating site mode
      if (createSite) {
        ask(
          () => {
            dispatch(createSiteMode(false))
            setOffline()
          },
          empty,
          null,
          {
            title: 'Confirmation',
            messages: [
              'Sites cannot be created if Auto-Save Mode is disabled.',
              'Do you want to cancel site creation?',
            ],
            textYesBtn: 'Disable Auto-Save Mode',
            textNoBtn: 'Cancel',
            allowContentComponent: true,
          },
        )
      } else {
        setOffline()
      }
    } else {
      if (changesCount) {
        ask(
          async () => {
            await saveChanges()
            dispatch(setOfflineMode(false))
            dispatch(saveEventLog(EVENT_TYPE.autoSaveMode, ' [On]'))
          },
          async () => {
            await rollbackChanges()
            dispatch(setOfflineMode(false))
            dispatch(saveEventLog(EVENT_TYPE.autoSaveMode, ' [On]'))
          },
          empty,
          {
            title: 'Save changes?',
            messages: [
              'There are unsaved changes',
              'Do you want to save or rollback them?',
            ],
            textYesBtn: 'Save',
            textNoBtn: 'Rollback',
            textCancelBtn: 'Cancel',
          },
        )
      } else {
        await dispatch(clearChangesAndUndo())
        dispatch(setOfflineMode(false))
        dispatch(saveEventLog(EVENT_TYPE.autoSaveMode, ' [On]'))
      }
    }
  }, [ dispatch, saveChanges, rollbackChanges, changesCount, ask, createSite ])

  const changeMode = useCallback((event, value) => {
    doSetOfflineMode(!value)
  }, [ doSetOfflineMode ])

  const toggleMode = useCallback(() => {
    doSetOfflineMode(active)
  }, [ doSetOfflineMode, active ])

  const undoClick = useCallback(() => {
    dispatch(doUndo())
  }, [ dispatch ])

  const redoClick = useCallback(() => {
    dispatch(doRedo())
  }, [ dispatch ])

  const renderContent = useCallback(() => expanded
    ? (
        <>
          <AutosaveModeToggle active={active} onToggle={changeMode} disabled={disabled} />
          {!active && (
            <div className="autosave-mode-button-container">
              <AutosaveModeIconButton disabled={!canUndo} iconName={UndoIcon} onClick={undoClick} />
              <AutosaveModeIconButton disabled={!canRedo} iconName={RedoIcon} onClick={redoClick} />
              <AutosaveModeButton text="Save" onClick={saveChanges} disabled={!changesCount} />
            </div>
          )}
        </>
      )
    : (
        <SideNavButton lighter iconName={AutosaveModeIcon} onClick={toggleMode} pressed={active} text={LABEL} />
      ),
  [
    expanded, active, changeMode, toggleMode, undoClick, redoClick, changesCount, canUndo, canRedo, saveChanges,
    disabled,
  ])

  return (
    <div className={buildClassList(BASE_CLASS, { expanded, active })}>
      {renderContent()}
      {renderConfirm()}
    </div>
  )
}

export default AutosaveMode
