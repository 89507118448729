import React, { useCallback, useEffect, useState } from 'react'
import {
  DatePicker, DateRangeType, defaultDatePickerStrings, mergeStyleSets,
  Dialog, DialogFooter, DialogType, Spinner, SpinnerSize,
} from '@fluentui/react'
import { format } from 'date-fns'
import { PrimaryButton, DefaultButton } from '../../common/Button'
import { confirmStyles } from '../stylesForm'

import '../style.css'

const styles = mergeStyleSets({
  root: { selectors: { '> *': { marginBottom: 15 } } },
  control: { maxWidth: 300, marginBottom: 15 },
  buttonsContainer: { display: 'flex', justifyContent: 'end', marginBottom: 15, gap: 10 },
  button: { width: 110 },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1001,
  },
})

const MAX_COUNT_MESSAGES = 6
const viewFormat = 'MMMM.yyyy'
const minDate = new Date(2020, 0, 1)
const currentDate = new Date()

const onFormatDate = (date) => {
  return date ? format(date, viewFormat) : '----.----'
}

const calendarProps = {
  dateRangeType: DateRangeType.Month,
  showGoToToday: true, // no effect
  highlightSelectedMonth: true, // no effect
  highlightCurrentMonth: true, // no effect
  isDayPickerVisible: false,
  isMonthPickerVisible: true,
}

const SynchronizationForm = ({
  isShow, onHide, onOk, onExport, isImporting, isExporting, onImport, syncSource,
}) => {
  const [ dateFrom, setDateFrom ] = useState(null)
  const [ dateTo, setDateTo ] = useState(null)
  const onSync = useCallback(() => {
    onOk && onOk(syncSource, { dateFrom, dateTo })
    onHide && onHide()
  }, [ dateFrom, dateTo, onOk, onHide, syncSource ])
  const isPeriod = syncSource?.period
  const isImport = syncSource?.import
  const isSync = !isPeriod || (dateFrom && dateTo)
  const messages = syncSource?.period
    ? `Select the data synchronization period for ${syncSource?.name ?? ''}`
    : `Are you confirming the start of the data sync for the ${syncSource?.name ?? ''}?`

  useEffect(() => {
    if (dateTo && dateFrom && dateFrom > dateTo) {
      setDateFrom(dateTo)
    }
  }, [ dateFrom, dateTo ])

  useEffect(() => {
    if (dateTo && dateFrom && dateFrom > dateTo) {
      setDateFrom(dateTo)
    } else if (dateTo && !dateFrom) {
      setDateFrom(dateTo)
    } else if (!dateTo && dateFrom) {
      setDateTo(dateFrom)
    }
  }, [ dateFrom, dateTo ])

  return (
    <>
    {isShow && <div id="Overlay" className={styles.overlay} />}
    <Dialog
      hidden={!isShow}
      onDismiss={onHide}
      maxWidth={'800px'}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Synchronization',
      }}
      modalProps={{
        isModeless: true,
      }}
    >
      <div className={confirmStyles.body}>
        {isImport && <div>
          <p>{'You can export and import the settlements info before synchronization'}</p>
          <div className={styles.buttonsContainer}>
            <DefaultButton
              className={styles.button}
              onClick={onExport}
              disabled={isImporting || isExporting}
            >
              {isExporting ? <Spinner size={SpinnerSize.small} /> : 'Export'}
            </DefaultButton>
            <DefaultButton
              className={styles.button}
              onClick={onImport}
              disabled={isImporting || isExporting}
            >
              {isImporting ? <Spinner size={SpinnerSize.small} /> : 'Import'}
            </DefaultButton>
          </div>
        </div>}
        {Array.isArray(messages)
          ? messages.slice(0, MAX_COUNT_MESSAGES).map((message, index) => {
            if (index === MAX_COUNT_MESSAGES - 1 && MAX_COUNT_MESSAGES !== messages.length) {
              return (<p key={index} className="lastItem">...</p>)
            }
            return (<p key={index} className={!index ? 'title' : 'item'}>{message}</p>)
          })
          : messages
        }
      </div>
      {isPeriod
        ? (
            <div className={styles.root}>
              <DatePicker
                isRequired
                label="Date from"
                placeholder="Select a date..."
                className={styles.control}
                value={dateFrom}
                onSelectDate={setDateFrom}
                formatDate={onFormatDate}
                calendarProps={calendarProps}
                showGoToToday={true}
                highlightCurrentMonth={true}
                highlightSelectedMonth={true}
                strings={defaultDatePickerStrings}
                minDate={minDate}
                maxDate={dateTo || currentDate}
              />
              <DatePicker
                isRequired
                label="Date to"
                placeholder="Select a date..."
                className={styles.control}
                value={dateTo}
                onSelectDate={setDateTo}
                formatDate={onFormatDate}
                calendarProps={calendarProps}
                showGoToToday={true}
                highlightCurrentMonth={true}
                highlightSelectedMonth={true}
                strings={defaultDatePickerStrings}
                minDate={minDate}
                maxDate={currentDate}
              />
            </div>
          )
        : null
      }
      <DialogFooter>
        <PrimaryButton onClick={onSync} text="Ok" disabled={!isSync || isImporting} />
        <DefaultButton onClick={onHide} text="Close" />
      </DialogFooter>
    </Dialog>
    </>
  )
}

export default SynchronizationForm
