import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ProgressIndicator, Spinner, SpinnerSize } from '@fluentui/react'
import Modal from '../common/Modal'
import { selectSavingRates } from '../../features/compositeIndex/compositeIndexSlice'

import './SavingRates.css'

const SavingRates = ({ onClose }) => {
  const { total, current, status } = useSelector(selectSavingRates)

  useEffect(() => {
    if (status === 'final') {
      onClose()
    }
  }, [ onClose, status ])

  return (
    <Modal
      title="Saving composite index"
      width={480}
    >
      <ProgressIndicator
        label={`${current} / ${total}`}
        percentComplete={current / total}
      />
      {status === 'overloaded' && (
        <p className="SavingRates-warning">
          Server overloaded, please wait...
          <Spinner size={SpinnerSize.small} />
        </p>
      )}
    </Modal>
  )
}

export default SavingRates
