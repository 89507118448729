import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Handsontable from 'handsontable'
import { DateRangeType, Spinner, SpinnerSize, DialogFooter } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { HotTable } from '@handsontable/react'
import { format as formatDate } from 'date-fns'
import { toast } from 'react-hot-toast'
import { PrimaryButton } from '../common/Button'
import Modal from '../common/Modal'
import Grid from '../common/Grid'
import { useConfirm } from '../common/Confirm'
import Dropdown from '../common/Dropdown'
import DatePicker from '../common/DatePicker'
import {
  selectSiteFields, selectBaseStationFields, selectSectorFields, selectCountSites, selectCountBaseStations,
  selectCountSectors, selectComplaintFields, selectCountComplaintsFull,
} from '../../features/network/networkSlice'
import {
  HISTORICAL_DATA_TYPE, FORMAT_TYPE, selectLoading, selectExporting, loadHistoricalData, setSelectedAttributes,
  selectSelectedAttributes, selectHistoricalData, selectHistoricalDataColumns, clearHistoricalData, selectWarnings,
  clearWarnings,
} from '../../features/historicalData/historicalDataSlice'
import { saveEventLog, EVENT_TYPE, STATUS_ERROR, wrapByEventLog } from '../../features/eventLog/eventLogSlice'
import { empty } from '../../utils/format'
import SelectAttributesDialog from './SelectAttributesDialog'
import {
  SITE_FIELDS, REQUIRED_SITE_FIELDS, IGNORE_SITE_FIELDS, RBS_FIELDS, REQUIRED_RBS_FIELDS, IGNORE_RBS_FIELDS,
  SECTOR_FIELDS, REQUIRED_SECTOR_FIELDS, IGNORE_SECTOR_FIELDS, REQUIRED_COMPLAINT_FIELDS,
  REQUIRED_SITE_FIELDS_FOR_POLYGON, REQUIRED_SITE_FIELDS_FOR_REGION, LIMITED_VOLUME_TYPES,
} from './constants'

import './HistoricalData.css'

const typeOptions = [
  { key: HISTORICAL_DATA_TYPE.region, text: 'Region', value: 0 },
  { key: HISTORICAL_DATA_TYPE.polygon, text: 'Polygon', value: 1 },
  { key: HISTORICAL_DATA_TYPE.site, text: 'Site', value: 2 },
  { key: HISTORICAL_DATA_TYPE.rbs, text: 'RBS', value: 3 },
  { key: HISTORICAL_DATA_TYPE.sector, text: 'Sector', value: 6 },
  { key: HISTORICAL_DATA_TYPE.complaints, text: 'Complaints', value: 7 },
]

const typePlaceholder = typeOptions.map((type) => type.text).join('/')
const typeTitle = 'Please select what you want to use as a filter'

const formatOptions = [
  { key: FORMAT_TYPE.table, text: 'Table', value: 0 },
  { key: FORMAT_TYPE.excel, text: 'Export (Excel)', value: 1 },
  { key: FORMAT_TYPE.csv, text: 'Export (CSV)', value: 2 },
]

const formatPlaceholder = 'Select format'
const formatTitle = 'Please select the format in which you want to get the data'
const viewFormat = 'MMMM yyyy'
const dateFormat = 'yyyy-MM-dd'

const EXCEL_RECORD_LIMIT = 300000
const CSV_RECORD_LIMIT = 450000

const minDate = new Date(2020, 0, 1)

const onFormatDate = (date) => date ? formatDate(date, viewFormat) : ''

const monthDiff = (d1, d2) => {
  let months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()
  return months + 1
}

const HistoricalData = ({ onClose }) => {
  const dispatch = useDispatch()
  const hotGridRef = useRef()
  const { renderConfirm, ask, msg } = useConfirm()

  const [ type, setType ] = useState(null)
  const [ format, setFormat ] = useState(null)
  const [ monthFrom, setMonthFrom ] = useState(new Date())
  const [ monthTo, setMonthTo ] = useState(new Date())
  const [ attributes, setAttributes ] = useState(null)
  const [
    selectAttributesOpened,
    {
      setTrue: openSelectAttributes,
      setFalse: closeSelectAttributes,
    },
  ] = useBoolean(false)

  const loading = useSelector(selectLoading)
  const exporting = useSelector(selectExporting)
  const siteColumns = useSelector(selectSiteFields)
  const rbsColumns = useSelector(selectBaseStationFields)
  const sectorColumns = useSelector(selectSectorFields)
  const complaintColumns = useSelector(selectComplaintFields)
  const sitesCount = useSelector(selectCountSites)
  const rbsCount = useSelector(selectCountBaseStations)
  const sectorsCount = useSelector(selectCountSectors)
  const selectedAttributes = useSelector(selectSelectedAttributes)
  const data = useSelector(selectHistoricalData)
  const fields = useSelector(selectHistoricalDataColumns)
  const warnings = useSelector(selectWarnings)
  const complaintsCount = useSelector(selectCountComplaintsFull)

  useEffect(() => {
    if (monthTo && monthFrom && monthFrom > monthTo) {
      setMonthFrom(monthTo)
    } else if (monthTo && !monthFrom) {
      setMonthFrom(monthTo)
    } else if (!monthTo && monthFrom) {
      setMonthTo(monthFrom)
    }
  }, [ monthFrom, monthTo ])

  const columns = useMemo(() => {
    return fields
      ? fields.map((field) => {
        return {
          ...field,
          hidden: !selectedAttributes?.includes(field.id) && !field.custom,
          editable: false,
        }
      })
      : []
  }, [ selectedAttributes, fields ])

  useEffect(() => {
    if (type) {
      let addFields = []
      let requiredFields = []
      let ignoreFields = IGNORE_SECTOR_FIELDS
      let attributes = sectorColumns
      if (type.key === HISTORICAL_DATA_TYPE.rbs) {
        attributes = rbsColumns
        addFields = RBS_FIELDS
        requiredFields = REQUIRED_RBS_FIELDS
        ignoreFields = IGNORE_RBS_FIELDS
      } else if (
        type.key === HISTORICAL_DATA_TYPE.site || type.key === HISTORICAL_DATA_TYPE.polygon ||
        type.key === HISTORICAL_DATA_TYPE.region
      ) {
        attributes = siteColumns
        addFields = SITE_FIELDS
        if (type.key === HISTORICAL_DATA_TYPE.region) {
          requiredFields = REQUIRED_SITE_FIELDS_FOR_REGION
        } else if (type.key === HISTORICAL_DATA_TYPE.polygon) {
          requiredFields = REQUIRED_SITE_FIELDS_FOR_POLYGON
        } else {
          requiredFields = REQUIRED_SITE_FIELDS
        }
        ignoreFields = IGNORE_SITE_FIELDS
      } else if (type.key === HISTORICAL_DATA_TYPE.sector) {
        addFields = SECTOR_FIELDS
        requiredFields = REQUIRED_SECTOR_FIELDS
      } else if (type.key === HISTORICAL_DATA_TYPE.complaints) {
        attributes = complaintColumns
        addFields = null
        ignoreFields = null
        requiredFields = REQUIRED_COMPLAINT_FIELDS
      }
      attributes = attributes.filter((attr) => {
        if (!addFields) {
          return !attr.hidden && attr.historical && !requiredFields.includes(attr.id)
        }
        return !attr.hidden && attr.historical &&
          (addFields.includes(attr.id) ||
            (attr.inCompositeIndex && !ignoreFields.includes(attr.id)))
      })
      const selectedAttributes = requiredFields.concat(attributes.map((column) => column.id).filter(Boolean))
      dispatch(setSelectedAttributes(selectedAttributes))
      setAttributes(attributes)
    }
  }, [ dispatch, setAttributes, type, siteColumns, rbsColumns, sectorColumns, complaintColumns ])

  useEffect(() => {
    if (warnings) {
      dispatch(saveEventLog(EVENT_TYPE.historicalDataWarnings, `\n\t${warnings.join('\n\t')}`, STATUS_ERROR))
      toast.error(warnings.join('\n'), { duration: 5000 })
      dispatch(clearWarnings())
    }
  }, [ dispatch, warnings ])

  const selectType = useCallback((_, item) => {
    dispatch(clearHistoricalData())
    setType(item)
  }, [ dispatch, setType ])

  const selectFormat = useCallback((_, item) => setFormat(item), [ setFormat ])

  const onChooseAttribute = useCallback(() => {
    openSelectAttributes()
  }, [ openSelectAttributes ])

  const onGetData = useCallback(async () => {
    const onActionRun = async () => {
      const fromDate = new Date(monthFrom)
      fromDate.setDate(1)
      const from = formatDate(fromDate, dateFormat)
      const toDate = new Date(monthTo)
      toDate.setDate(1)
      const to = formatDate(toDate, dateFormat)

      await dispatch(wrapByEventLog({
        type: EVENT_TYPE.historicalDataRequest,
        details: `Type = ${type.key}; From = ${from}; To = ${to}; Format = ${format?.key}`,
        action: () => dispatch(loadHistoricalData(type.key, from, to, format?.key)),
        extractor: ([ rows, cols ]) => `Loaded ${rows} rows x ${cols} columns`,
      }))

      const table = hotGridRef.current?.hotInstance
      if (table) {
        table.getPlugin('columnSorting').sort({ column: 0, sortOrder: 'asc' })
      }
    }

    const calcRecordCount = () => {
      const periods = monthDiff(monthFrom, monthTo)
      if (periods > 1) {
        let count
        switch (type?.key) {
          case HISTORICAL_DATA_TYPE.site: {
            count = sitesCount
            break
          }
          case HISTORICAL_DATA_TYPE.rbs: {
            count = rbsCount
            break
          }
          case HISTORICAL_DATA_TYPE.sector: {
            count = sectorsCount
            break
          }
          case HISTORICAL_DATA_TYPE.complaints: {
            count = complaintsCount
            break
          }
          default:
        }
        return count * periods
      } else {
        return 0
      }
    }

    if (LIMITED_VOLUME_TYPES.includes(type?.key)) {
      const recordCount = calcRecordCount()
      if (/* format?.key !== FORMAT_TYPE.table && */ recordCount > CSV_RECORD_LIMIT) {
        msg({
          messages: [
            'The reporting tool is unable to create a file of this size.',
            'Please reduce the number of elements or narrow the data sampling period.',
          ],
        })
        return
      } else if (format?.key === FORMAT_TYPE.excel && recordCount > EXCEL_RECORD_LIMIT) {
        msg({
          messages: [
            'Ecxel\'s report creation tool is unable to create a file of this size.',
            'Please choose CSV format, reduce the number of elements or narrow the data sampling period.',
          ],
        })
        return
      }
    }

    if (type.key === HISTORICAL_DATA_TYPE.complaints) {
      return onActionRun()
    }

    const SETTINGS = {
      colHeaders: true,
      stretchH: 'all',
      licenseKey: 'non-commercial-and-evaluation',
    }

    const columns = [
      { key: 'name', title: 'Name', readOnly: true },
      { key: 'amount', title: 'Selected', readOnly: true },
    ]

    const data = [
      [ 'Sites', sitesCount ],
      [ 'RBS', rbsCount ],
      [ 'Sectors', sectorsCount ],
    ]

    const renderStatistics = () => (
      <HotTable
        data={data}
        columns={columns}
        settings={SETTINGS}
        width="400px"
        height="180px"
      />
    )

    ask(
      onActionRun,
      empty,
      null,
      {
        title: 'Confirmation',
        subTitle: 'Network items will be used',
        messages: renderStatistics,
        textYesBtn: 'Get data',
        textNoBtn: 'Cancel',
        allowContentComponent: true,
      },
    )
  }, [
    dispatch, type, format, ask, msg, sitesCount, rbsCount, sectorsCount, complaintsCount, monthFrom, monthTo,
  ])

  const onCloseSelectAttributes = useCallback(() => {
    closeSelectAttributes()
  }, [ closeSelectAttributes ])

  const onApplySelectAttributes = useCallback((selectedAttributes) => {
    if (type) {
      let requiredFields = []
      if (type.key === HISTORICAL_DATA_TYPE.rbs) {
        requiredFields = REQUIRED_RBS_FIELDS
      } else if (type.key === HISTORICAL_DATA_TYPE.site) {
        requiredFields = REQUIRED_SITE_FIELDS
      } else if (type.key === HISTORICAL_DATA_TYPE.polygon) {
        requiredFields = REQUIRED_SITE_FIELDS_FOR_POLYGON
      } else if (type.key === HISTORICAL_DATA_TYPE.region) {
        requiredFields = REQUIRED_SITE_FIELDS_FOR_REGION
      } else if (type.key === HISTORICAL_DATA_TYPE.sector) {
        requiredFields = REQUIRED_SECTOR_FIELDS
      } else if (type.key === HISTORICAL_DATA_TYPE.complaints) {
        requiredFields = REQUIRED_COMPLAINT_FIELDS
      }
      const resAttributes = requiredFields.concat(selectedAttributes)
      dispatch(setSelectedAttributes(resAttributes))
    }
  }, [ dispatch, type ])

  const handleOnClose = useCallback(() => {
    dispatch(clearHistoricalData())
    onClose()
  }, [ dispatch, onClose ])

  const dateRenderer = function (hotInstance, td, row, column, prop, value, cellProperties) {
    Handsontable.renderers.DateRenderer.apply(this, arguments)
    if (cellProperties.instance.getCellMeta(row, column)?.readOnly) {
      td.className = 'read-only-cell'
    }
    const date = new Date(value)
    td.innerHTML = `<span>${formatDate(date, viewFormat)}</span>`
  }

  const cells = (row, col) => {
    const cellProperties = {}
    const type = columns?.[col]?.type
    if (type === 'datetime') {
      cellProperties.renderer = dateRenderer
    }
    return cellProperties
  }

  return (
    <>
      <Modal
        id={'historical-data'}
        title={'Historical data'}
        onClose={!loading && !exporting && handleOnClose}
        heightAuto={true}
        marginV={'10vh'}
      >
        <div className={'historical-data-content'}>
          <div className={'historical-data-type'}>
            <Dropdown
              options={typeOptions}
              label={'Select type'}
              placeholder={typePlaceholder}
              title={typeTitle}
              onChange={selectType}
              selectedKey={type?.key}
              disabled={loading || exporting}
            />
            <PrimaryButton
              text="Choose attribute"
              onClick={onChooseAttribute}
              disabled={!attributes || loading || exporting}
            />
          </div>
          <div className={'historical-data-format'}>
            <div className={'historical-data-date-range'}>
              <DatePicker
                label='Month from'
                value={monthFrom}
                onSelectDate={setMonthFrom}
                formatDate={onFormatDate}
                calendarProps={{
                  dateRangeType: DateRangeType.Month,
                  isDayPickerVisible: false,
                }}
                highlightSelectedMonth={true}
                highlightCurrentMonth={true}
                minDate={minDate}
                maxDate={monthTo}
                disabled={loading || exporting}
              />
              <DatePicker
                id={'historical-data-month-to'}
                label='Month to'
                value={monthTo}
                onSelectDate={setMonthTo}
                formatDate={onFormatDate}
                calendarProps={{
                  dateRangeType: DateRangeType.Month,
                  isDayPickerVisible: false,
                }}
                highlightSelectedMonth={true}
                highlightCurrentMonth={true}
                minDate={minDate}
                maxDate={new Date()}
                disabled={loading || exporting}
              />
              <Dropdown
                options={formatOptions}
                label={'Select format'}
                placeholder={formatPlaceholder}
                title={formatTitle}
                onChange={selectFormat}
                selectedKey={format?.key}
                disabled={loading || exporting}
              />
            </div>
            <PrimaryButton
              text={format?.key !== FORMAT_TYPE.table ? 'Export data' : 'Get data' }
              onClick={onGetData}
              disabled={loading || exporting || !selectedAttributes || !format || !monthFrom || !monthTo}
            />
          </div>
          <div className={'historical-data-table'}>
            {(loading || exporting)
              ? (
                  <Spinner
                    size={SpinnerSize.large}
                    label={exporting ? 'Exporting historical data...' : 'Loading historical data...'}
                  />
                )
              : columns?.length > 0 && data?.length > 0
                ? (
                    <Grid
                      refHot={hotGridRef}
                      data={data}
                      columns={columns}
                      stretchH="all"
                      width="100%"
                      dataType={`historicalData-${type?.key}`}
                      cells={cells}
                      disableHotKeys={true}
                    />
                  )
                : columns.length > 0 && 'No data for this period'
            }
          </div>
        </div>
        <DialogFooter>
          <PrimaryButton text="Close" onClick={handleOnClose} disabled={loading || exporting} />
        </DialogFooter>
      </Modal>
      <SelectAttributesDialog
        columns={attributes}
        selectedAttributes={selectedAttributes}
        onClose={onCloseSelectAttributes}
        onApply={onApplySelectAttributes}
        hidden={!selectAttributesOpened}
      />
      {renderConfirm()}
    </>
  )
}

export default HistoricalData
