export const AdvancedFilterIcon = 'ADVANCED_FILTER_ICON'
export const ApproveIcon = 'APPROVE_ICON'
export const AutosaveModeIcon = 'AUTOSAVE_MODE_ICON'
export const BusinessCasesIcon = 'BUSINESS_CASES_ICON'
export const CabinetIcon = 'CABINET_ICON'
export const CalculateDraftIcon = 'CALCULATE_DRAFT_ICON'
export const CalculatePainZonesIcon = 'CALCULATE_PAIN_ZONES_ICON'
export const CaseSummaryIcon = 'CASE_SUMMARY_ICON'
export const CheckmarkIcon = 'CHECKMARK_ICON'
export const ClipboardIcon = 'CLIPBOARD_ICON'
export const CloseIcon = 'CLOSE_ICON'
export const ColorsIcon = 'COLORS_ICON'
export const ColumnSettingsIcon = 'COLUMN_SETTINGS_ICON'
export const ComplaintsIcon = 'COMPLAINTS_ICON'
export const CompositeIndexIcon = 'COMPOSITE_INDEX_ICON'
export const ComputationZoneIcon = 'COMPUTATION_ZONE_ICON'
export const ConvertIcon = 'CONVERT_ICON'
export const CoordinatesIcon = 'COORDINATES_ICON'
export const CopyIcon = 'COPY_ICON'
export const CorrespondenceIcon = 'CORRESPONDENCE_ICON'
export const CoverageIcon = 'COVERAGE_ICON'
export const CreateSiteIcon = 'CREATE_SITE_ICON'
export const CreateZoneIcon = 'CREATE_ZONE_ICON'
export const CSVIcon = 'CSV_ICON'
export const CustomizationIcon = 'CUSTOMIZATION_ICON'
export const CutIcon = 'CUT_ICON'
export const DefaultMap = 'DEFAULT_MAP'
export const DeleteIcon = 'DELETE_ICON'
export const DragIcon = 'DRAG_ICON'
export const DropdownIcon = 'DROPDOWN_ICON'
export const EditCoefficientsIcon = 'EDIT_COEFFICIENTS_ICON'
export const EntireTableIcon = 'ENTIRE_TABLE_ICON'
export const EqualIcon = 'EQUAL_ICON'
export const ExceptSelectedIcon = 'EXCEPT_SELECTED_ICON'
export const ExportIcon = 'EXPORT_ICON'
export const FilterIcon = 'FILTER_ICON'
export const FilteringZoneIcon = 'FILTERING_ZONE_ICON'
export const FocusZoneIcon = 'FOCUS_ZONE_ICON'
export const FreezeColumnIcon = 'FREEZE_COLUMN_ICON'
export const FreezeRowIcon = 'FREEZE_ROW_ICON'
export const FullscreenTableIcon = 'FULLSCREEN_TABLE_ICON'
export const GreaterEqualIcon = 'GREATER_EQUAL_ICON'
export const GreaterIcon = 'GREATER_ICON'
export const HiddenDropdownIcon = 'HIDDEN_DROPDOWN_ICON'
export const HideIcon = 'HIDE_ICON'
export const HideTableIcon = 'HIDE_TABLE_ICON'
export const HistoricalDataIcon = 'HISTORICAL_DATA_ICON'
export const ImportIcon = 'IMPORT_ICON'
export const InfoIcon = 'INFO_ICON'
export const KMLIcon = 'KML_ICON'
export const KMZIcon = 'KMZ_ICON'
export const LeftArrowIcon = 'LEFT_ARROW_ICON'
export const LessEqualIcon = 'LESS_EQUAL_ICON'
export const LessIcon = 'LESS_ICON'
export const MacroIndicatorsIcon = 'MACRO_INDICATORS_ICON'
export const MapIcon = 'MAP_ICON'
export const MapStyleIcon = 'MAP_STYLE_ICON'
export const MIDMIFIcon = 'MID_MIF_ICON'
export const MinusIcon = 'MINUS_ICON'
export const MoreActionsIcon = 'MORE_ACTIONS_ICON'
export const MoreIcon = 'MORE_ICON'
export const MoveDownIcon = 'MOVE_DOWN_ICON'
export const MoveLeftIcon = 'MOVE_LEFT_ICON'
export const MoveRightIcon = 'MOVE_RIGHT_ICON'
export const MoveUpIcon = 'MOVE_UP_ICON'
export const MSExcelIcon = 'MS_EXCEL_ICON'
export const NetworkIcon = 'NETWORK_ICON'
export const NotEqualIcon = 'NOT_EQUAL_ICON'
export const OnlySelectedIcon = 'ONLY_SELECTED_ICON'
export const OpenDropdownIcon = 'OPEN_DROPDOWN_ICON'
export const OpenSiteIcon = 'OPEN_SITE_ICON'
export const PasteIcon = 'PASTE_ICON'
export const PencilIcon = 'PENCIL_ICON'
export const PinIcon = 'PIN_ICON'
export const PlusIcon = 'PLUS_ICON'
export const PointerIcon = 'POINTER_ICON'
export const RBSIcon = 'RBS_ICON'
export const RedoIcon = 'REDO_ICON'
export const RejectIcon = 'REJECT_ICON'
export const ReplaceIcon = 'REPLACE_ICON'
export const ResetFilterIcon = 'RESET_FILTER_ICON'
export const ResizeElementIcon = 'RESIZE_ELEMENT_ICON'
export const SaveIcon = 'SAVE_ICON'
export const SearchIcon = 'SEARCH_ICON'
export const SectorsIcon = 'SECTORS_ICON'
export const SelectedRangeIcon = 'SELECTED_RANGE_ICON'
export const ShowIcon = 'SHOW_ICON'
export const SitesIcon = 'SITES_ICON'
export const SortDownIcon = 'SORT_DOWN_ICON'
export const SortIcon = 'SORT_ICON'
export const SortUpIcon = 'SORT_UP_ICON'
export const StandardIcon = 'STANDARD_ICON'
export const TRFIcon = 'TRF_ICON'
export const UndoIcon = 'UNDO_ICON'
export const UpdateDBIcon = 'UPDATE_DB_ICON'
export const UserMapIcon = 'USER_MAP_ICON'
export const VectorMapIcon = 'VECTOR_MAP_ICON'
export const ZonesIcon = 'ZONES_ICON'
