export const STAGE_KEYS = {
  LOADING: 'loading',
  LOADED: 'loaded',
  FILTERING: 'filtering',
  FILTERED: 'filtered',
  INDEXING: 'indexing',
  INDEXED: 'indexed',
  COMPLETE: 'complete',
  ABORTED: 'aborted',
}
