import { fetch } from './rest'

export const OPERATION_DIFF = {
  APPLY_DEFAULT: 'APPLY_DEFAULT',
  APPLY_PROJECT: 'APPLY_PROJECT',
  APPLY_SHADOW: 'APPLY_SHADOW',
  APPLY_SHADOW_DEFAULT: 'APPLY_SHADOW_DEFAULT',
}

export const getData = (activeProjectId = '_') => {
  return fetch(`/api/project/${activeProjectId}/network/diff`, 'GET')
}

export const applyNetworkDiff = (project, operation, data) => {
  if (!project) {
    return false
  }
  return fetch(`/api/project/${project}/network/diff`, 'PATCH', { operation, ...data })
}

export const reloadFromDefault = (projectId) => {
  if (!projectId) {
    return false
  }
  return fetch(`/api/project/${projectId}/cancel`, 'PUT')
}

export const updateFromAtoll = (projectId) => {
  return fetch('/api/job', 'POST', {
    type: 'SYNC_ATOLL_NETWORK_PROJECT',
    properties: {
      projectId: projectId,
    },
  })
}

export const updateBC = (data) => {
  return fetch('/api/updateDB/updateBC', 'POST', data)
    .catch((error) => {
      console.error('Error updating BC', error)
      return {
        error: error,
        successful: false,
      }
    })
}
