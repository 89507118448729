import { errorFixing, serverErrorMessage } from '../utils/format'

export const ORIGIN = process.env.REACT_APP_API || window.location.origin
export const uriEventLog = '/api/logs'

export class AuthenticationError extends Error {
  constructor (message) {
    super(message)
    this.name = 'Authentication Error'
  }
}

const object2URLEncoded = (element, key, list = []) => {
  if (typeof element === 'object') {
    for (const idx of Object.keys(element)) {
      object2URLEncoded(element[idx], key ? `${key}[${idx}]` : idx, list)
    }
  } else {
    list.push(`${key}=${encodeURIComponent(element)}`)
  }
  return list.join('&')
}

export const fetch = (
  uri, method = 'GET', body, bodyType = 'json', credentials = true, origin, ignoreNotFound,
) => {
  if (method.toUpperCase() !== 'GET') {
    const store = window.store?.getState()
    const activeProjectId = store?.projects.active?.id || '_'
    const userId = store?.login.user?.id || 'anonymous'
    localStorage.setItem(`last_project_id_${userId}`, activeProjectId)
  }
  const options = {
    method,
    mode: 'cors',
    cache: 'no-cache',
  }
  if (credentials) {
    options.credentials = 'include'
  }
  if (body) {
    if (body instanceof FormData) {
      options.body = body
    } else {
      options.body = bodyType === 'json' ? JSON.stringify(body) : object2URLEncoded(body)
      options.headers = {
        'Content-Type': bodyType === 'json' ? 'application/json' : 'application/x-www-form-urlencoded',
      }
    }
  }
  document.body.classList.add('waiting')
  return global.fetch(`${origin || ORIGIN}${uri}`, options)
    .finally(() => {
      document.body.classList.remove('waiting')
    })
    .then(async (res) => {
      const { ok, status } = res
      if (!ok || status >= 400) {
        if (status === 401 || status === 403) {
          // Ця ситуація має виникати, коли у користувача закінчився термін дії авторизаційної cookie,
          // або коли доступ користувача був заблокований адміністратором.
          // Видалимо авторизаційну cookie і перенаправимо його на форму авторизації.
          const message = status === 401
            // eslint-disable-next-line max-len
            ? 'Your authentication credentials are invalid. This may occur if your session has expired. You will be redirected to the login page.'
            // eslint-disable-next-line max-len
            : 'You have been denied access. Please contact your administrator for assistance. You will be redirected to the login page.'
          throw new AuthenticationError(message)
        }
        let errorMessage
        try {
          const object = await res.json()
          if (object?.errorMessage?.length < 256) {
            errorMessage = object?.errorMessage
          } else {
            errorMessage = 'Validation error. Please try again.'
          }
        } catch (e) {
          errorMessage = 'Something went wrong. Please try again.'
        }
        const error = new Error(errorMessage || `[${status}] query "${uri}" is not OK`)
        error.status = status
        throw error
      }
      if ([ 202, 204 ].indexOf(status) >= 0) {
        return null
      }
      return bodyType === 'json' ? res.json() : (bodyType === 'blob' ? res.blob() : res.text())
    })
    .then((json = {}) => {
      if (json === null) {
        return null
      }
      // eslint-disable-next-line no-prototype-builtins
      if (!json.hasOwnProperty('success')) {
        return json
      }
      const { success, data, error } = json
      if (!success && error) {
        throw new Error(error)
      }
      return data
    })
    .catch((error) => {
      if (error.status === 409) {
        serverErrorMessage(error)
      } else if (error.status !== 404 || !ignoreNotFound) {
        errorFixing(error, uri, uri.startsWith(uriEventLog) && !(error instanceof AuthenticationError))
      }
      throw error
    })
}
