import React from 'react'
import Search from './Search'

export const SearchContainer = () => (
  <div
    id='search-content-container'
    style={{
      flex: 1,
      display: 'flex',
    }}
  />
)

export default Search
