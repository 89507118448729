import { mergeStyles } from '@fluentui/react'
import { KEYS } from '../constants'

export const prefixColumnId = 'table.users'

export const COLUMNS = [
  {
    id: KEYS.ID,
    title: 'Identifier',
    readOnly: true,
  },
  {
    id: KEYS.CHECK,
    title: 'check',
    type: 'checkbox',
    readOnly: false,
  },
  {
    id: KEYS.NAME,
    title: 'Name',
    readOnly: true,
  },
  {
    id: KEYS.LOGIN,
    title: 'Login',
    type: 'text',
    readOnly: true,
  },
  {
    id: KEYS.ROLES,
    title: 'Role',
    type: 'roles',
    readOnly: true,
    className: 'align-center',
  },
  {
    id: KEYS.STATUS,
    title: 'Status',
    type: 'status',
    readOnly: true,
    className: 'align-center',
  },
]

export const iconClass = mergeStyles({
  marginTop: 1,
  marginRight: 8,
})

export const iconClassGroup = mergeStyles({
  marginTop: 1,
  marginRight: 8,
  backgroundColor: '#e5f1ff',
})
