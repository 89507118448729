import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'

const initialState = {
  loading: false,
  saving: false,
  name: 'Users',
  list: [],
  reload: 0,
}

const loading = (state) => {
  state.loading = true
}

const saving = (state) => {
  state.saving = true
}
const doLoadUsers = createAsyncThunk(
  'users/getUsers',
  api.users.getUsers,
)

const doSaveUser = createAsyncThunk(
  'user/saveUser',
  api.users.saveUser,
)

const doUpdateUser = createAsyncThunk(
  'user/updateUser',
  api.users.updateUser,
)

const doUpdateUsers = createAsyncThunk(
  'user/updateUsers',
  api.users.updateUsers,
)

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setProjectList: (state, action) => {
      state.users.list = action.payload
    },
  },
  extraReducers: (builder) => builder
    .addCase(doLoadUsers.pending, loading)
    .addCase(doLoadUsers.fulfilled, (state, action) => {
      state.loading = false
      state.ready = true
      state.list = action.payload
    })
    .addCase(doLoadUsers.rejected, (state, action) => {
      state.loading = false
      state.list = []
      console.error('doLoadUsers', action)
    })
    .addCase(doSaveUser.pending, saving)
    .addCase(doSaveUser.fulfilled, (state) => {
      state.saving = false
    })
    .addCase(doUpdateUser.pending, saving)
    .addCase(doUpdateUser.fulfilled, (state) => {
      state.saving = false
      state.reload++
    })
    .addCase(doUpdateUsers.pending, saving)
    .addCase(doUpdateUsers.fulfilled, (state) => {
      state.saving = false
      state.reload++
    })
    .addCase(doUpdateUsers.rejected, (state, action) => {
      state.saving = false
      state.reload++
      console.error('doUpdateUsers', action)
    }),
})

export const selectUsers = (state) => state.users.list

export const selectLoading = (state) => state.users.loading
export const selectSaving = (state) => state.users.saving
export const selectBusy = (state) => !!state.users.loading || !!state.users.saving
export const selectReload = (state) => state.users.reload

export const loadUsers = () => (dispatch, getState) => {
  const currentState = getState()
  if (!selectLoading(currentState)) {
    return Promise.all([
      dispatch(doLoadUsers()),
    ])
  }
}

export const updateUsers = (usersData) => (dispatch, getState) => {
  const currentState = getState()
  if (!selectLoading(currentState) && !selectSaving(currentState) && Array.isArray(usersData)) {
    return dispatch(doUpdateUsers(usersData)).then(() => {
      return dispatch(loadUsers())
    })
  }
}

export default usersSlice.reducer
