import React from 'react'
import PropTypes from 'prop-types'
import { mergeStyles } from '@fluentui/react'
import ProgressItem from './ProgressItem'
import { addClassWhenNeeded } from './utils'

const progressClass = mergeStyles({
  marginTop: 0,
  marginRight: 4,
  marginLeft: 4,
})

export function ProgressRenderer (props) {
  addClassWhenNeeded(props)

  const percentComplete = props.value
  return (
    <ProgressItem
      percentComplete={percentComplete}
      className={progressClass}
    />
  )
}

ProgressRenderer.PropsTypes = {
  TD: PropTypes.object,
  value: PropTypes.any,
  cellProperties: PropTypes.object,
}
