const DEF_CUT = 5

export const LOCALE = 'uk-UA'

export const cutItems = (items, key, count = DEF_CUT) => {
  const isCut = items.length > count
  const cutItems = items
    .slice(0, isCut ? count : count + 1)
    .map((item) => key ? item[key] : item)
  if (isCut) {
    cutItems.push('...')
  }
  return cutItems
}

// параметр -> restError = new Error(message)
export const errorFixing = (error, url = '', doNotLog) => {
  new Promise((resolve) => {
    const urlS = url ? `${url}\n` : ''
    const eventDict = {
      error,
      message: `${urlS}${error.message}`,
    }
    const errorEvent = new ErrorEvent('resterror', eventDict)
    resolve(errorEvent)
  }).then((errorEvent) => {
    !doNotLog && window.dispatchEvent(errorEvent)
  })
}

export const serverErrorMessage = (error) => {
  new Promise((resolve) => {
    const eventDict = {
      error,
      message: `Operation failed due to the conflict!\n${error.message}`,
    }
    const errorEvent = new ErrorEvent('resterrormessage', eventDict)
    resolve(errorEvent)
  }).then((errorEvent) => {
    window.dispatchEvent(errorEvent)
  })
}

export const numberFormatting = new Intl.NumberFormat(
  LOCALE,
  { minimumIntegerDigits: 1, minimumFractionDigits: 0, maximumFractionDigits: 6 },
).format

export const empty = () => {}

export const alwaysTrue = () => true

export const alwaysFalse = () => false

export const getItemKey = (item) => item.key

export const formatNumeric = (value, precision) => {
  const format = (precision && precision < 9 && precision >= 0)
    ? { minimumFractionDigits: 0, maximumFractionDigits: precision }
    : undefined
  return value != null ? (value).toLocaleString(LOCALE, format) : ''
}
