import { SPREAD_DEFAULT } from '../../../constants/settings'
import { DEFAULT_SETTINGS_VECTOR_MAP } from '../../../features/settings/settingsSlice'
import { ELEMENT_TYPES } from './constants'

export const extractDisplaySlice = (settings, element) => !settings
  ? settings
  : element.type === ELEMENT_TYPES.VECTOR_MAPS
    ? settings[element.type][element.key] ?? DEFAULT_SETTINGS_VECTOR_MAP
    : settings[element.key]

export const cleanUp = (settings) => {
  const result = {}
  for (const key of Object.keys(settings)) {
    if (key === ELEMENT_TYPES.VECTOR_MAPS) {
      result[key] = cleanUp(settings[key])
    } else {
      const { labeling, spread } = settings[key]
      if (spread) {
        result[key] = { labeling, spread: SPREAD_DEFAULT, attribute: null, coloring: null }
      }
    }
  }
  return result
}

export const toNumber = (value) => {
  const result = +value
  return Number.isNaN(result) || value?.trim() === '' ? null : result
}
