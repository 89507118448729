import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { DefaultButton, PrimaryButton, IconButton } from '@fluentui/react'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import { TextField } from '@fluentui/react/lib/TextField'
import { selectGCNames } from '../../features/bc/bcSlice'

const MAX_DESCRIPTION_LENGTH = 100
const MIN_WIDTH_DIALOG = 480

export const findNewName = (name, names) => {
  if (!names?.includes(name)) {
    return name
  }
  let nextValue
  const regex = /\((\d+)\)$/
  const matches = name.match(regex)
  if (matches && matches.length > 1) {
    const currentIndex = Number.parseInt(matches[1])
    nextValue = `${name.replace(matches[0], '')}(${currentIndex + 1})`
  } else {
    nextValue = `${name} (1)`
  }
  return findNewName(nextValue, names)
}

export const CopyGroupBCDialog = ({
  isShow, onHide, onCreate, groupBC: originalGBCId,
}) => {
  const [ groupBCName, onChangeGroupBCName ] = useState(null)
  const [ freeName, setFreeName ] = useState(null)

  const gbcNames = useSelector(selectGCNames)

  const originalGBCName = useMemo(() => {
    return gbcNames?.find((item) => (
      item.id === originalGBCId
    ))?.name || ''
  }, [ originalGBCId, gbcNames ])
  const names = useMemo(() => {
    return gbcNames?.map((item) => (
      item.name
    ))
  }, [ gbcNames ])

  useEffect(() => {
    if (isShow) {
      onChangeGroupBCName(findNewName(originalGBCName, names))
      setFreeName(null)
    }
  }, [ originalGBCName, names, isShow ])

  const onChangeName = useCallback(({ target }) => {
    const newName = target?.value
    onChangeGroupBCName(newName)
    const freeName = findNewName(newName.trim(), names)
    if (freeName === newName) {
      setFreeName(null)
    } else {
      setFreeName(freeName)
    }
  }, [ onChangeGroupBCName, names ])

  const onOk = useCallback(() => {
    onCreate(originalGBCId, groupBCName)
  }, [ onCreate, originalGBCId, groupBCName ])

  return (
    <Dialog
      hidden={!isShow}
      onDismiss={onHide}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Copy Group Business Case',
        subText: `Create a new Group Business Case from ${originalGBCName}`,
      }}
      minWidth={MIN_WIDTH_DIALOG}
      modalProps={{
        isBlocking: true,
      }}
    >
      <div>
        <TextField
          label={'Group Business Case Name'}
          maxLength={MAX_DESCRIPTION_LENGTH}
          value={groupBCName}
          onChange={onChangeName}
          placeholder={'Enter new name'}
        />
        {freeName
          ? (
              <div>
                <div>
                  {'This name already exists, the recommended name is:'}
                </div>
                <span style={{ color: 'blue' }}>
                  {freeName}
                </span>
                <IconButton
                  iconProps={{ iconName: 'Merge' }}
                  title={'Apply'}
                  onClick={() => onChangeName({ target: { value: freeName } })}
                />
              </div>
            )
          : null
        }
      </div>
      <DialogFooter>
        {onCreate && (
          <PrimaryButton
            onClick={onOk}
            text="Apply"
            disabled={Boolean(freeName) || !(groupBCName && groupBCName.trim())}
          />
        )}
        <DefaultButton onClick={onHide} text="Cancel" />
      </DialogFooter>
    </Dialog>
  )
}

export default CopyGroupBCDialog
