import React, { useMemo } from 'react'
import { Dropdown } from '@fluentui/react'
import EditFieldLabel from '../EditFieldLabel'

import './Dropdown.css'

const DropdownComponent = ({ label, placeholder, description, className, ...props }) => {
  const cn = useMemo(() => {
    const baseClass = 'capex-dropdown-wrapper'
    return className ? `${baseClass} ${className}` : baseClass
  }, [ className ])

  return (
    <div className={cn}>
      <Dropdown
        {...props}
        className={props.readOnly ? 'capex-dropdown capex-dropdown-readOnly' : 'capex-dropdown'}
        placeholder={placeholder || label}
      />
      <EditFieldLabel
        label={label}
        description={description}
        showLabel={true}
      />
    </div>
  )
}

export default DropdownComponent
