import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { activeProject, loadingProjects } from '../../features/projects/projectsSlice'
import { KEYS } from '../Cabinet/constants'
import { selectLoading as selectLoadingAll, selectStages } from '../../features/loading/loadingSlice'
import { selectLoading as selectLoadingTrs } from '../../features/trf/trfSlice'
import { selectLoading as selectLoadingBc } from '../../features/bc/bcSlice'
import Component from './Component'
import {
  TITLE,
} from './constants'

const LoaderProject = ({ onClose, isChanged }) => {
  const project = useSelector(activeProject)
  const loading = useSelector(selectLoadingAll)
  const loadingTRS = useSelector(selectLoadingTrs)
  const loadingBC = useSelector(selectLoadingBc)
  const loadingProject = useSelector(loadingProjects)
  const stages = useSelector(selectStages)

  const sources = useMemo(() => {
    const sources = []
    sources.push({ message: `Loading data in project: ${project?.[KEYS.DESCRIPTION] ?? 'Default'}`, loading: loadingProject })
    if (loading || loadingBC || loadingTRS) {
      sources.push({ message: 'Loading resource:' })
    }
    return sources
  }, [ loading, loadingBC, loadingProject, loadingTRS, project ])

  return (
    <Component
      title={TITLE}
      value={null}
      loadingSource={sources}
      stages={stages}
      onClose={onClose}
      isChanged={isChanged}
    />
  )
}

export default LoaderProject
