import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { checkTreeItemGeo, selectLegendGeo, TOP_INDEX_LEGEND } from '../../../../features/geo/geoSlice'
import { selectVectorItems } from '../../../../features/vector/vectorSlice'
import { selectSettings, saveSettings } from '../../../../features/settings/settingsSlice'
import Subheader from '../../../common/Subheader'
import Toggle from '../../../common/Toggle'
import Checkbox from '../../../common/Checkbox'
import { SECTOR_TYPES_UNIQUE } from '../../../../constants/network'
import { selectUserAccess } from '../../../../features/login/loginSlice'
import { COMPLAINTS } from '../../../../constants/access'

const LEGEND_ITEMS = {
  zones: 'Zones',
  sites: 'Sites',
  ...Object.fromEntries(SECTOR_TYPES_UNIQUE.map((sector) => [ sector, sector ])),
  complaints: 'Complaints',
}

const Legend = () => {
  const dispatch = useDispatch()

  const legendGeo = useSelector(selectLegendGeo)
  const vector = useSelector(selectVectorItems)
  const settings = useSelector(selectSettings).legend
  const userAccess = useSelector(selectUserAccess, shallowEqual)

  const items = useMemo(() => {
    const items = { ...LEGEND_ITEMS }
    if (!userAccess[COMPLAINTS]) {
      delete items.complaints
    }
    vector.forEach((item) => {
      items[item.id] = item.name
    })
    return items
  }, [ vector, userAccess ])

  const handleToggle = useCallback(() => {
    dispatch(checkTreeItemGeo([ TOP_INDEX_LEGEND ]))
  }, [ dispatch ])

  const handleCheck = useCallback((event, value) => {
    const legend = { ...settings }
    legend[event.currentTarget.id] = value
    dispatch(saveSettings({ legend }))
  }, [ dispatch, settings ])

  return (
    <>
      <Subheader text="Show on Map" />
      <Toggle onText="On" offText="Off" checked={legendGeo.state.selected} onChange={handleToggle} />
      <Subheader text="Legend Elements" />
      {Object.keys(items).map((key) => (
        <Checkbox id={key} key={key} label={items[key]} checked={settings[key]} onChange={handleCheck} />
      ))}
    </>
  )
}

export default Legend
