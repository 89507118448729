import React, { useMemo } from 'react'
import { HotTable, HotColumn } from '@handsontable/react'
import { TASK_STATES } from '../../../features/taskLog'
import { IconRenderer, STATUS } from './IconRenderer'
import { ProgressRenderer } from './ProgressRenderer'

const SETTINGS = {
  licenseKey: 'non-commercial-and-evaluation',
  colHeaders: true,
  stretchH: 'all',
  autoRowSize: false,
  autoColumnSize: false,
  manualColumnResize: true,
  readOnly: true,
  wordWrap: false,
  fillHandle: false,
  hiddenColumns: {
    columns: [ 0 ],
    indicators: false,
    copyPasteEnabled: false,
  },
}

const COLUMN_TYPES = {
  ICON: 'icon',
  TIME: 'time',
  PROJECT: 'project',
  DESCRIPTION: 'description',
  PROGRESS: 'progress',
}

const COLUMNS = [
  { id: 'id', data: 'id', width: 15, type: 'text' },
  { id: COLUMN_TYPES.ICON, data: 'icon', width: 15, type: 'text', className: 'htCenter htMiddle' },
  { id: COLUMN_TYPES.TIME, data: 'time', width: 65, type: 'text' },
  { id: COLUMN_TYPES.DESCRIPTION, data: 'description', type: 'text' },
  { id: COLUMN_TYPES.PROGRESS, data: 'progress', type: 'numeric', className: 'htLeft htMiddle' },
]

const COLUMN_HEADERS = [ 'Id', '', 'Time', 'Description', 'Progress' ]

const getIconByStatus = (state) => {
  switch (state) {
    case TASK_STATES.completed:
      return STATUS.DONE
    case TASK_STATES.failed:
      return STATUS.ERROR
    default:
      return STATUS.PROGRESS
  }
}

const TaskTable = ({ refHot, tasks }) => {
  const data = useMemo(() => {
    return tasks
      ? tasks.map((task) => {
        return {
          id: task.id,
          icon: getIconByStatus(task.state),
          time: task.time,
          description: task.description,
          progress: task.progress,
        }
      })
      : []
  }, [ tasks ])

  return (
    <HotTable
      ref={refHot}
      data={data}
      colHeaders={COLUMN_HEADERS}
      settings={SETTINGS}
      style={{
        height: '100%',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      {COLUMNS.map(({ id, ...settings }) => {
        return (
          <HotColumn
            key={id}
            data={id}
            settings={settings}
          >
            {id === COLUMN_TYPES.ICON ? <IconRenderer hot-renderer/> : null}
            {id === COLUMN_TYPES.PROGRESS ? <ProgressRenderer hot-renderer/> : null}
          </HotColumn>
        )
      })}
    </HotTable>
  )
}

export default TaskTable
