import {
  ENUM_TECHNOLOGY, RBS_COMPOSITE_INDEX_FIELD, RBS_ID_FIELD, RBS_NAME_FIELD, RBS_SITE_FIELD, RBS_SITE_NAME_FIELD,
  SECTOR_COMPOSITE_INDEX_FIELD, SECTOR_ID_FIELD, SECTOR_NAME_FIELD, SECTOR_RBS_FIELD, SECTOR_RBS_NAME_FIELD,
  SECTOR_SITE_FIELD, SECTOR_SITE_NAME_FIELD, SITE_COMPOSITE_INDEX_FIELD, SITE_ID_FIELD, SITE_NAME_FIELD,
} from '../../constants/network'

export const TITLE = 'Composite index'

export const EXECUTION_STATE = {
  INIT: 'init',
  INIT_RESET: 'initReset',
  INIT_DATA: 'initData',
  INIT_STATISTIC: 'initStatistic',
  INIT_TEMPLATES: 'initTemplates',
  CALCULATION: 'calculation',
  CALCULATED: 'calculated',
  EDIT: 'edit',
  VIEW: 'view',
  ERROR: 'error',
}

export const TITLE_STATE = {
  [EXECUTION_STATE.INIT]: 'Init',
  [EXECUTION_STATE.INIT_RESET]: 'Init Reset',
  [EXECUTION_STATE.INIT_DATA]: 'Load, init data',
  [EXECUTION_STATE.INIT_STATISTIC]: 'Calculate Statistic',
  [EXECUTION_STATE.INIT_TEMPLATES]: 'Load Templates',
  [EXECUTION_STATE.CALCULATION]: 'Calculation',
  [EXECUTION_STATE.CALCULATED]: 'Calculated',
  [EXECUTION_STATE.EDIT]: 'Edit',
  [EXECUTION_STATE.VIEW]: 'View',
  [EXECUTION_STATE.ERROR]: 'Error',
}

export const defaultTemplate = {
  key: 'default',
  text: 'Default template',
  description: 'Default template for composite index',
}

export const defaultTemplateData = {
  site: [],
  rbs: [],
  sectors: [],
}

export const ELEMENTS = {
  SITES: 'sites',
  RBS: 'rbs',
  SECTORS: 'sectors',
}

export const optionsElement = {
  [ELEMENTS.SITES]: {
    key: ELEMENTS.SITES,
    text: 'Sites',
    value: [ 'Sites' ],
  },
  [ELEMENTS.RBS]: {
    key: ELEMENTS.RBS,
    text: 'RBS',
    value: [ 'Sites', 'RBS' ],
  },
  [ELEMENTS.SECTORS]: {
    key: ELEMENTS.SECTORS,
    text: 'Sectors',
    value: [ 'Sites', 'RBS', 'Sectors' ],
  },
}

export const statisticHeader = [
  'Technology',
  // 'RBSs',
  'Sectors',
]

// список строчем таблицы статистики
export const statisticRow = [
  ENUM_TECHNOLOGY.GSM900,
  ENUM_TECHNOLOGY.GSM1800,
  ENUM_TECHNOLOGY.UMTS2100,
  ENUM_TECHNOLOGY.LTE900,
  ENUM_TECHNOLOGY.LTE1800,
  ENUM_TECHNOLOGY.LTE2100,
  ENUM_TECHNOLOGY.LTE2300,
  ENUM_TECHNOLOGY.LTE2600,
]

export const monthOptions = [
  { key: '0', text: 'Current project', value: 0 },
  { key: '1', text: '1 month', value: 1 },
  { key: '2', text: '2 months', value: 2 },
  { key: '3', text: '3 months', value: 3 },
  { key: '4', text: '4 months', value: 4 },
  { key: '5', text: '5 months', value: 5 },
  { key: '6', text: '6 months', value: 6 },
  { key: '7', text: '7 months', value: 7 },
  { key: '8', text: '8 months', value: 8 },
  { key: '9', text: '9 months', value: 9 },
  { key: '10', text: '10 months', value: 10 },
  { key: '11', text: '11 months', value: 11 },
  { key: '12', text: '12 months', value: 12 },
  { key: '15', text: '15 months', value: 15 },
  { key: '18', text: '18 months', value: 18 },
  { key: '21', text: '21 months', value: 21 },
  { key: '24', text: '24 months', value: 24 },
]

/* export const defTemplateOptions = [
  { key: 'default', text: 'Default Template' },
] */

export const titleMonth =
  'The number of previous months (from the current date) that must be taken into account in the calculation'

export const titleTemplate = 'List of available templates'

export const columnSettingRate = {
  minWidth: 70,
  maxWidth: 80,
  isResizable: true,
  isSorted: false,
  isSortedDescending: false,
}

export const columnsRate = {
  sites: [
    {
      key: ELEMENTS.SITES,
      name: 'Sites',
      fieldName: '0',
      id: SITE_NAME_FIELD,
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: 'rate',
      name: 'Rate',
      fieldName: '1',
      id: 'RATE',
      ...columnSettingRate,
    },
  ],
  rbs: [
    {
      key: ELEMENTS.SITES,
      name: 'Sites',
      fieldName: '0',
      id: RBS_SITE_NAME_FIELD,
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: ELEMENTS.RBS,
      name: 'RBS',
      fieldName: '1',
      id: RBS_NAME_FIELD,
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: 'rate',
      name: 'Rate',
      fieldName: '2',
      id: 'RATE',
      ...columnSettingRate,
    },
  ],
  sectors: [
    {
      key: ELEMENTS.SITES,
      name: 'Sites',
      fieldName: '0',
      id: SECTOR_SITE_NAME_FIELD,
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: ELEMENTS.RBS,
      name: 'RBS',
      fieldName: '1',
      id: SECTOR_RBS_NAME_FIELD,
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: ELEMENTS.SECTORS,
      name: 'Sectors',
      fieldName: '2',
      id: SECTOR_NAME_FIELD,
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
    },
    {
      key: 'rate',
      name: 'Rate',
      fieldName: '3',
      id: 'RATE',
      ...columnSettingRate,
    },
  ],
}

export const columnsResultRate = {
  sites: [
    SITE_COMPOSITE_INDEX_FIELD,
    SITE_ID_FIELD,
  ],
  rbs: [
    RBS_COMPOSITE_INDEX_FIELD,
    RBS_SITE_FIELD,
    RBS_ID_FIELD,
  ],
  sectors: [
    SECTOR_COMPOSITE_INDEX_FIELD,
    SECTOR_SITE_FIELD,
    SECTOR_RBS_FIELD,
    SECTOR_ID_FIELD,
  ],
}

export const siteNameForElement = {
  sites: SITE_NAME_FIELD,
  rbs: RBS_SITE_NAME_FIELD,
  sectors: SECTOR_SITE_NAME_FIELD,
}

export const columnsTableRate = {
  sites: [
    {
      id: SITE_NAME_FIELD,
      name: 'Sites',
    },
  ],
  rbs: [
    {
      id: RBS_SITE_NAME_FIELD,
      name: 'Sites',
    },
    {
      id: RBS_NAME_FIELD,
      name: 'RBS',
    },
  ],
  sectors: [
    {
      id: SECTOR_SITE_NAME_FIELD,
      name: 'Sites',
    },
    {
      id: SECTOR_RBS_NAME_FIELD,
      name: 'RBS',
    },
    {
      id: SECTOR_NAME_FIELD,
      name: 'Sector',
    },
  ],
}

export const LABELS_BY_STAGE = [
  'Initialization',
  'First stage of calculation',
  'Second stage of calculation',
  'Third stage of calculation',
  'Sorting by index ',
  'Sorted ',
  'End',
]
