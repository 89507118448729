import React, { useEffect } from 'react'
import {
  useParams,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { initNetwork } from '../features/network/networkSlice'
import { loadProject, selectForceProjectReload } from '../features/projects/projectsSlice'
import { setProjectSettings } from '../features/settings/settingsSlice'
import ConfirmEnsureProject from '../components/Cabinet/Project/ConfirmEnsureProject'
import { Loader } from '../components/Loader'
import SavingChanges from '../components/dialogs/SavingChanges'
import { selectLoading as selectLoadingAll, selectSavingChanges } from '../features/loading/loadingSlice'
import Workspace from './Workspace'

const Project = () => {
  const dispatch = useDispatch()

  const savingChanges = useSelector(selectSavingChanges)
  const forceReload = useSelector(selectForceProjectReload)

  const { projectId } = useParams()

  useEffect(() => {
    dispatch(loadProject(projectId))
      .then(() => dispatch(setProjectSettings))
      .then(() => dispatch(initNetwork(forceReload)))
  }, [ dispatch, projectId, forceReload ])

  const loading = useSelector(selectLoadingAll)

  return (
    <>
      {loading && (
        <Loader />
      )}
      {savingChanges && (
        <SavingChanges />
      )}
      <ConfirmEnsureProject />
      <Workspace />
    </>
  )
}

export default Project
