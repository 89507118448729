import { Stack, StackItem } from '@fluentui/react'
import React, { useCallback, useState } from 'react'
import { useHandler } from '../../../hooks'
import { empty } from '../../../utils/format'
import { PrimaryButton, SecondaryButton } from '../../common/Button'
import TextField from '../../common/TextField'
import TextFieldSearch from '../../common/TextField/TextFieldSearch'
import AdditionalSettings from './AdditionalSettings'

import './Search.css'

const MAX_SEARCH_LENGTH = 128
const ENTER_KEY = 13

const initialOptions = {
  searchBy: 'cols',
  matchCase: false,
  entireCell: false,
}

const prepare = (value) => {
  value = value.trim()
  if (value) {
    const tabPos = value.indexOf('\t')
    const nlPos = value.indexOf('\n')
    const crPos = value.indexOf('\r')
    let minPos = -1
    if (tabPos >= 0 && (minPos < 0 || minPos > tabPos)) {
      minPos = tabPos
    }
    if (nlPos >= 0 && (minPos < 0 || minPos > nlPos)) {
      minPos = nlPos
    }
    if (crPos >= 0 && (minPos < 0 || minPos > crPos)) {
      minPos = crPos
    }
    if (minPos >= 0) {
      value = value.slice(minPos)
    }
  }
  return value.slice(0, MAX_SEARCH_LENGTH)
}

const Search = ({ onSearch, onReplace }) => {
  const [ options, setOptions ] = useState(initialOptions)

  const [ searchText, setSearchText ] = useState('')
  const [ replaceText, setReplaceText ] = useState('')

  const onChangedText = useCallback((_, value) => {
    setSearchText(value)
  }, [])

  const onReplaceChanged = useCallback((_, value) => {
    setReplaceText(value)
  }, [])

  const handleSearch = useCallback(() => {
    onSearch(prepare(searchText), options.searchBy, options.matchCase, options.entireCell)
  }, [ onSearch, searchText, options ])

  const handleSearchPrev = useCallback(() => {
    onSearch(prepare(searchText), options.searchBy, options.matchCase, options.entireCell,
      undefined, undefined, undefined, undefined, true)
  }, [ onSearch, searchText, options ])

  const handleSearchKeyDown = useHandler((event) => {
    if (event.keyCode === ENTER_KEY) {
      handleSearch()
      event.target.focus()
    }
  })

  const handleReplace = useCallback(() => {
    onReplace(prepare(searchText), replaceText, options.searchBy, options.matchCase,
      options.entireCell, false)
  }, [ onReplace, searchText, replaceText, options ])

  const handleReplaceAll = useCallback(() => {
    onReplace(prepare(searchText), replaceText, options.searchBy, options.matchCase,
      options.entireCell, true)
  }, [ onReplace, searchText, replaceText, options ])

  const handleReplaceKeyDown = useCallback((event) => {
    if (event.keyCode === ENTER_KEY) {
      handleReplace()
      event.target.focus()
    }
  }, [ handleReplace ])

  const onSearchByChange = useCallback((event, { key: searchBy }) => {
    setOptions({ ...options, searchBy })
  }, [ options, setOptions ])

  const onMatchCaseChange = useCallback((event, matchCase) => {
    setOptions({ ...options, matchCase })
  }, [ options, setOptions ])

  const onEntireCellChange = useCallback((event, entireCell) => {
    setOptions({ ...options, entireCell })
  }, [ options, setOptions ])

  return (
    <Stack veticalFill={true} grow={true} className={'overflow-auto'} >
      <StackItem verticalFill={true} grow={true} className={'overflow-auto'}>
        <div className='searchFieldContainer'>
          <TextFieldSearch
            label={'Find what'}
            placeholder={'Find what'}
            maxLength={255}
            value={searchText}
            onChange={onChangedText}
            onKeyDown={handleSearchKeyDown}
            onPaste={empty}
            searchNext={handleSearch}
            searchPrev={handleSearchPrev}
          />
          {onReplace && (
            <TextField
              className={'replaceField'}
              label={'Replace with'}
              placeholder={'Replace with'}
              maxLength={255}
              value={replaceText}
              onChange={onReplaceChanged}
              onKeyDown={handleReplaceKeyDown}
              onPaste={empty}
            />
          )}
        </div>
        <AdditionalSettings
          searchBy={options.searchBy}
          matchCase={options.matchCase}
          entireCell={options.entireCell}
          onSearchByChange={onSearchByChange}
          onMatchCaseChange={onMatchCaseChange}
          onEntireCellChange={onEntireCellChange}
        />
      </StackItem>
      <Stack className={'large-pad section'} tokens={{ childrenGap: 12 }} vertical >
        {onReplace && (
          <Stack tokens={{ childrenGap: 12 }} horizontal>
            <SecondaryButton
              className={'search-button'}
              text={'Replace All'}
              title={'Replace All'}
              onClick={handleReplaceAll}
            />
            <PrimaryButton
              className={'search-button'}
              text={'Replace'}
              title={'Replace'}
              onClick={handleReplace}
            />
          </Stack>
        )}
        <Stack tokens={{ childrenGap: 12 }} horizontal >
          <PrimaryButton
            className={'search-button'}
            text={'Previous'}
            title={'Search previous'}
            onClick={handleSearchPrev}
          />
          <PrimaryButton
            className={'search-button'}
            text={'Next'}
            title={'Search next'}
            onClick={handleSearch}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Search
