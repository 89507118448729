import React from 'react'
import cn from 'classnames'
import style from './style.module.scss'

export const WebkitScrollbar = ({
  children,
  className,
  top = '0px',
  bottom = '0px',
  left = '0px',
  right = '0px',
}) => (
  <div className={cn(style.overflow1, className)}>
    <div className={`${style.overflow2}`}>
      <div
        className={`${style.scrollbar} ${style.col}`}
          style={{
            '--scrollbar-top-offset': top,
            '--scrollbar-bottom-offset': bottom,
            '--scrollbar-left-offset': left,
            '--scrollbar-right-offset': right,
          }}
      >
        {children}
      </div>
    </div>
  </div>
)

export const WebkitScrollbarV = ({
  children,
  className,
  top = '0px',
  bottom = '0px',
  outside = false,
  noShrink = false,
}) => (
  <div
    className={cn(style.overflowV, className, {
      [style.outside]: outside,
      [style.noShrink]: noShrink,
    })}
  >
    <div
      className={`${style.scrollbar} ${style.col}`}
      style={{ '--scrollbar-top-offset': top, '--scrollbar-bottom-offset': bottom }}
    >
      {children}
    </div>
  </div>
)

export const WebkitScrollbarH = ({
  children,
  className,
  left = '0px',
  right = '0px',
  outside = false,
}) => (
  <div
    className={cn(style.overflowH, className, {
      [style.outside]: outside,
    })}
  >
    <div
      className={`${style.scrollbar} ${style.row}`}
      style={{ '--scrollbar-left-offset': left, '--scrollbar-right-offset': right }}
    >
      {children}
    </div>
  </div>
)
