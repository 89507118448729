import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTab, setAdditionalPanel, setTab } from '../../../features/panel/panelSlice'
import Pivot, { FW_TABS_2 } from '../../common/Pivot'
import MapStyle from './MapStyle'
import Colors from './Colors'

const Customization = ({ confirmClose, additionalPanelRef }) => {
  const dispatch = useDispatch()

  const tab = useSelector(selectTab) || 'map-style'

  const handleLinkClick = useCallback((item) => {
    dispatch(setTab(item.props.itemKey))
    dispatch(setAdditionalPanel(null))
  }, [ dispatch ])

  return (
    <Pivot selectedKey={tab} onLinkClick={handleLinkClick} className={FW_TABS_2}>
      <Pivot.Item headerText="Map Style" itemKey="map-style">
        <MapStyle />
      </Pivot.Item>
      <Pivot.Item headerText="Colors" itemKey="colors">
        <Colors confirmClose={confirmClose} additionalPanelRef={additionalPanelRef} />
      </Pivot.Item>
    </Pivot>
  )
}

export default Customization
