import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { GeoJSON } from 'react-leaflet'
import L from 'leaflet'
import {
  checkIndicateItem,
  selectVectorIndicate,
  selectVectorJSONs,
} from '../../../../features/vector/vectorSlice'
import { reverseLngLat } from '../../utils'

const styleIndicateBlink = {
  color: `rgb(${255}, ${20}, ${80})`,
  fillColor: `rgb(${255}, ${20}, ${80})`,
}

const styleIndicate = {
  color: `rgb(${255}, ${20}, ${80})`,
  fillColor: `rgb(${80}, ${20}, ${255})`,
}

const TIME_BLINK = 500
const COUNT_BLINK = 4

const IndicateVector = ({ map }) => {
  const [ blink, setBlink ] = useState(0)
  const dispatch = useDispatch()
  const vectorMaps = useSelector(selectVectorJSONs, shallowEqual)
  const indicate = useSelector(selectVectorIndicate)

  const indicateVector = useMemo(() => {
    let indicateVector = null
    if (indicate && Array.isArray(vectorMaps)) {
      const { childrenId, vectorMapId } = indicate
      vectorMaps.some((vectorMap) => {
        if (vectorMap.id !== vectorMapId) {
          return false
        }
        const features = vectorMap?.json?.features
        if (!Array.isArray(features)) {
          return false
        }
        return features.some((feature) => {
          if (feature.id === childrenId) {
            indicateVector = feature
            setBlink(COUNT_BLINK)
            return true
          }
          return false
        })
      })
    }
    return indicateVector
  }, [ indicate, vectorMaps, setBlink ])

  useEffect(() => {
    if (map && indicateVector) {
      const type = indicateVector.geometry?.type
      const coordinates = indicateVector.geometry?.coordinates
      if (!Array.isArray(coordinates)) {
        return
      }
      const coords = reverseLngLat(coordinates)
      const featureBounds = type === 'Point' ? L.latLng(coords) : L.latLngBounds(coords)
      const mapBounds = map.getBounds()
      if (!mapBounds.contains(featureBounds)) {
        map.flyToBounds(featureBounds, { animate: false })
      }
    }
  }, [ map, indicateVector ])

  useEffect(() => {
    let timer
    if (blink) {
      timer = setTimeout(() => {
        const newBlink = blink - 1
        if (!newBlink) {
          dispatch(checkIndicateItem(null))
        }
        setBlink(newBlink)
      }, TIME_BLINK)
    }
    return () => clearTimeout(timer)
  }, [ indicate, blink, dispatch ])

  return indicateVector
    ? (
        <GeoJSON
          key={`${indicate.vectorMapId}:${indicate.childrenId}`}
          data={indicateVector}
          pane="overlayPane"
          style={(blink % 2) ? styleIndicateBlink : styleIndicate}
        />
      )
    : null
}

export default IndicateVector
