import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  panel: null,
  tab: null,
  additionalPanel: null,
  resolve: null,
  refill: false,
}

const panelSlice = createSlice({
  name: 'panel',
  initialState,
  reducers: {
    setPanel: (state, action) => {
      if (state.panel !== action.payload) {
        state.panel = action.payload
        state.additionalPanel = null
        state.tab = null
      }
    },
    setAdditionalPanel: (state, action) => {
      state.additionalPanel = action.payload
    },
    setTab: (state, action) => {
      state.tab = action.payload
    },
    setResolve: (state, action) => {
      state.resolve = action.payload
    },
    setRefill: (state, action) => {
      state.refill = action.payload
    },
  },
})

const { setResolve, setRefill } = panelSlice.actions

export const { setPanel, setTab, setAdditionalPanel } = panelSlice.actions

export const selectPanel = (state) => state.panel.panel
export const selectAdditionalPanel = (state) => state.panel.additionalPanel
export const selectTab = (state) => state.panel.tab
export const selectRefill = (state) => state.panel.refill
export const selectResolve = (state) => state.panel.resolve

export const checkSaveChanges = (panelId) => (dispatch, getState) => {
  const state = getState()
  if (selectPanel(state) === panelId) {
    return new Promise((resolve) => {
      dispatch(setResolve(resolve))
    }).then(() => {
      dispatch(setResolve(null))
    })
  }
}

export const confirmedClosePanel = (panelId) => async (dispatch) => {
  await dispatch(checkSaveChanges(panelId))
  return dispatch(setPanel(null))
}

export const confirmedRefillPanel = (panelId, refill) => async (dispatch, getState) => {
  await dispatch(checkSaveChanges(panelId))
  const state = getState()
  if (selectPanel(state) === panelId) {
    await dispatch(setRefill(true))
    await refill()
    return dispatch(setRefill(false))
  }
}

export default panelSlice.reducer
