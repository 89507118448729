import {
  DEGREE_DECIMAL_LETTER, DEGREE_DECIMAL_SIGN, DEGREE_MINUTE_SECOND_ICON, DEGREE_MINUTE_SECOND_LETTER,
} from '../constants/geo'

// Еквівалентність двох географічних точок
export const eqPoint = ([ lng1, lat1 ], [ lng2, lat2 ]) => lng1 === lng2 && lat1 === lat2

// Глибина вкладеності масивів
export const arrayDepth = (rings) => Array.isArray(rings) ? arrayDepth(rings[0]) + 1 : 0

// Серіалізація/десеріалізація координат
const ITEM_DIVIDER = '\t'
const POINT_DIVIDER = '\n'
const RING_DIVIDER = '\n---\n'
const PART_DIVIDER = '\n===\n'

const PRECISION_DIF = 5

const SINGS = {
  lng: [ 'E', 'W' ],
  lat: [ 'N', 'S' ],
}

const SYMBOLS = {
  icon: [ '°', "'", '"' ],
  letter: [ 'd', 'm', 's' ],
}

// Format coordinates
export const formatCoordinate = (value, precision) => {
  if (typeof value !== 'number') {
    if (typeof value === 'string') {
      value = value.replace(',', '.')
    }
    value = Number(value)
    if (isNaN(value)) {
      value = 0
    }
  }
  return value.toFixed(precision)
}

const breakCoordinate = (value) => {
  const degree = Math.trunc(value)
  const frac = (value - degree) * 60
  const minute = Math.trunc(frac)
  const second = (frac - minute) * 60
  return [ degree, minute, second ]
}

const degreeWithSign = (value, pSign, nSign, symbolDegree, symbolMinute, symbolSecond, precision) => {
  let [ degree, minute, second ] = breakCoordinate(Math.abs(value))
  degree = `${degree}${symbolDegree}`
  minute = `${minute}${symbolMinute}`
  second = `${formatCoordinate(second, Math.max(precision - PRECISION_DIF, 0))}${symbolSecond}`
  return `${degree}${minute}${second}${value >= 0 ? pSign : nSign}`
}

const toFixedWithSign = (value, pSign, nSign, precision) =>
  `${formatCoordinate(Math.abs(value), precision)}${value >= 0 ? pSign : nSign}`

export const formatItem = (value, type, format) => {
  if (value == null) {
    return ''
  }
  switch (format.coordinatesEncoding) {
    case DEGREE_MINUTE_SECOND_ICON: { // 'xx°xx\'xx.xxx"S'
      return degreeWithSign(value, ...SINGS[type], ...SYMBOLS.icon, format.coordinatesPrecision)
    }
    case DEGREE_MINUTE_SECOND_LETTER: { // 'xxdxxmxx.xxxsS'
      return degreeWithSign(value, ...SINGS[type], ...SYMBOLS.letter, format.coordinatesPrecision)
    }
    case DEGREE_DECIMAL_LETTER: { // 'xx.xxxxxxS'
      return toFixedWithSign(value, ...SINGS[type], format.coordinatesPrecision)
    }
    default: { // DEGREE_DECIMAL_SIGN: '-xx.xxxxxx'
      return formatCoordinate(value, format.coordinatesPrecision)
    }
  }
}

export const formatPoint = ([ lng, lat ], format) => {
  return `${formatItem(lng, 'lng', format)}${ITEM_DIVIDER}${formatItem(lat, 'lat', format)}`
}

export const serializeGeometry = (geometry, format) => {
  const recursiveSerializeGeometry = (geometry) => {
    const depth = arrayDepth(geometry)
    if (depth === 1) {
      return formatPoint(geometry, format)
    } else if (depth === 2) {
      return geometry.map(recursiveSerializeGeometry).join(POINT_DIVIDER)
    } else if (depth === 3) {
      return geometry.map(recursiveSerializeGeometry).join(RING_DIVIDER)
    } else {
      return geometry.map(recursiveSerializeGeometry).join(PART_DIVIDER)
    }
  }

  return `${recursiveSerializeGeometry(geometry)}${POINT_DIVIDER}`
}

const REGEXP = {
  [DEGREE_MINUTE_SECOND_ICON]: /^(\d{1,3})\s?°\s?(\d{1,2})\s?'\s?(\d{1,2})[.,]?(\d{0,}?)\s?"\s?([NSWE])$/gi,
  [DEGREE_MINUTE_SECOND_LETTER]: /^(\d{1,3})\s?d\s?(\d{1,2})\s?m\s?(\d{1,2})[.,]?(\d{0,}?)\s?s\s?([NSWE])$/gi,
  [DEGREE_DECIMAL_LETTER]: /^(\d{1,3})[.,]?(\d{0,}?)\s?([NSWE])$/gi,
  [DEGREE_DECIMAL_SIGN]: /^([+-]?)(\d{1,3})[.,]?(\d{0,}?)$/gi,
}

export const validateLng = (lng) => lng !== null && lng >= -180 && lng <= 180

export const validateLat = (lat) => lat !== null && lat >= -90 && lat <= 90

export const parseItem = (str) => {
  if (!str) {
    return null
  }
  let result = null
  for (const [ key, value ] of Object.entries(REGEXP)) {
    const m = new RegExp(value).exec(str.trim())
    if (m !== null) {
      switch (key) {
        case DEGREE_MINUTE_SECOND_ICON: // 'xx°xx\'xx.xxx"S'
        case DEGREE_MINUTE_SECOND_LETTER: { // 'xxdxxmxx.xxxsS'
          const degree = parseInt(m[1])
          const minute = parseInt(m[2])
          const second = parseInt(m[3]) + (m[4] ? parseInt(m[4]) / Math.pow(10, m[4].length) : 0)
          if (degree >= 0 && degree <= 180 && minute >= 0 && minute < 60 && second >= 0 && second < 60) {
            result = degree + minute / 60 + second / 3600
            if ([ 's', 'S', 'w', 'W' ].includes(m[5])) {
              result = -result
            }
          }
          break
        }
        case DEGREE_DECIMAL_LETTER: { // 'xx.xxxxxxS'
          const value = parseInt(m[1]) + (m[2] ? parseInt(m[2]) / Math.pow(10, m[2].length) : 0)
          if (value >= 0 && value <= 180) {
            result = value
            if ([ 's', 'S', 'w', 'W' ].includes(m[3])) {
              result = -result
            }
          }
          break
        }
        default: { // DEGREE_DECIMAL_SIGN: '-xx.xxxxxx'
          const value = parseInt(m[2]) + (m[3] ? parseInt(m[3]) / Math.pow(10, m[3].length) : 0)
          if (value >= 0 && value <= 180) {
            result = value
            if (m[1] === '-') {
              result = -result
            }
          }
        }
      }
      break
    }
  }
  return result
}

export const parsePoint = (str) => {
  if (!str) {
    return null
  }
  const point = str.trim().split(ITEM_DIVIDER).map(parseItem)
  return point[0] !== null && point[1] !== null ? point : null
}

export const parsePolygon = (text) => {
  if (!text) {
    return null
  }
  const points = text.trim()
    .split(POINT_DIVIDER)
    .map(parsePoint)
  if (!points.every(Boolean) || points.length < 3) {
    return null
  }
  if (!eqPoint(points[0], points[points.length - 1])) {
    points.push(points[0])
  }
  return points.length >= 4 ? points : null
}

export const parseRing = (text) => {
  if (!text) {
    return null
  }
  const polies = text.trim()
    .split(RING_DIVIDER)
    .map(parsePolygon)
  return !polies.every(Boolean) || !polies.length ? null : polies
}

export const parseGeometry = (text) => {
  if (!text) {
    return null
  }
  const rings = text.trim().split(PART_DIVIDER)
  if (!rings.length || !rings[0]) {
    return null
  }
  const polygons = rings
    .map(parseRing)

  return !polygons.every(Boolean) || !polygons.length ? null : polygons
}
