import { addTask, updateTaskByType, updateTask } from './taskLogSlice'
import { TASK_STATES } from './constants'

export const addTaskType = (type, name, id) => async (dispatch) => {
  dispatch(addTask({ type, name, id }))
}

export const setTaskTypeFailed = (type) => async (dispatch) => {
  dispatch(updateTaskByType({ type, state: TASK_STATES.failed }))
}

export const setTaskTypeCompleted = (type) => async (dispatch) => {
  dispatch(updateTaskByType({ type, state: TASK_STATES.completed }))
}

export const setTaskFailed = (id) => async (dispatch) => {
  dispatch(updateTask({ id, state: TASK_STATES.failed }))
}

export const setTaskCompleted = (id) => async (dispatch) => {
  dispatch(updateTask({ id, state: TASK_STATES.completed }))
}
