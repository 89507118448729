import Supercluster from 'supercluster'
import { featureCollection, polygon } from '@turf/helpers'
import clustersDbscan from '@turf/clusters-dbscan'
import convex from '@turf/convex'
import buffer from '@turf/buffer'
import union from '@turf/union'
import bbox from '@turf/bbox'
import { CLUSTER_RADIUS } from '../../constants/geo'
import { PAIN_ZONE_PARAMS } from '../../constants/default'

export const calculatePainZones = (features, zoom, resolution, sensitivity, weight, weightMode, bBox) => {
  // Будуємо суперкластер для візуалізації скарг на мінікарті форми розрахунку пейн-зон
  let superCluster
  if (bBox) {
    superCluster = new Supercluster({ radius: CLUSTER_RADIUS })
    superCluster.load(features)
  }

  // Підрахуємо загальну вагу скарг
  let totalWeight
  if (weightMode) {
    totalWeight = features.reduce((acc, { properties: { weight } }) => acc + (Number(weight) || 0), 0)
  }

  // Визначаємо кластери для візуалізації на мінікарті, відкидаємо одиничні скарги, щоб не засмічували вид
  let visualClusters
  if (bBox) {
    visualClusters = superCluster
      .getClusters(bBox, Math.round(zoom))
      .filter(({ properties: { cluster } }) => cluster)
  }

  // Кластеризуємо скарги методом DBSCAN
  const clusters = clustersDbscan(featureCollection(features), resolution / 1000, { minPoints: 2 })

  // Будуємо список ідентифікаторів кластерів, відкидаємо одиничні кластери
  const clusterIds = [ ...new Set(clusters.features.map(({ properties: { cluster } }) => cluster)) ]
    .filter((id) => id !== undefined)

  if (clusterIds.length === 0) {
    return bBox
      ? [ [], visualClusters ]
      : [ [], null, null ]
  }

  // Визначаємо кількість скарг у кожному кластері, вагу кластера, відсоток ваги кластера від загальної ваги скарг
  // Також визначаємо список скарг, що входять до кожного кластера
  let clusterData = clusterIds.map((id) => {
    const features = clusters.features.filter(({ properties: { cluster } }) => cluster === id)
    const weight = features.reduce((acc, { properties: { weight } }) => acc + (Number(weight) || 0), 0)
    const accidents = features.reduce((acc, { properties: { accidents } }) => acc + (Number(accidents) || 0), 0)
    return ({
      id,
      weight,
      accidents,
      percent: totalWeight === 0 ? 0 : Math.round(weight / totalWeight * 10000) / 100,
      complaints: features.length,
      features,
    })
  })
    // Сортуємо кластери за спаданням кількості скарг
    .sort((a, b) => b.complaints - a.complaints)

  // Відкидаємо кластери, щільність яких (за вагою чи кількістю) менше заданого користувачем граничного значення
  if (weightMode) {
    clusterData = clusterData.filter(({ percent }) => percent >= weight)
  } else {
    const maxCount = clusterData[0].complaints
    clusterData = clusterData
      .filter(({ complaints }) => complaints >= (maxCount - 2) * sensitivity / PAIN_ZONE_PARAMS.sensitivity.max + 2)
  }

  // Будуємо полігони кластерів із буфером відповідної ширини
  clusterData = clusterData
    .map((item) => {
      const conv = item.features.length < 4 ? null : convex(featureCollection(item.features), { concavity: 1 })
      return {
        ...item,
        polygon: conv
          ? buffer(conv, resolution / 2, { units: 'meters' })
          : buffer(featureCollection(item.features), resolution / 2, { units: 'meters' }).features
            .reduce((acc, item) => union(acc, item), polygon([])),
      }
    })

  // Формуємо список зон
  const zones = clusterData.map(({ polygon }) => polygon)

  return bBox
    ? [ zones, visualClusters ]
    : [ zones, bbox(featureCollection(zones)), clusterData ]
}
