import React, { useCallback } from 'react'
import { Stack, DetailsRow, DefaultButton } from '@fluentui/react'
import { SelectionMode } from '@fluentui/utilities'
import { classFooter, stylesRowFooter } from './styles'

import './style.css'

const TFooter = ({ onApply, props, disabled, columns }) => {
  const onClickHandler = useCallback((item) => () => {
    onApply && onApply(item)
  }, [ onApply ])

  return (
    <Stack>
      {(Array.isArray(props)
        ? props
        : [ props ])
        .map((item) => {
          const controlColumn = columns.find((column) => (column.key === item.controlColumn))
          const enabledFooter = controlColumn ? controlColumn.indeterminate || controlColumn.select : false

          return (
            <DefaultButton
              disabled={disabled && !enabledFooter}
              key={item?.key}
              uniqueId={item?.key}
              style={{
                width: '100%',
                whiteSpace: 'pre-wrap',
              }}
              text={item?.title}
              onClick={onClickHandler(item)}
              allowDisabledFocus
            />
          )
        })
      }
    </Stack>
  )
}

const Footer = ({ props, onApply, footer }) => {
  const columns = props.columns

  const renderItemColumn = useCallback((props, index, column) => {
    if (column?.fieldName == null || props?.[column.fieldName] == null) {
      return null
    }

    return (
    <TFooter
      disabled={!column?.data?.isChecked && !column?.data?.isIndeterminate}
      key={column.key}
      props={props[column.fieldName]}
      columns={columns}
      onApply={onApply}
    />)
  }, [ onApply, columns ])

  if (props) {
    return (
      <div className={classFooter.row}>
        <DetailsRow
          className={'update-footer'}
          styles={stylesRowFooter}
          onRenderItemColumn={renderItemColumn}
          item={footer}
          itemIndex={-1}
          selectionMode={SelectionMode.none}
          columns={columns}
          rowWidth={props.rowWidth}
        />
      </div>
    )
  }
  return null
}

export default Footer
