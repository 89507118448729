import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import TopNavButton from '../components/TopNavMenu/TopNavButton'
import { CloseIcon, FullscreenTableIcon, HideTableIcon } from '../components/common/icons/names'
import { selectAdditionalPanel, selectPanel, setAdditionalPanel, setPanel } from '../features/panel/panelSlice'
import { selectOfflineMode } from '../features/loading/loadingSlice'
import { findMenuItem } from '../constants/menus'
import Customization from '../components/Panels/Customization'
import ImportedMaps from '../components/Panels/ImportedMaps'
import Network from '../components/Panels/Network'
import SearchMap from '../components/Panels/SearchMap'
import Zones from '../components/Panels/Zones'
import { ColumnsContainer as Columns } from '../components/Panels/Columns'
import { SearchContainer as Search } from '../components/Panels/Search'
import { ReplaceContainer as Replace } from '../components/Panels/Replace'
import NetworkItemEditor from '../components/Records/NetworkItemEditor'
import BCSummaryPanel from '../components/BusinessCases/BCSummaryPanel'
import { IconButton } from '../components/common/Button'

import './Panel.css'

const panels = {
  Customization,
  ImportedMaps,
  Network,
  SearchMap,
  Zones,
  Columns,
  NetworkItemEditor,
  BCSummaryPanel,
  Search,
  Replace,
}

const PanelHeader = ({ caption, onClose }) => {
  return (
    <div className="panel-header">
      {caption && (
        <label id="panel-caption-label">{caption}</label>
      )}
      {onClose && (
        <TopNavButton iconName={CloseIcon} onClick={onClose} expanded={false} />
      )}
    </div>
  )
}

const DetailsHeader = ({ onClick, isOpen }) => {
  return (
    <div className="panel-header">
      <div id="panel-details-caption" className='details-caption' style={{ display: !isOpen && 'none' }} />
      <TopNavButton
        iconName={isOpen ? HideTableIcon : FullscreenTableIcon}
        onClick={onClick}
        expanded={false}
        className="panel-details-button"
      />
    </div>
  )
}

const Panel = () => {
  const dispatch = useDispatch()
  const additionalPanelRef = useRef()

  const [ confirmClose ] = useState({})

  const panel = useSelector(selectPanel)
  const additionalPanel = useSelector(selectAdditionalPanel)
  const offlineMode = useSelector(selectOfflineMode)

  const [ caption, Component, withDetails, data ] = useMemo(() => {
    const dataIndex = panel?.indexOf('#')
    const key = dataIndex && dataIndex !== -1 ? panel.substring(0, dataIndex) : panel
    const data = dataIndex && dataIndex !== -1 ? panel.substring(dataIndex + 1) : undefined
    const menuItem = findMenuItem(key)
    return [
      menuItem?.text,
      panels[menuItem?.component] ?? null,
      menuItem?.details,
      data,
    ]
  }, [ panel ])

  const [ isDetailsOpen, setDetailsOpen ] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 330)
    if (!panel) {
      setDetailsOpen(false)
    }
  }, [ panel, isDetailsOpen, setDetailsOpen ])

  const closePanel = useCallback(async () => {
    if (!confirmClose.check || await confirmClose.check()) {
      setDetailsOpen(false)
      dispatch(setPanel(null))
    }
  }, [ dispatch, setDetailsOpen, confirmClose ])

  const handleOnClickDetails = useCallback(() => {
    if (!isDetailsOpen && offlineMode) {
      window.dispatchEvent(
        new ErrorEvent('resterror', { message: 'Details are not allowed if Auto-Save Mode is disabled.' }),
      )
      return
    }
    setDetailsOpen(!isDetailsOpen)
  }, [ isDetailsOpen, setDetailsOpen, offlineMode ])

  useEffect(() => {
    if (offlineMode && isDetailsOpen) {
      setDetailsOpen(!isDetailsOpen)
    }
  }, [ offlineMode, isDetailsOpen, setDetailsOpen ])

  const closeAdditionalPanel = useCallback(() => {
    dispatch(setAdditionalPanel(null))
  }, [ dispatch ])

  return (
    <>
      {panel !== null && withDetails && (
        <CSSTransition in={isDetailsOpen} timeout={300} classNames="details">
          <div id="panel-details" className="details">
            <DetailsHeader isOpen={isDetailsOpen} onClick={handleOnClickDetails} />
            <div id="panel-details-content" style={{ display: !isDetailsOpen && 'none' }} />
          </div>
        </CSSTransition>
      )}
      {panel !== null && !withDetails && (
        <div className="additional-panel" style={{ display: additionalPanel === null && 'none' }}>
          <div className="additional-panel-header-container">
            <div className="additional-panel-header">{additionalPanel}</div>
            <div className="capex-section-header-suffix">
              <IconButton icon={HideTableIcon} onClick={closeAdditionalPanel} />
            </div>
          </div>
          <div className="additional-panel-content" ref={additionalPanelRef} />
        </div>
      )}
      <CSSTransition in={panel !== null} timeout={300} classNames="panel" unmountOnExit>
        <div className={`panel ${withDetails ? 'with-details' : ''}`}>
          <PanelHeader caption={caption} onClose={closePanel} />
          <div className="panel-content">
            {Component && (
              <Component
                onClose={closePanel}
                confirmClose={confirmClose}
                data={data}
                additionalPanelRef={additionalPanelRef}
              />
            )}
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export default Panel
