export const REPORT_COVERAGE_BY_SIGNAL = 'REPORT_COVERAGE_BY_SIGNAL'
export const REPORT_COVERAGE_BY_SECTOR = 'REPORT_COVERAGE_BY_SECTOR'
export const REPORT_NEIGHBORS = 'REPORT_NEIGHBORS'
export const REPORT_COVERAGE_BY_POINT_LIST = 'REPORT_COVERAGE_BY_POINT_LIST'
export const REPORT_COVERAGE_BY_ZONE_LIST = 'REPORT_COVERAGE_BY_ZONE_LIST'
export const REPORT_BC_CALCULATION_DETAILS = 'REPORT_BC_CALCULATION_DETAILS'

export const REPORT_STATUS_KEY = 'status'
export const REPORT_RESULT_KEY = 'executeDetails'

export const REPORT_NAMES = {
  [REPORT_COVERAGE_BY_SIGNAL]: 'Coverage By Signal Level',
  [REPORT_COVERAGE_BY_SECTOR]: 'Coverage By Sectors',
  [REPORT_NEIGHBORS]: 'Neighbors',
  [REPORT_COVERAGE_BY_POINT_LIST]: 'Network Service For Point List',
  [REPORT_COVERAGE_BY_ZONE_LIST]: 'Network Service For Zone List',
  [REPORT_BC_CALCULATION_DETAILS]: 'Business Case Calculation Details',
}
