import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MapContainer, TileLayer } from 'react-leaflet'
import { useDebounce } from 'use-debounce'
import {
  Stack, StackItem, DialogFooter, Slider, DatePicker, Text, SpinButton, addDays, addMonths, IconButton, Callout,
  Checkbox, Spinner, SpinnerSize,
} from '@fluentui/react'
import { useConst, useBoolean, useId } from '@fluentui/react-hooks'
import { point, featureCollection } from '@turf/helpers'
import tin from '@turf/tin'
import distance from '@turf/distance'
import { DefaultButton, PrimaryButton } from '../common/Button'
import ChoiceGroup from '../common/ChoiceGroup'
import Modal from '../common/Modal'
import {
  hideBuildPainZones, selectChosenSubstance, buildPainZones, selectGeoTree, TOP_INDEX_ZONES,
} from '../../features/geo/geoSlice'
import { selectPainZoneParams, saveSettings } from '../../features/settings/settingsSlice'
import { selectSitesInComputation, setHistoricalComplaints } from '../../features/network/networkSlice'
import { PAIN_ZONE_PARAMS } from '../../constants/default'
import { INDEX_ZONE_FOCUS } from '../../constants/geo'
import { useConfirm } from '../common/Confirm'
import Complaints from './Complaints'

import './BuildPainZones.css'

const MAX_PERIOD_DAYS = 39

const aggregationRangeHint = `The maximum distance (in meters) between Complaints to aggregate them to one group.
Enter a number or use the slider. Press "Auto" button to set the aggregation range to the half of the average distance between Sites inside the Computation Zone.`

const complaintsDensityHint = `The minimum "Complaints density" (the number of Complaints per unit of area) in one group to consider it a Pain Zone.
Enter a number or use the slider.`

const weightOfComplaintsHint = `The minimum sum of the weight of Complaints in one group in relation to the total weight of all Complaints to consider it a Pain Zone.
Enter a number or use the slider.`

const dateRangeHint = 'You can additionally restrict the list of current Complaints by date range.'

const encodeSlider = (params, value) => {
  const { min, max, step } = params
  return Math.round(Math.sqrt((value - min) * (max - min)) / step) * step + min
}

const decodeSlider = (params, value) => {
  const { min, max, step } = params
  return Number((Math.round((value - min) ** 2 / (max - min) / step) * step).toFixed(3)) + min
}

const diffDays = (date1, date2) => Math.round((date2 - date1) / (1000 * 60 * 60 * 24))

const InfoTooltip = ({ text }) => {
  const [ isCalloutVisible, { toggle: toggleIsCalloutVisible } ] = useBoolean(false)
  const buttonId = useId('callout-button')

  return (
    <div className="info-tooltip">
      <IconButton
        id={buttonId}
        iconProps={{ iconName: 'Info' }}
        title={text}
        onClick={toggleIsCalloutVisible}
        className="info-tooltip-button"
      />
      {isCalloutVisible && (
        <Callout
          target={`#${buttonId}`}
          setInitialFocus
          onDismiss={toggleIsCalloutVisible}
          role="dialog"
          calloutMaxWidth={300}
          directionalHint={6}
        >
          <div className="info-tooltip-callout">
            <Text>{text}</Text>
          </div>
        </Callout>
      )}
    </div>
  )
}

const BuildPainZones = () => {
  const dispatch = useDispatch()
  const { confirm, renderConfirm } = useConfirm()

  const chosenSubstance = useSelector(selectChosenSubstance, shallowEqual)
  const painZoneParams = useSelector(selectPainZoneParams)
  const geoTree = useSelector(selectGeoTree)
  const sitesInComputation = useSelector(selectSitesInComputation, shallowEqual)

  const [ mode, setMode ] = useState(painZoneParams.mode || 'density')
  const [ resolution, setResolution ] = useState(Number(painZoneParams.resolution))
  const [ sensitivity, setSensitivity ] = useState(Number(painZoneParams.sensitivity))
  const [ weight, setWeight ] = useState(Number(painZoneParams.weight) ?? PAIN_ZONE_PARAMS.weight.default)
  const [ dateFrom, setDateFrom ] = useState(null)
  const [ dateTo, setDateTo ] = useState(null)
  const [ zoom, setZoom ] = useState(localStorage.getItem('mapZoom') || 13)
  const [ filteredComplaintsCount, setFilteredComplaintsCount ] = useState(0)
  const [ painZonesCount, setPainZonesCount ] = useState(0)
  const [ autoPreview, { toggle: toggleAutoPreview, setFalse: offAutoPreview } ] = useBoolean(false)
  const [ modified, { toggle: toggleModified, setTrue: setModified } ] = useBoolean(true)
  const [ loadingComplaints, setLoadingComplaints ] = useState(false)
  const [ okPressed, setOkPressed ] = useState(false)

  const [ calcResolution ] = useDebounce(resolution, 100)
  const [ calcSensitivity ] = useDebounce(sensitivity, 100)
  const [ calcWeight ] = useDebounce(weight, 100)

  const maxDate = useConst(new Date(Date.now()))
  const minDateCurrent = useConst(addDays(maxDate, -30))
  const minDate = useConst(addMonths(maxDate, -13))

  const resolutionSlider = useMemo(() => encodeSlider(PAIN_ZONE_PARAMS.resolution, resolution), [ resolution ])
  const sensitivitySlider = useMemo(() => encodeSlider(PAIN_ZONE_PARAMS.sensitivity, sensitivity), [ sensitivity ])
  const weightSlider = useMemo(() => encodeSlider(PAIN_ZONE_PARAMS.weight, weight), [ weight ])
  const historicalComplaints = useMemo(() => dateFrom && dateFrom < minDateCurrent, [ dateFrom, minDateCurrent ])

  const focusZoneCount = useMemo(() => {
    return geoTree[TOP_INDEX_ZONES].children[INDEX_ZONE_FOCUS].children?.length ?? 0
  }, [ geoTree ])

  const position = useMemo(() => JSON.parse(localStorage.getItem('mapPosition') || '[50.45,30.52]'), [])

  const avgDist = useMemo(() => {
    if (sitesInComputation.length < 2) {
      return 0
    } else if (sitesInComputation.length === 2) {
      return distance(sitesInComputation[0], sitesInComputation[1], { units: 'meters' })
    } else {
      const sitesCollection = featureCollection(sitesInComputation.map(point))
      const sitesTriangulated = tin(sitesCollection)
      const sidesAverageLengths = sitesTriangulated.features.map((triangle) => {
        const poly = triangle.geometry.coordinates[0]
        const p1 = point(poly[0])
        const p2 = point(poly[1])
        const p3 = point(poly[2])
        const d1 = distance(p1, p2, { units: 'meters' })
        const d2 = distance(p2, p3, { units: 'meters' })
        const d3 = distance(p3, p1, { units: 'meters' })
        return (d1 + d2 + d3) / 3
      })
      const average = sidesAverageLengths.reduce((a, b) => a + b, 0) / sidesAverageLengths.length
      return sidesAverageLengths
        // треба відфільтрувати трикутники по краях зони, які можуть бути дуже великими
        // та точно не характеризують середню відстань між точками
        .filter((length) => length < average * 3)
        .reduce((a, b) => a + b, 0) / sidesAverageLengths.length
    }
  }, [ sitesInComputation ])

  // Трік, щоб запобігти ре-рендеру (і спрацюванню "важких" селекторів) через вичитку нотифікацій з бекенду
  useEffect(() => {
    global.drawMode = true
    return () => {
      global.drawMode = false
    }
  }, [])

  const closeForm = useCallback(async () => {
    if (historicalComplaints) {
      await dispatch(setHistoricalComplaints(null))
    }
    dispatch(hideBuildPainZones())
  }, [ dispatch, historicalComplaints ])

  const changeDateFrom = useCallback((date) => {
    setModified()
    setDateFrom(date)
    if (date < minDateCurrent) {
      offAutoPreview()
    }
    if (dateTo === null || dateTo < date || diffDays(date, dateTo) > MAX_PERIOD_DAYS) {
      const fromNow = diffDays(addDays(date, MAX_PERIOD_DAYS), new Date())
      if (fromNow > 0) {
        setDateTo(addDays(date, MAX_PERIOD_DAYS))
      } else {
        setDateTo(null)
      }
    }
  }, [ setDateFrom, setModified, offAutoPreview, minDateCurrent, dateTo, setDateTo ])

  const changeDateTo = useCallback((date) => {
    setModified()
    setDateTo(date)
    if (dateFrom === null || dateFrom > date || diffDays(dateFrom, date) > MAX_PERIOD_DAYS) {
      let newValue = addDays(date, -MAX_PERIOD_DAYS)
      const d = diffDays(minDate, newValue)
      if (d < 0) {
        newValue = minDate
      }
      setDateFrom(newValue)
    }
  }, [ setDateTo, setModified, dateFrom, setDateFrom, minDate ])

  const changeResolution = useCallback((event, value) => {
    setModified()
    setResolution(Number(value))
  }, [ setResolution, setModified ])

  const changeSensitivity = useCallback((event, value) => {
    setModified()
    setSensitivity(Number(value))
  }, [ setSensitivity, setModified ])

  const changeWeight = useCallback((event, value) => {
    setModified()
    setWeight(Number(value))
  }, [ setWeight, setModified ])

  const slideResolution = useCallback((value) => {
    setModified()
    setResolution(decodeSlider(PAIN_ZONE_PARAMS.resolution, Number(value)))
  }, [ setResolution, setModified ])

  const slideSensitivity = useCallback((value) => {
    setModified()
    setSensitivity(decodeSlider(PAIN_ZONE_PARAMS.sensitivity, Number(value)))
  }, [ setSensitivity, setModified ])

  const slideWeight = useCallback((value) => {
    setModified()
    setWeight(decodeSlider(PAIN_ZONE_PARAMS.weight, Number(value)))
  }, [ setWeight, setModified ])

  const onOK = useCallback(() => {
    const proceed = async () => {
      setOkPressed(true)
      await dispatch(saveSettings({
        painZones: {
          resolution,
          sensitivity,
          weight,
          mode,
        },
      }))
      await dispatch(buildPainZones({
        zoom,
        resolution,
        sensitivity,
        dateFrom,
        dateTo,
        weight,
        mode,
      }))
      closeForm()
    }

    if (focusZoneCount > 0) {
      confirm(
        proceed,
        {
          messages: [
            'All existing Focus Zones will be overwritten by new set of Pain Zones.',
            'Do you want to proceed?',
          ],
        },
      )
    } else {
      proceed()
    }
  }, [
    dispatch, closeForm, resolution, sensitivity, dateFrom, dateTo, zoom, focusZoneCount, confirm, weight, mode,
    setOkPressed,
  ])

  const setAverageDistance = useCallback(() => {
    setModified()
    setResolution(Math.round(avgDist / 2))
  }, [ avgDist, setResolution, setModified ])

  const handleChangeMode = useCallback((event, option) => {
    setModified()
    setMode(option.key)
  }, [ setMode, setModified ])

  useEffect(() => {
    const updateComplaints = async () => {
      setLoadingComplaints(true)
      try {
        if (historicalComplaints) {
          await dispatch(setHistoricalComplaints({ dateFrom, dateTo: dateTo || new Date() }))
        } else {
          await dispatch(setHistoricalComplaints(null))
        }
      } finally {
        setLoadingComplaints(false)
      }
    }

    if (autoPreview || !modified) {
      updateComplaints()
    }
  }, [ autoPreview, modified, dateFrom, dateTo, historicalComplaints, dispatch, setLoadingComplaints ])

  return (
    <Modal
      title="Calculate Pain Zones"
      width={'90vw'}
      heightAuto
      onClose={closeForm}
    >
      <Stack className="full-height">
        {/* <Separator alignContent="end"><Text variant="xLarge">Options</Text></Separator> */}
        <StackItem>
          <table>
            <tbody>
              <tr valign="top">
                <td style={{ width: 120, paddingRight: 18 }} valign="middle">
                  <DefaultButton
                    text="Auto"
                    title="Set the aggregation range to the half of the average distance between sites"
                    onClick={setAverageDistance}
                    disabled={avgDist === 0 || Math.round(avgDist / 2) === resolution}
                  />
                </td>
                <td style={{ width: 120, paddingRight: 18, position: 'relative' }}>
                  <SpinButton
                    label="Aggregation range"
                    labelPosition="Top"
                    min={PAIN_ZONE_PARAMS.resolution.min}
                    max={PAIN_ZONE_PARAMS.resolution.max}
                    step={PAIN_ZONE_PARAMS.resolution.step}
                    value={resolution}
                    onChange={changeResolution}
                  />
                  <InfoTooltip text={aggregationRangeHint} />
                </td>
                <td style={{ minWidth: 240, paddingTop: 32 }}>
                  <Slider
                    min={PAIN_ZONE_PARAMS.resolution.min}
                    max={PAIN_ZONE_PARAMS.resolution.max}
                    value={resolutionSlider}
                    onChange={slideResolution}
                    showValue={false}
                  />
                </td>
                <td style={{ width: 160, paddingLeft: 24, paddingRight: 18, position: 'relative' }}>
                  <DatePicker
                    label="Date from"
                    value={dateFrom}
                    onSelectDate={changeDateFrom}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                  <InfoTooltip text={dateRangeHint} />
                </td>
                <td style={{ paddingLeft: 12, paddingRight: 18 }} valign="middle">
                  <Checkbox
                    label="Auto Preview"
                    disabled={historicalComplaints}
                    checked={autoPreview}
                    onChange={toggleAutoPreview}
                  />
                </td>
                <td style={{ paddingRight: 18 }} valign="middle">
                  <DefaultButton
                    text="Update"
                    disabled={autoPreview || !modified}
                    onClick={toggleModified}
                  />
                </td>
              </tr>
              <tr valign="top">
                <td style={{ width: 120, paddingTop: 5, paddingRight: 18 }}>
                  <ChoiceGroup
                    selectedKey={mode}
                    options={[
                      { key: 'density', text: 'By density' },
                      { key: 'weight', text: 'By weight' },
                    ]}
                    onChange={handleChangeMode}
                    style={{ maxHeight: 40 }}
                  />
                </td>
                <td style={{ width: 120, paddingRight: 18, position: 'relative' }}>
                  {mode === 'density' && (
                    <>
                      <SpinButton
                        label="Complaints density"
                        labelPosition="Top"
                        min={PAIN_ZONE_PARAMS.sensitivity.min}
                        max={PAIN_ZONE_PARAMS.sensitivity.max}
                        step={PAIN_ZONE_PARAMS.sensitivity.step}
                        value={sensitivity}
                        onChange={changeSensitivity}
                      />
                      <InfoTooltip text={complaintsDensityHint} />
                    </>
                  )}
                  {mode === 'weight' && (
                    <>
                      <SpinButton
                        label="Weight of Complaints, %"
                        labelPosition="Top"
                        min={PAIN_ZONE_PARAMS.weight.min}
                        max={PAIN_ZONE_PARAMS.weight.max}
                        step={PAIN_ZONE_PARAMS.weight.step}
                        value={weight}
                        onChange={changeWeight}
                      />
                      <InfoTooltip text={weightOfComplaintsHint} />
                    </>
                  )}
                </td>
                <td style={{ minWidth: 240, paddingTop: 32 }}>
                  {mode === 'density' && (
                    <Slider
                      min={PAIN_ZONE_PARAMS.sensitivity.min}
                      max={PAIN_ZONE_PARAMS.sensitivity.max}
                      value={sensitivitySlider}
                      onChange={slideSensitivity}
                      showValue={false}
                    />
                  )}
                  {mode === 'weight' && (
                    <Slider
                      min={PAIN_ZONE_PARAMS.weight.min}
                      max={PAIN_ZONE_PARAMS.weight.max}
                      value={weightSlider}
                      onChange={slideWeight}
                      showValue={false}
                    />
                  )}
                </td>
                <td style={{ width: 160, paddingLeft: 24, paddingRight: 18, position: 'relative' }}>
                  <DatePicker
                    label="Date to"
                    value={dateTo}
                    onSelectDate={changeDateTo}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                  <InfoTooltip text={dateRangeHint} />
                </td>
                <td style={{ paddingLeft: 12, paddingRight: 18 }} valign="middle">
                  {(autoPreview || !modified) && (
                    loadingComplaints
                      ? (
                          <Spinner size={SpinnerSize.medium} />
                        )
                      : (
                          <>
                            Complaints: <strong>{filteredComplaintsCount}</strong>
                            <br />
                            Pain Zones: <strong>{painZonesCount}</strong>
                          </>
                        )
                  )}
                </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </StackItem>
        {/* <Separator alignContent="end"><Text variant="xLarge">Preview</Text></Separator> */}
        <StackItem className="full-height">
          <MapContainer
            center={position}
            zoom={zoom}
            className="full-height full-width"
            preferCanvas
            zoomDelta={0.5}
            zoomSnap={0.5}
            attributionControl={false}
          >
            {chosenSubstance && (
              <TileLayer {...chosenSubstance} noWrap key={chosenSubstance.url} keepBuffer={20} />
            )}
            {(autoPreview || !modified) && !okPressed && (
              <Complaints
                resolution={calcResolution}
                sensitivity={calcSensitivity}
                weight={calcWeight}
                mode={mode}
                onChangeZoom={setZoom}
                dateFrom={dateFrom}
                dateTo={dateTo}
                onFilteredComplaintsCount={setFilteredComplaintsCount}
                onPainZonesCount={setPainZonesCount}
                loadingComplaints={loadingComplaints}
              />
            )}
          </MapContainer>
        </StackItem>
        <StackItem>
          <DialogFooter>
            <PrimaryButton
              onClick={onOK}
              text="Calculate"
              disabled={painZonesCount === 0 || okPressed || (!autoPreview && modified)}
            />
            <DefaultButton
              onClick={closeForm}
              text="Cancel"
              disabled={okPressed}
            />
          </DialogFooter>
        </StackItem>
      </Stack>
      {renderConfirm()}
    </Modal>
  )
}

export default BuildPainZones
