import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { DefaultButton /*, Spinner, SpinnerSize */ } from '@fluentui/react'
import { cabinet as t } from '../constants'
import Grid from '../../common/Grid'
import { EMPTY } from '../../../constants'
import { DetailsRenderer } from './DetailsRenderer'

const synchronizeIcon = {
  iconName: 'Sync',
}

const Component = ({
  selectSource,
  data,
  onAfterEdit,
  onSynchronize,
  onSelect,
  hotGridRef,
  // loading,
  columns,
}) => {
  const onClickHandler = useCallback(() => {
    onSynchronize(selectSource)
  }, [ selectSource, onSynchronize ])

  return (
    <>
      <DefaultButton
        style={{ marginBottom: '8px' }}
        disabled={!selectSource}
        text={t.synchronize}
        iconProps={synchronizeIcon}
        onClick={onClickHandler}
        allowDisabledFocus
      />
      <Grid
        dataType={'ManualSync'}
        data={data ?? EMPTY.ARRAY}
        refHot={hotGridRef}
        columns={columns}
        manualColumns={false}
        onAfterEdit={onAfterEdit}
        onSelectionEnd={onSelect}
        fillHandle={false}
        isFullViewHeader
      >
        {(id) => id === 'table.synchronization.executeDetails' ? <DetailsRenderer hot-renderer/> : null}
      </Grid>
    </>
  )
}

Component.propTypes = {
  amountSelected: PropTypes.number,
  data: PropTypes.array,
  onSynchronize: PropTypes.func,
  onChangeCheck: PropTypes.func,
  hotTableRef: PropTypes.object,
}

export default Component
