import { Checkbox, Stack } from '@fluentui/react'
import React, { useCallback } from 'react'
import { IconButton } from '@fluentui/react/lib/Button'
import { stylesChoiceGroup } from './styles'

const iconStyles = {
  fontSize: '1.5em',
}
const groupIcon = {
  className: 'icon-group',
  iconName: 'ExploreContentSingle', // 'Add',
  style: iconStyles,
}
const groupIcon2 = {
  className: 'icon-group',
  iconName: 'CollapseContentSingle', // 'Remove',
  style: iconStyles,

}

const TD = ({ item, column, onUpdateItem }) => {
  const isSelect = column?.isSelectable // выводить чекбокс
  const isGroup = column?.isGrouping && item.sectors?.length// выводить кнопку группировки
  const columnName = column?.fieldName

  // Обработка чекбокса выбора строки
  const onChangeHandler = useCallback((event, checked) => {
    const newItem = { ...item, [columnName]: checked }
    onUpdateItem && onUpdateItem(newItem)
  }, [ columnName, item, onUpdateItem ])

  // Обработка управления отображением группы
  const onClickGroupHandler = useCallback(() => {
    const newItem = { ...item, groupVisible: !item.groupVisible }
    onUpdateItem && onUpdateItem(newItem)
  }, [ item, onUpdateItem ])

  return (
    <Stack
      horizontal
    >
      {isSelect
        ? <Checkbox
          label=""
          checked={!!item[columnName]}
          onChange={onChangeHandler}
          styles={stylesChoiceGroup}
        />
        : null
      }
      {isGroup
        ? <IconButton
          style={{ height: 'auto' }}
          iconProps={item.groupVisible ? groupIcon2 : groupIcon}
          checked={!item[columnName]}
          onClick={onClickGroupHandler}
        />
        : null
      }
      <span>{item?.[columnName]}</span>
    </Stack>
  )
}

export default TD
