import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
import Modal from '../common/Modal'
import { selectRawRates } from '../../features/compositeIndex/compositeIndexSlice'
import Dropdown from '../common/Dropdown'

import './GraphModal.css'

const stringLabels = (data, fieldIndex, rates) => {
  const map = {}
  data.resource.forEach((row, index) => {
    const key = row[fieldIndex]
    const value = rates[index]
    if (!map[key]) {
      map[key] = {
        count: 1,
        value,
      }
    } else {
      map[key].count++
      map[key].value += value
    }
  })
  const entries = Object.entries(map).sort((a, b) => a[0].localeCompare(b[0]))
  return [
    entries.map(([ key ]) => key === 'null' ? '(empty)' : key),
    entries.map(([ , { count, value } ]) => value / count),
  ]
}

const byExactValue = (data, fieldIndex, rates, labels) => {
  const map = labels.reduce((acc, item) => ({ ...acc, [item]: { count: 0, value: 0 } }), {})
  data.resource.forEach((row, index) => {
    const key = row[fieldIndex]
    const value = rates[index]
    map[key].count++
    map[key].value += value
  })
  return [
    labels,
    labels.map((label) => map[label].count && map[label].value / map[label].count),
  ]
}

const enumLabels = (data, fieldIndex, rates) => {
  const labels = data.fields[fieldIndex]['enum-values'].split(',').sort()
  return byExactValue(data, fieldIndex, rates, labels)
}

const dateLabels = (data, fieldIndex, rates) => stringLabels(data, fieldIndex, rates)

const booleanLabels = (data, fieldIndex, rates) => {
  const labels = [ false, true ]
  return byExactValue(data, fieldIndex, rates, labels)
}

const doubleLabels = (data, fieldIndex, rates) => {
  let min, max
  data.resource.forEach((row) => {
    const key = row[fieldIndex]
    if (min === undefined || key < min) {
      min = key
    }
    if (max === undefined || key > max) {
      max = key
    }
  })
  const range = max - min
  const step = Math.pow(10, Math.ceil(Math.log10(range / 100)))
  const first = Math.floor(min / step) * step
  const last = Math.floor(max / step) * step
  const labels = []
  const limits = []
  for (let i = first; i <= last; i += step) {
    const prev = parseFloat(i.toFixed(12))
    const next = parseFloat((i + step).toFixed(12))
    labels.push(`${prev} — ${next}`)
    limits.push([ prev, next ])
  }
  const values = limits.map(() => ({ count: 0, value: 0 }))
  data.resource.forEach((row, index) => {
    const key = row[fieldIndex]
    const value = rates[index]
    for (let i = 0; i < limits.length; i++) {
      const [ min, max ] = limits[i]
      if (key >= min && key <= max) {
        values[i].count++
        values[i].value += value
      }
    }
  })
  return [
    labels,
    values.map(({ count, value }) => count && value / count),
  ]
}

const intLabels = (data, fieldIndex, rates) => doubleLabels(data, fieldIndex, rates)

const GraphModal = ({ onClose, data }) => {
  const rates = useSelector(selectRawRates)

  const fields = useMemo(
    () => data?.fields
      ?.filter(({ hidden, label }) => label !== 'Lock' && !hidden)
      ?.map(({ id: key, label: text }) => ({ key, text })),
    [ data ],
  )

  const [ field, setField ] = useState(null)

  const handleFieldChange = useCallback((event, option) => {
    setField(option)
  }, [ setField ])

  const [ labels, values ] = useMemo(() => {
    const factorFieldIndex = field && data?.fields?.findIndex(({ id }) => id === field?.key)
    if (factorFieldIndex === null || factorFieldIndex === -1) {
      return [ null, null ]
    }
    const factorField = data.fields[factorFieldIndex]
    switch (factorField.type) {
      case 'string': return stringLabels(data, factorFieldIndex, rates)
      case 'enum': return enumLabels(data, factorFieldIndex, rates)
      case 'date': return dateLabels(data, factorFieldIndex, rates)
      case 'boolean': return booleanLabels(data, factorFieldIndex, rates)
      case 'double': return doubleLabels(data, factorFieldIndex, rates)
      case 'int': return intLabels(data, factorFieldIndex, rates)
      default: {
        console.error('Unsupported field type', factorField.type, 'for field', factorField.label)
        return [ null, null ]
      }
    }
  }, [ data, field, rates ])

  return (
    <Modal
      onClose={onClose}
      title="Graph"
    >
      <div style={{ height: '84vh' }}>
        <div className="graph-header">
          <Dropdown
            className="graph-field"
            label="Field"
            options={fields}
            selectedKey={field?.key}
            onChange={handleFieldChange}
          />
        </div>
        <div className="graph-body">
          {labels && (
            <Bar
              options={{ responsive: true, plugins: { legend: { display: false } } }}
              data={{ labels, datasets: [ { data: values } ] }}
              className="graph-canvas"
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default GraphModal
