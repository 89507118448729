/* eslint-disable react/no-unknown-property */
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DefaultButton, Label, TooltipHost } from '@fluentui/react'
import { selectBaseStationFields, selectSectorFields, selectSiteFields } from '../../../features/network/networkSlice'
import { EMPTY } from '../../../constants'
import { WebkitScrollbar } from '../../common/WebkitScrollbar'
import InputSpin from '../../common/InputSpin'
import { ELEMENTS } from '../constants'
import Header from './Header'

import './styles.scss'

const lowerIcon = { iconName: 'DoubleChevronDown8' } // 12

const higherIcon = { iconName: 'DoubleChevronUp8' } // 12

const stylesBetter = {
  root: {
    padding: 0,
    borderStyle: 'none',
    fontSize: '0.90em',
    backgroundColor: 'transparent',
    whiteSpace: 'nowrap',
  },
  label: {
    fontWeight: 400,
  },
  rootDisabled: {
    backgroundColor: 'transparent',
  },
}

const Row = ({ value, onChange, percentage, disabled, fields }) => {
  const field = useMemo(() => fields.find((field) => field.id === value.id) ?? EMPTY.OBJECT, [ fields, value.id ])

  const visibleBetter = useMemo(() => !!value.weight, [ value.weight ])

  const onBetterHandler = useCallback(() => {
    const newItem = { ...value, better: !value.better }
    onChange(newItem)
  }, [ onChange, value ])

  return (
    <tr>
      <td align={'left'}>
        <TooltipHost content={field?.description}>
          <Label styles={{ root: { margin: '0 16px' } }}>{field?.label}</Label>
        </TooltipHost>
      </td>
      <td width={'80px'}>
        <InputSpin
          key={value.id}
          maxValue={100 + (value.weight || 0) - (percentage || 0)}
          minValue={0}
          name={'weight'}
          onChange={onChange}
          item={value}
          className={'widthPercent'}
          suffix={'%'}
          disabled={disabled}
        />
      </td>
      <td>
        {visibleBetter && (
          <DefaultButton
            disabled={disabled}
            toggle
            styles={stylesBetter}
            allowDisabledFocus
            text={value.better ? 'Higher - better' : 'Lower - better'}
            iconProps={value.better ? higherIcon : lowerIcon}
            onClick={onBetterHandler}
          />
        )}
      </td>
    </tr>
  )
}

const TableWeights = ({ items, onChange, percentage, disabled, elementKey, isEdit }) => {
  const siteFields = useSelector(selectSiteFields)
  const rbsFields = useSelector(selectBaseStationFields)
  const sectorFields = useSelector(selectSectorFields)

  const fields = useMemo(() => elementKey === ELEMENTS.SITES
    ? siteFields
    : elementKey === ELEMENTS.RBS
      ? rbsFields
      : elementKey === ELEMENTS.SECTORS
        ? sectorFields
        : EMPTY.ARRAY,
  [ elementKey, rbsFields, sectorFields, siteFields ])

  const onChangeHandler = useCallback((value) => {
    const newItems = items.map((item) => (item.id !== value.id ? item : value))
    onChange(newItems, elementKey)
  }, [ onChange, items, elementKey ])

  return (
    <WebkitScrollbar>
      <table className={'attributes'}>
        <Header percentage={percentage} disabled={disabled}/>
        <tbody>
          {Array.isArray(items) && items.filter((item) => isEdit || item.weight).map((item) => (
            <Row
              disabled={disabled}
              key={item.id}
              value={item}
              onChange={onChangeHandler}
              percentage={percentage}
              fields={fields}
            />
          ))}
        </tbody>
      </table>
    </WebkitScrollbar>
  )
}

export default TableWeights
