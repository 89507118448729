import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react'
import TextField from '../../common/TextField'
import { MoveUpIcon } from '../../common/icons/names'
import { IconButton, DefaultButton, PrimaryButton } from '../../common/Button'
import { confirmStyles } from '../stylesForm'
import { cabinet, KEYS, MAX_DESCRIPTION_LENGTH } from '../constants'
import { findProjectName } from '../Project/utils'
import { selectProjects } from '../../../features/projects/projectsSlice'

const MIN_WIDTH_DIALOG = 480

const CreateProjectForm = ({ isShow, onHide, onCreate, project: projectInit, title, subText, okText }) => {
  const [ project, onChangeProject ] = useState({})
  const [ projectFreeName, setFreeName ] = useState(null)

  const projects = useSelector(selectProjects)

  useEffect(() => {
    onChangeProject(projectInit)
    setFreeName(null)
  }, [ projectInit ])

  const onChangeDescription = useCallback(({ target }) => {
    const newName = target?.value
    onChangeProject({ ...project, [KEYS.DESCRIPTION]: newName })
    const trimmed = newName?.trim()
    const projectFreeName = findProjectName(projects, trimmed)
    if (projectFreeName === trimmed || trimmed === '') {
      setFreeName(null)
    } else {
      setFreeName(projectFreeName)
    }
  }, [ project, onChangeProject, projects ])

  const onOk = useCallback(() => {
    onCreate(project)
  }, [ onCreate, project ])

  const projectName = useMemo(() => project?.[KEYS.DESCRIPTION], [ project ])

  const substituteName = useCallback(() => {
    onChangeDescription({ target: { value: projectFreeName } })
  }, [ onChangeDescription, projectFreeName ])

  return (
    <Dialog
      hidden={!isShow}
      onDismiss={onHide}
      dialogContentProps={{
        type: DialogType.normal,
        title: title,
        subText,
      }}
      minWidth={MIN_WIDTH_DIALOG}
      modalProps={{
        isBlocking: true,
      }}
    >
      <div className={confirmStyles.body}>
        <div className="text-field-container">
          <TextField
            label={cabinet.title[KEYS.DESCRIPTION]}
            maxLength={MAX_DESCRIPTION_LENGTH}
            value={projectName}
            onChange={onChangeDescription}
            className="fix-label-pos"
            placeholder="Enter Project name"
          />
        </div>
        <div style={{ visibility: projectFreeName ? 'unset' : 'hidden' }}>
          <div>
            {'Such a project exists, the recommended name is:'}
          </div>
          <span style={{ color: 'blue' }}>
            {projectFreeName}
          </span>
          <IconButton
            icon={MoveUpIcon}
            title="Apply"
            onClick={substituteName}
          />
        </div>
      </div>
      <DialogFooter>
        {onCreate && (
          <PrimaryButton onClick={onOk} text={okText || 'Create'} disabled={!project[KEYS.DESCRIPTION]?.trim()} />
        )}
        <DefaultButton onClick={onHide} text="Cancel" />
      </DialogFooter>
    </Dialog>
  )
}

export default CreateProjectForm
