import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import L from 'leaflet'
import Supercluster from 'supercluster'
import { createClusterIcon, updateClusters } from '../../render'
import { CLUSTER_RADIUS } from '../../../../constants/geo'
import { selectComplaintsAsGeoJSON, selectComplaintsRedraw } from '../../../../features/network/networkSlice'

const ComplaintsCluster = ({ map, radius = CLUSTER_RADIUS }) => {
  const [ cluster, setCluster ] = useState(null)

  const complaints = useSelector(selectComplaintsAsGeoJSON, shallowEqual)
  const complaintsRedraw = useSelector(selectComplaintsRedraw)

  // Ініціалізація кластера скарг
  useEffect(() => {
    if (map && !map.complaints) {
      map.complaints = L.geoJson(null, {
        pointToLayer: createClusterIcon,
      }).addTo(map)
    }
    return () => {
      if (map && map.complaints) {
        map.removeLayer(map.complaints)
        delete map.complaints
      }
    }
  }, [ cluster, map ])

  // Ініціалізація кластера скарг
  useEffect(() => {
    const createdCluster = new Supercluster({ radius })
    setCluster(createdCluster)
    map.complaintsCluster = createdCluster
  }, [ map, setCluster, radius ])

  // Оновлення кластера скарг
  useEffect(() => {
    if (map.complaintsCluster) {
      map.complaintsCluster.load(complaints.features)
      updateClusters(map)
    }
  }, [ map, complaints, complaintsRedraw ])

  return null
}

export default ComplaintsCluster
