import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActionButton, FontWeights, getTheme, IconButton, mergeStyleSets, Modal, Stack, StackItem,
} from '@fluentui/react'
import {
  selectSiteNames, selectCheckMarks, selectAll, unselectAll, selectResolve, setCheckMark,
} from '../../features/reports/reportsSlice'
import { DefaultButton, PrimaryButton } from '../common/Button'
import Checkbox from '../common/Checkbox'

import './SelectSites.css'

const theme = getTheme()

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}

const cancelIcon = { iconName: 'Cancel' }

const contentStyles = mergeStyleSets({
  header: [
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
})

const SelectSites = () => {
  const dispatch = useDispatch()

  const siteNames = useSelector(selectSiteNames)
  const checkMarks = useSelector(selectCheckMarks)
  const { resolve, reject } = useSelector(selectResolve)

  const selectedCount = useMemo(() => checkMarks.filter(Boolean).length, [ checkMarks ])

  const handleCheck = useCallback((event, value) => {
    dispatch(setCheckMark({ index: Number(event.currentTarget.dataset.index), value }))
  }, [ dispatch ])

  const handleSelectAll = useCallback(() => {
    dispatch(selectAll())
  }, [ dispatch ])

  const handleUnselectAll = useCallback(() => {
    dispatch(unselectAll())
  }, [ dispatch ])

  const handleCancel = useCallback(() => {
    reject()
  }, [ reject ])

  const handleOk = useCallback(() => {
    resolve()
  }, [ resolve ])

  return (
    <Modal
      width={480}
      containerClassName="top-most"
      isOpen={true}
      isBlocking={true}
    >
      <div className={contentStyles.header}>
        <span>Select Sites</span>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          onClick={handleCancel}
        />
      </div>
      <Stack className="modal-content-sites">
        {siteNames.length > 1 && (
          <StackItem>
            <ActionButton text="Select All" onClick={handleSelectAll} />
            <ActionButton text="Unselect All" onClick={handleUnselectAll} />
          </StackItem>
        )}
        <StackItem className="site-check-list">
          {siteNames.map((siteName, index) => (
            <Checkbox
              key={`${index}-${siteName}`}
              inputProps={{
                'data-index': index,
              }}
              checked={checkMarks[index]}
              label={siteName}
              onChange={handleCheck}
            />
          ))}
        </StackItem>
        <StackItem>
          <DefaultButton className="dialog-button-sites" text="Cancel" onClick={handleCancel} />
          <PrimaryButton className="dialog-button-sites" text="OK" disabled={selectedCount < 1} onClick={handleOk} />
        </StackItem>
      </Stack>
    </Modal>
  )
}

export default SelectSites
