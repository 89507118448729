import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Stack, StackItem } from '@fluentui/react'
import ElementsTree from '../ElementsTree'
import {
  TOP_INDEX_SITES, checkTreeItem as checkTreeItemNetwork, selectNetworkTree, createSiteMode,
} from '../../../../features/network/networkSlice'
import { setPanel } from '../../../../features/panel/panelSlice'
import { SecondaryButton, PrimaryButton } from '../../../common/Button'
import { selectUserAccess } from '../../../../features/login/loginSlice'
import { EDIT_ELEMENTS } from '../../../../constants/access'
import { selectOfflineMode } from '../../../../features/loading/loadingSlice'
import { selectIsSharedProject } from '../../../../features/projects/projectsSlice'

const Sites = () => {
  const dispatch = useDispatch()

  const networkTree = useSelector(selectNetworkTree, shallowEqual)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const offlineMode = useSelector(selectOfflineMode)
  const isSharedProject = useSelector(selectIsSharedProject)

  const elementsShown = useMemo(() => networkTree[TOP_INDEX_SITES].state.selected, [ networkTree ])

  const toggleElements = useCallback(() => {
    dispatch(checkTreeItemNetwork([ TOP_INDEX_SITES ]))
  }, [ dispatch ])

  const createSite = useCallback(() => {
    dispatch(createSiteMode(true))
    dispatch(setPanel(null))
  }, [ dispatch ])

  return (
    <>
      <ElementsTree topIndex={TOP_INDEX_SITES} />
      <StackItem>
        <Stack horizontal className="panel-footer">
          <SecondaryButton
            text={elementsShown ? 'Hide All Sites' : 'Show Sites'}
            onClick={toggleElements}
            className="full-width"
          />
          <PrimaryButton
            text="Create Site"
            onClick={createSite}
            className="full-width"
            disabled={!userAccess[EDIT_ELEMENTS] || offlineMode || isSharedProject}
          />
        </Stack>
      </StackItem>
    </>
  )
}

export default Sites
