import React from 'react'
import { Stack } from '@fluentui/react'
import Modal from '../common/Modal'
import InitLog from '../InitLog'

const Component = ({
  onClose,
  title,
  loadingSource = [],
}) => {
  return (
    <Modal
      title={title}
      onClose={onClose}
      width={480}
    >
      <Stack>
        {loadingSource.map((sourece, index) => (
          <Stack.Item style={{ overflowX: 'clip', whiteSpace: 'nowrap' }} key={index}>{sourece.message}</Stack.Item>),
        )}
        <Stack.Item>
          <InitLog modal/>
        </Stack.Item>
      </Stack>
    </Modal>
  )
}

export default Component
