import React, { useCallback } from 'react'
import { SpinButton as SpinButtonComponent } from '@fluentui/react'
import EditFieldLabel from '../EditFieldLabel'

import './SpinEditField.css'
import { LOCALE } from '../../../utils/format'

const checkValueExists = (value) => {
  return value !== undefined && value !== null
}

const SpinEditField = ({ label, placeholder, description, onChange, min, max, ...props }) => {
  const toNumber = useCallback((value) => {
    if (value && typeof value === 'string') {
      return parseFloat(value.replaceAll(/\s/g, '').replace(',', '.'))
    }
    return value
  }, [])
  const formatValue = useCallback((value) => {
    if (checkValueExists(value)) {
      let numValue = toNumber(value)
      if (isNaN(numValue)) {
        numValue = 0
      }
      if (checkValueExists(min)) {
        numValue = Math.max(numValue, min)
      }
      if (checkValueExists(max)) {
        numValue = Math.min(numValue, max)
      }
      return numValue.toLocaleString(
        LOCALE,
        {
          minimumFractionDigits: 0,
          maximumFractionDigits: props.precision,
        })
    } else {
      return null
    }
  }, [ toNumber, props.precision, min, max ])
  const handleOnChange = useCallback((event, value) => {
    onChange(event, toNumber(value))
  }, [ onChange, toNumber ])

  const formattedValue = formatValue(props.defaultValue)
  return (
  <div className='capex-spinEditField-wrapper'>
    <SpinButtonComponent
      {...props}
      key={props.value}
      className='capex-spinEditField'
      defaultValue={formattedValue}
      placeholder={placeholder || label}
      onChange={handleOnChange}
      onValidate={formatValue}
      onIncrement={(value) => {
        if (checkValueExists(value)) {
          const numValue = toNumber(value)
          const nextValue = numValue > -1 && numValue < 0 ? Math.abs(numValue) : numValue + 1
          return formatValue(nextValue)
        }
      }}
      onDecrement={(value) => {
        if (checkValueExists(value)) {
          const numValue = toNumber(value)
          const nextValue = numValue > 0 && numValue < 1 ? -Math.abs(numValue) : numValue - 1
          return formatValue(nextValue)
        }
      }}
    />
    <EditFieldLabel
      label={label}
      description={description}
      showLabel={props.value !== undefined || props.defaultValue !== undefined}
    />
  </div>
  )
}

export default SpinEditField
