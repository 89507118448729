import { fetch, uriEventLog } from './rest'

// Значення uriLog не змінювати безпосередньо, а лише через константу uriEventLog!
const uriLog = uriEventLog

export const getEventLog = ({ userId, from, to, address, type, status }) => {
  const params = new URLSearchParams()
  from && params.append('from', from)
  to && params.append('to', to)
  address && params.append('address', address)
  type && params.append('_type', type)
  status && params.append('status', status)
  return fetch(`${uriLog}/${userId}?${params.toString()}`, 'GET', null, 'text')
}

export const logEvent = ({ url, message, type, status }) =>
  fetch(uriLog, 'POST', { url, _type: type, status, message })
