import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack, ProgressIndicator } from '@fluentui/react'
import { Label } from '@fluentui/react/lib/Label'
import { activeProjectId, forceProjectReload } from '../features/projects/projectsSlice'
import { JOB_FINISHED_STATUS, JOB_FROM_ATOLL, STATUS_JOB } from '../features/updateProject/update'
import { errorFixing } from '../utils/format'
import { useConfirm } from '../components/common/Confirm'
import { getJobStatus, selectJobControl, startJobControl } from '../features/synchronization/synchronizationSlice'
import { useHandler } from '../hooks'
import { setTaskCompleted, setTaskFailed } from '../features/taskLog/taskLogSlice'
import { askShowStatistics } from '../components/BusinessCases/utils'
import { selectCalculateForApproveMessage, setCalculateForApproveMessage } from '../features/bc/bcSlice'
import NotPresentInAtollItems from '../components/BusinessCases/NotPresentInAtolItems'

const CONTROL_PERIOD = 30000

const Footer = () => {
  const jobControl = useSelector(selectJobControl)
  const projectId = useSelector(activeProjectId)
  const calculateForApproveMessage = useSelector(selectCalculateForApproveMessage)
  const [ jobsRun, setJobsRun ] = useState(null)
  const { renderConfirm, msg, ask } = useConfirm()
  const dispatch = useDispatch()

  const updateJobRun = useHandler((newJobRun) => {
    if (jobsRun?.id !== newJobRun?.id) {
      setJobsRun(newJobRun)
    }
  })

  useEffect(() => {
    if (calculateForApproveMessage) {
      const renderStatistics = () => (
        <NotPresentInAtollItems data={calculateForApproveMessage} />
      )
      const cancelAction = () => {
        dispatch(setCalculateForApproveMessage(null))
      }
      askShowStatistics(
        ask,
        renderStatistics,
        null,
        cancelAction,
        'List of items that were not found in Atoll',
      )
    }
  }, [ dispatch, calculateForApproveMessage, ask ])

  useEffect(() => {
    if (projectId) {
      const itemName = `${JOB_FROM_ATOLL}-${projectId}`
      const jobsStr = localStorage.getItem(itemName)
      let jobObj
      try {
        jobObj = JSON.parse(jobsStr)
      } catch (e) {
        jobObj = {}
      }
      const id = jobObj?.id
      if (id) {
        const timerId = setTimeout(() => {
          dispatch(startJobControl())
        }, CONTROL_PERIOD)

        dispatch(getJobStatus(id))?.then(({ payload }) => {
          const { id: jobId, status } = payload ?? {}
          if (JOB_FINISHED_STATUS.includes(status) && jobId === id) {
            clearTimeout(timerId)
            localStorage.removeItem(itemName)
            if (status === STATUS_JOB.FINISHED_SUCCESS) {
              dispatch(setTaskCompleted(id))
              msg({
                title: 'Synchronization with Atoll',
                messages: 'Synchronization have completed. The page will be refreshed.',
              }, () => dispatch(forceProjectReload()))
            } else {
              dispatch(setTaskFailed(id))
              msg({
                title: 'Synchronization with Atoll',
                messages: 'An error occurred while synchronizing',
              })
            }
            setJobsRun(null)
          } else {
            updateJobRun(jobObj)
          }
        }).catch((error) => {
          clearTimeout(timerId)
          setJobsRun(null)
          errorFixing(error)
        })

        return () => {
          clearTimeout(timerId)
        }
      } else {
        localStorage.removeItem(itemName)
      }
    }
  }, [ jobControl, projectId, dispatch, msg, updateJobRun ])

  return (
    <>
      {jobsRun
        ? (
            <Stack horizontal tokens={{ padding: '0 4px' }} style={{ height: 24 }}>
              <Label
                style={{ marginLeft: 8 }}
                className={'small-text'}
                title={`Synchronization with Atoll launched on ${jobsRun?.date ?? '?'}`}
              >
                {'Synchronization with Atoll'}
              </Label>
              <ProgressIndicator
                className={'progress-footer'}
                barHeight={8}
              />
            </Stack>
          )
        : null
      }
      {renderConfirm()}
    </>
  )
}

export default Footer
