import { COLUMNS_UPDATE, COLUMNS_UPDATE_BC } from '../../constants/updateDB'

export const TITLE = 'Synchronize with Default project'
export const NOT_SELECT = 'notSelect'
export const BUTTON_KEY = {
  APPLY_IN_MY_PROJECT: 'applyInMyProject',
  UPDATE_TO_DB: 'updateToDB',
  APPLY_IN_MY_PROJECT_AND_UPDATE_TO_DB: 'applyInMyProjectUpdateToDB',
  APPLY_IN_MY_PROJECT_DBValue: 'applyInMyyProjectDBValue',
  UPDATE_BC: 'updateBC',
}
export const RELOAD_COMMAND = [
  BUTTON_KEY.APPLY_IN_MY_PROJECT,
  BUTTON_KEY.APPLY_IN_MY_PROJECT_AND_UPDATE_TO_DB,
  BUTTON_KEY.APPLY_IN_MY_PROJECT_DBValue,
]

export const stateCheck = {
  checked: {
    isIndeterminate: false,
    isChecked: true,
  },
  unchecked: {
    isIndeterminate: false,
    isChecked: false,
  },
  indeterminate: {
    isIndeterminate: true,
    isChecked: false,
  },
}

export const footerClear = {
  [COLUMNS_UPDATE.ORIGINAL_VALUE]: [
    {
      key: BUTTON_KEY.APPLY_IN_MY_PROJECT,
      title: 'Apply in my Project',
      column: COLUMNS_UPDATE.ORIGINAL_VALUE,
    },
  ],
  [COLUMNS_UPDATE.NEW_VALUE]: [
    {
      key: BUTTON_KEY.UPDATE_TO_DB,
      title: 'Update to DB',
      column: COLUMNS_UPDATE.NEW_VALUE,
    },
  ],
}

export const footerSimultaneous = {
  [COLUMNS_UPDATE.ORIGINAL_VALUE]: [
    {
      key: BUTTON_KEY.APPLY_IN_MY_PROJECT,
      title: 'Apply in my Project',
      column: COLUMNS_UPDATE.ORIGINAL_VALUE,
    },
  ],
  [COLUMNS_UPDATE.NEW_VALUE]: [
    {
      key: BUTTON_KEY.UPDATE_TO_DB,
      title: 'Update to DB',
      column: COLUMNS_UPDATE.NEW_VALUE,
    },
  ],
}

export const footerConflict = {
  [COLUMNS_UPDATE.ORIGINAL_VALUE]: [
    {
      key: BUTTON_KEY.APPLY_IN_MY_PROJECT,
      title: 'Apply in my Project',
      column: COLUMNS_UPDATE.ORIGINAL_VALUE,
    },
    {
      key: BUTTON_KEY.APPLY_IN_MY_PROJECT_AND_UPDATE_TO_DB,
      title: 'Apply in my Project and Update to DB',
      column: COLUMNS_UPDATE.ORIGINAL_VALUE,
    },
  ],
  [COLUMNS_UPDATE.NEW_VALUE]: [
    {
      key: BUTTON_KEY.UPDATE_TO_DB,
      title: 'Update to DB',
      column: COLUMNS_UPDATE.NEW_VALUE,
    },
  ],
  [COLUMNS_UPDATE.DB_VALUE]: [
    {
      key: BUTTON_KEY.APPLY_IN_MY_PROJECT_DBValue,
      title: 'Apply in my Project',
      column: COLUMNS_UPDATE.DB_VALUE,
    },
  ],
}

export const footerUpdateBC = {
  [COLUMNS_UPDATE_BC.BC_NAME]: [
    {
      key: BUTTON_KEY.UPDATE_BC,
      title: 'BC to Default project',
      column: COLUMNS_UPDATE_BC.BC_NAME,
      controlColumn: COLUMNS_UPDATE_BC.SELECT, // Столбец определяющий активацию ячейки
    },
  ],
}

export const initColumns = [
  // {
  //   key: 'column0',
  //   name: 'Select',
  //   fieldName: 'select',
  //   minWidth: 0,
  //   maxWidth: 0,
  // },
  {
    key: COLUMNS_UPDATE.NETWORK_ELEMENT,
    name: 'Network Element',
    fieldName: COLUMNS_UPDATE.NETWORK_ELEMENT,
    minWidth: 210,
    maxWidth: 220,
    height: 32,
    isRowHeader: true,
    isResizable: true,
    isSorted: true,
    isSortedDescending: false,
    isPadded: true,
    isIcon: true,
  },
  {
    key: COLUMNS_UPDATE.FIELD,
    name: 'Field',
    fieldName: COLUMNS_UPDATE.FIELD,
    minWidth: 80,
    maxWidth: 110,
    isResizable: true,
    isSorted: true,
    isSortedDescending: false,
    isPadded: true,
  },
  {
    key: COLUMNS_UPDATE.ORIGINAL_VALUE,
    name: 'Original Value',
    fieldName: COLUMNS_UPDATE.ORIGINAL_VALUE,
    minWidth: 100,
    maxWidth: 150,
    isSorted: false,
    isSortedDescending: false,
    isResizable: true,
    data: {
      isIndeterminate: false,
      isChecked: false,
    },
    isPadded: true,
    isValue: true,
  },
  {
    key: COLUMNS_UPDATE.NEW_VALUE,
    name: 'New Value',
    fieldName: COLUMNS_UPDATE.NEW_VALUE,
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    data: {
      isIndeterminate: false,
      isChecked: false,
    },
    isValue: true,
  },

]

// , newValue: 'appp', DBValue: 'dbva' }
export const initColumnsClear = [
  ...initColumns,
  {
    key: COLUMNS_UPDATE.DB_VALUE,
    name: 'DB Value',
    fieldName: COLUMNS_UPDATE.DB_VALUE,
    minWidth: 80,
    maxWidth: 110,
    isResizable: true,
    isValue: true,
  },
]

export const initColumnsSimultaneous = [
  ...initColumns,
  {
    key: COLUMNS_UPDATE.DB_VALUE,
    name: 'DB Value',
    fieldName: COLUMNS_UPDATE.DB_VALUE,
    minWidth: 80,
    maxWidth: 110,
    isResizable: true,
    isValue: true,
  },
  {
    key: COLUMNS_UPDATE.USER_NAME,
    name: 'User Name',
    title: 'Користувач, що останнім вніс зміни до дефолтного проекту по цьому елементу',
    fieldName: COLUMNS_UPDATE.USER_NAME,
    minWidth: 80,
    maxWidth: 110,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: COLUMNS_UPDATE.DATE_TIME,
    name: 'Date and time',
    fieldName: COLUMNS_UPDATE.DATE_TIME,
    minWidth: 80,
    maxWidth: 110,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
]

export const initColumnsConflict = [
  ...initColumns,
  {
    key: COLUMNS_UPDATE.DB_VALUE,
    name: 'DB Value',
    fieldName: COLUMNS_UPDATE.DB_VALUE,
    minWidth: 80,
    maxWidth: 110,
    isResizable: true,
    data: {
      isIndeterminate: false,
      isChecked: false,
    },
    isValue: true,
  },
  {
    key: COLUMNS_UPDATE.USER_NAME,
    name: 'User Name',
    title: 'Користувач, що останнім вніс зміни до дефолтного проекту по цьому елементу',
    fieldName: COLUMNS_UPDATE.USER_NAME,
    minWidth: 80,
    maxWidth: 110,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
  {
    key: COLUMNS_UPDATE.DATE_TIME,
    name: 'Date and time',
    fieldName: COLUMNS_UPDATE.DATE_TIME,
    minWidth: 80,
    maxWidth: 110,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
]

export const initColumnsUpdateBC = [
  {
    key: COLUMNS_UPDATE_BC.SELECT,
    name: '',
    fieldName: COLUMNS_UPDATE_BC.SELECT,
    isSelectable: true,
    minWidth: 32,
    maxWidth: 32,
    value: false,
  },
  {
    key: COLUMNS_UPDATE_BC.GROUP_BC,
    name: 'Group BC',
    fieldName: COLUMNS_UPDATE_BC.GROUP_BC,
    minWidth: 210,
    maxWidth: 220,
    height: 32,
    isGrouping: true,
    isRowHeader: true,
    isResizable: true,
    isSorted: true,
    isSortedDescending: false,
    isPadded: true,
  },
  {
    key: COLUMNS_UPDATE_BC.BC_NAME,
    name: 'BC Name',
    fieldName: COLUMNS_UPDATE_BC.BC_NAME,
    minWidth: 210,
    maxWidth: 220,
    height: 32,
    isGrouping: true,
    isRowHeader: true,
    isResizable: true,
    isSorted: true,
    isSortedDescending: false,
    isPadded: true,
  },
  {
    key: COLUMNS_UPDATE_BC.SITE,
    name: 'Site/Sectors',
    fieldName: COLUMNS_UPDATE_BC.SITE,
    minWidth: 80,
    maxWidth: 110,
    isResizable: true,
    isSorted: true,
    isSortedDescending: false,
    isPadded: true,
  },
  {
    key: COLUMNS_UPDATE_BC.STATUS,
    name: 'Status/Technology',
    fieldName: COLUMNS_UPDATE_BC.STATUS,
    minWidth: 120,
    maxWidth: 150,
    isSorted: false,
    isSortedDescending: false,
    isResizable: true,
    isPadded: true,
  },
  {
    key: COLUMNS_UPDATE_BC.DATE_TIME,
    name: 'Date and time',
    fieldName: COLUMNS_UPDATE_BC.DATE_TIME,
    minWidth: 120,
    maxWidth: 160,
    isResizable: true,
    isSorted: false,
    isSortedDescending: false,
  },
]

export const STATUS_BC_KEYS = {
  SYNCHRONIZED: 'synchronized',
  ON_CALCULATION_DMP: 'on_calculation_dmp',
  ON_CALCULATION_SNC: 'on_calculation_snc',
  SAVED: 'saved',
}

export const STATUS_BC_TEXT = {
  [STATUS_BC_KEYS.SYNCHRONIZED]: 'Synchronized',
  [STATUS_BC_KEYS.ON_CALCULATION_DMP]: 'On calculation DMP',
  [STATUS_BC_KEYS.ON_CALCULATION_SNC]: 'On calculation SNC',
  [STATUS_BC_KEYS.SAVED]: 'Saved',
}
