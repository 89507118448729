import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DropdownMenuItemType } from '@fluentui/react'
import Dropdown from '../../common/Dropdown'
import { findMapById, selectVectorItems } from '../../../features/vector/vectorSlice'
import { selectComplaintFields, selectSectorFields, selectSiteFields } from '../../../features/network/networkSlice'
import { SPREAD_DISCRETE, SPREAD_RANGE } from '../../../constants/settings'
import { isNumberType } from '../../../utils/grid'
import { ELEMENT_TYPES } from './constants'

export const NO_LABELS = '---no-labels---'

const buildAttributeList = (pages, fields) => {
  if (pages === null && Array.isArray(fields)) {
    return fields
      .filter(({ hidden }) => (!hidden))
      .map(({ id, label }) => ({ key: id, text: label }))
  } else {
    const result = []
    pages.forEach((item) => {
      result.push({
        key: item,
        text: item,
        itemType: DropdownMenuItemType.Header,
      })
      fields
        .filter(({ page, hidden }) => !hidden && page === item)
        .forEach(({ id, label }) => {
          result.push({ key: id, text: label })
        })
    })
    return result
  }
}

const filterFields = (fields, spread) => {
  switch (spread) {
    case SPREAD_RANGE: {
      return fields.filter(({ type, hidden }) => !hidden && isNumberType(type))
    }
    case SPREAD_DISCRETE: {
      return fields.filter(({ type, hidden }) => !hidden && !isNumberType(type))
    }
    default: {
      return []
    }
  }
}

export const getInfoVectorMap = (vectorMaps, element) => {
  if (element) {
    const { type, key } = element
    if (type === ELEMENT_TYPES.VECTOR_MAPS) {
      const vectorMap = findMapById(vectorMaps, key)
      if (vectorMap) {
        const {
          idFldIdx,
          nameFldIdx,
          attributes,
          colorFldIdx,
        } = vectorMap
        return {
          vectorMapFields: attributes?.fields ?? [],
          vectorMapRecords: attributes?.list?.getList ? attributes?.list?.getList() : [],
          idIdxVectorMap: idFldIdx,
          nameIdxVectorMap: nameFldIdx,
          colorIdxVectorMap: colorFldIdx,
        }
      }
    }
  }
  return { vectorMapFields: [], vectorMapRecords: [], nameIdxVectorMap: null, colorIdxVectorMap: null }
}

const Attributes = ({ element, selectedKey, mode, onChange }) => {
  const vectorMaps = useSelector(selectVectorItems)

  const siteFields = useSelector(selectSiteFields)
  const sectorFields = useSelector(selectSectorFields)
  const complaintFields = useSelector(selectComplaintFields)
  const { vectorMapFields } = useMemo(() => getInfoVectorMap(vectorMaps, element), [ vectorMaps, element ])

  const filteredSiteFields = useMemo(() => filterFields(siteFields, mode),
    [ mode, siteFields ])
  const filteredSectorFields = useMemo(() => filterFields(sectorFields, mode),
    [ mode, sectorFields ])
  const filteredComplaintFields = useMemo(() => filterFields(complaintFields, mode),
    [ mode, complaintFields ])
  const filteredVectorMapFields = useMemo(() => filterFields(vectorMapFields, mode),
    [ mode, vectorMapFields ])

  const sitePages = useMemo(() => [ ...new Set(siteFields.map(({ page }) => page)) ],
    [ siteFields ])
  const sectorPages = useMemo(() => [ ...new Set(sectorFields.map(({ page }) => page)) ],
    [ sectorFields ])
  const complaintPages = useMemo(() => [ ...new Set(complaintFields.map(({ page }) => page)) ],
    [ complaintFields ])

  const filteredSitePages = useMemo(() => [ ...new Set(filteredSiteFields.map(({ page }) => page)) ],
    [ filteredSiteFields ])
  const filteredSectorPages = useMemo(() => [ ...new Set(filteredSectorFields.map(({ page }) => page)) ],
    [ filteredSectorFields ])
  const filteredComplaintPages = useMemo(() => [ ...new Set(filteredComplaintFields.map(({ page }) => page)) ],
    [ filteredComplaintFields ])

  const siteAttributeOptions = useMemo(() => buildAttributeList(sitePages, siteFields),
    [ siteFields, sitePages ])
  const sectorAttributeOptions = useMemo(() => buildAttributeList(sectorPages, sectorFields),
    [ sectorFields, sectorPages ])
  const complaintAttributeOptions = useMemo(() => buildAttributeList(complaintPages, complaintFields),
    [ complaintFields, complaintPages ])
  const vectorMapAttributeOptions = useMemo(() => {
    const vectorMap = findMapById(vectorMaps, element?.key)
    return vectorMap?.attributes?.fields ? buildAttributeList(null, vectorMap.attributes.fields) : []
  }, [ vectorMaps, element?.key ])

  const attributeOptions = useMemo(() => {
    if (element?.type === ELEMENT_TYPES.SITES) {
      return siteAttributeOptions
    }
    if (element?.type === ELEMENT_TYPES.SECTORS) {
      return sectorAttributeOptions
    }
    if (element?.type === ELEMENT_TYPES.COMPLAINTS) {
      return complaintAttributeOptions
    }
    if (element?.type === ELEMENT_TYPES.VECTOR_MAPS) {
      return vectorMapAttributeOptions
    }
    return []
  },
  [ element, siteAttributeOptions, sectorAttributeOptions, complaintAttributeOptions, vectorMapAttributeOptions ])

  const filteredAttributeOptions = useMemo(() => {
    switch (element?.type) {
      case ELEMENT_TYPES.SITES:
        return buildAttributeList(filteredSitePages, filteredSiteFields)
      case ELEMENT_TYPES.SECTORS:
        return buildAttributeList(filteredSectorPages, filteredSectorFields)
      case ELEMENT_TYPES.COMPLAINTS:
        return buildAttributeList(filteredComplaintPages, filteredComplaintFields)
      case ELEMENT_TYPES.VECTOR_MAPS:
        return buildAttributeList(null, filteredVectorMapFields)
      default:
        return []
    }
  }, [
    element, filteredSectorFields, filteredSectorPages, filteredSiteFields, filteredSitePages, filteredVectorMapFields,
    filteredComplaintFields, filteredComplaintPages,
  ])

  const options = useMemo(() => mode
    ? filteredAttributeOptions
    : [
        { key: NO_LABELS, text: '<No Labels>' },
        ...attributeOptions,
      ],
  [ mode, attributeOptions, filteredAttributeOptions ])

  return (
    <Dropdown
      placeholder="Select an attribute"
      label="Attribute"
      options={options}
      selectedKey={selectedKey}
      onChange={onChange}
      disabled={!options.length}
      styles={{
        dropdown: { width: '100%' },
        dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
        dropdownItem: { height: 'auto' },
      }}
    />
  )
}

export default Attributes
