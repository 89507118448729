import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { StackItem } from '@fluentui/react'
import {
  checkTreeItem as checkTreeItemNetwork, expandTreeItem as expandTreeItemNetwork, locateItem as locateItemNetwork,
  menuItemClick as menuItemClickNetwork, selectNetworkTree, activateTreeItem,
  TOP_INDEX_COVERAGE,
} from '../../../features/network/networkSlice'
import { coverageNameComparator } from '../../../features/network/utils'
import { setPanel } from '../../../features/panel/panelSlice'
import ColorWithPicker from '../../common/ColorWithPicker'
import { COLOR_COVERAGE } from '../../../constants/features'
import { saveUserSettings, selectSettingsUserColors } from '../../../features/settings/settingsSlice'
import { ID_NETWORK_COVERAGE } from '../../../constants/network'
import { COVERAGE_DEFAULT_COLOR } from '../../Map/components/constants'
import VirtualizedTree from './VirtualizedTree'

const cleanMenuItem = (item) => {
  const { key, text, canCheck, isChecked, itemType, subMenuProps: { items } = {} } = item
  const result = { key, text, canCheck, isChecked, itemType }
  if (items?.length) {
    result.items = items.map(cleanMenuItem)
  }
  return result
}

const extractGroupByMenu = (menu) => {
  if (!menu) {
    return false
  }
  const groupItem = menu.find(({ key }) => key === 'group')
  if (!groupItem) {
    return false
  }
  return groupItem.subMenuProps.items.map(cleanMenuItem)
}

const getSettingsKey = (parentkey, key) => (
  `${ID_NETWORK_COVERAGE}-${parentkey}-${key}`
)

const ElementsTree = ({
  topIndex,
  heightCorrection = 'hc321',
  selectable = true,
  expandTreeItem = expandTreeItemNetwork,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const networkTree = useSelector(selectNetworkTree, shallowEqual)
  const userColors = useSelector(selectSettingsUserColors)

  const changeColor = useCallback((id, value) => {
    dispatch(saveUserSettings({ userColors: { [id]: value } }))
  }, [ dispatch ])

  const nodes = useMemo(() => {
    const c = networkTree[topIndex].children || []
    let result = c.getList ? c.getList() : c
    if (topIndex === TOP_INDEX_COVERAGE && COLOR_COVERAGE) {
      result = result?.map((map) => {
        const children = map.children?.map((chld) => {
          const key = getSettingsKey(map.id, chld.name)
          return {
            ...chld,
            suffix: [
              (
                <ColorWithPicker
                  id={key}
                  key="suffix-color"
                  className="zone-item-color"
                  color={userColors[key] || COVERAGE_DEFAULT_COLOR}
                  onChange={changeColor}
                  disabled={false}
                />
              ),
            ],
          }
        })
        const needToSort = children.find((item) => item.name.includes('>='))
        if (needToSort) {
          children.sort(coverageNameComparator(true))
        }

        return {
          ...map,
          children,
        }
      })
    }
    return result
  }, [ networkTree, topIndex, userColors, changeColor ])

  const menu = useMemo(() => {
    return extractGroupByMenu(networkTree[topIndex].menu)
  }, [ networkTree, topIndex ])

  const onSelectNode = useCallback((path) => {
    dispatch(checkTreeItemNetwork(path))
    dispatch(locateItemNetwork(path))
  }, [ dispatch ])

  const onClickNode = useCallback((path) => {
    dispatch(locateItemNetwork(path))
  }, [ dispatch ])

  const onDblClickNode = useCallback((path) => {
    if (topIndex !== TOP_INDEX_COVERAGE) {
      dispatch(activateTreeItem(path, history))
      dispatch(setPanel(null))
    }
  }, [ dispatch, history, topIndex ])

  const onExpandNode = useCallback((path) => {
    dispatch(expandTreeItem(path))
  }, [ dispatch, expandTreeItem ])

  const onMenuItemClick = useCallback((event, item) => {
    dispatch(menuItemClickNetwork([ topIndex ], item.key))
  }, [ dispatch, topIndex ])

  return (
    <StackItem grow style={{ display: 'flex' }} className={heightCorrection}>
      <VirtualizedTree
        nodes={nodes}
        menu={menu}
        onExpandNode={onExpandNode}
        onClickNode={onClickNode}
        onDblClickNode={onDblClickNode}
        onSelectNode={onSelectNode}
        onMenuItemClick={onMenuItemClick}
        selectable={selectable}
      />
    </StackItem>
  )
}

export default ElementsTree
