import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Stack } from '@fluentui/react'
import { createProject, loadProjects } from '../features/projects/projectsSlice'
import { KEYS } from '../components/Cabinet/constants'
import Menu from './Menu'
import Caption from './Caption'

import './Header.css'

const Header = ({ routes }) => {
  const dispatch = useDispatch()

  const handleDoubleClick = useCallback(async (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (event.altKey) {
      // Для полегшення роботи тестувальників, короткий спосіб створення нового проєкту
      const { payload: projects = [] } = await dispatch(loadProjects())
      const maxNumber = projects
        .map(({ description }) => description.match(/^New project (\d+)$/))
        .filter(Boolean)
        .map(([ , number ]) => Number(number))
        .sort((a, b) => a - b)
        .pop() ?? 0
      const id = await dispatch(createProject({
        [KEYS.PROJECT_NAME]: 'NEW PROJECT',
        [KEYS.DESCRIPTION]: `New project ${maxNumber + 1}`,
      }))
      if (id) {
        const path = window.location.pathname
        const defIdIndex = path.lastIndexOf('_/')
        const page = defIdIndex >= 0 ? path.substring(defIdIndex + 2) : path.split('/').pop()
        window.location = `/${id}/${page}`
      }
    }
  }, [ dispatch ])

  return (
    <Stack horizontal className="header" onDoubleClick={handleDoubleClick}>
      <Stack horizontal className="header-left" tokens={{ childrenGap: 8, padding: '0 8px' }} grow>
        <Caption routes={routes} />
      </Stack>
      <Menu routes={routes} />
    </Stack>
  )
}

export default Header
