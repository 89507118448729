import * as updateDB from './updateDB'
import * as login from './login'
import * as geo from './geo'
import * as vector from './vector'
import * as raster from './raster'
import * as settings from './settings'
import * as network from './network'
import * as projects from './projects'
import * as users from './users'
import * as macroIndicators from './macroIndicators'
import * as trf from './trf'
import * as bc from './bc'
import * as compositeIndex from './compositeIndex'
import * as jobs from './jobs'
import * as history from './history'
import * as eventLog from './eventLog'
import * as notifications from './notifications'
import * as reports from './reports'
import * as metrics from './metrics'
import * as settlements from './settlements'

const API = {
  login,
  geo,
  vector,
  raster,
  settings,
  network,
  projects,
  users,
  macroIndicators,
  trf,
  bc,
  updateDB,
  compositeIndex,
  jobs,
  history,
  eventLog,
  notifications,
  reports,
  metrics,
  settlements,
}

export default API
