export const confirm = {
  ok: 'Ok',
  no: 'No',
  yes: 'Yes',
  apply: 'Apply',
  cancel: 'Cancel',
  continue: 'Continue',
  DEF_CONFIRM_TITLE: 'Confirm Action',
  confirmation: 'Confirmation',
}
