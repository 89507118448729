import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react'
import { DefaultButton, PrimaryButton } from '../../common/Button'
import { loadUsers, selectUsers } from '../../../features/users/usersSlice'
import { selectUser } from '../../../features/login/loginSlice'
import UserPickerList from '../UserPickerList'
import { KEYS } from '../constants'

import '../style.css'
import { getColumnIndexById } from '../Project/settings'
import { userHasSubRoleUser, userIsFPA } from '../../../features/login/utils'

const MIN_WIDTH_DIALOG = 480
const MAX_COUNT_MESSAGES = 5

const ShareProjectForm = ({ isShow, onHide, onOk, projects, onlyFPA }) => {
  const dispatch = useDispatch()
  const userPickerRef = useRef(null)

  const users = useSelector(selectUsers)
  const myUser = useSelector(selectUser)

  const usersAvailableForShare = useMemo(() => {
    return users
      .filter(onlyFPA ? userIsFPA : userHasSubRoleUser)
      .filter(({ id }) => id !== myUser.id)
  }, [ users, myUser, onlyFPA ])

  const [ selectedUsers, setSelectedUsers ] = useState([])
  const [ projectsSelected, setProjectsSelected ] = useState([])

  useEffect(() => {
    if (isShow) { dispatch(loadUsers()) }
  }, [ dispatch, isShow ])

  useEffect(() => {
    setProjectsSelected(projects ?? [])
  }, [ projects ])

  const handleOk = useCallback(() => {
    onOk(projectsSelected, selectedUsers)
  }, [ projectsSelected, selectedUsers, onOk ])

  return (
    <Dialog
      hidden={!isShow}
      onDismiss={onHide}
      dialogContentProps={{
        type: DialogType.normal,
        title: onlyFPA ? 'Share the project for approving' : 'Granting access to users to projects',
      }}
      minWidth={MIN_WIDTH_DIALOG}
      modalProps={{
        isBlocking: true,
        className: 'share-project',
      }}
    >
      {isShow && (
        <>
          <div>
            <p className="title">
              {`Share ${projectsSelected.length} projects:`}
            </p>
            {projectsSelected.slice(0, MAX_COUNT_MESSAGES).map((project, index) => {
              const columnId = getColumnIndexById(KEYS.ID)
              const columnName = getColumnIndexById(KEYS.DESCRIPTION)
              if (index === MAX_COUNT_MESSAGES - 1 && MAX_COUNT_MESSAGES !== projectsSelected.length) {
                return (
                  <p key={project[columnId]} className="lastItem">...</p>
                )
              }
              return (
                <p key={project[columnId]} className="item">
                  {project[columnName] ?? '-'}
                </p>
              )
            })}
            <p className="title">
              Select users
            </p>
          </div>
          <UserPickerList
            userPickerRef={userPickerRef}
            setSelectedUsers={setSelectedUsers}
            users={usersAvailableForShare}
          />
          <DialogFooter>
            {onOk && (
              <PrimaryButton
                text="Share"
                onClick={handleOk}
                disabled={selectedUsers.length === 0}
              />
            )}
            <DefaultButton onClick={onHide} text="Cancel" />
          </DialogFooter>
        </>
      )}
    </Dialog>
  )
}

export default ShareProjectForm
