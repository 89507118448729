import React from 'react'

import './AutosaveModeButton.css'

const AutosaveModeButton = ({ text, onClick, ...rest }) => {
  return (
    <button className="autosave-mode-button" onClick={onClick} {...rest}>
      {text}
    </button>
  )
}

export default AutosaveModeButton
