import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { createPortal } from 'react-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Stack, StackItem } from '@fluentui/react'
import deepmerge from 'deepmerge'
import convert from 'color-convert'
import PanelSections from '../../PanelSections'
import { IconButton, PrimaryButton, SecondaryButton } from '../../../common/Button'
import { FullscreenTableIcon } from '../../../common/icons/names'
import {
  selectSettingsDisplay, saveSettings, DEFAULT_COLORING, restoreDefaults,
} from '../../../../features/settings/settingsSlice'
import {
  elementsRedraw, selectComplaintFields, selectComplaintFldIdx, selectComplaints, selectSectorFields,
  selectSectorFldIdx, selectSectors, selectSiteFields, selectSiteFldIdx, selectSites,
} from '../../../../features/network/networkSlice'
import { selectVectorItems } from '../../../../features/vector/vectorSlice'
import { selectAdditionalPanel, setAdditionalPanel } from '../../../../features/panel/panelSlice'
import { useConfirm } from '../../../common/Confirm'
import { empty, numberFormatting } from '../../../../utils/format'
import { SPREAD_DISCRETE, SPREAD_RANGE } from '../../../../constants/settings'
import { adjustableSpreads, ELEMENT_TYPES } from '../constants'
import { extractDisplaySlice } from '../utils'
import { getInfoVectorMap } from '../Attributes'
import ElementsMode from './ElementsMode'
import ColorValues from './ColorValues'
import Preview from './Preview'

const MAX_TABLE_SIZE = 1000

const A_INIT = 'A_INIT'
const A_RESET = 'A_RESET'
const A_UPDATE = 'A_UPDATE'

const SITES_ELEMENT = { key: 'sites', type: ELEMENT_TYPES.SITES }

const EPSILON = 0.000001

const arrayMerge = (_, src) => src

const merge = (a, b) => deepmerge(a, b, { arrayMerge })

const reducer = (state, action) => {
  switch (action.type) {
    case A_INIT: {
      return merge(state ?? {}, action.payload)
    }
    case A_RESET: {
      return
    }
    case A_UPDATE: {
      const { payload: { element, slice } } = action
      return element.type === ELEMENT_TYPES.VECTOR_MAPS
        ? {
            ...state,
            [ELEMENT_TYPES.VECTOR_MAPS]: {
              ...state[ELEMENT_TYPES.VECTOR_MAPS],
              [element.key]: merge(state[element.type][element.key], slice),
            },
          }
        : {
            ...state,
            [element.key]: merge(state[element.key], slice),
          }
    }
    default: {
      return state
    }
  }
}

const thousandSeparator = (x) => x === null ? '' : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const findMinMax = (records, idx) => {
  let max = -Infinity
  let min = Infinity
  for (const record of records) {
    if (record[idx] < min) {
      min = record[idx]
    }
    if (record[idx] > max) {
      max = record[idx]
    }
  }
  if (min === -Infinity) {
    min = null
  }
  if (max === Infinity) {
    max = null
  }
  return [ min, max ]
}

const prp = (from, to, factor) => from + (to - from) * factor

const calculateColor = (from, to, factor) => {
  if (!factor) {
    return from
  }
  const fa = +`0x${from.slice(7)}`
  const ta = +`0x${to.slice(7)}`
  const [ fh, fs, fv ] = convert.hex.hsv(from.slice(1, 7))
  const [ th, ts, tv ] = convert.hex.hsv(to.slice(1, 7))
  const [ r, g, b ] = convert.hsv.rgb(prp(fh, th, factor), prp(fs, ts, factor), prp(fv, tv, factor))
  return `#${convert.rgb.hex(r, g, b)}${`0${Math.round(prp(fa, ta, factor)).toString(16)}`.slice(-2)}`
}

const calculateColorTable = (coloring, field) => {
  let from = coloring.start.color
  let min = coloring.start.value
  let to = coloring.end.color
  let max = coloring.end.value
  let step = coloring.step
  if (step < EPSILON) {
    step = 1
  }
  if (min === null) {
    min = 0
  }
  if (max === null) {
    max = 0
  }
  if (min > max) {
    [ min, max ] = [ max, min ]
    ;[ from, to ] = [ to, from ]
  }
  const table = []
  if (min !== max) {
    let prev = min
    table.push({
      minValue: null,
      maxValue: min,
      min: null,
      max: numberFormatting(min),
      description: `${field} < ${numberFormatting(min)}`,
      color: from,
    })
    let count = 0
    for (let cur = min + step; cur <= max; cur += step) {
      cur = +cur.toFixed(12)
      table.push({
        minValue: prev,
        maxValue: cur,
        min: numberFormatting(prev),
        max: numberFormatting(cur),
        description: `${numberFormatting(prev)} ≤ ${field} < ${numberFormatting(cur)}`,
        color: calculateColor(from, to, (cur - min) / (max + step - min)),
      })
      prev = cur
      count++
      if (count > MAX_TABLE_SIZE) {
        break
      }
    }
    table.push({
      minValue: max,
      maxValue: null,
      min: numberFormatting(max),
      max: null,
      description: `${numberFormatting(max)} ≤ ${field}`,
      color: to,
    })
  } else {
    table.push({
      minValue: min,
      maxValue: max,
      min: numberFormatting(min),
      max: numberFormatting(max),
      description: `${field} = ${thousandSeparator(min)}`,
      color: from,
    })
  }
  coloring.table = table
}

const log10 = (x) => Math.log(x) / Math.log(10)

const calculateStep = (min, max) => {
  if (min === max) {
    return 0
  }
  if (max - min <= 1) {
    return Math.pow(10, Math.min(-1, Math.round(log10(Math.abs(min - max) / 10))))
  }
  return Math.max(1, Math.trunc(Math.abs(min - max) / 10))
}

const calculateRangeSpreadLimits = (coloring, records, attrIdx, fields) => {
  const [ min, max ] = findMinMax(records, attrIdx)
  const result = {
    ...coloring,
    start: {
      ...coloring.start,
      value: min,
    },
    end: {
      ...coloring.end,
      value: max,
    },
    step: calculateStep(min, max),
  }
  calculateColorTable(result, fields[attrIdx].label)
  return result
}

const calculateRangeSpread = (coloring, records, attrIdx, fields) => {
  const result = { ...coloring }
  calculateColorTable(result, fields[attrIdx].label)
  return result
}

const calculateDiscreteSpread = (coloring, records, attrIdx, field) => {
  const result = { ...coloring }
  const distinct = field.type === 'boolean'
    ? [ ...new Set([ true, false ]) ]
    : [ ...new Set(records.map((record) => record[attrIdx])) ]
  distinct.sort()
  const from = coloring.start.color
  const to = coloring.end.color
  const l = distinct.length
  let table = []
  if (l === 1) {
    table = [ {
      color: from,
      value: distinct[0],
    } ]
  }
  if (l > 1) {
    const steps = l - 1
    for (let i = 0; i <= steps; i++) {
      table.push({
        color: calculateColor(from, to, i / steps),
        value: distinct[i],
      })
    }
  }
  result.fieldType = field.type
  result.fieldLabel = field.label
  result.table = table
  return result
}

const Colors = ({ confirmClose, additionalPanelRef }) => {
  const dispatch = useDispatch()
  const { ask, msg, renderConfirm } = useConfirm()

  const [ modified, setModified ] = useState(false)
  const [ element, setElement ] = useState(SITES_ELEMENT)
  const [ current, dispatchCurrent ] = useReducer(reducer)

  const additionalPanel = useSelector(selectAdditionalPanel)
  const displaySettings = useSelector(selectSettingsDisplay)
  const vectorMaps = useSelector(selectVectorItems)
  const sites = useSelector(selectSites).getList()
  const siteFields = useSelector(selectSiteFields)
  const sectors = useSelector(selectSectors).getList()
  const complaints = useSelector(selectComplaints).getList()
  const [ idIdx1, nameIdx1 ] = useSelector(selectSiteFldIdx, shallowEqual)
  const sectorFields = useSelector(selectSectorFields)
  const [ idIdx2, nameIdx2, , , , , , typeIdx2 ] = useSelector(selectSectorFldIdx, shallowEqual)
  const complaintFields = useSelector(selectComplaintFields)
  const [ idIdx3 ] = useSelector(selectComplaintFldIdx, shallowEqual)

  useEffect(() => {
    if (!current && displaySettings) {
      dispatchCurrent({ type: A_INIT, payload: displaySettings })
    }
  }, [ current, displaySettings ])

  const slice = useMemo(() => extractDisplaySlice(current, element), [ current, element ])

  const { vectorMapFields, vectorMapRecords, idIdxVectorMap, nameIdxVectorMap, colorIdxVectorMap } = useMemo(
    () => getInfoVectorMap(vectorMaps, element), [ vectorMaps, element ])

  const idIdx = useMemo(() => (
    element.type === ELEMENT_TYPES.SITES
      ? idIdx1
      : element.type === ELEMENT_TYPES.SECTORS
        ? idIdx2
        : element.type === ELEMENT_TYPES.COMPLAINTS
          ? idIdx3
          : element.type === ELEMENT_TYPES.VECTOR_MAPS
            ? idIdxVectorMap
            : null
  ), [ idIdx1, idIdx2, idIdx3, element, idIdxVectorMap ])

  const nameIdx = useMemo(() => (
    element.type === ELEMENT_TYPES.SITES
      ? nameIdx1
      : element.type === ELEMENT_TYPES.SECTORS
        ? nameIdx2
        : element.type === ELEMENT_TYPES.COMPLAINTS
          ? idIdx3
          : element.type === ELEMENT_TYPES.VECTOR_MAPS
            ? nameIdxVectorMap
            : null
  ), [ nameIdx1, nameIdx2, idIdx3, element, nameIdxVectorMap ])

  const colorIdx = useMemo(() => (
    element.type === ELEMENT_TYPES.VECTOR_MAPS
      ? colorIdxVectorMap
      : -1
  ), [ colorIdxVectorMap, element ])

  const records = useMemo(() => (
    element.type === ELEMENT_TYPES.SITES
      ? sites
      : element.type === ELEMENT_TYPES.SECTORS
        ? sectors.filter((record) => record[typeIdx2].startsWith(element.key))
        : element.type === ELEMENT_TYPES.COMPLAINTS
          ? complaints
          : element.type === ELEMENT_TYPES.VECTOR_MAPS
            ? vectorMapRecords
            : []
  ), [ element, sites, sectors, complaints, typeIdx2, vectorMapRecords ])

  const fields = useMemo(() => (
    element.type === ELEMENT_TYPES.SITES
      ? siteFields
      : element.type === ELEMENT_TYPES.SECTORS
        ? sectorFields
        : element.type === ELEMENT_TYPES.COMPLAINTS
          ? complaintFields
          : element.type === ELEMENT_TYPES.VECTOR_MAPS
            ? vectorMapFields
            : []
  ), [ element, siteFields, sectorFields, complaintFields, vectorMapFields ])

  const changeElement = useCallback((event, option) => {
    setElement(option)
  }, [])

  const changeMode = useCallback((event, option) => {
    if (option) {
      dispatchCurrent({ type: A_UPDATE, payload: { element, slice: { spread: option.key, attribute: null } } })
      setModified(true)
    }
  }, [ element ])

  const changeColoring = useCallback((coloring, attribute) => {
    attribute = attribute ?? slice.attribute
    localStorage.setItem(`${element.type}_${element.key}_${slice?.spread}_${attribute}`, JSON.stringify(coloring))
    const attrIdx = fields.findIndex(({ id }) => id === attribute)
    switch (slice?.spread) {
      case SPREAD_RANGE: {
        coloring = calculateRangeSpread(merge(slice?.coloring, coloring), records, attrIdx, fields)
        break
      }
      case SPREAD_DISCRETE: {
        const idx = fields.findIndex(
          ({ id }, index) => ((index !== 0 || attribute !== 'id') && id === attribute),
        )
        coloring = calculateDiscreteSpread(merge(slice?.coloring, coloring), records, attrIdx, fields[idx])
        coloring.start.value = null
        coloring.end.value = null
        coloring.step = null
        break
      }
      default: {
        coloring.table = []
      }
    }
    const { table, ...rest } = coloring
    localStorage.setItem(`${element.type}_${element.key}_${slice?.spread}_${attribute}`, JSON.stringify(rest))
    dispatchCurrent({ type: A_UPDATE, payload: { element, slice: { coloring } } })
    setModified(true)
  }, [ element, slice, records, fields ])

  const changeAttribute = useCallback((event, option) => {
    const attribute = option.key
    dispatchCurrent({ type: A_UPDATE, payload: { element, slice: { attribute } } })
    const cached = localStorage.getItem(`${element.type}_${element.key}_${slice?.spread}_${attribute}`)
    changeColoring(cached ? JSON.parse(cached) : DEFAULT_COLORING, attribute)
  }, [ element, changeColoring, slice?.spread ])

  const setAutoValues = useCallback(() => {
    const attrIdx = fields.findIndex(({ id }) => id === slice?.attribute)
    changeColoring(calculateRangeSpreadLimits(slice?.coloring, records, attrIdx, fields))
  }, [ slice?.coloring, slice?.attribute, changeColoring, records, fields ])

  const openAdditionalPanel = useCallback(() => {
    dispatch(setAdditionalPanel('Preview'))
  }, [ dispatch ])

  // eslint-disable-next-line react/display-name
  const PreviewComponent = useMemo(() => () => (
    <Preview
      idIdx={idIdx}
      nameIdx={nameIdx}
      colorIdx={colorIdx}
      techIdx={typeIdx2}
      records={records}
      element={element}
      mode={slice?.spread}
      coloring={slice?.coloring}
      attribute={slice?.attribute}
      expanded={additionalPanel}
    />
  ), [ idIdx, nameIdx, colorIdx, typeIdx2, records, element, slice, additionalPanel ])

  const items = useMemo(() => [
    {
      id: ' elements',
      title: 'Select Elements',
      Component: () => (
        <ElementsMode
          element={element}
          onChangeElement={changeElement}
          mode={slice?.spread}
          onChangeMode={changeMode}
          attribute={slice?.attribute}
          onChangeAttribute={changeAttribute}
        />
      ),
    },
    {
      id: 'colors',
      title: 'Set Colors',
      hidden: !element || !slice?.spread || !slice?.attribute || !adjustableSpreads.includes(slice?.spread),
      Component: () => (
        <ColorValues
          mode={slice?.spread}
          coloring={slice?.coloring}
          onChange={changeColoring}
          onAutoClick={setAutoValues}
        />
      ),
    },
    {
      id: 'preview',
      title: 'Preview',
      hidden: !element || !slice?.spread || (adjustableSpreads.includes(slice?.spread) && !slice?.attribute) ||
        additionalPanel !== null,
      Component: PreviewComponent,
      suffix: (
        <IconButton icon={FullscreenTableIcon} onClick={openAdditionalPanel} />
      ),
    },
  ], [
    slice, element, changeElement, changeMode, changeAttribute, changeColoring, setAutoValues, PreviewComponent,
    openAdditionalPanel, additionalPanel,
  ])

  const onReset = useCallback(() => {
    const doReset = async () => {
      await dispatch(restoreDefaults())
      dispatchCurrent({ type: A_RESET })
      setModified(false)
      setElement(SITES_ELEMENT)
      setTimeout(() => {
        dispatch(elementsRedraw())
        msg({ messages: [ 'Map Styles & Colors were successfully reset to default values.' ] })
      }, 0)
    }

    ask(
      doReset,
      empty,
      null,
      {
        title: 'Confirmation',
        messages: [ 'All settings will be reset to default values' ],
        textYesBtn: 'Continue',
        textNoBtn: 'Cancel',
      },
    )
  }, [ dispatch, ask, msg ])

  const onSave = useCallback(() => {
    dispatch(saveSettings({ display: current }, true))
    dispatch(elementsRedraw())
    setModified(false)
  }, [ dispatch, current ])

  const doConfirmClose = useCallback(async () => {
    const onCancel = () => {
      dispatchCurrent({ type: A_RESET })
      setModified(false)
    }

    if (!modified) {
      onCancel()
      return true
    }

    return new Promise((resolve) => {
      ask(
        () => {
          onCancel()
          resolve(true)
        },
        () => resolve(false),
        null,
        {
          messages: [ 'Cancel all changes?' ],
        },
      )
    })
  }, [ modified, ask ])

  useEffect(() => {
    if (confirmClose) {
      confirmClose.check = doConfirmClose
    }
  }, [ confirmClose, doConfirmClose ])

  return (
    <Stack className="panel-stack full-height">
      <StackItem grow className="panel-sections-container">
        <PanelSections collapsible={false} items={items} />
      </StackItem>
      <StackItem className="panel-button-container">
        <SecondaryButton
          text="Reset Default"
          onClick={onReset}
        />
        <PrimaryButton
          text="Save"
          onClick={onSave}
          disabled={!modified}
        />
      </StackItem>
      {renderConfirm()}
      {additionalPanel !== null && additionalPanelRef && additionalPanelRef.current && createPortal(
        <PreviewComponent />,
        additionalPanelRef.current,
      )}
    </Stack>
  )
}

export default Colors
