import React, { useCallback, useEffect } from 'react'
import { Label } from '@fluentui/react/lib/Label'
import { Dropdown, Stack } from '@fluentui/react'
import { renderIconSector } from '../../../Map/draw'

export const DEF_WIDTH = 64
export const DEF_HEIGHT = 32
export const DEFAULT_SECTOR_ICON = 0
export const ID_SECTOR = 'icon-sector-item'
export const ID_SECTOR_OPTION = 'icon-sector-option'

const PATTERN_COUNT = 10 // Відповідає кількості рутин в drawSectorDetails (модуль src/components/Map/draw.js)

const optionsSector = new Array(PATTERN_COUNT).fill(null).map((_, index) => ({
  key: index,
  text: index,
}))

const iconSectorStyles = { marginLeft: 8, width: DEF_WIDTH, height: DEF_HEIGHT }

const ItemOption = ({ figure }) => {
  useEffect(() => {
    const idContainer = ID_SECTOR_OPTION + figure
    renderIconSector(idContainer, figure)
  }, [ figure ])

  return (
    <div id={ID_SECTOR_OPTION + figure} style={iconSectorStyles} />
  )
}

const onRenderOption = (option) => (
  <ItemOption figure={option.key} />
)

const ItemTitle = ({ figure, name }) => {
  useEffect(() => {
    const idContainer = ID_SECTOR + name
    renderIconSector(idContainer, figure)
  }, [ figure, name ])

  return (
    <div id={ID_SECTOR + name} style={iconSectorStyles} />
  )
}

const IconSector = ({ name, type = DEFAULT_SECTOR_ICON, onChange }) => {
  const onRenderTitle = useCallback(() => (
    <ItemTitle name={name} figure={type} />
  ), [ name, type ])

  const onChangeHandler = useCallback((event, item) => {
    onChange(name, item)
  }, [ name, onChange ])

  return (
    <Stack horizontal verticalAlign="start" style={{ marginTop: 16 }}>
      <Label style={{ width: 100 }}>
        {name}
      </Label>
      <Dropdown
        style={{ width: 100 }}
        options={optionsSector}
        onRenderOption={onRenderOption}
        onRenderTitle={onRenderTitle}
        selectedKey={type}
        onChange={onChangeHandler}
      />
    </Stack>
  )
}

export default IconSector
