import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useBoolean } from '@fluentui/react-hooks'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import { DefaultButton, PrimaryButton } from '../../common/Button'
import { selectSectorFields, selectSectorsFull } from '../../../features/network/networkSlice'
import { findIndex } from '../../../features/network/indexing'
import {
  SECTOR_ID_FIELD, SECTOR_NAME_FIELD, SECTOR_SITE_FIELD, SECTOR_STATUS_FIELD, STATUS_DRAFT,
} from '../../../constants/network'
import Checkbox from '../../common/Checkbox'
import Subheader from '../../common/Subheader'

import './EditSectors.css'

const MIN_WIDTH = 600

export const EditSectors = ({ show, onClose, onChange, bcId, siteId, sectedSectorIds }) => {
  const sectorsData = useSelector(selectSectorsFull)
  const sectorFields = useSelector(selectSectorFields)

  const [ selected, setSelected ] = useState(null)
  const [ isModified, { setTrue: setModified, setFalse: setNotModified } ] = useBoolean(false)
  // const [ modified, setModified ] = useState(null)

  const sectorIdColIndex = useMemo(() => findIndex(sectorFields, SECTOR_ID_FIELD), [ sectorFields ])
  const sectorNameColIndex = useMemo(() => findIndex(sectorFields, SECTOR_NAME_FIELD), [ sectorFields ])
  const sectorStatusColIndex = useMemo(() => findIndex(sectorFields, SECTOR_STATUS_FIELD), [ sectorFields ])
  const sectors = useMemo(() => {
    if (show) {
      const sectors = sectorsData.findRangeByValue(siteId, SECTOR_SITE_FIELD)
      if (sectors?.length > 0) {
        return sectors.reduce((agg, ind) => {
          const sector = sectorsData.getList()[ind]
          if (sector[sectorStatusColIndex] === STATUS_DRAFT) {
            agg[sector[sectorIdColIndex]] = sector[sectorNameColIndex]
          }
          return agg
        }, {})
      }
    }
    return []
  }, [ show, siteId, sectorsData, sectorIdColIndex, sectorNameColIndex, sectorStatusColIndex ])

  useEffect(() => {
    if (sectedSectorIds && sectedSectorIds.length > 0) {
      const selected = sectedSectorIds.reduce((agg, id) => {
        const idx = sectorsData?.findIndexById(id)
        if (idx && sectorsData.getList()[idx] && sectorsData.getList()[idx]?.[sectorStatusColIndex] === STATUS_DRAFT) {
          agg[id] = sectorsData.getList()[idx][sectorNameColIndex]
        }
        return agg
      }, {})
      setSelected(selected)
      setNotModified()
    }
  }, [ sectorsData, sectedSectorIds, sectorNameColIndex, sectorStatusColIndex, setNotModified ])

  const onClickSave = useCallback(async () => {
    if (selected) {
      onChange(bcId, selected)
      setSelected(null)
      setNotModified()
      onClose()
    }
  }, [ onChange, onClose, bcId, selected, setNotModified ])

  const onClickClose = useCallback(() => {
    setSelected(null)
    setNotModified()
    onClose()
  }, [ onClose, setNotModified ])

  const handleChangeSectors = useCallback((id) => () => {
    let newModified = {}
    if (!selected?.[id]) {
      newModified = { ...(selected || {}) }
      newModified[id] = sectors[id]
    } else {
      const newKeys = Object.keys(selected).filter((value) => value !== id)
      if (newKeys.length > 0) {
        newKeys.forEach((key) => {
          newModified[key] = sectors[key]
        })
      } else {
        newModified = null
      }
    }
    setSelected(newModified)
    setModified()
  }, [ sectors, selected, setModified ])

  return show && (
    <>
      <Dialog
        hidden={!show}
        onDismiss={onClickClose}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Edit sectors',
        }}
        minWidth={MIN_WIDTH}
        modalProps={{
          isBlocking: true,
        }}
      >
        <Subheader text="Select which sectors should be calculated" />
        <div className='capex-edit-sectors-list-container'>
          <div className='capex-edit-sectors-list'>
            {Object.keys(sectors).map((id, index) => {
              return (
                <Checkbox
                  key={id}
                  checked={!!selected?.[id]}
                  label={sectors[id]}
                  onChange={handleChangeSectors(id)}
                />
              )
            })}
          </div>
        </div>
        <DialogFooter>
          <PrimaryButton onClick={onClickSave} disabled={!isModified || !selected} text={'Save'} />
          <DefaultButton onClick={onClickClose} text={'Close'} />
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default EditSectors
