import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { useHistory, useLocation } from 'react-router-dom'
import { DefaultButton, PrimaryButton, Spinner, TagPicker, ContextualMenu, ActionButton, Icon } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@fluentui/react-accordion'
import {
  resetAllFilters, selectHasNetworkFilters, selectSectorFields, selectSectorsFull, selectSiteFields, selectSitesFull,
} from '../../../features/network/networkSlice'
import { findIndex } from '../../../features/network/indexing'
import {
  SECTOR_ID_FIELD, SECTOR_NAME_FIELD, SECTOR_SITE_FIELD, SECTOR_STATUS_FIELD,
  SECTOR_TECH_FIELD, SITE_LAT_FIELD, SITE_LNG_FIELD, STATUS_ACTIVE,
} from '../../../constants/network'
import { ADD_NEIGHBORS_ON_MAP, APPLY_SECTORS_FILTER } from '../../../constants/features'

import './EditBCNeighbors.css'
import { DATA_TYPES } from '../../../constants/common'
import { addFilterToTable, dataFilteringByType } from '../../../features/filters/filters'
import { useConfirm } from '../../common/Confirm'
import { RESET_FILTERS_CONFIRMATION_MESSAGE } from '../../../layout/Caption'
import { activeProjectId } from '../../../features/projects/projectsSlice'
import {
  addModifiedValues, hideEditNeighbors, selectCreateBCFields, selectModifiedNeighbors,
  selectEditNeighborsForBC, updateBCNeighbors, setModifiedNeighbors,
  setIsModifiedNeighbors, selectIsModifiedNeighbors,
  selectSelectedModificationZone, setSelectedModificationZone, insertNeighborsFromRawText,
} from '../../../features/bc/bcSlice'
import {
  BC_NEIGHBORS_FIELD, BC_SITE_FIELD, BC_SITE_ID_FIELD, BC_STATUS_ID, BC_STATUS_ID_FIELD,
} from '../../../features/bc/constants'
import { setBounce } from '../../../features/geo/geoSlice'
import { setPanel, selectPanel } from '../../../features/panel/panelSlice'

const DIALOG_WIDTH = '600px !important'
const DIALOG_WIDTH_MAP = '450px !important'

const editIcon = {
  iconName: 'Edit',
}

const removeIcon = {
  iconName: 'Cancel',
}

export const EditBCNeighbors = ({ activeSector, onShow, onSelectSite, onSelectSector }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { ask, renderConfirm } = useConfirm()
  const [ isFiltering, { setTrue: startFiltering, setFalse: finishFiltering } ] = useBoolean(false)
  const [ bcURI, setBCURI ] = useState(null)

  const editNeighborsForBC = useSelector(selectEditNeighborsForBC)
  const modifiedNeighbors = useSelector(selectModifiedNeighbors)
  const isModifiedNeighbors = useSelector(selectIsModifiedNeighbors)
  const selectedModificationZone = useSelector(selectSelectedModificationZone)
  const sitesFull = useSelector(selectSitesFull)
  const sitesFields = useSelector(selectSiteFields)
  const sectorsFull = useSelector(selectSectorsFull)
  const sectorsFields = useSelector(selectSectorFields)
  const createBcFields = useSelector(selectCreateBCFields)
  const hasFilters = useSelector(selectHasNetworkFilters)
  const projectId = useSelector(activeProjectId)
  const activePanel = useSelector(selectPanel)

  const sectorIdFldIdx = useMemo(() => findIndex(sectorsFields, SECTOR_ID_FIELD), [ sectorsFields ])
  const sectorSiteIdFldIdx = useMemo(() => findIndex(sectorsFields, SECTOR_SITE_FIELD), [ sectorsFields ])
  const sectorNameFldIdx = useMemo(() => findIndex(sectorsFields, SECTOR_NAME_FIELD), [ sectorsFields ])
  const sectorTechFldIdx = useMemo(() => findIndex(sectorsFields, SECTOR_TECH_FIELD), [ sectorsFields ])
  const sectorStatusFldIdx = useMemo(() => findIndex(sectorsFields, SECTOR_STATUS_FIELD), [ sectorsFields ])
  const sectorNames = useMemo(() => {
    return sectorsFull?.getList()?.filter((sector) => sector[sectorStatusFldIdx] === STATUS_ACTIVE)?.map((sector) => {
      const name = sector[sectorNameFldIdx]
      return { key: name, name }
    })
  }, [ sectorsFull, sectorNameFldIdx, sectorStatusFldIdx ])
  const bcSiteIdFldIdx = useMemo(() => findIndex(createBcFields, BC_SITE_ID_FIELD), [ createBcFields ])
  const bcSiteNameFldIdx = useMemo(() => findIndex(createBcFields, BC_SITE_FIELD), [ createBcFields ])
  const siteLatFldIdx = useMemo(() => findIndex(sitesFields, SITE_LAT_FIELD), [ sitesFields ])
  const siteLngFldIdx = useMemo(() => findIndex(sitesFields, SITE_LNG_FIELD), [ sitesFields ])
  const isMap = useMemo(() => location.pathname?.endsWith('map'), [ location.pathname ])
  const dialogWidth = useMemo(() => isMap ? DIALOG_WIDTH_MAP : DIALOG_WIDTH, [ isMap ])
  const siteName = useMemo(() => {
    return editNeighborsForBC?.bc?.[bcSiteNameFldIdx]
  }, [ editNeighborsForBC, bcSiteNameFldIdx ])
  const siteId = useMemo(() => {
    return editNeighborsForBC?.bc?.[bcSiteIdFldIdx]
  }, [ editNeighborsForBC, bcSiteIdFldIdx ])

  const activeSectorName = useMemo(() => {
    const sectorInd = sectorsFull.findIndexById(activeSector)
    if (sectorInd >= 0) {
      return sectorsFull.getList()[sectorInd][sectorNameFldIdx]
    }
  }, [ activeSector, sectorsFull, sectorNameFldIdx ])

  const modifiedNeighborsNotEmpty = useMemo(() => {
    return modifiedNeighbors && Object.values(modifiedNeighbors).find((sectors) => {
      return Object.values(sectors).find((neighbors) => neighbors?.length > 0)
    })
  }, [ modifiedNeighbors ])

  useEffect(() => {
    if (editNeighborsForBC?.neighbors) {
      onShow()
      dispatch(setIsModifiedNeighbors(false))
      dispatch(setModifiedNeighbors(editNeighborsForBC.neighbors.data))
    }
  }, [ dispatch, editNeighborsForBC?.neighbors, onShow ])

  useEffect(() => {
    if (!isMap) {
      dispatch(setSelectedModificationZone(null))
    }
  }, [ dispatch, isMap ])

  useEffect(() => {
    if (activeSector && modifiedNeighbors) {
      const sectorInd = sectorsFull.findIndexById(activeSector)
      if (sectorInd >= 0) {
        const name = sectorsFull.getList()[sectorInd][sectorNameFldIdx]
        const tech = sectorsFull.getList()[sectorInd][sectorTechFldIdx]
        if (modifiedNeighbors[tech]) {
          const found = Object.keys(modifiedNeighbors[tech]).find((key) => key === name)
          if (found) {
            dispatch(setSelectedModificationZone({ name, tech, type: 'sector' }))
          }
        }
      }
    }
  }, [ dispatch, activeSector, sectorsFull, sectorNameFldIdx, sectorTechFldIdx, modifiedNeighbors ])

  const handleBackToBC = useCallback(() => {
    if (activePanel) {
      dispatch(setPanel(null))
    }
    if (bcURI) {
      history.replace(bcURI)
    }
    setBCURI(null)
  }, [ dispatch, activePanel, history, bcURI, setBCURI ])

  const handleHide = useCallback(() => {
    if (isMap) {
      handleBackToBC()
    }
    dispatch(hideEditNeighbors())
    setBCURI(null)
    dispatch(setIsModifiedNeighbors(false))
  }, [ isMap, handleBackToBC, dispatch ])

  const handleRemoveAll = useCallback(() => {
    const doRemoveAll = () => {
      const newModifiedValue = Object.keys(modifiedNeighbors).reduce((agg, tech) => {
        agg[tech] = Object.keys(modifiedNeighbors[tech]).reduce((techAgg, sector) => {
          techAgg[sector] = []
          return techAgg
        }, {})
        return agg
      }, {})
      dispatch(setModifiedNeighbors(newModifiedValue))
      dispatch(setIsModifiedNeighbors(true))
    }

    ask(
      doRemoveAll,
      () => {},
      null,
      {
        title: 'Confirmation',
        messages: 'You are about to remove all neighboring sectors. Continue?',
        textYesBtn: 'Yes',
        textNoBtn: 'No',
      },
    )
  }, [ ask, dispatch, modifiedNeighbors ])

  const handleApply = useCallback(() => {
    const doApply = () => {
      if (editNeighborsForBC && modifiedNeighbors) {
        const id = editNeighborsForBC.id
        dispatch(updateBCNeighbors(id, modifiedNeighbors))
        dispatch(addModifiedValues({
          [id]: {
            [BC_STATUS_ID_FIELD]: BC_STATUS_ID.NEW,
            [BC_NEIGHBORS_FIELD]: modifiedNeighbors,
          },
        }))
      }
      handleHide()
    }
    if (isMap) {
      handleBackToBC()
      setTimeout(() => {
        doApply()
      }, 100)
    } else {
      doApply()
    }
  }, [ dispatch, modifiedNeighbors, handleHide, isMap, editNeighborsForBC, handleBackToBC ])

  const getUniqueSectorNames = useCallback(() => {
    const sectorNames = modifiedNeighbors
      ? Object.values(modifiedNeighbors).reduce((agg, val) => {
        if (val) {
          Object.keys(val).forEach((sector) => {
            agg.add(sector)
            if (val[sector] && val[sector].length > 0) {
              val[sector].forEach((item) => agg.add(item))
            }
          })
        }
        return agg
      }, new Set())
      : null
    return sectorNames
  }, [ modifiedNeighbors ])

  const handleCopy = useCallback(() => {
    const sectorNames = getUniqueSectorNames()
    if (sectorNames && sectorNames.size > 0) {
      navigator.clipboard.writeText([ ...sectorNames ].join('\n'))
      toast.success('Copied to clipboard', { position: 'top-right' })
    }
  }, [ getUniqueSectorNames ])

  const handleAddFilter = useCallback(() => {
    if (!isFiltering) {
      startFiltering()
      setTimeout(() => {
        const doAddFilter = async () => {
          const sectorNames = getUniqueSectorNames()
          if (sectorNames && sectorNames.size > 0) {
            const filter = [ ...sectorNames ].reduce((agg, value) => {
              agg.comparisonRules.push([ {
                columnIndex: sectorNameFldIdx,
                type: 'string',
                comparisonOperator: '=',
                value,
              } ])
              return agg
            }, { inversion: false, comparisonRules: [] })
            await dispatch(addFilterToTable({ dataType: DATA_TYPES.SECTORS, filter, reset: true }))
            await dispatch(dataFilteringByType(DATA_TYPES.SECTORS))
            toast.success('Filter added', { position: 'top-right', duration: 5000 })
          }
          finishFiltering()
        }
        if (hasFilters) {
          const doResetFilters = async () => {
            await dispatch(resetAllFilters())
            doAddFilter()
          }
          const doCancel = () => {
            finishFiltering()
          }
          ask(
            doResetFilters,
            doCancel,
            null,
            {
              title: 'Confirmation',
              messages: RESET_FILTERS_CONFIRMATION_MESSAGE,
              textYesBtn: 'Ok',
              textNoBtn: 'Cancel',
            },
          )
        } else {
          doAddFilter()
        }
      }, 1)
    }
  }, [
    dispatch, getUniqueSectorNames, sectorNameFldIdx, isFiltering, startFiltering, finishFiltering, hasFilters, ask,
  ])

  const doSelectSector = useCallback((siteId, sectorId) => {
    const siteInd = sitesFull.findIndexById(siteId)
    if (siteInd >= 0) {
      const site = sitesFull.getList()[siteInd]
      const lat = site[siteLatFldIdx]
      const lng = site[siteLngFldIdx]
      if (lat && lng) {
        dispatch(setBounce({ lat, lng }))
      }
    }

    if (onSelectSite) {
      onSelectSite(siteId)
    }
    setTimeout(() => {
      if (onSelectSector) {
        onSelectSector(sectorId)
      }
    }, 100)
  }, [ dispatch, onSelectSite, onSelectSector, sitesFull, siteLatFldIdx, siteLngFldIdx ])

  const handleSelectOnMap = useCallback(() => {
    const doSelectOnMap = async () => {
      setBCURI(location.pathname)
      await history.replace(`/${projectId || '_'}/map`)
      const bcSiteId = editNeighborsForBC.bc[bcSiteIdFldIdx]
      if (bcSiteId) {
        doSelectSector(bcSiteId, null)
      }
    }
    if (hasFilters) {
      const doResetFilters = async () => {
        await dispatch(resetAllFilters())
        doSelectOnMap()
      }
      ask(
        doResetFilters,
        () => {},
        null,
        {
          title: 'Confirmation',
          messages: RESET_FILTERS_CONFIRMATION_MESSAGE,
          textYesBtn: 'Ok',
          textNoBtn: 'Cancel',
        },
      )
    } else {
      doSelectOnMap()
    }
  }, [
    dispatch, ask, hasFilters, location, history, projectId, setBCURI, editNeighborsForBC,
    bcSiteIdFldIdx, doSelectSector,
  ])

  const listContainsTagList = useCallback((tag, tagList) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false
    }
    return tagList.some((compareTag) => compareTag?.key === tag?.key)
  }, [])

  const filterSuggestedTags = useCallback((filterText, tagList) => {
    if (!filterText || filterText.length < 3) {
      return []
    }
    const result = []
    sectorNames.every((sector) => {
      if (sector.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 &&
      !listContainsTagList(sector, tagList)) {
        result.push(sector)
      }
      return result.length < 100
    })

    return result
  }, [ sectorNames, listContainsTagList ])

  const getTextFromItem = useCallback((item) => item.name, [])

  const onItemSelected = useCallback((tech, sector) => (selectedItems) => {
    const newModifiedValue = {
      ...modifiedNeighbors,
      [tech]: {
        ...modifiedNeighbors[tech],
        [sector]: selectedItems && selectedItems.length > 0
          ? [
              ...selectedItems.map((item) => item.name),
            ]
          : [],
      },
    }
    dispatch(setModifiedNeighbors(newModifiedValue))
    dispatch(setIsModifiedNeighbors(true))
  }, [ dispatch, modifiedNeighbors ])

  const handleOnSiteClick = useCallback(() => {
    doSelectSector(siteId, null)
    dispatch(setSelectedModificationZone(null))
  }, [ dispatch, doSelectSector, siteId ])

  const handleTechClick = useCallback((tech) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (onSelectSector) {
      onSelectSector(null)
    }
    dispatch(setSelectedModificationZone({ name: tech, type: 'tech' }))
  }, [ dispatch, onSelectSector ])

  const handleOnSectorClick = useCallback((sector, tech) => (e) => {
    e.stopPropagation()
    dispatch(setSelectedModificationZone({ name: sector, tech, type: 'sector' }))
    const sectorIdx = sectorsFull.findRangeByValue(sector, SECTOR_NAME_FIELD)
    if (sectorIdx >= 0) {
      const sectorId = sectorsFull.getList()[sectorIdx][sectorIdFldIdx]
      doSelectSector(siteId, sectorId)
    }
  }, [ dispatch, doSelectSector, siteId, sectorsFull, sectorIdFldIdx ])

  const handleOnNeighborClick = useCallback((sector) => (e) => {
    e.stopPropagation()
    const sectorIdx = sectorsFull.findRangeByValue(sector, SECTOR_NAME_FIELD)
    if (sectorIdx >= 0) {
      const sectorId = sectorsFull.getList()[sectorIdx][sectorIdFldIdx]
      const siteId = sectorsFull.getList()[sectorIdx][sectorSiteIdFldIdx]
      doSelectSector(siteId, sectorId)
    } else {
      if (onSelectSector) {
        onSelectSector(null)
      }
      toast.error('Sector not found')
    }
  }, [ doSelectSector, onSelectSector, sectorsFull, sectorIdFldIdx, sectorSiteIdFldIdx ])

  const findInputInChildren = useCallback((target) => {
    const children = target.children
    for (let i = 0; i < children.length; i++) {
      const child = children[i]
      if (child.tagName === 'INPUT') {
        return child
      }
      if (child.children.length > 0) {
        const inputElement = findInputInChildren(child)
        if (inputElement) {
          return inputElement
        }
      }
    }
    return null
  }, [])

  const handleOnPaste = useCallback((tech, sector) => async (e) => {
    const value = e.clipboardData.getData('text/plain')
    const res = await dispatch(insertNeighborsFromRawText(tech, sector, value))
    if (res) {
      e.stopPropagation()
      setTimeout(() => {
        const target = document.getElementById(`${tech}-${sector}`)
        if (target) {
          findInputInChildren(target)?.focus()
        }
      }, 100)
    }
  }, [ dispatch, findInputInChildren ])

  const dialogStyles = useMemo(() => ({
    main: isMap
      ? {
          position: 'fixed',
          right: '10px',
        }
      : undefined,
  }), [ isMap ])

  const handleOnCancelSelection = useCallback(() => {
    dispatch(setSelectedModificationZone(null))
  }, [ dispatch ])

  const handleOnClickStopPropagation = useCallback((e) => {
    e.stopPropagation()
  }, [])

  return (
    <>
    <Dialog
      hidden={!editNeighborsForBC}
      styles={dialogStyles}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Edit Neighbors Sectors',
        subText: editNeighborsForBC?.name && (isMap
          ? (
          <ActionButton
            className={'select-sector-neighbors-icon'}
            title={'Select for editing'}
            text={`Modify neighboring sectors for ${editNeighborsForBC?.name} (${siteName})`}
            iconProps={editIcon}
            onClick={handleOnSiteClick}
          />
            )
          : `Modify neighboring sectors for ${editNeighborsForBC?.name}`),
      }}
      minWidth={dialogWidth}
      maxWidth={dialogWidth}
      modalProps={{
        className: isMap && 'scapex-compact',
        isModeless: true,
        dragOptions: isMap && {
          menu: ContextualMenu,
        },
      }}
    >
      <div
        className={'capex-neighbors-editor'}
        onClick={handleOnCancelSelection}
      >
      {modifiedNeighbors && <Accordion
        collapsible multiple defaultOpenItems={Object.keys(modifiedNeighbors)}
      >
        {Object.keys(modifiedNeighbors).map((tech) => {
          return (
            <AccordionItem
              className={`capex-neighbors-tech ${selectedModificationZone?.name === tech ? 'selected' : ''}`}
              value={tech}
              key={tech}
            >
              <AccordionHeader
                expandIconPosition="end"
                className="capex-section-header-suffix"
                onClick={handleOnClickStopPropagation}
              >
                {isMap
                  ? <div
                      className='select-tech-neighbors-button'
                      onClick={handleTechClick(tech)}
                    >
                      <Icon iconName={'Edit'} />
                      <span>{tech}</span>
                    </div>
                  : <span>{tech}</span>
                }
              </AccordionHeader>
              <AccordionPanel className="accordion-panel">
                {modifiedNeighbors[tech] && Object.keys(modifiedNeighbors[tech])?.length > 0
                  ? (
                      Object.keys(modifiedNeighbors[tech]).map((sector) => {
                        const items = modifiedNeighbors[tech]?.[sector]?.map((name) => {
                          return { key: name, name }
                        })
                        return (
                          <div
                            id={`${tech}-${sector}`}
                            key={`${tech}-${sector}`}
                            className={`capex-neighbors-editor-container ${selectedModificationZone?.name === sector ? 'selected' : ''}`}
                            onClick={handleOnClickStopPropagation}
                            onPaste={handleOnPaste(tech, sector)}
                          >
                            <TagPicker
                              key={`${tech}-${sector}-${items?.length}`}
                              className='capex-neighbors-editor-picker'
                              defaultSelectedItems={items}
                              removeButtonAriaLabel="Remove"
                              onResolveSuggestions={filterSuggestedTags}
                              getTextFromItem={getTextFromItem}
                              onChange={onItemSelected(tech, sector)}
                              onRenderItem={isMap
                                ? (e) => {
                                    return (
                                      <div
                                        key={e.item.key}
                                        className={`capex-tag-item ${activeSectorName === e.item.key ? 'active' : ''}`}
                                      >
                                        <ActionButton
                                          title={'Show on map'}
                                          text={e.item.name}
                                          onClick={handleOnNeighborClick(e.item.key)}
                                        />
                                        <ActionButton
                                          title={'Remove'}
                                          iconProps={removeIcon}
                                          onClick={e.onRemoveItem}
                                        />
                                      </div>
                                    )
                                  }
                                : undefined}
                            />
                            <div
                              className={`capex-neighbors-editor-label ${isMap ? 'on-map' : ''}`}
                            >
                              {isMap
                                ? <ActionButton
                                    className={'select-sector-neighbors-icon'}
                                    title={'Select for editing'}
                                    iconProps={editIcon}
                                    text={sector}
                                    onClick={handleOnSectorClick(sector, tech)}
                                  />
                                : <span>{sector}</span>}
                            </div>
                          </div>
                        )
                      })
                    )
                  : <span>{'No sectors'}</span>}
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>}
      </div>
      <DialogFooter>
        <div className='button-container'>
          <div className='button-section-left'>
            {ADD_NEIGHBORS_ON_MAP && (!bcURI
              ? <DefaultButton
                onClick={handleSelectOnMap}
                text={'Select on map'}
              />
              : <DefaultButton
                onClick={handleBackToBC}
                text={'Back to BC'}
              />)}
            {ADD_NEIGHBORS_ON_MAP &&
            <DefaultButton
              disabled={!modifiedNeighborsNotEmpty}
              className="icon-button"
              onClick={handleRemoveAll}
              title="Remove All"
            >
              <Icon iconName={'Delete'} />
            </DefaultButton>}
            {APPLY_SECTORS_FILTER && !isMap && <>
              <DefaultButton
                className="icon-button"
                onClick={handleAddFilter}
                title={!isFiltering && 'Add Filter'}
                disabled={isFiltering}
              >
                {isFiltering ? <Spinner /> : <Icon iconName={'Filter'} />}
              </DefaultButton>
              <DefaultButton
                className="icon-button"
                onClick={handleCopy}
                title="Copy to Clipboard"
              >
                <Icon iconName={'Copy'} />
              </DefaultButton>
            </>}
          </div>
          <div className='button-section-right'>
            <PrimaryButton
              onClick={handleApply}
              text="Apply"
              disabled={!isModifiedNeighbors}
            />
            <DefaultButton onClick={handleHide} text="Cancel" />
          </div>
        </div>
      </DialogFooter>
    </Dialog>
    {renderConfirm()}
    </>
  )
}

export default EditBCNeighbors
