export const TASK_STATES = {
  active: 'active',
  completed: 'completed',
  failed: 'failed',
}

export const TASK_TYPES = {
  loadProject: 'loadProject',
  initNetwork: 'initNetwork',
  loadSites: 'loadSites',
  loadBaseStations: 'loadBaseStations',
  loadSectors: 'loadSectors',
  loadComplaints: 'loadComplaints',
  loadBusinessUnits: 'loadBusinessUnits',
  loadProjectSites: 'loadProjectSites',
  loadProjectBaseStations: 'loadProjectBaseStations',
  loadProjectSectors: 'loadProjectSectors',
  saveProject: 'saveProject',
  rollbackProject: 'rollbackProject',
  createBC: 'createBC',
  removeBC: 'removeBC',
  calculateBC: 'calculateBC',
  approveBC: 'approveBC',
  rejectBC: 'rejectBC',
  export: 'export',
  import: 'import',
  exportHistoricalData: 'exportHistoricalData',
  uploadVectorMap: 'uploadVectorMap',
  loadVectorMapJSON: 'loadVectorMapJSON',
  loadVectorMapAttributes: 'loadVectorMapAttributes',
  loadCoverage: 'loadCoverage',
  uploadRasterMap: 'uploadRasterMap',
  loadProjectJobs: 'loadProjectJobs',
  updateFromAtoll: 'updateFromAtoll',
  coverageBySectors: 'coverageBySectors',
  coverageBySignal: 'coverageBySignal',
  calculatePainZones: 'calculatePainZones',
  exportPolygon: 'exportPolygon',
  exportFilteringZone: 'exportFilteringZone',
  exportFocusZone: 'exportFocusZone',
  exportComputationZone: 'exportComputationZone',
  exportVectorMap: 'exportVectorMap',
  saveCompositeIndex: 'saveCompositeIndex',
  synchronizationDB: 'synchronizationDB',
  reloadFromDefaultProject: 'reloadFromDefaultProject',
  reloadFromDPUnmodified: 'reloadFromDPUnmodified',
  convertVectorMapToFilteringZone: 'convertVectorMapToFilteringZone',
  convertVectorMapToFocusZone: 'convertVectorMapToFocusZone',
  convertVectorMapToComputationZone: 'convertVectorMapToComputationZone',
  asyncReport: 'asyncReport',
  exportCompositeIndexes: 'exportCompositeIndexes',
  financialIndicatorsReport: 'financialIndicatorsReport',
  allBusinessCasesReport: 'allBusinessCasesReport',
}

export const TASK_DESCRIPTIONS = {
  loadProject: 'Load project',
  initNetwork: 'Initialize network objects',
  loadSites: 'Load Sites',
  loadBaseStations: 'Load RBS',
  loadSectors: 'Load Sectors',
  loadComplaints: 'Load Complaints',
  loadBusinessUnits: 'Load Business Units',
  loadProjectSites: 'Load Project Sites',
  loadProjectBaseStations: 'Load Project RBS',
  loadProjectSectors: 'Load Project Sectors',
  saveProject: 'Save Project',
  rollbackProject: 'Rollback Project',
  createBC: 'Create or modify Business Case',
  removeBC: 'Remove Business Case',
  calculateBC: 'Calculate Business Case',
  approveBC: 'Approve Business Case',
  rejectBC: 'Reject Business Case',
  export: 'Export data from',
  import: 'Import data to',
  exportHistoricalData: 'Export Historical Data',
  uploadVectorMap: 'Upload Vector Map',
  loadVectorMapJSON: 'Load Vector Map GeoJSON',
  loadVectorMapAttributes: 'Load Vector Map Table Attributes',
  loadCoverage: 'Load Coverage',
  uploadRasterMap: 'Upload Raster Map',
  loadProjectJobs: 'Load project jobs',
  updateFromAtoll: 'Synchronize the project and Default project with Atoll',
  coverageBySectors: 'Generate coverage report by sectors',
  coverageBySignal: 'Generate coverage report by signal',
  calculatePainZones: 'Calculate Pain Zones',
  exportPolygon: 'Export completed for polygon',
  exportFilteringZone: 'Export completed for Filtering zone',
  exportFocusZone: 'Export completed for Focus zones',
  exportComputationZone: 'Export completed for Computation zone',
  exportVectorMap: 'Export completed for Vector map',
  saveCompositeIndex: 'Composite indexes are calculated and saved',
  synchronizationDB: 'Synchronization with Default project completed',
  reloadFromDefaultProject: 'All data in your project has been updated from the Default project',
  reloadFromDPUnmodified: 'All unmodified data in your project has been updated from the Default project',
  convertVectorMapToFilteringZone: 'Vector Map converted to Filtering Zone',
  convertVectorMapToFocusZone: 'Vector Map converted to Focus Zones',
  convertVectorMapToComputationZone: 'Vector Map converted to Computation Zone',
  asyncReport: 'Report requested',
  exportCompositeIndexes: 'Export completed for Composite indexes',
  financialIndicatorsReport: 'Financial Indicators Report',
  allBusinessCasesReport: 'All Business Cases Report',
}
