import {
  SECTOR_RBS_NAME_FIELD, SECTOR_SITE_FIELD, SECTOR_TECH_FIELD, SECTORS,
} from '../../constants/network'
import { findIndex } from '../../features/network/indexing'
import { binaryIncludes } from '../../utils/math'
import { optionsElement } from './constants'

export function tableToClipboardText (columns, rates, kpi, elementKey, resource, fields) {
  const columnKPI = [ 'KPI', 'Weights, %', 'Rating type' ].join('\t')
  const kpiSelect = []
  if (!Array.isArray(fields)) {
    return false
  }
  const dataKPI = kpi
    .map((row) => {
      const name = fields.find((field) => field.id === row.id)?.label ?? '-'
      if (row.weight) {
        kpiSelect.push(row)
        return [ name, row.weight, row.better ? 'Higher-better' : 'Lower-better' ].join('\t')
      }
      return null
    })
    .filter(Boolean)
    .join('\n')
  const dataIdx = []
  const columnRates = [ ...columns, { id: 'rate', name: 'Rate' }, ...kpiSelect ]
    .map((column) => {
      dataIdx.push(column.id === 'rate' ? 'rate' : findIndex(fields, column.id))
      return column.name ?? column.label
    })
    .join('\t')
  const dataC = rates
    .map((rate) => {
      const index = rate.at(-2)
      const value = rate.at(-1)
      return dataIdx
        .map((idx) => idx === 'rate' ? value?.toFixed(6).replace('.', ',') : resource[index]?.[idx])
        .join('\t')
    })
    .join('\n')
  return `${columnKPI}\n${dataKPI}\n\n\n${columnRates}\n${dataC}`
}

export function tableToClipboard (e, columns, rates, kpi, elementKey, resource, fields) {
  const text = tableToClipboardText(columns, rates, kpi, elementKey, resource, fields)
  e.view.navigator.clipboard.writeText(text)
  e.preventDefault()
  return true
}

export const initCalc = (kpi, resource, fields) => {
  const kpiFiltered = kpi.filter((item) => item.weight)
  const weights = kpiFiltered.map((kpi) => kpi.weight / 100)
  const better = kpiFiltered.map((kpi) => !!kpi.better)
  const indexField = kpiFiltered.map((kpi) => findIndex(fields, kpi.id))
  const kHigher = resource.length
  const kLower = kHigher / (kHigher - 1)
  return { weights, better, indexField, kHigher, kLower }
}

export const sortingRateIndex = (rates, indexRate) => rates?.sort((rate1, rate2) => rate2[indexRate] - rate1[indexRate])

let rateSorting = []
export const sortingRateSite = (rates, startIndex) => {
  if (startIndex === 0) {
    rateSorting = []
  }
  const length = rates.length
  const module = Math.min(Math.ceil(length / 100), 1000)
  for (let index = startIndex; index < length; index++) {
    const rate = rates[index]
    if (rate !== null) {
      rateSorting.push(rate)
      const site = rate[0]
      for (let i = index + 1; i < length; i++) {
        if (rates[i]?.[0] === site) {
          rateSorting.push(rates[i])
          rates[i] = null
        }
      }
    }
    if (!(index % module)) {
      return { currentIndex: index + 1, sorted: rateSorting.length }
    }
  }
  return { rateSorting }
}

export const getOptionsElements = (templateData) => {
  const keys = Object.keys(templateData) // Елементи, що наявні в шаблоні
  return keys.map((key) => optionsElement[key]).filter(Boolean)
}

export const buildTemplateAttributes = (template, allKpiElements) => {
  const keys = Object.keys(template)
  const buildKpiElements = {}
  keys.forEach((key) => {
    const kpiElement = allKpiElements?.[key]
    const kpiTemplate = template?.[key]
    if (Array.isArray(kpiElement) && Array.isArray(kpiTemplate)) {
      buildKpiElements[key] = kpiElement
        .map((kpi) => ({ ...kpi, ...(kpiTemplate.find((item) => item.id === kpi.id) ?? {}) }))
    }
  })
  return buildKpiElements // { sites: allKpiSites, sectors: allKpiSectors, rbs: allKpiRBS }
}

export const getAttributesKPI = (fields, historical) => {
  let filteredFields = fields
    .filter((field) => field.inCompositeIndex)
  if (historical) {
    filteredFields = filteredFields
      .filter((field) => field.historical)
  }
  return filteredFields
    .map((field) => {
      const { id, label, description } = field
      return { id, label, description, weight: 0, better: true }
    })
}

export const calculateStatistic = (fields, sectors, dispatch) => {
  const indexTechnology = fields.findIndex((field) => field.id === SECTOR_TECH_FIELD)
  const indexRBS = fields.findIndex((field) => field.id === SECTOR_RBS_NAME_FIELD)
  const indexSite = fields.findIndex((field) => field.id === SECTOR_SITE_FIELD)
  const statisticSector = {}
  const fullStatisticRBS = {}
  const sites = new Set()
  SECTORS.forEach((key) => {
    statisticSector[key] = 0
    fullStatisticRBS[key] = []
  })
  if (indexTechnology >= 0 && indexRBS >= 0 && indexSite >= 0) {
    sectors.forEach((sector) => {
      const list = fullStatisticRBS[sector[indexTechnology]]
      if (!Array.isArray(list)) {
        return
      }
      const insertIndex = binaryIncludes(list, sector[indexRBS], true)
      if (insertIndex !== true) {
        list.splice(insertIndex, 0, sector[indexRBS])
      }
      statisticSector[sector[indexTechnology]]++
      sites.add(sector[indexSite])
    })
  }
  const statisticRBS = {}
  SECTORS.forEach((key) => {
    statisticRBS[key] = fullStatisticRBS?.[key]?.length
  })
  return { statisticRBS, statisticSector, statisticSiteBySectors: sites.size }
}
