import { fetch } from './rest'

export const load = () => fetch('/api/jobs')

export const synchronize = (data) => fetch('/api/job', 'POST', data)

export const getJobStatus = (id) => fetch(`/api/jobs/${id}`, 'GET')

export const getJobStatusIgnoreNotFound = (id) => fetch(`/api/jobs/${id}`, 'GET', undefined, 'json', true, undefined, true)

export const getProjectJobs = (projectId) => fetch(`/api/project/${projectId}/jobs`, 'GET')
