export const TRF_TYPE_FIELD = 'table.trf.type'
export const TRF_DATE_FIELD = 'table.trf.date'
export const TRF_DATA_5G_FIELD = 'table.trf.data.5g'
export const TRF_DATA_4G_FIELD = 'table.trf.data.4g'
export const TRF_DATA_3G_FIELD = 'table.trf.data.3g'
export const TRF_DATA_2G_FIELD = 'table.trf.data.2g'
export const TRF_DATA_TOTAL_FIELD = 'table.trf.data.total'
export const TRF_VOICE_2G_FIELD = 'table.trf.voice.2g'
export const TRF_VOICE_3G_FIELD = 'table.trf.voice.3g'
export const TRF_VOICE_VOLTE_FIELD = 'table.trf.voice.voLte'
export const TRF_VOICE_VONR_FIELD = 'table.trf.voice.voNr'
export const TRF_VOICE_VOWIFI_FIELD = 'table.trf.voice.voWiFi'
export const TRF_VOICE_TOTAL_FIELD = 'table.trf.voice.total'
export const TRF_AIM_2G_FIELD = 'table.trf.a1m.2g'
export const TRF_AIM_3G_FIELD = 'table.trf.a1m.3g'
export const TRF_AIM_VOLTE_FIELD = 'table.trf.a1m.voLte'
export const TRF_AIM_VONR_FIELD = 'table.trf.a1m.voNr'
export const TRF_AIM_VOWIFI_FIELD = 'table.trf.a1m.voWiFi'
export const TRF_AIM_M2M_FIELD = 'table.trf.a1m.m2m'
export const TRF_A1M_TOTAL_FIELD = 'table.trf.a1m.total'

export const TYPE_FC = 'FC'
export const TYPE_ACT = 'ACT'

export const headers = (step) => {
  return [
    {
      id: TRF_TYPE_FIELD,
      label: 'Type',
      description: 'type',
      type: 'enum',
      'enum-values': `${TYPE_ACT},${TYPE_FC}`,
      size: 55,
      editable: false,
    },
    {
      id: TRF_DATE_FIELD,
      label: 'Date',
      description: 'Date',
      type: 'string',
      correctFormat: true,
      size: 100,
      editable: false,
    },
    { id: TRF_DATA_5G_FIELD, label: '5G(Gb)', description: 'Data 5G', type: 'double', size: 50, editable: true },
    { id: TRF_DATA_4G_FIELD, label: '4G(Gb)', description: 'Data 4G', type: 'double', size: 50, editable: true },
    { id: TRF_DATA_3G_FIELD, label: '3G(Gb)', description: 'Data 3G', type: 'double', size: 50, editable: true },
    { id: TRF_DATA_2G_FIELD, label: '2G(Gb)', description: 'Data 2G', type: 'double', size: 50, editable: true },
    {
      id: TRF_DATA_TOTAL_FIELD,
      label: 'Total DATA(Gb)',
      description: 'Total DATA',
      size: 50,
      type: 'double',
    },
    { id: TRF_VOICE_2G_FIELD, label: '2G(Erl)', description: 'Voice 2G', type: 'double', size: 50, editable: true },
    { id: TRF_VOICE_3G_FIELD, label: '3G(Erl)', description: 'Voice 3G', type: 'double', size: 50, editable: true },
    { id: TRF_VOICE_VOLTE_FIELD, label: 'VoLTE(Erl)', description: 'VoLTE', type: 'double', size: 50, editable: true },
    { id: TRF_VOICE_VONR_FIELD, label: 'VoNR(Erl)', description: 'VoNR', type: 'double', size: 50, editable: true },
    {
      id: TRF_VOICE_VOWIFI_FIELD,
      label: 'VoWIFI(Erl)',
      description: 'VoWIFI',
      type: 'double',
      size: 50,
      editable: true,
    },
    {
      id: TRF_VOICE_TOTAL_FIELD,
      label: 'Total VOICE(Erl)',
      description: 'Total VOICE',
      size: 50,
      type: 'double',
    },
    { id: TRF_AIM_2G_FIELD, label: '2G', description: '2G', type: 'double', size: 50, editable: true },
    { id: TRF_AIM_3G_FIELD, label: '3G', description: '3G', type: 'double', size: 50, editable: true },
    { id: TRF_AIM_VOLTE_FIELD, label: 'VoLTE', description: 'VoLTE', type: 'double', size: 50, editable: true },
    { id: TRF_AIM_VONR_FIELD, label: 'VoNR', description: 'VoNR', type: 'double', size: 50, editable: true },
    { id: TRF_AIM_VOWIFI_FIELD, label: 'VoWIFI', description: 'VoWIFI', type: 'double', size: 50, editable: true },
    { id: TRF_AIM_M2M_FIELD, label: 'M2M', description: 'M2M', type: 'double', size: 50, editable: true },
    {
      id: TRF_A1M_TOTAL_FIELD,
      label: 'Total A1M',
      description: 'Total A1M',
      size: 50,
      type: 'double',
    },
  ]
}

export default headers
