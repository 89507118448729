// Функція формування списку елементів, що потрапили під клік
import { SECTOR_TYPES } from '../../constants/network'
import { ID_ICON_COMPLAINT, ID_ICON_SECTOR, ID_ICON_SITES } from '../Panels/Customization/constants'
import { rotatePoints, scaleSector, siteRadius } from './draw'
import { COMPLAINT_SIZE } from './render'

// Константи тонкого налаштування
const LOOKUP_PIXELS = 48
const SECTOR_PIXELS = 20
const PIXEL_TOLERANCE = 2
export const SECTOR_OFFSET_STEP = 40

export const getElementList = (map, point) => {
  const zoom = map.getZoom()
  const r = siteRadius(zoom, map.ratios?.[ID_ICON_SITES])
  const ne = map.containerPointToLatLng({ x: point.x + LOOKUP_PIXELS, y: point.y - LOOKUP_PIXELS })
  const sw = map.containerPointToLatLng({ x: point.x - LOOKUP_PIXELS, y: point.y + LOOKUP_PIXELS })
  const bounds = {
    minX: sw.lng,
    minY: sw.lat,
    maxX: ne.lng,
    maxY: ne.lat,
  }
  const maxOffset = (Object.keys(SECTOR_TYPES).length - 2) * SECTOR_OFFSET_STEP
  const ne2 =
    map.containerPointToLatLng({ x: point.x + LOOKUP_PIXELS + maxOffset, y: point.y - LOOKUP_PIXELS - maxOffset })
  const sw2 =
    map.containerPointToLatLng({ x: point.x - LOOKUP_PIXELS - maxOffset, y: point.y + LOOKUP_PIXELS + maxOffset })
  const bounds2 = {
    minX: sw2.lng,
    minY: sw2.lat,
    maxX: ne2.lng,
    maxY: ne2.lat,
  }
  const sites = map._sites?.isVisible()
    ? map._sites._positionsTree.search(bounds)
      .filter(({ marker }) => {
        let result = marker.options.type === 'site'
        if (result) {
          const p = map.latLngToContainerPoint(marker._latlng)
          result = Math.hypot(Math.abs(p.x - point.x), Math.abs(p.y - point.y)) < r + PIXEL_TOLERANCE
        }
        return result
      })
    : []

  const scale = scaleSector(zoom, map.ratios?.[ID_ICON_SECTOR])
  const sectors = map._sectors?.isVisible()
    ? map._sectors._positionsTree.search(bounds2)
      .filter(({ marker }) => {
        const { type, technology, site } = marker.options
        let result = type === 'sector'
        if (result) {
          const offset = map._sectors.activeSite?.id === site ? map._sectors.activeSite.techOffset[technology] : 0
          const p = map.latLngToContainerPoint(marker._latlng)
          const rp = rotatePoints(marker.options.angle, [ { x: 0, y: 25 + offset } ])[0]
          result = Math.hypot(Math.abs(p.x + rp.x - point.x), Math.abs(p.y - rp.y - point.y)) <
            SECTOR_PIXELS * scale + PIXEL_TOLERANCE
        }
        return result
      })
    : []
  return sites.concat(sectors).map(({ marker }) => marker)
}

export const allAtSite = (sectors, activeSite) =>
  activeSite && (sectors.length >= 1) && sectors.every((marker) => marker.options.site === activeSite)

export const allAtAnySite = (sectors) => {
  let sameSite
  for (const marker of sectors) {
    const site = marker.options.site
    if (!sameSite) {
      sameSite = site
    } else if (site !== sameSite) {
      return
    }
  }
  return sameSite
}

// Функція пошуку маркерів
export const findSiteMarker = (map, id) => {
  if (map && map._sites) {
    const ne = map.containerPointToLatLng({ x: map._container.offsetWidth, y: 0 })
    const sw = map.containerPointToLatLng({ x: 0, y: map._container.offsetHeight })
    const bounds = {
      minX: sw.lng,
      minY: sw.lat,
      maxX: ne.lng,
      maxY: ne.lat,
    }
    for (const { marker } of map._sites._positionsTree.search(bounds)) {
      if (marker.options.id === id) {
        return marker
      }
    }
  }
}

export const findSectorMarker = (map, id) => {
  if (map && map._sectors) {
    const ne = map.containerPointToLatLng({ x: map._container.offsetWidth, y: 0 })
    const sw = map.containerPointToLatLng({ x: 0, y: map._container.offsetHeight })
    const bounds = {
      minX: sw.lng,
      minY: sw.lat,
      maxX: ne.lng,
      maxY: ne.lat,
    }
    for (const { marker } of map._sectors._positionsTree.search(bounds)) {
      if (marker.options.id === id) {
        return marker
      }
    }
  }
}

export const getEntityLayers = (map, entityId) => {
  const layers = []
  map.eachLayer((layer) => {
    if (layer.options.id === entityId || layer.feature?.id === entityId) {
      layers.push(layer)
    }
  })
  return layers
}

export const getComplaintList = (map, point) => {
  const d = (map._complaints ? (map.ratios?.[ID_ICON_COMPLAINT] || 4) : COMPLAINT_SIZE / 2) + PIXEL_TOLERANCE
  const ne = map.containerPointToLatLng({ x: point.x + d, y: point.y - d })
  const sw = map.containerPointToLatLng({ x: point.x - d, y: point.y + d })
  const zoom = map.getZoom()
  const bbox = [ sw.lng, sw.lat, ne.lng, ne.lat ]
  const bounds = {
    minX: sw.lng,
    minY: sw.lat,
    maxX: ne.lng,
    maxY: ne.lat,
  }
  if (map._complaints) {
    return map._complaints.isVisible()
      ? map._complaints._positionsTree.search(bounds)
        .filter(({ marker }) => {
          let result = marker.options.type === 'complaint'
          if (result) {
            const p = map.latLngToContainerPoint(marker._latlng)
            result = Math.hypot(Math.abs(p.x - point.x), Math.abs(p.y - point.y)) < d
          }
          return result
        })
      : []
  } else {
    return map.complaintsCluster.getClusters(bbox, Math.round(zoom))
  }
}
