import { useEffect } from 'react'
import L from 'leaflet'

const TargetCoverageButton = ({ map, visible, title, className, onClick }) => {
  useEffect(() => {
    if (visible && !map._container._targetCoverageButton) {
      map._container._targetCoverageButton = L.control.simpleButton({
        title,
        onClick,
        className,
      }).addTo(map)
    } else if (!visible && map._container._targetCoverageButton) {
      map._container._targetCoverageButton.remove()
      delete map._container._targetCoverageButton
    }
  }, [ map, visible, title, className, onClick ])

  return null
}

export default TargetCoverageButton
