import { COLUMNS_UPDATE, VALUES_FIELDS_REQUEST } from '../../../constants/updateDB'
import { NOT_SELECT } from '../constants'
import { DATA_TYPES } from '../../../constants/common'

export const getSelectData = (items, column) => {
  if (!items || !column) {
    return null
  }
  const newItems = {
    sites: [],
    rbses: [],
    sectors: [],
  }
  items
    .filter((item) => item.select === column)
    .forEach((item) => {
      const newItem = {}
      VALUES_FIELDS_REQUEST.forEach((field) => {
        if (Object.hasOwn(item, field)) {
          newItem[field] = item[field]
        }
      })
      newItems[item.type].push(newItem)
    })
  Object.keys(newItems).forEach((key) => {
    if (newItems[key].length === 0) {
      delete newItems[key]
    }
  })
  return newItems
}

const columnFiltered = [ COLUMNS_UPDATE.ORIGINAL_VALUE, COLUMNS_UPDATE.NEW_VALUE, COLUMNS_UPDATE.DB_VALUE ]
const filterEqual = (item) => {
  const values = columnFiltered.map((column) => {
    const value = item[column]
    return value == null ? '' : `${value}`.trim()
  })
  return !(values[0] === values[1] && values[1] === values[2])
}

export const initializeData = (data) => {
  const { sites, rbses, sectors } = data ?? {}
  let sitesBuild = []
  let rbsesBuild = []
  let sectorsBuild = []
  if (Array.isArray(sites)) {
    sitesBuild = sites.filter(filterEqual).map((item) => ({
      ...item,
      key: `${item.siteId}_${item.attributeId}`,
      select: NOT_SELECT,
      type: DATA_TYPES.SITES,
      [COLUMNS_UPDATE.NETWORK_ELEMENT]: item.siteName,
    }))
  }
  if (Array.isArray(rbses)) {
    rbsesBuild = rbses.filter(filterEqual).map((item) => ({
      ...item,
      key: `${item.rbsId}_${item.attributeId}`,
      select: NOT_SELECT,
      type: DATA_TYPES.RBSES,
      [COLUMNS_UPDATE.NETWORK_ELEMENT]: item.rbsName,
    }))
  }
  if (Array.isArray(sectors)) {
    sectorsBuild = sectors.filter(filterEqual).map((item) => ({
      ...item,
      key: `${item.sectorId}_${item.attributeId}`,
      select: NOT_SELECT,
      type: DATA_TYPES.SECTORS,
      [COLUMNS_UPDATE.NETWORK_ELEMENT]: item.sectorName,
    }))
  }
  return sitesBuild.concat(rbsesBuild, sectorsBuild)
}
