import { getTheme, mergeStyles } from '@fluentui/react'

const theme = getTheme()

export const initDragged = {
  draggedItem: undefined,
  draggedIndex: -1,
}

const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
})

export const getDragDropEvents = (dragged, setDragged, insertBeforeItem) => {
  return {
    canDrop: (dropContext, dragContext) => {
      return true
    },
    canDrag: (item) => {
      return true
    },
    onDragEnter: (item, event) => {
      return dragEnterClass
    },
    onDrop: (item, event) => {
      if (dragged.draggedItem) {
        insertBeforeItem(item)
      }
    },
    onDragStart: (item, itemIndex, selectedItems, event) => {
      setDragged({
        draggedItem: item,
        draggedIndex: itemIndex,
      })
    },
    onDragEnd: (item, event) => {
      setDragged(initDragged)
    },
  }
}
