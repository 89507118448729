import React, { useCallback } from 'react'
import Table from '../common/Table'
import { footerSimultaneous } from '../constants'
import { TABLES_UPDATE } from '../../../constants/updateDB'
import { getSelectData } from '../common/utils'
import { apiUpdateDB } from '../../../features/updateProject/update'

const Simultaneous = ({ onUpdateItem, changeCheckBoxHeader, clickApplyUpdate, items, projectId, ...props }) => {
  const onUpdateItemHandler = useCallback(
    (props) => onUpdateItem && onUpdateItem(props, TABLES_UPDATE.SIMULTANEOUS),
    [ onUpdateItem ])

  const onChangeCheckBoxHeader = useCallback(
    (...props) => changeCheckBoxHeader && changeCheckBoxHeader(...props, TABLES_UPDATE.SIMULTANEOUS),
    [ changeCheckBoxHeader ])

  // const onApply = useCallback(
  //   (key) => clickApplyUpdate && clickApplyUpdate(key, TABLES_UPDATE.SIMULTANEOUS),
  //   [clickApplyUpdate])

  const onApply = useCallback(
    (button) => {
      const column = button?.column
      const select = getSelectData(items, column)
      const key = button?.key
      const updateDB = apiUpdateDB.simultaneous[key] && apiUpdateDB.simultaneous[key](projectId, select)
      clickApplyUpdate(updateDB, key)
    }, [ clickApplyUpdate, items, projectId ])

  return (
    <Table
      id={TABLES_UPDATE.SIMULTANEOUS}
      items={items}
      footer={footerSimultaneous}
      onUpdateItem={onUpdateItemHandler}
      changeCheckBoxHeader={onChangeCheckBoxHeader}
      onApply={onApply}
      {...props}
    />
  )
}

export default Simultaneous
