export const SPREAD_RANGE = 'range'
export const SPREAD_DISCRETE = 'discrete'
export const SPREAD_UNIQUE = 'unique'
export const SPREAD_DEFAULT = 'default'

export const FONT_REGULAR = 'regular'
export const FONT_BOLD = 'bold'
export const FONT_ITALIC = 'italic'
export const FONT_UNDERLINE = 'underline'

export const BACKGROUND_NONE = 'none'
export const BACKGROUND_HALO = 'halo'
export const BACKGROUND_OPAQUE = 'opaque'
