import React, { useCallback, useRef, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearTaskLog, selectPendingTasks, selectTaskLog } from '../../features/taskLog/taskLogSlice'
import { EVENT_TYPE, saveEventLog } from '../../features/eventLog/eventLogSlice'
import { exportTableData } from '../../utils/export'
import HeaderContext from '../../layout/context/HeaderContext'
import TaskTable from './components/TaskTable'

const TaskLogComponent = ({ setTaskLogActions }) => {
  const dispatch = useDispatch()
  const refHot = useRef()

  const tasks = useSelector(selectTaskLog)
  const pending = useSelector(selectPendingTasks)
  const disabledAction = pending || !(tasks?.length > 0)

  const taskLogExport = useCallback(() => {
    const table = refHot.current?.hotInstance
    try {
      const nestedHeaders = refHot.current?.props?.settings?.nestedHeaders
      exportTableData('xlsx', false, table, 'TaskLog', null, null, nestedHeaders)
      dispatch(saveEventLog(EVENT_TYPE.taskLogExport, `: ${table.countRows()} rows`))
    } catch (err) {
      console.error(err)
    }
  }, [ refHot, dispatch ])

  const taskLogClear = useCallback(() => {
    dispatch(clearTaskLog())
  }, [ dispatch ])

  const taskLogActions = useMemo(() => ({
    clear: taskLogClear,
    export: taskLogExport,
    clearDisabled: disabledAction,
    exportDisabled: disabledAction,
  }), [ taskLogClear, taskLogExport, disabledAction ])

  useEffect(() => {
    setTaskLogActions && setTaskLogActions(taskLogActions)
  }, [ setTaskLogActions, taskLogActions ])

  return (
    <div className='full-height-wrap'>
      <TaskTable
        refHot={refHot}
        tasks={tasks}
      />
    </div>
  )
}

const TaskLogPage = () => {
  return (
    <HeaderContext.Consumer>
      {({ setTaskLogActions }) => (
        <TaskLogComponent
          setTaskLogActions={setTaskLogActions}
        />
      )}
    </HeaderContext.Consumer>
  )
}

export default TaskLogPage
