import { mergeStyles } from '@fluentui/react/lib/Styling'

export const filterSymbol = {
  equal: '=',
  notEqual: '<>',
  less: '<',
  greater: '>',
  lessEqual: '<=',
  greaterEqual: '>=',
}
export const MAX_ROWS = 500 // максимальное количество строк таблице поиска
export const MAX_COLS = 200 // максимальное количество столбцов таблице поиска
export const DEFAULT_COLS = 5
export const DEFAULT_ROWS = 10
export const FILTER_MAX_SIZE = 500 // максимальное количество строк фильтра

export const MENU_FILTER_KEYS = {
  ONLY_SELECTED: 'OnlySelected',
  EXCEPT_SELECTED: 'ExceptSelected',
  SPECIAL_FILTERING: 'SpecialFiltering',
  RESET_FILTER: 'ResetFilter',
}

export const initHeader = [ {}, {}, {}, {}, {} ]
export const initDataFilter = JSON.stringify([
  [ {}, {}, {}, {}, {} ],
  [ {}, {}, {}, {}, {} ],
  [ {}, {}, {}, {}, {} ],
  [ {}, {}, {}, {}, {} ],
])

export const menuItemsText = [
  {
    key: 'equal',
    text: '= equal',
    value: '=',
  },
  {
    key: 'notEqual',
    text: '<> Not equal',
    value: '<>',
  },
]

export const menuItems = [
  ...menuItemsText,
  {
    key: 'less',
    text: '< Less',
    value: '<',
  },
  {
    key: 'lessEqual',
    text: '<= Less equal',
    value: '<=',
  },
  {
    key: 'greater',
    text: '> Greater',
    value: '>',
  },
  {
    key: 'greaterEqual',
    text: '>= Greater equal',
    value: '>=',
  },
]

export const menuItemsSelected = (onClick) => ([
  {
    key: MENU_FILTER_KEYS.ONLY_SELECTED,
    text: 'Only selected',
    onClick: onClick,
  },
  {
    key: MENU_FILTER_KEYS.EXCEPT_SELECTED,
    text: 'Except selected',
    onClick: onClick,
  },
])
export const menuItemsAlways = (onClick, noFilter) => ([
  {
    key: MENU_FILTER_KEYS.SPECIAL_FILTERING,
    text: 'Special filtering',
    iconProps: { iconName: 'FilterSettings' },
    onClick: onClick,
  },
  {
    key: MENU_FILTER_KEYS.RESET_FILTER,
    text: 'Reset filter',
    iconProps: { iconName: 'ClearFilter' },
    onClick: onClick,
    disabled: noFilter,
  },
])

export const optionsDataType = [
  {
    key: 'sites',
    text: 'Sites',
  },
  {
    key: 'baseStations',
    text: 'RBS',
  },
  {
    key: 'sectors',
    text: 'Sectors',
  },
  {
    key: 'complaints',
    text: 'Complaints',
  },
]

export const labelDataType = {
  sites: 'Sites',
  baseStations: 'RBS',
  sectors: 'Sectors',
  complaints: 'Complaints',
}

export const typeText = [ 'text', 'dropdown', 'checkbox', 'string', 'enum' ]
export const typeNumber = [ 'numeric', 'data', 'long', 'double', 'int', 'integer', 'float' ]
export const typeDate = [ 'date', 'datetime' ]
export const typeBoolean = [ 'boolean' ]
export const isBooleanTypeColumn = (type) => typeBoolean.includes(type)
export const operationForText = [ '=', '<>' ]

export const clearFilterIcon = {
  iconName: 'ClearFilter',
}

export const clipboardIcon = {
  iconName: 'ClipboardList',
}

export const normalFilterIcon = {
  iconName: 'Location',
}

export const inversionFilterIcon = {
  iconName: 'CircleStopSolid',
}
export const iconFilterClass = mergeStyles({
  marginLeft: 4,
  marginRight: 4,
})

export const iconButtonClass = mergeStyles({
  padding: 0,
})

const booleanValue = [
  true,
  false,
  'true',
  'false',
  'TRUE',
  'FALSE',
  1,
  '1',
  0,
  '0',
]

const trueValue = [
  true,
  'true',
  'TRUE',
  1,
  '1',
]

export const isBoolean = (value) => booleanValue.includes(value)

export const isTrue = (value) => trueValue.includes(value)
