import React from 'react'
import { Toggle as ToggleComponent } from '@fluentui/react'

import './Toggle.css'

const Toggle = (props) => (
  <ToggleComponent className="capex-toggle" {...props} />
)

export default Toggle
