import React from 'react'
import { useSelector } from 'react-redux'
import { ProgressIndicator } from '@fluentui/react'
import Modal from '../common/Modal'
import { selectSavingChanges } from '../../features/loading/loadingSlice'

const SavingChanges = () => {
  const { total, current, title } = useSelector(selectSavingChanges)

  return (
    <Modal
      title={title}
      width={480}
    >
      <ProgressIndicator
        label={`${current} / ${total}`}
        percentComplete={current / total}
      />
    </Modal>
  )
}

export default SavingChanges
