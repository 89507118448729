import { ROLES, TYPES } from '../../constants/roles'

export const userHasSubRoleUser = (user) => {
  const roles = user?.roles || []
  return roles.includes(ROLES.ROLE_USER)
}

export const isLocalAdmin = (user) => {
  const roles = user?.roles || []
  return roles.includes(ROLES.ROLE_ADMIN) && user?.type === TYPES.LOCAL
}

export const userHasSubRoleFPA = (user) => {
  const roles = user?.roles || []
  return roles.includes(ROLES.ROLE_FPA)
}

export const userIsFPA = (user) => {
  const roles = user?.roles || []
  return roles.includes(ROLES.ROLE_FPA) && !roles.includes(ROLES.ROLE_ADMIN)
}
