import L from 'leaflet'
import { Polygon } from 'react-leaflet'
import React, { useCallback, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { checkPolygonIsCorrect, reverseLngLat, toGeometry, toPolygon, toTurf } from '../../utils'
import { SECTOR_BASE, SECTOR_COLORS } from '../../../../constants/network'
import { determineColor } from '../../render'
import { DEFAULT_COLORS } from '../../draw'
import {
  selectSectorFldIdxTC,
  selectSectors,
} from '../../../../features/network/networkSlice'
import { selectSettingsDisplay } from '../../../../features/settings/settingsSlice'
import { useHandler } from '../../../../hooks'
import { findSectorMarker, getElementList } from '../../../../components/Map/list'

const TargetCoverages = ({
  map, targetCoverages, activeSector, colorAttributeIdx, onEditPolygon, setActiveSector, drawMode, version,
}) => {
  const sectors = useSelector(selectSectors)
  const settingsDisplay = useSelector(selectSettingsDisplay)
  const [ idIdx2, tIdx2 ] = useSelector(selectSectorFldIdxTC, shallowEqual)

  const sectorColorById = useCallback((sectorId) => {
    const sectorIdx = sectors.findIndexById(sectorId)
    if (sectorIdx >= 0) {
      const item = sectors.getList()[sectorIdx]
      const base = SECTOR_BASE[item[tIdx2]]
      return determineColor(item, settingsDisplay[base], colorAttributeIdx[base], idIdx2) ||
        SECTOR_COLORS[item[tIdx2]] || DEFAULT_COLORS.SECTOR
    } else {
      return null
    }
  }, [ sectors, tIdx2, settingsDisplay, colorAttributeIdx, idIdx2 ])

  // Обробка клацання на зоні -- активація сектора
  const onLayerClick = useHandler((event) => {
    const list = getElementList(map, event.containerPoint)
    if (list.length > 0) {
      return
    }
    L.DomEvent.stop(event)
    const sectorId = event.target.options.id
    if (activeSector !== sectorId) {
      setActiveSector(sectorId)
    }
  })

  // Обробка подвійного клацання на зоні -- редагування властивостей зони
  const onLayerDblClick = useHandler((event) => {
    const list = getElementList(map, event.containerPoint)
    if (list.length > 0) {
      return
    }
    const layer = event.target
    const geometry = toGeometry(layer.getLatLngs())
    if (checkPolygonIsCorrect(geometry)) {
      L.DomEvent.stop(event)
      const [ sectorId, targetCoverage ] = Object.entries(targetCoverages)
        .find(([ sectorId ]) => sectorId === layer.options.id) ?? []
      if (sectorId && targetCoverage) {
        onEditPolygon({
          id: sectorId,
          geometry: toPolygon(toTurf(geometry)).geometry.coordinates,
          properties: targetCoverage.properties,
          type: 'targetCoverage',
          site: map._activeSite,
          name: findSectorMarker(map, sectorId)?.options?.name ?? '',
        })
      }
    }
  })

  // Встановлення обробників подій миші на TargetCoverage
  useEffect(() => {
    setTimeout(() => {
      map.eachLayer((layer) => {
        if (layer.options.targetId && layer.options.targetId.startsWith('targetCoverage-')) {
          if (drawMode) {
            layer.off('dblclick', onLayerDblClick)
            layer.off('click', onLayerClick)
          } else {
            layer.on('dblclick', onLayerDblClick)
            layer.on('click', onLayerClick)
          }
        }
      })
    })
  }, [ map, targetCoverages, onLayerDblClick, onLayerClick, activeSector, drawMode ])

  return (
    <>
      {Object.entries(targetCoverages ?? {})
        .filter(([ , { geometry } ]) => geometry.length)
        .map(([ sectorId, { geometry, properties, hash } ]) => {
          const colorById = sectorColorById(sectorId)
          return colorById
            ? (
              <Polygon
                id={sectorId}
                targetId={`targetCoverage-${sectorId}`}
                key={`targetCoverage-${hash}-${sectorId}${activeSector === sectorId ? '-active' : ''}-${version}`}
                positions={reverseLngLat(geometry)}
                pmIgnore={sectorId !== activeSector}
                stroke={sectorId === activeSector}
                color={'red'}
                fillColor={colorById}
                fillOpacity={0.5}
                description={properties?.description}
              />
              )
            : null
        })
  }</>
  )
}

export default TargetCoverages
