import { Polygon } from 'react-leaflet'
import React, { useCallback, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import L from 'leaflet'
import { checkPolygonIsCorrect, reverseLngLat, toGeometry, toPolygon, toTurf } from '../../utils'
import { selectEditZone, selectEditZoneColors, selectZones, setEditZone } from '../../../../features/geo/geoSlice'
import { ID_GEO_ZONES } from '../../../../constants/geo'
import { getElementList, getEntityLayers } from '../../list'
import { flattenPolygonOnMap, zoomMapToEntity } from './utils'
import { skipHoverEvents } from './Vector'

const Zones = ({
  map,
  activeSector,
  saveZone,
  setDrawMode,
  onEditPolygon,
  targetCoverage,
  setTargetCoverageButton,
  startDrawOrEditPolygon,
}) => {
  const dispatch = useDispatch()

  const zones = useSelector(selectZones, shallowEqual)
  const editZone = useSelector(selectEditZone)
  const editZoneColors = useSelector(selectEditZoneColors, shallowEqual)

  // Обробка події завершення редагування зони
  const onFinishEditing = useCallback(() => {
    saveZone(map, true)
    dispatch(setEditZone(null))
    setDrawMode(false)
  }, [ map, dispatch, setDrawMode, saveZone ])

  // Ініціалізація панелі інструментів редагування зони
  useEffect(() => {
    if (!map || !map.pm) {
      return
    }
    map.pm.addControls({
      drawMarker: false,
      drawCircleMarker: false,
      drawPolyline: false,
      drawText: false,
    })
    const buttons = map.pm.Toolbar.getControlOrder()
    if (!buttons.includes('done')) {
      map.pm.Toolbar.createCustomControl({
        name: 'done',
        block: 'custom',
        title: 'Finish Editing',
        className: 'Done-Icon',
        toggle: false,
        onClick: onFinishEditing,
      })
    }
    map.pm.removeControls()
  }, [ map, onFinishEditing ])

  // Увімкнення режиму редагування зони
  useEffect(() => {
    if (map && map.pm) {
      if (!editZone) {
        map.pm.editZone = null
      }
      if (!activeSector) {
        map.pm.activeSector = null
      }
    }
    if (!map || !map.pm || (!editZone && !activeSector) || !zones) {
      return
    }
    if (editZone) {
      const zone = zones.find(({ key }) => key === editZone)
      if (zone) {
        setDrawMode(true)
        if (map.pm.editZone !== editZone) {
          flattenPolygonOnMap(map, editZone, zone.geometry)
          map.pm.editZoneColors = editZoneColors
          zoomMapToEntity(map, editZone)
        }
        if (!map.pm.editZone) {
          const { radius, center } = zone.properties || {}
          if (radius && center) {
            const [ layer ] = getEntityLayers(map, zone.key)
            if (layer) {
              const circle = L.circle(center, { ...layer.options, radius })
              circle.addTo(map)
              map.removeLayer(layer)
              L.PM.reInitLayer(layer)
            }
          }
          startDrawOrEditPolygon(!zone.geometry.length)
        }
        map.pm.editZone = editZone
        map.pm.editZoneUUID = zone.uuid
        map.pm.editZoneProperties = zone.properties
        // setEditedLayersEvents(editZone)
      }
    } else if (activeSector) {
      if (!map.pm.activeSector) {
        setTargetCoverageButton(true)
      }
      map.pm.activeSector = activeSector
      // setEditedLayersEvents(activeSector)
    } else {
      setDrawMode(false)
    }
  }, [
    zones, editZone, map, activeSector, targetCoverage, startDrawOrEditPolygon, setDrawMode, setTargetCoverageButton,
    editZoneColors, // setEditedLayersEvents,
  ])

  // Обробка подвійного кліку на зоні
  const onLayerDblClick = useCallback((event) => {
    if (map.pm.globalEditModeEnabled()) {
      return
    }
    const list = getElementList(map, event.containerPoint)
    if (list.length > 0) {
      return
    }
    const layer = event.target
    const geometry = toGeometry(layer.getLatLngs())
    if (checkPolygonIsCorrect(geometry)) {
      L.DomEvent.stop(event)
      const zone = zones.find((zone) => zone.key === layer.options.id)
      onEditPolygon({
        id: layer.options.id,
        geometry: toPolygon(toTurf(geometry)).geometry.coordinates,
        properties: zone?.properties,
        uuid: zone?.uuid,
      })
    }
  }, [ onEditPolygon, zones, map ])

  // Встановлення обробника подвійного кліку на зонах
  useEffect(() => {
    map.eachLayer((layer) => {
      if (layer.options.id && layer.options.id.startsWith(ID_GEO_ZONES)) {
        layer.on('dblclick', onLayerDblClick)
      }
    })
  }, [ map, zones, onLayerDblClick ])

  return (
    <> {
      zones
        .filter(({ geometry }) => geometry.length)
        .map(({ key, hash, geometry, options }) => (
          <Polygon
            id={key}
            key={`${editZone || 'zero'}-${hash}`}
            positions={reverseLngLat(geometry)}
            pmIgnore={!map.pm || key !== editZone}
            eventHandlers={skipHoverEvents}
            {...options}
          />
        ))
    }
    </>
  )
}

export default Zones
