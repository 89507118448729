import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import {
  Checkbox,
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  Stack,
} from '@fluentui/react'
import { SelectionMode } from '@fluentui/utilities'
import { DefaultButton, PrimaryButton } from '../common/Button'
import { alwaysFalse, getItemKey } from '../../utils/format'

const gridStyles = {
  root: {
    overflowX: 'auto',
    selectors: {
      '& [role=grid]': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        height: 'calc(100vh - 240px)',
      },
    },
  },
  headerWrapper: {
    flex: '0 0 auto',
  },
  contentWrapper: {
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
  },
}

const stackTokens = { childrenGap: 10 }

const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  keepInBounds: true,
}

const modalProps = {
  isBlocking: false,
  isModeless: true,
  dragOptions: dragOptions,
}

const dialogStyles = {
  main: {
    selectors: {
      '@media (min-width: 680px)': {
        maxWidth: 680,
        width: 520,
      },
    },
  },
}

const Row = ({ item, index, column, attributes, setAttributes, setModified }) => {
  const onChangeHandler = useCallback((ev, value) => {
    if (!Array.isArray(attributes)) { return }
    const newAttribute = attributes.map((attribute, indexColumn) => {
      return (index !== indexColumn
        ? attribute
        : {
            ...attribute,
            selected: value,
          }
      )
    })
    setAttributes(newAttribute)
    setModified(true)
  }, [ attributes, index, setAttributes, setModified ])

  return column.fieldName === 'selected'
    ? <Checkbox
        checked={item.selected}
        onChange={onChangeHandler}
      />
    : item?.[column.fieldName]
}

const SelectAttributesDialog = ({
  onApply,
  onClose,
  hidden,
  columns,
  selectedAttributes,
}) => {
  const [ attributes, setAttributes ] = useState()
  const [ initialState, setInitialState ] = useState(null)
  const [ state, setState ] = useState(null)
  const [ modified, setModified ] = useState(false)

  useEffect(() => {
    if (!columns || !Array.isArray(columns)) {
      return
    }
    const items = columns.map((column) => {
      return {
        key: column.id,
        label: column.label,
        hidden: column.hidden,
        selected: state?.includes(column.id),
      }
    })
    setAttributes(items)
  }, [ columns, state ])

  useEffect(() => {
    if (!hidden) {
      setState(selectedAttributes)
      if (!initialState) {
        setInitialState(selectedAttributes)
      }
    }
  }, [ hidden, initialState, setInitialState, selectedAttributes ])

  const doApply = useCallback(() => {
    if (!attributes || !Array.isArray(attributes)) {
      return
    }
    const selected = attributes.map((column) => {
      if (column.selected) {
        return column.key
      }
      return null
    }).filter(Boolean)
    onApply(selected)
  }, [ onApply, attributes ])

  const hasSelected = useCallback(() => {
    if (!attributes || !Array.isArray(attributes)) {
      return false
    }
    return attributes.some((attribute) => attribute.selected)
  }, [ attributes ])

  const onClickApply = useCallback(() => {
    doApply()
    setModified(false)
  }, [ doApply, setModified ])

  const closeAndClear = useCallback(() => {
    onClose()
    setInitialState(null)
    setModified(false)
  }, [ onClose, setInitialState ])

  const onClickOk = useCallback(() => {
    doApply()
    closeAndClear()
  }, [ doApply, closeAndClear ])

  const onClickCancel = useCallback(() => {
    if (initialState) {
      onApply(initialState)
    }
    closeAndClear()
  }, [ initialState, closeAndClear, onApply ])

  const onRenderItemColumn = useCallback((item, index, column) => (
    <Row
      item={item}
      index={index}
      column={column}
      attributes={attributes}
      setAttributes={setAttributes}
      setModified={setModified}
    />
  ), [ attributes, setAttributes ])

  const onHeaderClick = useCallback((_, column) => {
    if (column.key === 'visible') {
      const checkedAttributes = attributes.map((attribute) => {
        if (attribute.selected) {
          return attribute.key
        }
        return null
      }).filter(Boolean)
      if (checkedAttributes.length < attributes.length) {
        const allAttributes = attributes.map((attribute) => {
          return attribute.key
        })
        setState(allAttributes)
      } else {
        setState([])
      }
      setModified(true)
    }
  }, [ attributes, setState ])

  const dialogContentProps = useMemo(() => ({
    type: DialogType.normal,
    title: (
      <Stack horizontal>
        <div>Choose attributes</div>
      </Stack>
    ),
  }), [])

  const columnsTable = useMemo(() => ([
    { key: 'name', name: 'Name column', fieldName: 'label', minWidth: 150, maxWidth: 300, isResizable: true },
    { key: 'visible', name: 'Visible', fieldName: 'selected', minWidth: 100, maxWidth: 100, isResizable: true },
  ]), [])

  return (
    <Dialog
      key="chooseAttribute"
      hidden={hidden}
      onDismiss={onClose}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      styles={dialogStyles}
    >
      <Stack tokens={stackTokens} style={{ marginRight: '8px', overflow: 'auto' }}>
        <DetailsList
          items={attributes}
          columns={columnsTable}
          enterModalSelectionOnTouch={true}
          constrainMode={ConstrainMode.unconstrained}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selectionMode={SelectionMode.none}
          compact={false}
          onRenderItemColumn={onRenderItemColumn}
          styles={gridStyles}
          getKey={getItemKey}
          setKey="settingsColumns"
          onShouldVirtualize={alwaysFalse}
          onColumnHeaderClick={onHeaderClick}
          onSelectionChanged={onHeaderClick}
        />
      </Stack>
      <DialogFooter>
        <PrimaryButton
          onClick={onClickApply}
          text="Apply"
          disabled={!modified || !hasSelected()}
        />
        <DefaultButton
          onClick={onClickOk}
          text="Ok"
          disabled={!hasSelected()}
        />
        <DefaultButton onClick={onClickCancel} text="Cancel" />
      </DialogFooter>
    </Dialog>
  )
}

export default SelectAttributesDialog
