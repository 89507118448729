import { fetch } from './rest'

export const checkAuthenticated = () =>
  fetch('/api/user')
    .then((user) => {
      if (!user || user.id === 'anonymous') {
        return null
      }
      return user
    })

export const logout = () =>
  fetch('/api/auth/bh2removecookie', 'POST') // .then(checkAuthenticated)

export const changePassword = ({ userId, oldPassword, newPassword }) =>
  fetch(`/api/user/${userId}/password`, 'PUT', { oldPassword, newPassword })

// $shiro1$SHA-256$500000$dO9ASy4IVKY6OQiUB7cJzQ==$lZOD4SebsqiQc+iXe6upmf1069vXtyV8U5REIPsYo+g=
