import React from 'react'

import './Subheader.css'

const Subheader = ({ text }) => (
  <div className="common-subheader">
    {text}
  </div>
)

export default Subheader
