import React, { useCallback, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { DayOfWeek } from '@fluentui/react'
import { selectComplaintFields, selectComplaintFldIdx } from '../../features/network/networkSlice'
import { isDateType, isNumberType } from '../../utils/grid'
import {
  COMPLAINT_ADDRESS_FIELD, EXCLUDED_FIELDS, LNG_ATTR, LAT_ATTR, COMPOSITE_INDICES,
} from '../../constants/network'
import { ISODate } from '../../features/network/utils'
import PanelSections from '../Panels/PanelSections'
import Checkbox from '../common/Checkbox'
import SpinEditField from '../common/SpinEditField'
import TextField from '../common/TextField'
import DatePicker from '../common/DatePicker'
import Dropdown from '../common/Dropdown'
import { findIndex } from '../../features/network/indexing'
import { selectCoordinatesFormat } from '../../features/settings/settingsSlice'
import { DECIMAL_SCALE_COORDINATE } from '../Panels/SearchMap/SearchMap'
import { LOCALE } from '../../utils/format'

const formatDate = (date) => date.toLocaleDateString()

const parseDateFromString = (string) => {
  const parts = string.split('.')
  return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`)
}

const findField = (fields, search) => fields.findIndex(({ id }) => id === search)

const NetworkItemProperties = ({
  path, data, onChange, fields, refHot, complaints, onSelectComplaint, readOnly, isDraft, isRemoved, nameFieldIndex,
  customEditableCheck,
}) => {
  const complaintFields = useSelector(selectComplaintFields)
  const [
    idFieldIdx,
    nameFieldIdx,,,,
    typePFieldIdx,
    dateFieldIdx,
  ] = useSelector(selectComplaintFldIdx, shallowEqual)
  const coordinatesFormat = useSelector(selectCoordinatesFormat, shallowEqual)

  const addressFieldIdx = useMemo(() => findIndex(complaintFields, COMPLAINT_ADDRESS_FIELD), [ complaintFields ])
  const decimalScaleCoordinate = useMemo(() => coordinatesFormat.coordinatesPrecision ?? DECIMAL_SCALE_COORDINATE,
    [ coordinatesFormat ])

  const pages = useMemo(() => [ ...new Set(fields.map(({ page }) => page)) ], [ fields ])

  const valueChanged = useMemo(() => Object.fromEntries(fields.map(({ id }) => [
    id,
    (event, value) => {
      if (event instanceof Date && value === undefined) {
        value = event
      }
      if (!onChange) {
        return
      }
      const fIdx = findField(fields, id)
      if (value && value.key) {
        value = value.key
      } else if (isNumberType(fields[fIdx].type) && value !== null) {
        value = typeof value === 'string'
          ? parseFloat(value.replaceAll(/\s/g, '').replace(',', '.'))
          : value
      } else if (isDateType(fields[fIdx].type) && value !== null) {
        value = ISODate(value)
      }
      if (fIdx === nameFieldIndex) {
        data[fIdx] = (value || '').toUpperCase()
      } else {
        data[fIdx] = value
      }
      onChange(fIdx)
    },
  ])), [ fields, data, onChange, nameFieldIndex ])

  const renderCheckBox = useCallback((id, label, description, value, editable) => (
    <Checkbox
      key={id}
      id={id}
      label={label}
      defaultChecked={value}
      disabled={!editable}
      onChange={editable && valueChanged[id]}
    />
  ), [ valueChanged ])

  const renderDatePicker = useCallback((id, label, description, value, editable) => editable
    ? (
        <DatePicker
          key={id}
          allowTextInput
          formatDate={formatDate}
          parseDateFromString={parseDateFromString}
          firstDayOfWeek={DayOfWeek.Monday}
          value={new Date(value)}
          onSelectDate={valueChanged[id]}
          label={label}
          description={description}
        />
      )
    : (
        <TextField
          key={id}
          defaultValue={value ? formatDate(new Date(value)) : ''}
          label={label}
          description={description}
          readOnly={true}
        />
      ),
  [ valueChanged ])

  const renderSelect = useCallback((id, label, description, value, editable, values) => editable
    ? (
        <Dropdown
          key={id}
          defaultSelectedKey={value}
          onChange={valueChanged[id]}
          options={[
            ...(values
              ? values.split(',').map((item) => ({ key: item, text: item }))
              : [ { key: 'NONE', text: '(empty)' }, { key: value, text: value } ]
            ),
          ]}
          label={label}
          description={description}
        />
      )
    : (
        <TextField
          key={id}
          label={label}
          description={description}
          defaultValue={values && Array.isArray(values)
            ? values.find(({ key }) => key === value)?.value ?? '(empty)'
            : value}
          readOnly={true}
        />
      ),
  [ valueChanged ])

  const viewValue = (value, precision) => {
    const numberFormatting = new Intl.NumberFormat(
      LOCALE,
      { minimumIntegerDigits: 1, minimumFractionDigits: 0, maximumFractionDigits: precision },
    ).format

    return value ? numberFormatting(value) : value
  }
  const renderSpinBox = useCallback((id, label, description, value, editable, precision, min, max) => editable
    ? (
        <SpinEditField
          key={id}
          defaultValue={value}
          onChange={valueChanged[id]}
          precision={precision}
          label={label}
          description={description}
          min={min}
          max={max}
        />
      )
    : (
        <TextField
          key={id}
          label={label}
          description={description}
          defaultValue={viewValue(value)}
          readOnly={true}
        />
      ),
  [ valueChanged ])

  const renderInput = useCallback((id, label, description, value, editable, maxLength, onClick) => onClick
    ? (
        <div className='text-field-container' onDoubleClick={onClick}>
          <TextField
            key={id}
            label={label}
            description={description}
            maxLength={maxLength ?? 250}
            defaultValue={value}
            readOnly={!editable}
            onChange={editable && valueChanged[id]}
            upperCase={id === fields[nameFieldIndex]?.id}
          />
        </div>
      )
    : (
        <TextField
          key={id}
          label={label}
          description={description}
          maxLength={maxLength ?? 250}
          defaultValue={value}
          readOnly={!editable}
          onChange={editable && valueChanged[id]}
          upperCase={id === fields[nameFieldIndex]?.id}
        />
      ),
  [ valueChanged, nameFieldIndex, fields ])

  const renderEditor = useCallback(
    (id, type, label, description, value, editable, precision, values, min, max, onClick) => type === 'boolean'
      ? renderCheckBox(id, label, description, value, editable)
      : type === 'datetime' || type === 'date'
        ? renderDatePicker(id, label, description, value, editable)
        : type === 'enum'
          ? renderSelect(id, label, description, value, editable, values)
          : isNumberType(type)
            ? renderSpinBox(id, label, description, value, editable, precision, min, max)
            : renderInput(id, label, description, value, editable, max, onClick),
    [ renderCheckBox, renderDatePicker, renderInput, renderSelect, renderSpinBox ])

  const handleOnClickComplaint = useCallback((path) => () => {
    onSelectComplaint(path)
  }, [ onSelectComplaint ])

  const getPrecision = useCallback((type, id) => {
    if (type === 'double') {
      const isCoordinates = LNG_ATTR.includes(id) || LAT_ATTR.includes(id)
      if (isCoordinates) {
        return decimalScaleCoordinate
      }
      const isCompositeIndex = COMPOSITE_INDICES.includes(id)
      if (isCompositeIndex) {
        return 4
      }
      return 8
    }
    return 0
  }, [ decimalScaleCoordinate ])

  const items = useMemo(() => {
    if (!data) {
      return null
    }

    const res = pages.map((page) => {
      const pageFields = fields
        .filter(({ id, hidden, page: p }) => p === page && EXCLUDED_FIELDS.indexOf(id) < 0 && !hidden)
      return pageFields && pageFields.length > 0
        ? {
            id: page,
            title: page,
            Component: () => (
              <>
                {pageFields.map(
                  ({ id, label, type, precision, editable, editableDraft, description, min, max, ...other }) =>
                    renderEditor(
                      id,
                      type,
                      label, description,
                      data[fields.findIndex(({ id: fldId }) => fldId === id)],
                      !readOnly && !isRemoved && (isDraft ? editableDraft : editable) &&
                        (!customEditableCheck || customEditableCheck(id)),
                      precision || getPrecision(type, id),
                      type === 'enum' && other['enum-values'],
                      min,
                      max,
                    ),
                )}
              </>
            ),
          }
        : null
    }).filter(Boolean)
    if (complaints && complaints.length > 0) {
      res.push({
        id: 'complaints-table',
        title: `Complaints (${complaints.length})`,
        Component: () => <>
          {complaints
            .map(({ element, path }) =>
              renderEditor(
                element[idFieldIdx],
                'text',
                `${element[typePFieldIdx]} ${element[dateFieldIdx]}`,
                element[addressFieldIdx],
                element[nameFieldIdx],
                false,
                null,
                null,
                null,
                null,
                handleOnClickComplaint(path),
              ),
            )
          }
        </>,
      })
    }
    return res
  }, [
    pages, complaints, customEditableCheck,
    data, fields, isDraft, isRemoved, readOnly, renderEditor,
    dateFieldIdx, idFieldIdx, nameFieldIdx, typePFieldIdx, addressFieldIdx,
    handleOnClickComplaint, getPrecision,
  ])

  return items
    ? (
        <PanelSections id="network-item" items={items} />
      )
    : null
}

export default NetworkItemProperties
