import { Callout } from '@fluentui/react'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setComplaintEditor, setSectorEditor, setSiteEditor } from '../../../../features/network/networkSlice'
import { directDrawSector, directDrawSite } from '../../draw'
import { SECTOR_TYPES } from '../../../../constants/network'
import { selectSettingsSectorsIcon } from '../../../../features/settings/settingsSlice'
import { setPanel } from '../../../../features/panel/panelSlice'

const SelectorEditor = ({ elementsMenu, setElementsMenu, setActiveSite, setActiveSector, elementsMenuTarget }) => {
  const dispatch = useDispatch()
  const sectorsIcon = useSelector(selectSettingsSectorsIcon)

  const hideElementsMenu = useCallback(() => {
    setElementsMenu(null)
  }, [ setElementsMenu ])

  // Ініціалізація меню вибору елементів після даблкліку
  useEffect(() => {
    elementsMenu && elementsMenu.forEach(({ id, type, color, technology }) => {
      const canvas = document.getElementById(`canvas-${id}`)
      if (!canvas) {
        return
      }
      const ctx = canvas.getContext('2d')
      switch (type) {
        case 'site': {
          directDrawSite(ctx, 24, 18, 12, undefined, null, color)
          break
        }
        case 'sector': {
          const figure = sectorsIcon[technology] ?? SECTOR_TYPES[technology]
          directDrawSector(ctx, 2, 18, 12, 90, null, null, color, figure, technology)
          break
        }
        case 'complaint': {
          ctx.strokeStyle = '#1fa820'
          ctx.fillStyle = 'rgb(98, 246, 14)'
          ctx.beginPath()
          ctx.arc(24, 18, 8, 0, 2 * Math.PI)
          ctx.fill()
          ctx.stroke()
          break
        }
        default:
      }
    })
  }, [ elementsMenu, sectorsIcon ])

  return (
    <Callout
      onDismiss={hideElementsMenu}
      setInitialFocus
      calloutMaxHeight={360}
      hidden={!elementsMenu}
      target={elementsMenuTarget}
    >
      <table>
        <tbody>
        {(elementsMenu || []).map(({
          id,
          type,
          name,
        }) => (
          <tr
            key={`${type}-${id}`}
            onClick={() => {
              switch (type) {
                case 'site': {
                  hideElementsMenu()
                  setActiveSite(id)
                  dispatch(setSiteEditor(id))
                  dispatch(setPanel('edit-site'))
                  break
                }
                case 'sector': {
                  hideElementsMenu()
                  setActiveSector(id)
                  dispatch(setSectorEditor(id))
                  dispatch(setPanel('edit-sector'))
                  break
                }
                case 'complaint': {
                  hideElementsMenu()
                  dispatch(setComplaintEditor(id))
                  dispatch(setPanel('edit-complaint'))
                  break
                }
                default:
              }
            }}
            className="menu-item"
          >
            <td>
              <canvas id={`canvas-${id}`} width={48} height={32}/>
            </td>
            <td style={{
              verticalAlign: 'middle',
              fontWeight: 'bold',
            }}>
              {name}&nbsp;&nbsp;&nbsp;&nbsp;
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </Callout>
  )
}

export default SelectorEditor
