import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useMapEvents } from 'react-leaflet'
import L from 'leaflet'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import simplify from '@turf/simplify'
import { point } from '@turf/helpers'
import { selectComplaintsAsGeoJSON } from '../../features/network/networkSlice'
import { selectComputationZone, toPolygon } from '../../features/geo/geoSlice'
import { createClusterIcon } from '../../components/Map/render'
import { calculatePainZones } from './utils'

const Complaints = ({
  resolution, sensitivity, weight, mode, dateFrom, dateTo, onChangeZoom, onFilteredComplaintsCount, onPainZonesCount,
  loadingComplaints,
}) => {
  const complaints = useSelector(selectComplaintsAsGeoJSON, shallowEqual)
  const computationZone = useSelector(selectComputationZone, shallowEqual)

  const map = useMapEvents({
    zoomend: () => {
      const zoom = map.getZoom()
      setZoom(zoom)
      onChangeZoom(zoom)
    },
    moveend: () => {
      setBounds(map.getBounds())
    },
  })

  const [ zoom, setZoom ] = useState(map.getZoom())
  const [ bounds, setBounds ] = useState(map.getBounds())

  const poly = useMemo(() => {
    let poly = null
    if (computationZone) {
      poly = toPolygon(computationZone)
      simplify(poly, { tolerance: 0.001, highQuality: true, mutate: true })
    }
    return poly
  }, [ computationZone ])

  const filteredComplaints = useMemo(() => complaints.features
    .filter(({ geometry: { coordinates: [ lng, lat ] }, properties: { date } }) => {
      const d = new Date(date)
      const inComputation = !poly || booleanPointInPolygon(point([ lng, lat ]), poly)
      const inDates = (!dateFrom || dateFrom <= d) && (!dateTo || d <= dateTo)
      return inComputation && inDates
    }), [ complaints, poly, dateFrom, dateTo ])

  useEffect(() => {
    map.painZones = L.geoJson(null).addTo(map)
    map.complaints = L.geoJson(null, {
      pointToLayer: createClusterIcon,
    }).addTo(map)
    return () => {
      map.painZones.removeFrom(map)
      delete map.painZones
    }
  }, [ map ])

  useEffect(() => {
    onFilteredComplaintsCount && onFilteredComplaintsCount(filteredComplaints.length)
  }, [ filteredComplaints, onFilteredComplaintsCount ])

  useEffect(() => {
    if (loadingComplaints) {
      return
    }
    document.body.classList.add('waiting')
    setTimeout(() => {
      if (!map || !map.painZones || !map.complaints) {
        return
      }
      map.painZones.clearLayers()
      map.complaints.clearLayers()
      const [ zones, clusters ] = calculatePainZones(filteredComplaints, zoom, resolution, sensitivity, weight,
        mode === 'weight', bounds.toBBox())
      console.info('Pain zones calculated:', zones.length)
      onPainZonesCount && onPainZonesCount(zones.length)
      map.painZones.addData(zones)
      map.complaints.addData(clusters)
      document.body.classList.remove('waiting')
    }, 50)
  }, [
    map, zoom, bounds, filteredComplaints, resolution, sensitivity, weight, mode, poly, dateFrom, dateTo,
    onFilteredComplaintsCount, onPainZonesCount, loadingComplaints,
  ])

  return null
}

L.LatLngBounds.prototype.toBBox = function () {
  return [ this.getWest(), this.getSouth(), this.getEast(), this.getNorth() ]
}

export default Complaints
