import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { StackItem, ActionButton } from '@fluentui/react'
import { cabinet as i18n } from '../constants'
import { iconClassGroup } from './constants'

const blockIcon = {
  iconName: 'blockContact',
}

const editIcon = {
  iconName: 'Edit',
}

const unblockIcon = {
  iconName: 'UserFollowed',
}

const Menu = memo(({
  existBlock,
  existUnblock,
  onBlock,
  onUnblock,
  onEdit,
  busy,
  selectCell,
}) => {
  return (
    <StackItem>
      <ActionButton
        disabled={!selectCell || busy}
        text={i18n.edit}
        iconProps={editIcon}
        onClick={onEdit}
        allowDisabledFocus
      />
      <ActionButton
        disabled={!existUnblock || busy}
        iconProps={blockIcon}
        className={iconClassGroup}
        onClick={onBlock}
      >
        {i18n.block}
      </ActionButton>
      <ActionButton
        disabled={!existBlock || busy}
        iconProps={unblockIcon}
        className={iconClassGroup}
        onClick={onUnblock}
      >
        {i18n.unblock}
      </ActionButton>
  </StackItem>
  )
})

Menu.displayName = 'UsersMenu'

Menu.propTypes = {
  existBlock: PropTypes.bool,
  existUnblock: PropTypes.bool,
  onBlock: PropTypes.func,
  onUnblock: PropTypes.func,
  onChangeRole: PropTypes.func,
  onEdit: PropTypes.func,
  busy: PropTypes.bool,
  selectCell: PropTypes.bool,
}

export default Menu
