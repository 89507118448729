import React from 'react'
import { PrimaryButton } from '@fluentui/react/lib/Button'

import './styles.css'

const LoginForm = () => {
  return (
    <div className="centered">
      <PrimaryButton
        href="/api/auth/login/form"
        text="Sign in"
      />
    </div>
  )
}

export default LoginForm
