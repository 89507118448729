import React from 'react'
import { Checkbox as CheckboxComponent } from '@fluentui/react'

import './Checkbox.css'

const Checkbox = (props) => (
  <CheckboxComponent className="capex-checkbox" {...props} />
)

export default Checkbox
