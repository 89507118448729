import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner, SpinnerSize } from '@fluentui/react'
import { loadUsers, selectUsers } from '../../../features/users/usersSlice'
import {
  selectLoading, selectEventLog, getEventLog, MESSAGES, STATUS_PENDING, STATUS_SUCCESS, STATUS_ERROR, clearEventLog,
  saveEventLog, EVENT_TYPE,
} from '../../../features/eventLog/eventLogSlice'
import TextField from '../../../components/common/TextField'
import Dropdown from '../../../components/common/Dropdown'
import DatePicker from '../../../components/common/DatePicker'
import { DefaultButton, PrimaryButton } from '../../../components/common/Button'
import { exportToFile } from '../../../utils/export'

import './EventLog.css'

const typeAll = {
  key: 'all',
  text: '(All types)',
}

const statusAll = {
  key: 'all',
  text: '(All statuses)',
}

const types = [
  typeAll,
  ...Object.keys(MESSAGES).sort().map((key) => ({
    key,
    text: MESSAGES[key],
  })),
]

const statuses = [
  statusAll,
  { key: STATUS_PENDING, text: 'Pending' },
  { key: STATUS_SUCCESS, text: 'Success' },
  { key: STATUS_ERROR, text: 'Error' },
]

const EventLog = () => {
  const dispatch = useDispatch()

  const loading = useSelector(selectLoading)
  const loadedUsers = useSelector(selectUsers)
  const logs = useSelector(selectEventLog)

  const [ user, setUser ] = useState(null)
  const [ type, setType ] = useState(typeAll)
  const [ status, setStatus ] = useState(statusAll)
  const [ dateFrom, setDateFrom ] = useState(null)
  const [ dateTo, setDateTo ] = useState(null)
  const [ ip, setIp ] = useState('')

  useEffect(() => {
    dispatch(loadUsers())

    return () => {
      dispatch(clearEventLog())
    }
  }, [ dispatch ])

  const handleExport = useCallback(() => {
    if (logs && user?.text) {
      exportToFile(logs, `${user?.text}.txt`)
      dispatch(saveEventLog(EVENT_TYPE.eventLogExport, `: ${logs.split('\n').length} lines`))
    }
  }, [ user, logs, dispatch ])

  const handleSelectUser = useCallback((event, option) => {
    if (user?.key !== option.key) {
      setUser(option)
    }
  }, [ setUser, user ])

  const handleSelectType = useCallback((event, option) => {
    if (type?.key !== option.key) {
      setType(option)
    }
  }, [ type, setType ])

  const handleSelectStatus = useCallback((event, option) => {
    if (status?.key !== option.key) {
      setStatus(option)
    }
  }, [ status, setStatus ])

  const changeDateFrom = useCallback((date) => {
    setDateFrom(date)
  }, [ setDateFrom ])

  const changeDateTo = useCallback((date) => {
    setDateTo(date)
  }, [ setDateTo ])

  const changeIp = useCallback((event, value) => {
    setIp(value?.trim() || '')
  }, [ setIp ])

  const users = useMemo(() => loadedUsers?.map((item) => ({
    key: item.id,
    text: item.name,
  }) || []), [ loadedUsers ])

  const handleRefresh = useCallback(() => {
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    const today = d.getTime()
    const timeZoneOffsetMs = d?.getTimezoneOffset() * 60 * 1000
    const from = dateFrom?.getTime() || today
    const to = dateTo?.getTime()
    dispatch(getEventLog(
      user?.key,
      from && (from - timeZoneOffsetMs),
      to && (to + 86400000 - timeZoneOffsetMs),
      ip || null,
      type?.key === 'all' ? null : type?.key,
      status?.key === 'all' ? null : status?.key,
    ))
  }, [ dispatch, user, type, status, dateFrom, dateTo, ip ])

  return (
    <div className='event-log-content'>
      <div className="panel-header">
        <Dropdown
          label="User"
          placeholder="Select a user"
          className="item"
          options={users}
          selectedKey={user?.key}
          onChange={handleSelectUser}
        />
        <TextField
          label="IP Address"
          className="item"
          value={ip}
          onChange={changeIp}
        />
        <DatePicker
          label="Date from"
          value={dateFrom}
          className="item"
          onSelectDate={changeDateFrom}
        />
        <DatePicker
          label="Date to"
          value={dateTo}
          className="item"
          onSelectDate={changeDateTo}
        />
        <Dropdown
          label="Event type"
          className="large-item"
          options={types}
          selectedKey={type?.key}
          onChange={handleSelectType}
        />
        <Dropdown
          label="Status"
          className="item"
          options={statuses}
          selectedKey={status?.key}
          onChange={handleSelectStatus}
        />
        <PrimaryButton
          className="small-item"
          text="Refresh"
          onClick={handleRefresh}
          disabled={!user || !user.key}
        />
        <DefaultButton
          className="small-item"
          text="Export"
          onClick={handleExport}
          disabled={!logs || !user?.text}
        />
        <div className="tail" />
      </div>
      {loading
        ? (
            <div className='panel-spinner'>
              <Spinner size={SpinnerSize.large} />
            </div>
          )
        : logs
          ? (
              <div className='panel-logs'>
                <pre>{logs}</pre>
              </div>
            )
          : (
              <div className='panel-no-logs'>
                <pre>{'No logs to show'}</pre>
              </div>
            )
      }
    </div>
  )
}

export default EventLog
