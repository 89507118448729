import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useBoolean } from '@fluentui/react-hooks'
import { Stack } from '@fluentui/react'
import {
  MapIcon, SitesIcon, RBSIcon, SectorsIcon, ComplaintsIcon, BusinessCasesIcon, MacroIndicatorsIcon, TRFIcon,
  CompositeIndexIcon, HistoricalDataIcon, UpdateDBIcon, CabinetIcon, VectorMapIcon, CheckmarkIcon, CalculateDraftIcon,
  MSExcelIcon,
} from '../common/icons/names'
import { Logo, LogoWithText } from '../common/icons'
import { activeProjectId, selectIsMyProject, selectIsSharedProject } from '../../features/projects/projectsSlice'
import { buildClassList } from '../utils/classes'
import { selectUserAccess, selectChangePasswordOpen, selectUser } from '../../features/login/loginSlice'
import { selectChangesCount, selectOfflineMode } from '../../features/loading/loadingSlice'
import { closeMap, selectVectorItems } from '../../features/vector/vectorSlice'
import { saveActiveProjectJobs, selectPendingTasks } from '../../features/taskLog/taskLogSlice'
import { setPanel } from '../../features/panel/panelSlice'
import {
  loadNotifications, selectNewNotifications, selectNotifications,
} from '../../features/notifications/notificationsSlice'
import { loadMetrics, selectMetrics } from '../../features/metrics/metricsSlice'
import { EVENT_TYPE, saveEventLog } from '../../features/eventLog/eventLogSlice'
import { selectIsEditNeighborsOpened } from '../../features/bc/bcSlice'
import {
  BUSINESS_CASES, COMPLAINTS, COMPOSITE_INDEXES_CALCULATE, MACRO_INDICATORS, TRF, HISTORICAL_DATA, UPDATE_DB,
  EDIT_ELEMENTS, NOTIFICATIONS, ANALYTICAL_REPORTS,
} from '../../constants/access'
import { KEYS, ROUTES, sectionByPath } from '../../constants/routes'
import CompositeIndex from '../CompositeIndex'
import HistoricalData from '../HistoricalData'
import ChangePassword from '../ChangePassword'
import SelectOperationUpdateDB from '../UpdateDB/SelectOperationUpdateDB'
import SelectOperationCabinet from '../Cabinet/SelectOperationCabinet'
import SelectOperationReports from '../AnalyticalReports/SelectOperationReports'
import { UpdateDB } from '../UpdateDB'
import Badge from '../common/Badge'
import { SideNavButton, SideNavDivider } from './SideNavButton'
import AutosaveMode from './AutosaveMode'
import MetricsPanel from './MetricsPanel'

import './Drawer.css'

const DRAWER_BASE_CLASS = 'drawer'
const LOGO_BASE_CLASS = 'drawer-logo'

const initialExpanded = localStorage.getItem('drawer_expanded') === 'true'

const DrawerLogo = ({ expanded, onClick }) => (
  <div className={buildClassList(LOGO_BASE_CLASS, { expanded })} onClick={onClick}>
    <img src={expanded ? LogoWithText : Logo} title={expanded ? '' : 'Smart Capex'} alt="Smart Capex" />
  </div>
)

const Drawer = ({ routes }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const projectId = useSelector(activeProjectId)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const isMyProject = useSelector(selectIsMyProject)
  const isSharedProject = useSelector(selectIsSharedProject)
  const offlineMode = useSelector(selectOfflineMode)
  const changesCount = useSelector(selectChangesCount)
  const vectorMaps = useSelector(selectVectorItems)
  const pendingTasks = useSelector(selectPendingTasks)
  const newNotifications = useSelector(selectNewNotifications)
  const changePasswordOpen = useSelector(selectChangePasswordOpen)
  const user = useSelector(selectUser)
  const notifications = useSelector(selectNotifications, shallowEqual)
  const metrics = useSelector(selectMetrics, shallowEqual)
  const isEditNeighborsOpened = useSelector(selectIsEditNeighborsOpened)

  const [ expanded, setExpanded ] = useState(initialExpanded)
  const [ compositeIndexOpen, { setTrue: openCompositeIndex, setFalse: closeCompositeIndex } ] = useBoolean(false)
  const [ historicalDataOpen, { setTrue: openHistoricalData, setFalse: closeHistoricalData } ] = useBoolean(false)
  const [ updateDbOpen, { setTrue: openUpdateDb, setFalse: closeUpdateDb } ] = useBoolean(false)
  const [ reportsOpen, { setTrue: openReports, setFalse: closeReports } ] = useBoolean(false)
  const [ cabinetOpen, { setTrue: openCabinet, setFalse: closeCabinet } ] = useBoolean(false)
  const [ synchronizeOpen, { setTrue: openSynchronize, setFalse: closeSynchronize } ] = useBoolean(false)

  const doOpenCompositeIndex = useCallback(() => {
    dispatch(saveEventLog(EVENT_TYPE.openToolWindow, ': Composite Index'))
    openCompositeIndex()
  }, [ openCompositeIndex, dispatch ])

  const doOpenHistoricalData = useCallback(() => {
    dispatch(saveEventLog(EVENT_TYPE.openToolWindow, ': Historical Data'))
    openHistoricalData()
  }, [ openHistoricalData, dispatch ])

  const doOpenUpdateDb = useCallback(() => {
    dispatch(saveEventLog(EVENT_TYPE.openToolWindow, ': Update DB'))
    openUpdateDb()
  }, [ openUpdateDb, dispatch ])

  const doOpenReports = useCallback(() => {
    dispatch(saveEventLog(EVENT_TYPE.openToolWindow, ': Reports'))
    openReports()
  }, [ openReports, dispatch ])

  const doOpenCabinet = useCallback(() => {
    dispatch(saveEventLog(EVENT_TYPE.openToolWindow, ': Cabinet'))
    openCabinet()
  }, [ openCabinet, dispatch ])

  const doOpenSynchronize = useCallback(() => {
    dispatch(saveEventLog(EVENT_TYPE.openToolWindow, ': Synchronize with Default project'))
    openSynchronize()
  }, [ openSynchronize, dispatch ])

  const isCabinet = useMemo(() => (routes?.location?.pathname ?? '').split('/')[1] === 'cabinet', [ routes ])

  useEffect(() => {
    const beforeUnload = () => {
      dispatch(saveActiveProjectJobs(projectId))
    }

    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [ dispatch, projectId ])

  useEffect(() => {
    const doLoadNotifications = () => loadNotifications(dispatch, user, notifications, projectId)
    const doLoadMetrics = () => loadMetrics(dispatch, user, metrics)

    const intervalNotifications = setInterval(doLoadNotifications, 10 * 1000) // 10 seconds
    const intervalMetrics = setInterval(doLoadMetrics, 10 * 1000) // 10 seconds

    return () => {
      clearInterval(intervalNotifications)
      clearInterval(intervalMetrics)
    }
  }, [ dispatch, user, notifications, metrics, projectId ])

  const doExpand = useCallback(() => {
    const value = !expanded
    localStorage.setItem('drawer_expanded', String(value))
    setExpanded(value)
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 50)
  }, [ expanded, setExpanded ])

  const logo = useCallback(() => (
    <DrawerLogo expanded={expanded} onClick={doExpand}/>
  ), [ expanded, doExpand ])

  const goToLink = useCallback((event) => {
    let el = event.currentTarget
    while (el && el.dataset.link === undefined) {
      el = el.parentElement
    }
    if (el) {
      let link = el.dataset.link
      link = link.startsWith('/') ? link : `/${projectId ?? '_'}/${link}`
      const newTab = el.dataset.newtab === 'true'
      if (newTab) {
        window.open(link, '_blank')
      } else {
        dispatch(setPanel(null))
        history.push(link)
      }
    }
  }, [ projectId, history, dispatch ])

  const sideNavButtonLink = useCallback((iconName, section, onClick = null) => (
    <SideNavButton
      iconName={iconName}
      text={ROUTES[section].label}
      expanded={expanded}
      link={ROUTES[section].route}
      newTab={ROUTES[section].route.startsWith('/')}
      onClick={onClick || goToLink}
      pressed={sectionByPath(window.location.pathname) === section}
    />
  ), [ expanded, goToLink ])

  const sideNavButtonLink2 = useCallback((iconName, meta, onCloseClick, id) => (
    <SideNavButton
      iconName={iconName}
      text={meta.label}
      expanded={expanded}
      link={meta.route}
      newTab={meta.route.startsWith('/')}
      onClick={goToLink}
      pressed={sectionByPath(window.location.pathname) === meta.route}
      closeId={id}
      onCloseClick={onCloseClick}
    />
  ), [ expanded, goToLink ])

  const sideNavButtonAction = useCallback((iconName, text, onClick, disabled = false) => (
    <SideNavButton
      iconName={iconName}
      text={text}
      expanded={expanded}
      disabled={disabled}
      onClick={onClick}
    />
  ), [ expanded ])

  const divider = useCallback(() => (
    <SideNavDivider expanded={expanded} />
  ), [ expanded ])

  const autosaveMode = useCallback(() => (
    <AutosaveMode
      disabled={isEditNeighborsOpened}
      expanded={expanded}
    />
  ), [ isEditNeighborsOpened, expanded ])

  const metricsPanel = useCallback(() => (
    <MetricsPanel expanded={expanded} data={metrics} />
  ), [ expanded, metrics ])

  const onCloseEditVectorMap = useCallback((id) => {
    dispatch(closeMap({ id }))
    if (window.location.pathname.split('/').slice(-2).join('/') === `vector-map/${id}`) {
      history.push(`/${projectId ?? '_'}/map`)
    }
  }, [ dispatch, projectId, history ])

  return (
    <Stack className={buildClassList(DRAWER_BASE_CLASS, { expanded })}>
      {logo()}
      <div className="drawer-container hidden-scrollbar">
        {sideNavButtonLink(MapIcon, KEYS.MAP)}
        {divider()}
        {sideNavButtonLink(SitesIcon, KEYS.SITES)}
        {sideNavButtonLink(RBSIcon, KEYS.RBS)}
        {sideNavButtonLink(SectorsIcon, KEYS.SECTORS)}
        {userAccess[COMPLAINTS] && sideNavButtonLink(ComplaintsIcon, KEYS.COMPLAINTS)}
        {userAccess[BUSINESS_CASES] && sideNavButtonLink(BusinessCasesIcon, KEYS.BUSINESS_CASES)}
        {userAccess[MACRO_INDICATORS] && sideNavButtonLink(MacroIndicatorsIcon, KEYS.MACRO_INDICATORS)}
        {userAccess[TRF] && sideNavButtonLink(TRFIcon, KEYS.TRF)}
        {vectorMaps
          .map(({ id, name, editing }) => editing
            ? sideNavButtonLink2(VectorMapIcon, { label: name, route: `vector-map/${id}` }, onCloseEditVectorMap, id)
            : null,
          )
          .filter(Boolean)
        }
        {(userAccess[COMPOSITE_INDEXES_CALCULATE] || userAccess[HISTORICAL_DATA] || userAccess[UPDATE_DB]) && divider()}
        {userAccess[COMPOSITE_INDEXES_CALCULATE] && (
          sideNavButtonAction(CompositeIndexIcon, 'Composite Index', doOpenCompositeIndex, isCabinet || isSharedProject)
        )}
        {userAccess[HISTORICAL_DATA] && (
          sideNavButtonAction(HistoricalDataIcon, 'Historical Data', doOpenHistoricalData, isCabinet)
        )}
        {userAccess[UPDATE_DB] && (
          sideNavButtonAction(UpdateDBIcon, 'Update DB', doOpenUpdateDb, !isMyProject || (offlineMode && changesCount))
        )}
        {userAccess[ANALYTICAL_REPORTS] && (
          sideNavButtonAction(MSExcelIcon, 'Reports', doOpenReports, isCabinet)
        )}
        {divider()}
        {userAccess[NOTIFICATIONS] && (
          newNotifications > 0
            ? <Badge
                className={isEditNeighborsOpened ? 'scapex-no-pointer-events' : ''}
                value={newNotifications}
                title={`${newNotifications} new notifications`}
                shift={8}
              >
                {sideNavButtonLink(CalculateDraftIcon, KEYS.NOTIFICATIONS)}
              </Badge>
            : sideNavButtonLink(CalculateDraftIcon, KEYS.NOTIFICATIONS)
        )}
        {sideNavButtonLink(pendingTasks ? 'Spinner' : CheckmarkIcon, KEYS.TASK_LOG)}
        {sideNavButtonAction(CabinetIcon, isCabinet ? 'Logout' : 'Cabinet', doOpenCabinet)}
      </div>
      {!isCabinet && isMyProject && userAccess[EDIT_ELEMENTS] && autosaveMode()}
      {metricsPanel()}
      {compositeIndexOpen && (
        <CompositeIndex onClose={closeCompositeIndex} />
      )}
      {historicalDataOpen && (
        <HistoricalData onClose={closeHistoricalData} />
      )}
      {changePasswordOpen && (
        <ChangePassword />
      )}
      {updateDbOpen && (
        <SelectOperationUpdateDB onClose={closeUpdateDb} onOpenSynchronize={doOpenSynchronize} />
      )}
      {reportsOpen && (
        <SelectOperationReports onClose={closeReports} />
      )}
      {cabinetOpen && (
        <SelectOperationCabinet onClose={closeCabinet} isCabinet={isCabinet} />
      )}
      {synchronizeOpen && (
        <UpdateDB onClose={closeSynchronize} />
      )}
    </Stack>
  )
}

export default Drawer
