import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useBoolean } from '@fluentui/react-hooks'
import {
  DetailsList, DetailsListLayoutMode, DetailsRow, getTheme, IconButton, DetailsHeader, TooltipHost, Label,
} from '@fluentui/react'
import { SelectionMode } from '@fluentui/utilities'
import { updateTableStyles } from '../../UpdateDB/common/styles'
import { columnSettingRate, columnsRate } from '../constants'
import { EMPTY } from '../../../constants'
import { selectSortedRates } from '../../../features/compositeIndex/compositeIndexSlice'
import { getIndexById } from '../../../features/network/filtration'

const theme = getTheme()

let revert = false
let prevSite = null

const styleRate = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const onRenderRow = (props) => {
  const customStyles = {}
  if (props) {
    const site = props.item?.[0]
    if (prevSite !== site) {
      customStyles.root = { fontWeight: 600 }
      prevSite = site
      revert = !revert
    }
    if (revert) {
      customStyles.root = { backgroundColor: theme.palette.themeLighterAlt, ...customStyles.root }
    }
    return <DetailsRow {...props} styles={customStyles} />
  }
  return null
}

const TableRates = ({ elementKey, dataSources }) => {
  const sortedRates = useSelector(selectSortedRates)

  // Прапорець виведення даних за показниками KPI
  const [ isExtended, { toggle: setExtended } ] = useBoolean(false)
  const tableStyles = useMemo(() => {
    const result = {
      ...updateTableStyles,
      contentWrapper: {
        ...updateTableStyles.contentWrapper,
        overflowX: 'hidden',
      },
    }
    return result
  }, [])

  const { columns, rates } = useMemo(() => {
    const { resource, fields, kpi } = dataSources ?? {}
    if (!Array.isArray(columnsRate[elementKey]) || !Array.isArray(kpi)) {
      return { columns: [], rates: [] }
    }
    const columns = [ ...(columnsRate[elementKey]) ]
    const columnsIndex = columns.map((column) => column.id === 'RATE' ? null : getIndexById(fields, column.id))
    const startIndex = columns.length
    if (isExtended) {
      kpi.forEach((item, index) => {
        columnsIndex.push(getIndexById(fields, item.id))
        columns.push({
          key: item.id,
          name: item.label,
          fieldName: `${startIndex + index}`,
          id: item.id,
          ...columnSettingRate,
        })
      })
    }
    if (!Array.isArray(sortedRates) || !Array.isArray(resource)) {
      return { columns, rates: [] }
    }
    const rates = sortedRates.map((rate) => {
      const rowIdx = rate.at(-2) // Передостанній -- індекс рядка у вихідних даних
      return columnsIndex.map((index) => index === null
        ? rate.at(-1).toFixed(4) // Останній -- rates
        : resource[rowIdx]?.[index])
    })
    return { columns, rates }
  }, [ sortedRates, dataSources, elementKey, isExtended ])

  const onRenderItemHeader = useCallback((props) => {
    const { children, column, hostClassName } = props
    const isRate = column.key === 'rate'
    return (
      <TooltipHost content={column.name} >
        <span className={hostClassName} style={isRate ? styleRate : null}>
          {children}
          {isRate && (
            <IconButton
              iconProps={{ iconName: isExtended ? 'ChevronLeftSmall' : 'ChevronRightSmall' }}
              onClick={setExtended}
              style={{ marginRight: '8px' }}
            />
          )}
        </span>
      </TooltipHost>
    )
  }, [ isExtended, setExtended ])

  const onRenderDetailsHeader = useCallback((props) => (
    <DetailsHeader {...props} onRenderColumnHeaderTooltip={onRenderItemHeader}/>
  ), [ onRenderItemHeader ])

  return !rates || rates.length === 0
    ? (
        <Label styles={{ root: { textAlign: 'center', marginTop: '64px' } }}>
          No data
        </Label>
      )
    : (
        <DetailsList
          items={rates ?? EMPTY.ARRAY}
          compact={true}
          columns={columns}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selectionMode={SelectionMode.none}
          onRenderRow={onRenderRow}
          onRenderDetailsHeader={onRenderDetailsHeader}
          styles={tableStyles}
        />
      )
}

export default TableRates
