import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'react-tooltip-lite'
import toast from 'react-hot-toast'
import Handsontable from 'handsontable'
import { Label } from '@fluentui/react'
import { addClassWhenNeeded } from '../../TaskLog/components/utils'

import './DetailsRenderer.css'
import { DefaultButton } from '../../common/Button'

export const DetailsRenderer = (props) => {
  const value = props.value

  const onCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(value)
    toast.success('Copied to clipboard', { position: 'top-right' })
  }, [ value ])

  addClassWhenNeeded(props)

  Handsontable.dom.addClass(props.TD, 'read-only-cell')

  if (!value) {
    return null
  }

  const nextLineIdx = value.indexOf('\n')
  const previewSize = nextLineIdx > 0 ? Math.min(100, nextLineIdx) : 100
  const preview = value.length > previewSize ? value.substring(0, previewSize) + '...' : value
  const content = (
    <div className='tooltip-content'>
      <div className='tooltip-content-header'>
        <Label>{'Execute details'}</Label>
        <DefaultButton onClick={onCopyToClipboard} text="Copy to clipboard" />
      </div>
      <div className='tooltip-content-text'>
        {value.split('\n').map((line, index) => (<p key={index}>{line}</p>))}
      </div>
    </div>
  )
  const arrowContent = (
    <div className='scapex-tooltip-arrow'>
      <div className='scapex-tooltip-arrow-inner' />
      <div className='scapex-tooltip-arrow-icon' />
    </div>
  )
  return (
    <div className='capex-execute-details'>
      <span className='capex-execute-details-preview'>{preview}</span>
      <Tooltip direction='down' arrowContent={arrowContent} className='capex-details-tooltip' content={content}>
        {'ⓘ'}
      </Tooltip>
    </div>
  )
}

DetailsRenderer.PropsTypes = {
  TD: PropTypes.object,
  value: PropTypes.any,
  cellProperties: PropTypes.object,
}
