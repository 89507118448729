import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'

const initialState = {
  name: 'MacroIndicators',
  loading: false,
  saving: false,
  data: {},
  updatedYears: null,
  error: false,
}

const loading = (state) => {
  state.loading = true
}

const saving = (state) => {
  state.saving = true
}

const doLoadMacroIndicators = createAsyncThunk(
  'macroIndicators/getMacroIndicators',
  api.macroIndicators.getMacroIndicators,
)

const doSaveMacroIndicators = createAsyncThunk(
  'macroIndicators/saveMacroIndicators',
  api.macroIndicators.saveMacroIndicators,
)

export const macroIndicatorsSlice = createSlice({
  name: 'macroIndicators',
  initialState,
  reducers: {
    setUpdatedYears: (state, action) => {
      state.updatedYears = action.payload
    },
  },
  extraReducers: (builder) => builder
    .addCase(doLoadMacroIndicators.pending, loading)
    .addCase(doLoadMacroIndicators.fulfilled, (state, action) => {
      state.loading = false
      state.ready = true
      state.data = action.payload
    })
    .addCase(doSaveMacroIndicators.pending, saving)
    .addCase(doSaveMacroIndicators.fulfilled, (state) => {
      state.saving = false
      state.error = false
      state.ready = true
    })
    .addCase(doSaveMacroIndicators.rejected, (state) => {
      state.saving = false
      state.error = true
      state.ready = true
    }),
})

const {
  setUpdatedYears,
} = macroIndicatorsSlice.actions

export const selectMacroIndicators = (state) => state.macroIndicators.data

export const selectMacroIndicatorYears = (state) => {
  // return state.macroIndicators.list.map((ind) => ind.year)
  const data = state.macroIndicators.data
  return Object.keys(data)
}

export const selectLoading = (state) => state.macroIndicators.loading

export const selectSaving = (state) => state.macroIndicators.saving

export const selectError = (state) => state.macroIndicators.error

export const selectUpdatedYears = (state) => state.macroIndicators.updatedYears

export const loadMacroIndicators = () => (dispatch, getState) => {
  const currentState = getState()
  if (!selectLoading(currentState)) {
    return Promise.all([
      dispatch(doLoadMacroIndicators()),
    ])
  }
}

export const saveMacroIndicators = (data) => async (dispatch, getState) => {
  const currentState = getState()
  if (selectSaving(currentState)) {
    return false
  }
  await dispatch(doSaveMacroIndicators(data))
  const error = selectError(getState())
  if (!error) {
    dispatch(setUpdatedYears(null))
    return true
  } else {
    return false
  }
}

export const addUpdatedYears = (data) => (dispatch, getState) => {
  dispatch(setUpdatedYears(data))
}

export default macroIndicatorsSlice.reducer
