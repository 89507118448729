import React from 'react'
import PropTypes from 'prop-types'

import sprite from './sprite.svg'

export { default as Logo } from './logo.svg'
export { default as LogoWithText } from './logo-text.svg'

export const IconSvg = ({ name = 'CLOSE_ICON', size = 20 }) => (
  <svg key={name} width={size} height={size} fill="currentColor">
    <use href={`${sprite}#${name}`} />
  </svg>
)

IconSvg.propTypes = {
  name: PropTypes.string.isRequired,
}

export const iconSvgLine = ({ name = 'CLOSE_ICON', size = 20, className }) => (
  `<svg width="${size}" height="${size}" fill="currentColor"${className ? ` class="${className}"` : ''}>\
<use xlink:href="${sprite}#${name}"></use>\
</svg>`
)
