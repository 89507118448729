export const KEYS = {
  ANALYST: 'Analyst',
  GMT: 'GMT',
  FPA: 'FPA',
  ADMIN: 'Admin',
  RPL: 'RPL',
}

export const ROLE_OPTIONS = [
  { key: KEYS.ANALYST, text: 'Analyst' },
  // { key: KEYS.RPL, text: 'Radio Planner' },
  { key: KEYS.GMT, text: 'Geo Marketing Team' },
  { key: KEYS.FPA, text: 'Financial Planning Assistant' },
  { key: KEYS.ADMIN, text: 'Administrator' },
]

// Server-side role names
export const ROLES = {
  ROLE_USER: 'ROLE_USER',
  ROLE_GMT: 'ROLE_GMT',
  ROLE_FPA: 'ROLE_FPA',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_RPL: 'ROLE_RPL',
}

export const TYPES = {
  LOCAL: 'LOCAL',
  LDAP: 'LDAP',
  SYSTEM: 'SYSTEM',
}

export const generalizedRoles = {
  [KEYS.ANALYST]: [],
  [KEYS.RPL]: [ ROLES.ROLE_USER, ROLES.ROLE_RPL ],
  [KEYS.GMT]: [ ROLES.ROLE_USER, ROLES.ROLE_GMT ],
  [KEYS.FPA]: [ ROLES.ROLE_USER, ROLES.ROLE_RPL, ROLES.ROLE_GMT, ROLES.ROLE_FPA ],
  [KEYS.ADMIN]: [ ROLES.ROLE_USER, ROLES.ROLE_RPL, ROLES.ROLE_GMT, ROLES.ROLE_FPA, ROLES.ROLE_ADMIN ],
}

export const getGeneralizedRole = (userRoles = []) => Object.keys(generalizedRoles).find((key) => {
  const templateRoles = generalizedRoles[key]
  if (templateRoles.length !== userRoles.length) {
    return false
  }
  const included = templateRoles.filter((role) => userRoles.includes(role))
  return included.length === templateRoles.length
})
