export const BC_SELECTED_FIELD = 'table.bc.selected'
export const BC_ID_FIELD = 'table.bc.id'
export const BC_INSTANCE_ID_FIELD = 'table.bci.id'
export const BC_EX_STATUS_FIELD = 'table.bc.indicator'
export const BC_NAME_FIELD = 'table.bc.name'
export const BC_STATUS_ID_FIELD = 'table.bc.status.id'
export const BC_STATUS_NAME_FIELD = 'table.bc.status.name'
export const BC_STATUS_COMMENT_FIELD = 'table.bc.status.comment'
export const BC_GROUP_ID_FIELD = 'table.bcg.id'
export const BC_GROUP_NAME_FIELD = 'table.bcg.name'
export const BC_GROUP_OWNER_FIELD = 'table.bcg.owner'
export const BC_SITE_PROJECT_ID_FIELD = 'table.bc.network.site.projectId'
export const BC_SITE_ID_FIELD = 'table.bc.network.site.id'
export const BC_SITE_FIELD = 'table.bc.network.site.name'
export const BC_SITE_TYPE_FIELD = 'table.bc.network.site.type'
export const BC_COMMENTS_FIELD = 'table.bc.comment'
export const BC_CALCULATED_ID_FIELD = 'table.bc.calculated'

export const BC_CALC_METHOD_FIELD = 'table.bc.calcMethod'
export const BC_CASE_DURATION_FIELD = 'table.bc.case.duration'
export const BC_CASE_TYPE_FIELD = 'table.bc.case.type'
export const BC_DATE_CAPEX_FIELD = 'table.bc.date.capex'
export const BC_DATE_REVENUE_FIELD = 'table.bc.date.revenue'
export const BC_CAPEX_CONSTRUCTION_FIELD = 'table.bc.capex.construction'
export const BC_CAPEX_RAN_FIELD = 'table.bc.capex.ran'
export const BC_CAPEX_OTHER_FIELD = 'table.bc.capex.other'
export const BC_EDRPOU_FIELD = 'table.bc.edrpou'
export const BC_ASSOCIATION_INCOME_FIELD = 'table.bc.associationIncome'
export const BC_OPEX_RENT_FIELD = 'table.bc.opex.rent'
export const BC_OPEX_UTILITIES_FIELD = 'table.bc.opex.utilities'
export const BC_OPEX_MAINTENANCE_FIELD = 'table.bc.opex.maintenance'
export const BC_OTHER_OPEX_FIELD = 'table.bc.opex.other'
export const BC_ADD_REVENUE_FIELD = 'table.bc.revenue.additional'
export const BC_REVENUE_TREND_FIELD = 'table.bc.revenue.trend'
export const BC_TOWERCO_FIELD = 'table.bc.towerco.enabled'
export const BC_TOWER_CO_RENT_FIELD = 'table.bc.towerco.rent'
export const BC_DATA_CANN_COEF_FIELD = 'table.bc.coef.cannib.data'
export const BC_VOICE_CANN_COEF_TRAF_FIELD = 'table.bc.coef.cannib.voice.traf'
export const BC_VOICE_CANN_COEF_REF_FIELD = 'table.bc.coef.cannib.voice.rev'

export const BC_PROJECT_NAME = 'table.bc.name'
export const BC_PROJECT_ADDRESS = 'table.bc.project.address'
export const BC_PROJECT_LEAD = 'table.bcg.owner'
export const BC_PROJECT_SPONSOR = 'table.bc.project.sponsor'
export const BC_FPA_REPRESENTATIVE = 'table.bc.approvedBy'

export const BC_VALUATION_KPI_NPV = 'table.bc.result.npv'
export const BC_VALUATION_KPI_ROI = 'table.bc.result.roi'
export const BC_VALUATION_KPI_IRR = 'table.bc.result.irr'
export const BC_VALUATION_KPI_PPY = 'table.bc.result.paybackPeriodYears'
export const BC_VALUATION_KPI_WAC = 'table.bc.result.wac'

export const BC_RBSS_FIELD = 'table.bc.network.rbs.names'
export const BC_SECTORS_FIELD = 'table.bc.network.sectors.names'
export const BC_NEIGHBORS_FIELD = 'table.bc.network.neighbors'

export const BC_CORRESPONDENCE_FIELD = 'table.bc.correspondence'
export const BC_CORRESPONDENCE_DATE_FIELD = 'table.bc.correspondenceDate'

export const BC_SECTOR_IDS_FIELD = 'table.bc.network.sectors.ids'

export const BC_APPROVED_BY = 'table.bc.approvedBy'
export const BC_APPROVED_AT = 'table.bc.approvedAt'

export const EXISTING_STATUS_ID = {
  NEW: 'NEW',
  EXISTING: 'EXISTING',
  SELECTED: 'SELECTED',
}

export const CALC_STATUS_MANUAL_REVENUE = 'MANUAL_REVENUE'
export const CALC_STATUS_MACHINE_LEARNING = 'MACHINE_LEARNING'

export const CALC_METHOD_DRIVER_BASED = 'DRIVER_BASED'
export const CALC_METHOD_MACHINE_LEARNING = 'MACHINE_LEARNING'
export const CALC_METHOD_MANUAL_NEIGHBORS = 'MANUAL_NEIGHBORS'
export const CALC_METHOD_NEW_SECTORS = 'NEW_SECTORS'
export const CALC_METHOD_AUTO = 'AUTO'

export const CASE_TYPE_B2B = 'B2B'
export const CASE_TYPE_B2C = 'B2C'

export const CALC_METHOD_DRIVER_BASED_CLASSNAME = 'scapex-business-case-driver-based'
export const CALC_METHOD_MACHINE_LEARNING_CLASSNAME = 'scapex-business-case-machine-learning'
export const CALC_METHOD_MANUAL_NEIGHBORS_CLASSNAME = 'scapex-business-case-manual-neighbors'
export const CALC_METHOD_NEW_SECTORS_CLASSNAME = 'scapex-business-case-new-sectors'

export const getCalcMethodClassName = (calcMethod) => {
  switch (calcMethod) {
    case CALC_METHOD_DRIVER_BASED:
      return CALC_METHOD_DRIVER_BASED_CLASSNAME
    case CALC_METHOD_MACHINE_LEARNING:
      return CALC_METHOD_MACHINE_LEARNING_CLASSNAME
    case CALC_METHOD_MANUAL_NEIGHBORS:
      return CALC_METHOD_MANUAL_NEIGHBORS_CLASSNAME
    case CALC_METHOD_NEW_SECTORS:
      return CALC_METHOD_NEW_SECTORS_CLASSNAME
    default:
      return undefined
  }
}

export const BC_STATUS_ID = {
  NEW: 'NEW',
  CALCULATE_DMP_DRAFT: 'CALCULATE_DMP_DRAFT',
  CALCULATE_DMP_DRAFT_SUCCESS: 'CALCULATE_DMP_DRAFT_SUCCESS',
  CALCULATE_DMP_DRAFT_FAILED: 'CALCULATE_DMP_DRAFT_FAILED',
  CALCULATE_SNC_DRAFT: 'CALCULATE_SNC_DRAFT',
  CALCULATE_SNC_DRAFT_SUCCESS: 'CALCULATE_SNC_DRAFT_SUCCESS',
  CALCULATE_SNC_DRAFT_FAILED: 'CALCULATE_SNC_DRAFT_FAILED',
  CALCULATE_DMP_FOR_APPROVE_ATOLL_SYNC: 'CALCULATE_DMP_FOR_APPROVE_ATOLL_SYNC',
  CALCULATE_DMP_FOR_APPROVE: 'CALCULATE_DMP_FOR_APPROVE',
  CALCULATE_DMP_FOR_APPROVE_SUCCESS: 'CALCULATE_DMP_FOR_APPROVE_SUCCESS',
  CALCULATE_DMP_FOR_APPROVE_FAILED: 'CALCULATE_DMP_FOR_APPROVE_FAILED',
  CALCULATE_SNC_FOR_APPROVE: 'CALCULATE_SNC_FOR_APPROVE',
  CALCULATE_SNC_FOR_APPROVE_SUCCESS: 'CALCULATE_SNC_FOR_APPROVE_SUCCESS',
  CALCULATE_SNC_FAILED: 'CALCULATE_SNC_FAILED',
  APPROVE_DRAFT: 'APPROVE_DRAFT',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  REMOVED: 'REMOVED',
}

export const BC_STATUS_NEW_NAME = 'New'

export const REQUIRED_STATUS_FOR_CALCULATE_DRAFT = [
  BC_STATUS_ID.CALCULATE_SNC_DRAFT_SUCCESS,
  BC_STATUS_ID.NEW,
]

export const REQUIRED_STATUS_FOR_RECALCULATE_DRAFT = [
  // BC_STATUS_ID.CALCULATE_SNC_DRAFT_SUCCESS,
  BC_STATUS_ID.NEW,
]

export const REQUIRED_STATUS_FOR_CALCULATE = [
  BC_STATUS_ID.CALCULATE_SNC_DRAFT_SUCCESS,
]

export const REQUIRED_STATUS_FOR_CANCEL_CALCULATION = [
  BC_STATUS_ID.CALCULATE_DMP_DRAFT,
  BC_STATUS_ID.CALCULATE_SNC_DRAFT,
  BC_STATUS_ID.CALCULATE_DMP_FOR_APPROVE,
  BC_STATUS_ID.CALCULATE_SNC_FOR_APPROVE,
]

export const REQUIRED_STATUS_FOR_APPROVE_DRAFT = [
  BC_STATUS_ID.CALCULATE_SNC_FOR_APPROVE_SUCCESS,
]

export const REQUIRED_STATUS_FOR_APPROVE = [
  BC_STATUS_ID.APPROVE_DRAFT,
]

export const REQUIRED_STATUS_FOR_REJECT = [
  BC_STATUS_ID.APPROVE_DRAFT,
  BC_STATUS_ID.APPROVED,
]

export const REQUIRED_STATUS_FOR_DISABLE_DELETE = [
  BC_STATUS_ID.CALCULATE_DMP_DRAFT,
  BC_STATUS_ID.CALCULATE_DMP_FOR_APPROVE,
  BC_STATUS_ID.CALCULATE_SNC_DRAFT,
  BC_STATUS_ID.CALCULATE_SNC_FOR_APPROVE,
  BC_STATUS_ID.APPROVED,
]

export const REQUIRED_STATUS_FOR_DISABLE_ANY_CHANGES = [
  BC_STATUS_ID.APPROVED,
  BC_STATUS_ID.APPROVE_DRAFT,
  BC_STATUS_ID.CALCULATE_DMP_FOR_APPROVE_ATOLL_SYNC,
  BC_STATUS_ID.CALCULATE_DMP_DRAFT,
  BC_STATUS_ID.CALCULATE_DMP_DRAFT_SUCCESS,
  BC_STATUS_ID.CALCULATE_SNC_DRAFT,
  BC_STATUS_ID.CALCULATE_DMP_FOR_APPROVE,
  BC_STATUS_ID.CALCULATE_DMP_FOR_APPROVE_SUCCESS,
  BC_STATUS_ID.CALCULATE_SNC_FOR_APPROVE,
]

export const CHANGE_STATUS = [
  BC_STATUS_ID.CALCULATE_SNC_DRAFT_SUCCESS,
  BC_STATUS_ID.CALCULATE_SNC_DRAFT_FAILED,
  BC_STATUS_ID.CALCULATE_DMP_DRAFT_FAILED,
  BC_STATUS_ID.CALCULATE_DMP_FOR_APPROVE_FAILED,
  BC_STATUS_ID.CALCULATE_SNC_FOR_APPROVE_SUCCESS,
  BC_STATUS_ID.REJECTED,
]

export const CHANGE_STATUS_ON_IMPORT = [
  BC_STATUS_ID.CALCULATE_SNC_DRAFT_SUCCESS,
  BC_STATUS_ID.CALCULATE_SNC_FOR_APPROVE_SUCCESS,
]

export const FIELDS_TO_CHANGE_STATUS_ON_IMPORT = [
  BC_CALC_METHOD_FIELD,
  BC_CAPEX_CONSTRUCTION_FIELD,
  BC_CAPEX_RAN_FIELD,
  BC_CAPEX_OTHER_FIELD,
  BC_OPEX_RENT_FIELD,
  BC_OPEX_UTILITIES_FIELD,
  BC_OPEX_MAINTENANCE_FIELD,
  BC_VOICE_CANN_COEF_REF_FIELD,
  BC_VOICE_CANN_COEF_TRAF_FIELD,
  BC_DATA_CANN_COEF_FIELD,
]

export const SORTED_TECHNOLOGIES = {
  '2G': [ 'GSM900', 'GSM1800' ],
  '3G': [ 'UMTS2100' ],
  '4G': [ 'LTE900', 'LTE1800', 'LTE2100', 'LTE2300', 'LTE2600' ],
}

export const BC_IMPORT_MAX_SECTORS = 64
