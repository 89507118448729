import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { EVENT_TYPE, saveEventLog, wrapByEventLog } from '../eventLog/eventLogSlice'
import api from '../../api'
import { ACCESS_TO_FEATURE } from '../../constants/access'

const initialState = {
  empty: true,
  loading: false,
  login: null,
  user: null,
  error: null,
  changePasswordOpen: false,
}

const processLoginAction = async (promise, emptyOnError = false) => {
  try {
    const user = await promise
    return {
      empty: false,
      user,
      error: null,
    }
  } catch (err) {
    console.error(err)
    return {
      empty: emptyOnError,
      user: null,
      error: err.message,
    }
  }
}

export const checkAuthenticated = createAsyncThunk(
  'login/checkAuthenticated',
  (payload, thunkAPI) => thunkAPI.dispatch(wrapByEventLog({
    type: EVENT_TYPE.login,
    action: () => processLoginAction(api.login.checkAuthenticated(), true),
  })),
)

export const logout = createAsyncThunk(
  'login/logout',
  (payload, thunkAPI) => {
    thunkAPI.dispatch(saveEventLog(EVENT_TYPE.logout))
    return processLoginAction(api.login.logout())
  },
)

const changePassword = createAsyncThunk(
  'login/changePassword',
  api.login.changePassword,
)

const processUserAction = (builder, thunk) => {
  builder
    .addCase(thunk.pending, (state) => {
      state.loading = true
      // state.user = null
      state.error = null
    })
    .addCase(thunk.fulfilled, (state, action) => {
      state.loading = false
      state.empty = action.payload.empty
      state.user = action.payload.user
      state.error = action.payload.error
    })
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setChangePasswordOpen: (state, action) => {
      state.changePasswordOpen = action.payload
    },
  },
  extraReducers: (builder) => {
    processUserAction(builder, checkAuthenticated)
    processUserAction(builder, logout)
  },
})

export const { setChangePasswordOpen } = loginSlice.actions

export const selectEmpty = (state) => state.login.empty
export const selectLoading = (state) => state.login.loading
export const selectUser = (state) => state.login.user
export const selectUserRoles = (state) => state.login.user?.roles
export const selectChangePasswordOpen = (state) => state.login.changePasswordOpen

export const selectUserAccess = (state) => Object.fromEntries(
  Object.keys(ACCESS_TO_FEATURE)
    .map((feature) => [
      feature,
      ACCESS_TO_FEATURE[feature] === null || state.login.user?.roles?.includes(ACCESS_TO_FEATURE[feature]),
    ]))

export const selectUserAccessMemo = createSelector(
  selectUserRoles,
  (roles) => Object.fromEntries(
    Object.keys(ACCESS_TO_FEATURE)
      .map((feature) => [
        feature,
        ACCESS_TO_FEATURE[feature] === null || roles?.includes(ACCESS_TO_FEATURE[feature]),
      ])),
)

export const selectError = (state) => state.login.error

export const doChangePassword = (oldPassword, newPassword) => (dispatch, getState) => {
  const state = getState()
  const userId = selectUser(state).id
  return dispatch(wrapByEventLog({
    type: EVENT_TYPE.changePassword,
    action: () => dispatch(changePassword({ userId, oldPassword, newPassword })),
  }))
}

export default loginSlice.reducer
