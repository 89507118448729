import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { getListBCData, redrawBusinessCases, redrawCreateBC, selectBCFull } from '../bc/bcSlice'
import { setTaskCompleted } from '../taskLog/taskLogSlice'
import { saveEventLog, EVENT_TYPE, STATUS_ERROR, STATUS_SUCCESS } from '../eventLog/eventLogSlice'
import { activeProjectId, setActiveProjectRemoved } from '../projects/projectsSlice'
import { findIndex } from '../network/indexing'
import { REPORT_BC_CALCULATION_DETAILS } from '../../constants/reports'
import {
  CARRY_OVER_REMAINING_NOTIFICATIONS,
  fields,
  MAX_NOTIFICATIONS_DISPLAYED,
  NOTIFICATIONS_BC_ID_FIELD, NOTIFICATIONS_BC_NAME_FIELD,
  NOTIFICATIONS_ERROR_FIELD,
  NOTIFICATIONS_ID_FIELD, NOTIFICATIONS_PROJECT_ID_FIELD, NOTIFICATIONS_PROJECT_NAME_FIELD,
  NOTIFICATIONS_REPORT_ID_FIELD,
  NOTIFICATIONS_STATUS_FIELD,
  NOTIFICATIONS_TYPE_FIELD,
  STATUS_UNREAD, TYPE_BC_CALCULATE_SNC_COMPLETED, TYPE_PROJECT_DELETED,
  TYPES_BC_TO_UPDATE, TYPES_REPORT_TO_UPDATE, USER_MESSAGES,
} from './constants'

const initialState = {
  loading: false,
  list: null,
  fields,
  lastId: null,
}

let loading = false

const doCreateNotifications = createAsyncThunk(
  'notifications/createNotifications',
  api.notifications.createNotifications,
)

const doUpdateNotifications = createAsyncThunk(
  'notifications/updateNotifications',
  api.notifications.updateNotifications,
)

const doRemoveNotifications = createAsyncThunk(
  'notifications/removeNotifications',
  api.notifications.removeNotifications,
)

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload
    },
    setLastId: (state, action) => {
      state.lastId = action.payload
    },
  },
})

const { setList, setLastId } = notificationsSlice.actions

export const selectNotifications = (state) => state.notifications.list
export const selectNotificationsFields = (state) => state.notifications.fields
export const selectNewNotifications = (state) => {
  const statusColIdx = findIndex(fields, NOTIFICATIONS_STATUS_FIELD)
  return selectNotifications(state)
    ?.filter((notification) => notification?.[statusColIdx] === STATUS_UNREAD)?.length || 0
}
export const selectNotificationsLastId = (state) => state.notifications.lastId

const postProcessNotifications = async (dispatch, getState) => {
  const state = getState()
  let lastId = selectNotificationsLastId(state)

  const storageLastId = Number(localStorage.getItem('lastNotificationId') || 0)
  if (lastId === null) {
    dispatch(setLastId(storageLastId))
    lastId = storageLastId
  }
  const notifications = selectNotifications(state)
  const currentProjectId = activeProjectId(state)

  const bcsToUpdate = []
  let maxId = lastId

  const idColIdx = findIndex(fields, NOTIFICATIONS_ID_FIELD)
  const typeColIdx = findIndex(fields, NOTIFICATIONS_TYPE_FIELD)
  const bcIdColIdx = findIndex(fields, NOTIFICATIONS_BC_ID_FIELD)
  const reportIdColIdx = findIndex(fields, NOTIFICATIONS_REPORT_ID_FIELD)
  const errorColIdx = findIndex(fields, NOTIFICATIONS_ERROR_FIELD)
  const projectIdColIdx = findIndex(fields, NOTIFICATIONS_PROJECT_ID_FIELD)
  const projectNameColIdx = findIndex(fields, NOTIFICATIONS_PROJECT_NAME_FIELD)
  const bcNameColIdx = findIndex(fields, NOTIFICATIONS_BC_NAME_FIELD)

  const messagesToShow = {
    messages: {},
    notificationsCount: 0,
  }

  notifications?.slice().reverse().every((notification) => {
    const id = notification[idColIdx]
    if (id > lastId) {
      if (messagesToShow.notificationsCount <= MAX_NOTIFICATIONS_DISPLAYED) {
        const type = notification[typeColIdx]
        const bscId = notification[bcIdColIdx]
        const reportId = notification[reportIdColIdx]
        const error = notification[errorColIdx]
        const projectId = notification[projectIdColIdx]
        const projectName = notification[projectNameColIdx]
        const bcName = notification[bcNameColIdx]
        if (TYPES_BC_TO_UPDATE.includes(type) && bscId && !bcsToUpdate.includes(bscId)) {
          bcsToUpdate.push(bscId)
        }
        if (TYPES_REPORT_TO_UPDATE.includes(type) && reportId) {
          dispatch(setTaskCompleted(reportId))
          dispatch(saveEventLog(
            EVENT_TYPE.reportCompleted,
            ` [Job ID ${reportId}]${error ? `\nERROR: ${error}` : ''}`,
            error ? STATUS_ERROR : STATUS_SUCCESS,
          ))
        }
        if (type === TYPE_PROJECT_DELETED) {
          if (projectId === currentProjectId) {
            dispatch(setActiveProjectRemoved(true))
          }
        }
        const message = USER_MESSAGES[type]
        let skipMessage = false
        if (message) {
          const projectKey = error ? projectName || null : null // do not group by project success messages
          const errorKey = error || null // use null for success messages
          if (!messagesToShow.messages[type]?.[projectKey]?.[errorKey]) {
            // Need to create new message type
            if (messagesToShow.notificationsCount >= MAX_NOTIFICATIONS_DISPLAYED) {
              if (CARRY_OVER_REMAINING_NOTIFICATIONS) {
                return false
              } else {
                skipMessage = true
              }
            }
            if (!skipMessage) {
              if (!messagesToShow.messages[type]) {
                messagesToShow.messages[type] = {}
              }
              if (!messagesToShow.messages[type][projectKey]) {
                messagesToShow.messages[type][projectKey] = {}
              }
              if (!messagesToShow.messages[type][projectKey][errorKey]) {
                messagesToShow.messages[type][projectKey][errorKey] = []
              }
              messagesToShow.notificationsCount += 1
            }
          }

          if (messagesToShow.messages[type]?.[projectKey]?.[errorKey]) {
            messagesToShow.messages[type][projectKey][errorKey].push(bcName)
          }
        }
      }
      if (id > maxId) {
        maxId = id
      }
    }
    return true
  })

  if (messagesToShow.notificationsCount > 0) {
    Object.keys(messagesToShow.messages).forEach((type) => {
      Object.keys(messagesToShow.messages[type]).forEach((projectKey) => {
        Object.keys(messagesToShow.messages[type][projectKey]).forEach((errorKey) => {
          const bcNames = messagesToShow.messages[type][projectKey][errorKey]
          if (bcNames.length > 0) {
            let message = errorKey !== 'null' ? `${USER_MESSAGES[type]}: ${errorKey}` : `${USER_MESSAGES[type]}`
            if (bcNames.length > 1) {
              message = `(x${bcNames.length}) ${message}`
            }
            if (projectKey !== 'null') {
              message += `\nProject: ${projectKey}`
            }
            if (bcNames.length === 1 && bcNames[0]) {
              message += `\nBC: ${bcNames[0]}`
            }
            window.dispatchEvent(new ErrorEvent(errorKey !== 'null' ? 'resterror' : 'infomessage', { message }))
          }
        })
      })
    })
  }

  const bcList = selectBCFull(state)
  if (bcList.getList().length && bcsToUpdate.length) {
    await dispatch(getListBCData(bcsToUpdate))
    dispatch(redrawCreateBC())
    dispatch(redrawBusinessCases())
  }
  if (maxId > lastId) {
    dispatch(setLastId(maxId))
  }
  if (maxId > storageLastId) {
    localStorage.setItem('lastNotificationId', maxId)
  }
}

export const loadNotifications = async (dispatch, user, notifications, activeProjectId) => {
  if (!loading && user?.id && !global.drawMode && !global.menuMode) {
    loading = true
    try {
      const result = await api.notifications.getNotifications()
      const newList = result?.map((event) => {
        const { id, status, properties, createdAt, type, contentSize } = event
        const {
          sourceUserName, projectId, projectDescription, bsciId, bscName, bscgId, id: reportId, type: reportType, error,
        } = properties
        const isBCReportCompleted = type === TYPE_BC_CALCULATE_SNC_COMPLETED
        return [
          id, status, createdAt, type, sourceUserName,
          projectId, projectDescription,
          projectId
            ? {
                id: `${id}`,
                href: `/${projectId}/map`,
                label: `${projectDescription}`,
                newTab: activeProjectId !== projectId,
              }
            : null,
          bscgId, bsciId, bscName,
          projectId && bsciId
            ? {
                id: `bc_${id}`,
                href: `/${projectId}/business-cases?bc=${bsciId}`,
                label: `${bscName}`,
                newTab: activeProjectId !== projectId,
              }
            : null,
          isBCReportCompleted ? bsciId : reportId,
          isBCReportCompleted ? REPORT_BC_CALCULATION_DETAILS : reportType,
          error,
          Number(contentSize) || null,
        ]
      })
      if (newList && JSON.stringify(newList) !== JSON.stringify(notifications)) {
        dispatch(setList(newList))
      }
    } finally {
      loading = false
    }
    return dispatch(postProcessNotifications)
  }
}

export const createNotifications = (data) => (dispatch) => {
  return dispatch(doCreateNotifications(data))
}

export const updateNotifications = (status, ids) => (dispatch) => {
  if (!loading) {
    return dispatch(doUpdateNotifications({ status, ids }))
  }
}

export const removeNotifications = (ids) => (dispatch) => {
  if (!loading) {
    return dispatch(doRemoveNotifications(ids))
  }
}

export default notificationsSlice.reducer
