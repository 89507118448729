import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@fluentui/react'
import { selectSettings, saveSettings } from '../../../../features/settings/settingsSlice'
import { elementsRedraw } from '../../../../features/network/networkSlice'
import { SECTORS } from '../../../../constants/network'
import IconSector from './IconSector'

const SectorIcons = () => {
  const dispatch = useDispatch()

  const sectorsIcon = useSelector(selectSettings).sectorsIcon

  const onChangedSectorIcon = useCallback((id, { key }) => {
    dispatch(saveSettings({
      sectorsIcon: {
        [id]: key,
      },
    }))
    dispatch(elementsRedraw())
  }, [ dispatch ])

  return (
    <Stack className="full-width" style={{ paddingBottom: 16 }}>
      {SECTORS.map((id) => (
        <IconSector
          key={id}
          name={id}
          type={sectorsIcon[id]}
          onChange={onChangedSectorIcon}
        />
      ))}
    </Stack>
  )
}

export default SectorIcons
