import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState, forwardRef } from 'react'
import union from '@turf/union'
import circle from '@turf/circle'
import { polygon } from '@turf/helpers'
import throttle from 'lodash.throttle'
import { Marker, TileLayer, useMapEvents, Pane } from 'react-leaflet'
import L from 'leaflet'
import {
  selectCoordinatesFormat, selectSettings, selectSettingsComplaintsGrouping, selectSettingsDisplay, selectSettingsSizes,
} from '../../../../features/settings/settingsSlice'
import {
  createNewSite, createSiteMode, getSiteTargetCoverage, selectCreateSiteMode, selectNetworkLayersVisibility,
  selectSectorFields, setSectorEditor, setSiteEditor, updateSectorTargetCoverage, selectSelectedCoverage,
  selectSectorsFull, selectSectorFldIdxTC, setBundlePath, initNetworkTemplates, setComplaintEditor,
} from '../../../../features/network/networkSlice'
import {
  initSubstances, saveEditedZone, selectBounce, selectChosenSubstance, selectEditZone, setEditZone, setGlobalDrawMode,
} from '../../../../features/geo/geoSlice'
import { selectSelectedRasterMaps } from '../../../../features/raster/rasterSlice'
import { allAtAnySite, allAtSite, getComplaintList, getElementList, getEntityLayers } from '../../list'
import { checkPolygonIsCorrect, toGeometry, toPolygon, toTurf } from '../../utils'
import { selectUserAccess } from '../../../../features/login/loginSlice'
import { selectIsMyProject } from '../../../../features/projects/projectsSlice'
import { confirmedClosePanel, confirmedRefillPanel, setPanel } from '../../../../features/panel/panelSlice'
import { selectIsEditNeighborsOpened } from '../../../../features/bc/bcSlice'
import { serializeGeometry } from '../../../../utils/geo'
import { determineColor, updateClusters } from '../../render'
import TargetCoverageButton from '../TargetCoverageButton'
import { F4_KEY, ESCAPE_KEY, MAX_HINTS, HINT_TIMEOUT } from '../constants'
import { ID_NETWORK_COVERAGE, SECTOR_BASE, SECTOR_COLORS } from '../../../../constants/network'
import { COLOR_COVERAGE } from '../../../../constants/features'
import { DEFAULT_COLORS } from '../../../Map/draw'
import { iconSvgLine } from '../../../common/icons'
import { CoordinatesIcon, PlusIcon } from '../../../common/icons/names'
import { COMPLAINTS, EDIT_ELEMENTS } from '../../../../constants/access'
import EditBCNeighbors from '../../../BusinessCases/EditBCNeighbors'
import { flattenPolygonOnMap, hexToRgba, zoomMapToEntity } from './utils'
import SelectorEditor from './SelectorEditor'
import TargetCoverages from './TargetCoverages'
import ComplaintsCluster from './ComplaintsCluster'
import ComplaintsCanvas from './ComplaintsCanvas'
import Sites from './Sites'
import Sectors from './Sectors'
import SearchMarker from './SearchMarker'
import Legend from './Legend'
import Zones from './Zones'
import Vector from './Vector'
import Ruler from './Ruler'

export const ORIGIN = process.env.REACT_APP_API || window.location.origin

// Принцип організації даних у масиві, що описує геометрію зони:
// P (point): [ Lng, Lat ]
// Simple polygon: [[ P, P, ... P ]]
// Polygon with hole: [[ P, P, ... P], [ P, P, ... P ]]
// Multipolygon (contains 2 polygons): [ [[ P, P, ... P ]], [[ P, P, ... P ]] ]
// Multipolygon (contains 2 polygons, one with hole): [ [[ P, P, ... P ]], [[ P, P, ... P ], [ P, P, ... P ]] ]

const GeoContent = (props, ref) => {
  const {
    shown, onEditPolygon, reloadTargetCoverages, onNeedUpdateTCVersion, onShowContextMenu,
  } = props
  const dispatch = useDispatch()

  const format = useSelector(selectCoordinatesFormat, shallowEqual)
  const inCreateSiteMode = useSelector(selectCreateSiteMode)
  const networkLayersVisibility = useSelector(selectNetworkLayersVisibility, shallowEqual)
  const ratios = useSelector(selectSettingsSizes)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const complaintsGrouping = useSelector(selectSettingsComplaintsGrouping)

  const editZone = useSelector(selectEditZone)

  const isEditNeighborsOpened = useSelector(selectIsEditNeighborsOpened)

  const [ elementsMenu, setElementsMenu ] = useState(null)
  const [ elementsMenuTarget, setElementsMenuTarget ] = useState(null)
  const [ activeSite, setActiveSite ] = useState(null)
  const [ activeSector, setActiveSector ] = useState(null)
  const [ targetCoverage, setTargetCoverage ] = useState({})
  const [ drawMode, setDrawMode ] = useState(false)

  useEffect(() => {
    global.drawMode = drawMode
    dispatch(setGlobalDrawMode(drawMode))
    return () => {
      global.drawMode = false
      dispatch(setGlobalDrawMode(false))
    }
  }, [ drawMode, dispatch ])

  const doSetActiveSite = useCallback((siteId) => {
    setActiveSite(siteId)
    if (siteId === null) {
      dispatch(confirmedClosePanel('edit-site'))
    } else {
      dispatch(confirmedRefillPanel('edit-site', () => dispatch(setSiteEditor(siteId))))
    }
  }, [ dispatch ])

  useImperativeHandle(ref, () => ({
    setActiveSite: (siteId) => {
      doSetActiveSite(siteId)
    },
  }))

  const doSetActiveSector = useCallback((sectorId) => {
    setActiveSector(sectorId)
    if (sectorId) {
      dispatch(setSectorEditor(sectorId))
    } else {
      dispatch(setBundlePath([]))
    }
  }, [ dispatch ])

  const handleOnSelectSite = useCallback((siteId) => {
    setActiveSite(siteId)
  }, [])

  const handleOnSelectSector = useCallback((sectorId) => {
    setActiveSector(sectorId)
  }, [])

  // Збереження змін зони після редагування
  const saveZone = useCallback(async (map, doFilter = false) => {
    if (map.pm.activeSector || map.pm.editZone) {
      const layers = getEntityLayers(map, map.pm.activeSector || map.pm.editZone)
      const result = layers
        .map((layer) => {
          if (layer.getRadius) {
            const { lng, lat } = layer.getLatLng()
            const radius = layer.getRadius()
            return circle([ lng, lat ], radius / 1000, { steps: 256 }).geometry.coordinates
          } else {
            return toGeometry(layer.getLatLngs())
          }
        })
        .filter(checkPolygonIsCorrect)
        .reduce((acc, item) => union(acc, toPolygon(toTurf(item))), polygon([]))
      const description = layers.reduce((acc, layer) => acc || layer.options.description, '')
      const circleProps = {
        radius: undefined,
        center: undefined,
      }
      const { center, radius, ...oldProps } = map.pm.editZoneProperties ?? {}
      if (layers.length === 1 && layers[0].getRadius) {
        circleProps.radius = layers[0].getRadius()
        circleProps.center = layers[0].getLatLng()
      }
      layers.forEach((layer) => map.removeLayer(layer))
      if (map.pm.activeSector) { // збереження targetCoverages
        await dispatch(updateSectorTargetCoverage(map._activeSite, map.pm.activeSector, result.geometry.coordinates,
          { description, ...oldProps, ...circleProps }))
        const tmp = await dispatch(getSiteTargetCoverage(map._activeSite))
        setTargetCoverage(tmp)
        global.targetCoverage = tmp
        onNeedUpdateTCVersion()
      } else { // збереження зони
        dispatch(saveEditedZone({
          id: map.pm.editZone,
          zone: result.geometry.coordinates,
          uuid: map.pm.editZoneUUID,
          properties: {
            ...oldProps,
            ...circleProps,
          },
        }, doFilter))
      }
    }
  }, [ dispatch, setTargetCoverage, onNeedUpdateTCVersion ])

  // Обробка подій контейнера карти
  const map = useMapEvents({
    mouseover: (event) => {
      if (inCreateSiteMode && map.createSiteCursor) {
        map.createSiteCursor.style.display = 'block'
      }
      if (!map.info) {
        map.info = L.control({ position: 'bottomright' })
        map.info.onAdd = function () {
          this._div = L.DomUtil.create('div', 'info capex-info')
          this._div2 = L.DomUtil.create('div', 'capex-info-row', this._div)
          this._icon = L.DomUtil.create('div', 'capex-info-icon', this._div2)
          this._icon.innerHTML = iconSvgLine({ name: CoordinatesIcon })
          this._coordinates = L.DomUtil.create('span', 'capex-info-label', this._div2)
          this.update(event.latlng)
          return this._div
        }
        map.info.update = throttle(function ({ lat, lng }) {
          this._coordinates.innerHTML = serializeGeometry([ lng, lat ], format)
        }, 200)
        map.info.addTo(map)
      }
    },
    mouseout: () => {
      if (inCreateSiteMode && map.createSiteCursor) {
        map.createSiteCursor.style.display = 'none'
      }
      if (map.info) {
        map.info.remove()
        delete map.info
      }
      if (map.idle) {
        clearTimeout(map.idle)
        delete map.idle
      }
      if (map.hint) {
        map.hint.remove()
        delete map.hint
      }
    },
    mousemove: (event) => {
      if (map.info) {
        map.info.update(event.latlng)
      }
      if (map.hint) {
        map.hint.remove()
        delete map.hint
      }
      if (map.idle) {
        clearTimeout(map.idle)
      }
      map.idle = setTimeout(() => {
        const list = getElementList(map, event.containerPoint)
        let content = null
        if (list.length >= 1) {
          content = list.map((item) => item.options.name)
          if (list.length > MAX_HINTS) {
            content = content.slice(0, MAX_HINTS)
            content.push('...')
          }
          content = content.join('<br>')
        } else {
          const [ complaint ] = getComplaintList(map, event.containerPoint)
          if (complaint) {
            if (complaint.marker) {
              content = complaint.marker.options.typeP
            } else if (!complaint.properties.cluster) {
              content = complaint.properties.type
            }
          }
        }
        if (content) {
          map.hint = L.tooltip()
          map.hint._latlng = event.latlng
          map.hint.setContent(content)
          map.hint.addTo(map)
        }
      }, HINT_TIMEOUT)
      if (inCreateSiteMode && map.createSiteCursor) {
        map.createSiteCursor.style.left = `${event.originalEvent.x - 13}px`
        map.createSiteCursor.style.top = `${event.originalEvent.y - 13}px`
      }
    },
    zoomend: () => {
      localStorage.setItem('mapZoom', map.getZoom())
    },
    moveend: () => {
      const position = map.getCenter()
      localStorage.setItem('mapPosition', JSON.stringify([ position.lat, position.lng ]))
      updateClusters(map)
    },
    keydown: (event) => {
      if (inCreateSiteMode && event.originalEvent.keyCode === ESCAPE_KEY) {
        dispatch(createSiteMode(false))
      }
      if (event.originalEvent.keyCode === F4_KEY) {
        let present = false
        const bounds = new L.LatLngBounds()
        if (map._sites._markers.length) {
          bounds.extend(map._sites.getBounds())
          present = true
        }
        if (map._sectors._markers.length) {
          bounds.extend(map._sectors.getBounds())
          present = true
        }
        if (present) {
          map.fitBounds(bounds)
        }
      }
    },
    click: async (event) => {
      if (inCreateSiteMode) {
        dispatch(createSiteMode(false))
        const created = await dispatch(createNewSite(event.latlng))
        if (created) {
          dispatch(setSiteEditor(created))
          setActiveSite(created)
          dispatch(setPanel('edit-site'))
        }
      } else if (!drawMode) {
        const list = getElementList(map, event.containerPoint)
        const sites = list.filter((item) => item.options.type === 'site')
        const sectors = list.filter((item) => item.options.type === 'sector')
        if (sites.length >= 1) {
          if (map._activeSite !== list[0].options.id) {
            doSetActiveSector(null)
            doSetActiveSite(list[0].options.id)
          }
        } else if (allAtSite(sectors, map._activeSite)) {
          if (map._activeSite !== list[0].options.site) {
            doSetActiveSector(null)
            doSetActiveSite(list[0].options.site)
          }
          if (map._activeSector !== list[0].options.id) {
            doSetActiveSector(null)
            doSetActiveSector(list[0].options.id)
          }
        } else {
          const sameSite = allAtAnySite(sectors)
          if (sameSite) {
            if (map._activeSector) {
              doSetActiveSector(null)
            }
            doSetActiveSite(sameSite)
          } else {
            if (map._activeSector) {
              doSetActiveSector(null)
            }
            if (map._activeSite) {
              doSetActiveSite(null)
            }
          }
        }
      }
    },
    dblclick: async (event) => {
      const list = getElementList(map, event.containerPoint)
      if (list.length === 1) {
        switch (list[0].options.type) {
          case 'site': {
            await dispatch(setSiteEditor(list[0].options.id))
            dispatch(setPanel('edit-site'))
            break
          }
          case 'sector': {
            await dispatch(setSectorEditor(list[0].options.id))
            dispatch(setPanel('edit-sector'))
            break
          }
          default:
        }
      } else if (list.length > 1) {
        const sites = list.filter((item) => item.options.type === 'site')
        const sectors = list.filter((item) => item.options.type === 'sector')
        setElementsMenuTarget(event.originalEvent)
        setElementsMenu([ ...sites, ...sectors ].map((item) => item.options))
      } else if (map._complaints) {
        const [ complaint ] = getComplaintList(map, event.containerPoint)
        if (complaint) {
          const id = complaint.marker.options.id
          await dispatch(setComplaintEditor(id))
          dispatch(setPanel('edit-complaint'))
        }
      }
    },
    'pm:create': ({ layer, shape, source }) => {
      if (source === 'Draw' && (shape === 'Polygon' || shape === 'Rectangle' || shape === 'Circle')) {
        if (map.pm.activeSector || map.pm.editZone) {
          layer.setStyle({
            ...(map.pm.editZoneColors ?? {}),
            id: map.pm.activeSector || map.pm.editZone,
            pmIgnore: false,
          })
          L.PM.reInitLayer(layer)
          // saveZone(map)
          map.pm.enableGlobalEditMode()
        }
      }
    },
    contextmenu: (event) => {
      L.DomEvent.stopPropagation(event)
      event.originalEvent.preventDefault()
      if (!map.pm.globalEditModeEnabled() && !editZone && onShowContextMenu) {
        event.originalEvent.latlng = event.latlng
        onShowContextMenu(event.originalEvent)
      }
    },
    /* 'pm:update': () => {
      saveZone(map)
    },
    'pm:cut': () => saveZone(map), */
    /* 'pm:rotateend': () => {
      // saveZone(map)
      map.eachLayer((layer) => {
        if (layer.pm?.options?.rotate) {
          layer.removeFrom(map)
        }
      })
    }, */
  })

  const hideEditZone = useCallback(() => {
    saveZone(map, true)
    dispatch(setEditZone(null))
    setDrawMode(false)
  }, [ dispatch, map, saveZone ])

  // Обмежуємо координати, тим самим забороняємо притаманне для Leaflet циклічне прокручування карти по довготі
  useEffect(() => {
    window.map = map
    map.doSetActiveSector = doSetActiveSector
    map.setMaxBounds([
      [ -85, -180 ],
      [ 85, 180 ],
    ])
    if (!map._zoomControl) {
      map._zoomControl = L.control.zoom({
        position: 'bottomright',
      }).addTo(map)
    }
  }, [ map, doSetActiveSector ])

  useEffect(() => {
    const zoomToEntityEventType = 'zoom-to-entity'
    const onZoomToEntity = (event) => {
      const entityId = event.detail.nodeId
      if (entityId) {
        zoomMapToEntity(map, entityId)
      }
    }
    window.addEventListener(zoomToEntityEventType, onZoomToEntity)
    return () => {
      window.removeEventListener(zoomToEntityEventType, onZoomToEntity)
    }
  }, [ map ])

  const startDrawOrEditPolygon = useCallback((draw) => {
    if (draw) {
      map.pm.enableDraw('Polygon')
    } else {
      map.pm.enableGlobalEditMode()
    }
  }, [ map ])

  const sectorFields = useSelector(selectSectorFields)
  const { sites, ...sectorSettings } = useSelector(selectSettingsDisplay) || {}
  const coverageLayers = useSelector(selectSelectedCoverage, shallowEqual)
  const rasterMaps = useSelector(selectSelectedRasterMaps, shallowEqual)
  const isMyProject = useSelector(selectIsMyProject)
  const settings = useSelector(selectSettings)

  const colorAttributeIdx = useMemo(() => Object.entries(sectorSettings).reduce((result, [ key, value ]) => ({
    ...result,
    [key]: sectorFields.findIndex(({ id }) => id === value?.attribute),
  }), {}), [ sectorSettings, sectorFields ])

  const sortedCoverageLayers = useMemo(() => {
    const noTX = []
    const toSort = []
    coverageLayers?.forEach((layer) => {
      if (!layer.tx) {
        noTX.push(layer)
      } else {
        toSort.push(layer)
      }
    })
    toSort.sort(({ order: orderA }, { order: orderB }) => (orderA > orderB) ? 1 : ((orderB > orderA) ? -1 : 0))
    return [ ...noTX, ...toSort ]
  }, [ coverageLayers ])

  const sectorsFull = useSelector(selectSectorsFull)
  const settingsDisplay = useSelector(selectSettingsDisplay)
  const [ idIdx2, tIdx2 ] = useSelector(selectSectorFldIdxTC, shallowEqual)

  const sectorColorById = useCallback((sectorId) => {
    const sectorIdx = sectorsFull.findIndexById(sectorId)
    const item = sectorsFull.getList()[sectorIdx]
    const base = SECTOR_BASE[item[tIdx2]]
    return determineColor(item, settingsDisplay[base], colorAttributeIdx[base], idIdx2) || SECTOR_COLORS[item[tIdx2]] ||
      DEFAULT_COLORS.SECTOR
  }, [ sectorsFull, tIdx2, settingsDisplay, colorAttributeIdx, idIdx2 ])

  const [ targetCoverageButton, setTargetCoverageButton ] = useState(false)

  const targetCoverageButtonClick = useCallback(() => {
    const targetCoverage = global.targetCoverage
    setTargetCoverageButton(false)
    setDrawMode(true)
    const activeTC = targetCoverage[map.pm.activeSector]
    const draw = !activeTC || !activeTC.geometry.length
    map.pm.editZoneColors = {
      targetId: `targetCoverage-${map.pm.activeSector}`,
      stroke: true,
      color: 'red',
      fillColor: sectorColorById(map.pm.activeSector),
      fillOpacity: 0.5,
      description: activeTC?.properties.description,
    }
    if (!draw) {
      flattenPolygonOnMap(map, map.pm.activeSector, activeTC.geometry)
      zoomMapToEntity(map, map.pm.activeSector)
    }
    if (activeTC) {
      const { radius, center } = activeTC.properties || {}
      if (radius && center) {
        const [ layer ] = getEntityLayers(map, map.pm.activeSector)
        if (layer) {
          const circle = L.circle(center, { ...layer.options, radius })
          circle.addTo(map)
          map.removeLayer(layer)
          L.PM.reInitLayer(layer)
        }
      }
    }
    startDrawOrEditPolygon(draw)
  }, [ map, setTargetCoverageButton, startDrawOrEditPolygon, sectorColorById ])

  useEffect(() => {
    map.ratios = ratios
  }, [ map, ratios ])

  // Додаткова ініціалізація карти
  useEffect(() => {
    if (map) {
      map.doubleClickZoom.disable()
      if (!map.pm) {
        map.pm = new L.PM.Map(map)
      }
      map.dispatch = dispatch
      map.setElementsMenu = setElementsMenu
      map.setElementsMenuTarget = setElementsMenuTarget
      // У новому дизайні чомусь прибрали шкалу масштабу, тому відключаємо її
      /* if (!map._scale) {
        map._scale = L.control.scale({ position: 'bottomright', imperial: false }).addTo(map)
      } */
    }
  }, [ map, dispatch, setElementsMenu, setElementsMenuTarget ])

  // Реакція на зміну видимості елементів мережі
  useEffect(() => {
    if (!map) {
      return
    }
    map.networkLayersVisibility = networkLayersVisibility
    updateClusters(map)
  }, [ map, networkLayersVisibility ])

  // Перемикання режиму створення сайту
  useEffect(() => {
    if (map) {
      if (inCreateSiteMode) {
        L.DomUtil.addClass(map._container, 'hide-default-cursor')
        map.createSiteCursor = document.createElement('div')
        map.createSiteCursor.className = 'create-site-cursor'
        map.createSiteCursor.innerHTML = `
          <div class="create-site-cursor-icon">${iconSvgLine({ name: PlusIcon, size: 26 })}</div>
          <div class="create-site-cursor-hint">Select Location</div>
        `
        document.body.appendChild(map.createSiteCursor)
        map._container.focus()
      } else {
        L.DomUtil.removeClass(map._container, 'hide-default-cursor')
        map.createSiteCursor?.remove()
        delete map.createSiteCursor
      }
    }
  }, [ map, inCreateSiteMode ])

  const chosenSubstance = useSelector(selectChosenSubstance, shallowEqual)

  // Перемикання видимості тулбара створення/редагування полігону
  useEffect(() => {
    if (drawMode !== map.pm.controlsVisible()) {
      if (!drawMode) {
        map.pm.disableDraw()
        map.pm.disableGlobalEditMode()
        map.pm.disableGlobalDragMode()
        map.pm.disableGlobalRemovalMode()
        map.pm.disableGlobalCutMode()
        map.pm.disableGlobalRotateMode()
      }
      map.pm.toggleControls()
    }
    if (!drawMode) {
      setTargetCoverageButton(Boolean(activeSector))
    }
  }, [ map, drawMode, activeSector, setTargetCoverageButton ])

  // Ініціалізація списку картографічних основ
  useEffect(() => {
    L.PM.setOptIn(true)
    dispatch(initSubstances())
    dispatch(initNetworkTemplates())
  }, [ dispatch ])

  const bounce = useSelector(selectBounce)

  // отримання TargetCoverage для активного сайту
  useEffect(() => {
    const fetchData = async () => {
      const tmp = activeSite ? await dispatch(getSiteTargetCoverage(activeSite)) : {}
      setTargetCoverage(tmp)
      global.targetCoverage = tmp
    }
    fetchData()
  }, [ dispatch, activeSite, setTargetCoverage, reloadTargetCoverages ])

  const keyMaps = {}

  return (
    <>
      <Legend map={map} />
      <Ruler map={map} />
      <SearchMarker map={map} bounce={bounce} />
      {userAccess[COMPLAINTS] && (
        complaintsGrouping
          ? (
              <ComplaintsCluster map={map} />
            )
          : (
              <ComplaintsCanvas map={map} shown={shown} />
            )
      )}
      {userAccess[EDIT_ELEMENTS] && isMyProject && <TargetCoverageButton
        title="Edit target coverage"
        className="leaflet-target-coverage-button"
        map={map}
        visible={targetCoverageButton && !map.pm.editZone && !isEditNeighborsOpened}
        onClick={targetCoverageButtonClick}
      />}
      <Sectors
        map={map}
        activeSector={activeSector}
        setTargetCoverageButton={setTargetCoverageButton}
        colorAttributeIdx={colorAttributeIdx}
        shown={shown}
        userAccess={userAccess}
      />
      <Sites
        map={map}
        activeSite={activeSite}
        activeSector={activeSector}
        shown={shown}
        userAccess={userAccess}
      />
      {chosenSubstance && (
        <TileLayer
          {...chosenSubstance}
          noWrap
          key={chosenSubstance.url}
          keepBuffer={20}
        />
      )}
      <Pane name="coveragePane" style={{ zIndex: 201 }}>
        {sortedCoverageLayers.length > 0 && sortedCoverageLayers.map((props) => {
          const { id, name, parent } = props
          if (!id) {
            return null
          }

          let filter
          let opacity = 0.7
          if (COLOR_COVERAGE) {
            const key = `${ID_NETWORK_COVERAGE}-${parent}-${name}`
            const colorHex = settings.userColors[key]
            if (colorHex) {
              const color = hexToRgba(colorHex)
              const R = color.r / 255
              const G = color.g / 255
              const B = color.b / 255
              filter = `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"><defs><filter id="scapex" color-interpolation-filters="sRGB"><feColorMatrix type="matrix" values="0 0 0 0 ${R} 0 0 0 0 ${G} 0 0 0 0 ${B} 0 0 0 1 0"/></filter></defs></svg>#scapex')`
              opacity = color.a / 255
            }

            if (!keyMaps[parent]) {
              keyMaps[parent] = []
            }
            keyMaps[parent] += id + colorHex
          } else {
            keyMaps[parent] += id
          }

          return (
          <TileLayer
            url={`${ORIGIN}/api/geomaps/${id}/tiles/{z}/{x}/{y}`}
            opacity={opacity}
            noWrap
            key={keyMaps[parent]}
            eventHandlers={COLOR_COVERAGE
              ? {
                  tileload: (e) => {
                    if (filter) {
                      e.tile.style.filter = filter
                    }
                  },
                }
              : undefined}
          />
          )
        })}
      </Pane>
      <Pane name="rasterPane" style={{ zIndex: 202 }}>
        {rasterMaps && rasterMaps.length > 0 && rasterMaps.map(({ id }) => (
          <TileLayer
            url={`${ORIGIN}/api/geomaps/${id}/tiles/{z}/{x}/{y}`}
            opacity={0.7}
            noWrap
            key={id}
          />
        ))}
      </Pane>
      <Vector map={map} />
      <Zones
        map={map}
        activeSector={activeSector}
        targetCoverage={targetCoverage}
        setTargetCoverageButton={setTargetCoverageButton}
        onEditPolygon={onEditPolygon}
        saveZone={saveZone}
        setDrawMode={setDrawMode}
        startDrawOrEditPolygon={startDrawOrEditPolygon}
      />
      <TargetCoverages
        map={map}
        targetCoverages={targetCoverage}
        activeSector={activeSector}
        setActiveSector={doSetActiveSector}
        colorAttributeIdx={colorAttributeIdx}
        onEditPolygon={onEditPolygon}
        drawMode={drawMode}
        version={reloadTargetCoverages}
      />
      {bounce && (
        <Marker position={bounce} />
      )}
      <SelectorEditor
        elementsMenu={elementsMenu}
        elementsMenuTarget={elementsMenuTarget}
        setActiveSector={doSetActiveSector}
        setActiveSite={doSetActiveSite}
        setElementsMenu={setElementsMenu}
      />
      <EditBCNeighbors
        activeSector={activeSector}
        onShow={hideEditZone}
        onSelectSite={handleOnSelectSite}
        onSelectSector={handleOnSelectSector}
      />
    </>
  )
}

export default forwardRef(GeoContent)
