import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CreateProjectForm from '../Form/CreateProjectForm'
import {
  loadProjects,
  selectConfirmEnsureProject,
  setConfirmEnsureProject,
} from '../../../features/projects/projectsSlice'

const ConfirmEnsureProject = () => {
  const dispatch = useDispatch()
  const project = useSelector(selectConfirmEnsureProject)

  const isShow = useMemo(() => Boolean(project), [ project ])
  const defProject = useMemo(() => ({
    ...project,
    description: '',
  }), [ project ])

  useEffect(() => {
    if (isShow) {
      dispatch(loadProjects())
    }
  }, [ isShow, dispatch ])

  const resolve = useMemo(() => project?.resolve, [ project ])

  const onOk = useCallback((data) => {
    const { description } = data
    dispatch(setConfirmEnsureProject({
      ...data,
      description: description || project?.description,
      confirmed: true,
    }))
    resolve()
  }, [ resolve, dispatch, project ])

  const onCancel = useCallback(() => {
    dispatch(setConfirmEnsureProject({ confirmed: false }))
    resolve()
  }, [ resolve, dispatch ])

  return isShow
    ? (
        <CreateProjectForm
          project={defProject}
          isShow={true}
          onCreate={onOk}
          onHide={onCancel}
          title="Create New Project"
        />
      )
    : null
}

export default ConfirmEnsureProject
