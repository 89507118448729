import React, { useCallback, useRef } from 'react'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import { Spinner, Stack, Label } from '@fluentui/react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFiltersFiltering } from '../../features/filters/filters'
import { DefaultButton, PrimaryButton } from '../common/Button'
import { selectSettings } from '../../features/settings/settingsSlice'
import TableSpecialFiltering from './TableSetFilters'
import { isBoolean, isBooleanTypeColumn, isTrue, labelDataType } from './constant'

// Used to add spacing between example checkboxes
const stackTokens = { childrenGap: 10 }

const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  keepInBounds: true,
  // menu: ContextualMenu,
}

const modalProps = {
  isBlocking: true,
  isModeless: false,
  dragOptions: dragOptions,
}

const dialogStyles = {
  main: {
    selectors: {
      '@media (min-width: 680px)': {
        maxWidth: 880,
        width: 820,
      },
    },
  },
}

const debrisFilterData = (specialFilters) => {
  const debrisFilter = []
  const { columns, filter } = specialFilters
  if (!Array.isArray(columns) || !Array.isArray(filter)) {
    return debrisFilter
  }
  filter.forEach((row) => {
    const condition = []
    row.forEach((cell, index) => {
      if (cell.value == null || cell.value === '') {
        return // Поле даних порожнє
      }
      const { type, columnIndex } = columns[index] ?? {}
      if (!type || columnIndex == null) {
        return // Не вказано атрибут, за яким здійснювати фільтрацію
      }
      if (isBooleanTypeColumn(type)) {
        if (!isBoolean(cell.value)) {
          return // Дані не відповідають булевому типу
        }
        condition.push({ columnIndex, type, ...cell, value: isTrue(cell.value) })
      } else {
        condition.push({ columnIndex, type, ...cell })
      }
    })
    if (condition.length) {
      debrisFilter.push(condition)
    }
  })
  return debrisFilter
}

const DialogFilter = ({
  onClose,
  hidden,
  dataType,
  type = DialogType.normal,
  columns,
  currentFilter,
  dataFiltering,
  addFilters,
  resetFilters,
  resetSelection,
}) => {
  const dispatch = useDispatch()
  const specialFilters = useRef({})
  const { colNotDisplay } = useSelector(selectSettings)
  const notDisplayColumns = colNotDisplay?.[dataType]

  const onResetFilters = useCallback(() => {
    if (resetFilters && dataFiltering) {
      dispatch(resetFilters(dataType))
      dispatch(dataFiltering(dataType))
    }
  }, [ resetFilters, dispatch, dataType, dataFiltering ])

  const onAddSpecialFilters = useCallback(() => {
    if (!columns || !dispatch || !dataType) { return }

    const comparisonRules = debrisFilterData(specialFilters?.current)
    if (comparisonRules.length) {
      if (addFilters && dataFiltering) {
        dispatch(addFilters({ dataType, filter: { inversion: false, comparisonRules }, reset: true }))
        dispatch(dataFiltering(dataType))
      }
    } else {
      onResetFilters()
    }
    resetSelection()
  }, [ columns, dispatch, dataType, resetSelection, addFilters, dataFiltering, onResetFilters ])

  // Індикація процесу фільтрації (не працює, фільтрація поки не асинхронна)
  const filtering = useSelector(selectFiltersFiltering(dataType))

  return (<>
    <Dialog
      hidden={hidden}
      onDismiss={onClose}
      dialogContentProps={{
        type: type,
        title: <Stack horizontal>
          <div>Filter</div>
          {filtering
            ? <Spinner style={{ marginLeft: '20px' }}/>
            : null
          }
        </Stack>,
      }}
      modalProps={modalProps}
      styles={dialogStyles}
    >
      <Stack tokens={stackTokens} style={{ marginRight: '8px', overflow: 'auto' }}>
        <Label title={dataType ?? ''} style={{ padding: '0 5px' }}>{labelDataType?.[dataType] ?? ''}</Label>
        <TableSpecialFiltering
          columnsSettings={columns}
          specialFilters={specialFilters}
          oldFilters={currentFilter}
          notDisplayColumns={notDisplayColumns}
        />
      </Stack>
      <DialogFooter>
        <PrimaryButton onClick={onAddSpecialFilters} text="Apply" />
        <DefaultButton
          onClick={() => {
            onClose()
            onAddSpecialFilters()
          }}
          text="OK"
        />
        <DefaultButton onClick={onClose} text="Cancel" />
      </DialogFooter>
    </Dialog>
    </>
  )
}

export default DialogFilter
