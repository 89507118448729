/* jslint bitwise: true, indent: 4, laxbreak: true, laxcomma: true, smarttabs: true, plusplus: true */

/*! @source http://purl.eligrey.com/github/FileSaver.js/blob/master/FileSaver.js */

export const saveAs =
  // IE 10+ (native saveAs)
  (typeof navigator !== 'undefined' &&
      navigator.msSaveOrOpenBlob && navigator.msSaveOrOpenBlob.bind(navigator)) ||
  // Everyone else
  (function (view) {
    // IE <10 is explicitly unsupported
    if (typeof navigator !== 'undefined' && /MSIE [1-9]\./.test(navigator.userAgent)) {
      return
    }
    let doc = view.document
    // only get URL when necessary in case BlobBuilder.js hasn't overridden it yet
    const getURL = function () {
      return view.URL || view.webkitURL || view
    }
    const URL = view.URL || view.webkitURL || view
    let saveLink = doc.createElementNS('http://www.w3.org/1999/xhtml', 'a')
    const canUseSaveLink = !view.externalHost && 'download' in saveLink
    /* const click = function (node) {
      const event = doc.createEvent('MouseEvents')
      event.initMouseEvent(
        'click', true, false, view, 0, 0, 0, 0, 0
        , false, false, false, false, 0, null,
      )
      node.dispatchEvent(event)
    } */
    const webkitReqFs = view.webkitRequestFileSystem
    const reqFs = view.requestFileSystem || webkitReqFs || view.mozRequestFileSystem
    const throwOutside = function (ex) {
      (view.setImmediate || view.setTimeout)(function () {
        throw ex
      }, 0)
    }
    const forceSaveableType = 'application/octet-stream'
    let fsMinSize = 0
    const deletionQueue = []
    const processDeletionQueue = function () {
      let i = deletionQueue.length
      while (i--) {
        const file = deletionQueue[i]
        if (typeof file === 'string') { // file is an object URL
          URL.revokeObjectURL(file)
        } else { // file is a File
          file.remove()
        }
      }
      deletionQueue.length = 0 // clear queue
    }
    const dispatch = function (filesaver, eventTypes, event) {
      eventTypes = [].concat(eventTypes)
      let i = eventTypes.length
      while (i--) {
        const listener = filesaver['on' + eventTypes[i]]
        if (typeof listener === 'function') {
          try {
            listener.call(filesaver, event || filesaver)
          } catch (ex) {
            throwOutside(ex)
          }
        }
      }
    }
    const FileSaver = function (blob, name) {
      // First try a.download, then web filesystem, then object URLs
      const filesaver = this
      const type = blob.type
      let blobChanged = false
      let objectUrl
      let targetView
      const getObjectUrl = function () {
        const objectUrl = getURL().createObjectURL(blob)
        deletionQueue.push(objectUrl)
        return objectUrl
      }
      const dispatchAll = function () {
        dispatch(filesaver, 'writestart progress write writeend'.split(' '))
      }
      // on any filesys errors revert to saving with object URLs
      const fsError = function () {
        // don't create more object URLs than needed
        if (blobChanged || !objectUrl) {
          objectUrl = getObjectUrl(blob)
        }
        if (targetView) {
          targetView.location.href = objectUrl
        } else {
          window.open(objectUrl, '_blank')
        }
        filesaver.readyState = filesaver.DONE
        dispatchAll()
      }
      const abortable = function (func) {
        return function () {
          if (filesaver.readyState !== filesaver.DONE) {
            return func.apply(this, arguments)
          }
        }
      }
      const createIfNotFound = { create: true, exclusive: false }
      let slice

      filesaver.readyState = filesaver.INIT
      if (!name) {
        name = 'download'
      }
      if (canUseSaveLink) {
        objectUrl = getObjectUrl(blob)
        // FF for Android has a nasty garbage collection mechanism
        // that turns all objects that are not pure javascript into 'deadObject'
        // this means `doc` and `save_link` are unusable and need to be recreated
        // `view` is usable though:
        doc = view.document
        saveLink = doc.createElementNS('http://www.w3.org/1999/xhtml', 'a')
        saveLink.href = objectUrl
        saveLink.download = name
        const event = doc.createEvent('MouseEvents')
        event.initMouseEvent(
          'click', true, false, view, 0, 0, 0, 0, 0
          , false, false, false, false, 0, null,
        )
        saveLink.dispatchEvent(event)
        filesaver.readyState = filesaver.DONE
        dispatchAll()
        return
      }
      // Object and web filesystem URLs have a problem saving in Google Chrome when
      // viewed in a tab, so I force save with application/octet-stream
      // http://code.google.com/p/chromium/issues/detail?id=91158
      if (view.chrome && type && type !== forceSaveableType) {
        slice = blob.slice || blob.webkitSlice
        blob = slice.call(blob, 0, blob.size, forceSaveableType)
        blobChanged = true
      }
      // Since I can't be sure that the guessed media type will trigger a download
      // in WebKit, I append .download to the filename.
      // https://bugs.webkit.org/show_bug.cgi?id=65440
      if (webkitReqFs && name !== 'download') {
        name += '.download'
      }
      if (type === forceSaveableType || webkitReqFs) {
        targetView = view
      }
      if (!reqFs) {
        fsError()
        return
      }
      fsMinSize += blob.size
      reqFs(view.TEMPORARY, fsMinSize, abortable(function (fs) {
        fs.root.getDirectory('saved', createIfNotFound, abortable(function (dir) {
          const save = function () {
            dir.getFile(name, createIfNotFound, abortable(function (file) {
              file.createWriter(abortable(function (writer) {
                writer.onwriteend = function (event) {
                  targetView.location.href = file.toURL()
                  deletionQueue.push(file)
                  filesaver.readyState = filesaver.DONE
                  dispatch(filesaver, 'writeend', event)
                }
                writer.onerror = function () {
                  const error = writer.error
                  if (error.code !== error.ABORT_ERR) {
                    fsError()
                  }
                }
                'writestart progress write abort'.split(' ').forEach(function (event) {
                  writer['on' + event] = filesaver['on' + event]
                })
                writer.write(blob)
                filesaver.abort = function () {
                  writer.abort()
                  filesaver.readyState = filesaver.DONE
                }
                filesaver.readyState = filesaver.WRITING
              }), fsError)
            }), fsError)
          }
          dir.getFile(name, { create: false }, abortable(function (file) {
            // delete file if it already exists
            file.remove()
            save()
          }), abortable(function (ex) {
            if (ex.code === ex.NOT_FOUND_ERR) {
              save()
            } else {
              fsError()
            }
          }))
        }), fsError)
      }), fsError)
    }
    const FSProto = FileSaver.prototype
    const saveAs = function (blob, name) {
      return new FileSaver(blob, name)
    }

    FSProto.abort = function () {
      const filesaver = this
      filesaver.readyState = filesaver.DONE
      dispatch(filesaver, 'abort')
    }
    FSProto.readyState = FSProto.INIT = 0
    FSProto.WRITING = 1
    FSProto.DONE = 2

    FSProto.error =
    FSProto.onwritestart =
    FSProto.onprogress =
    FSProto.onwrite =
    FSProto.onabort =
    FSProto.onerror =
    FSProto.onwriteend = null

    view.addEventListener('unload', processDeletionQueue)
    saveAs.unload = function () {
      processDeletionQueue()
      view.removeEventListener('unload', processDeletionQueue)
    }
    return saveAs
  }(
    (typeof window !== 'undefined' && window) || this.content,
  ))
// `self` is undefined in Firefox for Android content script context
// while `this` is nsIContentFrameMessageManager
// with an attribute `content` that corresponds to the window

// if (typeof module !== 'undefined') { module.exports = saveAs }
