import { createSlice } from '@reduxjs/toolkit'
import api from '../../api'

let loading = false

const initialState = {
  data: {
    atoll: {
      id: 'atoll',
      name: 'Atoll',
      availability: null,
      subServices: {
        db: {
          name: 'DB',
          availability: null,
        },
      },
    },
    dwh: {
      id: 'dwh',
      name: 'DWH',
      availability: null,
      subServices: {
        sftp: {
          name: 'SFTP',
          availability: null,
        },
        db: {
          name: 'DB',
          availability: null,
        },
      },
    },
    dmp: {
      id: 'dmp',
      name: 'DMP',
      availability: null,
      subServices: {
        api: {
          name: 'API',
          availability: null,
        },
        eventHub: {
          name: 'EventHub',
          availability: null,
        },
        sftp: {
          name: 'SFTP',
          availability: null,
        },
      },
    },
    snc: {
      id: 'snc',
      name: 'SNC',
      availability: null,
      subServices: {
        geoserver: {
          name: 'GeoServer',
          availability: null,
        },
        sftp: {
          name: 'SFTP',
          availability: null,
        },
        db: {
          name: 'DB',
          availability: null,
        },
      },
    },
  },
}

const decodeMetrics = (data, state) => {
  const result = JSON.parse(JSON.stringify(state))

  result.atoll.availability = data['atoll.status']
  result.atoll.subServices.db.availability = data['atoll.db.status']

  result.dwh.availability = data['dwh.status']
  result.dwh.subServices.sftp.availability = data['dwh.sftp.status']
  result.dwh.subServices.db.availability = data['dwh.db.status']

  result.dmp.availability = data['dmp.status']
  result.dmp.subServices.api.availability = data['dmp.api.status']
  result.dmp.subServices.eventHub.availability = data['dmp.eventhub.status']
  result.dmp.subServices.sftp.availability = data['dmp.sftp.status']

  result.snc.availability = data['snc.status']
  result.snc.subServices.geoserver.availability = data['snc.geoserver.status']
  result.snc.subServices.sftp.availability = data['snc.sftp.status']
  result.snc.subServices.db.availability = data['snc.db.status']

  return result
}

const loadingFailed = (state) => {
  const result = JSON.parse(JSON.stringify(state))

  result.atoll.availability = false
  result.atoll.subServices.db.availability = null
  result.dwh.availability = false
  result.dwh.subServices.sftp.availability = null
  result.dwh.subServices.db.availability = null
  result.dmp.availability = false
  result.dmp.subServices.api.availability = null
  result.dmp.subServices.eventHub.availability = null
  result.dmp.subServices.sftp.availability = null
  result.snc.availability = false
  result.snc.subServices.geoserver.availability = null
  result.snc.subServices.sftp.availability = null
  result.snc.subServices.db.availability = null

  return result
}

export const metricsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },
  },
})

const { setData } = metricsSlice.actions

export const selectMetrics = (state) => state.metrics.data

export const loadMetrics = async (dispatch, user, metrics) => {
  if (!loading && user?.id && !global.drawMode && !global.menuMode) {
    loading = true
    let newData
    try {
      const result = await api.metrics.getMetrics()
      newData = decodeMetrics(result, metrics)
    } catch (e) {
      console.error('Failed to retrieve system metrics', e)
      newData = loadingFailed(metrics)
    } finally {
      loading = false
      if (newData && JSON.stringify(newData) !== JSON.stringify(metrics)) {
        dispatch(setData(newData))
      }
    }
  }
}

export default metricsSlice.reducer
