import React from 'react'
import Search from '../Search'

const Replace = ({ onSearch, onReplace }) => {
  return (
    <Search
      onSearch={onSearch}
      onReplace={onReplace}
    />
  )
}

export default Replace
