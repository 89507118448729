import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import api from '../../api'
import { wrapByEventLog, EVENT_TYPE } from '../eventLog/eventLogSlice'
import { readFileContentXLSX, tableToExcel } from '../../utils/export'
import { alwaysTrue } from '../../utils/format'
import { xlsxFileInputId } from '../../components/Cabinet/ManualSynchronization/Container'

const initialState = {
  loading: false,
  saving: false,
  name: 'Synchronization',
  list: [],
  reload: 0,
  loadingStatus: false,
  jobControl: 0,
  exporting: false,
  importing: false,
}

const loading = (state) => {
  state.loading = true
}

const loadingJobStatus = (state) => {
  state.loadingStatus = true
}

const saving = (state) => {
  state.saving = true
}

export const doLoadJobs = createAsyncThunk(
  'jobs/load',
  api.jobs.load,
)

const doSynchronize = createAsyncThunk(
  'jobs/synchronize',
  api.jobs.synchronize,
)

export const doGetJobStatus = createAsyncThunk(
  'jobs/getJobStatus',
  api.jobs.getJobStatus,
)

export const doGetSettlements = createAsyncThunk(
  'settlements/getSettlements',
  api.settlements.getSettlements,
)

export const doSetSettlements = createAsyncThunk(
  'settlements/setSettlements',
  api.settlements.setSettlements,
)

export const synchronizationSlice = createSlice({
  name: 'synchronization',
  initialState,
  reducers: {
    startJobControl: (state) => {
      state.jobControl = state.jobControl ? state.jobControl + 1 : 1
    },
    setImporting: (state, action) => {
      state.importing = action.payload
    },
    setExporting: (state, action) => {
      state.exporting = action.payload
    },
  },
  extraReducers: (builder) => builder
    .addCase(doLoadJobs.pending, loading)
    .addCase(doLoadJobs.fulfilled, (state, action) => {
      state.loading = false
      state.ready = true
      state.list = action.payload
    })
    .addCase(doLoadJobs.rejected, (state) => {
      state.loading = false
    })
    .addCase(doSynchronize.pending, saving)
    .addCase(doSynchronize.fulfilled, (state) => {
      state.saving = false
    })
    .addCase(doSynchronize.rejected, (state) => {
      state.saving = false
    })
    .addCase(doGetJobStatus.pending, loadingJobStatus)
    .addCase(doGetJobStatus.fulfilled, (state) => {
      state.loadingStatus = false
    })
    .addCase(doGetJobStatus.rejected, (state) => {
      state.loadingStatus = false
    }),
})

export const {
  startJobControl,
  setExporting,
  setImporting,
} = synchronizationSlice.actions

export const selectJobs = (state) => state.synchronization?.list

export const selectLoading = (state) => state.synchronization?.loading
export const selectSaving = (state) => state.synchronization?.saving
export const selectBusy = (state) => state.synchronization?.loading || state.synchronization?.saving
export const selectReload = (state) => state.synchronization?.reload
export const selectIsImporting = (state) => state.synchronization?.importing
export const selectIsExporting = (state) => state.synchronization?.exporting

export const selectJobControl = (state) => state.synchronization?.jobControl
const selectGetStatusLoading = (state) => state.synchronization?.loadingStatus

export const loadJobs = (dispatch, getState) => {
  const currentState = getState()
  if (!selectBusy(currentState)) {
    return Promise.all([
      dispatch(doLoadJobs()),
    ])
  }
}

const extractPeriod = (job) => {
  const { fromDate, toDate } = job?.properties || {}
  if (fromDate && toDate) {
    return ` from ${fromDate.slice(0, 7)} to ${toDate.slice(0, 7)}`
  }
  return ''
}

export const synchronizeJob = (job) => async (dispatch, getState) => {
  const currentState = getState()
  if (!selectBusy(currentState)) {
    const result = await dispatch(wrapByEventLog({
      type: EVENT_TYPE.synchronization,
      details: `Start job ${job.type}${extractPeriod(job)}`,
      action: () => dispatch(doSynchronize(job)),
    }))
    if (!result.error) {
      dispatch(loadJobs)
    }
  }
}

export const getJobStatus = (jobId) => (dispatch, getState) => {
  const currentState = getState()
  if (!selectGetStatusLoading(currentState)) {
    return dispatch(doGetJobStatus(jobId))
  }
}

export const onExportSettlementsToXLSX = async (dispatch, getState) => {
  try {
    dispatch(setExporting(true))
    const fileName = 'settlements.xlsx'
    const doExport = async () => {
      const response = await dispatch(doGetSettlements())
      if (!response?.error && response?.payload) {
        const settlements = response.payload
        if (settlements?.length > 0) {
          const headers = Object.keys(settlements[0])
          const cells = settlements.map((settlement) => {
            return headers.map((header) => settlement[header])
          })
          tableToExcel(headers, cells, alwaysTrue, 'Settlements', fileName)
          return cells.length
        } else {
          toast.error('No data for export')
        }
      }
      return 0
    }
    await dispatch(wrapByEventLog({
      type: EVENT_TYPE.exportSettlements,
      details: `Exporting settlements data to ${fileName}`,
      action: () => doExport(),
      extractor: (result) => {
        return result > 0 ? `${result} lines were exported to ${fileName}` : 'No data for export'
      },
    }))
  } finally {
    dispatch(setExporting(false))
  }
}

export const onImportSettlementsToXLSX = async (dispatch) => {
  try {
    dispatch(setImporting(true))
    const input = document.getElementById(xlsxFileInputId)
    const doImport = async () => {
      const [ headerNames, ...cells ] = await readFileContentXLSX(input.files[0], true)
      if (cells?.length > 0 && headerNames?.length > 0 && cells[0]?.length === headerNames.length) {
        const data = cells.map((row) => {
          return row.reduce((agg, item, index) => {
            agg[headerNames[index]] = [ 'longitude', 'latitude' ].includes(headerNames[index])
              ? parseFloat(item)
              : (headerNames[index] === 'population' ? parseInt(item) : item)
            return agg
          }, {})
        })
        await dispatch(doSetSettlements(data))
        return cells.length
      } else {
        toast.error('There is no data available for import, or the format is not suitable')
      }
      return 0
    }

    await dispatch(wrapByEventLog({
      type: EVENT_TYPE.importSettlements,
      details: `Importing settlements data from ${input.files[0]}`,
      action: () => doImport(),
      extractor: (result) => {
        return result > 0
          ? `${result} lines were imported from ${input.files[0]}`
          : 'There is no data available for import, or the format is not suitable'
      },
    }))
  } finally {
    dispatch(setImporting(false))
  }
}

export default synchronizationSlice.reducer
