import { v4 as uuid } from 'uuid'
import { BC_ID_FIELD, BC_NAME_FIELD } from '../features/bc/constants'
import { fetch } from './rest'
import CoefficientsData from './mock/bcCoefficient.json'

export const getAllBCData = (projectId) => fetch(`/api/project/${projectId}/bc`)

export const getListBCData = (bcIds) => fetch('/api/bcs', 'POST', bcIds)

export const createGroupCase = (action) => fetch('/api/bc', 'POST', action)

export const getBusinessCaseGroups = (valid) => fetch(valid ? '/api/bcgs?valid=true' : '/api/bcgs')

export const createBusinessCases = (action) => new Promise((resolve, reject) => {
  const { groupCaseId, businessCases } = action
  const data = {
    operation: 'MODIFY_ATTRIBUTES',
    items: businessCases.reduce((res, bc) => {
      res[bc[BC_ID_FIELD] || uuid()] = {
        ...bc,
        [BC_ID_FIELD]: undefined,
        [BC_NAME_FIELD]: bc[BC_NAME_FIELD] === '' ? null : bc[BC_NAME_FIELD],
      }
      return res
    }, {}),
  }
  return fetch(`/api/bc/${groupCaseId}`, 'POST', data).then(resolve).catch(reject)
})

export const deleteGC = (action) => fetch('/api/bc', 'DELETE', action)

export const deleteBusinessCases = (action) => new Promise((resolve, reject) => {
  const { groupCaseId, businessCaseIds } = action
  const data = {
    operation: 'REMOVE',
    items: businessCaseIds,
  }
  return fetch(`/api/bc/${groupCaseId}`, 'POST', data).then(resolve).catch(reject)
})

export const copyGroupBusinessCase = ({ groupBusinessCaseId: id, newName: name }) =>
  fetch('/api/bc/copy', 'POST', { id, name })

export const deleteGroupBusinessCase = (id) =>
  fetch('/api/bc', 'DELETE', { id })

export const calculateDraft = (action) => new Promise((resolve, reject) => {
  const { groupCaseId, businessCaseIds } = action
  const data = {
    operation: 'CALCULATE_DMP_DRAFT',
    items: businessCaseIds.reduce((res, bcId) => {
      res[bcId] = {}
      return res
    }, {}),
  }
  return fetch(`/api/bc/${groupCaseId}`, 'POST', data).then(resolve).catch(reject)
})

export const recalculateDraft = (action) => new Promise((resolve, reject) => {
  const { groupCaseId, businessCaseIds } = action
  const data = {
    operation: 'CALCULATE_SNC',
    items: businessCaseIds.reduce((res, bcId) => {
      res[bcId] = {}
      return res
    }, {}),
  }
  return fetch(`/api/bc/${groupCaseId}`, 'POST', data).then(resolve).catch(reject)
})

export const calculateFake = (action) => new Promise((resolve, reject) => {
  const { groupCaseId, businessCaseIds } = action
  const data = {
    operation: 'CALCULATE_SNC_FAKE',
    items: businessCaseIds.reduce((res, bcId) => {
      res[bcId] = {}
      return res
    }, {}),
  }
  return fetch(`/api/bc/${groupCaseId}`, 'POST', data).then(resolve).catch(reject)
})

export const calculateForApprove = (action) => new Promise((resolve, reject) => {
  const { groupCaseId, businessCaseIds } = action
  const data = {
    operation: 'CALCULATE_DMP_FOR_APPROVE', // 'CALCULATE_SNC',
    items: businessCaseIds.reduce((res, bcId) => {
      res[bcId] = {}
      return res
    }, {}),
  }
  return fetch(`/api/bc/${groupCaseId}`, 'POST', data).then(resolve).catch(reject)
})

export const cancelCalculation = (action) => new Promise((resolve, reject) => {
  const { groupCaseId, businessCaseIds } = action
  const data = {
    operation: 'CALCULATE_DMP_CANCEL',
    items: businessCaseIds.reduce((res, bcId) => {
      res[bcId] = {}
      return res
    }, {}),
  }
  return fetch(`/api/bc/${groupCaseId}`, 'POST', data).then(resolve).catch(reject)
})

export const approveDraft = (action) => new Promise((resolve, reject) => {
  const { groupCaseId, businessCaseIds } = action
  const data = {
    operation: 'APPROVE_DRAFT',
    items: businessCaseIds.reduce((res, bcId) => {
      res[bcId] = {}
      return res
    }, {}),
  }
  return fetch(`/api/bc/${groupCaseId}`, 'POST', data).then(resolve).catch(reject)
})

export const approve = (action) => new Promise((resolve, reject) => {
  const { groupCaseId, businessCaseIds } = action
  const data = {
    operation: 'APPROVE',
    items: businessCaseIds.reduce((res, bcId) => {
      res[bcId] = {}
      return res
    }, {}),
  }
  return fetch(`/api/bc/${groupCaseId}`, 'POST', data).then(resolve).catch(reject)
})

export const reject = (action) => new Promise((resolve, reject) => {
  const { groupCaseId, businessCaseIds } = action
  const data = {
    operation: 'REJECT',
    items: businessCaseIds.reduce((res, bcId) => {
      res[bcId] = {}
      return res
    }, {}),
  }
  return fetch(`/api/bc/${groupCaseId}`, 'POST', data).then(resolve).catch(reject)
})

export const getBusinessCaseFinancials = ({ groupCaseId, id, instanceId }) =>
  fetch(`/api/bc/${groupCaseId}/${id}/${instanceId}/result`)

export const checkCorrespondence = (projectId) =>
  fetch(`/api/project/${projectId}/bc/correspondence`)

export const getBusinessCaseDifferences = ({ groupCaseId, id, instanceId }) =>
  fetch(`/api/bc/${groupCaseId}/${id}/${instanceId}/correspondence-data`)

export const getCalculationCoefficients = () => new Promise((resolve) => {
  setTimeout(
    () => resolve(CoefficientsData),
    500,
  )
})

export const updateCalculationCoefficients = (data) => new Promise((resolve) => {
  const { coefficients } = CoefficientsData
  const updated = []
  coefficients.forEach((coeff) => {
    updated.push({
      ...coeff,
      value: data[coeff.id] !== undefined ? data[coeff.id] : coeff.value,
    })
  })
  setTimeout(
    () => resolve({ coefficients: updated }),
    500,
  )
})
