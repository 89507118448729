import React, { useCallback, useMemo, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  selectComplaints, selectComplaintFields, setComplaintEditor, selectComplaintFldIdx,
} from '../../features/network/networkSlice'
import { setPanel } from '../../features/panel/panelSlice'
import Table from '../../components/common/Table'
import { DATA_TYPES } from '../../constants/common'
import { COMPLAINT_LAT_FIELD, COMPLAINT_LNG_FIELD } from '../../constants/network'
import { initColumnsGrid } from './utils'

const SUB_TYPES = {
  [COMPLAINT_LAT_FIELD]: 'lat',
  [COMPLAINT_LNG_FIELD]: 'lng',
}
const Complaints = () => {
  const dispatch = useDispatch()
  const refHot = useRef()

  const data = useSelector(selectComplaints)
  const columnsInit = useSelector(selectComplaintFields)
  const columns = useMemo(() => initColumnsGrid(columnsInit, SUB_TYPES), [ columnsInit ])

  const [ idFieldIdx ] = useSelector(selectComplaintFldIdx, shallowEqual)

  const recordEdit = useCallback(async (row) => {
    await dispatch(setComplaintEditor(row))
    dispatch(setPanel('edit-complaint'))
  }, [ dispatch ])

  return (
    <Table
      data={data}
      columns={columns}
      idFieldIdx={idFieldIdx}
      onRecordEdit={recordEdit}
      refHot={refHot}
      dataType={DATA_TYPES.COMPLAINTS}
      readOnly
      manualColumnMove
      viewportColumnRenderingOffset={35}
    />
  )
}

export default Complaints
