import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectUserAccess } from '../../../../features/login/loginSlice'
import { COMPLAINTS, EDIT_ELEMENTS } from '../../../../constants/access'
import PanelSections from '../../PanelSections'
import MapLayers from './MapLayers'
import Legend from './Legend'
import Labels from './Labels'
import CoordinateFormats from './CoordinateFormats'
import SectorIcons from './SectorIcons'
import IconSize from './IconSize'
import NetworkTemplates from './NetworkTemplates'
import DeepFiltration from './DeepFiltration'
import ComplaintsGrouping from './ComplaintsGrouping'
import RulerSettings from './RulerSettings'

const mapStyleItems = (userAccess) => [
  {
    id: 'layers',
    title: 'Layers',
    Component: MapLayers,
  },
  {
    id: 'legend',
    title: 'Legend',
    Component: Legend,
  },
  {
    id: 'labels',
    title: 'Labels',
    Component: Labels,
  },
  {
    id: 'coordinate-formats',
    title: 'Coordinate Formats',
    Component: CoordinateFormats,
  },
  {
    id: 'complaints-grouping',
    title: 'Complaints Grouping',
    Component: ComplaintsGrouping,
    hidden: !userAccess[COMPLAINTS],
  },
  {
    id: 'sector-icons',
    title: 'Sector Icons',
    Component: SectorIcons,
  },
  {
    id: 'icon-size',
    title: 'Icon Size',
    Component: IconSize,
  },
  {
    id: 'ruler-props',
    title: 'Ruler',
    Component: RulerSettings,
  },
  {
    id: 'network-templates',
    title: 'Network Templates',
    Component: NetworkTemplates,
    hidden: !userAccess[EDIT_ELEMENTS],
  },
  {
    id: 'deep-filtration',
    title: 'Deep Filtration',
    Component: DeepFiltration,
  },
]

const MapStyle = () => {
  const userAccess = useSelector(selectUserAccess)

  const items = useMemo(() => mapStyleItems(userAccess), [ userAccess ])

  return (
    <PanelSections id="map-style" items={items} />
  )
}

export default MapStyle
