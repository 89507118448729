import React from 'react'
import { useSelector } from 'react-redux'
import { ProgressIndicator } from '@fluentui/react'
import Modal from '../common/Modal'
import { selectLoadingResourceStatus } from '../../features/compositeIndex/compositeIndexSlice'

const LoadingResourceStatus = () => {
  const status = useSelector(selectLoadingResourceStatus)
  const { title, total, current } = status ?? {}

  return status
    ? (
        <Modal
          title="Loading"
          width={480}
        >
          <ProgressIndicator
            barHeight={5}
            label={title ?? `resource ${current} / ${total}`}
            percentComplete={current / total}
          />
        </Modal>
      )
    : <></>
}

export default LoadingResourceStatus
