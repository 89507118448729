/* eslint-disable react/no-unknown-property */
import React from 'react'

const Component = ({ percentage }) => (
  <thead>
    <tr>
      <th width={'50%'} height={'2em'}>Attributes</th>
      <th width={'80px'} bgcolor={(percentage ?? 0) < 100 ? 'lightcoral' : 'lightgreen'}>
        {`Weight ${percentage === 100 ? '' : `${percentage || 0}%`}`}
      </th>
      <th width={'120px'}>Rating type</th>
    </tr>
  </thead>
)

export default Component
