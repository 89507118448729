import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@fluentui/react'
import TextField from '../../../common/TextField'
import Dropdown from '../../../common/Dropdown'
import SpinEditField from '../../../common/SpinEditField'
import { selectSettings, saveSettings } from '../../../../features/settings/settingsSlice'
import { COORDINATES_ENCODINGS } from '../../../../constants/geo'
import { serializeGeometry } from '../../../../utils/geo'

const CoordinateFormats = () => {
  const dispatch = useDispatch()

  const settings = useSelector(selectSettings).formats

  const onChangeEncoding = useCallback((event, value) => {
    dispatch(saveSettings({
      formats: {
        coordinatesEncoding: value.key,
      },
    }))
  }, [ dispatch ])

  const onChangePrecision = useCallback((event, value) => {
    dispatch(saveSettings({
      formats: {
        coordinatesPrecision: value,
      },
    }))
  }, [ dispatch ])

  return (
    <Stack style={{ marginTop: 8, paddingBottom: 20, gap: 8 }}>
      <Dropdown
        label="Coordinates Encoding"
        options={COORDINATES_ENCODINGS}
        defaultSelectedKey={settings.coordinatesEncoding}
        onChange={onChangeEncoding}
      />
      <SpinEditField
        label="Coordinates Precision"
        min={0}
        max={10}
        value={settings.coordinatesPrecision}
        onChange={onChangePrecision}
      />
      <TextField
        label="Sample"
        value={serializeGeometry([ 30.56524684321655, 50.56546846546547 ], settings)}
      />
    </Stack>
  )
}

export default CoordinateFormats
