import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { ComboBox, Label } from '@fluentui/react'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import { DefaultButton, PrimaryButton } from '../../common/Button'
import { confirmStyles } from '../../Cabinet/stylesForm'
import {
  selectInUpdate,
  selectSectorFldIdx, selectSectorsFull, selectSiteFldIdx, selectSitesFull,
  setInUpdate,
} from '../../../features/network/networkSlice'
import { SECTOR_BASE, SECTOR_SITE_FIELD, SITE_NAME_FIELD, STATUS_REMOVED } from '../../../constants/network'
import { useConfirm } from '../../../components/common/Confirm'

const MIN_WIDTH_DIALOG = 360
const SEARCH_LETTERS = 4

const NewSectorForm = ({ show, onCancel, onOk }) => {
  const techField = useRef()
  const dispatch = useDispatch()

  const sitesFull = useSelector(selectSitesFull)
  const [ idFldIdx, nameFldIdx,,, statusFldIdx ] = useSelector(selectSiteFldIdx, shallowEqual)
  const sectorsFull = useSelector(selectSectorsFull)
  const [ ,,,,,,, techFldIdx,, rbsFldIdx ] = useSelector(selectSectorFldIdx, shallowEqual)
  const inUpdate = useSelector(selectInUpdate)

  const { renderConfirm, msg } = useConfirm()
  const [ siteId, setSiteId ] = useState(null)

  const technologies = useMemo(() => Object.keys(SECTOR_BASE).slice(1).map((item) => ({
    key: item,
    text: item,
  })), [])

  const findExistingBaseStation = useCallback((siteId, tech) => {
    const sectorsRange = sectorsFull.findRangeByValue(siteId, SECTOR_SITE_FIELD)
    if (!sectorsRange) {
      return null
    }
    const list = sectorsFull.getList()
    const sameTech = sectorsRange.find((index) => list[index][techFldIdx] === tech)
    if (sameTech === undefined) {
      return null
    }
    return list[sameTech][rbsFldIdx]
  }, [ sectorsFull, techFldIdx, rbsFldIdx ])

  const onCreate = useCallback(async () => {
    const tech = techField.current?.selectedOptions?.[0]?.key
    if (siteId && tech) {
      const baseStationId = findExistingBaseStation(siteId, tech)
      dispatch(setInUpdate(true))
      await onOk({ siteId, baseStationId, tech })
    } else {
      msg({ messages: [ 'Please, select Site Name and Technology' ] })
    }
  }, [ siteId, techField, findExistingBaseStation, onOk, msg, dispatch ])

  const siteNameChange = useCallback(({ target }) => {
    setSiteId(null)
    let options = ''
    const sample = target?.value ?? ''
    if (sample.length >= SEARCH_LETTERS) {
      const list = sitesFull.getList()
      const index = sitesFull.getIndex(SITE_NAME_FIELD)
      const [ minId, maxId ] = sitesFull.findRangeByPrefix(sample.toUpperCase(), SITE_NAME_FIELD, true)
      if (minId >= 0 && maxId >= 0) {
        const names = []
        let id
        for (let i = minId; i <= maxId; i++) {
          if (list[index[i]]) {
            const item = list[index[i]]
            if (item[statusFldIdx] !== STATUS_REMOVED) {
              names.push(item[nameFldIdx])
              id = item[idFldIdx]
            }
          }
        }
        options = names.map((name) => `<option value="${name}">${name}</option>`).join('')
        if (names.length === 1) {
          setSiteId(id)
        }
      }
    }
    document.getElementById('site-names').innerHTML = options
  }, [ sitesFull, idFldIdx, statusFldIdx, nameFldIdx ])

  return (
    <Dialog
      hidden={!show}
      onDismiss={onCancel}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Create New Sector',
      }}
      minWidth={MIN_WIDTH_DIALOG}
      modalProps={{
        isBlocking: true,
      }}
    >
      {renderConfirm()}
      <div className={confirmStyles.body}>
        <Label htmlFor="site-name">Site Name</Label>
        <input
          id="site-name"
          list="site-names"
          type="text"
          placeholder={`Enter ${SEARCH_LETTERS} first characters`}
          style={{ width: 190, height: 26, paddingLeft: 7, fontSize: '1em' }}
          onChange={siteNameChange}
        />
        <datalist id="site-names" />
        {/* <VirtualizedComboBox
          componentRef={siteField}
          label="Site"
          options={sites}
          autoComplete="on"
          required
        /> */}
        <ComboBox
          componentRef={techField}
          label="Technology"
          placeholder="Select Technology"
          options={technologies}
          autoComplete="on"
          required
          style={{ width: 200 }}
        />
      </div>
      <DialogFooter>
        <PrimaryButton onClick={onCreate} text="Create" disabled={!siteId || inUpdate} />
        <DefaultButton onClick={onCancel} text="Cancel" />
      </DialogFooter>
    </Dialog>
  )
}

export default NewSectorForm
