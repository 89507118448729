import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  selectSites, selectSiteFldIdx, selectBaseStations, selectBaseStationFldIdx, selectSectors, selectSectorFldIdx,
  // selectSitesFull, selectSiteFields,
} from '../network/networkSlice'
import { activeProjectId } from '../projects/projectsSlice'
import { findIndex } from '../network/indexing'
import { EVENT_TYPE, saveEventLog } from '../eventLog/eventLogSlice'
import api from '../../api'
import { tableToExcel, tableToCSV } from '../../utils/export'
import {
  // SITE_NAME_FIELD, SITE_POLYGON_FIELD, SITE_REGION_FIELD, SECTOR_SITE_NAME_FIELD,
  COMPLAINT_DATE_FIELD, SITE_DATE_FIELD, RBS_DATE_FIELD, SECTOR_DATE_FIELD,
} from '../../constants/network'
// import { AGGREGATE_AVERAGE } from '../../components/HistoricalData/constants'

export const EXPORT_FILE_NAME = 'historicalData'

export const HISTORICAL_DATA_TYPE = {
  region: 'region',
  polygon: 'polygon',
  site: 'site',
  rbs: 'rbs',
  sector: 'sector',
  complaints: 'complaints',
}

export const FORMAT_TYPE = {
  table: 'table',
  excel: 'excel',
  csv: 'csv',
}

const initialState = {
  loading: false,
  exporting: false,
  selectedAttributes: null,
  data: [],
  columns: [],
  index: null,
  warn: null,
}

const loading = (state) => {
  state.loading = true
}

const dateField = (id) => ({
  id,
  label: 'Date',
  description: 'Date',
  type: 'datetime',
  editable: false,
  inCompositeIndex: true,
  hidden: false,
  custom: true,
})

const loadingDone = (id, newColumn) => (state, action) => {
  if (!action?.payload) {
    state.loading = false
    return
  }

  const { data: historicalData, warn } = action.payload
  if (!newColumn) {
    const { data, fields } = historicalData
    const dateFieldIdx = findIndex(fields, id)
    fields.unshift(fields.splice(dateFieldIdx, 1)[0])
    data.forEach((item) => item.unshift(item.splice(dateFieldIdx, 1)[0]))
    state.data = data
    state.columns = fields
    if (warn && warn.length > 0) {
      state.warn = warn
    }
    state.loading = false
    return
  }

  const dates = Object.keys(historicalData).sort()
  if (dates.length > 0) {
    const fields = historicalData[dates[0]].fields
    fields.splice(0, 0, dateField(id))
    const data = dates.map((date) => {
      const data = historicalData[date].data
      data.forEach((row) => {
        row.splice(0, 0, date)
      })
      return data
    })
    state.data = [].concat(...data)
    state.columns = fields
  } else {
    state.data = []
    state.columns = []
  }
  if (warn && warn.length > 0) {
    state.warn = warn
  }
  state.loading = false
}

const exporting = (state) => {
  state.exporting = true
}

const exportingDone = (state) => {
  state.exporting = false
}

const getNames = (list, nameIdx) => {
  return list.getList().map((item) => {
    return item[nameIdx]
  })
}

/* const processHistoricalDataResponse = (state, fields, data, customFieldIdx, customField) => {
  const sitesFull = selectSitesFull(state)
  const siteFields = selectSiteFields(state)
  const siteCustomFieldIdx = findIndex(siteFields, customFieldIdx)
  const sectorSiteIdFieldIdx = findIndex(fields, SECTOR_SITE_NAME_FIELD)
  const grouped = data.reduce((agg, item) => {
    const siteName = item[sectorSiteIdFieldIdx]
    const siteIdx = sitesFull.findRangeByValue(siteName, SITE_NAME_FIELD)
    const siteRegion = siteIdx >= 0 ? sitesFull.getList()[siteIdx][siteCustomFieldIdx] ?? '' : ''
    if (!agg[siteRegion]) {
      agg[siteRegion] = []
    }
    agg[siteRegion].push(item)
    return agg
  }, {})
  return {
    fields: customField
      ? [
          customField,
          ...fields,
        ]
      : fields,
    data: Object.keys(grouped).map((key) => {
      const sectors = grouped[key]
      const data = []
      sectors.forEach((sector) => {
        fields.forEach((field, index) => {
          if (field.inCompositeIndex) {
            if (!data[index]) {
              data[index] = 0
            }
            data[index] += sector[index]
          } else {
            data[index] = null
          }
        })
      })
      fields.forEach((field, index) => {
        if (AGGREGATE_AVERAGE.includes(field.id)) {
          data[index] = Number(data[index] / sectors.length).toFixed(5)
        }
      })
      return [
        key,
        ...data,
      ]
    }),
  }
}

const groupHistoricalData = (state, historicalData, groupByField, fieldToPaste) => {
  const { data: hData, warn } = historicalData
  const dates = Object.keys(hData).sort()
  return {
    data: dates.reduce((agg, date) => {
      const fields = hData[date].fields
      const data = hData[date].data
      const grouped = processHistoricalDataResponse(
        state,
        fields,
        data,
        groupByField,
        agg.fields
          ? null
          : fieldToPaste,
      )
      if (!agg.fields) {
        agg.fields = grouped.fields
        agg.fields.splice(0, 0, dateField(SECTOR_DATE_FIELD))
      }
      if (grouped.data.length > 0) {
        agg.data = (agg.data || []).concat(grouped.data.map((row) => {
          row.splice(0, 0, date)
          return row
        }))
      }
      return agg
    }, {}),
    warn,
  }
} */

const doLoadDataSite = createAsyncThunk(
  'historicalData/loadDataSite',
  api.history.loadHistorySites,
)

const doLoadDataRBS = createAsyncThunk(
  'historicalData/loadDataRBSes',
  api.history.loadHistoryRBSes,
)

const doLoadDataComplaints = createAsyncThunk(
  'historicalData/loadDataComplaints',
  api.history.loadHistoryComplaints,
)

const doLoadDataSector = createAsyncThunk(
  'historicalData/loadDataSector',
  api.history.loadHistorySectors,
)

const doLoadDataRegion = createAsyncThunk(
  'historicalData/loadDataRegion',
  api.history.loadHistoryRegions,
  /* async (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const historicalData = await api.history.loadHistorySectors(payload)
    return groupHistoricalData(
      state,
      historicalData,
      SITE_REGION_FIELD,
      {
        id: 'table.network.sector.region',
        label: 'Region',
        description: 'Region',
        type: 'string',
        editable: false,
        inCompositeIndex: true,
        hidden: true,
        custom: true,
      },
    )
  }, */
)

const doLoadDataPolygon = createAsyncThunk(
  'historicalData/loadDataPolygon',
  api.history.loadHistoryPolygons,
  /* async (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const historicalData = await api.history.loadHistorySectors(payload)
    return groupHistoricalData(
      state,
      historicalData,
      SITE_POLYGON_FIELD,
      {
        id: 'table.network.sector.polygon',
        label: 'Polygon',
        description: 'Polygon',
        type: 'string',
        editable: false,
        inCompositeIndex: true,
        hidden: true,
        custom: true,
      },
    )
  }, */
)

const prepareDataForExport = (state) => {
  const data = selectHistoricalData(state)
  const columns = selectHistoricalDataColumns(state)
  const headers = columns.map((column) => column.label)
  const selectedAttributes = selectSelectedAttributes(state)
  const hidden = (_, index) => {
    return selectedAttributes.includes(columns[index].id) || columns[index].custom
  }
  return {
    data,
    headers,
    hidden,
  }
}

export const doExportToCSV = createAsyncThunk(
  'historicalData/exportToCSV',
  (_, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, headers, hidden } = prepareDataForExport(state)
    tableToCSV(headers, data, hidden, `${EXPORT_FILE_NAME}.csv`)
  },
)

export const doExportToExcel = createAsyncThunk(
  'historicalData/exportToExcel',
  (_, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, headers, hidden } = prepareDataForExport(state)
    tableToExcel(headers, data, hidden, EXPORT_FILE_NAME, `${EXPORT_FILE_NAME}.xlsx`)
  },
)

export const historicalDataSlice = createSlice({
  name: 'historicalData',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSelectedAttributes: (state, action) => {
      state.selectedAttributes = action.payload
    },
    clearHistoricalData: (state) => {
      state.selectedAttributes = null
      state.data = []
      state.columns = []
    },
    clearWarnings: (state) => {
      state.warn = null
    },
  },
  extraReducers: (builder) => builder
    .addCase(doLoadDataSite.pending, loading)
    .addCase(doLoadDataSite.fulfilled, loadingDone(SITE_DATE_FIELD, true))
    .addCase(doLoadDataRBS.pending, loading)
    .addCase(doLoadDataRBS.fulfilled, loadingDone(RBS_DATE_FIELD, true))
    .addCase(doLoadDataSector.pending, loading)
    .addCase(doLoadDataSector.fulfilled, loadingDone(SECTOR_DATE_FIELD, true))
    .addCase(doLoadDataComplaints.pending, loading)
    .addCase(doLoadDataComplaints.fulfilled, loadingDone(COMPLAINT_DATE_FIELD))
    .addCase(doLoadDataRegion.pending, loading)
    .addCase(doLoadDataRegion.fulfilled, loadingDone(SITE_DATE_FIELD, true))
    .addCase(doLoadDataPolygon.pending, loading)
    .addCase(doLoadDataPolygon.fulfilled, loadingDone(SITE_DATE_FIELD, true))
    .addCase(doExportToCSV.pending, exporting)
    .addCase(doExportToCSV.fulfilled, exportingDone)
    .addCase(doExportToExcel.pending, exporting)
    .addCase(doExportToExcel.fulfilled, exportingDone)
    .addMatcher((action) => action.type.endsWith('/rejected'), (state, action) => {
      state.loading = false
      state.exporting = false
      console.error(`Action [${action.type}] failed with error: ${action.error?.message}`)
    }),
})

export const {
  setLoading,
  setSelectedAttributes,
  clearHistoricalData,
  clearWarnings,
} = historicalDataSlice.actions

export const selectLoading = (state) => state.historicalData?.loading
export const selectWarnings = (state) => state.historicalData?.warn
export const selectExporting = (state) => state.historicalData?.exporting
export const selectSelectedAttributes = (state) => state.historicalData?.selectedAttributes
export const selectHistoricalData = (state) => state.historicalData?.data
export const selectHistoricalDataColumns = (state) => state.historicalData?.columns

export const loadHistoricalData = (type, fromDate, toDate, format) => async (dispatch, getState) => {
  const currentState = getState()

  if (type === HISTORICAL_DATA_TYPE.site) {
    const sites = selectSites(currentState)
    const [ , nameIdx ] = selectSiteFldIdx(currentState)
    if (sites.getList().length > 0) {
      const names = getNames(sites, nameIdx)
      await dispatch(doLoadDataSite({ fromDate, toDate, names }))
    }
  } else if (type === HISTORICAL_DATA_TYPE.rbs) {
    const rbss = selectBaseStations(currentState)
    const [ , nameIdx,,, siteNameIdx ] = selectBaseStationFldIdx(currentState)
    if (rbss.getList().length > 0) {
      const names = rbss.getList().reduce((agg, row) => {
        agg.push({
          siteName: row[siteNameIdx],
          rbsName: row[nameIdx],
        })
        return agg
      }, [])
      await dispatch(doLoadDataRBS({ fromDate, toDate, names }))
    }
  } else if (type === HISTORICAL_DATA_TYPE.complaints) {
    const projectId = activeProjectId(currentState) ?? '_'
    await dispatch(doLoadDataComplaints({ projectId, fromDate, toDate }))
  } else if (type === HISTORICAL_DATA_TYPE.sector) {
    const sectors = selectSectors(currentState)
    if (sectors.getList().length > 0) {
      const [ , nameIdx ] = selectSectorFldIdx(currentState)
      const names = getNames(sectors, nameIdx)
      await dispatch(doLoadDataSector({ fromDate, toDate, names }))
    }
  } else {
    const sites = selectSites(currentState)
    if (sites.getList().length > 0) {
      const [ , nameIdx ] = selectSiteFldIdx(currentState)
      const names = getNames(sites, nameIdx)
      if (type === HISTORICAL_DATA_TYPE.region) {
        await dispatch(doLoadDataRegion({ fromDate, toDate, names }))
      } else if (type === HISTORICAL_DATA_TYPE.polygon) {
        await dispatch(doLoadDataPolygon({ fromDate, toDate, names }))
      }
    }
  }

  const selectedAttributes = selectSelectedAttributes(currentState)

  const { data, columns } = getState().historicalData
  const [ rows, cols ] = [ data.length, selectedAttributes?.length || columns.length ]

  if (format === FORMAT_TYPE.csv) {
    dispatch(doExportToCSV())
  } else if (format === FORMAT_TYPE.excel) {
    dispatch(doExportToExcel())
  }

  if (format !== FORMAT_TYPE.table) {
    dispatch(saveEventLog(
      EVENT_TYPE.historicalDataExport,
      ` [Type = ${type}; From = ${fromDate}; To = ${toDate}; Format = ${format}; Rows: ${rows}; Columns: ${cols}]`,
    ))
  }

  return [ rows, cols ]
}

export default historicalDataSlice.reducer
